import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { claimAngpau } from "../../../store/ducks/angpau/actions";

export class RayaEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ketupakAmt: "",
      bonusStatus: false,
    };
  }
  componentDidMount() {
    document.body.classList.add('modal-open');
  }
  
  componentDid= () => {
    const { t } = this.props;
    Swal.fire({
      iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
      icon: "info",
      title: t("global.sorry"),
      text: t("cny.raya-requirement"),
      confirmButtonText: t("global.ok"),
      customClass: (!isDesktop) ? {
        container: "swal-mobile-container sorry",
        icon: "swal-sorry-icon",
      } : []
    });
  }

  

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
      if (nextProps.claim_angpau_result.responseCode === 200) {
          if(nextProps.claim_angpau_result.data.depositStatus === false){
            Swal.fire({
              iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
              icon: "info",
              title: t("global.sorry"),
              text: t("cny.raya-requirement"),
              confirmButtonText: t("global.ok"),
              customClass: (!isDesktop) ? {
                container: "swal-mobile-container sorry",
                icon: "swal-sorry-icon",
              } : []
            });
          }else{
            this.setState({
              bonusStatus: true,
              ketupakAmt: nextProps.claim_angpau_result.data.amount 
            });
          }
      } else {
        return false;
      }
    }

    return true;
  }

  openKetupak = () => {
    let data = {};
    data.action = "click";
    this.props.claimAngpau(data);
    // this.setState({
    //   bonusStatus: true,
    // });
  };

  closeKetupak = () => {
    this.setState({
      homePageEvents: false,
    });
  };
  

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const raya = this.imageImport(require.context("../../../assets/images/home-overlay-events/", false, /\.(png|jpe?g|svg|webp)$/));

    return (
      <Auxiliary>
        {this.props.homePageEvents ? (<>
          {/* <div className='fes-ani-content raya' >
            <div className='fes-ani-head'>
              {this.state.bonusStatus === true &&
                <div className={`fes-amount-wrap ${!isDesktop && 'mobile'}`}>
                  <h5>
                    {t("cny.congratz")}
                    <br />
                    {t("cny.received")}
                  </h5>
                  <p>RM<br /><span className='fes-ani-amount'>{this.state.ketupakAmt}</span></p>
                </div>}
              <div className='fes-ani-body'>
                <img className={`flare-light ${!isDesktop && 'mobile'}`} src={raya["shine.png"]} alt="flare light" />
                {this.state.bonusStatus === false ? (
                  <>
                    <div className={`fes-title-top ${!isDesktop && 'mobile'}`}>
                      <span className='title-raya'>{t("cny.raya-events")}</span>
                      <h5 className='mt-2'>{t("cny.highest-receive")} RM168</h5>
                      <img className={`ketupak-ribbon ${!isDesktop && 'mobile'}`} src={raya["ketupak_ribbon.png"]} alt="ketupak-ribbon" />
                    </div>
                    <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={raya["ketupak.png"]} alt="ketupak" onClick={this.openKetupak} />
                    <button className={`fes-button-text ${!isDesktop && 'mobile'}`} onClick={this.openKetupak} >{t("cny.receive-bonus")}</button>
                  </>
                ) :
                  (<>
                    <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={raya["ketupak-open.png"]} alt="ketupak open" />
                    <img className={`ani-burst ${!isDesktop && 'mobile'}`} src={raya["star-1.png"]} alt="star burst" />
                    <img className={`ani-burst-one ${!isDesktop && 'mobile'}`} src={raya["star-2.png"]} alt="star burst" />
                  </>
                  )
                }
              </div>
              <div>
                {this.state.bonusStatus === false &&
                  <h5 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }} className='mt-3'>{t("cny.details")}</h5>
                }
                <Icon type="button" className="fes-btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} />
              </div>
            </div>
          </div> */}
          <section className='ani-section raya'>
            <div className='ani-content-wrapper'>
              <div className={`ani-header-wrapper ${!isDesktop && 'mobile'}`}>
                <div className='ani-title'>
                      <h1>{t("cny.raya-events")}</h1>
                      <img className={`title-bg ${!isDesktop && 'mobile'}`} src={raya["ketupak_ribbon.png"]} alt="ketupak-ribbon" />
                    </div>
                    <h5>{t("cny.highest-receive")} RM168</h5>
               </div>
              <div className='ani-item-wrapper'>
              <img className={`flare-light ${!isDesktop && 'mobile'}`} src={raya["shine.png"]} alt="flare light" />
              {this.state.bonusStatus === false ? (
                  <>
               <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={raya["ketupak.png"]} alt="ketupak" onClick={this.openKetupak} />
               </>
               ) :
                  (
                  <>
                  <div className={`fes-amount-wrap ${!isDesktop && 'mobile'}`}>
                  <h5>
                    {t("cny.congratz")}
                    <br />
                    {t("cny.received")}
                  </h5>
                  <p>RM<br /><span className='fes-ani-amount'>{this.state.ketupakAmt}</span></p>
                </div>
                    <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={raya["ketupak-open.png"]} alt="ketupak open" />
                    <img className={`ani-burst ${!isDesktop && 'mobile'}`} src={raya["star-1.png"]} alt="star burst" />
                    <img className={`ani-burst-one ${!isDesktop && 'mobile'}`} src={raya["star-2.png"]} alt="star burst" />
                  </>
                  )
                }
                              <div className={`ani-bottom-wrapper ${!isDesktop && 'mobile'}`}>
              {this.state.bonusStatus === false &&
               <button className={`claim-btn ${!isDesktop && 'mobile'}`} onClick={this.openKetupak} >{t("cny.receive-bonus")}</button>
              }
              <div className='close-button-wrapper'>
              {this.state.bonusStatus === false &&
                  <h5 onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }} className='mt-3'>{t("cny.details")}</h5>
                }
                <Icon type="button" className="fes-btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} />
              </div>
              </div>
              </div>

            </div>
            { isDesktop ? <img className="fes-ani-overlay" src={raya["raya-bg.png"]} alt="ketupak open" /> : <img className="fes-ani-overlay" src={raya["raya-bg-mobile.png"]} alt="ketupak open" />}
            
          </section>
        </>
        ) :
          ("")
        }

      </Auxiliary>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    claim_angpau_result: state.events.claim_angpau_result
  };
};

const mapDispatchToProps = {
  claimAngpau
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(RayaEvents));