import axios from "../../../common/config/axios";
import * as type from "./types";

export const download = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("mobile_apps/download", {params: data})
    .then((res) => {
            dispatch({
                type: type.DOWNLOAD,
                payload: res,
            });
        }).catch((err) => {
            console.log("Download Apps Data Error", err);
            dispatch({
                type: type.DOWNLOAD,
                payload: [],
            });
        });
};