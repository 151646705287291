import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";
// import { getMemberReferral, getMemberReferralBonus } from "../../../store/ducks/profile/actions";

//Components
import DataTable from "../../../components/Datatable/DataTable";
import MobileSubHeader from '../../../components/PageHeader/MobileSubHeader';
import Button from '../../../components/Button/Button';
import DateScroller from "../../../components/MobileAsset/DateScroller/DateScroller";

//Icons
import { Icon } from "@iconify/react";



class MobileReferralDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterShow: ''
    };
  }

  componentDidMount() {
  }

  showDateHandler = () => {
    this.setState({
      filterShow: 'date'
    })
  }

  render() {
    const { t } = this.props;

    return (
      <Auxiliary>
        <DateScroller
          onChangeDateHandler={this.props.onChangeDateHandler}
          toggleShowDateSelect={this.props.toggleShowDateSelect}
          {...this.state}
          {...this.props}
        />
        <section className="pf-m-dashboard on-top">
          <div className={`filters-m-col ${this.state.filterShow ? 'show' : ''}`}>
            <MobileSubHeader
              className="bg-white"
              closeModalHandler={this.props.toggleDetailsHandler}
              header={t("referral.referral-amount")}
              style={{ justifyContent: "unset" }}
            />
            <div className="filters-m-panel">
              <div className="filter-m-tp-col">
                <div className="filter-m-cat">
                  <label className="filter-m-label">{t("referral.date")}:</label>
                  <button className={`btn btn-m-filter date ${this.state.filterShow === 'date' && 'active'}`} type="button" onClick={this.showDateHandler}>
                    <p>
                      {this.props.start_date}
                      <br />
                      {this.props.end_date}
                    </p>
                    <Icon icon="uil:angle-down" />
                  </button>
                </div>
              </div>
              <div className="filter-m-btm-col">
                <div className="filters-m-date-group">
                  <div className="d-flex align-items-center flex-wrap">
                    {this.props.filterDate &&
                      this.props.filterDate.map((item, index) => (
                        <div className="form-check form-check-inline filter" key={index}>
                          <input
                            className="btn-check"
                            type="radio"
                            name="filterDate"
                            id={item.id}
                            value={item.value}
                            defaultChecked={index === 0}
                            checked={index === this.props.currentFilterDate}
                            onClick={() => this.props.onChangeFilterDateHandler(index)}
                          />
                          <label className="btn form-check-label" htmlFor={item.id} onClick={
                            () => [
                              this.props.filterDayRange(item.value)
                            ]
                          }>
                            {t(`history.${item.label}`)}
                          </label>
                        </div>
                      ))}
                  </div>
                  <div className="filter-m-date">
                    <label className="filter-m-label">{t("referral.start-date")}:</label>
                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'startDate')}>
                      <p>{this.props.start_date}</p>
                      <Icon icon="uil:angle-right" />
                    </button>
                  </div>
                  <div className="filter-m-date">
                    <label className="filter-m-label">{t("referral.end-date")}:</label>
                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'endDate')}>
                      <p>{this.props.end_date}</p>
                      <Icon icon="uil:angle-right" />
                    </button>
                  </div>
                </div>
                <Button
                  typeButton="button"
                  classValue="btn btn-gradient-blue mt-3"
                  idValue="search"
                  buttonName={t('global.check')}
                  clicked={() => { this.props.searchHandler(); this.setState({ filterShow: '' }); }}
                  disabled={this.props.disable_search_btn}
                />
                <Button
                  typeButton='button'
                  classValue='btn btn-success w-100'
                  idValue='reset'
                  buttonName={t('global.reset')}
                  clicked={this.props.onResetHandler}
                />
              </div>
            </div>
          </div>
          <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
          <div className="pd-m-card-col">
            <Fade duration={1000} triggerOnce>
              <DataTable
                keyField="serialNo"
                data={this.props.dataListing}
                columns={this.props.columns}
                activePage={this.props.activePage}
                limit={this.props.limit}
                totalItems={this.props.total_records}
                paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                // sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                loading={this.props.loading}
                hideSearchBar={true}
                hideTotalRecords={true}
                classValue="pd-table"
              // component="referral"
              />
            </Fade>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(MobileReferralDetails));
