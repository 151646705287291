import React, { Component, createRef } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";

import SideBar from '../../components/SideBar/SideBar';
import { Icon } from "@iconify/react";
import { Fade } from "react-awesome-reveal";

export class MobileLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.topSection = createRef();
    }

    scrollToTop = () => {
        this.topSection.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }


    changeLeaderboard = (id) => {
        this.props.switchLeaderboard(id)
        if (id >= 0 && id <= 4) {
            const activeBtn = document.getElementById("slug" + id)
            activeBtn.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;

        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const gameIcon = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));
        const img = this.imageImport(require.context("../../assets/images/leaderboard", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '-cn';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn"
                break;
            default:
                imageLanguage = ""
                break;
        }

        return (
            <Auxiliary>
                <SideBar />
                <div className="banner-col">
                    <div className="banner-item">
                        <img className='h-auto' src={banner[`lb-m-banner${imageLanguage}.webp`]}/>
                    </div>
                </div>
                <section className='lb-section' ref={this.topSection}>
                    <div className='lb-event-date mt-3 px-3'>
                        <button className='btn btn-gradient-blue'
                            // onClick={() => this.props.switchLeaderboard(this.props.activeGameIndex - 1)}
                            onClick={() => this.changeLeaderboard(this.props.activeGameIndex - 1)}
                        >
                            <Icon icon="uil:angle-left" />
                        </button>

                        <div className='date'>
                            <Icon icon="uil:clock" />
                            <Fade>
                                <span>{this.props.activeGameStartDate}</span>
                                <span className='px-2'>{t('check_in.time_3')}</span>
                                <span>{this.props.activeGameEndDate}</span>
                            </Fade>
                        </div>

                        <button className='btn btn-gradient-blue'
                            // onClick={() => this.props.switchLeaderboard(this.props.activeGameIndex + 1)}
                            onClick={() => this.changeLeaderboard(this.props.activeGameIndex + 1)}
                        >
                            <Icon icon="uil:angle-right" />
                        </button>
                    </div>

                    <div className='lb-game-container px-3'>
                        <div className='row g-3'>
                            {
                                this.props.leaderboards.map((item, index) => (
                                    <div className='col-3' key={index}>
                                        <div id={`slug${index}`} className={`lb-game ${this.props.activeGame === item.id && 'active'}`} onClick={() => this.props.switchLeaderboard(index)}>
                                            <span>
                                                <img className='icon-grey' src={gameIcon[item.game_slug + '-grey.svg']} alt={item.game_title} />
                                                <img className='icon-white' src={gameIcon[item.game_slug + '-white.svg']} alt={item.game_title} />
                                            </span>
                                            <p>{t("vendor." + item.game_slug)}</p>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                (this.props.leaderboards.length < 5) && Array.from({ length: 5 - this.props.leaderboards.length }, (x, i) => {
                                    return (
                                        <div className='col-3'>
                                            <div className='lb-game coming-soon'>
                                                <p>Coming Soon</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='lb-name-list px-3'>
                        <div>
                            <div className='d-flex align-item-center justify-content-center mb-3'>
                                <img src={img["paddy.webp"]} alt="deco" />
                                <h1>{t("leaderboard.winner-list-1")}</h1>
                                <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                            </div>
                            <div className='first-card'>
                                <span className='lazy-load-image-background'>
                                    <img className='emblem' src={img["top1-logo.webp"]} alt="top" />
                                </span>

                                <div className='winner'>
                                    <p className='name'>{this.props.candidates[0]?.username}</p>
                                    <div className='d-flex pb-2'>
                                        <p>{t("leaderboard.betting")}:</p>
                                        <p className='number'>{this.props.candidates[0]?.total_win}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <p>{t("leaderboard.prize")}:</p>
                                        <p className='number bonus'>{this.props.candidates[0]?.prize}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='second-card'>
                                <span className='lazy-load-image-background'>
                                    <img className='emblem' src={img["top2-logo.webp"]} alt="top" />
                                </span>

                                <div className='winner'>
                                    <p className='name'>{this.props.candidates[1]?.username}</p>
                                    <div className='d-flex pb-2'>
                                        <p>{t("leaderboard.betting")}:</p>
                                        <p className='number'>{this.props.candidates[1]?.total_win}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <p>{t("leaderboard.prize")}:</p>
                                        <p className='number bonus'>{this.props.candidates[1]?.prize}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='third-card'>
                                <span className='lazy-load-image-background'>
                                    <img className='emblem' src={img["top3-logo.webp"]} alt="top" />
                                </span>
                                <div className='winner'>
                                    <p className='name'>{this.props.candidates[2]?.username}</p>
                                    <div className='d-flex pb-2'>
                                        <p>{t("leaderboard.betting")}:</p>
                                        <p className='number'>{this.props.candidates[2]?.total_win}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <p>{t("leaderboard.prize")}:</p>
                                        <p className='number bonus'>{this.props.candidates[2]?.prize}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='d-flex align-item-center justify-content-center mb-3'>
                                <img src={img["paddy.webp"]} alt="deco" />
                                <h1>{t("leaderboard.winner-list-2")}</h1>
                                <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                            </div>
                            <div className='lb-first-table'>
                                <div className='events-table-container card-shadow'>
                                    <table className='table table-borderless events-table'>
                                        <thead>
                                            <tr>
                                                <th>{t("leaderboard.ranking")}</th>
                                                <th>{t("leaderboard.winner")}</th>
                                                <th>{t("leaderboard.betting")}</th>
                                                <th>{t("leaderboard.prize")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.candidates.slice(3, 10).map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 4}</td>
                                                        <td>{item.username}</td>
                                                        <td className='number'>{item.total_win}</td>
                                                        <td className='number bonus'>{item.prize}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='my-3'>
                            <div className='d-flex align-item-center justify-content-center mb-3'>
                                <img src={img["paddy.webp"]} alt="deco" />
                                <h1>{t("leaderboard.winner-list-3")}</h1>
                                <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                            </div>

                            <div className='lb-second-table'>
                                <div className='events-table-container card-shadow'>
                                    <table className='table table-borderless events-table'>
                                        <thead>
                                            <tr>
                                                <th>{t("leaderboard.winner-3-1").replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</th>
                                                <th>{t("leaderboard.winner-3-2").replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.candidates.slice(10, 30).map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 11}</td>
                                                        <td>{item.username}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='term-section'>
                        <h4 className='tnc-title'>{t('bet_and_earn.tnc')}</h4>
                        <div className='px-3'>
                            <h4 className='text-center'>{t("leaderboard.ranking-prize")}</h4>
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr className='bg-gray'>
                                        <td colSpan={2}>{t("leaderboard.top-1-10")}</td>
                                    </tr>
                                    <tr>
                                        <td>1.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize1}</td>
                                        <td>6.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize6}</td>
                                    </tr>
                                    <tr>
                                        <td>2.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize2}</td>
                                        <td>7.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize7}</td>
                                    </tr>
                                    <tr>
                                        <td>3.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize3}</td>
                                        <td>8.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize8}</td>
                                    </tr>
                                    <tr>
                                        <td>4.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize4}</td>
                                        <td>9.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize9}</td>
                                    </tr>
                                    <tr>
                                        <td>5.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize5}</td>
                                        <td>10.{this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize10}</td>
                                    </tr>
                                    <tr className='bg-gray'>
                                        <td>{t("leaderboard.top-11-20")}</td>
                                        <td>{t("leaderboard.top-21-30")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("leaderboard.prize")} {this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize11_20}</td>
                                        <td>{t("leaderboard.prize")} {this.props.currency[this.props.currentUser.country_id]}{this.props.activeGamePrizes?.prize21_30}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='term-container'>
                            <div className='term-content no-bg'>
                                <h4 className='mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
                                <ol>
                                    <li>{t("leaderboard.rule-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                    <li>{t("leaderboard.rule-2")}</li>
                                    <li>{t("leaderboard.rule-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                    <li>{t("leaderboard.rule-4")}</li>
                                    <li>{t("leaderboard.rule-5")}</li>
                                    <li>{t("leaderboard.rule-6")}</li>
                                    <li>{t("leaderboard.rule-7")}</li>
                                    <li>{t("leaderboard.rule-8")}</li>
                                    <li>{t("leaderboard.rule-9", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="btt-btn mt-0 mb-5">
                        <div className="btn btn-white" onClick={this.scrollToTop}>
                            <Icon icon="ph:caret-double-up-bold" />
                            {t("vip.back-to-top")}
                        </div>
                    </div>
                </section>
            </Auxiliary>
        )
    }
}

export default (withNamespaces("translation")(MobileLeaderboard));
