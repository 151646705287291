import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const forgotPasswordReducer = createReducer(initialState) ({
    [type.POST_FORGOT_PASSWORD]: (state, action) => ({
        ...state,
        post_forgot_password_data: action.payload,
    }),
    [type.VERIFY_FORGOT_PASSWORD]: (state, action) => ({
        ...state,
        verify_forgot_password_data: action.payload,
    }),
    [type.POST_SEND_FORGOT_PASSWORD_EMAIL]: (state, action) => ({
        ...state,
        post_forgot_password_email_data: action.payload,
    }),
    [type.CHECK_FORGOT_PASSWORD]: (state, action) => ({
        ...state,
        check_forgot_password_data: action.payload
    }),
    [type.GENERATE_FORGOT_PASSWORD_OTP]: (state, action) => ({
        ...state,
        generate_forgot_password_otp_data: action.payload
    }),
    [type.RESEND_FORGOT_PASSWORD_OTP]: (state, action) => ({
        ...state,
        resend_forgot_password_otp_data: action.payload
    }),
    [type.VERIFY_FORGOT_PASSWORD_OTP]: (state, action) => ({
        ...state,
        verify_forgot_password_otp_data: action.payload
    }),
    [type.POST_FORGOT_PASSWORD_OTP]: (state, action) => ({
        ...state,
        post_forgot_password_otp_data: action.payload,
    })
});

export default forgotPasswordReducer;