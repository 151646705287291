import { Component } from "react";
import Modal from "react-modal";
import { withNamespaces } from "react-i18next";

class DoItNowDepositModal extends Component {
    render(){
        const { t } = this.props;
        return(
            <>
                <Modal
                    isOpen={this.props.openDoItNowModal}
                    // closeTimeoutMS={500}
                    portalClassName={`global-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={this.props.toggleDoItNowModalHandler}
                >
                    <div className="modal-content crypto-deposit">
                        <div className="modal-header">
                            {/* <div className='cd-top gap-1'>
                                <p>Warning</p>
                            </div>
                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleDoItNowModalHandler} /> */}
                        </div>
                        <div className="modal-body">
                            <div className='cd-details'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <p>{t("deposit.deposit-doitnow")}</p>
                                        {/* <p className="text-danger">Reminder: QR Code is used to scan once only</p> */}
                                        <button type="button" className="btn btn-gradient-blue text-center" onClick={this.props.toggleDoItNowModalHandler} >OK</button>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <form target="_blank" method="post" id="doitnow_form" action={this.props.doitnowFormInput ? this.props.doitnowFormInput.doitnow_url : ""} className="d-none">
                        <input readOnly type="string" name="clientId" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.clientId : ""} />
                        <input readOnly type="string" name="transactionId" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.transactionId : ""} />
                        <input readOnly type="string" name="custName" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.custName : ""} />
                        <input readOnly type="string" name="amount" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.amount : ""} />
                        <input readOnly type="string" name="returnUrl" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.returnUrl : ""} />
                        <input readOnly type="string" name="callbackUrl" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.callbackUrl : ""} />
                        <input readOnly type="string" name="paymentMethod" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.paymentMethod : ""} />
                        <input readOnly type="string" id="hashVal" name="hashVal" value={this.props.doitnowFormInput ? this.props.doitnowFormInput.hashVal : ""} />
                    </form>
                </Modal>
            </>
        );
    }
}

export default withNamespaces("translation")(DoItNowDepositModal);