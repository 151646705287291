import * as actionType from './types';
import axios from '../../../common/config/axios';
import { isDesktop } from 'react-device-detect';

export const getVendorList = (props) => (dispatch, getState) =>
{
  props.country_id = getState().currentUser.data.country_id;
  // console.log(props);
    axios.get(`${(isDesktop) ? 'live_casino' : 'home'}/vendor_list`, {params: props})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.VENDOR_LIST,
        value: response.data,
      })
    })
    .catch(err =>
    {

    });
};

export const accessGame = (props, vendor) => dispatch =>
{
    // console.log(props);
    let gameUrl = '';
    if(props.other_url)
    {
      gameUrl = (props.game_mode === 1) ? `${vendor}/get_casino_url` : `${vendor}/get_demo_casino_url`;
    }
    else
    {
      gameUrl = (props.game_mode === 1) ? `${vendor}/get_game_url` : `${vendor}/get_demo_url`;
    }
    axios.post(gameUrl, props)
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.CASINO_GAME_ACCESS,
        value: response.data,
        actionFrom: props.action_from
      })
    })
    .catch(err =>
    {

    });
};