import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Modal from "react-modal";
import { Icon } from "@iconify/react";

//Miscellaneous
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";

//Components
import GamePagination from "../../../components/Pagination/GamePagination";
import NoRecords from "../../../components/NoRecords/NoRecords";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

// Actions
import * as actions from "../../../store/ducks/history/actions";

class Mall extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            filterShow: '',
            loading: false,
            gameList: [],
            openPromoTncModal: false,
        };
    }

    componentDidMount()
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray('mall');
            this.props.funcGetList(listingArr);
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.gameList !== this.props.gameList && nextProps.gameList !== undefined)
        {
            if (nextProps.gameList.responseCode === 200 && nextProps.gameList.msgType === 'success')
            {
                this.setState({
                    gameList: nextProps.gameList.data.reference_code_details.gameList,
                    loading: false,
                });
            }
        }

        return true;
    }

    // showTypeHandler = () => 
    // {
    //     this.setState({
    //         filterShow: 'type',
    //     })
    // }

    // showDateHandler = () => 
    // {
    //     this.setState({
    //         filterShow: 'date',
    //     })
    // }

    gameListHandler = (e, id) => 
    {
        e.preventDefault();
        this.setState({
            loading: true,
            openPromoTncModal: !this.state.openPromoTncModal,
        }, () => 
        {
            if(id > 0)
            {
                // console.log(id);
                const listingArr = {};
                listingArr.id = id;
                this.props.funcGetGameList(listingArr);
            }
        });
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    render() 
    {
        const { t } = this.props;
        const mallImg = this.imageImport(require.context("../../../assets/images/mall/", false, /\.(png|jpe?g|svg|webp)$/));
        let gameList = (this.state.gameList !== undefined) ? this.state.gameList : [];
        
        return (
            <Auxiliary>
                
                <Modal
                    isOpen={this.state.openPromoTncModal}
                    closeTimeoutMS={500}
                    portalClassName={`global-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={(event) => this.gameListHandler(event)}>
                    <div className="modal-content tnc-content">
                        <div className="modal-header">
                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.gameListHandler(event)} />
                        </div>
                        <div className="modal-body table-responsive">
                            {this.state.loading === true
                            ?
                                <Skeleton count={3} style={{ marginBottom: ".25rem" }} />
                            :
                                <div className='col-lg-12 col-xl-12'>
                                    <div className='card box-shadow-0'>
                                        <div className='card-header'>
                                            <h4 className='card-title mg-b-0'>{t('redemption-history.game-list')}</h4>
                                        </div>
                                        <div className='card-body pt-0'>
                                            {this.state.loading
                                            ?
                                                <Skeleton count={3} />
                                            :       
                                                gameList.map((val, idx) => 
                                                {
                                                    return (
                                                        <i>
                                                            {val.game_title} <Icon icon="ci:dot-01-xs" /> &nbsp;
                                                        </i> 
                                                    );
                                                })    
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>

                {
                isDesktop ? (
                    <>
                    {this.props.data.loading === true
                        ?
                        <Skeleton count={2} height={'3.125rem'} style={{ marginBottom: ".25rem" }} />
                        :
                        <>
                        {this.props.data.loading === false && this.props.data.totalRecords <= 0
                            ?
                            <NoRecords component={'mall'} />
                            :
                            <Fade duration={1000} triggerOnce>
                                <table className="pd-table rh-pd-table">
                                {this.props.data && this.props.data.data.map((item, index) =>
                                    <tbody key={index} className="rdp-his-body">
                                    <tr className="rdp-his-title text-center">
                                        <td className="d-flex align-items-center">
                                        <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                        <p className="ms-2">{item.date}</p>
                                        </td>
                                        <td>{t('redemption-history.order-total')}</td>
                                        <td>{t('global.status')}</td>
                                        <td className='text-end'>
                                        {item.status === 3 ? <p className='orange-font'>{t('referral.pending')}</p> :
                                            <>{item.status === 4 ? <p className='red-font'>{t('global.cancel')}</p> : <p className='green-font'>{t('history.completed')}</p>}
                                            </>}
                                        </td>
                                    </tr>
                                    <tr className="rdp-his-content">
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {/* <img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.name} /> */}
                                                <img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL + item.image} alt={item.name} />
                                                <h6 className='mb-0' data-index={item.id}>{item.name}</h6>
                                            </div>
                                        </td>
                                        <td className='text-center'> <img src={mallImg["coin.svg"]} className="product-coin" alt="Product Coin" />{(item.coin) === null ? t('redemption-history.refund') : item.coin}</td>
                                        <td className={(item.category === 'free-bet') ? 'text-center' : ''}>
                                            <span className='mall-track-title'>
                                                {
                                                (item.category === 'free-bet')
                                                ?
                                                    (item.status === 3) 
                                                    ? 
                                                        <p className='orange-font'>{t('referral.processing')}</p> 
                                                    : 
                                                        <>{item.status === 4 ? <p className='red-font'>{t('global.cancel')}</p> : <p className='green-font'>{t('global.confirm')}</p>}</>
                                                :
                                                    <>
                                                    <Icon icon="carbon:delivery" className="icon-img" />
                                                    {item.status === 3 ? <p className='orange-font'>{t('redemption-history.preparing-parcel')}</p> :
                                                        <>{item.status === 4 ? <p className='red-font'>{t('redemption-history.prize-desc-9')}</p> : <p className='green-font'>{t('redemption-history.prize-desc-5')}</p>}
                                                        </>}
                                                    </>
                                                }
                                            </span>
                                        </td>
                                        <td className='text-end'>
                                        {
                                            (item.reference_code && item.reference_code !== null && item.status === 1)
                                            ?
                                                (item.category === 'free-bet')
                                                ?
                                                    <button
                                                        type="button"
                                                        onClick={(event) => this.gameListHandler(event, item.id)}
                                                        rel="noreferrer" className='btn btn-gradient-blue float-end'
                                                    >
                                                        {t('redemption-history.game-list')}
                                                    </button>
                                                :                                      
                                                    <button
                                                        type="button"
                                                        onClick={() => window.open(`https://www.tracking.my/track/${item.reference_code}`, '_blank')}
                                                        rel="noreferrer" className={`btn btn-gradient-blue float-end`}
                                                    >
                                                        {t('redemption-history.track-parcel')}
                                                    </button>
                                            :
                                                ""
                                        }
                                        </td>
                                    </tr>
                                    </tbody>
                                )}
                                </table>
                            </Fade>
                        }
                        <GamePagination
                            totalPage={this.props.data.totalPage}
                            curPage={this.props.data.activePage}
                            data={this.props.data.data}
                            navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'mall')}
                        />
                        </>}
                    </>
                ) : (
                    <section className="checkout">
                        {this.props.data.loading === false && this.props.data.totalRecords <= 0
                        ?
                        <NoRecords component={'mall'} />
                        :
                        <Fade duration={1000} triggerOnce>
                        {this.props.data && this.props.data.data.map((item, index) => (
                            <div className='rh-m-pd-card' key={index}>
                                <div className='rh-m-tp-panel justify-content-between'>
                                    <p><Icon icon="ic:outline-event-note" />{item.date}</p>
                                    {item.status === 3 ? <p className='orange-font'>{t('referral.pending')}</p> :
                                    <>{item.status === 4 ? <p className='red-font'>{t('global.cancel')}</p> : <p className='green-font'>{t('history.completed')}</p>}
                                    </>}
                                </div>
                                <div className='rh-m-md-panel'>
                                    <div className='rh-m-md-img'>
                                    <img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.name} />
                                    </div>
                                    <div className='rh-m-md-content d-inline-flex justify-content-between w-100'>
                                    <div>
                                        <h6>{item.name}</h6>
                                        <p>{t('redemption-history.order-total')}</p>
                                    </div>
                                    <div>
                                        <h6>x1</h6>
                                        <p>{(item.coin) === null ? t('redemption-history.refund') : item.coin}</p>
                                    </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="rh-m-btm-panel">
                                    <div className='rh-m-btm-status'>
                                    {
                                        (item.category === 'free-bet')
                                        ?
                                            (item.status === 3) 
                                            ? 
                                                <p className='orange-font'>{t('referral.processing')}</p> 
                                            : 
                                                <>{item.status === 4 ? <p className='red-font'>{t('global.cancel')}</p> : <p className='green-font '>{t('global.confirm')}</p>}</>
                                        :
                                            <>
                                                <Icon icon="carbon:delivery" className="icon-img" />
                                                {item.status === 3 ? <p className='orange-font'>{t('redemption-history.preparing-parcel')}</p> :
                                                <>{item.status === 4 ? <p className='red-font'>{t('redemption-history.prize-desc-9')}</p> : <p className='green-font'>{t('redemption-history.prize-desc-5')}</p>}
                                                </>}
                                            </>
                                    }
                                    </div>
                                    <div>
                                    {
                                        (item.reference_code && item.reference_code !== null && item.status === 1)
                                        ?
                                            (item.category === 'free-bet')
                                            ?
                                                <button
                                                    type="button"
                                                    onClick={(event) => this.gameListHandler(event, item.id)}
                                                    rel="noreferrer" className='btn btn-gradient-blue float-end'
                                                >
                                                    {t('redemption-history.game-list')}
                                                </button>
                                            :
                                                <button
                                                    type="button"
                                                    onClick={() => window.open(`https://www.tracking.my/track/${item.reference_code}`, '_blank')}
                                                    rel="noreferrer" className={`btn btn-gradient-blue w-100`}
                                                >
                                                    {t('redemption-history.track-parcel')}
                                                </button>
                                        :
                                            ""
                                    }
                                    </div>
                                </div>
                            </div>
                        ))}
                        </Fade>
                        }
                        <GamePagination
                            totalPage={this.props.data.totalPage}
                            curPage={this.props.data.activePage}
                            data={this.props.data.data}
                            navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'mall')}
                        />
                    </section>
                )
                }
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => 
{
    return {
        gameList: state.history.game_list,
        // currentUser: state.currentUser.data,
        // updatePrize: state.history.update_prize,
    }
};

const mapDispatchToProps = dispatch => 
{
    return {
        funcGetGameList: (filter) => dispatch(actions.getGameList(filter)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Mall));