import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // vendor: [],
};

const generalReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GENERAL_VENDOR_LIST]: (state, action) => ({
        ...state,
        vendor: action.value,
    }),

    [type.FOOTER]: (state, action) => ({
        ...state,
        footer: action.value,
    }),
});

export default generalReducer;