import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
// import { isDesktop } from "react-device-detect";
// import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
// import { Icon } from "@iconify/react";
// import { Reveal } from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";

//Components 
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";

//Actions 


class MobileBonus extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        
        return images;
    };

    render() 
    {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../../assets/images/bonus/mobile", false, /\.(png|jpe?g|svg|webp)$/));
        let s3ImgUrl = `${process.env.REACT_APP_S3_URL}/images/icon/bonus`;

        return (
            <Auxiliary>
                {this.props.loading && <LoadingPage />}
                <section className="bonus-m-col">
                    <MobileSubHeader
                        className="bg-white"
                        returnPage="/me"
                        header="Bonus"
                        style={{ justifyContent: "unset" }}
                    />
                    {/* <div className="bonus-m-content">
                        <img className="active" src={`${s3ImgUrl}/bonus-ico.svg`} alt='bonus-ico' />
                        <div>
                        <label>{t('bonus.total-redemption-amount')}</label>
                        <p>
                            {this.props.loading ? (
                                <Skeleton count={1} />
                            ) : (
                                <>
                                    {this.props.currentUser.currency}
                                    <span className="ms-2">888.00</span>
                                </>
                            )}
                        </p>
                        </div>
                    </div> */}
                    <div className="bonus-event-m-container">
                        <div className="row g-3">
                            <div className="col-4">
                                <ul className="nav nav-tabs" id="bonusTab" role="tablist">
                                    {this.props.seasonalEventsData && this.props.seasonalEventsData.length > 0 ? (
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="seasonal-event-tab" data-bs-toggle="tab" data-bs-target="#seasonal-event" type="button" role="tab">{t('bonus.seasonal-event-bonus')}</button>
                                        </li>
                                    ) : null}
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="special-event-tab" data-bs-toggle="tab" data-bs-target="#special-event" type="button" role="tab">{t('bonus.special-event-bonus')}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="long-term-event-tab" data-bs-toggle="tab" data-bs-target="#long-term-event" type="button" role="tab">{t('bonus.long-term-event-bonus')}</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-8">
                                <div className="tab-content" id="bonusTabContent">
                                <div className="tab-pane fade show" id="seasonal-event" role="tabpanel">
                                    {this.props.seasonalEventsData && this.props.seasonalEventsData.map((item, index) => (
                                        <div className='bonus-event-m-card' key={index}>
                                            <img src={img[`${item.img}.webp`]} alt={item.img} />
                                            <div className="bonus-event-m-content">
                                                <p>{t(`seasonal-event.${item.name}`)}</p>
                                                {item.url === null ? (
                                                    !item.redeem ? (
                                                        <button
                                                            className="btn btn-gradient-blue"
                                                            type="button"
                                                            onClick={() => (item.redeem ? this.props.applyBonusHandler(item.name) : {})}
                                                            disabled={item.redeem ? false : true}
                                                        >
                                                            {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-gradient-blue"
                                                            onClick={this.props.sesonalClaimHandler}
                                                        >
                                                            {t('bonus.redeem')}
                                                        </button>
                                                    )
                                                ) : (
                                                    <Link className="btn btn-gradient-blue" to={{ pathname: `${item.url}` }}>
                                                        {t('bonus.redeem')}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade show active" id="special-event" role="tabpanel">
                                    {this.props.specialEventsData && this.props.specialEventsData.map((item, index) => (
                                        <div className={`bonus-event-m-card w-border ${item.url === null && item.redeem === false && "w-bg"} ${this.props.currentUser.language_code === "id" ? item.new && "new-events-id" : item.new && "new-events"}
                                        `} key={index}>
                                            <div className="bonus-event-m-content">
                                                <img className="active" src={`${s3ImgUrl}/${item.img}.svg`} alt={item.img} />
                                                <p>{t(`transaction-type.${item.name}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}&nbsp;
                                                <span>
                                                    {
                                                        (item.name === 'rescue-sport') ? `(${(item.name === 'rescue-sport' && item.percent) || 0}%)` 
                                                        : (item.name === 'rescue-casino') ? `(${(item.name === 'rescue-casino' && item.percent) || 0}%)`
                                                        : (item.name === 'rescue-slot') ? `(${(item.name === 'rescue-slot' && item.percent) || 0}%)`
                                                        : null
                                                    }
                                                </span>
                                                </p>
                                                {
                                                    (item.url === null) 
                                                    ?
                                                        <button className="btn btn-gradient-blue" type="button" onClick={() => (item.redeem) ? this.props.applyBonusHandler(item.name) : {}} disabled={(item.redeem) ? false : true}>
                                                            {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                        </button> 
                                                    : (item.url === "modal") ?
                                                        <button className="btn btn-gradient-blue" type="button" onClick={ item.redeem ?  this.props.toggleBonusCodeModal : ""} disabled={(item.redeem) ? false : true}>
                                                        {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                        </button> 
                                                    : 
                                                        <Link className="btn btn-gradient-blue" to={{ pathname: `${item.url}` }}>
                                                            {t('bonus.redeem')}
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="long-term-event" role="tabpanel">
                                    {this.props.longEventsData && this.props.longEventsData.map((item, index) => (
                                        <div className={`bonus-event-m-card w-border ${item.url === null && item.redeem === false && "w-bg"} ${this.props.currentUser.language_code === "id" ? item.new && "new-events-id" : item.new && "new-events"}`} key={index}>
                                            <div className="bonus-event-m-content">
                                                <img className="active" src={`${s3ImgUrl}/${item.img}.svg`} alt={item.img} />
                                                <p>
                                                    {t(`transaction-type.${item.name}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}&nbsp;
                                                    <span>
                                                        {
                                                            (item.name === 'wallet-balance-interest') ? `(${item.bonus || 0}%)` 
                                                            : (item.name === 'welcome-bonus') ? `(${(item.name === 'welcome-bonus' && item.bonus) || 0}%)`
                                                            : (item.name === 'daily-bonus') ? `(${(item.name === 'daily-bonus' && item.bonus) || 0}%)`
                                                            : (item.name === 'sports-rebate') ? `(${(item.name === 'sports-rebate' && item.bonus) || 0}%)`
                                                            : (item.name === 'casino-rebate') ? `(${(item.name === 'casino-rebate' && item.bonus) || 0}%)`
                                                            : (item.name === 'slots-rebate') ? `(${(item.name === 'slots-rebate' && item.bonus) || 0}%)`
                                                            : (item.name === 'games-rebate') ? `(${(item.name === 'games-rebate' && item.bonus) || 0}%)`
                                                            : null
                                                        }
                                                    </span>
                                                </p>
                                                {
                                                    // null, if button is onClick, not redirecting
                                                    (item.url === null) 
                                                    ?
                                                        <button className="btn btn-gradient-blue" type="button" onClick={() => (item.redeem) ? this.props.applyBonusHandler(item.name) : {}} disabled={(item.redeem) ? false : true}>
                                                            {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                        </button> 
                                                    :
                                                        <Link className="btn btn-gradient-blue" to={{ pathname: `${item.url}` }}>
                                                            {t('bonus.redeem')}
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bonus-btn-col">
                        <button className="btn btn-gradient-blue" type="button" onClick={() => undefined}>
                            {t('bonus.quick-redeem')}
                        </button>
                    </div> */}
                </section>
            </Auxiliary>
        );
    }
}

export default (withNamespaces("translation")(MobileBonus));
