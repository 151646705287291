import { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import slugConf from "../../../common/data/games.json";
import { withNamespaces } from "react-i18next";
class GameHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            vendorList: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[shouldComponentUpdate]",nextProps);
        if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
            if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
                let vendorList = nextProps.vendor.data.vendor_list;
                let gameVendorList = [];
                if (vendorList.length > 0) {
                    vendorList.map((item, idx) => {
                        if (item.game_type_slug === "ga") {
                            gameVendorList.push(item);
                        }
                        return null;
                    });
                }
                this.setState({
                    vendorList: gameVendorList,
                    loading: false
                })
            }
        }
        return true;
    }

    render() {
        const { t } = this.props;
        let imgDropdown = this.props.imgDropdown;
        let games = this.state.vendorList ? this.state.vendorList : [];
        return (
            <>
                {(!this.state.loading && games) &&
                    games.slice(0, 11).map((item, index) => {
                        if (item.maintenance_status === 0) {
                            let link = ((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || '');
                            return (
                                <Link
                                    to={{ pathname: `/games/${link}`, state: { vendorSlug: item.game_vendor_slug } }}
                                    className="col-2"
                                    key={index}
                                >
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <img className="dropdown-img" src={imgDropdown[`gm-${item.game_vendor_slug}-girl.webp`]} alt="Dropdown" />
                                </Link>
                            );
                        }
                        else {
                            return (
                                <div key={index} className="col-2 under-maintenance">
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <img className="dropdown-img" src={imgDropdown[`gm-${item.game_vendor_slug}-girl.webp`]} alt="Dropdown" />
                                </div>
                            );
                        }
                    })}
                {
                    games.length >= 11 && (
                        <div className="col-2">
                            <NavLink className="btn more-btn-blue" to={{pathname:"/games"}} >{t("page-header.more-games")}</NavLink>
                        </div>
                    )
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vendor: state.general.vendor
    }
}

export default connect(mapStateToProps)(withNamespaces("translation")(GameHeader));