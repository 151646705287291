import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
// import Logo from "../../assets/images/merchant-logo.png";
// import EventLogo from "../../assets/images/logo-raya.png";

import { Vertify } from "@alex_xu/react-slider-vertify";
import ValidationError from "../../components/ValidationError/ValidationError";
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import SideBar from '../../components/SideBar/SideBar';


// Check country to limit the contact no fields
let contact_no_min_limits = 9;
// let contact_no_max_limits = 10;

class MobileRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      recaptcha_status: false,
    };
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/register/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const flag = this.imageImport(
      require.context(
        "../../assets/images/flags/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const recaptchaImg = this.imageImport(
      require.context(
        "../../assets/images/recaptcha/",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        {this.state.isLoading && <LoadingPage />}
        {/* <video
          className="login-video mobile"
          allowsInlineMediaPlayback="true"
          autoPlay
          loop
          muted
          controls={false}
          playsInline
        >
          <source src={BackVideo} type="video/mp4" />
        </video> */}
        <section className="register-section register-m-section">
        <SideBar />
        {/* <img src={img[`reglogin-m-bg.webp`]} alt="login register image" className="logreg-img" /> */}
         <img src={img[`reglogin-m-bg${imageLanguage}.webp`]} alt="login register image" className="logreg-img" />
          <div className="x-container-no-m">

            <div className="register-form">
              {/* {this.props.currentEvent === "raya" ? (
                <img src={EventLogo} alt="logo" className="logo-image" />
              ) : (
                <img src={Logo} alt="logo" className="logo-image" />
              )} */}
             
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/login">
                    {t("page-header.login")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/register">
                    {t("page-header.register")}
                  </a>
                </li>
              </ul>
              <form
                method="post"
                className="member-form"
                name="member_register_from"
                id="member_register_from"
                onSubmit={this.props.postMemberRegister}
                noValidate
              >
                <ValidationError
                  formName="member_register_from"
                  touched={this.props.touched}
                  message={this.props.error_message}
                />
                <div style={{width:0,height:0}}>
                  <input style={{width:0,height:0,opacity:0}} id="email_" name="email_" type="email" value={this.props.formFilter.email_} />
                </div>
                {/* username */}
                <div className="input-group">
                  <span className="input-group-text left-icon">
                    <img src={img["icon-user.webp"]} alt="username icon" />
                  </span>
                  <StringInput
                    className={`form-control ${
                      this.props.errorMsg.username && "was-validated is-invalid"
                    }`}
                    id="username"
                    name="username"
                    required
                    requiredMessage={t("validation.required")}
                    placeholder={t("register.username")}
                    value={this.props.formFilter.username}
                    onChange={this.props.onInputChange}
                    onBlur={this.props.handleCheckUsername}
                    autoComplete="off"
                    errorMsg={this.props.errorMsg.username || ""}
                    formError={this.props.formError}
                  />
                </div>
                {this.props.check_username === false && (
                  <small className="caution-text mobile">
                    <img
                      src={img["icon-caution.webp"]}
                      alt="username caution"
                    />
                    {t("register.username-name-exist")}
                  </small>
                )}
                {/* password */}
                <div className="input-group">
                  <span className="input-group-text left-icon">
                    <img src={img["icon-password.webp"]} alt="password icon" />
                  </span>
                  <PasswordInput
                    showPwd={this.props.formFilter.showPwd}
                    id="pwd"
                    name="pwd"
                    className={`no-border form-control ${
                      this.props.errorMsg.password && "was-validated is-invalid"
                    }`}
                    placeholder={t("register.password")}
                    autoComplete="off"
                    required
                    requiredMessage={t("validation.required")}
                    minLength={6}
                    minLengthMessage={t("validation.minLength", { 0: 6 })}
                    maxLength={20}
                    maxLengthMessage={t("validation.minLength", { 0: 20 })}
                    value={this.props.formFilter.pwd}
                    onChange={(e) => this.props.updatePassword(e.target.value)}
                    errorMsg={this.props.errorMsg.password || ""}
                    formError={this.props.formError}
                    format={
                      <span className=" input-group-text">
                        <button
                          type="button"
                          className="right-icon"
                          onClick={this.props.showPwd}
                        >
                          {this.props.formFilter.showPwd ? (
                            <img
                              src={img["icon-eye-open.webp"]}
                              alt="show pwd"
                            />
                          ) : (
                            <img
                              src={img["icon-eye-close.webp"]}
                              alt="hide pwd"
                            />
                          )}
                        </button>
                      </span>
                    }
                  />
                </div>
                <div className="pi-sec-lvl-col">
                  <div className="pi-sec-lvl-content">
                    <div
                      className={`pi-sec-lvl-bar ${this.props.formFilter.pwdStrength}`}
                    >
                      <small>
                        {this.props.formFilter.pwdStrength === "low"
                          ? t("register.low")
                          : this.props.formFilter.pwdStrength === "normal"
                          ? t("register.normal")
                          : t("register.strong")}
                      </small>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                {/* re-enter password */}
                <div className="input-group">
                  <span className="input-group-text left-icon">
                    <img
                      src={img["icon-password.webp"]}
                      alt="reenter password icon"
                    />
                  </span>
                  <PasswordInput
                    showPwdConfirmation={
                      this.props.formFilter.showPwdConfirmation
                    }
                    id="pwdConfirmation"
                    name="pwdConfirmation"
                    className={`no-border form-control ${
                      this.props.errorMsg.confirm_password &&
                      "was-validated is-invalid"
                    }`}
                    // className="form-control no-border"
                    placeholder={`${t("register.re-enter")}${t(
                      "register.password"
                    )}`}
                    autoComplete="off"
                    required
                    requiredMessage={t("validation.required")}
                    value={this.props.formFilter.pwdConfirmation}
                    oldPassword={this.props.formFilter.pwd}
                    onChange={this.props.onInputChange}
                    confirmpassword={this.props.formFilter.pwdConfirmation}
                    minLength={6}
                    minLengthMessage={t("validation.minLength", { 0: 6 })}
                    maxLength={20}
                    maxLengthMessage={t("validation.minLength", { 0: 20 })}
                    errorMsg={this.props.errorMsg.confirm_password || ""}
                    formError={this.props.formError}
                    format={
                      <span className="input-group-text ">
                        <button
                          type="button"
                          className="right-icon"
                          onClick={this.props.showPwdConfirmation}
                        >
                          {this.props.formFilter.showPwdConfirmation ? (
                            <img
                              src={img["icon-eye-open.webp"]}
                              alt="show pwd"
                            />
                          ) : (
                            <img
                              src={img["icon-eye-close.webp"]}
                              alt="hide pwd"
                            />
                          )}
                        </button>
                      </span>
                    }
                  />
                </div>

                {!this.props.mobileSelectionLoading && (
                  <div className="input-group position-relative reg-and-log-select mobile">
                    <ReactSelect
                      classNamePrefix="react-select"
                      defaultValue={this.props.mobileSelectionLabelling.filter(
                        (country) =>
                          country.code === this.props.mobileSelectionShortcode
                      )}
                      options={this.props.contactNoArry}
                      // options={
                      //   this.props.referral
                      //     ? this.props.contactNoArry.filter(
                      //         (contact) =>
                      //           contact.value ===
                      //           this.props.mobileSelectionPrefix
                      //       )
                      //     : this.props.contactNoArry
                      // }
                      onChange={this.props.handleCountryContactNo}
                      formatOptionLabel={(item) => (
                        <>
                          <img src={flag[`${item.image}`]} alt={item.image} />
                          <p>{item.value}</p>
                          <p>{item.name}</p>
                        </>
                      )}
                    />
                    <StringInput
                      className={`${
                        this.props.errorMsg.contact_number &&
                        "was-validated is-invalid"
                      }`}
                      onChange={this.props.onInputChange}
                      name="contact_no"
                      id="contact_no"
                      placeholder="123456789"
                      value={this.props.formFilter.contact_no}
                      minLength={contact_no_min_limits}
                      maxLength={this.props.defaultMobileMaxLimit}
                      minLengthMessage={
                        this.props.errorMsg.contact_number
                          ? ""
                          : t("validation.minLength", {
                              0: contact_no_min_limits,
                            })
                      }
                      maxLengthMessage={
                        this.props.errorMsg.contact_number
                          ? ""
                          : t("validation.maxLength", {
                              0: this.props.defaultMobileMaxLimit,
                            })
                      }
                      required
                      requiredMessage={t("validation.required")}
                      errorMsg={this.props.errorMsg.contact_number || ""}
                      formError={this.props.formError}
                    />
                  </div>
                )}

                {/* recapcha */}
                {/* <button className="register-recaptcha" type="button" data-bs-toggle="modal" data-bs-target="#recaptcha-modal"> */}
                <button
                  className="register-recaptcha"
                  type="button"
                  data-bs-toggle={this.props.visible ? "modal" : ""}
                  data-bs-target="#recaptcha-modal"
                >
                  <span>
                    {!this.props.visible ? (
                      <img
                        src={img["icon-robot-select.webp"]}
                        alt="recaptcha select"
                      />
                    ) : (
                      <img
                        src={img["icon-robot-unselect.webp"]}
                        alt="recaptcha unselect"
                      />
                    )}
                    <span >
                      {t("register.i-not-a-robot")}
                    </span>
                  </span>
                </button>
                {/* checklist*/}
                <div className="checklist">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="tnc_check"
                    username="tnc_check"
                    value={!this.props.formFilter.tnc_check}
                    onChange={this.props.onInputChange}
                    required
                  />
                  <small
                    className="form-check-label px-2"
                    htmlFor="flexCheckChecked"
                  >
                    {t("register.tnc")}
                  </small>
                </div>
                <div
                  className="modal recaptcha-modal fade "
                  id="recaptcha-modal"
                  tabIndex="-1"
                  aria-labelledby="recaptcha-modalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      {!this.props.recaptcha_loading && (
                        <Vertify
                          width={720}
                          height={480}
                          l={84}
                          r={18}
                          visible={this.props.visible}
                          id="recaptcha_status"
                          name="recaptcha_status"
                          // value={!this.state.formFilter.recaptcha_status}
                          // onSuccess={this.props.recaptchaSuccess}
                          // onFail={() => alert("fail")}
                          // onRefresh={() => alert("refresh")}
                          onSuccess={() =>
                            this.props.recaptchaSuccess("success")
                          }
                          onFail={() => this.props.recaptchaSuccess("failed")}
                          onRefresh={() => this.props.recaptchaSuccess()}
                          imgUrl={
                            recaptchaImg[
                              `${this.props.recaptcha_image_starting_point}.jpg`
                            ]
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <button
                  className={`btn btn-blue ${
                    this.props.formSubmit === false ? `disabled` : ``
                  }`}
                  type="submit"
                  form="member_register_from"
                  value="Submit"
                >
                  {t("register.register-button-text")}
                </button>
                {/* login now / skip and enter/ contact us*/}
                <div className="d-flex justify-content-center small-w">
                  <div className="skip-btn">
                    <Link to={{ pathname: "/" }}>
                      <small>{t("register.skip-and-enter")}</small>
                    </Link>
                  </div>
                </div>

              {/*google and tele login*/}
              <div className="text-center bottom-content small-w">
              <small>
                        <span className="line">{t("register.or")}</span>
                      </small>
                      <small className="small-w">
                        {t("register.login-with")} :
                      </small>


                <div className="google-tele-bg ">
                  <button
                    type="button"
                    onClick={this.props.handleGoogleRedirect}
                  >
                    <img src={img["icon-google.webp"]} alt="google logo" />
                  </button>
                  <button type="button" onClick={this.props.handleTelegram}>
                    {" "}
                    <img src={img["icon-telegram.webp"]} alt="telegram logo" />
                  </button>
                </div>
              </div>
              {/* contact us*/}
              <div className="text-center contact-us-btn small-w">
                <Link to={{ pathname: "/contact-us" }}>
                  <img
                    src={img["icon-contact-us.webp"]}
                    alt="contact us icon"
                  />
                  <small> {t("footer.contact-us")} </small>
                </Link>
              </div>
              </form>

            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default withNamespaces("translation")(MobileRegister);
