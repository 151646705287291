import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Modal from "react-modal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
// import { GoInfo } from "react-icons/go";
import { default as ReactSelect } from "react-select";
import { connect } from "react-redux";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
import DataTable from "../../../components/Datatable/DataTable";
// import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";

//Icons
import { Icon } from "@iconify/react";
import { numFormat } from "../../../hoc/Shared/utility";

class DepositWithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray("withdrawal");
            this.props.funcGetList(listingArr);
        }
    }

    renderTooltip(props, remark) {
        // const { t } = this.props;
        return (
            <Tooltip className="status-tooltip" {...props}>
                {remark}
            </Tooltip>
        );
    }

    render() {
        const { t } = this.props;
        let rsData = [];
        let stCls = { 0: "on-going", 1: "success", 2: "failed", 3: "on-going", 4: "failed" };
        let stVal = { 0: t("history.pending"), 1: t("history.success"), 2: t("history.reject"), 3: t("history.processing"), 4: t("history.cancel") };
        const num_format = numFormat(this.props.currentUser.currency);

        // let promoList = this.props.data.promoList ? this.props.data.promoList : [];
        let promoList = [];
        if (this.props.data.promoList && this.props.openModal) {
            promoList = this.props.data.promoList.map((val, idx) => {
                return {
                    id: val.id,
                    label: val.title,
                    key: idx,
                };
            });
        }
        let validPromo = this.props.data.validPromo;

        const columns = [
            // {text: 'No', dataField: "no", formatter: this.noFormatter, headerAttrs: { hidden: true }},
            { text: t("history.date"), dataField: "create_date" },
            { text: t("history.order-id"), dataField: "order_id"},
            { text: t("history.amount"), dataField: "credit" },
            { text: t("history.method"), dataField: "method" },
            { text: t("history.type"), dataField: "transaction_type" },
            { text: t("history.approve-time"), dataField: "approval_time" },
            { text: t("history.status"), dataField: "status" },
            { text: t("global.action"), dataField: "action" },
            // { text: t("history.status"), dataField: "status"},
        ];

        if (this.props.data.data) {
            rsData = this.props.data.data.map((val, idx) => {
                let actionVal =
                    val.transaction_type_id === 2 && val.status === 0 ? (
                        <button className="btn btn-action" onClick={(event) => this.props.cancelHandler(event, val.id)}>
                            {t("history.cancel")}
                        </button>
                    ) : (validPromo && validPromo !== undefined && validPromo.valid_apply === 1 && val.id === validPromo.id) ? (
                        <button className="btn btn-action" onClick={(event) => this.props.promoModalHandler(event, val.id)}>
                            {t("global.edit")}
                        </button>
                    ) : (
                        "-"
                    );

                let promoVal = <span className={`${stCls[val.status]}`}>{stVal[val.status]}</span>

                return {
                    no: idx,
                    create_date: moment(val.create_date).format("YYYY-MM-DD H:mm"),
                    credit: val.transaction_type_id === 1 ? new Intl.NumberFormat("en-US", num_format).format(val.credit) : new Intl.NumberFormat("en-US", num_format).format(val.debit),
                    method: t(`transaction-channel.${val.transaction_channel}`),
                    transaction_type: t(`transaction-type.${val.transaction_type}`),
                    action: actionVal,
                    status:
                        // val.status === 2 ? (
                        //     <span role="button" className={` status ${stCls[val.status]}`}>
                        //         {stVal[val.status]}
                        //     </span>
                        // ) : (
                        //     <>
                        //         {promoVal}
                        //     </>
                        // ),
                        val.status === 2 ? (
                            <OverlayTrigger placement="left" overlay={(props) => this.renderTooltip(props, val.remark)}>
                                <span role="button" className={` status ${stCls[val.status]}`}>
                                    {/* <GoInfo className="me-1" /> */}
                                    <Icon icon="mdi:alert-circle-outline"/>
                                    {stVal[val.status]}
                                </span>
                            </OverlayTrigger>
                        ) : (
                            <>
                                {promoVal}
                            </>
                        ),
                    order_id: val.order_id,
                    approval_time: val.approval_time
                };
            });
        }

        return (
            <Auxiliary>
                <Fade duration={1000} triggerOnce>
                    <DataTable
                        keyField="no"
                        data={rsData}
                        columns={columns}
                        activePage={this.props.data.activePage}
                        limit={this.props.data.limit}
                        totalItems={this.props.data.totalRecords}
                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "activePage")}
                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "limit")}
                        loading={this.props.data.loading}
                        hideSearchBar={true}
                        hideTotalRecords={true}
                        classValue="pd-table"
                        component="transaction-history"
                    />
                </Fade>

                {this.props.openModal && (
                    <Modal
                        isOpen={this.props.openModal}
                        closeTimeoutMS={500}
                        portalClassName={`global-modal`}
                        className="modal modal-dialog modal-dialog-centered modal-pd"
                        ariaHideApp={false}
                        onRequestClose={(event) => this.props.promoModalHandler(event)}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t('history.select-promotion')}</h5>
                                <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.props.promoModalHandler(event)} />
                            </div>
                            <div className="modal-body">
                                <h6>Promotion</h6>
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        {/* <Select
                                            id="promo_id"
                                            name="promo_id"
                                            className="form-control form-select history-promos"
                                            getOptionValue="id"
                                            getOptionLabel="title"
                                            options={promoList}
                                            // onChange={(event) => this.props.applyPromoHandler(event, this.state.select_id)}
                                            onChange={(event) => this.props.onChangeHandler(event)}
                                            value={this.props.data.promo_id}
                                            emptyValue={true}
                                        /> */}
                                        <ReactSelect
                                            classNamePrefix="react-select"
                                            placeholder={t("history.select-promotion")}
                                            name="promo_id"
                                            options={promoList}
                                            // onChange={(event) => this.props.applyPromoHandler(event, this.state.select_id)}
                                            onChange={(event) => this.props.onPromoInputChangeHandler(event, 'promo_id')}
                                            // value={this.props.data.promo_id}
                                            // value={(this.props.promo_key !== null) ? promoList[this.props.promo_key] : ''}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-blue"
                                    buttonName={t("global.apply")}
                                    clicked={(event) => this.props.applyPromoHandler(event, this.props.data.select_id, this.props.data.promo_id)}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DepositWithdrawal));
