import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {};

const christmasReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.CLAIM]: (state, action) => ({
        ...state,
        claim_result: action.value,
    }),
    [type.MOBILE_APPS_PROMO_CLAIM]: (state, action) => ({
        ...state,
        mobile_apps_promo_claim: action.value,
    })
});

export default christmasReducer;
