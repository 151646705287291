import React, { Component } from 'react'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
// import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';
import SideBar from '../../components/SideBar/SideBar';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';
import moment from 'moment';
export class MobileCheckIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {

    const { t } = this.props;
    const img = this.imageImport(require.context("../../assets/images/check-in", false, /\.(png|jpe?g|svg|webp)$/));
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        <SideBar />
        <div className="banner-col">
          <div className="banner-item">
            <img src={banner[`check-in-banner${imageLanguage}.webp`]} />
          </div>
        </div>
        <section className='check-in-section p-1'>
          {/* <MobileSubHeader
            className="bg-white"
            returnPage={'/'}
            header={t('check_in.title_1')}
            style={{ justifyContent: "unset" }}
          /> */}
          <div className='p-3'>

            <div className='section-header-gray'>
              <p>{t('check_in.title_2')}</p>
              {(this.props.start_day && this.props.end_day) &&
                <p className='event-date'>{t('check_in.time_1')}：
                  <span>{this.props.start_day}</span>
                  <span className='px-2'>{t('check_in.time_3')}</span>
                  <span>{this.props.end_day}</span>
                </p>
              }
            </div>

            {this.props.checkInDay.length > 0 &&
              <div className='check-in-m-container'>
                <div className='row g-2'>
                  {
                    this.props.checkInDay && this.props.checkInDay.map((item, index) => (
                      <div className='col-3'>
                        <div className={`check-in-col ${item.active ? 'active' : ''} ${item.current ? 'current' : ''}`} key={index}>
                          <h5>{item.label}</h5>
                          {
                            index.id === "extra-bonus" ? <img src={img['more-coin.webp']} alt="coin" /> :
                              item.claim === true ? <img src={img['silver-coin.webp']} alt="coin" /> :
                                item.claim === false && <img src={img['golden-coin.webp']} alt="coin" />
                          }
                          <p className='number'>{item.amount}</p>
                          {
                            !item.isLogin ? (
                              <Link className='btn btn-gradient-blue' to={{ pathname: "/login" }}>
                                <span>{t("check_in.login_to_claim")}</span>
                              </Link>
                            ) : (
                              item.enable ? (
                                <button className='btn btn-gradient-blue' onClick={() => this.props.claimBonusHandler(item.hash, item.turnover, item.date, item.bonus)} >
                                  <span>{t("check_in.claim")}</span>
                                </button>
                              ) : (
                                <>
                                  {
                                    item.claim ? (
                                      <>
                                        <button className='btn btn-gray' disabled={true}>
                                          <span>
                                            {t("check_in.claimed")}
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button className='btn btn-gray' data-tip data-for='claim' disabled={moment().format("YYYY-MM-DD") === item.date ? false : true}>
                                          <span>
                                            {t("check_in.claim")}
                                          </span>
                                        </button>
                                        <ReactTooltip id='claim' type='info' effect='solid' className="custom-tooltip">
                                          <span>{t("check_in.not_acheive_turnover_amount")}</span>
                                        </ReactTooltip>
                                      </>
                                    )
                                  }
                                </>
                              ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {this.props.table1.length > 0 &&
              <div className='events-table-container card-shadow mt-3'>
                <table className='table table-borderless events-table'>
                  <thead>
                    <tr>
                      <th>{t("check_in.today-total-bet")}</th>
                      <th>{t("check_in.reward-today")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.table1 && this.props.table1.map((item, index) => (
                        <tr key={index}>
                          <td className='number'>{item.amount}</td>
                          <td className='number'>{item.reward}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            }

            {this.props.table2.length > 0 &&
            <div className='events-table-container card-shadow mt-3'>
              <table className='table table-borderless events-table'>
                <thead>
                  <tr>
                    <th>{t("check_in.average-bet")}</th>
                    <th>{t("check_in.full-check-in")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.table2 && this.props.table2.map((item, index) => (
                      <tr key={index}>
                        <td className='number'>{item.amount}</td>
                        <td className='number'>{item.reward}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              </div>
            }

            <div className='blue-bg-notice mt-3'>
              <div>
                <p>{t("check_in.rule-header")}</p>
                <ol className='mt-3 ps-3'>
                  <li>{t("check_in.rule-1")}</li>
                  <li>{t("check_in.rule-2")}</li>
                  <li>{t("check_in.rule-3")}</li>
                  <li>{t("check_in.rule-4")}</li>
                  <li>{t("check_in.rule-5")}</li>
                  <li>{t("check_in.rule-6")}</li>
                </ol>
              </div>

            </div>

            <div className='blue-bg-notice mt-3'>
              <div>
                <p>{t("check_in.mention-1")}</p>
                <p className='mt-4'>{t("check_in.mention-2")}</p>
                <p>{t("check_in.mention-3")}</p>
                <p>{t("check_in.mention-4")}</p>
              </div>
            </div>

            {/* <div className='event-time-table mt-3'>
              <table className='table table-borderless card-shadow events-table'>
                <thead>
                  <tr>
                    <th className='event-session'>{t("check_in.event-periods")}</th>
                    <th>{t("check_in.event-date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.table3 && this.props.table3.map((item, index) => (
                      <tr key={index}>
                        <td className='event-session'>{item.session}</td>
                        <td>{item.date}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div> */}

            {/* <div className='blue-bg-notice mt-3'>
              <div>
                <p>{t("check_in.mention-5")}</p>
              </div>
            </div> */}

          </div>
        </section>

      </Auxiliary >

    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  }
}

export default connect(mapStateToProps)(withNamespaces("translation")(MobileCheckIn));