import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const announcementReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name

    [type.SHOW_ANNOUNCEMENT]: (state, action) => ({
        ...state,
        show_announcement_data: action.payload,
    }),

});

export default announcementReducer;