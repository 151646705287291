import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getData = () => (dispatch, getState) => {
  let data = {};
  data.member_id = getState().currentUser.data.id;
  data.country_id = getState().currentUser.data.country_id;
  data.language_code = getState().currentUser.data.language_code;
  axios.get('gold_digger/index', {params: data}).then(function (response) {
    dispatch ({
      type: actionType.INDEX,
      value: response.data
    })
  }).catch(err => {
    console.log(err);
  });
};

export const claim = (data) => dispatch => {
  axios.post('gold_digger/claim', data).then( (response) => {
    dispatch ({
        type: actionType.CLAIM,
        value: response.data
    })
  }).catch (err => {
    console.log(err);
  })
};