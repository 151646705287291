import { Component } from "react";
import { connect } from "react-redux";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { detectCountry } from "../../hoc/Shared/utility";

class CountrySetting extends Component {
    constructor(props){
        super(props);
        this.state = {
            login: localStorage.getItem("accessToken") ? true : false,
            changeLang: localStorage.getItem("changeCountry") ? true : false
        }
    }

    componentDidMount(){
        // console.log("System props", this.props.currentUser, this.props.location, localStorage.getItem("changeCountry"), localStorage.getItem("accessToken"));
        if(!this.state.login && !this.state.changeLang){
            this.checkDomain();
        }
        else{
            // changed lang or logged in, do ntg for now
        }
    }

    checkDomain = () => {
        // console.log("run domain check");
        let dynamicCountry = detectCountry(window.location.host);
        let domainContent = dynamicCountry.data;

        const data = {};
        // if domain didnt specify in json file, default show country MY data regardless of domain
        if(domainContent === null){
            data.country_code = "MY";
            data.country_id = 1;
            data.language_code = "en";
            data.language_id = 1;
            data.time_zone = "Asia/Kuala_Lumpur";
            data.member_group_id = 1;
        }
        else{
            data.country_code = domainContent[0];
            data.country_id = domainContent[1];
            data.language_code = domainContent[2];
            data.language_id = domainContent[3];
            data.time_zone = domainContent[4];
            data.member_group_id = domainContent[5];
        }
        this.props.getUserData(data);
    }

    render(){
        // console.log(this.props.currentUser);
        // do not render anything here
        return null;
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const mapDispatchToProps = {
    getUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySetting);