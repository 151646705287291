import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

//Miscellaneous
import { isDesktop } from "react-device-detect";
// import moment from "moment-timezone";
// import Skeleton from "react-loading-skeleton";
// import Timer from "react-compound-timer";
import { Fade } from "react-awesome-reveal";

//Components
// import MobileSubHeader from '../../../components/PageHeader/MobileSubHeader';
// import DateScroller from '../../../components/MobileAsset/DateScroller/DateScroller';
// import Button from '../../../components/Button/Button';
import GamePagination from "../../../components/Pagination/GamePagination";
import NoRecords from "../../../components/NoRecords/NoRecords";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { currency } from '../../../common/config/currency';

//Icons
import { Icon } from "@iconify/react";

export class FIfaQna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterShow: '',
      tableShow: "",
    };
  }

  componentDidMount() {
      if(!this.props.disableSearchButton) {
          const listingArr = this.props.getFilterArray('fifa_qna');
          this.props.funcGetList(listingArr);
      }
  }

  showTypeHandler = () => {
    this.setState({
      filterShow: 'type'
    })
  }

  showDateHandler = () => {
    this.setState({
      filterShow: 'date'
    })
  }


  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  }

  render() {
    const { t } = this.props;
    const flag = this.imageImport(require.context("../../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              {this.props.fifa_qna.data === undefined || this.props.fifa_qna.data.length <= 0
                ?
                <NoRecords component={''} />
                :
                <Fade duration={1000} triggerOnce>
                  <table className="pd-table rh-pd-table fifa-rh">
                    {this.props.fifa_qna.data && this.props.fifa_qna.data.map((item, index) =>
                        <tbody key={index} className="rdp-his-body">
                          <tr className="rdp-his-title ">
                            <td colSpan={4}>
                              <p className="ms-2"><Icon icon="ic:outline-event-note" width="1rem" height="1rem" /> {item.date}</p>
                            </td>
                            <td className='text-center'>{t('redemption-history.fifa_qna.correct_answer')}</td>
                            <td className='text-center'>{t('redemption-history.fifa_qna.winning_amount')}</td>
                          </tr>
                          <tr className="rdp-his-content">
                            <td colSpan={4}>
                              <h6 className='mb-0'>{t('redemption-history.fifa_qna.no_of_submission')}: {index+1}</h6>
                              <span style={{ cursor: 'pointer' }} data-bs-toggle="collapse" data-bs-target={`#collapseTab${index}`}>{t('redemption-history.fifa_qna.view_question')} <Icon className="turn-btn" icon={"uil:angle-down"} /></span>
                            </td>
                            <td className='text-center'><span className='btn-outline-blue'> {item.totalCorrect} / 9</span></td>
                            <td className='text-center'>
                              <span className="green-font" >{currency[this.props.currentUser.country_id]} {item.winAmount}</span>
                            </td>
                          </tr>
                          <tr id={`collapseTab${index}`} className="collapse rdp-his-content">
                            <td colSpan={6}>
                              <ul>
                                <li>
                                  <span className='left-head'>
                                    {t('redemption-history.fifa_qna.total_correct_answer_message').replace('##no##',item.totalCorrect)}
                                  </span>
                                </li>
                                {item.betData && Object.values(item.betData).map((betData, betDataIndex) =>
                                  <li key={betDataIndex}>
                                    <div className='left-head'>
                                      <div className={`left-content ${betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ? "green-font" : "red-font"}`}>
                                        <p>{this.props.fifa_qna.questionArray[betDataIndex].question}</p>
                                        <div className='d-flex justify-content-between'>
                                          <div>
                                            <span className='team-content'><img src={flag[`${betData[1].toLowerCase()}.svg`]} alt={betData[1].toLowerCase()} /> {t(`world-cup.team.${betData[1].toLowerCase()}`)}</span>
                                          </div>
                                          <span className='team-content'>
                                            {betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ?
                                              <Icon icon="ep:circle-check-filled" width="1rem" height="1rem" color="#04be02" />
                                              :
                                              <Icon icon="ic:baseline-cancel" width="1rem" height="1rem" color="#ea4e3d" />
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ?
                                      ""
                                      :
                                      <div className='right-head'>
                                      <span className='team-content'><img src={flag[`${this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()}.svg`]} alt={this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()} /> {t(`world-cup.team.${this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()}`)}</span>
                                    </div>
                                    }
                                  </li>
                                )}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      )
                    }
                  </table>
                </Fade>
              }
              <GamePagination
                totalPage={this.props.data.totalPage}
                curPage={this.props.data.activePage}
                data={this.props.data.data}
                navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'lucky_spin')}
              />
            </>
          ) : (
            <section className="fifa-two-qna">
              {this.props.fifa_qna.data === undefined || this.props.fifa_qna.data.length <= 0
                    ?
                    <NoRecords component={''} />
                    : 
              <>
                {this.props.fifa_qna.data &&
                  this.props.fifa_qna.data.map((item, index) => (
                    <div className='rh-m-pd-card' key={index} >
                      <div className='rh-m-tp-panel'>
                        <p><Icon icon="ic:outline-event-note" />{item.date}</p>
                      </div>
                      <div className='rh-m-md-panel  row mt-3'>
                        <div className='col-6 text-center'>
                          <p className='mb-2'>{t('redemption-history.fifa_qna.correct_answer')}:</p>
                          <h6 className='btn-outline-blue'> {item.totalCorrect} / 9</h6>
                        </div>
                        <div className='col-6 text-center'>
                          <p className='mb-3'>{t('redemption-history.fifa_qna.winning_amount')} </p>
                          <h6><span className="green" >{currency[this.props.currentUser.country_id]} {item.winAmount}</span>
                          </h6>
                        </div>
                        <p className='mt-3'>{t('redemption-history.fifa_qna.total_correct_answer_message').replace('##no##',item.totalCorrect)}</p>
                        <p style={{ cursor: 'pointer', color: "#3e73fa" }} data-bs-toggle="collapse" data-bs-target={`#collapseTab${index}`}>{t('redemption-history.fifa_qna.view_question')} <Icon className="turn-btn" icon={"uil:angle-down"} /></p>
                      </div>
                      <div id={`collapseTab${index}`} className="collapse">
                        <hr />
                        {item.betData && Object.values(item.betData).map((betData, betDataIndex) =>
                          <div key={index} className="team-body">
                            <div className='d-flex justify-content-between'>
                              <p className={`${betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ? "green-font" : "red-font"}`} >{this.props.fifa_qna.questionArray[betDataIndex].question}</p>
                              <span className='team-content'>
                                {betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ?
                                  <Icon icon="ep:circle-check-filled" width="1rem" height="1rem" color="#04be02" />
                                  :
                                  <Icon icon="ic:baseline-cancel" width="1rem" height="1rem" color="#ea4e3d" />
                                }
                              </span>
                            </div>
                            <div className={`${betData[1] === this.props.fifa_qna.questionArray[betDataIndex].answer[0] ? "green-font" : "red-font"}`} >
                              <span className='team-content'><img src={flag[`${betData[1].toLowerCase()}.svg`]} alt={betData[1].toLowerCase()} /> {t(`world-cup.team.${betData[1].toLowerCase()}`)}</span>
                            </div>
                            {(item.correct) === "no" ?
                              <div className='ans-content'>
                                <p>{t('redemption-history.fifa_qna.correct_answer')} :</p>
                                <span className='team-content'><img src={flag[`${this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()}.svg`]} alt={this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()} /> {t(`world-cup.team.${this.props.fifa_qna.questionArray[betDataIndex].answer[0].toLowerCase()}`)}</span>
                              </div>
                              :
                              ""
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </>
              }
            </section>
          )
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.data
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(FIfaQna));