import React, { Component } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from 'react-device-detect';
import Logo from "../../assets/images/merchant-logo.png";
// import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import DomPurify from "../../components/Sanitize/DomPurify";

class MaintenancePageMobile extends Component {

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/miscellaneous",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    // const icon = this.imageImport(
    //   require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    // );
    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <section className="miscellaneous desktop">
                <div className="x-container-no-m">
                  <div className="miscellaneous-content">
                    <div className="miscellaneous-body">
                    <img src={img["maintanance.webp"]} alt="maintenance" className="left-image"/>
                    </div>
                    <div className="miscellaneous-body">
                    <img src={Logo} alt="logo" className="logo-image" />
                    <p className="my-3">
                    <DomPurify text={t("miscellaneous.maintenance", { attribute: process.env.REACT_APP_MERCHANT_EMAIL }).replace("##start_date##", '2024-Jul-25 05:00AM').replace("##end_date##", '2024-Jul-25 06:30AM')} />
                    </p>
                    <a className="btn enter-btn-blue" href={`mailto:${process.env.REACT_APP_MERCHANT_EMAIL}`} target="_blank" rel="noreferrer">
                      {t("contact-us.contact-us")}
                    </a>
                    {/* <Link className="btn enter-btn-blue"
                      to={{ pathname: "/contact-us" }}>
                      {t("contact-us.contact-us")}
                    </Link> */}
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <section className="miscellaneous mobile">
            {/* <SideBar /> */}
            <div className="m-container">
              <div className="miscellaneous-content">
              <img src={Logo} alt="logo" className="logo-image" />
                <img src={img["maintanance.webp"]} alt="Maintainance" />
                <p><DomPurify text={t("miscellaneous.maintenance", { attribute: process.env.REACT_APP_MERCHANT_EMAIL }).replace("##start_date##", '2024-Jul-25 05:00AM').replace("##end_date##", '2024-Jul-25 06:30AM')} /></p>
                <br />
                <a className="d-flex align-items-center justify-content-center btn-gradient-blue" href={`mailto:${process.env.REACT_APP_MERCHANT_EMAIL}`} target="_blank" rel="noreferrer">
                  {t("contact-us.contact-us")}
                </a>
                {/* <Link className="btn-gradient-blue"
                    to={{ pathname: "/contact-us" }}>
                    {t("contact-us.contact-us")}
                </Link> */}
              </div>
            </div>
          </section>
          )
        }
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MaintenancePageMobile));