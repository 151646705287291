import React, { Component } from "react";
import { connect } from "react-redux";
import { default as ReactSelect } from "react-select";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";
import Modal from "react-modal";

import DataTable from "../../../components/Datatable/DataTable";
import Button from "../../../components/Button/Button";
import { getAvailableCryptoList } from "../../../store/ducks/profile/actions";
import { addWithdrawalCryptoAddress } from "../../../store/ducks/withdrawal/actions";
import { getMemberCryptoAddress, removeMemberCryptoAddress, updateMemberWithdrawalAddress } from "../../../store/ducks/profile/actions";
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import StringInput from "../../../components/Input/StringInput/StringInput";

import MobileCryptoDetails from "./MobileCryptoDetails";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

import * as actions from "../../../store/ducks/withdrawal/actions";

let cryptoChannel = [];
const cryptoWalletType = ['TRC20-USDC', 'TRC20-USDT'];
class CryptoDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            memberCryptoAddressData: [],
            cryptoSelect: [],
            crypto: "",
            network: "",
            withdrawalAddress: "",
            totalRecords: 0,
            error_message: [],
            touched: false,

            edit_id: null,
            edit_crypto: "",
            edit_network: "",
            edit_withdrawal_address: "",
            openModal: false,
        };
    }

    componentDidMount() {
        formValidation("add_crypto_address_form");

        this.props.funcGetDropDown();
        this.props.getMemberCryptoAddress();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.memberCryptoAddressData !== this.props.memberCryptoAddressData && nextProps.memberCryptoAddressData !== undefined) {
            if (nextProps.memberCryptoAddressData.responseCode === 200) {
                // let accountHolder = nextProps.memberBankData.data.accountHolder;
                let memberCryptoAddress = nextProps.memberCryptoAddressData.data;
                // let bankDropdown = nextProps.memberBankData.data.bank;
                this.setState({
                    memberCryptoAddressData: memberCryptoAddress,
                    loading: false,
                    totalRecords: memberCryptoAddress.length,
                });

                Swal.close();
            }
        }

        if (nextProps.memberRemoveCryptoAddressResult !== this.props.memberRemoveCryptoAddressResult) {
            if (nextProps.memberRemoveCryptoAddressResult.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-delete-successful"),
                    // icon: nextProps.memberRemoveCryptoAddressResult.msgType,
                    icon: "success",
                    confirmButtonText: t("global.confirm"),
                    customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container sucess",
                              icon: "swal-success-icon",
                          }
                        : [],
                }).then((result) => {
                    this.props.getMemberCryptoAddress();
                });
            } else if (nextProps.memberRemoveCryptoAddressResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.memberRemoveCryptoAddressResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.update_crypto_address_result !== this.props.update_crypto_address_result) {
            if (nextProps.update_crypto_address_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-edit-successful"),
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container sucess",
                              icon: "swal-success-icon",
                          }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            openModal: false,
                            edit_id: null,
                            edit_bank_key: null,
                            edit_crypto: "",
                            edit_network: "",
                            edit_withdrawal_address: "",
                            touched: false,
                        });
                        this.searchHandler();
                    }
                });
            } else {
                clearFormValidation("update_member_withdrawal_address_form");
                if (nextProps.update_crypto_address_result.responseCode === 422) {
                    this.setState({
                        error_message: nextProps.update_crypto_address_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<ul>";
                    nextProps.update_crypto_address_result.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                  container: "swal-mobile-container sorry",
                                  icon: "swal-sorry-icon",
                              }
                            : [],
                    });
                }
            }
        }

        if (nextProps.store_withdrawal_crypto_address !== this.props.store_withdrawal_crypto_address) {
            const { t } = this.props;
            if (nextProps.store_withdrawal_crypto_address.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-add-successful"),
                    // icon: nextProps.store_withdrawal_crypto_address.msgType,
                    icon: "success",
                    confirmButtonText: t("global.confirm"),
                    customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container sucess",
                              icon: "swal-success-icon",
                          }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            cryptoSelect: [],
                            crypto: "",
                            withdrawalAddress: "",
                            loading: false,
                        });

                        // this.props.funcGetAvailableCrypto();
                        this.props.getMemberCryptoAddress();
                    }
                });
            } else if (nextProps.store_withdrawal_crypto_address.responseCode === 422) {
                this.setState({
                    touched: true,
                    formError: true,
                    error_message: nextProps.store_withdrawal_crypto_address.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                if (cryptoChannel.length === 0) {
                    nextProps.dropDown.data.payment_gateway &&
                        nextProps.dropDown.data.payment_gateway
                            .filter((val) => val.slug_details !== "crypto-vendor" && val.transaction_slug === "crypto" && cryptoWalletType.includes(val.slug) /*&& val.dp_status === 1*/)
                            .map((val) => {
                                cryptoChannel.push({
                                    id: val.id,
                                    label: val.slug,
                                    type: val.slug_details ? val.slug_details.toString().toLowerCase() : val.slug_details,
                                });
                            });
                }
            }
        }
        return true;
    }

    toggleAddBank = () => {
        this.setState({ showAddBank: !this.state.showAddBank });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    actionFormatter = (cell, row) => {
        let removeButton, output;

        removeButton = (
            <>
                <Button
                    typeButton="button"
                    classValue="btn icon"
                    key={`edit-${row.withdrawalAddressId}`}
                    idValue={row.withdrawalAddressId === undefined ? 0 : row.withdrawalAddressId}
                    clicked={(e) => this.openModalHandler(e, row.withdrawalAddressId, row.network, row.withdrawalAddress)}
                    buttonName={<Icon icon="uil:edit" />}
                />
                <Button
                    typeButton="button"
                    classValue="btn icon red"
                    key={`remove-${row.withdrawalAddressId}`}
                    idValue={row.withdrawalAddressId === undefined ? 0 : row.withdrawalAddressId}
                    clicked={(e) => this.removeCryptoAddressHandler(e, row.withdrawalAddressId, row.withdrawalAddress)}
                    buttonName={<Icon icon="uil:trash-alt" />}
                />
            </>
        );

        output = [removeButton];
        return output;
    };

    addCryptoWithdrawalAddressHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                      container: "swal-mobile-container confirmation",
                      icon: "swal-confirmation-icon",
                  }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.crypto = this.state.crypto.toUpperCase();
                data.network = this.state.network;
                data.withdrawal_address = this.state.withdrawalAddress;

                this.props.funcStoreWithdrawalCryptoAddress(data);
            }
        });
    };

    removeCryptoAddressHandler = (e, id, withdrawalAddress) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
            title: t("global.reminder"),
            text: t("global.remove") + " " + withdrawalAddress + "? " + t("global.deleted-cannot-recovered") + ".",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.confirm"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                      container: "swal-mobile-container reminder",
                      icon: "swal-reminder-icon",
                  }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.withdrawal_address_id = id;
                this.props.removeMemberCryptoAddress(data);
            }
        });
    };

    handleNetworkChange = (event) => {
        this.setState({
            network: event.label,
            crypto: event.type,
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    openModalHandler = (e, id, network, withdrawal_address) => {
        e.preventDefault();

        this.setState({
            openModal: true,
            edit_id: id,
            edit_network: network,
            edit_withdrawal_address: withdrawal_address,
            errorMsg: [],
            formError: false,
            showAddBank: !this.state.showAddBank,
        });
    };

    closeModalHandler = (e) => {
        e.preventDefault();

        this.setState({
            openModal: false,
        });
    };

    handleEditBankChange = (event) => {
        this.setState({
            edit_network: event.label,
            edit_bank_key: event.key,
        });
    };

    onInputChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            errorMsg: [],
            formError: false,
        });
    };

    updateWithdrawalAddresssHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                      container: "swal-mobile-container confirmation",
                      icon: "swal-confirmation-icon",
                  }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.id = this.state.edit_id;
                data.crypto = this.state.edit_network === "TRC20-USDC" ? "USDC" : "USDT";
                data.withdrawal_address = this.state.edit_withdrawal_address;

                this.props.updateMemberWithdrawalAddress(data);
            }
        });
    };

    searchHandler = () => {
        this.setState({
            loading: true,
        });
        this.props.getMemberCryptoAddress();
    };

    render() {
        const { t } = this.props;
        const cryptoImg = this.imageImport(require.context("../../../assets/images/profile/crypto/", false, /\.(png|jpe?g|svg)$/));
        const columns = [
            { text: t("crypto-address-details.crypto"), dataField: "crypto" },
            { text: t("crypto-address-details.network"), dataField: "network" },
            { text: t("crypto-address-details.withdrawal-address"), dataField: "withdrawalAddress" },
            { text: "", dataField: "delete", formatter: this.actionFormatter },
        ];

        let rsData = [];

        // let cryptoChannel = [
        //     // { id: 21, label: "ERC20-USDT", type: "usdt" },
        //     { id: 20, label: "TRC20-USDT", type: "usdt" },
        //     { id: 22, label: "TRC20-USDC", type: "usdc" },
        //     // { id: 23, label: "ERC20-USDC", type: "usdc" },
        // ];

        if (this.state.memberCryptoAddressData && !this.state.loading) {
            let cryptoVal = null;
            let cryptoDisplayImage = null;

            rsData = this.state.memberCryptoAddressData.map((val, idx) => {
                if (val.crypto === "USDT") {
                    cryptoDisplayImage = "usdt";
                } else if (val.crypto === "USDC") {
                    cryptoDisplayImage = "usdc";
                }

                cryptoVal = isDesktop ? (
                    <div className="d-flex align-items-center" key={idx}>
                        <img className="me-3" src={cryptoImg[`${cryptoDisplayImage}.svg`]} alt={val.network.toUpperCase()} />
                        <p>{val.crypto}</p>
                    </div>
                ) : (
                    <div className="my-bd-m-col" key={idx}>
                        <img src={cryptoImg[`${cryptoDisplayImage}.svg`]} alt={val.network.toUpperCase()} />
                        <div className="my-bd-m-acc">
                            <p>{val.network}</p>
                            <p>{val.withdrawal_address}</p>
                        </div>
                    </div>
                );

                return {
                    no: idx,
                    crypto: cryptoVal,
                    network: val.network,
                    withdrawalAddress: val.withdrawal_address,
                    withdrawalAddressId: val.id,
                };
            });
        }

        if (this.state.openModal) {
            formValidation("update_member_withdrawal_address_form");
        }

        return (
            <Auxiliary>
                {isDesktop ? (
                    <>
                        <Modal
                            isOpen={this.state.openModal}
                            closeTimeoutMS={500}
                            portalClassName={`global-modal`}
                            className="modal modal-dialog modal-dialog-centered modal-pd"
                            ariaHideApp={false}
                            onRequestClose={(event) => this.closeModalHandler(event)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{t("crypto-address-details.edit-withdrawal-address-details")}</h5>
                                    <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.closeModalHandler(event)} />
                                </div>
                                <div className="modal-body">
                                    <form
                                        className="forms-col"
                                        method="post"
                                        id="update_member_withdrawal_address_form"
                                        encType="multipart/form-data"
                                        onSubmit={this.updateWithdrawalAddresssHandler}
                                        noValidate
                                    >
                                        <div>
                                            <label className="col-form-label lh-1">{t("crypto-address-details.withdrawal-address")}</label>
                                            <StringInput
                                                id="edit_withdrawal_address"
                                                name="edit_withdrawal_address"
                                                className={`form-control ${this.state.error_message.withdrawal_address ? "was-validated is-invalid" : ""}`}
                                                value={this.state.edit_withdrawal_address}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                step="any"
                                                required={true}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.error_message.withdrawal_address || ""}
                                            />
                                        </div>
                                        <Button typeButton="submit" classValue="btn btn-gradient-blue mt-3" buttonName={t("top-profile.update")} />
                                    </form>
                                </div>
                            </div>
                        </Modal>

                        <div className="tab-pane fade show active" id="crypto-details" role="tabpanel" aria-labelledby="crypto-tab">
                            <DataTable
                                keyField="no"
                                data={rsData}
                                columns={columns}
                                activePage={1}
                                limit={20}
                                totalItems={this.state.totalRecords}
                                paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                                sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                loading={this.state.loading}
                                hideSearchBar={true}
                                hidePagination={true}
                                hideTotalRecords={true}
                                classValue="pd-table w-border mb-0"
                                component="crypto-details"
                            />
                            <form method="post" id="add_crypto_address_form" className="form-horizontal" onSubmit={this.addCryptoWithdrawalAddressHandler} noValidate>
                                {this.state.loading ? (
                                    <Skeleton count={2} style={{ marginBottom: "1rem" }} height={"56px"} />
                                ) : (
                                    <>
                                        <button onClick={() => this.toggleAddBank()} className="btn text-icon mt-5 mb-3" type="button">
                                            {this.state.showAddBank ? <Icon icon="radix-icons:minus-circled" /> : <Icon icon="radix-icons:plus-circled" />}
                                            {t("crypto-address-details.add-new-crypto-acc")}
                                        </button>
                                        <div className={`add-more-col  ${this.state.showAddBank ? "show" : ""}`}>
                                            <div className="add-more-headers mb-3">
                                                <div className="row">
                                                    {/* <label className="col-3">{t("crypto-address-details.crypto")}</label> */}
                                                    <label className="col-3 offset-3">{t("crypto-address-details.network")}</label>
                                                    <label className="col-6">{t("crypto-address-details.withdrawal-address")}</label>
                                                </div>
                                            </div>
                                            {/* <form method="post" id="add_crypto_address_form" className="form-horizontal" onSubmit={this.addCryptoWithdrawalAddressHandler} noValidate> */}
                                            <div className="row g-2">
                                                <div className="col-6">
                                                    <ReactSelect
                                                        classNamePrefix="react-select"
                                                        name="network"
                                                        options={cryptoChannel}
                                                        placeholder={t("crypto-address-details.select-network")}
                                                        onChange={this.handleNetworkChange}
                                                        formatOptionLabel={(item) => (
                                                            <>
                                                                <img src={cryptoImg[`${item.type}.svg`]} alt={item.label} />
                                                                <p>{item.label}</p>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <div className="d-flex">
                                                        <div className="w-100 me-2">
                                                            <StringInput
                                                                id="withdrawalAddress"
                                                                name="withdrawalAddress"
                                                                className={`form-control ${this.state.error_message.withdrawal_address ? "was-validated is-invalid" : ""}`}
                                                                value={this.state.withdrawalAddress}
                                                                onChange={this.handleChange}
                                                                required={true}
                                                                requiredMessage={t("validation.required")}
                                                                formError={this.state.formError}
                                                                errorMsg={this.state.error_message.withdrawal_address || ""}
                                                            />
                                                        </div>
                                                        <Button
                                                            typeButton="submit"
                                                            classValue="btn btn-gradient-blue"
                                                            idValue="submit"
                                                            buttonName={t("crypto-address-details.add-withdrawal-address")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </>
                ) : (
                    <MobileCryptoDetails
                        {...this.state}
                        rsData={rsData}
                        columns={columns}
                        datatablesChangeHandler={this.datatablesChangeHandler}
                        actionFormatter={this.actionFormatter}
                        removeCryptoAddressHandler={this.removeCryptoAddressHandler}
                        searchHandler={this.searchHandler}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberCryptoAddressData: state.profile.member_crypto_address_data,
        memberRemoveCryptoAddressResult: state.profile.member_remove_crypto_address_result,
        getAvailableCryptoListResult: state.profile.get_available_crypto_list_result,
        store_withdrawal_crypto_address: state.withdrawal.store_withdrawal_crypto_address,
        update_crypto_address_result: state.profile.update_crypto_address_result,
        dropDown: state.withdrawal.dropDown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberCryptoAddress: () => dispatch(getMemberCryptoAddress()),
        removeMemberCryptoAddress: (data) => dispatch(removeMemberCryptoAddress(data)),
        funcGetAvailableCrypto: () => dispatch(getAvailableCryptoList()),
        funcStoreWithdrawalCryptoAddress: (data) => dispatch(addWithdrawalCryptoAddress(data)),
        updateMemberWithdrawalAddress: (data) => dispatch(updateMemberWithdrawalAddress(data)),
        funcGetDropDown: (data) => dispatch(actions.getDropDown(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(CryptoDetails));
