export const DROPDOWN = "DEPOSIT_DROPDOWN";
export const STORE = "DEPOSIT_STORE";
export const CRYPTO = "DEPOSIT_CRYPTO";
export const DIREPAY_CRYPTO = "DIREPAY_CRYPTO";
export const CRYPTO_TRANS_STATUS = "CRYPTO_TRANS_STATUS";

export const GET_CURRENCY_FPAY = "GET_CURRENCY_FPAY";
export const GET_BANK_OPTIPN_SECRET_PAY = "GET_BANK_OPTIPN_SECRET_PAY";
export const GET_BANK_OFFLINE_OPTION_SECRET_PAY = "GET_BANK_OFFLINE_OPTION_SECRET_PAY";

export const DEFAULT_DROPDOWN_OPTIONS = "DEFAULT_DROPDOWN_OPTIONS";
export const DEFAULT_SECRET_PAY_BANK_OPTIONS = "DEFAULT_SECRET_PAY_BANK_OPTIONS";
export const DEFAULT_SECRET_PAY_BANK_OFFLINE_OPTIONS = "DEFAULT_SECRET_PAY_BANK_OFFLINE_OPTIONS";

export const GET_EWALLET_TYPE = "GET_EWALLET_TYPE";