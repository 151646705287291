import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getDropDown = () => (dispatch, getState) =>
{
    let data = {};
    data.language_id = getState().currentUser.data.language_id;
    axios.get('deposit/drop_down', {params: data})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const store = (data) => dispatch =>
{
    // console.log(data);
    axios.post('deposit/store', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getCrypto = (data) => dispatch =>
{
    axios.get('profile/get_crypto', {
      params: {
        "crypto": data['crypto']
      }
    }).then(function (response) 
    {
      dispatch (
      {
        type: actionType.CRYPTO,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getFpayCurrency = (data) => dispatch =>
{
    axios.post('fpay/currency', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.GET_CURRENCY_FPAY,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getSecretPayBankOption = (data) => dispatch => 
{
  axios.post('secret_pay/bank_option', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.GET_BANK_OPTIPN_SECRET_PAY,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
}

export const direPayDepositCrypto = (data) => dispatch => 
{
  axios.post('deposit/crypto_deposit', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DIREPAY_CRYPTO,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
}

export const getCryptoTransStatus = (data) => dispatch =>
{
    axios.get('deposit/trans_status', {
      params: {
        "reference_code": data['reference_code']
      }
    }).then(function (response) 
    {
      dispatch (
      {
        type: actionType.CRYPTO_TRANS_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const defaultDropDownOptions = (data) => dispatch => {
  if(data){
    dispatch({
      type: actionType.DEFAULT_DROPDOWN_OPTIONS,
      value: data
    });
  }
}

export const defaultSecretPayBankOptions = (data) => dispatch => {
  if(data){
    dispatch({
      type: actionType.DEFAULT_SECRET_PAY_BANK_OPTIONS,
      value: data
    });
  }
}

export const getSecretPayBankOfflineOption = (data) => dispatch => 
{
  axios.post('secret_pay/bank_offline_option', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.GET_BANK_OFFLINE_OPTION_SECRET_PAY,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
}

export const defaultSecretPayBankOfflineOptions = (data) => dispatch => {
  if(data){
    dispatch({
      type: actionType.DEFAULT_SECRET_PAY_BANK_OFFLINE_OPTIONS,
      value: data
    });
  }
}

export const getSecretPayEwalletOption = (data) => dispatch => 
{
  axios.post('secret_pay/ewallet_type_option', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.GET_EWALLET_TYPE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
}