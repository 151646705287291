import React, { Component } from "react";
import { connect } from "react-redux";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getVIP, validateVIPBirthdayBonus } from "../../store/ducks/vip/actions";
import { getMemberProfile } from "../../store/ducks/profile/actions";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import Swal from "sweetalert2";
import moment from "moment";

//Components
// import Banner from "../../components/Banner/Banner";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MobileVip from "./MobileVip";
import { currency } from "../../common/config/currency";
import { numFormat } from "../../hoc/Shared/utility";

//Icons
import { Icon } from "@iconify/react";

class Vip extends Component {
    constructor(props) {
        super(props);
        let vipDetails = JSON.parse(localStorage.getItem('vipDetails'));
        this.state = {
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            vip: (vipDetails && vipDetails.vip) ? vipDetails.vip : [],
            set_birthday_date: false,
            loading: true,
            login: localStorage.getItem("accessToken") ? true : false
        };
    }

    fetchData() {
        if(localStorage.getItem('vipDetails')){
            let vipDetails = JSON.parse(localStorage.getItem('vipDetails'));
            let tmpMoment = moment(vipDetails.expired);
            let duration = moment.duration(tmpMoment.diff(moment())).asSeconds();

            if(duration > 0){
                this.setState({
                    vip: vipDetails.vip,
                    loading: false
                });

                return false;
            }
        }

        const showVIPData = {};
        this.props.getVIP(showVIPData);
    }

    componentDidMount() {
        this.fetchData();

        if (localStorage.getItem("accessToken")) {
            this.props.getMemberProfile();

            this.props.validateVIPBirthdayBonus();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.vip_data !== this.props.vip_data) {
            if (nextProps.vip_data.responseCode === 200) {
                this.setState({
                    vip: nextProps.vip_data.data,
                    loading: false,
                });

                let expired = moment().add(2, 'minutes');
                let tmpArr = {};
                tmpArr.vip = nextProps.vip_data.data;
                tmpArr.expired = expired.format('YYYYMMDDTHHmmss');
                localStorage.setItem('vipDetails', JSON.stringify(tmpArr));
            }
        }

        if (nextProps.memberProfileData !== this.props.memberProfileData && nextProps.memberProfileData !== undefined) {
            if (nextProps.memberProfileData.responseCode === 200) {
                let memberProfile = nextProps.memberProfileData.data.memberProfile[0];

                this.setState({
                    set_birthday_date: memberProfile.dateOfBirth !== null ? true : false,
                });
            }
        }

        if (nextProps.validate_vip_birthday_bonus !== undefined && nextProps.validate_vip_birthday_bonus !== this.props.validate_vip_birthday_bonus) {
            if (nextProps.validate_vip_birthday_bonus.responseCode === 200 && nextProps.validate_vip_birthday_bonus.msgType === "success") {
                this.setState({
                    dob: nextProps.validate_vip_birthday_bonus.data.dob,
                });
            }
        }

        return true;
    }

    redirectProfilePage = () => {
        if (localStorage.getItem("accessToken")) {
            window.location.href = "/personal-information";
        } else {
            const { t } = this.props;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: !isDesktop ? false : "",
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container sorry",
                        icon: "swal-sorry-icon",
                    }
                    : [],
            });
        }
        // this.props.history.push('/personal-information');
    };

    redirectVipExclusivePage = () => {
        if (localStorage.getItem("accessToken")) {
            window.location.href = "/vip-exclusive";
        } else {
            const { t } = this.props;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: !isDesktop ? false : "",
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container sorry",
                        icon: "swal-sorry-icon",
                    }
                    : [],
            });
        }
    };

    Benefit = (props) => {
        const { t } = this.props;

        let birthdayCountdown = null;
        if (this.state.dob) {
            let nextBirthday = moment().format("yyyy-") + moment(this.state.dob).format("MM-DD");
            if (nextBirthday === moment().format("yyyy-MM-DD")) {
                birthdayCountdown = "(" + t("vip.happy-birthday") + ")";
            } else {
                if (nextBirthday < moment().format("yyyy-MM-DD")) {
                    nextBirthday = moment().add(1, "years").format("yyyy-") + moment(this.state.dob).format("MM-DD");
                }

                const given = moment(nextBirthday, "YYYY-MM-DD");
                const current = moment().startOf("day");
                const dayLeft = moment.duration(given.diff(current)).asDays();
                birthdayCountdown = dayLeft;
            }
        }

        return (
            <div className="row">
                <div className="col-3">
                    <div className="benefit-header">
                        <img src={props.vip["crown.webp"]} alt="crown" />
                        <h5>{t("vip.upgrade-bonus")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]} {props.bonusMoney}</h4>
                        <p className="content">{t("vip.upgrade-bonus")}</p>
                        <p className="footer">{t("vip.automatically-issue")}</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="benefit-header">
                        <img src={props.vip["pocket.webp"]} alt="pocket" />
                        <h5>{t("vip.vip-weekly-benefits")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]} {props.vipWeeklyBonus}</h4>
                        <button className="btn btn-outline-blue" onClick={this.redirectVipExclusivePage}>
                            {t("vip.get-now")}
                        </button>
                        <p className="mention">
                            {t("vip.vip-weekly-bonus-mention")} <br />
                            {this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]} <span className="number">{props.turnoverWeeklyTarget}</span>
                        </p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="benefit-header">
                        <img src={props.vip["cake.webp"]} alt="cake" />
                        <h5>{t("vip.birthday-bonus")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]} {props.birthdayBonus}</h4>
                        {this.state.set_birthday_date === false ? (
                            <>
                                <button className="btn btn-outline-blue" onClick={this.redirectProfilePage}>
                                    {t("vip.set-now")}
                                </button>
                                <p className="footer">{t("vip.birthday-bonus-remark")}</p>
                            </>
                        ) : (
                            <>
                                <button className="btn btn-outline-blue" onClick={this.redirectVipExclusivePage}>
                                    {t("vip.get-now")}
                                </button>
                                <p className="content">
                                    {t("global.count-down")}
                                    <span className="number"> {birthdayCountdown} </span>
                                    {t("datepicker.day")}
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="benefit-header">
                        <img src={props.vip["present.webp"]} alt="present" />
                        <h5>{t("vip.daily-withdrawal-limit")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]} {props.dailyWithdrawalLimit}</h4>
                        <p className="content">{t("vip.daily-withdrawal-limit")}</p>
                        <p className="footer">
                            {t("vip.max-daily-withdrawal-limit")} {props.dailyWithdrawalCounts}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };
    render() {
        const { t } = this.props;
        const num_format = this.state.login ? numFormat(this.props.currentUser.currency) : numFormat(currency[this.props.currentUser.country_id]);
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const vip = this.imageImport(require.context("../../assets/images/vip", false, /\.(gif|png|jpe?g|svg|webp|webm)$/));

        let vipInfo = [];
        this.state.vip.map((vipval, idx) => {
            vipInfo.push({
                id: idx,
                name: "VIP" + vipval.level,
                img: "vip" + vipval.level + "",
                deposit: new Intl.NumberFormat("en-US", num_format).format(vipval.minDepositTierUpgrade),
                turnover: new Intl.NumberFormat("en-US", num_format).format(vipval.minTurnoverTierUpgrade),
                relegation: new Intl.NumberFormat("en-US", num_format).format(vipval.turnoverTierMaintain),
                depositRelegation: new Intl.NumberFormat("en-US", num_format).format(vipval.depositTierMaintain),
            });
            return null;
        });

        const vipSettings = {
            slidesToShow: isDesktop ? 1 : 3,
            adaptiveHeight: false,
            centerMode: true,
            centerPadding: isDesktop ? "392px" : "0",
            // focusOnSelect: true,
            infinite: true,
            dots: this.state.loading ? false : true,
            arrows: false,
            customPaging: function (i) {
                return <span>{i + 1}</span>;
            },
            beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
            afterChange: (current) => this.setState({ activeSlide2: current }),
        };

        let prevPage = this.props.location && this.props.location.state && this.props.location.state.prev ? this.props.location.state.prev : undefined;

        let imageLanguage = "";
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            // case "ms":
            //     imageLanguage = "-ms"
            //     break;
            case "id":
                imageLanguage = "-id"
                break;
            default:
                imageLanguage = "";
                break;
        }

        return (
            <Auxiliary>
                {this.state.loading && <LoadingPage />}
                {isDesktop ? (
                    <section className="vip-section">
                        <div className="banner-col">
                            <div className="banner-item">
                                <img src={banner[`vip-banner${imageLanguage}.webp`]} />
                            </div>
                        </div>
                        <div className="x-container-no-m">
                            <div className="vip-slick mb-5">
                                <div className="slick-container">
                                    {this.state.loading ? (
                                        <Slider ref={(c) => (this.slider = c)} {...vipSettings}>
                                            <Skeleton count={1} height={217} width="100%" />
                                            <Skeleton count={1} height={217} width="100%" />
                                            <Skeleton count={1} height={217} width="100%" />
                                        </Slider>
                                    ) : (
                                        <Slider ref={(c) => (this.slider = c)} {...vipSettings}>
                                            {vipInfo &&
                                                vipInfo.map((item, index) => (
                                                    <div className="vip-card-container" key={index}>
                                                        <span className="card-shadow">
                                                            <img
                                                                src={
                                                                    vip[
                                                                    `${item.id === 10
                                                                        ? "vip10-bg.webp"
                                                                        : item.id === 11
                                                                            ? "vip11-bg.webp"
                                                                            : item.id === 12
                                                                                ? "vip12-bg.webp"
                                                                                : "vip-bg.webp"
                                                                    }`
                                                                    ]
                                                                }
                                                                alt="vip-img"
                                                            />
                                                        </span>
                                                        <div className="vip-card">
                                                            <div className="vip-level">
                                                                <LazyLoadImage
                                                                    className="vip-logo"
                                                                    src={vip[`${item.img}${this.state.activeSlide2 === item.id ? ".gif" : ".webp"}`]}
                                                                    alt="vip-logo"
                                                                    effect="blur"
                                                                />
                                                                <p className="mt-2">
                                                                    {t("vip.vip-level")}: {item.name}
                                                                </p>
                                                            </div>
                                                            <div className="vip-info">
                                                                <div className="d-flex mb-2">
                                                                    <img className="info-icon" src={vip["t-deposit.webp"]} alt="vip-deposit" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.total-deposit")}
                                                                            <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span>
                                                                        </p>
                                                                        <p className="amount">{item.deposit}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mb-2">
                                                                    <img src={vip["t-turnover.webp"]} alt="vip-turnover" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.total-turnover")}
                                                                            <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span>
                                                                        </p>
                                                                        <p className="amount">{item.turnover}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <img src={vip["relegation.webp"]} alt="vip-relagation" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.relegation-turnover")}
                                                                            <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span>
                                                                        </p>
                                                                        {item.id === 0 || item.id === 1 || item.id === 2 ||  item.id === 3 ? (
                                                                            <p className="amount">
                                                                                {item.depositRelegation} <span>{t("vip.lifetime-day")}</span>
                                                                            </p>
                                                                            ) : (
                                                                                <p className="amount">
                                                                                {item.depositRelegation} <span>(90 {t("datepicker.day")})</span>
                                                                            </p>
                                                                            )
                                                                        }
                                                                        <span>**{t("vip.total-relegation-turnover")}：</span>

                                                                        <p className="t-relegation">{item.relegation}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </Slider>
                                    )}
                                    <div className="slick-arrow">
                                        <div className="slick-arrow-btn prev" onClick={() => this.slider.slickPrev()}>
                                            <Icon icon="uil:angle-left-b" />
                                        </div>
                                        <div className="slick-arrow-btn next" onClick={() => this.slider.slickNext()}>
                                            <Icon icon="uil:angle-right-b" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="vip-benefit py-5">
                                {this.state.loading ? (
                                    <div className="row">
                                        <div className="col-3">
                                            <Skeleton count={1} height={216} width="100%" />
                                        </div>
                                        <div className="col-3">
                                            <Skeleton count={1} height={216} width="100%" />
                                        </div>
                                        <div className="col-3">
                                            <Skeleton count={1} height={216} width="100%" />
                                        </div>
                                        <div className="col-3">
                                            <Skeleton count={1} height={216} width="100%" />
                                        </div>
                                    </div>
                                ) : (
                                    this.state.vip.map((vipVal, idx) => {
                                        if (this.state.activeSlide === idx) {
                                            return (
                                                <this.Benefit
                                                    vip={vip}
                                                    bonusMoney={new Intl.NumberFormat("en-US", num_format).format(vipVal.upLevelBonus)}
                                                    dailyWithdrawalLimit={new Intl.NumberFormat("en-US", num_format).format(vipVal.maxDailyWithdrawal)}
                                                    totalRelegationTurnover={new Intl.NumberFormat("en-US", num_format).format(vipVal.turnoverTierMaintain)}
                                                    birthdayBonus={new Intl.NumberFormat("en-US", num_format).format(vipVal.birthdayBonus)}
                                                    vipWeeklyBonus={new Intl.NumberFormat("en-US", num_format).format(vipVal.vipWeeklybonus)}
                                                    turnoverWeeklyTarget={new Intl.NumberFormat("en-US", num_format).format(vipVal.turnoverWeeklyTarget)}
                                                    dailyWithdrawalCounts={vipVal.dailyWithdrawalCounts}
                                                />
                                            );
                                        }
                                        return null;
                                    })
                                )}
                            </div>
                            <div className="vip-table mb-5">
                                <h3>{t("vip.vip-maximum-rates-rebate")}</h3>
                                <table className="table table-bordered bg-white">
                                    <thead>
                                        <tr>
                                            <td>{t("vip.vip-level")}</td>
                                            {this.state.vip.map((vipVal, idx) => {
                                                return (
                                                    <td>
                                                        {t("vip.vip")}
                                                        {vipVal.level}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("about-us.sports")}</td>
                                            {this.state.vip.map((vipVal, idx) => {
                                                return <td>{vipVal.sportRebate !== null ? vipVal.sportRebate : "0.00"}%</td>;
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t("about-us.live-casino")}</td>
                                            {this.state.vip.map((vipVal, idx) => {
                                                return <td>{vipVal.live_casino_rebate !== null ? vipVal.live_casino_rebate : "0.00"}%</td>;
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t("about-us.slots")}</td>
                                            {this.state.vip.map((vipVal, idx) => {
                                                return <td>{vipVal.slotRebate !== null ? vipVal.slotRebate : "0.00"}%</td>;
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t("about-us.games")}</td>
                                            {this.state.vip.map((vipVal, idx) => {
                                                return <td>{vipVal.virtualRebate !== null ? vipVal.virtualRebate : "0.00"}%</td>;
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="vip-bullet-content mb-5">
                                <h4>{t("vip.term-condition")}</h4>
                                <ol>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.criterion-upgrading-lv")} :</span>
                                            <span>{t("vip.criterion-upgrading-lv-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.sequence-upgrading-lv")} :</span>
                                            <span>{t("vip.sequence-upgrading-lv-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.relegation-requirements")} :</span>
                                            <span>{t("vip.relegation-requirements-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.downgrade-criteria")} :</span>
                                            <span>{t("vip.downgrade-criteria-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.tc-upgrade-bonus")} :</span>
                                            <span>{t("vip.tc-upgrade-bonus-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.tc-birthday-bonus")} :</span>
                                            <span>{t("vip.tc-birthday-bonus-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.tc-weekly-bonus")} :</span>
                                            <span>{t("vip.tc-weekly-bonus-content")}</span>
                                        </div>
                                    </li>
                                    <li>{t("vip.tc-last", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                    <div className="tnc-list justify-content-start">
                                        <span>{t("global.remark")}:</span>
                                        <span>{t("vip.tc-remark")}</span>
                                    </div>
                                </ol>
                            </div>
                        </div>
                    </section>
                ) : (
                    <MobileVip
                        redirectVipExclusivePage={this.redirectVipExclusivePage}
                        redirectProfilePage={this.redirectProfilePage}
                        prevPage={prevPage}
                        imageLanguage={imageLanguage}
                        currency={currency}
                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { vip, profile } = state;

    return {
        vip_data: vip.vip_data,
        currentUser: state.currentUser.data,
        memberProfileData: profile.member_profile_data,
        validate_vip_birthday_bonus: vip.validate_vip_birthday_bonus,
    };
};

const mapDispatchToProps = {
    getVIP,
    getMemberProfile,
    validateVIPBirthdayBonus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Vip));
