import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Modal from "react-modal";
import { default as ReactSelect } from "react-select";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
import DataTable from "../../../components/Datatable/DataTable";
// import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import CryptoDepositModal from "../../../components/CryptoDepositModal/CryptoDepositModal";
import { numFormat } from "../../../hoc/Shared/utility";

import * as actions from "../../../store/ducks/deposit/actions";

//Icons
import { Icon } from "@iconify/react";

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCryptoDepositModal: false,
            input: {
                amount: ""
            },
            cryptoPayment: "pending",
            refCode: "",
            txid: "",
            exchangeRate: "",
            usdtAmt: "",
            channel: "",
            type: "",

            time: {},
            timer: 0,
            seconds: 900,
            expiredDateTime: ""
        };
    }

    componentDidMount() {
        if (!this.props.disableSearchButton) {
            const listingArr = this.props.getFilterArray('deposit');
            this.props.funcGetList(listingArr);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.crypto_trans_status !== this.props.crypto_trans_status && nextProps.crypto_trans_status !== undefined) {
            let date = moment(nextProps.crypto_trans_status.data[0].expired_date);
            const current = moment(new Date(), "YYYY-mm-dd HH:mm:ss");

            var given = moment(date, "YYYY-mm-dd HH:mm:ss");

            const secondsLeft = moment.duration(current.diff(given)).asSeconds();

            let minusSeconds = 60 - secondsLeft;

            let timeLeftVar = this.secondsToTime(Math.abs(secondsLeft));

            let cryptoType = nextProps.crypto_trans_status.data[0].crypto;
            
            if (minusSeconds <= 0) {
                this.setState({
                    txid: nextProps.crypto_trans_status.data[0].reference_code,
                    refCode: nextProps.crypto_trans_status.data[0].payment_address,
                    exchangeRate: nextProps.crypto_trans_status.data[0].rates,
                    usdtAmt: nextProps.crypto_trans_status.data[0].crypto_amount,
                    channel: nextProps.crypto_trans_status.data[0].crypto,
                    type: cryptoType.split('-')[1],
                    cryptoPayment: "reject",
                    input: {
                        amount: nextProps.crypto_trans_status.data[0].credit
                    }
                })
            } else {
                this.setState({
                    timer: 0,
                    time: timeLeftVar,
                    seconds: Math.abs(minusSeconds),
                    txid: nextProps.crypto_trans_status.data[0].reference_code,
                    refCode: nextProps.crypto_trans_status.data[0].payment_address,
                    exchangeRate: nextProps.crypto_trans_status.data[0].rates,
                    usdtAmt: nextProps.crypto_trans_status.data[0].crypto_amount,
                    channel: nextProps.crypto_trans_status.data[0].crypto,
                    cryptoPayment: (nextProps.crypto_trans_status.data[0].status === 1 ? "success" : nextProps.crypto_trans_status.data[0].status === 2 ? "reject" : "pending"),
                    type: cryptoType.split('-')[1],
                    input: {
                        amount: nextProps.crypto_trans_status.data[0].credit
                    },
                    expiredDateTime: nextProps.crypto_trans_status.data[0].expired_date
                })
            }


        }

        return true;
    }

    openCryptoModalHandler = (reference_code) => {

        let data = {};
        data.reference_code = reference_code;
        this.props.funcCryptoTransStatus(data);

        this.setState({
            openCryptoDepositModal: !this.state.openCryptoDepositModal
        });

        clearInterval(this.timer);
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
        }
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    render() {
        const { t } = this.props;
        let rsData = [];
        let stCls = { 0: "on-going", 1: "success", 2: "failed", 3: "on-going", 4: "failed" };
        let stVal = { 0: t("history.pending"), 1: t("history.success"), 2: t("history.reject"), 3: t("history.processing"), 4: t("history.cancel") };
        const num_format = numFormat(this.props.currentUser.currency);

        // let promoList = this.props.data.promoList ? this.props.data.promoList : [];
        let promoList = [];
        if (this.props.data.promoList && this.props.openModal) {
            promoList = this.props.data.promoList.map((val, idx) => {
                return {
                    id: val.id,
                    label: val.title,
                    key: idx,
                };
            });
        }
        let validPromo = this.props.data.validPromo;

        const columns = [
            // {text: 'No', dataField: "no", formatter: this.noFormatter, headerAttrs: { hidden: true }},
            { text: t("history.date"), dataField: "create_date" },
            { text: t("history.order-id"), dataField: "order_id" },
            { text: t("history.amount"), dataField: "credit" },
            { text: t("history.method"), dataField: "method" },
            { text: t("history.type"), dataField: "transaction_type" },
            { text: t("history.approve-time"), dataField: "approval_time" },
            { text: t("history.status"), dataField: "status" },
            { text: t("global.action"), dataField: "action" },
            // { text: t("history.status"), dataField: "status"},
        ];

        if (this.props.data.data) {
            rsData = this.props.data.data.map((val, idx) => {
                let actionVal =
                    val.transaction_type_id === 2 && val.status === 0 ? (
                        <button className="btn btn-action" onClick={(event) => this.props.cancelHandler(event, val.id)}>
                            {t("history.cancel")}
                        </button>
                    ) : validPromo && validPromo !== undefined && validPromo.valid_apply === 1 && val.id === validPromo.id ? (
                        <button className="btn btn-action" onClick={(event) => this.props.promoModalHandler(event, val.id)}>
                            {t("global.edit")}
                        </button>
                    ) : (
                        <span>{"-"}</span>
                    );

                let orderIdVal = val.status === 0 && val.transaction_channel === "crypto" ? (
                    <div
                        className="d-flex align-items-center justify-content-center btn-action no-hover"
                        onClick={() => this.openCryptoModalHandler(val.reference_code)}
                    >
                        <span className="me-1">{val.order_id}</span>
                        <Icon icon="uil:search" />
                    </div>
                ) : (
                    <span>{val.order_id}</span>
                )



                let promoVal = <span className={`${stCls[val.status]}`}>{stVal[val.status]}</span>

                return {
                    no: idx,
                    create_date: moment(val.create_date).format("YYYY-MM-DD H:mm"),
                    credit: val.transaction_type_id === 1 ? new Intl.NumberFormat("en-US", num_format).format(val.credit) : new Intl.NumberFormat("en-US", num_format).format(val.debit),
                    method: t(`transaction-channel.${val.transaction_channel}`),
                    transaction_type: t(`transaction-type.${val.transaction_type}`),
                    action: actionVal,
                    status:
                        val.status === 2 ? (
                            <span role="button" className={` status ${stCls[val.status]}`}>
                                {stVal[val.status]}
                            </span>
                        ) : (
                            <>
                                {promoVal}
                            </>
                        ),
                    order_id: orderIdVal,
                    approval_time: val.approval_time
                };
            });
        }

        return (
            <Auxiliary>
                <CryptoDepositModal
                    {...this.state}
                    {...this.props}
                    toggleCryptoDepositModalHandler={this.openCryptoModalHandler}
                />

                <Fade duration={1000} triggerOnce>
                    <DataTable
                        keyField="no"
                        data={rsData}
                        columns={columns}
                        activePage={this.props.data.activePage}
                        limit={this.props.data.limit}
                        totalItems={this.props.data.totalRecords}
                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "activePage")}
                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "limit")}
                        loading={this.props.data.loading}
                        hideSearchBar={true}
                        hideTotalRecords={true}
                        classValue="pd-table"
                        component="transaction-history"
                    />
                </Fade>

                {
                    this.props.openModal && (
                        <Modal
                            isOpen={this.props.openModal}
                            closeTimeoutMS={500}
                            portalClassName={`global-modal`}
                            className="modal modal-dialog modal-dialog-centered modal-pd"
                            ariaHideApp={false}
                            onRequestClose={(event) => this.props.promoModalHandler(event)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{t('history.select-promotion')}</h5>
                                    <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.props.promoModalHandler(event)} />
                                </div>
                                <div className="modal-body">
                                    <div className="forms-col">
                                        <div>
                                            <label className="col-form-label lh-1">{t('transaction-type.promotion')}</label>
                                            {/* <Select
                                                id="promo_id"
                                                name="promo_id"
                                                className="form-control form-select history-promos"
                                                getOptionValue="id"
                                                getOptionLabel="title"
                                                options={promoList}
                                                // onChange={(event) => this.props.applyPromoHandler(event, this.state.select_id)}
                                                onChange={(event) => this.props.onChangeHandler(event)}
                                                value={this.props.data.promo_id}
                                                emptyValue={true}
                                            /> */}
                                            <ReactSelect
                                                classNamePrefix="react-select"
                                                placeholder={t("history.select-promotion")}
                                                name="promo_id"
                                                options={promoList}
                                                // onChange={(event) => this.props.applyPromoHandler(event, this.state.select_id)}
                                                onChange={(event) => this.props.onPromoInputChangeHandler(event, 'promo_id')}
                                                // value={this.props.data.promo_id}
                                                // value={(this.props.promo_key !== null) ? promoList[this.props.promo_key] : ''}
                                                isClearable={true}
                                            />
                                        </div>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-gradient-blue w-25"
                                            buttonName={t("global.apply")}
                                            clicked={(event) => this.props.applyPromoHandler(event, this.props.data.select_id, this.props.data.promo_id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )
                }
            </Auxiliary >
        );
    }
}


//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
        crypto_trans_status: state.deposit.crypto_trans_status
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcCryptoTransStatus: (data) => dispatch(actions.getCryptoTransStatus(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Deposit));
