import * as type from './types';
import axios from '../../../common/config/axios';

export const getSpareDomain = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get('spare_domain/list', { params: data })
    .then((res) => {
        dispatch({
            type: type.SPARE_DOMAIN_LIST,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}