import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import moment from "moment";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import DataTable from "../../../components/Datatable/DataTable";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
// import NoRecords from "../../../components/NoRecords/NoRecords";
// import Pagination from "../../../components/Pagination/Pagination";
import { numFormat } from "../../../hoc/Shared/utility";

//Icons
import { Icon } from "@iconify/react";
import ReferralModal from "./ReferralModal";

import { getMemberReferral, getMemberReferralBonus } from "../../../store/ducks/profile/actions";
import { isDesktop } from "react-device-detect";
import MobileReferral from "./MobileReferral";

const filterDate = [
  { id: "filterToday", value: "0", label: "today" },
  { id: "filterYtd", value: "1", label: "yesterday" },
  { id: "filter7Day", value: "7", label: "last-7-days" },
  { id: "filter30Days", value: "30", label: "last-30-days" },
];

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      count_invitation: 0,
      count_completed: 0,
      bonus_received: 0,
      activePage: 1,
      limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
      data: [],
      specific_date: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      currentFilterDate: 0,
      order: "",
      total_records: 0,
      total_pages: 0,
      disable_search_btn: false,

      /* mobile */
      showDateSelect: false,
      showDate: '',
      selectedDate: '',

      spin: false,
    };
    this.dateRangeRef = createRef();
  }

  componentDidMount() {
    this.props.getMemberReferral();

    let params = this.getFilterArray();
    this.props.getMemberReferralBonus(params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]", nextProps);

    if (nextProps.member_referral_data !== undefined && nextProps.member_referral_data !== this.props.member_referral_data) {
      if (nextProps.member_referral_data.responseCode === 200 && nextProps.member_referral_data.msgType === "success") {
        let referralData = nextProps.member_referral_data.data;

        this.setState({
          count_invitation: referralData.memberReferralInvitation,
          // count_completed: referralData.memberReferralComplete,
          bonus_received: referralData.memberReferralTotalBonus,
          loading: false
        });
      }
    }

    if (nextProps.member_referral_bonus_data !== undefined && nextProps.member_referral_bonus_data !== this.props.member_referral_bonus_data) {
      if (nextProps.member_referral_bonus_data.responseCode === 200 && nextProps.member_referral_bonus_data.msgType === "success") {
        let referralBonusData = nextProps.member_referral_bonus_data.data;
        this.setState({
          data: referralBonusData.result.row,
          total_records: referralBonusData.result.pagination.total_record,
          total_pages: referralBonusData.result.pagination.total_page,
          loading: false,
          disable_search_btn: false
        });
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  getFilterArray = () => {
    const listingArr = {};
    listingArr.page = this.state.activePage;
    listingArr.limit = this.state.limit;
    listingArr.order = JSON.stringify(this.state.order);
    listingArr.start_date = this.state.start_date;
    listingArr.end_date = this.state.end_date;
    return listingArr;
  }

  searchHandler = () => {
    this.setState({
      activePage: 1,
      loading: true,
      data: [],
      disable_search_btn: true
    }, () => {
      const params = this.getFilterArray();
      this.props.getMemberReferralBonus(params);
    });
  }

  datatablesChangeHandler = (val, act) => {
    this.setState({
      loading: true,
      data: [],
      [act]: val
    }, () => {
      const params = this.getFilterArray();
      this.props.getMemberReferralBonus(params);
    });
  };

  filterByDate = (event, day) => {
    let days = parseInt(day);
    let start_date = moment().subtract(days, "days").format("YYYY-MM-DD");
    let end_date = moment().format("YYYY-MM-DD");
    this.setState({
      start_date: start_date,
      end_date: end_date
    }, () => {
      // let startDate = moment(this.state.start_date);
      // let endDate = moment(this.state.end_date);
      // let dayRange = endDate.diff(startDate, 'days');
      // let disableButton = null;
      // if(!startDate.isSameOrBefore(endDate) || !endDate.isSameOrAfter(startDate) || dayRange > 92) {
      //     disableButton = true;
      // } else {
      //     disableButton = false;
      // }
      // this.setState({
      //     disableSearchButton: disableButton
      // })
      this.dateRangeRef.current.setStartDate(moment(this.state.start_date).format("MM/DD/YYYY"));
      this.dateRangeRef.current.setEndDate(moment(this.state.end_date).format("MM/DD/YYYY"));
      this.searchHandler();
    });
  }

  filterDayRange = (val) => {
    // let days = Number(event.target.value);
    let days = val;
    let startDate = moment().subtract(days, "days").format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");
    let transactionDate = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`;

    this.setState({
      start_date: startDate,
      end_date: endDate,
      transaction_date: transactionDate,
      filterRange: days
    }, () => {
      let startDate = moment(this.state.start_date);
      let endDate = moment(this.state.end_date);
      let dayRange = endDate.diff(startDate, 'days');
      let disableButton = null;
      if (!startDate.isSameOrBefore(endDate) || !endDate.isSameOrAfter(startDate) || dayRange > 92) {
        disableButton = true;
      } else {
        disableButton = false;

        let params = this.getFilterArray(this.state.historyTab);
        this.props.getMemberReferralBonus(params);
      }
      this.setState({
        disable_search_btn: disableButton
      })

      this.searchHandler();
    });
  }

  onPickerEvent = (event, picker) => {
    // console.log(moment(picker.startDate).format('MM/DD/YYYY'),moment(picker.endDate).format('MM/DD/YYYY'));
  }

  onPickerCallback = (start, end, label) => {
    let start_date = moment(start).format('YYYY-MM-DD');
    let end_date = moment(end).format("YYYY-MM-DD");
    this.setState({
      start_date: start_date,
      end_date: end_date
    });
  }

  onInputChange = (event) => {
    if (event.target.id === 'transaction_date') {
      const date = (event.target.value !== '') ? event.target.value.split(" - ") : null;
      this.setState({
        start_date: (date[0]) ? moment(date[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        end_date: (date[1]) ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        transaction_date: event.target.value,
      });

      const startDate = moment(this.state.start_date);
      const endDate = moment(this.state.end_date);
      const diff = endDate.diff(startDate, 'days');

      // Only allow to get data between 3 months
      if (diff > 92) {
        this.setState({
          disable_search_btn: true
        })
      } else {
        this.setState({
          disable_search_btn: false
        })
      }
    }
  }

  toggleShowDateSelect = (e, show) => {
    if (this.state.showDateSelect === false) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({
      showDateSelect: !this.state.showDateSelect,
    });
    if (show === 'startDate') {
      this.setState({
        showDate: 'startDate',
        selectedDate: this.state.start_date,
      })
    } else if (show === 'endDate') {
      this.setState({
        showDate: 'endDate',
        selectedDate: this.state.end_date,
      })
    }
  }
  onChangeDateHandler = (value) => {
    if (this.state.showDate === 'startDate') {
      let days = value.days !== undefined ? value.days.toString().padStart(2, "0") : value.days;
      this.setState({
        start_date: `${value.years}-${value.months}-${days}`,
      }, () => {
        this.timeDateDiff();
      });
    } else if (this.state.showDate === 'endDate') {
      let days = value.days !== undefined ? value.days.toString().padStart(2, "0") : value.days;
      this.setState({
        end_date: `${value.years}-${value.months}-${days}`,
      }, () => {
        this.timeDateDiff();
      })
    }
  }

  timeDateDiff = () => {
    let startDate = moment(this.state.start_date);
    let endDate = moment(this.state.end_date);
    let dayRange = endDate.diff(startDate, 'days');
    let disableButton = null;
    if (!startDate.isSameOrBefore(endDate) || !endDate.isSameOrAfter(startDate) || dayRange > 92) {
      disableButton = true;
    } else {
      disableButton = false;
    }

    this.setState({
      disable_search_btn: disableButton
    });
  }

  onChangeFilterDateHandler = (value, e) => {
    this.setState({
      currentFilterDate: value
    });
  }

  onResetHandler = () => {
    this.setState({
      currentFilterDate: 0,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      transaction_date: "",
      spin: true
    }, () => {
      const listingArr = this.getFilterArray();
      this.props.getMemberReferralBonus(listingArr);
    });

    setTimeout(() => this.setState({ spin: false }), 2000);
  }

  render() {
    const { t } = this.props;
    const currency = this.props.currentUser.data.currency;
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const resetIcon = this.imageImport(
      require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );

    const num_format = numFormat(this.props.currentUser.data.currency);
    const columns = [
      // { text: t("referral.table-col.serial"), dataField: "serialNo" },
      // { text: t("referral.table-col.registration-date"), dataField: "createdDate" },
      // { text: t("referral.table-col.account"), dataField: "username" },
      // { text: t("referral.table-col.deposit"), dataField: "totalDeposit" },
      // { text: t("referral.table-col.prize"), dataField: "prize" },
      // { text: t("referral.table-col.status"), dataField: "referralStatus" }
      { text: t("referral.table-col.date"), dataField: "createdDate" },
      { text: t("referral.table-col.order-id"), dataField: "orderId" },
      { text: t("referral.table-col.prize"), dataField: "payoutAmt" },
      { text: t("referral.table-col.status"), dataField: "status" }
    ];
    let data = this.state.data ? this.state.data.map((val, idx) => {
      let refStatus = null;
      // let totalDeposit = null;
      switch (val.status) {
        case 0:
          refStatus = <span className="status pending">{t('referral.pending')}</span>
          break;
        case 1:
          refStatus = <span className="status success">{t('referral.success')}</span>
          break;
        case 2:
          refStatus = <span className="status fail">{t('referral.failed')}</span>
          break;
        default:
          refStatus = <span className="status pending">{t('referral.pending')}</span>
          break;
      }
      // totalDeposit = Intl.NumberFormat('en-US', num_format).format(val.totalDeposit) + " / " + Intl.NumberFormat('en-US', num_format).format(val.depositRequirement)
      return {
        // serialNo: val.serialNo,
        createdDate: val.create_date,
        // transactionDate: val.transaction_date,
        orderId: val.order_id,
        payoutAmt: Intl.NumberFormat('en-US', num_format).format(val.payout_amt),
        status: refStatus,
      };
    }) : [];

    const fadeUp = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, 50px ,0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `;

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <section className="pf-dashboard">
                <div className="x-container">
                  <ProfileSideBar />
                  <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="pd-card-col">
                          <h4>{t("referral.referral-amount")}</h4>
                          <div className="pd-content">
                            <div className="filters-col">
                              <div className="referral-tp-panel ">
                                <div className="row g-3">
                                  <div className="col-6">
                                    <div>
                                      <img className="active" src={icons["people-ico.svg"]} alt="people-ico" />
                                      <div>
                                        <label>{t("referral.recommendation.count")}</label>
                                        <p>
                                          <span>{!this.state.loading ? this.state.count_invitation : 0}</span> {t("referral.recommendation.person")}
                                        </p>
                                      </div>
                                      <button className="btn btn-gradient-blue ms-auto" data-bs-toggle="modal" data-bs-target="#referralModal">
                                        <p className="text-white">{t("referral.refer-now")}</p>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div>
                                      <img className="active" src={icons["bonus-received-ico.svg"]} alt="bonus-received-ico" />
                                      <div>
                                        <label>{t("referral.bonus-received")}</label>
                                        <p>
                                          {currency} <span>{!this.state.loading ? Intl.NumberFormat('en-US', num_format).format(this.state.bonus_received) : Intl.NumberFormat('en-US', num_format).format(0)}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filters-bt-panel">
                                <div className="filters-bt-col">
                                  <label className="col-form-label">{t("referral.date")}:</label>
                                  <div className="filters-group">
                                    {filterDate &&
                                      filterDate.map((item, index) => (
                                        <div className="form-check form-check-inline filter" key={index}>
                                          <input
                                            className="btn-check"
                                            type="radio"
                                            name="filterDate"
                                            id={item.id}
                                            value={item.value}
                                            defaultChecked={index === 0}
                                            checked={index === this.state.currentFilterDate}
                                            onClick={(e) => this.onChangeFilterDateHandler(index, e)}
                                          />
                                          <label className="btn form-check-label" htmlFor={item.id} style={{ maxWidth: '75px' }} onClick={() => this.filterDayRange(item.value)}>
                                            {t(`history.${item.label}`)}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div className="filters-bt-col">
                                  <label className="col-form-label">
                                    {t("referral.time")}:
                                  </label>
                                  <div className="input-group right">
                                    <DateTimePicker
                                      id="transaction_date"
                                      name="transaction_date"
                                      className="form-control"
                                      autoUpdateInput={true}
                                      autoApply={true}
                                      format="MM/DD/YYYY"
                                      value={this.state.transaction_date}
                                      onChange={this.onInputChange}
                                    />
                                    <span className="input-group-text">
                                      <Icon icon="uil:calendar-alt" />
                                    </span>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-gradient-blue"
                                    onClick={this.searchHandler}
                                    disabled={this.state.disable_search_btn}>
                                    {t("global.check")}
                                  </button>
                                  <div className="reset-btn">
                                    <img className={`${this.state.spin ? "spin" : ""} `} src={resetIcon["rotate-icon.svg"]} alt="reset" onClick={this.onResetHandler} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pd-card-col">
                          <Fade direction="up" duration={500} triggerOnce>
                            <DataTable
                              keyField="serialNo"
                              data={data}
                              columns={columns}
                              activePage={this.state.activePage}
                              limit={this.state.limit}
                              totalItems={this.state.total_records}
                              paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                              // sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                              loading={this.state.loading}
                              hideSearchBar={true}
                              hideTotalRecords={true}
                              classValue="pd-table"
                              component="referral"
                            />
                          </Fade>
                          {/* <NoRecords component="referral" /> */}
                          {/* <Pagination /> */}
                        </div>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </section>
              <ReferralModal />
            </>
          ) : (
            <MobileReferral

              icons={icons}
              current={currency}
              num_format={num_format}

              dataListing={data}
              columns={columns}
              filterDate={filterDate}
              filterDayRange={this.filterDayRange}

              onChangeDateHandler={this.onChangeDateHandler}
              toggleShowDateSelect={this.toggleShowDateSelect}
              searchHandler={this.searchHandler}
              datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}

              onResetHandler={this.onResetHandler}
              onChangeFilterDateHandler={this.onChangeFilterDateHandler}

              {...this.props}
              {...this.state}
            />
          )
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]", state);
  return {
    currentUser: state.currentUser,
    member_referral_data: state.profile.member_referral_data,
    member_referral_bonus_data: state.profile.member_referral_bonus_data
  }
}

const mapDispatchToProps = {
  getMemberReferral,
  getMemberReferralBonus
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Referral));
