import * as type from './types';
import axios from '../../../common/config/axios';

export const getInbox = (data) => dispatch => {
    axios.get('inbox/get_inbox', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_INBOX,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const showInbox = (data) => dispatch => {
    axios.get('inbox/show_inbox', {
        params: {
            "id": data['id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_INBOX,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const deleteInbox = (data) => dispatch => {
    axios.post('inbox/delete', data).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.DELETE_INBOX,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const readInbox = (data, index) => dispatch => {
    axios.post('inbox/read', data).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.READ_INBOX,
            payload: response,
            index: index
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getCountUnreadInboxAlert = (data) => (dispatch) => {
    axios
        .get("inbox/get_count_unread_alert_inbox", {
            params: data
        })
        .then((res) => {
            dispatch({
                type: type.GET_COUNT_UNREAD_INBOX_ALERT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Unread Inbox Alert Error", err);
        });
};

export const getInboxAlert = (data) => (dispatch) => {
    axios
        .get("inbox/get_alert_inbox", {params: data})
        .then((res) => {
            if(data.page === 1) {
                dispatch({
                    type: type.GET_INBOX_ALERT,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: type.LOAD_MORE_INBOX_ALERT,
                    payload: res.data.data.result.row,
                });
            }
        })
        .catch((err) => {
            console.log("Get Inbox Alert Error", err);
        });
};

export const readInboxAlert = (data, index) => dispatch => {
    axios.post('inbox/read_inbox_alert', data).then(() => {
        dispatch({
            type: type.READ_INBOX_ALERT,
            index: index
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getNotification = (data) => dispatch => {
    axios.get('inbox/get_notification', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_NOTIFICATION,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const readNotification = (data, index) => dispatch => {
    axios.post('inbox/read_notification', data).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.READ_NOTIFICATION,
            payload: response,
            index: index
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const deleteNotification = (data) => dispatch => {
    axios.post('inbox/delete_notification', data).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.DELETE_NOTIFICATION,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getNotificationSettings = (data) => dispatch => {
    axios.get('inbox/get_notification_setting').then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_NOTIFICATION_SETTINGS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateNotificationSettings = (data) => dispatch => {
    axios.post('inbox/update_notification_setting', data).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.UPDATE_NOTIFICATION_SETTINGS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};