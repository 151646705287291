import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
// import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import ReactPinField from "react-pin-field";
// import { Icon } from "@iconify/react";
import ValidationError from "../../components/ValidationError/ValidationError";
import StringInput from "../../components/Input/StringInput/StringInput";
// import Select from "../../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { default as ReactSelect } from "react-select";
// import Swal from "sweetalert2";
import MobileSubHeader from "../PageHeader/MobileSubHeader";

export class MobileSecurityPin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.errorMsg !== undefined && this.props.errorMsg !== undefined && nextProps.errorMsg !== this.props.errorMsg) {
      if (this.props.securityPin === "enter-sec-pin") {
        if (nextProps.errorMsg.amount || nextProps.errorMsg.bank_id) {
          if (this.props.showAddSecurityPin) {
            this.props.closeAddSecurityPin();
          }
        }
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/)
    );
    return (
      <Auxiliary>
        {this.props.showAddSecurityPin ? (
          <section className="sp-m-section pf-m-dashboard on-top d-block pe-auto">
            {this.props.securityPin === "set-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white"
                  closeModalHandler={this.props.closeAddSecurityPin}
                  header={t("profile.add-security-pin")}
                  style={{ justifyContent: "unset" }}
                />
                <form
                  className="forms-col needs-validation my-3"
                  method="post"
                  onSubmit={this.props.onSetSecurityPinHandler}
                >
                  <div className="pd-form-m-col">
                    <div className="input-group-col">
                      <h5>{t("profile.set-your-pin")}</h5>
                      <span>
                        {t("profile.security-pin-desc")}
                      </span>
                      <ReactPinField
                        className="pin-field mt-3"
                        length="6"
                        validate="0123456789"
                        type="password"
                        value={this.state.securityPin}
                        onComplete={this.props.onInputChange}
                      />
                      <p
                        className="forget-pin"
                        onClick={this.props.onForgetSecurityHandler}
                      >
                        {t("profile.forget-security-pin")}
                      </p>
                    </div>
                    <button
                      className="btn btn-gradient-blue mx-3"
                      type="submit"
                      value="Submit"
                    >
                      {t("global.submit")}
                    </button>
                    {/* <p className="sp-mention">
                      {t("profile.verification-mention-1")} &nbsp;
                      <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                      &nbsp;{t("profile.verification-mention-2")}
                    </p> */}
                  </div>
                </form>
              </>
            ) : this.props.securityPin === "enter-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white"
                  closeModalHandler={this.props.closeAddSecurityPin}
                  header={t('profile.enter-security-pin-title')}
                  style={{ justifyContent: "unset" }}
                />

                {/* {this.props.enter_pin_attempts === 0 && this.props.reenter_security_pin_status === false ? (
                  <div className="invalid-feedback d-block">Please wait {"0" + this.props.time.m + ":" + this.props.time.s} to reenter the security pin.</div>
                ) : this.props.verify_security_pin_status === false && (
                  <div className="invalid-feedback d-block">Incorrect Pin. Please try again. ( {this.props.enter_pin_attempts} attempt left).</div>
                )} */}

                <form
                  className="forms-col needs-validation my-3"
                  method="post"
                  onSubmit={this.props.onEnterSecPinHandler}
                >
                  <div className="pd-form-m-col">
                    <div className="input-group-col">
                      {this.props.checkSecurityAttemptsLoading === true ? (
                        <Skeleton count={1} />
                      ) : this.props.reenter_security_pin_status === true && (
                        <>
                          <h5>{t("profile.set-your-pin")}</h5>
                          <span>
                            {t("profile.security-pin-desc")}
                          </span>
                          <ReactPinField
                            className="pin-field mt-3"
                            length="6"
                            validate="0123456789"
                            type="password"
                            value={this.state.securityPin}
                            onComplete={this.props.onInputChange}
                          />

                          <p
                            className="forget-pin"
                            onClick={this.props.onForgetSecurityHandler}
                          >
                            {t("profile.forget-security-pin")}
                          </p>
                        </>
                      )}

                      {this.props.checkSecurityAttemptsLoading === true ? (
                        <Skeleton count={1} />
                      ) : this.props.reenter_security_pin_status === false ? (
                        <div className="invalid-feedback d-block">{t("global.please-wait")} {"0" + this.props.time.m + ":" + this.props.time.s} {t("profile.reenter-pin")}.</div>
                      ) : this.props.verify_security_pin_status === false && (
                        <div className="invalid-feedback d-block">{t("profile.incorrect-pin")} ( {this.props.enter_pin_attempts} {t("global.attemp-left")}).</div>
                      )}
                    </div>

                    {this.props.reenter_security_pin_status === true && (
                      <>
                        <button
                          className="btn btn-gradient-blue mx-3"
                          type="submit"
                          value="Submit"
                          disabled={this.props.reenter_security_pin_status === false ? true : false}
                        >
                          {t("global.submit")}
                        </button>
                      </>
                    )}
                    {/* <p className="sp-mention">
                      {t("profile.verification-mention-1")}&nbsp;
                      <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                      &nbsp;{t("profile.verification-mention-2")}
                    </p> */}
                  </div>
                </form>
              </>
            ) : this.props.securityPin === "request-otp-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white"
                  closeModalHandler={this.props.closeAddSecurityPin}
                  header="Request OTP to change Security PIN"
                  style={{ justifyContent: "unset" }}
                />

                {this.props.check_sending_sms_status === true ? (
                  <form
                    className="forms-col needs-validation my-3"
                    method="post"
                    onSubmit={this.props.verifyOtpHandler}
                  >
                    <div className="pd-form-m-col">
                      <div className="input-group-col">
                        <label className="col-form-label p-0 m-0">
                          {t("profile.mobile-number")}
                        </label>
                        <ValidationError
                          formName="mobile_verify_from"
                          touched={this.props.touched}
                          message={this.props.errorMsg}
                        />
                        <div className="input-group left has-validation pb-3">
                          <ReactSelect
                            classNamePrefix="react-select"
                            defaultValue={this.props.mobileCountryArray[0]}
                            options={this.props.mobileCountryArray}
                            onChange={this.props.handlePromoChange}
                            formatOptionLabel={(item) => (
                              <>
                                <img
                                  src={img[`${item.code}.webp`]}
                                  alt={item.image}
                                />
                                <p>{item.label}</p>
                              </>
                            )}
                          />

                          <StringInput
                            className={`${this.props.errorMsg.contact_no &&
                              "was-validated is-invalid"
                              }`}
                            style={{ width: "70%" }}
                            type="text"
                            placeholder="Mobile Phone No."
                            name="verificationPhoneNo"
                            defaultValue=""
                            value={this.props.currentMobileNo}
                            onChange={this.props.onSetMobileNoHandler}
                            minLength={this.props.contact_no_min_limits}
                            maxLength={this.props.contact_no_max_limits}
                            minLengthMessage={t("validation.minLength", {
                              0: this.props.contact_no_min_limits,
                            })}
                            maxLengthMessage={t("validation.maxLength", {
                              0: this.props.contact_no_max_limits,
                            })}
                            required
                            requiredMessage={t("validation.required")}
                            errorMsg={this.props.errorMsg.contact_no || ""}
                            formError={this.props.formError}
                          />
                        </div>

                        <div className="pt-3 border-top">
                          <label className="col-form-label p-0 m-0">
                            {t("profile.verification-code")}
                          </label>
                          <div className="verificate-m-input">
                            <input
                              type="number"
                              onChange={this.props.onVerificationCodeHandler}
                              className={`form-control ${this.props.sms_otp_code
                                ? "is-valid"
                                : "was-validated is-invalid"
                                }`}
                              placeholder="Please enter verification code"
                            />
                            {this.props.generateOTPStatus &&
                              this.props.check_sending_sms_status ? (
                              <div className="d-flex align-items-center justify-content-between">
                                {/* <small>
                                  {t("top-profile.otp-attempt-msg")}{" "}
                                  {this.props.check_sending_sms_times} / 5
                                </small> */}
                                <Button
                                  typeButton="button"
                                  clicked={(event) =>
                                    this.props.resendOTPHandler(event)
                                  }
                                  classValue="btn btn-underlined"
                                  buttonName={
                                    this.props.otpTimer
                                  }
                                  disabled={
                                    this.props.resend_otp_button_disabled ===
                                      true
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            ) : (
                              <button
                                className="btn btn-blue"
                                type="button"
                                onClick={this.props.generateOTPHandler}
                                disabled={
                                  this.props.generateOTPStatus === true &&
                                    this.props.resend_otp_button_disabled === true
                                    ? "active"
                                    : ""
                                }
                              >
                                {t("forgot-password.request")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-gradient-blue mx-3"
                        type="submit"
                        value="Submit"
                        disabled={
                          this.props.sms_otp_code === "" ? "active" : ""
                        }
                      >
                        {t("global.submit")}
                      </button>

                      {/* <p className="sp-mention">
                        {t("profile.verification-mention-1")} &nbsp;
                        <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                        &nbsp; {t("profile.verification-mention-2")}
                      </p> */}
                    </div>
                  </form>
                ) : (
                  <div className="verification-error">
                    <p>
                      {t("top-profile.otp-failed-word")}{" "}
                      {t("top-profile.otp-failed-msg")}
                    </p>
                  </div>
                )}
              </>
            ) : this.props.securityPin === "change-new-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white"
                  closeModalHandler={this.props.closeAddSecurityPin}
                  header={t('profile.enter-new-security-pin')}
                  style={{ justifyContent: "unset" }}
                />

                <form
                  className="forms-col needs-validation my-3"
                  method="post"
                  onSubmit={this.props.onUpdateSecurityPinHandler}
                >
                  <div className="pd-form-m-col">
                    <div className="input-group-col">
                      <h5>{t("profile.set-your-pin")}</h5>
                      <span>
                        {t("profile.security-pin-desc")}
                      </span>
                      <ReactPinField
                        className="pin-field mt-3"
                        length="6"
                        validate="0123456789"
                        type="password"
                        value={this.state.securityPin}
                        onComplete={this.props.onInputChange}
                        ref={this.props.onRef}
                      />
                    </div>
                    <button
                      className="btn btn-gradient-blue mx-3"
                      type="submit"
                      value="Submit"
                    >
                      {t("global.submit")}
                    </button>
                    {/* <p className="sp-mention">
                      {t("profile.verification-mention-1")} &nbsp;
                      <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                      &nbsp; {t("profile.verification-mention-2")}
                    </p> */}
                  </div>
                </form>
              </>
            ) : this.props.securityPin === "edit-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white edit-sec-header"
                  closeModalHandler={this.props.closeAddSecurityPin}
                  header={t('profile.edit-security-pin')}

                  modalHeader={t('global.remove')}
                  modalHandler={this.props.openRemovePin}
                />

                <form
                  className="forms-col needs-validation my-3"
                  method="post"
                  onSubmit={this.props.onEditSecurityPinHandler}
                >
                  <div className="pd-form-m-col">
                    <div className="input-group-col">
                      <h5>{t("profile.change-pin")}</h5>
                      <span>
                        {t("profile.edit-pin-desc")}
                      </span>
                      <ReactPinField
                        className="pin-field mt-3"
                        length="6"
                        validate="0123456789"
                        type="password"
                        value={this.state.securityPin}
                        onComplete={this.props.onInputChange}
                        ref={this.props.onRef}
                      />
                      {this.props.securityPinStatusClass && (
                        <p
                          className="forget-pin"
                          onClick={this.props.onForgetSecurityHandler}
                        >
                          {t("profile.forget-security-pin")}
                        </p>
                      )}
                    </div>
                    <button
                      className="btn btn-gradient-blue mx-3"
                      type="submit"
                      value="Submit"
                    >
                      {t("global.submit")}
                    </button>
                    {/* <p className="sp-mention">
                      {t("profile.verification-mention-1")} &nbsp;
                      <Link to={{ pathname: "/" }}>{t("global.submit")}</Link>
                      &nbsp;  {t("profile.verification-mention-2")}
                    </p> */}
                  </div>
                </form>
              </>
            ) : this.props.securityPin === "remove-sec-pin" ? (
              <>
                <MobileSubHeader
                  className="bg-white"
                  closeModalHandler={this.props.closeRemoveSecPin}
                  header={t("profile.remove-security-pin")}
                  style={{ justifyContent: "unset" }}
                />

                <form
                  className="forms-col needs-validation my-3"
                  method="post"
                  onSubmit={this.props.onRemoveSecurityPinHandler}
                >
                  <div className="pd-form-m-col">
                    <div className="input-group-col">
                      <h5>{t('profile.enter-current-security-pin')}</h5>
                      <span>
                        {t("profile.enter-current-security-pin")}
                      </span>
                      <ReactPinField
                        className="pin-field mt-3"
                        length="6"
                        validate="0123456789"
                        type="password"
                        value={this.state.securityPin}
                        onComplete={this.props.onInputChange}
                        ref={this.props.onRef}
                      />

                      <p
                        className="forget-pin"
                        onClick={this.props.onForgetSecurityHandler}
                      >
                        {t("profile.forget-security-pin")}
                      </p>

                    </div>
                    <button
                      className="btn btn-gradient-blue mx-3"
                      type="submit"
                      value="Submit"
                    >
                      {t("global.submit")}
                    </button>
                    {/* <p className="sp-mention">
                      {t("profile.verification-mention-1")} &nbsp;
                      <Link to={{ pathname: "/" }}>{t("global.submit")}</Link>
                      &nbsp;  {t("profile.verification-mention-2")}
                    </p> */}
                  </div>
                </form>
              </>
            ) : (
              <></>
            )}
          </section>
        ) : (
          <></>
        )}
      </Auxiliary>
    );
  }
}

export default withNamespaces("translation")(MobileSecurityPin);
