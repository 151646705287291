import * as type from './types';
import axios from '../../../common/config/axios';

export const postIsAuthenticated = (authStatus) => dispatch => {
    dispatch ({
        type: type.POST_IS_AUTHENTICATED,
        payload: {
            "data": authStatus
        }
    });
};

//Call the api for example call the login api
export const postLogin = (res_data) => dispatch => {
    axios.post('login', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.POST_LOGIN,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.POST_LOGIN,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

//Call the api for example call the login api
export const postLogOut = () => dispatch => {
    axios.post('logout', {
    }).then((response) => {
        dispatch({
            type: type.POST_LOG_OUT,
            payload: response.data
        })
    }).catch(err => {
        console.warn(err);
    })
};

//Call the api for example call the login api
export const updateFingerPrint = (res_data) => dispatch => {
    axios.post('update_fingerprint', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.UPDATE_FINGERPRINT,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.UPDATE_FINGERPRINT,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};