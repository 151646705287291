import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import slugConf from "../../../common/data/games.json";

class LotteryHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            vendorList: []
        }
    }

    componentDidMount(){}

    shouldComponentUpdate(nextProps, nextState){
        // console.log("[shouldComponentUpdate]", nextProps);
        if(nextProps.vendor!==undefined && nextProps.vendor!==this.props.vendor){
            if(nextProps.vendor.responseCode===200 && nextProps.vendor.msgType==="success"){
                let vendorList = nextProps.vendor.data.vendor_list;
                let lotteryVendorList = [];
                if(vendorList.length>0){
                    vendorList.forEach((item)=>{
                        if(item.game_type_slug === "ke"){
                            lotteryVendorList.push(item);
                        }
                    });
                }
                this.setState({
                    loading: false,
                    vendorList: lotteryVendorList
                });
            }
        }
        return true;
    }

    render(){
        const { t } = this.props;
        let imgDropdown = this.props.imgDropdown;
        let lottery = this.state.vendorList ? this.state.vendorList : [];
        return (
            <>
            {(!this.state.loading && lottery) &&
                lottery.map((item, index) => {
                    if(item.maintenance_status===0){
                        let link = ((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || ''); 
                        return (
                            <Link to={{pathname: `/lottery/${link}`,state:{vendorSlug: item.game_vendor_slug}}} className="col-3 position-relative" key={index}>
                                <div className="dropdown-title">
                                    <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt={`${item.game_vendor_slug}-icon`} />
                                    <p>{item.game_vendor_title}</p>
                                </div>
                                <div className="more-btn-blue mt-1">{t("page-header.enter")}</div>
                                <img className="dropdown-img" src={imgDropdown[`ltr-${item.game_vendor_slug}-img.webp`]} alt='' />
                            </Link>
                        );
                    }
                    else{
                        return (
                            <div className="col-3 position-relative" key={index}>
                                <div className="dropdown-title">
                                    <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt={`${item.game_vendor_slug}-icon`} />
                                    <p>{item.game_vendor_title}</p>
                                </div>
                                <div className="more-btn-blue mt-1">{t("global.under-maintenance")}</div>
                                <img className="dropdown-img" src={imgDropdown[`ltr-${item.game_vendor_slug}-img.webp`]} alt='' />
                            </div>
                        );
                    }
                })
            }
            </>
        )
    }
}

const mapStateToProps = state => {
    // console.log("[mapStateToProps]", state);
    return {
        vendor: state.general.vendor,
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps)(withNamespaces("translation")(LotteryHeader));