import React, { Component } from "react";
import { connect } from "react-redux";
// import DomPurify from "../../../components/Sanitize/DomPurify";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { detectCountry } from "../../../hoc/Shared/utility";

//Miscellaneous
import ReactPlayer from "react-player";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { Popover, OverlayTrigger } from "react-bootstrap";
import AdsVideo from "../../../assets/images/videos/ads-video-popular-events.mp4";
import Slider from "react-slick";
//Icons
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

let dynamicCountry = detectCountry(window.location.host);

class EventSectionNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "football",
        };
    }

    changeTab = (tab) => {
        this.setState({
            activeTab: tab,
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    render() {
        // let imageLanguage = "";
        // switch (this.props.currentUser.language_code) 
        // {
        //     case "zh-CN":
        //         imageLanguage = "-cn";
        //     break;
        //     case "ms":
        //         imageLanguage = "-ms"
        //     break;
        //     default:
        //         imageLanguage = "";
        //     break;
        // }

        const { t } = this.props;
        // const videos = this.imageImport(require.context("../../../assets/images/videos/",false,/\.(png|jpe?g|svg|mp4)$/));
        const liveImg = this.imageImport(require.context("../../../assets/images/live-streaming", false, /\.(png|jpe?g|svg|webp)$/));
        const chinese = this.imageImport(require.context("../../../assets/images/home/chinese", false, /\.(png|jpe?g|svg|webp)$/));
        const raya = this.imageImport(require.context("../../../assets/images/home/raya", false, /\.(png|jpe?g|svg|webp)$/));
        const mooncake = this.imageImport(require.context("../../../assets/images/home/mooncake", false, /\.(png|jpe?g|svg|webp)$/));
        const halloween = this.imageImport(require.context("../../../assets/images/home/halloween", false, /\.(png|jpe?g|svg|webp)$/));
        const christmas = this.imageImport(require.context("../../../assets/images/home/christmas", false, /\.(png|jpe?g|svg|webp)$/));
        const uefa = this.imageImport(require.context("../../../assets/images/home/uefa", false, /\.(png|jpe?g|svg|webp)$/));

        // const fadeData = [1, 2, 3, 4, 5];
        const liveCtg = ["football", "basketball"];

        const popoverHoverFocus = (
            <Popover id="popover-trigger-hover-focus">
                <p className="px-3 py-2">{t('main-page.odds-content-1')}</p>
            </Popover>
        );

        let sabaOdds = this.props.sabaOdds ? this.props.sabaOdds : [];

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
        };

           

        return (

            <section className="events-section live-sport-event">
                <div className="x-container">
                    {this.props.currentEvent === "uefa" ? 
                    <>
                        <div className="global-game-section">
                            <div className="game-container bg-transparent shadow-none rounded-0">
                                <div className="game-category border-0">
                                    <div className="row justify-content-center">
                                    <div className="col-6  position-relative">
                                        <img className="w-100 h-auto" src={uefa["euro2024.webp"]} alt="euro 2024"/>
                                        <button
                                            className="uefa-col betnow"
                                            onClick={() => this.props.accessGame('sb')}
                                        >
                                            {t('main-page.bet-now')}
                                        </button>
                                    </div>
                                    <div className="col-6 position-relative">
                                        <img className="w-100 h-auto" src={uefa["uefa-prediction.webp"]} alt="uefa prediction"/>
                                        <Link className="uefa-col playnow" to={{ pathname: `/euro-2024` }}>{t('global.play-now')}</Link>
                                    </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="section-header mt-5 mb-3"
                        >
                            <div className="d-flex align-item-center">

                            <img src={uefa["euro2024_title.svg"]} alt="header-deco"/>
                            </div>
                            <p>{t("world-cup.team.ger")}</p>
                        </div>
                        <div className="position-relative">
                                <div className="events-video-container card-shadow">
                                    <div className="events-video-content">
                                        <div className="row justify-content-center gap-3">
                                            <div className="col-8">
                                                <div className="ratio ratio-16x9">
                                                    {this.props.data.length > 0 ? this.props.data.map((item, idx) => {
                                                        return (
                                                            <ReactPlayer
                                                                volume={0.3}
                                                                width="100%"
                                                                height="100%"
                                                                url={item.video_url}
                                                                onEnded={this.playNext}
                                                                muted
                                                                controls
                                                                playing
                                                            />
                                                        );
                                                    }) : (
                                                        <ReactPlayer
                                                            volume={0.3}
                                                            width="100%"
                                                            height="100%"
                                                            url={AdsVideo}
                                                            onEnded={this.playNext}
                                                            muted
                                                            controls
                                                            playing
                                                        />

                                                    )}
                                                </div>
                                            </div>
                                            <div className="banner-slick uefa">
                                            <div className="slick-container">
                                            <Slider ref={(c) => (this.slider = c)} {...settings}>
                                        {sabaOdds && sabaOdds.map((item, index) => {
                                                        if (item.sport_type === this.state.activeTab) {
                                                            return (
                                                                <div className="uefa-slider"  key={index}>
                                                                    <div className="uefa-wrap">
                                                                <div className="uefa-slider-content">
                                                                    {/* <Link className="event-sport-detail card-shadow" to={{ pathname: `/online-sports-games-${dynamicCountry.label}` }}> */}
                                                                    <div className="event-sport-detail card-shadow" onClick={() => this.props.accessGame('sb')}>
                                                                        <div className="event-sport-header">
                                                                            <p className="team-vs">{item.home_name} {t('global.vs')} {item.away_name}</p>
                                                                            <p>{item.match_date}</p>
                                                                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverHoverFocus}>
                                                                                <span className="info-icon">
                                                                                    <Icon icon="mdi:information-variant" />
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                        <div className="event-sport-content">
                                                                            <div className="team-1">
                                                                                <span>{t('global.h')}</span>
                                                                                <p>{item.home_name}</p>
                                                                            </div>
                                                                            <div className="team-2">
                                                                                <span>{t('global.a')}</span>
                                                                                <p>{item.away_name}</p>
                                                                            </div>
                                                                            <div className="event-sport-bet">
                                                                                <div className="sport-hdp">
                                                                                    <p>{item.home_ah}</p>
                                                                                    <span>{t('main-page.handicap')}</span>
                                                                                    <p>{item.away_ah}</p>
                                                                                </div>
                                                                                <div className="sport-hdp">
                                                                                    <p>{item.home_odds}</p>
                                                                                    <span>{t('main-page.odds')}</span>
                                                                                    <p>{item.away_odds}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    <div className="d-flex justify-content-center">
                                                                        <button
                                                                            className="btn-gradient-blue w-100 mx-3 mb-3"
                                                                            onClick={() => this.props.accessGame('sb')}
                                                                        >
                                                                            {t('main-page.bet-now')}
                                                                        </button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                </div>
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}



                                            </Slider>
                                            
                                            <div className="slick-arrow">
                                                <div
                                                className="slick-arrow-btn prev"
                                                onClick={() => this.slider.slickPrev()}
                                                >
                                                <Icon icon="uil:angle-left-b" />
                                                </div>
                                                <div
                                                className="slick-arrow-btn next"
                                                onClick={() => this.slider.slickNext()}
                                                >
                                                <Icon icon="uil:angle-right-b" />
                                                </div>
                                            </div>
                                            </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div
                                className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
                                    }`}
                            >
                                <div className="d-flex align-item-center">
                                    {this.props.currentEvent === "christmas" ? (
                                        <img
                                            src={christmas["title-deco-left.webp"]}
                                            alt="header-deco"
                                        />
                                    ) : this.props.currentEvent === "cny" ? (
                                        <img
                                            src={chinese["title-deco-left.webp"]}
                                            alt="header-deco"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {this.props.currentEvent === "cny" ? <h2>{t("main-page.popular-events")}</h2> : <h2 className="mb-2">{t("main-page.popular-events")}</h2>}
                                    {this.props.currentEvent === "christmas" ? (
                                        <img
                                            src={christmas["title-deco-right.webp"]}
                                            alt="header-deco"
                                        />
                                    ) : this.props.currentEvent === "cny" ? (
                                        <img
                                            src={chinese["title-deco-right.webp"]}
                                            alt="header-deco"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <p>{t("main-page.popular-events-remark")}</p>
                            </div>
                            <div className="global-game-section">
                                <div className="game-container bg-transparent shadow-none rounded-0">
                                    <div className="game-category border-0">
                                        <ul className="row justify-content-center">
                                            {liveCtg &&
                                                liveCtg.map((item, index) => (
                                                    <li className="game-ctg-col" key={index}>
                                                        <div
                                                            className={`game-link ${this.state.activeTab === item ? "active" : ""
                                                                }`}
                                                            onClick={() => this.changeTab(item)}
                                                        >
                                                            <span>
                                                                {this.state.activeTab === item ? (
                                                                    <img
                                                                        className="icon-white"
                                                                        src={liveImg[`${item}-active.webp`]}
                                                                        alt="live category"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="icon-grey"
                                                                        src={liveImg[`${item}.webp`]}
                                                                        alt="live category"
                                                                    />
                                                                )}
                                                            </span>
                                                            <p>{t(`live-stream.${item}`)}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                {this.props.currentEvent === "raya" ? (
                                    <div className="events-deco-image raya">
                                        <img className="deco-1" src={raya["content-deco-1.webp"]} alt="deco" />
                                    </div>
                                ) : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? (
                                    <div className="events-deco-image mooncake">
                                        <img className="deco-1" src={mooncake["content-deco-1.webp"]} alt="deco" />
                                        <img className="deco-2" src={mooncake["content-deco-1.webp"]} alt="deco" />
                                    </div>
                                ) : this.props.currentEvent === "halloween" ? (
                                    <div className="events-deco-image halloween">
                                        <img
                                            className="halloween-deco-3"
                                            src={halloween["content-deco-3.webp"]}
                                            alt="content-deco-3"
                                        />
                                    </div>
                                ) : this.props.currentEvent === "cny" ? (
                                    <div className="events-deco-image cny ">
                                        <img
                                            className="deco-1"
                                            src={chinese["content-deco-1.webp"]}
                                            alt="content-deco-3"
                                        />
                                    </div>
                                ) : this.props.currentEvent === "christmas" && (
                                    <div className="events-deco-image christmas">
                                        <img
                                            className="christmas-deco-3"
                                            src={christmas["deco-3.webp"]}
                                            alt="content-deco-3"
                                        />
                                    </div>
                                )}
                                <div className="events-video-container card-shadow">
                                    {this.props.currentEvent === "cny" && (
                                        <img className="border-deco" src={chinese["border-bg.png"]} alt="border deco" />
                                    )}
                                    <div className="events-video-content">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="ratio ratio-16x9">
                                                    {/* <img className="stream-cover" src={liveImg["live-streaming-cover.webp"]} alt="cover"/> */}
                                                    {/* {this.props.loading ? (
                                                <Skeleton count={1} height={"100%"} />
                                            ) : this.props.data.length === 0 ? (
                                                <></>
                                            ) : (
                                                <>
                                                {this.props.data.map((item, idx) => {
                                                    if (this.props.promotedVideoId === item.video_id) {
                                                    return (
                                                        <ReactPlayer
                                                        volume={0.3}
                                                        width="100%"
                                                        height="100%"
                                                        url={item.video_url}
                                                        onEnded={this.playNext}
                                                        controls
                                                        playing
                                                        />
                                                    );
                                                    } else {
                                                    return null;
                                                    }
                                                })}
                                                </>
                                            )} */}
                                                    {this.props.data.length > 0 ? this.props.data.map((item, idx) => {
                                                        return (
                                                            <ReactPlayer
                                                                volume={0.3}
                                                                width="100%"
                                                                height="100%"
                                                                url={item.video_url}
                                                                onEnded={this.playNext}
                                                                muted
                                                                controls
                                                                playing
                                                            />
                                                        );
                                                    }) : (
                                                        <ReactPlayer
                                                            volume={0.3}
                                                            width="100%"
                                                            height="100%"
                                                            url={AdsVideo}
                                                            onEnded={this.playNext}
                                                            muted
                                                            controls
                                                            playing
                                                        />

                                                    )}
                                                </div>
                                                <div className="mt-5 d-flex justify-content-center">
                                                    {/* <Link
                                                className="btn-gradient-blue"
                                                to={{ pathname: `/online-sports-games-${dynamicCountry.label}` }}
                                            >
                                                {t('main-page.bet-now')}
                                            </Link> */}
                                                    <button
                                                        className="btn-gradient-blue"
                                                        onClick={() => this.props.accessGame('sb')}
                                                    >
                                                        {t('main-page.bet-now')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                {this.props.loading
                                                    ?
                                                    <Skeleton count={1} height={"100%"} />
                                                    :
                                                    <ul>
                                                        {sabaOdds && sabaOdds.map((item, index) => {
                                                            if (item.sport_type === this.state.activeTab) {
                                                                return (
                                                                    <li key={index}>
                                                                        {/* <Link className="event-sport-detail card-shadow" to={{ pathname: `/online-sports-games-${dynamicCountry.label}` }}> */}
                                                                        <div className="event-sport-detail card-shadow" onClick={() => this.props.accessGame('sb')}>
                                                                            <div className="event-sport-header">
                                                                                <p className="team-vs">{item.home_name} {t('global.vs')} {item.away_name}</p>
                                                                                <p>{item.match_date}</p>
                                                                                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverHoverFocus}>
                                                                                    <span className="info-icon">
                                                                                        <Icon icon="mdi:information-variant" />
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                            <div className="event-sport-content">
                                                                                <div className="team-1">
                                                                                    <span>{t('global.h')}</span>
                                                                                    <p>{item.home_name}</p>
                                                                                </div>
                                                                                <div className="team-2">
                                                                                    <span>{t('global.a')}</span>
                                                                                    <p>{item.away_name}</p>
                                                                                </div>
                                                                                <div className="event-sport-bet">
                                                                                    <div className="sport-hdp">
                                                                                        <p>{item.home_ah}</p>
                                                                                        <span>{t('main-page.handicap')}</span>
                                                                                        <p>{item.away_ah}</p>
                                                                                    </div>
                                                                                    <div className="sport-hdp">
                                                                                        <p>{item.home_odds}</p>
                                                                                        <span>{t('main-page.odds')}</span>
                                                                                        <p>{item.away_odds}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </Link> */}
                                                                    </li>
                                                                );
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
    };
};

export default connect(mapStateToProps)(withNamespaces("translation")(EventSectionNew));

