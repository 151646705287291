import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {};

const spareDomainReducer = createReducer(initialState) ({
    [type.SPARE_DOMAIN_LIST]: (state, action) => ({
        ...state,
        spare_domain_list: action.payload,
    })
});

export default spareDomainReducer;