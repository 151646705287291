import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../../routes";

export class PageContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            route.component && (

                                <Route
                                    path={route.path}
                                    key={index}
                                    exact={route.exact}
                                    // component={() => <route.component {...this.props} />}
                                    component={route.component}
                                />

                            )
                        );
                    })}
                    <Redirect from="/" to="/" />
                    <Route render={() => <h1>Not Found</h1>} />
                </Switch>
            </>
        );
    }
}

export default PageContent;
