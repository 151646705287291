import React, { Component } from "react";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";

//Icons
import { Icon } from "@iconify/react";

class InnerFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const img = this.imageImport(
      require.context(
        "../../../assets/images/profile/",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    return (
      <Auxiliary>
        <section className="pf-dashboard">
          <div className="x-container">
            <ProfileSideBar />
            <div className="row g-3">
              <div className="col-12">
                <div className="pd-card-col h-100">
                  <h4>
                    <button className="btn icon me-2" type="button">
                      <Icon icon="uil:angle-left-b" />
                    </button>
                    Feedback Details
                  </h4>
                  <div className="pd-content ">
                    <div className="msg-inner-col feedback">
                      <div className="msg-inner-header">
                        <div className="msg-inner-title">
                          <img src={icons['feedback.svg']} alt="feedback-ico" />
                          <h5>Deposit Problem</h5>
                        </div>
                        <div>
                          <button className="btn icon" type="button" disabled>
                            <Icon icon="uil:angle-left-b" />
                          </button>
                          <button className="btn icon" type="button">
                            <Icon icon="uil:angle-right-b" />
                          </button>
                        </div>
                      </div>
                      <div className="msg-inner-content">
                        <ul className="list-group">
                          <li className="list-group-item">
                            <img src={img['profile-img.jpg']} alt="profile-img" />
                            <div>
                              <p>
                                Dear customer: Ssangyong Tournament! Year-end feast! Reach the top! Baccarat will host the year-end finals of the Baccarat Contest from December 15, 2021 to December 16, 2021. Members who win the championship can receive up to 1,000,000 yuan in prizes! Please click on the offer page to view~
                              </p>
                              <span>2021 -12-05 23:35:34</span>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <img src={img['cs-img.jpg']} alt="cs-img" />
                            <div>
                              <p>
                                Dear customer: Ssangyong Tournament! Year-end feast! Reach the top! Baccarat will host the year-end finals of the Baccarat Contest from December 15, 2021 to December 16, 2021. Members who win the championship can receive up to 1,000,000 yuan in prizes! Please click on the offer page to view~
                              </p>
                              <span>2021 -12-05 23:35:34</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      </Auxiliary >
    );
  }
}

export default InnerFeedback;
