import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getDropDown = (data) => dispatch =>
{
    axios.get('withdrawal/drop_down', { 
      params: {
        "crypto": (data ? data['crypto'] : ""),
      }
    }).then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const store = (data) => dispatch =>
{
    // console.log(data);
    axios.post('withdrawal/store', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const addWithdrawalCryptoAddress = (data) => dispatch =>
{
    // console.log(data);
    axios.post('withdrawal/add_withdrawal_address', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: actionType.STORE_WITHDRAWAL_CRYPTO_ADDRESS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getWithdrawalCryptoAddress = (data) => dispatch =>
{
  axios.get('withdrawal/crypto_withdrawal_address_list', {
    params: {
      "crypto": data['crypto']
    }
  }).then(function (response) 
  {
    dispatch (
    {
      type: actionType.GET_WITHDRAWAL_CRYPTO_ADDRESS,
      value: response.data
    })
  })
  .catch(err => 
  {
    
  });
};

export const cryptoWithdrawalStore = (data) => dispatch =>
{
    // console.log(data);
    axios.post('withdrawal/crypto', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: actionType.CRYPTO_WITHDRAWAL_STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getWithdrawalDetails= (data) => dispatch =>
{
  axios.get('withdrawal/details', {
    params: {
      "group_id": data['group_id']
    }
  }).then(function (response) 
  {
    dispatch (
    {
      type: actionType.WITHDRAWAL_DETAILS,
      value: response.data
    })
  })
  .catch(err => 
  {
    
  });
};

export const getCryptoExchangeRates = (data) => dispatch =>
{
    axios.get('withdrawal/crypto_exchange_rate', { 
      params: {
        "amount": data['amount'],
        "currency": data['currency'],
        "crypto": data['crypto']
      }
    }).then(function (response) 
    {
      dispatch (
      {
        type: actionType.GET_CRYPTO_EXCHANGE_RATES,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};