import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

//Miscellaneous
import FlipNumbers from "react-flip-numbers";
import { isDesktop } from "react-device-detect";
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // timeRemaining: "00,000,000.00",
      timeRemaining: this.initializeJackpot(),
      number: 0,
    };
  }
  componentDidMount() {
    this.timer = setInterval(() => {
      // const randomNum = genRandom(0.00000001, 0.0000001, 10);
      // const randomNum = this.genRandom(20, 100, 2);
      // let date = new Date();
      // let day = date.getDay();

      const randomNum = this.genRandom(0, 20, 2);
      const timestamp = Date.now();
      let prizePool = parseFloat(timestamp.toString().slice(4, 11)) + parseFloat(randomNum);
      
      const newNumber = (parseFloat(prizePool)).toFixed(2);
      const newNumber2 = this.padLeadingZeros(newNumber.toString(), 11);

      // console.log(timestamp+' | '+prizePool+' | '+randomNum);

      this.setState({
        number: newNumber,
        timeRemaining: newNumber2,
      });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  initializeJackpot = () => {
    const randomNum = this.genRandom(0, 20, 2);
    const timestamp = Date.now();
    let prizePool = parseFloat(timestamp.toString().slice(4, 11)) + parseFloat(randomNum);
      
    const newNumber = (parseFloat(prizePool)).toFixed(2);
    const newNumber2 = this.padLeadingZeros(newNumber.toString(), 11);
    return newNumber2;
  }

  genRandom = (min, max, decimalPlaces) => {
    var random = Math.random() * (max - min) + min;
    var power = Math.pow(10, decimalPlaces);
    return Math.floor(random * power) / power;
  };

  padLeadingZeros = (num, size) => {
    var s = String(num).padStart(size, "0");
    return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const img = this.imageImport(
      require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
    );
    return (
      <Auxiliary>
        <div className="flip-number">
          <img src={img["pool-bg.png"]} alt="pool-bg"/>
          <FlipNumbers
            play
            color="#6C7BA8"
            // background="#fff"
            width={(isDesktop ? 40 : 50 )}
            height={(isDesktop ? 70 : 90 )}
            numbers={this.state.timeRemaining}
            nonNumberStyle = {{
              backgroundImage:"none",
              fontWeight:"900",
              color:"white"
            }}
          />
        </div>
      </Auxiliary>
    );
  }
}

export default Banner;
