import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const depositReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.CRYPTO]: (state, action) => ({
        ...state,
        crypto: action.value,
    }),
    [type.GET_CURRENCY_FPAY]: (state, action) => ({
        ...state,
        currency: action.value,
    }),
    [type.GET_BANK_OPTIPN_SECRET_PAY]: (state, action) => ({
        ...state,
        bank_option: action.value,
    }),
    [type.DIREPAY_CRYPTO]: (state, action) => ({
        ...state,
        deposit_crypto_result: action.value,
    }),
    [type.CRYPTO_TRANS_STATUS]: (state, action) => ({
        ...state,
        crypto_trans_status: action.value,
    }),
    [type.DEFAULT_DROPDOWN_OPTIONS]: (state, action) => ({
        ...state,
        default_dropdown_options: action.value 
    }),
    [type.DEFAULT_SECRET_PAY_BANK_OPTIONS]: (state, action) => ({
        ...state,
        default_secretpay_bank_options: action.value
    }),
    [type.GET_BANK_OFFLINE_OPTION_SECRET_PAY]: (state, action) => ({
        ...state,
        bank_offline_option: action.value,
    }),
    [type.DEFAULT_SECRET_PAY_BANK_OFFLINE_OPTIONS]: (state, action) => ({
        ...state,
        default_secretpay_bank_offline_options: action.value
    }),
    [type.GET_EWALLET_TYPE]: (state, action) => ({
        ...state,
        ewallet_type_option: action.value
    }),
});

export default depositReducer;