import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom';
import { withNamespaces } from "react-i18next";
import { detectCountry } from "../../hoc/Shared/utility";

let dynamicCountry = detectCountry(window.location.host);

class MobileGameNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEvent: "",
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const icon = this.imageImport(
      require.context(
        "../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );


    const ctg = [
      { name: t("page-header.live-casino"), icon: "lc-m.svg", game: "casino", activeIcon: "lc-active-m.svg", link: `/online-live-games-${dynamicCountry.label}` },
      { name: t("page-header.sports"), icon: "sport-m.svg", game: "sport", activeIcon: "sport-active-m.svg", link: `/online-sports-games-${dynamicCountry.label}` },
      { name: t("page-header.esports"), icon: "esports-m.svg", game: "esport", activeIcon: "esports-active-m.svg", link: `/e-sports` },
      { name: t("page-header.slots"), icon: "slot-m.svg", game: "slot", activeIcon: "slot-active-m.svg", link: "/slot" },
      { name: t("page-header.games"), icon: "games-m.svg", game: "games", activeIcon: "games-active-m.svg", link: "/games" },
      { name: t("page-header.p2p"), icon: "p2p-m.svg", game: "p2p", activeIcon: "p2p-active-m.svg", link: "/p2p" },
      { name: t("page-header.lottery"), icon: "lottery-m.svg", game: "keno", activeIcon: "lottery-active-m.svg", link: "/lottery" },
    ];

    return (
      <div className='game-m-nav-v2'>
          {
            ctg && ctg.map((item, index) => (
              <div className='ratio-1x1'>
              <span key={index} className={`game-nav-btn ${(this.props.gameType === item.game) ? 'active' : ''} ${this.state.currentEvent && 'event'}`} activeClassName='active' onClick={() => this.props.changeGameHandler(item.game, item.link)}>
                  <img className='nav-img' src={icon[item.icon]} alt='mobile-nav-img' />
                  <img className='active-nav-img' src={icon[item.activeIcon]} alt='mobile-active-nav-img' />
                  {
                    this.state.currentEvent === "raya" && (
                      <img className='event-btn-deco' src={raya["btn-deco.webp"]} alt="btn deco"/>
                    )
                  }
                  <p>{item.name}</p>
                </span>
                {/* <NavLink to={item.link} className="game-nav-btn" activeClassName='active'>
                  <img className='nav-img' src={icon[item.icon]} alt='mobile-nav-img' />
                  <img className='active-nav-img' src={icon[item.activeIcon]} alt='mobile-active-nav-img' />
                  <p>{item.name}</p>
                </NavLink> */}
              </div>

            ))
          }
        </div>
    )
  }
}

export default withNamespaces("translation")(MobileGameNav);