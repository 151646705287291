import axios from "../../../common/config/axios";
import * as type from "./types";

export const getData = (data) => (dispatch, getState) => {
    axios.post("event_qna/get_qna_data", data)
    .then((res) => {
            dispatch({
                type: type.GET_DATA,
                payload: res,
            });
        }).catch((err) => {
            console.log("Get Event QNA Data Error", err);
            dispatch({
                type: type.GET_DATA,
                payload: [],
            });
        });
};

export const createBet = (data) => (dispatch, getState) => {
    axios.post("event_qna/create_bet", data)
        .then((res) => {
            dispatch({
                type: type.CREATE,
                payload: res.data,
            });
        }).catch((err) => {
            console.log("Create Event QNA Error\n", err);
            dispatch({
                type: type.CREATE,
                payload: err.data,
            });
        });
};

export const submitBet = (data) => (dispatch, getState) => {
    axios.post("event_qna/submit_bet", data)
        .then((res) => {
            dispatch({
                type: type.SUBMIT,
                payload: res.data,
            });
        }).catch((err) => {
            console.log("Submit Event QNA Error\n", err);
            dispatch({
                type: type.SUBMIT,
                payload: err.data,
            });
        });
};

export const getHistoryByDate = (data) => (dispatch, getState) => {
    axios.post("event_qna/get_history_by_date", data)
        .then((res) => {
            dispatch({
                type: type.GET_HISTORY,
                payload: res.data,
            });
        }).catch((err) => {
            console.log("Get Event QNA History Error\n", err);
            dispatch({
                type: type.GET_HISTORY,
                payload: err.data,
            });
        });
};