import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const inboxReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name

    [type.GET_INBOX]: (state, action) => ({
        ...state,
        get_inbox_data: action.payload,
    }),

    [type.SHOW_INBOX]: (state, action) => ({
        ...state,
        show_inbox_data: action.payload,
    }),

    [type.DELETE_INBOX]: (state, action) => ({
        ...state,
        delete_inbox_result: action.payload,
    }),

    [type.READ_INBOX]: (state, action) => ({
        ...state,
        read_inbox_data: action.payload,
    }),

    [type.GET_COUNT_UNREAD_INBOX_ALERT]: (state, action) => ({
        ...state,
        count_unread_inbox_alert_data: action.payload,
    }),

    [type.GET_INBOX_ALERT]: (state, action) => ({
        ...state,
        inbox_alert_data: action.payload,
    }),

    [type.LOAD_MORE_INBOX_ALERT]: (state, action) => ({
        ...state,
        inbox_alert_data: {
            ...state.inbox_alert_data,
            data: {
                ...state.inbox_alert_data.data,
                result: {
                    ...state.inbox_alert_data.data.result,
                    row: state.inbox_alert_data.data.result.row.concat(action.payload)
                }
            }
        },
    }),

    [type.READ_INBOX_ALERT]: (state, action) => ({
        ...state,
        inbox_alert_data: {
            ...state.inbox_alert_data,
            data: {
                ...state.inbox_alert_data.data,
                result: {
                    ...state.inbox_alert_data.data.result,
                    row: [...state.inbox_alert_data.data.result.row.map((item,index) => (index === action.index) ? {...item, status: 1} : item)],
                    // row: {
                    //     ...state.inbox_alert_data.data.result.row,
                    //     [action.index]: {
                    //         ...state.inbox_alert_data.data.result.row[action.index],
                    //         status: 1
                    //     }
                    // }
                }
            }
        },
    }),

    [type.GET_NOTIFICATION]: (state, action) => ({
        ...state,
        get_notification_data: action.payload,
    }),

    [type.READ_NOTIFICATION]: (state, action) => ({
        ...state,
        read_notification_data: action.payload,
    }),

    [type.DELETE_NOTIFICATION]: (state, action) => ({
        ...state,
        delete_notification_result: action.payload,
    }),

    [type.GET_NOTIFICATION_SETTINGS]: (state, action) => ({
        ...state,
        get_notification_settings_data: action.payload,
    }),

    [type.UPDATE_NOTIFICATION_SETTINGS]: (state, action) => ({
        ...state,
        update_notification_settings_data: action.payload,
    })
});

export default inboxReducer;