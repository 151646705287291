import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const promotedEventsReducer = createReducer(initialState) ({
    [type.GET_PROMOTED_EVENTS]: (state, action) => ({
        ...state,
        promoted_events_data: action.payload,
    })
});

export default promotedEventsReducer;