import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const bannerReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name

    [type.SHOW_BANNER]: (state, action) => ({
        ...state,
        show_banner_data: action.payload,
    }),

});

export default bannerReducer;