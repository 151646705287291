import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => (dispatch, getState) =>
{
    props.country_id = getState().currentUser.data.country_id;
    axios.get('bonus/list', {params: props})
    .then(function (response)
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data,
        })
    })
    .catch(err =>
    {

    });
};

export const applyResueBonus = (props) => (dispatch) =>
{
    axios.post('rescue_bonus/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.APPLY_RESCUE_BONUS,
            value: response.data
        });
    })
    .catch(err => 
    {
      
    });
};

export const claimFirstBetBonus = (props) => (dispatch) =>
{
    axios.post('first_bet_bonus/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.CLAIM_FIRST_BET_BONUS,
            value: response.data
        });
    })
    .catch(err => 
    {
      
    });
};

export const redeemVoucherCode = (props) => (dispatch,getState) =>
{
    props.member_id = getState().currentUser.data.member_id;
    props.country_id = getState().currentUser.data.country_id;
    axios.post('voucher/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.REDEEM_VOUCHER_CODE,
            value: response.data
        });
    })
    .catch(err => 
    {
        dispatch (
        {
            type: actionType.REDEEM_VOUCHER_CODE,
            value: err.data
        });
    });
};

export const applyDelayBonus = (props) => (dispatch) =>
{
    axios.post('delay_bonus/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.APPLY_DELAY_BONUS,
            value: response.data
        });
    })
    .catch(err => 
    {
      
    });
};
