import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const eventsReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.INDEX]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.CLAIM]: (state, action) => ({
        ...state,
        claim: action.value,
    })
});

export default eventsReducer;