import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import MobileInnerMessage from "./MobileInnerMessage";


//Icons
import { Icon } from "@iconify/react";
import DomPurify from "../../../components/Sanitize/DomPurify";

class InnerMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      currentId: 0,
      msgType: "",
      currentIndex: 0,
      msgStatus: 0
    };
  }

  componentDidMount() {
    // console.log("[componentDidMount]");
    this.setData();
  }

  setData = () => {
    let currentIndex = 0;
    this.props.data.map((item, idx) => {
      if (item.Id === this.props.currentId) {
        currentIndex = idx;
      }
      return null;
    });
    this.setState({
      loading: false,
      data: this.props.data,
      currentId: this.props.currentId,
      msgType: this.props.msgType,
      currentIndex: currentIndex,
      msgStatus: this.props.msgStatus
    }, () => {
      if (parseInt(this.props.msgStatus) === 0) {
        this.props.handleReadMsg(this.props.currentId, this.props.msgType);
      }
    });
  }

  navigateMsg = (event, type) => {
    let newIndex = this.state.currentIndex;
    switch (type) {
      case "prev":
        newIndex = newIndex - 1;
        break;
      case "next":
        newIndex = newIndex + 1;
        break;
      default: 
      break;
    }
    this.setState({
      currentIndex: newIndex
    }, () => {
      let data = this.state.data;
      if (data[newIndex].status === 0) {
        this.props.handleReadMsg(data[newIndex].Id, this.props.msgType);
      }
    });
  }

  render() {
    const { t } = this.props;
    let lastIndex = this.state.data.length - 1;
    let data = this.state.data ? this.state.data.map((item, idx) => {
      // console.log(item)
      return (
        <div className="pd-content">
          <div className="msg-inner-col">
            <div className="msg-inner-header">
              <div className="msg-inner-title">
                <h5>{item.title}</h5>
                <p>{item.date}</p>
              </div>
              <div>
                <button className="btn icon trash" type="button" onClick={(event) => this.props.handleDeleteMsg(event, item.Id, this.state.msgType)}>
                  <Icon icon="uil:trash-alt" />
                </button>
                <button className={`btn icon ${idx !== 0 ? "" : "disabled"}`} type="button" onClick={idx !== 0 ? (event) => this.navigateMsg(event, "prev") : null}>
                  <Icon icon="uil:angle-left-b" />
                </button>
                <button className={`btn icon ${idx !== lastIndex ? "" : "disabled"}`} type="button" onClick={idx !== lastIndex ? (event) => this.navigateMsg(event, "next") : null}>
                  <Icon icon="uil:angle-right-b" />
                </button>
              </div>
            </div>
            <div className="msg-inner-content">
              <p>
                {/* {item.content.replace("&nbsp;","")} */}
                <DomPurify text={item.html_content} />
              </p>
            </div>
          </div>
        </div>
      );
    }) : [];
    // console.log("[render]",lastIndex, data, this.state.currentId, this.state.currentIndex, this.state.msgStatus);
    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <section className="pf-dashboard">
                <div className="x-container">
                  <ProfileSideBar />
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="pd-card-col h-100">
                        <h4>
                          <button className="btn icon me-2" type="button" onClick={(event) => this.props.handleChangePage(event, "listing", 0, [], "", 0, this.props.activePage)}>
                            <Icon icon="uil:angle-left-b" />
                          </button>
                          {t(`message.details.${this.state.msgType}`)}
                        </h4>
                        {data.length > 0 && data[this.state.currentIndex]}
                      </div>
                    </div>
                  </div>
                </div>
              </section >
            </>
          ) : (
            <MobileInnerMessage
              {...this.state}
              {...this.props}
              data={this.props.data}
              currentId={this.props.currentId}
              handleChangePage={this.props.handleChangePage}
              msgType={this.props.msgType}
              handleDeleteMsg={this.props.handleDeleteMsg}
              handleReadMsg={this.props.handleReadMsg}
              msgStatus={this.props.msgStatus}
              />
          )
        }
      </Auxiliary >

    );
  }
}

export default withNamespaces("translation")(InnerMessage);
