import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const angpauReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name

    [type.CLAIM_ANGPAU]: (state, action) => ({
        ...state,
        claim_angpau_result: action.value,
    }),
    [type.MOBILE_APPS_PROMO_CLAIM]: (state, action) => ({
        ...state,
        mobile_apps_promo_claim: action.value,
    })
});

export default angpauReducer;