import React, {Component, Fragment} from 'react';
import {
    required,
    minLength,
    checkPasswordMatch,
    validateDisplay,
    checkPasswordFormat
} from "../../../hoc/Shared/utility";
import PropTypes from 'prop-types';

class PasswordInput extends Component {
    constructor (props) {
        super (props);

        this.state = {
            value: '',
            requiredRules: required(false, this.props.value, this.props.requiredMessage),
            minLengthRules: minLength(false, this.props.value, this.props.minLength, this.props.minLengthMessage),
            checkPasswordRules: checkPasswordMatch(false, this.props.oldPassword, this.props.value, this.props.confirmPasswordMsg),
            checkPasswordFormatRules: checkPasswordFormat(false, this.props.value, this.props.passwordFormatMsg)
        };
    }

    // shouldComponentUpdate (nextProps) {
    //     return nextProps.value !== this.props.value;
    // }

    static getDerivedStateFromProps (nextProps, prevState) {

        if (
            (nextProps.value !== prevState.value)
        ) {

            let payload = {
                value: nextProps.value
            };

            if(nextProps.required){
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            if(nextProps.minLength){
                payload['minLengthRules'] = minLength(true, nextProps.value, nextProps.minLength, nextProps.minLengthMessage);
            }

            if (nextProps.confirmpassword){
                payload['checkPasswordRules'] = checkPasswordMatch(true, nextProps.oldPassword, nextProps.value, nextProps.confirmPasswordMsg)
            }

            if (nextProps.passwordformat){
                payload['checkPasswordFormatRules'] = checkPasswordFormat(true, nextProps.value, nextProps.passwordFormatMsg)
            }

            return payload;

        }

        return null;
    }

    render() {

        const { id, name, onChange, className, disabled, required, placeholder, minLength,  maxLength, onBlur, passwordformat, confirmpassword, showPwd, showPwdConfirmation, formError, errorMsg} = this.props;
        const { value, requiredRules, minLengthRules, checkPasswordRules, checkPasswordFormatRules } = this.state;

        const [ requiredClass ] = validateDisplay(requiredRules);
        const [ minLengthClass ] = validateDisplay(minLengthRules);
        const [ checkPasswordClass, checkPasswordMsg ] = validateDisplay(checkPasswordRules);
        const [ passwordFormatClass, passwordFormatMsg ] = validateDisplay(checkPasswordFormatRules);

        return (
            <Fragment>
                <input
                    type={`${showPwd === true || showPwdConfirmation === true ? 'text': 'password'}`}
                    id={id}
                    value={value}
                    // className={`form-control ${requiredClass === "is-invalid" ? "is-invalid": ""} ${minLengthClass} ${checkPasswordClass} ${passwordFormatClass} ${className}`}
                    className={`form-control ${requiredClass === "is-invalid" ? "is-invalid": ""} ${minLengthClass==='is-invalid'||checkPasswordClass==='is-invalid'||passwordFormatClass==='is-invalid'?'is-invalid':(minLengthClass||checkPasswordClass||passwordFormatClass)} ${className}`}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    pattern={passwordformat}
                    confirmpassword={confirmpassword}
                />
                {this.props.format}
                <div className="invalid-feedback">
                    {
                    (requiredClass !== 'is-valid' && this.props.requiredMessage)
                        || (requiredClass === 'is-valid' && minLengthClass === 'is-invalid' && this.props.minLengthMessage) 
                        || (requiredClass === 'is-valid' && checkPasswordClass === 'is-invalid' && checkPasswordMsg) 
                        || (requiredClass === 'is-valid' && passwordFormatClass === 'is-invalid' && passwordFormatMsg) 
                        || (formError && errorMsg)
                    }
                </div>
            </Fragment>
        )
    }
}

export default PasswordInput;

PasswordInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    minLength: PropTypes.any,
    maxLength: PropTypes.any,
    onBlur: PropTypes.func,
    passwordformat: PropTypes.string,
    // passwordFormatMsg: PropTypes.string,
    confirmpassword: PropTypes.string,
    confirmPasswordMsg: PropTypes.string,
    oldPassword: PropTypes.string,
    requiredMessage: PropTypes.string,
    minLengthMessage: PropTypes.string
};

PasswordInput.defaultProps = {
    className: ''
};