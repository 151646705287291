import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {};

const dailyMissionReducer = createReducer(initialState) ({
    [types.DAILY_MISSION]: (state, action) => ({
        ...state,
        daily_mission: action.payload
    }),
    [types.DAILY_MISSION_BONUSES]: (state, action) => ({
        ...state,
        daily_mission_bonuses: action.payload
    }),
    [types.DAILY_MISSION_BONUS_CLAIM]: (state, action) => ({
        ...state,
        daily_mission_bonus_claim: action.payload
    }),
    [types.DAILY_MISSION_EXTRA_BONUS_CLAIM]: (state, action) => ({
        ...state,
        daily_mission_extra_bonus_claim: action.payload
    })
});

export default dailyMissionReducer;

