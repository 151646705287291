import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const registerReducer = createReducer(initialState) ({
    [type.POST_REGISTER]: (state, action) => ({
        ...state,
        register_data: action.payload,
    }),
    [type.CHECK_USERNAME]: (state, action) => ({
        ...state,
        check_username_data: action.payload,
    }),
    [type.CHECK_REFERRAL_REGION]: (state, action) => ({
        ...state,
        check_referral_region: action.payload
    }),
    [type.CHECK_IP_REGION]: (state, action) => ({
        ...state,
        check_ip_region: action.payload
    })
});

export default registerReducer;