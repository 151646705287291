// import axios from "../../../common/config/axios";
import * as type from "./types";

export const getUserData = (data) => (dispatch) => {
    if(data) {
        dispatch({
            type: type.SET_USER,
            payload: data
        })
    }
};

export const changeCountry = (data) => (dispatch, getState) => {
    if(data) {
        let updatedData = getState().currentUser.data;
        updatedData.country_id = data.country_id;
        updatedData.country_code = data.country_code;
        // console.log("changeCountry Action",getState(), "\n", updatedData);
        dispatch({
            type: type.CHANGE_COUNTRY,
            payload: updatedData
        })
    }
};

export const changeLanguage = (data) => (dispatch, getState) => {
    if(data) {
        let updatedData = getState().currentUser.data;
        updatedData.language_id = data.language_id;
        updatedData.language_code = data.language_code;
        dispatch({
            type: type.CHANGE_LANGUAGE,
            payload: updatedData
        })
    }
};

// GAMES
export const setGameQT = (data) => (dispatch, getState) => {
    if(data) {
        dispatch({
            type: type.SET_GAME_QT,
            payload: data
        })
    }
};

export const setUrl = (data) => dispatch =>
{
  dispatch({
    type: type.SET_GAME_URL,
    payload: data
  })
};




// Unused

// export const getCurrentCountryId = () => (dispatch, getState) => {
//     const state = getState();
//     console.log(state);
//     dispatch ({
//         type: type.GET_COUNTRY_ID,
//     })
// }

// export const getCurrentUserGroup = () => dispatch => {
//     dispatch ({
//         type: type.GET_USER_GROUP,
//     })
// }

