import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // data: [],
};

const gameFavReducer = createReducer(initialState) ({
    [type.GAME_FAV]: (state, action) => ({
        ...state,
        game_fav: action.payload
    }),
    [type.UPDATE_GAME_FAV]: (state, action) => ({
        ...state,
        update_game_fav: action.payload
    })
});

export default gameFavReducer;