import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

//Miscellaneous
import { isDesktop } from "react-device-detect";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import Timer from "react-compound-timer";
import { Fade } from "react-awesome-reveal";

//Components
import Button from '../../../components/Button/Button';
import GamePagination from "../../../components/Pagination/GamePagination";
import NoRecords from "../../../components/NoRecords/NoRecords";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Icons
import { Icon } from "@iconify/react";

class LuckySpin extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {}
    }

    componentDidMount() 
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray('lucky_spin');
            this.props.funcGetList(listingArr);
        }
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item, index) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    getDuration = (createDate) => 
    {
        if (createDate) 
        {
            let tmpDate = createDate.split(' ');
            let createDateEof = moment(`${tmpDate[0]} 23:59:59`).tz("Asia/Kuala_Lumpur");
            const now = moment().tz("Asia/Kuala_Lumpur");
            const eof = moment().endOf('day').tz("Asia/Kuala_Lumpur");
            const duration = eof.diff(now, "milliseconds");
            return (duration < 0 || now > createDateEof) ? 0 : duration;
        }
        else 
        {
            return 0;
        }

        // example
        // const dateFromApi = "2022-09-26 00:00:10";
        // const createdAt = moment.tz(dateFromApi, "Asia/Kuala_Lumpur");
        // const now = moment.tz("2022-09-26 00:00:00", "Asia/Kuala_Lumpur");
        // const duration = createdAt.diff(now, "milliseconds");
        // return duration;
    }

    render() 
    {
        // console.log(this.props.data.data);
        const { t } = this.props;
        const img = this.imageImport(require.context("../../../assets/images/lucky-spin", false, /\.(png|jpe?g|svg|webp)$/));
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        return (
            <Auxiliary>
            {
                isDesktop ? (
                    <>
                        {this.props.data.loading === true
                        ?
                            <Skeleton count={2} height={'3.125rem'} style={{ marginBottom: ".25rem" }} />
                        :
                        <>
                            {this.props.data.loading === false && this.props.data.totalRecords <= 0
                            ?
                                <NoRecords component={'redemption-history'} />
                            :
                                <Fade duration={1000} triggerOnce>
                                    <table className="pd-table rh-pd-table">
                                    {
                                        this.props.data && this.props.data.data.map((item, index) => 
                                        {
                                            let strContent = [];
                                            let settingValue = (item.setting && item.setting !== null) ? JSON.parse(item.setting) : null;
                                            let imgVal = (item.category === 'gift' || item.category === 'rebate') ? item.prize : item.category;

                                            strContent.push(
                                                <>
                                                    <td colSpan={item.category === 'gift' ? 2 : 0}>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={img[`${imgVal}.webp`]} alt={`${item.prize}`} className="product-img" />
                                                            {item.category === "rebate" || item.category === "mb" 
                                                            ?
                                                                <span>
                                                                    <h6 className='mb-0'>{t(`lucky-spin.${item.prize}`)}</h6>
                                                                    <p>
                                                                        {this.props.currentUser.currency} {new Intl.NumberFormat("en-US", num_format).format(item.credit || 0.00)}
                                                                    </p>
                                                                </span>
                                                            :
                                                                <h6 className='mb-0'>{t(`lucky-spin.${item.prize}`, { currency: this.props.currentUser.currency ?? "" })}</h6>
                                                            }
                                                        </div>
                                                    </td>
                                                    {item.category === 'gift' ?
                                                        <>
                                                            <td>
                                                                <span className='mall-track-title'>
                                                                    <Icon icon="carbon:delivery" className="icon-img" />
                                                                    {
                                                                        (!this.props.data.address || this.props.data.address === null)
                                                                            ?
                                                                                <p className='deactive'>{t('redemption-history.prize-desc-1')}</p>
                                                                            :
                                                                            (item.status === 0)
                                                                            ?
                                                                                <p className='pending'>{t('redemption-history.prize-desc-3')}</p>
                                                                            :
                                                                                (item.reference_code && item.reference_code !== null)
                                                                                ?
                                                                                    <p className='active'>{t('redemption-history.prize-desc-5')}</p>
                                                                                :
                                                                                    <p className='pending'>{t('redemption-history.prize-desc-6')}</p>
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    (!this.props.data.address || this.props.data.address === null)
                                                                        ?
                                                                            // <Link to={{ pathname: "/personal-information" }}><span className="btn btn-gradient-blue float-end">{t('profile.add-address')}</span></Link>
                                                                            <Button
                                                                                typeButton="button"
                                                                                classValue="btn btn-gradient-blue float-end"
                                                                                buttonName={t('profile.add-address')}
                                                                                clicked={() => this.props.history.push('/personal-information')}
                                                                            />
                                                                        :
                                                                        (item.status === 0)
                                                                            ?
                                                                                <button onClick={(event) => this.props.updatePrizeHandler(event, item.id, this.props.data.address)} className="btn btn-gradient-blue float-end">{t('redemption-history.claim')}</button>
                                                                            :
                                                                            (item.reference_code && item.reference_code !== null && item.status === 1)
                                                                            ?
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => window.open(`https://www.tracking.my/track/${item.reference_code}`, '_blank')}
                                                                                    rel="noreferrer" className={`btn btn-gradient-blue float-end`}
                                                                                    // disabled={this.state.itemProcessing === (item.active) ? true : false}
                                                                                >
                                                                                    {t('redemption-history.track-parcel')}
                                                                                </button>
                                                                            :
                                                                                ''
                                                                }
                                                            </td>
                                                        </>
                                                        :
                                                        <>
                                                            <td>
                                                                {item.category === "bonus" || item.category === "mb" || item.category === "np"
                                                                    ?
                                                                    (settingValue && settingValue.turnover ? (<small className='interest-tnc'> <Icon icon="material-symbols:info" width="1rem" height="1rem" />{t('redemption-history.require-x-turnover', { attribute: settingValue.turnover })}</small>) : '')
                                                                    :
                                                                    <small className='interest-tnc'>
                                                                        <Icon icon="material-symbols:info" width="1rem" height="1rem" />
                                                                        {t('redemption-history.end-time')}:
                                                                        <p className='mx-2'>
                                                                            <Timer
                                                                                initialTime={this.getDuration(item.create_date)}
                                                                                direction="backward"
                                                                                formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                                            // onStop={(value) => {console.log('onStop hook')}}
                                                                            >
                                                                                (<Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />)
                                                                            </Timer>
                                                                        </p>
                                                                    </small>
                                                                }
                                                            </td>
                                                            <td>
                                                                <span className='mall-track-title'>
                                                                    <Icon icon="carbon:gift" className="icon-img" />
                                                                    {item.category === "np"
                                                                        ?
                                                                        <p className=''>{t('redemption-history.prize-desc-8')}</p>
                                                                        :

                                                                        (item.category === "bonus" || item.category === "mb") ?
                                                                            <p className='active'>{t('redemption-history.prize-desc-2')}</p>
                                                                            :
                                                                            <>
                                                                                {(this.getDuration(item.create_date) > 0)
                                                                                    ?
                                                                                    <p className='pending'>{t('redemption-history.prize-desc-4')}</p>
                                                                                    :
                                                                                    <p className='active'>{t('redemption-history.prize-desc-7')}</p>
                                                                                }
                                                                            </>
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                        </>
                                                    }
                                                </>
                                            );

                                            return (
                                                <tbody key={index} className="rdp-his-body">
                                                    <tr className="rdp-his-title">
                                                        <td className="d-flex align-items-center">
                                                            <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                                            <p className="ms-2">{item.create_date}</p>
                                                        </td>
                                                        <td colSpan="3"></td>
                                                    </tr>
                                                    <tr className="rdp-his-content">
                                                        {strContent}
                                                    </tr>
                                                </tbody>
                                            );
                                        })
                                    }
                                    </table>
                                </Fade>
                            }
                            <GamePagination
                                totalPage={this.props.data.totalPage}
                                curPage={this.props.data.activePage}
                                data={this.props.data.data}
                                navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'lucky_spin')}
                            />
                        </>}
                    </>
                ) : (
                    <div className="pf-m-dashboard">
                        <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
                        {this.props.data.loading === true
                        ?
                            <div className="pd-m-card-col white">
                                <div className='py-3'><Skeleton count={2} height={'3.125rem'} style={{ marginBottom: ".25rem" }} /></div>
                            </div>
                        :
                            <div className="pd-m-card-col">
                                {this.props.data.loading === false && this.props.data.totalRecords <= 0
                                    ?
                                        <NoRecords component={'redemption-history'} />
                                    :
                                    <>
                                        {
                                            this.props.data && this.props.data.data.map((item, index) => 
                                            {
                                                let strContent = [];
                                                let settingValue = (item.setting && item.setting !== null) ? JSON.parse(item.setting) : null;
                                                let imgVal = (item.category === 'gift' || item.category === 'rebate') ? item.prize : item.category;

                                                strContent.push(
                                                    <>
                                                        <div className="rh-m-md-panel">
                                                            <div className='rh-m-md-img'>
                                                                <img src={img[`${imgVal}.webp`]} alt={`${item.prize}`} />
                                                            </div>
                                                            <div className='rh-m-md-content'>
                                                                {item.category === "rebate" || item.category === "mb" ?
                                                                    <>
                                                                        <h6 className='mb-0'>{t(`lucky-spin.${item.prize}`)}</h6>
                                                                        <p className='mb-1'>
                                                                            {this.props.currentUser.currency} {new Intl.NumberFormat("en-US", num_format).format(item.credit || 0.00)}
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    <h6 className='mb-2'>{t(`lucky-spin.${item.prize}`, { currency: this.props.currentUser.currency ?? "" })}</h6>
                                                                }
                                                                {item.category !== "gift" &&
                                                                    <>
                                                                        {item.category === "bonus" || item.category === "mb" || item.category === "np" ?
                                                                            (settingValue && settingValue.turnover ? (<small className='interest-tnc'> <Icon icon="material-symbols:info" width="1.5rem" height="1.5rem" />{t('redemption-history.require-x-turnover', { attribute: settingValue.turnover })}</small>) : '')
                                                                            :
                                                                            <small className='interest-tnc'> <Icon icon="material-symbols:info" width="1.5rem" height="1.5rem" />
                                                                                {t('redemption-history.end-time')}:
                                                                                <p className='mx-2'>
                                                                                    <Timer
                                                                                        initialTime={this.getDuration(item.create_date)}
                                                                                        direction="backward"
                                                                                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                                                    // onStop={(value) => {console.log('onStop hook')}}
                                                                                    >
                                                                                        (<Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />)
                                                                                    </Timer>
                                                                                </p>
                                                                            </small>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="rh-m-btm-panel">
                                                            <div className='rh-m-btm-status'>
                                                                {item.category === 'gift' 
                                                                ?
                                                                    <>
                                                                        <Icon icon="carbon:delivery" className="icon-img" width="1.5rem" height="1.5rem" />
                                                                        {
                                                                            (!this.props.data.address || this.props.data.address === null)
                                                                                ?
                                                                                    <p className='deactive'>{t('redemption-history.prize-desc-1')}</p>
                                                                                :
                                                                                    (item.status === 0)
                                                                                    ?
                                                                                        <p className='pending'>{t('redemption-history.prize-desc-3')}</p>
                                                                                    :
                                                                                        (item.reference_code && item.reference_code !== null)
                                                                                        ?
                                                                                            <p className='active'>{t('redemption-history.prize-desc-5')}</p>
                                                                                        :
                                                                                            <p className='pending'>{t('redemption-history.prize-desc-6')}</p>
                                                                        }
                                                                    </>
                                                                :
                                                                    <>
                                                                        <Icon icon="carbon:gift" width="1.5rem" height="1.5rem" />
                                                                        {(item.category === "np")
                                                                            ?
                                                                                <p className=''>{t('redemption-history.prize-desc-8')}</p>
                                                                            :
                                                                            item.category === "bonus" || item.category === "mb" 
                                                                            ?
                                                                                <p className='active'>{t('redemption-history.prize-desc-2')}</p>
                                                                            :
                                                                                <>
                                                                                    {(this.getDuration(item.create_date) > 0)
                                                                                        ?
                                                                                        <p className='pending'>{t('redemption-history.prize-desc-4')}</p>
                                                                                        :
                                                                                        <p className='active'>{t('redemption-history.prize-desc-7')}</p>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            {item.category === 'gift' && (!this.props.data.address || this.props.data.address === null)
                                                            ?
                                                                // <Link className="btn btn-gradient-blue float-end" to={{ pathname: "/personal-information" }}>{t('profile.add-address')}</Link>
                                                                <Button
                                                                    typeButton="button"
                                                                    classValue="btn btn-gradient-blue float-end"
                                                                    buttonName={t('profile.add-address')}
                                                                    clicked={() => this.props.history.push('/personal-information')}
                                                                />
                                                            :
                                                                (item.status === 0)
                                                                ?
                                                                    <button onClick={(event) => this.props.updatePrizeHandler(event, item.id, this.props.data.address)} className="btn btn-gradient-blue float-end">{t('redemption-history.claim')}</button>
                                                                :
                                                                (item.reference_code && item.reference_code !== null && item.status === 1)
                                                                ?
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => window.open(`https://www.tracking.my/track/${item.reference_code}`, '_blank')}
                                                                        rel="noreferrer" className={`btn btn-gradient-blue float-end`}
                                                                        // disabled={this.state.itemProcessing === (item.active) ? true : false}
                                                                    >
                                                                        {t('redemption-history.track-parcel')}
                                                                    </button>
                                                                :
                                                                    ''
                                                            }
                                                        </div>
                                                    </>
                                                );

                                                return (
                                                    <div key={index} className='rh-m-pd-card'>
                                                        <div className="rh-m-tp-panel">
                                                            <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                                            <p>{item.create_date}</p>
                                                        </div>
                                                        {strContent}
                                                    </div>
                                                );
                                            })
                                        }
                                    </>
                                }
                                <GamePagination
                                    totalPage={this.props.data.totalPage}
                                    curPage={this.props.data.activePage}
                                    data={this.props.data.data}
                                    navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'lucky_spin')}
                                />
                            </div>
                        }
                    </div>
                )}
            </Auxiliary>
        )
    }
}

export default (withNamespaces("translation")(LuckySpin));