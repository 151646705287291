import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const authReducer = createReducer(initialState) ({
    [type.POST_LOGIN]: (state, action) => ({
        ...state,
        login_data: action.payload,
    }),

    [type.POST_LOG_OUT]: (state, action) => ({
        ...state,
        logout_data: action.payload,
    }),

    [type.POST_IS_AUTHENTICATED]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.UPDATE_FINGERPRINT]: (state, action) => ({
        ...state,
        update_fingerprint: action.payload,
    })
});

export default authReducer;