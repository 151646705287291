import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getGameFavList = (params) => dispatch => {
    axios.get('game_fav/list', {params: params})
    .then((res) => {
        dispatch({
           type: actionType.GAME_FAV,
           payload: res.data 
        });
    })
    .catch((err) => {

    });
};
export const updateGameFav = (params) => dispatch => {
    axios.post('game_fav/update', params)
    .then((res) => {
        dispatch({
            type: actionType.UPDATE_GAME_FAV,
            payload: res.data
        });
    })
    .catch((err) => {

    });
};