import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from "sweetalert2";

//Miscellaneous
import moment from "moment";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Button from "../../../components/Button/Button";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { isDesktop } from "react-device-detect";


//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import Rebate from "./Rebate";
import Promotion from "./Promotion";
import Bonus from "./Bonus";
import MobileTransactionHistory from "./MobileTransactionHistory";

//Icons
import { Icon } from "@iconify/react";

// Actions
import * as actions from "../../../store/ducks/history/actions";

const transactionTypes = [
    { id: "typeDeposit", value: "deposit", label: "deposit" },
    { id: "typeWithdrawal", value: "withdrawal", label: "withdrawal" },
    { id: "typePromotion", value: "promotion", label: "promotion" },
    { id: "typeRebate", value: "rebate", label: "rebate-cashback" },
    { id: "typeBonus", value: "bonus", label: "bonus" },
    // { id: "typeInterest", value: "interest", label: "interest" },
];

const filterDate = [
    { id: "filterToday", value: "0", label: "today" },
    { id: "filterYtd", value: "1", label: "yesterday" },
    { id: "filter7Day", value: "7", label: "last-7-days" },
    { id: "filter30Days", value: "30", label: "last-30-days" },
];

const initialTabState =
{
    /* datatables / pagination */
    activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
    limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    order: '',
    totalRecords: 0,

    /* api result array */
    data: [],

    /* skeleton loading */
    loading: true,

    /* for deposit/withdraw tab promotion usage */
    promoList: [],
    validPromo: {},
    promo_id: "",
    select_id: "",
};

class TransactionHistory extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            historyTab: (props.location.state && props.location.state.historyTab) ? props.location.state.historyTab : 'deposit',
            defaultTab: (props.location.state && props.location.state.defaultTab) ? props.location.state.defaultTab : 0,
            currentFilterDate: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            transaction_date: '',
            disableSearchButton: false,

            betting_summary: initialTabState,
            deposit: initialTabState,
            withdrawal: initialTabState,
            promotion: initialTabState,
            rebate: initialTabState,
            bonus: initialTabState,
            filterRange: 0,
            openModal: false,

            dates: null,

            /* mobile */
            showDateSelect: false,
            showDate: '',
            selectedDate: '',

            spin: false
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onPromoInputChangeHandler = this.onPromoInputChangeHandler.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') {
                let newTab = Object.assign({}, this.state[nextProps.rs.data.transaction_type]);
                newTab['data'] = nextProps.rs.data.result.row;
                newTab['totalRecords'] = nextProps.rs.data.result.pagination.total_record;
                newTab['loading'] = false;
                newTab['promoList'] = (nextProps.rs.data.valid_promo && nextProps.rs.data.promotion) ? nextProps.rs.data.promotion : [];
                newTab['validPromo'] = (nextProps.rs.data.valid_promo && nextProps.rs.data.promotion) ? nextProps.rs.data.valid_promo[0] : {};
                newTab['promo_id'] = '';
                newTab['select_id'] = '';

                this.setState({
                    [nextProps.rs.data.transaction_type]: newTab,
                    disableSearchButton: false,
                });
            }
            else
            {
                this.swalErrorHandler(nextProps.rs.message[0]);
            }
        }

        if (nextProps.cancel !== this.props.cancel && nextProps.cancel !== undefined) {
            if (nextProps.cancel.responseCode === 200 && nextProps.cancel.msgType === "success") {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
                    icon: "success",
                    title: t('global.success'),
                    // html: nextProps.cancel.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then(() => {
                    const listingArr = this.getFilterArray("deposit");
                    this.props.funcGetList(listingArr);
                });
            }
            else 
            {
                this.swalErrorHandler(nextProps.cancel.message[0]);
            }
        }

        if (nextProps.promo !== this.props.promo && nextProps.promo !== undefined) {
            if (nextProps.promo.responseCode === 200 && nextProps.promo.msgType === "success") {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
                    icon: "success",
                    title: t('global.success'),
                    // html: nextProps.promo.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then(() => {
                    this.setState({
                        openModal: false,
                    });

                    const listingArr = this.getFilterArray("deposit");
                    this.props.funcGetList(listingArr);
                });
            }
            else 
            {
                this.swalErrorHandler(nextProps.promo.message[0]);
            }
        }

        return true;
    }

    optionSelect = (e, option) => {
        e.preventDefault();
        this.setState({
            historyTab: option,
        });
    }

    onInputChange = (event) => {
        if (event.target.id === 'transaction_date') {
            const date = (event.target.value !== '') ? event.target.value.split(" - ") : null;
            this.setState({
                start_date: (date[0]) ? moment(date[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: (date[1]) ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                transaction_date: event.target.value,
            });

            const startDate = moment(this.state.start_date);
            const endDate = moment(this.state.end_date);
            const diff = endDate.diff(startDate, 'days');

            // Only allow to get data between 30 days
            if (diff > 30) {
                this.setState({
                    disableSearchButton: true
                })
            }
            else {
                this.setState({
                    disableSearchButton: false
                })
            }
        }
    }

    searchHandler = (tab) => {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['activePage'] = 1;
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            disableSearchButton: true
        }, () => {
            const listingArr = this.getFilterArray(tab);
            // console.log(listingArr);
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = (tab) => {
        const listingArr = {};
        listingArr.page = this.state[tab].activePage;
        listingArr.limit = this.state[tab].limit;
        listingArr.order = JSON.stringify(this.state[tab].order);
        listingArr.start_date = this.state.start_date;
        listingArr.end_date = this.state.end_date;
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.lang_id = this.props.currentUser.language_id;
        listingArr.transaction_type = this.state.historyTab;
        return listingArr;
    }

    datatablesChangeHandler = (val, tab, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+tab+' | '+act);
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter[act] = (act === 'searchValue') ? val.target.value : val;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            this.props.funcGetList(listingArr);
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    toggleProfileSideBar = () => {
        this.setState({
            profileSideBar: !this.state.profileSideBar,
        });
    };

    mobileOpenModal = () => {
        this.child.mobileOpenModal();
    };

    promoModalHandler = (e, tid) => {
        e.preventDefault();
        let newTab = Object.assign({}, this.state['deposit']);
        newTab['select_id'] = tid;
        this.setState({
            deposit: newTab,
            openModal: (tid) ? true : false,
        });
    }

    onChangeHandler = (e) => {
        // console.log(e.target.id+' | '+e.target.value)
        e.preventDefault();
        let newTab = Object.assign({}, this.state['deposit']);
        newTab[e.target.id] = e.target.value;
        this.setState({
            deposit: newTab,
        });
    }

    onPromoInputChangeHandler = (event, val) => {
        // console.log(event, val);
        let newTab = Object.assign({}, this.state['deposit']);
        newTab['promo_id'] = (event === null) ? '' : event.id;

        this.setState({
            deposit: newTab,
        });
    }

    cancelHandler = (e, id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
            // title: t("global.confirmation"),
            title: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                let data = {};
                data.id = id;
                this.props.funcCancelWithdraw(data);
            }
        });
    }

    applyPromoHandler = (e, tid, pid) => {
        // console.log(e.target.id+' | '+e.target.value+' | '+tid+' | '+pid);
        e.preventDefault();
        const { t } = this.props;
        if (tid && pid) {
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                } : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        // allowOutsideClick: false,
                        // allowEscapeKey: false,
                        // allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    let data = {};
                    data.id = tid;
                    data.promo_id = pid;
                    this.props.funcApplyPromo(data);
                }
            });
        }
    }

    filterDayRange = (val) => {
        // let days = Number(event.target.value);
        let days = val;
        let startDate = moment().subtract(days, "days").format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");
        let transactionDate = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`;

        this.setState({
            start_date: startDate,
            end_date: endDate,
            transaction_date: transactionDate,
            filterRange: days
        }, () => {
            let startDate = moment(this.state.start_date);
            let endDate = moment(this.state.end_date);
            let dayRange = endDate.diff(startDate, 'days');
            let disableButton = null;
            
            if (!startDate.isSameOrBefore(endDate) || !endDate.isSameOrAfter(startDate) || dayRange > 30) {
                disableButton = true;
            }
            else {
                disableButton = false;
                // const listingArr = this.getFilterArray(this.state.historyTab);
                // this.props.funcGetList(listingArr);
            }
            this.setState({
                disableSearchButton: disableButton
            })

            this.searchHandler(this.state.historyTab);
        });
    }

    tabShow = (option) => {
        let newTab = Object.assign({}, this.state[option]);
        newTab = initialTabState;
        newTab['loading'] = (this.state.disableSearchButton) ? false : true;
        this.setState({
            historyTab: option,
            [option]: newTab,
        });
    }

    //Mobile//

    toggleShowDateSelect = (e, show) => {
        if (this.state.showDateSelect === false) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showDateSelect: !this.state.showDateSelect,
        });

        const startDate = moment(this.state.start_date);
        const endDate = moment(this.state.end_date);
        const diff = endDate.diff(startDate, 'days');
        
        this.setState({
            showDate: show,
            selectedDate: (show === 'startDate') ? this.state.start_date : this.state.end_date,
            disableSearchButton: (diff > 30) ? true : false,
        })
    }
    
    onChangeDateHandler = (value) => {
        if (this.state.showDate === 'startDate') {
            this.setState({
                start_date: `${value.years}-${value.months}-${value.days}`,
            })
        }
        else if (this.state.showDate === 'endDate') {
            this.setState({
                end_date: `${value.years}-${value.months}-${value.days}`,
            })
        }
    }

    onChangeFilterDateHandler = (value, e) => {
        this.setState({
            currentFilterDate: value
        });
    }

    onResetHandler = () => {
        this.setState({
            defaultTab: 0,
            historyTab: 'deposit',
            currentFilterDate: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            transaction_date: "",
            spin: true
        }, () => {
            const listingArr = this.getFilterArray("deposit");
            this.props.funcGetList(listingArr);
        });

        setTimeout(() => this.setState({ spin: false }), 2000);
    }

    swalErrorHandler = (msg) => 
    {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : '',
            title: t("global.error"),
            text: msg,
            icon: 'error',
            confirmButtonText: t('global.ok'),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
            } : [],
        }).then(() => {
            let newTab = Object.assign({}, this.state[this.state.historyTab]);
            newTab['loading'] = false;

            this.setState({
                [this.state.historyTab]: newTab,
                disableSearchButton: false,
            });
        });
    }

    render() {
        const { t } = this.props;
        const listingArr = this.getFilterArray(this.state.historyTab);

        const fadeUp = keyframes`
            from {
            opacity: 0;
            transform: translate3d(0, 50px ,0);
            }
    
            to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            }
        `;

        const icon = this.imageImport(
            require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
        );

        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="pd-card-col">
                                            <h4 className="mb-2">{t('profile-sidebar.transaction-history')}</h4>
                                            <p>
                                                {t('history.notice-1')}
                                            </p>
                                            <div className="pd-content">
                                                <div className="filters-col">
                                                    <div className="filters-tp-panel">
                                                        <div className="filters-tp-col">
                                                            <label className="col-form-label">{t('history.type')}:</label>
                                                            <div className="filters-group">
                                                                {transactionTypes &&
                                                                    transactionTypes.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="filterType"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                defaultChecked={index === this.state.defaultTab}
                                                                                checked={item.value === this.state.historyTab}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id} onClick={() => this.tabShow(item.value)}>
                                                                                {t(`history.${item.label}`)}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="filters-bt-panel">
                                                        <div className="filters-bt-col">
                                                            <label className="col-form-label">{t('history.date')}:</label>
                                                            <div className="filters-group">
                                                                {filterDate &&
                                                                    filterDate.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="filterDate"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                defaultChecked={index === 0}
                                                                                onClick={(e) => this.onChangeFilterDateHandler(index, e)}
                                                                                checked={index === this.state.currentFilterDate}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id} style={{ maxWidth: '75px' }} onClick={() => this.filterDayRange(item.value)}>
                                                                                {/* {item.label} */}
                                                                                {t(`history.${item.label}`)}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="filters-bt-col">
                                                            <label className="col-form-label">
                                                                {t('referral.time')}:
                                                            </label>
                                                            <div className="input-group right">
                                                                <DateTimePicker
                                                                    id="transaction_date"
                                                                    name="transaction_date"
                                                                    className="form-control"
                                                                    autoUpdateInput={true}
                                                                    autoApply={true}
                                                                    format="MM/DD/YYYY"
                                                                    value={this.state.transaction_date}
                                                                    onChange={this.onInputChange}
                                                                />
                                                                <span className="input-group-text">
                                                                    <Icon icon="uil:calendar-alt" />
                                                                </span>
                                                            </div>
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-gradient-blue"
                                                                idValue="search"
                                                                buttonName={t('global.check')}
                                                                clicked={() => this.searchHandler(this.state.historyTab)}
                                                                disabled={this.state.disableSearchButton}
                                                            />
                                                            <div className="reset-btn">
                                                                <img className={`${this.state.spin ? "spin" : ""} `} src={icon["rotate-icon.svg"]} alt="reset" onClick={this.onResetHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pd-card-col">
                                            {this.state.historyTab === 'deposit' ? (
                                                <Deposit
                                                    tabName='deposit'
                                                    data={this.state.deposit}
                                                    datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                                                    getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                    funcGetList={() => this.props.funcGetList(listingArr)}
                                                    cancelHandler={(event, id) => this.cancelHandler(event, id)}
                                                    applyPromoHandler={(event, tid, pid) => this.applyPromoHandler(event, tid, pid)}
                                                    promoModalHandler={(event, tid) => this.promoModalHandler(event, tid)}
                                                    openModal={this.state.openModal}
                                                    onChangeHandler={(event) => this.onChangeHandler(event)}
                                                    onPromoInputChangeHandler={(event, data) => this.onPromoInputChangeHandler(event, data)}
                                                    {...this.state}

                                                />
                                            ) : this.state.historyTab === 'withdrawal' ? (
                                                <Withdrawal
                                                    tabName='withdrawal'
                                                    data={this.state.withdrawal}
                                                    datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                                                    getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                    funcGetList={() => this.props.funcGetList(listingArr)}
                                                    cancelHandler={(event, id) => this.cancelHandler(event, id)}
                                                    applyPromoHandler={(event, tid, pid) => this.applyPromoHandler(event, tid, pid)}
                                                    promoModalHandler={(event, tid) => this.promoModalHandler(event, tid)}
                                                    openModal={this.state.openModal}
                                                    onChangeHandler={(event) => this.onChangeHandler(event)}
                                                    onPromoInputChangeHandler={(event, data) => this.onPromoInputChangeHandler(event, data)}
                                                    {...this.state}

                                                />
                                            ) : this.state.historyTab === 'rebate' ? (
                                                <Rebate
                                                    tabName='rebate'
                                                    data={this.state.rebate}
                                                    datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                                                    getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                    funcGetList={() => this.props.funcGetList(listingArr)}
                                                    {...this.state}
                                                />
                                            ) : this.state.historyTab === 'promotion' ? (
                                                <Promotion
                                                    tabName='promotion'
                                                    data={this.state.promotion}
                                                    datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                                                    getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                    funcGetList={() => this.props.funcGetList(listingArr)}
                                                    {...this.state}
                                                />
                                            ) : this.state.historyTab === 'bonus' ? (
                                                <Bonus
                                                    tabName='bonus'
                                                    data={this.state.bonus}
                                                    datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                                                    getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                    funcGetList={() => this.props.funcGetList(listingArr)}
                                                    {...this.state}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </section>
                ) : (
                    <MobileTransactionHistory
                        transactionTypes={transactionTypes}
                        listingArr={listingArr}
                        filterDate={filterDate}

                        tabShow={this.tabShow}
                        searchHandler={this.searchHandler}
                        filterDayRange={this.filterDayRange}

                        // funcGetList={this.props.funcGetList()}
                        datatablesChangeHandler={this.datatablesChangeHandler}
                        getFilterArray={this.getFilterArray}
                        cancelHandler={this.cancelHandler}
                        applyPromoHandler={this.applyPromoHandler}
                        promoModalHandler={this.promoModalHandler}
                        onChangeHandler={this.onChangeHandler}
                        onPromoInputChangeHandler={this.onPromoInputChangeHandler}

                        onChangeDateHandler={this.onChangeDateHandler}
                        toggleShowDateSelect={this.toggleShowDateSelect}

                        onResetHandler={this.onResetHandler}
                        onChangeFilterDateHandler={this.onChangeFilterDateHandler}

                        {...this.state}
                        {...this.props}
                    />
                )
                }
            </Auxiliary>
        );
    }
}


//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        rs: state.history.rs,
        cancel: state.history.cancel,
        promo: state.history.promo,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcCancelWithdraw: (filter) => dispatch(actions.cancelWithdraw(filter)),
        funcApplyPromo: (filter) => dispatch(actions.applyPromo(filter)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(TransactionHistory));