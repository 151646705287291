import React, { Component, createRef } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icon } from "@iconify/react";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { numFormat } from "../../hoc/Shared/utility";
export class MobileVip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            vip: [],
            set_birthday_date: false,
        };
        this.topSection = createRef();
    }

    Benefit = (props) => {
        const { t } = this.props;

        let birthdayCountdown = null;
        if (this.props.dob) {
            let nextBirthday = moment().format("yyyy-") + moment(this.props.dob).format("MM-DD");
            if (nextBirthday === moment().format("yyyy-MM-DD")) {
                birthdayCountdown = "(" + t("vip.happy-birthday") + ")";
            } else {
                if (nextBirthday < moment().format("yyyy-MM-DD")) {
                    nextBirthday = moment().add(1, "years").format("yyyy-") + moment(this.props.dob).format("MM-DD");
                }

                const given = moment(nextBirthday, "YYYY-MM-DD");
                const current = moment().startOf("day");
                const dayLeft = moment.duration(given.diff(current)).asDays();
                birthdayCountdown = dayLeft;
            }
        }

        return (
            <div className="row g-3">
                <div className="col-6">
                    <div className="benefit-header">
                        <img src={props.vip["crown.webp"]} alt="crown" />
                        <h5>{t("vip.upgrade-bonus")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]} {props.bonusMoney}</h4>
                        <p className="content">{t("vip.upgrade-bonus")}</p>
                        <p className="footer">{t("vip.automatically-issue")}</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="benefit-header">
                        <img src={props.vip["pocket.webp"]} alt="pocket" />
                        <h5>{t("vip.vip-weekly-benefits")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]} {props.weeklyBonus}</h4>
                        <button className="btn btn-outline-blue" onClick={this.props.redirectVipExclusivePage}>
                            {t("vip.get-now")}
                        </button>
                        <p className="mention">
                            {t("vip.vip-weekly-bonus-mention")} <br />
                            {this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]} <span className="number">{props.turnoverWeeklyTarget}</span>
                        </p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="benefit-header">
                        <img src={props.vip["cake.webp"]} alt="cake" />
                        <h5>{t("vip.birthday-bonus")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]} {props.birthdayBonus}</h4>
                        {this.props.set_birthday_date === false ? (
                            <>
                                <button className="btn btn-outline-blue" onClick={this.props.redirectProfilePage}>
                                    {t("vip.set-now")}
                                </button>
                                <p className="footer">{t("vip.birthday-bonus-remark")}</p>
                            </>
                        ) : (
                            <>
                                <button className="btn btn-outline-blue" onClick={this.redirectVipExclusivePage}>
                                    {t("vip.get-now")}
                                </button>
                                <p className="content">
                                    {t("global.count-down")}
                                    <span className="number"> {birthdayCountdown} </span>
                                    {t("datepicker.day")}
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-6">
                    <div className="benefit-header">
                        <img src={props.vip["present.webp"]} alt="present" />
                        <h5>{t("vip.daily-withdrawal-limit")}</h5>
                    </div>
                    <div className="benefit-content gap-2">
                        <h4 className="money">{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]} {props.dailyWithdrawalLimit}</h4>
                        <p className="content">{t("vip.daily-withdrawal-limit")}</p>
                        <p className="footer">{t("vip.max-daily-withdrawal-limit")}</p>
                    </div>
                </div>
            </div>
        );
    };

    scrollToTop = () => {
        this.topSection.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const vip = this.imageImport(require.context("../../assets/images/vip", false, /\.(gif|png|jpe?g|svg|webp|webm)$/));

        const settings = {
            slidesToShow: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            dots: true,
            arrows: false,
            customPaging: function (i) {
                return <span>{i + 1}</span>;
            },
            beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
            afterChange: (current) => this.setState({ activeSlide2: current }),
        };

        const num_format = this.props.login ? numFormat(this.props.currentUser.currency) : numFormat(this.props.currency[this.props.currentUser.country_id]);
        let bonusMoney = 0.0;
        let weeklyBonus = 0.0;
        let birthdayBonus = 0.0;
        let dailyWithdrawalLimit = 0.0;
        let turnoverWeeklyTarget = 0.0;
        let vipInfo = this.props.vip
            ? this.props.vip.map((vipval, idx) => {
                if (this.state.activeSlide === idx) {
                    bonusMoney = new Intl.NumberFormat("en-US", num_format).format(vipval.upLevelBonus);
                    weeklyBonus = new Intl.NumberFormat("en-US", num_format).format(vipval.vipWeeklybonus);
                    birthdayBonus = new Intl.NumberFormat("en-US", num_format).format(vipval.birthdayBonus);
                    dailyWithdrawalLimit = new Intl.NumberFormat("en-US", num_format).format(vipval.maxDailyWithdrawal);
                    turnoverWeeklyTarget = new Intl.NumberFormat("en-US", num_format).format(vipval.turnoverWeeklyTarget);
                }
                return {
                    id: vipval.level,
                    name: "VIP" + vipval.level,
                    img: "vip" + vipval.level,
                    deposit: new Intl.NumberFormat("en-US", num_format).format(vipval.minDepositTierUpgrade),
                    turnover: new Intl.NumberFormat("en-US", num_format).format(vipval.minTurnoverTierUpgrade),
                    relegation: new Intl.NumberFormat("en-US", num_format).format(vipval.depositTierMaintain),
                    liveCasinoRebate: vipval.live_casino_rebate,
                    slotRebate: vipval.slotRebate,
                    sportRebate: vipval.sportRebate,
                    virtualRebate: vipval.virtualRebate,
                };
            })
            : [];
        return (
            <Auxiliary>
                <section className="vip-m-section" ref={this.topSection}>
                    <MobileSubHeader
                        className="bg-white"
                        returnPage={this.props.prevPage ? (this.props.prevPage === "/me" ? "/vip-exclusive" : "/") : "/"}
                        header={t("vip.vip-details-mobile")}
                        style={{ justifyContent: "unset" }}
                    />
                    <div className="banner-col">
                        <div className="banner-item">
                            <img src={banner[`vip-banner${this.props.imageLanguage}.webp`]} />
                        </div>
                    </div>
                    <div className="vip-m-slick">
                        <div className="slick-container">
                            <Slider ref={(c) => (this.slider = c)} {...settings}>
                                {vipInfo &&
                                    vipInfo.map((item, index) => (
                                        <div className="vip-card-container" key={index}>
                                            <span className="card-shadow">
                                                <img
                                                    src={
                                                        vip[
                                                        `${item.id === 10
                                                            ? "vip10-bg.webp"
                                                            : item.id === 11
                                                                ? "vip11-bg.webp"
                                                                : item.id === 12
                                                                    ? "vip12-bg.webp"
                                                                    : "vip-bg.webp"
                                                        }`
                                                        ]
                                                    }
                                                    alt="vip-img"
                                                />
                                            </span>
                                            <div className="vip-card-mobile">
                                                <div className="vip-level">
                                                    <LazyLoadImage
                                                        className="vip-m-logo"
                                                        src={vip[`${item.img}${this.state.activeSlide2 === index ? ".gif" : ".webp"}`]}
                                                        alt="vip-logo"
                                                        effect="blur"
                                                    />
                                                </div>
                                                <div className="vip-info">
                                                    <div className="d-flex mb-2">
                                                        <img className="info-icon" src={vip["t-deposit.webp"]} alt="vip-deposit" />
                                                        <div className="vip-content">
                                                            <p>
                                                                {t("vip.total-deposit")}
                                                                <span>&nbsp; ({this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]})</span>
                                                            </p>
                                                            <p className="amount">{item.deposit}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mb-2">
                                                        <img src={vip["t-turnover.webp"]} alt="vip-turnover" />
                                                        <div className="vip-content">
                                                            <p>
                                                                {t("vip.total-turnover")}
                                                                <span>&nbsp; ({this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]})</span>
                                                            </p>
                                                            <p className="amount">{item.turnover}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <img src={vip["relegation.webp"]} alt="vip-relagation" />
                                                        <div className="vip-content">
                                                            <p>{t("vip.relegation-turnover")}</p>
                                                            {item.id === 1 || item.id === 2 || item.id === 3 ||  item.id === 4 ? (
                                                                <p className="amount">
                                                                        {item.relegation} <span>{t("vip.lifetime-day")}</span>
                                                                </p>
                                                                ) : (
                                                                    <p className="amount">
                                                                    {item.relegation} <span>{t("vip.relegation-day")}</span>
                                                                </p>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </Slider>
                            <img className="crown-blue" src={vip["crown-blue.webp"]} alt="crown-blue" />
                        </div>
                    </div>
                    <div className="vip-benefit">
                        {/* {this.state.vip.map((vipVal, idx) => {
                    if(this.state.activeSlide === idx){
                      return ( 
                      <this.Benefit 
                        vip={vip} 
                        bonusMoney={new Intl.NumberFormat("en-US", num_format).format(vipVal.upLevelBonus)}
                        dailyWithdrawalLimit={new Intl.NumberFormat("en-US", num_format).format(vipVal.maxDailyWithdrawal)}
                        totalRelegationTurnover={new Intl.NumberFormat("en-US", num_format).format(vipVal.turnoverTierMaintain)}
                        birthdayBonus={new Intl.NumberFormat("en-US", num_format).format(vipVal.birthdayBonus)} 
                      />
                      )
                    }
                  })} */}
                        {this.state.activeSlide === 0 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 1 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 2 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 3 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 4 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 5 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 6 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 7 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 8 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 9 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 10 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : this.state.activeSlide === 11 ? (
                            <this.Benefit
                                vip={vip}
                                bonusMoney={bonusMoney}
                                weeklyBonus={weeklyBonus}
                                birthdayBonus={birthdayBonus}
                                dailyWithdrawalLimit={dailyWithdrawalLimit}
                                turnoverWeeklyTarget={turnoverWeeklyTarget}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="vip-m-table">
                        <h3>{t("vip.vip-maximum-rates-rebate")}</h3>
                        <div className="overflow-auto">
                            <table className="table table-bordered bg-white">
                                <thead>
                                    <tr>
                                        <td>{t("vip.vip-level")}</td>
                                        <td>{t("about-us.sports")}</td>
                                        <td>{t("about-us.live-casino")}</td>
                                        <td>{t("about-us.slots")}</td>
                                        <td>{t("about-us.games")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vipInfo &&
                                        vipInfo.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{item.sportRebate !== null ? item.sportRebate : '0.00'}%</td>
                                                    <td>{item.liveCasinoRebate !== null ? item.liveCasinoRebate : '0.00'}%</td>
                                                    <td>{item.slotRebate !== null ? item.slotRebate : '0.00'}%</td>
                                                    <td>{item.virtualRebate !== null ? item.virtualRebate : '0.00'}%</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className="vip-m-bullet-content">
                        <h4 className="pt-3">{t("vip.term-condition")}</h4>
                        <ol>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.criterion-upgrading-lv")} :</span>
                                    <span>{t("vip.criterion-upgrading-lv-content")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.sequence-upgrading-lv")} :</span>
                                    <span>{t("vip.sequence-upgrading-lv-content")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.relegation-requirements")} :</span>
                                    <span>{t("vip.relegation-requirements-content")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.downgrade-criteria")}</span>
                                    <span>{t("vip.downgrade-criteria-content")} :</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.tc-upgrade-bonus")}</span>
                                    <span>{t("vip.tc-upgrade-bonus-content")} :</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.tc-birthday-bonus")}</span>
                                    <span>{t("vip.tc-birthday-bonus-content")} :</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.tc-weekly-bonus")}</span>
                                    <span>{t("vip.tc-weekly-bonus-content")} :</span>
                                </div>
                            </li>
                            <li>{t("vip.tc-last", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            <div className="tnc-list justify-content-start remark">
                                <span>{t("global.remark")}:</span>
                                <span>{t("vip.tc-remark")}</span>
                            </div>
                        </ol>

                        <div className="btt-btn">
                            <div className="btn btn-white" onClick={this.scrollToTop}>
                                <Icon icon="ph:caret-double-up-bold" />
                                {t("vip.back-to-top")}
                            </div>
                        </div>
                    </div>
                </section>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MobileVip);
