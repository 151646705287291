import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // data: [],
};

const gameLauncherReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.SET_GAME_URL]: (state, action) => ({
        ...state,
        url: action.value
    }),
});

export default gameLauncherReducer;