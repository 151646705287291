import React, { Component } from 'react';
import { isDesktop } from "react-device-detect";
import Modal from "react-modal";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { default as ReactSelect } from "react-select";
import { withNamespaces } from "react-i18next";
import NoRecords from "../../components/NoRecords/NoRecords";

export class FifaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange = (selected) => {
      let params = {};
      params.member_id = this.props.currentUser.id;
      params.created_at = selected.value;
      this.props.getHistoryByDate(params);

      this.setState({
        optionSelected: selected
      });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };


  render() {
    const { t } = this.props;
    const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));

    return (
      <Auxiliary>
        {
          (isDesktop) ? (
            <Modal
              isOpen={this.props.fifaModal}
              closeTimeoutMS={500}
              portalClassName={`global-modal wc-check-result`}
              className="modal modal-dialog modal-dialog-centered"
              ariaHideApp={false}
              onRequestClose={this.props.toggleFifaModal}
              onAfterOpen={this.onAfterOpen}
            >

              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h1 className="modal-title">{t('qna.title-3')}</h1>
                </div>
                <div className="modal-body mb-5">
                  <div className='row w-100'>
                    <div className='col-6'>
                      <h5 className='m-0'>{t('qna.submit-record')}</h5>
                    </div>
                    <div className='col-2'>
                      <p>{t('qna.submit-times')}：<span>{this.props.totalSubmission}</span></p>
                    </div>
                    <div className='col-4'>
                      <p>{t('qna.submit-date')}:
                        <ReactSelect
                          classNamePrefix="react-select"
                          className="react-select"
                          defaultValue={this.props.betHistoryDate}
                          placeholder={this.props.betHistoryDate}
                          options={this.props.betHistoryDateList}
                          onChange={this.handleChange}
                          formatOptionLabel={(item) => (
                            <>
                              <Icon icon="uil:calendar-alt" /> {item.value}
                            </>
                          )}
                        />
                      </p>
                    </div>
                  </div>
                  <hr className='w-100' />
                  {this.props.totalSubmission > 0 ?
                  <table className='table mt-3' >
                    {/* <thead>
                      <tr >
                        <th colspan={5}></th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {!!(this.props.betHistory) && this.props.betHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{item.questions}</td>
                          <td> <img src={flag[`${item.ansOne}.svg`]} alt={item.ansOne} /> {item.ansOne}</td>
                          <td> <img src={flag[`${item.ansTwo}.svg`]} alt={item.ansTwo} /> {item.ansTwo}</td>
                          <td> <img src={flag[`${item.ansThree}.svg`]} alt={item.ansThree} /> {item.ansThree}</td>
                          <td> <img src={flag[`${item.ansFour}.svg`]} alt={item.ansFour} /> {item.ansFour}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                  :
                  <NoRecords/> 
                  }
                </div>
                <button className='btn btn-gradient-blue w-50 m-auto' onClick={this.props.toggleFifaModal}>{t('global.close')}</button>
              </div>
            </Modal>
          ) : (
            <Modal
              isOpen={this.props.fifaModal}
              closeTimeoutMS={500}
              portalClassName={`global-modal wc-check-result`}
              className="modal modal-dialog modal-dialog-centered"
              ariaHideApp={false}
              onRequestClose={this.props.toggleFifaModal}
              onAfterOpen={this.onAfterOpen}
            >

              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title">{t('qna.title-3')}</h5>
                </div>
                <div className="modal-body mb-3">
                <h5 className='mb-3'>{t('qna.submit-record')}</h5>
                  <div className='row w-100'>
                  <div className='col-8'>
                      <p>{t('qna.submit-date')}：</p>
                        <ReactSelect
                          classNamePrefix="react-select"
                          className="react-select"
                          defaultValue={this.props.betHistoryDate}
                          placeholder={this.props.betHistoryDate}
                          options={this.props.betHistoryDateList}
                          onChange={this.handleChange}
                          formatOptionLabel={(item) => (
                            <>
                              <Icon icon="uil:calendar-alt" /> {item.value}
                            </>
                          )}
                        />
                      
                    </div>
                    <div className='col-4'> 
                    <p>{t('qna.submit-times')}:</p><span>{this.props.totalSubmission}</span>
                    </div>
                  </div>
                  <hr className='w-100'/>
                  {this.props.totalSubmission > 0 ?
                  <>
                  {!!(this.props.betHistory) && this.props.betHistory.map((item, index) => (
                    <div className='result-body'  key={index}>
                      <p>{item.questions}</p>
                      <span> <img src={flag[`${item.ansOne}.svg`]} alt={item.ansOne} /> {item.ansOne}</span>
                      <span><img src={flag[`${item.ansTwo}.svg`]} alt={item.ansTwo} /> {item.ansTwo}</span>
                      <span><img src={flag[`${item.ansThree}.svg`]} alt={item.ansThree} /> {item.ansThree}</span>
                      <span><img src={flag[`${item.ansFour}.svg`]} alt={item.ansFour} /> {item.ansFour}</span>
                    </div>
                  ))
                  }
                  </>
                  :
                  <NoRecords/>
                }
                </div>
                <button className='btn btn-gradient-blue w-100 m-auto mb-3' onClick={this.props.toggleFifaModal}>{t('global.close')}</button>
              </div>
            </Modal>
          )
        }
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(FifaModal));