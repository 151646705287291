import React, { Component } from 'react'
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { updateMemberWithdrawalAddress } from "../../store/ducks/profile/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { formValidation, clearFormValidation } from "../../hoc/Shared/utility";

//Components
import MobileSubHeader from "../PageHeader/MobileSubHeader";
import NumberInput from "../Input/NumberInput/NumberInput";
import Button from "../Button/Button";

class MobileEditCrypto extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state =
        {
            errorMsg: [],
            formError: false,
            edit_withdrawal_address: ''
        };
    }

    componentDidMount() 
    {
        formValidation("update_bank_form");
        this.setState({
            edit_withdrawal_address: this.props.edit_withdrawal_address,
        });
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;

        if (nextProps.update_crypto_address_result !== this.props.update_crypto_address_result) {
            if (nextProps.update_crypto_address_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-edit-successful"),
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            openModal: false,
                            edit_id: null,
                            edit_bank_key: null,
                            edit_crypto: "",
                            edit_network: "",
                            edit_withdrawal_address: "",
                            touched: false,
                        });
                        this.props.searchHandler();
                        this.closeEditCryptoHandler();
                    }
                });
            } else {
                clearFormValidation("update_bank_form");
                if (nextProps.update_crypto_address_result.responseCode === 422) {
                    this.setState({
                        errorMsg: nextProps.update_crypto_address_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<ul>";
                    nextProps.update_crypto_address_result.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    });
                }
            }
        }

        return true;
    }

    closeEditCryptoHandler = () => 
    {
        this.props.toggleEditCryptoHandler();
        this.setState({
            new_withdrawal_address: '',
            formError: false,
            errorMsg: []
        });
    }

    onInputChangeHandler = (e) => 
    {
        this.setState({
            edit_withdrawal_address: e.target.value
        });
    }

    updateWithdrawalAddresssHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;
        let withdrawal_address = { withdrawal_address: t("crypto-address-details.address-required") }

        if (!this.state.edit_withdrawal_address) 
        {
            this.setState({
                formError: true,
                errorMsg: withdrawal_address
            })
        } else {
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.save"),
                cancelButtonText: t("global.cancel"),
                customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    // console.log(this.props);
                    let data = {};
                    data.id = this.props.edit_id;
                    data.crypto = this.props.edit_network === "TRC20-USDC" ? "USDC" : "USDT";
                    data.withdrawal_address = this.state.edit_withdrawal_address;
                    // console.log(data);
                    this.props.updateMemberWithdrawalAddress(data);
                }
            });
        }
    }

    render() 
    {
        const { t } = this.props;

        return (
            <Auxiliary>
                <section className={`pf-m-dashboard on-top remain-w ${this.props.showEditCrypto ? 'd-block pe-auto' : 'd-none'}`}>
                    <MobileSubHeader
                        className="bg-white"
                        closeModalHandler={this.closeEditCryptoHandler}
                        header={t("crypto-address-details.edit-withdrawal-address-details")}
                        style={{ justifyContent: "unset" }}
                    />
                    <form className="forms-col" method="post" id="update_bank_form" encType='multipart/form-data' onSubmit={this.updateWithdrawalAddresssHandler} noValidate>
                        <div className="pd-form-m-col">
                            <div>
                                <label className="col-form-label">{t("crypto-address-details.new-withdrawal-address")}</label>
                                <div className="input-group-col">
                                    <NumberInput
                                        id="edit_withdrawal_address"
                                        name="edit_withdrawal_address"
                                        className={`form-control ${(this.state.errorMsg.withdrawal_address) ? 'was-validated is-invalid' : ''}`}
                                        value={this.state.edit_withdrawal_address}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                        required={true}
                                        requiredMessage={t("validation.required")}
                                        formError={this.state.formError}
                                        errorMsg={this.state.errorMsg.withdrawal_address || ''}
                                        placeholder={this.props.edit_withdrawalAddress}
                                    />
                                </div>
                            </div>
                            <Button typeButton="submit" classValue="btn btn-gradient-blue mx-3" buttonName={t("top-profile.update")} />
                        </div>
                    </form>
                </section>
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        update_crypto_address_result: profile.update_crypto_address_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMemberWithdrawalAddress: (data) => dispatch(updateMemberWithdrawalAddress(data)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileEditCrypto));