import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import { Icon } from "@iconify/react";

//Components 
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import BankTransfer from "./BankTransfer";
import Crypto from "./Crypto";

//Actions 
import * as actions from "../../../store/ducks/withdrawal/actions";
import MobileWithdrawal from "./MobileWithdrawal";

class Withdrawal extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            withdrawalOption: "",
            transactionChannel: [],
            loading: true,
            paymentTypeId: "",
            paymentGatewaySelected: "",
            cryptoChannel: "",
            cryptoType: "",
            channelId: "",
            paymentGateway: [],
            withdrawalEstTime: ""
        }
    }

    componentDidMount() {
        this.props.funcGetDropDown();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let withdrawalOption = this.state.withdrawalOption;

                nextProps.dropDown.data.transaction_channel.forEach((val) => {
                    withdrawalOption = (withdrawalOption === "" && val.wd_status === 1) ? val.slug : withdrawalOption;
                });

                let paymentTypeId = "";
                let paymentGatewaySelected = "";
                let cryptoType = "";
                let cryptoChannel = "";
                let channelId = "";
                let withdrawalEstTime = "";

                if(withdrawalOption === "crypto"){
                    nextProps.dropDown.data.payment_gateway.map((val) => {
                        if (val.slug_details === "crypto-vendor" && val.wd_status === 1) {
                            if (paymentTypeId === "") {
                                paymentTypeId = val["id"];
                                paymentGatewaySelected = val["slug"];
                            }
                        }

                        else if (val.transaction_slug === "crypto" && val.wd_status === 1 && val.dp_status === 1) {
                            if (channelId === "") {
                                channelId = val["id"];
                                cryptoType = val['slug_details'];
                                cryptoChannel = val['slug'];
                                withdrawalEstTime = val["withdrawal_est_time"]
                            }
                        }
                    });
                }else{
                    nextProps.dropDown.data.transaction_channel.forEach((val,index) => {
                        if(index === 0){
                            withdrawalEstTime = val.withdrawal_est_time;
                        }
                    });
                }

                this.setState({
                    withdrawalOption: withdrawalOption,
                    transactionChannel: nextProps.dropDown.data.transaction_channel,
                    loading: false,
                    paymentTypeId: paymentTypeId,
                    paymentGatewaySelected: paymentGatewaySelected,
                    paymentGateway: nextProps.dropDown.data.payment_gateway,
                    cryptoChannel: cryptoChannel,
                    channelId: channelId,
                    cryptoType: cryptoType,
                    withdrawalEstTime: withdrawalEstTime
                });
            }
        }
        return true;
    }

    optionSelectHandler = (e, option, optionId, withdrawal_est_time) => {
        e.preventDefault();

        // let newInput = Object.assign({}, this.state.input);
        // newInput["transaction_channel_id"] = optionId;
        // newInput["payment_type_id"] = option === "bank-transfer" ? 1 : "";

        // Temporary hide for phase 2 only implement
        // if (option === "crypto") {

        //     this.props.funcGetAvailableCrypto();

        //     const data = {};
        //     data.crypto = this.state.cryptoOption;

        //     withdrawalAddressArry = [
        //         {
        //             label: "--- Select Address ---",
        //             value: "",
        //         },
        //     ];

        //     this.props.funcGetWithdrawalCryptoAddress(data);
        // }

        let paymentTypeId = "";
        let paymentGatewaySelected = "";

        let cryptoType = "";
        let cryptoChannel = "";
        let channelId = "";

        if(option === "crypto"){
            this.state.paymentGateway.map((val) => {
                if (val.slug_details === "crypto-vendor" && val.wd_status === 1) {
                    if (paymentTypeId === "") {
                        paymentTypeId = val["id"];
                        paymentGatewaySelected = val["slug"];
                    }
                }

                else if (val.transaction_slug === "crypto" && val.dp_status === 1 && val.wd_status === 1) {
                    if (channelId === "") {
                        channelId = val["id"];
                        cryptoType = val['slug_details'];
                        cryptoChannel = val['slug'];
                    }
                }
            });
        }

        this.setState({
            withdrawalOption: option,
            paymentTypeId: paymentTypeId,
            paymentGatewaySelected: paymentGatewaySelected,
            cryptoChannel: cryptoChannel,
            channelId: channelId,
            cryptoType: cryptoType,
            withdrawalEstTime: withdrawal_est_time
        });
    }

    redirectHandler = () => {
        this.props.history.push({
            pathname: '/transaction-history',
            state: {
                historyTab: 'withdrawal',
                defaultTab: 1
            }
        });
    }
    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() {
        const { t } = this.props;
        let transactionChannel = this.state.transactionChannel ? this.state.transactionChannel : [];
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <div className="row">
                                <div className="col-12">
                                    <div className="pd-card-col d-flex flex-column h-100">
                                        <h4>{t("profile-sidebar.withdraw")}</h4>
                                        <div className="pd-content mb-3">
                                            {this.state.loading ? (
                                                <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                                            ) : (
                                                <div className="d-grid gap-3">
                                                    <div className="row">
                                                        <label className="col-3 col-form-label">{t("withdraw.withdraw-options")}:</label>
                                                        <div className="col-9 select-bank-opts">
                                                            {transactionChannel.map((val, idx) => {
                                                                if (val.wd_status === 1) {
                                                                    return (
                                                                        <div
                                                                            className="form-check form-check-inline"
                                                                            onClick={(event) => this.optionSelectHandler(event, val.slug, val.id)}
                                                                            key={idx}
                                                                        >
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="withdrawalOption"
                                                                                id={`withdrawal${val.slug}`}
                                                                                value={val.slug}
                                                                                onChange={() => { }}
                                                                                checked={this.state.withdrawalOption === val.slug ? true : false}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={`withdrawal${val.slug}`}>
                                                                                <img src={icons[`${val.slug}.svg`]} alt={val.slug} />
                                                                                <p>{t(`transaction-channel.${val.slug}`)}</p>
                                                                                {val.withdrawal_est_time && (
                                                                                
                                                                                    
                                                                                <div className="transfer-duration">
                                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                                    Deposit in 1 min */}
                                                                                    {val.withdrawal_est_time === "00:00:00" ? (
                                                                                        <>
                                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                            {t("withdrawal.instant-withdrawal")}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                            {t("withdrawal.withdrawal-in")} {val.withdrawal_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}

                                                                                        </>
                                                                                    )}
                                                                                    
                                                                                </div>
                                                                                
                                                                            )}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                }
                                                                else {
                                                                    return null;
                                                                }
                                                            })}

                                                            {/* <div
                                                                className="form-check form-check-inline"
                                                                onClick={(event) => this.optionSelectHandler(event, "crypto", 2)}
                                                            >
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    name="withdrawalOption"
                                                                    id={`withdrawalcrypto`}
                                                                    value={"crypto"}
                                                                    onChange={() => { }}
                                                                    checked={this.state.withdrawalOption === "crypto" ? true : false}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`withdrawal${"crypto"}`}>
                                                                    Crypto
                                                                </label>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    {this.state.withdrawalOption === 'quick-pay' ? (
                                                        <>Quick Pay (Coming Soon)</>
                                                    ) : this.state.withdrawalOption === 'e-wallet' ? (
                                                        <>E-Wallet (Coming Soon)</>
                                                    ) : this.state.withdrawalOption === 'crypto' ? (
                                                        <Crypto
                                                            redirectHandler={this.redirectHandler}
                                                            {...this.state}
                                                        />
                                                    ) : this.state.withdrawalOption === 'bank-transfer' ? (
                                                        <BankTransfer
                                                            redirectHandler={this.redirectHandler}
                                                            {...this.state}
                                                        />
                                                    ) : (
                                                        <>Invalid Option</>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="payment-notices-col">
                                            <h6>{t('withdrawal.important-notice')}</h6>
                                            <ol>
                                                <li>{t('withdrawal.notice-content-1')}<a href="/" onClick={window['liveChatButton']}>{t('withdrawal.notice-content-2')}</a>{t('withdrawal.notice-content-3')}</li>
                                                <li>{t('withdrawal.notice-content-4')}</li>
                                                <li>{t('withdrawal.notice-content-5')}</li>
                                                <li>{t('withdrawal.notice-content-6')}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section >
                ) : (
                    <MobileWithdrawal
                        optionSelectHandler={this.optionSelectHandler}
                        redirectHandler={this.redirectHandler}

                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary >
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.withdrawal.dropDown,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Withdrawal));
