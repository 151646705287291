import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Skeleton from "react-loading-skeleton";
import paginationFactory, {
    // SizePerPageDropdownStandalone,
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
// import StringInput from "../Input/StringInput/StringInput";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faSearch} from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from "react-i18next";
// import '../../assets/css/_datatable.scss';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import NoRecords from "../../components/NoRecords/NoRecords";

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";

const DataTable = (props) => {
    const sizePerPageRenderer = ({ options, onSizePerPageChange }) => (
        <div className="dataTables_length">
            <select className="custom-select custom-select-sm form-control form-control-sm" onChange={(event) => onSizePerPageChange(event.target.value)} value={props.limit}>
                {options.map((option) => (
                    <option
                        key={option.text}
                        value={option.text}
                    // selected={props.limit === Number(option.text)}
                    >
                        {option.text}
                    </option>
                ))}
            </select>
        </div>
    );

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {props.t("global.showing")} {from} {props.t("global.to")} {to} {props.t("global.of")} {size} {props.t("global.entries")}
        </span>
    );

    //Pagination & Sorting Column Configuration
    const options = {
        page: props.activePage,
        sizePerPage: props.limit,
        //Show total result
        showTotal: true,
        paginationTotalRenderer: customTotal,
        totalSize: props.totalItems,
        //Custom Pagination & Limit Dropdown
        custom: true,
        //Pagination Function handler
        onPageChange: props.paginationHandler,
        //Sorting Function handler
        onSizePerPageChange: props.sizePerPageHandler,
        alwaysShowAllBtns: true,
        firstPageText: <Icon icon="ic:baseline-first-page" />,
        prePageText: <Icon icon="uil:angle-left-b" />,
        nextPageText: <Icon icon="uil:angle-right-b" />,
        lastPageText: <Icon icon="ic:baseline-last-page" />,
        //Limit Dropdown list
        sizePerPageList: [10, 20, 30, 40, 50],
        sizePerPageRenderer,
    };

    return (
        <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <BootstrapTable
                        bootstrap4
                        remote={true}
                        classes={props.classValue === undefined ? "dataTable mb-0" : props.classValue}
                        bordered={false}
                        keyField={props.keyField}
                        data={props.data}
                        columns={props.columns}
                        rowEvents={props.rowEvents}
                        rowClasses={props.rowClasses}
                        rowStyle={props.rowStyle}
                        {...paginationTableProps}
                        loading={props.loading === true ? <Skeleton /> : false}
                        onTableChange={() => { }}
                    />
                    {props.loading === false && props.totalItems <= 0 ? <NoRecords component={props.component || ''} /> : null}
                    {(() => {
                        if (props.hideTotalRecords !== undefined && props.hideTotalRecords === true && props.hidePagination !== undefined && props.hidePagination === true) {
                            return null;
                        } else {
                            return <>
                                {(() => {
                                    if (props.loading === true) {
                                        return <Skeleton count={1} height={'3.125rem'} style={{marginBottom: ".25rem"}}/>;
                                    } else {
                                        if (props.hideTotalRecords !== undefined && props.hideTotalRecords === true) {
                                            return null;
                                        } else {
                                            return <PaginationTotalStandalone {...paginationProps} />;
                                        }
                                    }
                                })()}
                                {(() => {
                                    if (props.loading === true) {
                                        return <Skeleton count={1} height={'3.125rem'} style={{marginBottom: "1rem"}}/>;
                                    } else {
                                        if (props.hidePagination !== undefined && props.hidePagination === true) {
                                            return null;
                                        } else {
                                            return <nav className="pagination-col"><PaginationListStandalone {...paginationProps} /></nav>;
                                        }
                                    }
                                })()}
                                {/* {props.loading === true ? <Skeleton /> : <PaginationTotalStandalone {...paginationProps} />} */}
                                {/* {props.loading === true ? <Skeleton /> : <PaginationListStandalone {...paginationProps} />} */}
                            </>
                        }
                    })()}
                </>
            )}
        </PaginationProvider>
    );
};
export default withNamespaces("translation")(DataTable);

DataTable.propTypes = {
    activePage: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    paginationHandler: PropTypes.func.isRequired,
    sizePerPageHandler: PropTypes.func.isRequired,
    searchChangeHandler: PropTypes.func,
    searchValue: PropTypes.string,
    searchSubmitHandler: PropTypes.func,
    searchPressSubmitHandler: PropTypes.func,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    hidePagination: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    hideTotalRecords: PropTypes.bool
};
