import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { Redirect } from "react-router-dom";
import slugConf from "../../common/data/games.json";
import * as actions from "../../store/ducks/lottery/actions";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";
// import MobileLottery from "./MobileLottery";
import MobileHome from "../Home/MobileHome";

class DefaultLottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            vendorSlug: "",
            vendorList: []
        }
    }

    componentDidMount() {
        let params = {};
        // params.country_id = this.props.currentUser.data.country_id;
        this.props.getVendorList(params);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
            if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
                let vendorList = nextProps.vendor.data.vendor_list;
                let vendorSlug = "";
                if (vendorList.length > 0) {
                    vendorSlug = vendorList[0].game_vendor_slug;
                }
                else {
                    vendorSlug = "not-found";
                }

                this.setState({
                    loading: false,
                    vendorSlug: vendorSlug,
                    vendorList: vendorList
                });
            }
        }
        return true;
    }

    render() {
        let vendorList = this.state.vendorList ? this.state.vendorList.map((item, index) => {
            return {
                name: item.game_vendor_title,
                slug: item.game_vendor_slug,
                img: `${item.game_type}-${item.game_vendor_slug}-img.webp`,
                gameType: item.game_type,
                hotStatus: item.hot_status
            };
        }) : [];
        
        return (
            <>
                {!this.state.loading ? (isDesktop ?
                    <Redirect
                        push
                        to={{ pathname: `/lottery/${((slugConf['slug-link'] && slugConf['slug-link'][this.state.vendorSlug]) || '')}`, state: { prevUrl: window.location.pathname } }}
                    />
                    :
                    // <MobileLottery 
                    //     vendorList={this.state.vendorList}
                    // />
                    <MobileHome
                        vendorList={vendorList}
                        location={this.props.location}
                    />
                ) : (
                    <>
                        <LoadingPage />
                        {isDesktop ? (
                            <Skeleton count={1} height={800} />
                        ) : (
                            <Skeleton count={1} height={500} />
                        )}
                    </>
                )
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendor: state.lottery.vendor,
        currentUser: state.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVendorList: (params) => dispatch(actions.getVendorList(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DefaultLottery));