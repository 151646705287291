import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import MobileCrypto from "./MobileCrypto";
import { isDesktop } from "react-device-detect";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import { Icon } from "@iconify/react";
import moment from "moment";
import { formValidation, clearFormValidation, numFormat } from "../../../hoc/Shared/utility";
import Modal from "react-modal";

import StringInput from "../../../components/Input/StringInput/StringInput";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Button from "../../../components/Button/Button";
import SecurityPinModal from "../../../components/SecurityPinModal/SecurityPinModal";

import * as actions from "../../../store/ducks/withdrawal/actions";
import { checkSecurityPinAttempts } from "../../../store/ducks/profile/actions";
import { removeMemberCryptoAddress, updateMemberWithdrawalAddress } from "../../../store/ducks/profile/actions";
import { getTopProfile } from "../../../store/ducks/profile/actions";

import * as CryptoJS from "crypto-js";
import { currency } from "../../../common/config/currency";

let cryptoChannel = [
    { id: 0, label: "TRC20-USDT", type: "usdt" },
    { id: 1, label: "TRC20-USDC", type: "usdc" },
];
export class Crypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddCrypto: false,

            input: {
                transaction_channel_id: "",
                amount: "",
                crypto_id: "",
                crypto_name: "",
                crypto_address: "",
                paymentGatewaySelected: this.props.paymentGatewaySelected ? this.props.paymentGatewaySelected : "",
                network: "",
            },

            editInput: {
                id: null,
                crypto_id: null,
                crypto_name: "",
                crypto_address: "",
                network: "",
            },

            dropDown: {
                payment_gateway: [],
                memberWithdrawalCryptoAddress: [],
                addMemberWithdrawalCryptoAddress: [],
            },

            isOptionLoading: false,
            loading: true,
            errorMsg: [],

            edit_id: null,
            edit_crypto: "",
            edit_network: "",
            edit_withdrawal_address: "",
            openModal: false,

            // channel: this.props.cryptoChannel ? this.props.cryptoChannel : "",
            // type: this.props.cryptoType ? this.props.cryptoType : "",
            channel: 'TRC20-USDT',
            channel_id: this.props.channelId ? this.props.channelId : "",
            type: 'USDT',
            exchangeRateAmount: 0,
            addSecurityPinStatus: false,

            enter_pin_attempts: 3,
            time: {},
            seconds: 60,
            timer: 0,
            reenter_security_pin_status: true,
            verify_security_pin_status: true,
            transType: "withdrawal",
            security_pin_create_date: "",

            openSecurityPinModal: false,
        };
    }

    componentDidMount() {
        if (localStorage.getItem("accessToken")) {
            this.props.getTopProfile();
        }

        let data = {};
        data.crypto = this.state.type;

        this.props.funcGetDropDown(data);

        formValidation("withdrawal_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
            if (nextProps.topProfileData.responseCode === 200) {
                this.setState({
                    addSecurityPinStatus: nextProps.topProfileData.data.topProfile.security_pin_verified === 1 ? true : false,
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                // if(this.state.addSecurityPinStatus === true){
                //     this.props.checkSecurityPinAttempts();
                // }
                let memberWithdrawalCryptoAddress = [];
                let newInput = Object.assign({}, this.state.input);
                let newEditInput = Object.assign({}, this.state.editInput);
                // let withdrawalOption = this.state.withdrawalOption;
                let maxWithdrawal = nextProps.dropDown.data.member_group.max_daily_withdrawal;
                let maxButtonWithdrawal =
                    parseFloat(nextProps.dropDown.data.wallet.balance) < parseFloat(nextProps.dropDown.data.member_group.max_daily_withdrawal)
                        ? nextProps.dropDown.data.wallet.balance
                        : nextProps.dropDown.data.member_group.max_daily_withdrawal;
                let filterWithdrawalCryptoAddress = nextProps.dropDown.data.withdrawal_crypto_address.filter((item) => item.crypto === this.state.type);
                
                nextProps.dropDown.data.transaction_channel.forEach((val) => {
                    // withdrawalOption = (withdrawalOption === "" && val.wd_status===1) ? val.slug : withdrawalOption;
                    newInput["transaction_channel_id"] =
                        newInput["transaction_channel_id"] === "" && val.wd_status === 1 && val.slug === "crypto" ? val.id : newInput["transaction_channel_id"];
                });

                nextProps.dropDown.data.withdrawal_crypto_address.forEach((val) => {
                    memberWithdrawalCryptoAddress[val.id] = val;
                    if(val.crypto === this.state.type)
                    {
                        newInput["crypto_id"] = newInput["crypto_id"] !== "" && newInput["crypto_id"] < val.id && this.state.type === "USDT" ? newInput["crypto_id"] : val.id;
                        newInput["crypto_name"] = newInput["crypto_name"] !== "" ? newInput["crypto_name"] : val.crypto;
                        newInput["crypto_address"] = newInput["crypto_address"] !== "" ? newInput["crypto_address"] : val.withdrawal_address;
                        newInput["network"] = newInput["network"] !== "" ? newInput["network"] : val.network;
                        newEditInput["withdrawal_address"] = val.withdrawal_address;
                    }
                });

                newInput["crypto_id"] = (filterWithdrawalCryptoAddress.length > 0) ? newInput["crypto_id"] : this.state.channel_id;
                // nextProps.dropDown.data.add_bank_data.forEach((val) => {
                //     addMemberBankAccount[val.bank_id] = val;
                //     // newInput["bank_id"] = newInput["bank_id"] !== "" ? newInput["bank_id"] : val.bank_id;
                // });

                // if (cryptoChannel.length === 0) {
                //     this.props.paymentGateway &&
                //         this.props.paymentGateway
                //             .filter((val) => val.slug_details !== "crypto-vendor" && val.transaction_slug === "crypto" && val.dp_status === 1)
                //             .map((val) => {
                //                 cryptoChannel.push({
                //                     id: val.id,
                //                     label: val.slug,
                //                     type: val.slug_details.toString().toLowerCase(),
                //                     withdrawal_est_time: val.withdrawal_est_time
                //                 });
                //             });
                // }

                this.setState({
                    input: newInput,
                    editInput: newEditInput,
                    dropDown: {
                        payment_gateway: nextProps.dropDown.data.payment_gateway,
                        memberWithdrawalCryptoAddress: memberWithdrawalCryptoAddress,
                        // addMemberBankAccount: addMemberBankAccount,
                    },
                    minWithdrawal: nextProps.dropDown.data.member_group.min_withdrawal || 0,
                    maxWithdrawal: maxWithdrawal || 0,
                    maxButtonWithdrawal: maxButtonWithdrawal || 0,
                    loading: false,
                    showAddCrypto: filterWithdrawalCryptoAddress.length > 0 ? false : true,
                    isOptionLoading: true,
                });
            }
        }

        if (nextProps.update_crypto_address_result !== this.props.update_crypto_address_result) {
            if (nextProps.update_crypto_address_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-edit-successful"),
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            openModal: false,
                            edit_id: null,
                            edit_bank_key: null,
                            edit_crypto: "",
                            edit_network: "",
                            edit_withdrawal_address: "",
                            touched: false,
                        });

                        this.searchHandler();
                    }
                });
            } else {
                clearFormValidation("update_member_withdrawal_address_form");
                if (nextProps.update_crypto_address_result.responseCode === 422) {
                    this.setState({
                        error_message: nextProps.update_crypto_address_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<ul>";
                    nextProps.update_crypto_address_result.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    });
                }
            }
        }

        if (nextProps.memberRemoveCryptoAddressResult !== this.props.memberRemoveCryptoAddressResult) {
            if (nextProps.memberRemoveCryptoAddressResult.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    html: t("crypto-address-details.crypto-acc-delete-successful"),
                    // icon: nextProps.memberRemoveCryptoAddressResult.msgType,
                    icon: "success",
                    confirmButtonText: t("global.confirm"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    this.searchHandler();
                });
            } else if (nextProps.memberRemoveCryptoAddressResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.memberRemoveCryptoAddressResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.get_crypto_exchange_rates_result !== this.props.get_crypto_exchange_rates_result) {
            if (nextProps.get_crypto_exchange_rates_result.responseCode === 200) {
                this.setState({
                    exchangeRateAmount: nextProps.get_crypto_exchange_rates_result.data.result.amount,
                });
            } else {
                this.setState({
                    exchangeRateAmount: 0,
                });
            }
        }

        if (nextProps.verify_security_pin_result !== this.props.verify_security_pin_result) {
            if (nextProps.verify_security_pin_result.responseCode === 200) {
                if (nextProps.verify_security_pin_result.data.verify_security_pin_status === true) {
                    // if (isDesktop) {
                    //     document.getElementById("securityPinModal").click();
                    // }

                    this.securityPinsubmitHandler();
                }
            } else {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
                    // icon: nextProps.verify_security_pin_result.msgType,
                    icon: "error",
                    title: t("global.error"),
                    html: nextProps.verify_security_pin_result.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container failed",
                            icon: "swal-failed-icon",
                        }
                        : [],
                }).then(() => {
                    if (this.state.enter_pin_attempts !== 0) {
                        this.setState({
                            touched: true,
                            errorMsg: nextProps.verify_security_pin_result.message.input_error || [],
                            formError: true,
                            enter_pin_attempts: this.state.enter_pin_attempts - 1,
                            verify_security_pin_status: false,
                        });
                    }

                    if (this.state.enter_pin_attempts === 0) {
                        if (this.state.seconds === 0) {
                            // var now = moment(new Date()); //todays date
                            // var end = moment(this.state.security_pin_create_date); // another date
                            // var duration = moment.duration(now.diff(end));
                            // var seconds = duration.asSeconds();

                            this.setState({
                                timer: 0,
                                seconds: 60,
                            });
                        }

                        let timeLeftVar = this.secondsToTime(this.state.seconds);
                        // console.log(this.state.seconds);
                        // console.log(timeLeftVar);

                        // this.setState({ time: timeLeftVar });

                        this.startTimer();

                        this.setState({
                            time: timeLeftVar,
                            reenter_security_pin_status: false,
                            verify_security_pin_status: true,
                        });
                    }

                    Swal.close();
                });
            }
        }

        if (nextProps.update_security_pin_result !== this.props.update_security_pin_result) {
            if (nextProps.update_security_pin_result.responseCode === 200) {
                // if (isDesktop) {
                //     document.getElementById("securityPinModal").click();
                // }

                this.securityPinsubmitHandler();
            } else {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                    icon: "error",
                    title: t("global.sorry"),
                    html: nextProps.update_security_pin_result.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        }
                        : [],
                }).then(() => {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.update_security_pin_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                });
            }
        }

        if (nextProps.check_security_pin_attempts_result !== this.props.check_security_pin_attempts_result) {
            if (nextProps.check_security_pin_attempts_result.responseCode === 200) {
                if (nextProps.check_security_pin_attempts_result.data) {
                    this.setState({
                        enter_pin_attempts:
                            nextProps.check_security_pin_attempts_result.data.security_pin_attempts !== 0
                                ? nextProps.check_security_pin_attempts_result.data.security_pin_attempts
                                : this.state.enter_pin_attempts,
                        security_pin_create_date: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.created_at
                            : this.state.security_pin_create_date,
                        checkSecurityAttemptsLoading: false,
                    });
                } else {
                    this.setState({
                        enter_pin_attempts: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.security_pin_attempts
                            : this.state.enter_pin_attempts,
                        security_pin_create_date: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.created_at
                            : this.state.security_pin_create_date,
                        checkSecurityAttemptsLoading: false,
                    });
                }

                const current = moment(new Date(), "YYYY-mm-dd HH:mm:ss");

                if (nextProps.check_security_pin_attempts_result.data) {
                    if (nextProps.check_security_pin_attempts_result.data.security_pin_attempts === 0) {
                        var t2 = moment(nextProps.check_security_pin_attempts_result.data.created_at);
                        var given = moment(t2, "YYYY-mm-dd HH:mm:ss");
                        const secondsLeft = moment.duration(current.diff(given)).asSeconds();

                        let minusSeconds = 60 - secondsLeft;
                        clearInterval(this.timer);

                        if (secondsLeft >= 60) {
                            this.setState({
                                reenter_security_pin_status: true,
                                verify_security_pin_status: true,
                            });
                        } else if (minusSeconds > 0) {
                            let timeLeftVar = this.secondsToTime(secondsLeft);

                            this.setState({
                                time: timeLeftVar,
                                seconds: minusSeconds,
                                reenter_security_pin_status: false,
                                verify_security_pin_status: true,
                            });

                            this.startTimer();
                        }
                    }
                }
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log("shouldComponentUpdate (create):", nextProps.store);
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                // if(document.getElementById('securityPinModal'))
                // {
                //     document.getElementById('securityPinModal').click();
                // }
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("global.record-added"),
                    showConfirmButton: true,
                    timer: 2000,
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then(() => {
                    let newInput = Object.assign({}, this.state.input);
                    newInput["amount"] = "";

                    this.setState({
                        input: newInput,
                        errorMsg: [],
                        formError: false,
                    });

                    // this.props.funcGetDropDown();
                    this.props.redirectHandler();
                    // this.props.history.push({
                    //     pathname: '/transaction-history',
                    //     historyTab: "TransactionHistory"
                    // });
                });
            } else {
                clearFormValidation("withdrawal_form");
                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.store.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                    // if(document.getElementById('securityPinModal'))
                    // {
                    //     document.getElementById('securityPinModal').click();
                    // }
                } else {
                    let msg = null;
                    msg = "<div>";
                    nextProps.store.message.forEach((val) => {
                        msg += "<p>" + val + "</p>";
                    });
                    msg += "</div>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== "" && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }
                        }
                    });
                }
            }
        }

        return true;
    }

    showAddCryptoHandler = () => {
        let newInput = Object.assign({}, this.state.input);
        newInput["crypto_address"] = "";
        newInput["crypto_id"] = this.state.channel_id;

        this.setState({
            showAddCrypto: !this.state.showAddCrypto,
            input: newInput,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    selectPaymentGatewayHandler = (val) => {
        this.setState({
            paymentGatewaySelected: val,
        });
    };

    onInputChangeHandler = (event, withdrawal_address) => {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.name === "add_crypto_id" ? "crypto_id" : event.target.name] = event.target.value;

        if (event.target.name === "add_crypto_id" || event.target.name === "crypto_id") {
            newInput["crypto_address"] = event.target.name === "crypto_id" ? withdrawal_address : newInput["crypto_address"];
            newInput["crypto_id"] = event.target.name === "add_crypto_id" ? event.target.value : newInput["crypto_id"];
        }
        if (event.target.name === "crypto_address") {
            newInput["crypto_address"] = event.target.value;
        }

        if (event.target.name.includes("amount")) {
            const splitedValue = event.target.value.split(".");
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
        }

        this.setState({
            input: newInput,
            // withdrawalAmt: null,
            showAddCrypto: event.target.name === "crypto_id" ? false : this.state.showAddCrypto,
            [event.target.name]: event.target.value,
            formError: false,
            errorMsg: [],
        });
    };

    withdrawalMaxHandler = () => {
        let newInput = Object.assign({}, this.state.input);
        if (this.state.withdrawalOption === "crypto") {
            newInput["amount"] = this.state.maxButtonWithdrawal;
        } else {
            newInput["amount"] = this.state.maxButtonWithdrawal;
        }

        let data = {};
        data.amount = this.state.maxButtonWithdrawal;
        data.currency = this.props.currentUser.currency;
        data.crypto = this.state.channel;

        this.props.funcGetCryptoExchangeRates(data);

        this.setState({
            // withdrawalAmt: this.state.maxButtonWithdrawal,
            input: newInput,
        });
    };

    securityPinsubmitHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const inputArr = {};
        Object.keys(this.state.input).forEach((val) => {
            inputArr["transaction_channel_id"] = this.state.input["transaction_channel_id"];
            inputArr["payment_type_id"] = this.state.channel_id;
            inputArr["rates"] = this.state.exchangeRateAmount / 100;
            inputArr["crypto_amount"] = this.state.exchangeRateAmount;
            inputArr["currency"] = this.props.currentUser.currency;
            inputArr["withdrawal_address"] = this.state.input.crypto_address;
            inputArr["crypto"] = this.state.type;
            inputArr["amount"] = this.state.input.amount;
            inputArr["network"] = this.state.channel;
            inputArr["crypto_id"] = this.state.input.crypto_id;
            // inputArr[val] = this.state.input[val];
        });

        // HASHING ALGO
        inputArr["trxTime"] = moment().unix();
        let hashStr = this.hashingAlg(inputArr);
        inputArr["trxHash"] = hashStr;

        this.props.funcStore(inputArr);
    };

    submitHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        
        if (this.state.input.crypto_address === "" || this.state.input.crypto_address === null) {
            this.setState({
                errorMsg: { crypto_address: t("error-msg.crypto_address") },
            });
        } else if (this.state.input.crypto_id === "" || this.state.input.crypto_id === null) {
            this.setState({
                errorMsg: { crypto_id: t("error-msg.crypto_id") },
            });
        } else {
            if (this.state.addSecurityPinStatus === true) {
                document.getElementById("securityPinSubmitBtn").click();
            }
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container confirmation",
                        icon: "swal-confirmation-icon",
                    }
                    : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const inputArr = {};

                    Object.keys(this.state.input).forEach((val) => {
                        inputArr["transaction_channel_id"] = this.state.input["transaction_channel_id"];
                        inputArr["payment_type_id"] = this.props.paymentTypeId;
                        inputArr["rates"] = this.state.exchangeRateAmount / 100;
                        inputArr["crypto_amount"] = this.state.exchangeRateAmount;
                        inputArr["currency"] = this.props.currentUser.currency;
                        inputArr["withdrawal_address"] = this.state.input.crypto_address;
                        inputArr["crypto"] = this.state.type;
                        inputArr["amount"] = this.state.input.amount;
                        inputArr["network"] = this.state.channel;
                        inputArr["crypto_id"] = this.state.input.crypto_id;
                        // inputArr[val] = this.state.input[val];
                    });

                    // HASHING ALGO
                    inputArr["trxTime"] = moment().unix();
                    let hashStr = this.hashingAlg(inputArr);
                    inputArr["trxHash"] = hashStr;
                    // console.log(inputArr);
                    this.props.funcStore(inputArr);
                }
            });
        }
    };

    updateWithdrawalAddresssHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.id = this.state.edit_id;
                data.crypto = this.state.edit_network === "TRC20-USDC" ? "USDC" : "USDT";
                data.withdrawal_address = this.state.edit_withdrawal_address;

                this.props.updateMemberWithdrawalAddress(data);
            }
        });
    };

    removeCryptoAddressHandler = (e, id, withdrawalAddress) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
            title: t("global.reminder"),
            text: t("global.remove") + " " + withdrawalAddress + "? " + t("global.deleted-cannot-recovered") + ".",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.confirm"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container reminder",
                    icon: "swal-reminder-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.withdrawal_address_id = id;
                this.props.removeMemberCryptoAddress(data);
            }
        });
    };

    openModalHandler = (e, id, network, withdrawal_address) => {
        e.preventDefault();

        this.setState({
            openModal: true,
            edit_id: id,
            edit_network: network,
            edit_withdrawal_address: withdrawal_address,
            errorMsg: [],
            formError: false,
            showAddBank: !this.state.showAddBank,
        });
    };

    closeModalHandler = (e) => {
        e.preventDefault();

        this.setState({
            openModal: false,
        });
    };

    searchHandler = () => {
        let newInput = Object.assign({}, this.state.input);
        newInput['crypto_id'] = '';
        newInput['crypto_name'] = '';
        newInput['crypto_address'] = '';
        this.setState(
            {
                loading: true,
                input: newInput,
            },
            () => {
                let data = {};
                data.crypto = this.state.type;
                this.props.funcGetDropDown(data);
            }
        );
    };

    selectChannel = (val, id, slug) => {
        let newInput = Object.assign({}, this.state.input);
        let type = (val === "TRC20-USDC" ? "USDC" : "USDT");
        let filterWithdrawalCryptoAddress = this.state.dropDown.memberWithdrawalCryptoAddress.filter((item) => item.crypto === type);
        let showAddCrypto = (filterWithdrawalCryptoAddress && filterWithdrawalCryptoAddress.length > 0) ? false : true;
        
        newInput["amount"] = "";
        newInput["crypto_address"] = (filterWithdrawalCryptoAddress && filterWithdrawalCryptoAddress.length > 0) ? (filterWithdrawalCryptoAddress[0].withdrawal_address || '') : '';
        newInput['crypto_id'] = (filterWithdrawalCryptoAddress && filterWithdrawalCryptoAddress.length > 0) ? (filterWithdrawalCryptoAddress[0].id || '') : id;
        
        this.setState({
            input: newInput,
            errorMsg: [],
            channel: val,
            channel_id: id,
            type,
            showAddCrypto,
            exchangeRateAmount: 0,
        });

        // this.searchHandler();
    };

    onCryptoGetExchangeRates = () => {
        let data = {};
        data.amount = this.state.input.amount;
        data.currency = this.props.currentUser.currency;
        data.crypto = this.state.channel;

        this.props.funcGetCryptoExchangeRates(data);
    };

    securityPinModalHandler = (value) => {
        if (this.state.input.amount && this.state.input.crypto_id && this.state.input.crypto_address !== null) {
            this.setState({ openSecurityPinModal:true ,securityPinModal: value, checkSecurityAttemptsLoading: true });
        }

        this.props.checkSecurityPinAttempts();
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0 || seconds <= 0) {
            clearInterval(this.timer);
            this.setState({ reenter_security_pin_status: true, seconds: 60, time: 0, enter_pin_attempts: 3 });
        }
    };

    hashingAlg = (inputArr) => {
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        Object.keys(inputArr).forEach((item) => {
            if (inputArr[item] !== "" && item !== "rates") {
                paramsKey.push(item);
            }
        });
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = inputArr[val];
            if (x !== "") {
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        return hashStr;
    };

    toggleSecurityPinModal = () => {
        this.setState({
            openSecurityPinModal: !this.state.openSecurityPinModal
        })
    }

    render() {
        const { t } = this.props;
        const num_format = numFormat(this.props.currentUser.currency);
        const cryptoImg = this.imageImport(require.context("../../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));

        let inputArr = this.state.input ? this.state.input : [];

        const memberCryptoWallet = [{ id: 1, crypto_id: "1", crypto_name: "USDT-TRC20", crypto_type: "usdt", crypto_address: "3Dbf3dck67356ghfghjki569809355678" }];


        // let addMemberCryptoWallet = [
        //     // { id: 21, label: "ERC20-USDT", type: "usdt" },
        //     { id: (process.env.REACT_APP_ENV === "staging") ? 21 : 6, label: "TRC20-USDT", type: "usdt" },
        //     { id: (process.env.REACT_APP_ENV === "staging") ? 23 : 5, label: "TRC20-USDC", type: "usdc" },
        //     // { id: 23, label: "ERC20-USDC", type: "usdc" },
        // ];

        if (this.state.openModal) {
            formValidation("update_member_withdrawal_address_form");
        }

        // const cryptoChannel = [
        //     // { id: 21, label: "ERC20-USDT", type: "usdt" },
        //     { id: 20, label: "TRC20-USDT", type: "usdt" },
        //     { id: 22, label: "TRC20-USDC", type: "usdc" },
        //     // { id: 23, label: "ERC20-USDC", type: "usdc" },
        // ];
        return (
            <>
                <Modal
                    isOpen={this.state.openModal}
                    closeTimeoutMS={500}
                    portalClassName={`global-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={(event) => this.closeModalHandler(event)}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t("crypto-address-details.edit-withdrawal-address-details")}</h5>
                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.closeModalHandler(event)} />
                        </div>
                        <div className="modal-body">
                            <form
                                className="forms-col"
                                method="post"
                                id="update_member_withdrawal_address_form"
                                encType="multipart/form-data"
                                onSubmit={this.updateWithdrawalAddresssHandler}
                                noValidate
                            >
                                <div>
                                    <label className="col-form-label lh-1">{t("crypto-address-details.withdrawal-address")}</label>
                                    <StringInput
                                        id="edit_withdrawal_address"
                                        name="edit_withdrawal_address"
                                        className={`form-control ${this.state.errorMsg.withdrawal_address ? "was-validated is-invalid" : ""}`}
                                        value={this.state.edit_withdrawal_address}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                        step="any"
                                        required={true}
                                        requiredMessage={t("validation.required")}
                                        formError={this.state.formError}
                                        errorMsg={this.state.errorMsg.withdrawal_address || ""}
                                    />
                                </div>
                                <Button typeButton="submit" classValue="btn btn-gradient-blue mt-3" buttonName={t("top-profile.update")} />
                            </form>
                        </div>
                    </div>
                </Modal>

                <SecurityPinModal
                    {...this.state}
                    toggleSecurityPinModal={this.toggleSecurityPinModal}
                // verificationModalHandler={this.verificationModalHandler}
                />
                {isDesktop ? (
                    <form className="forms-col needs-validation" method="post" id="withdrawal_form" onSubmit={this.submitHandler} noValidate>
                        {this.state.loading ? (
                            <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                        ) : (
                            <>
                                {/* <div className="row">
                                    <label className="col-3 col-form-label">{t("withdrawal.payment-option")}:</label>
                                    <div className="col-9">
                                        <div className="form-check-group select-bank-opts">
                                            {this.state.dropDown.payment_gateway.length > 0 ? (
                                                this.state.dropDown.payment_gateway.map((val, idx) => {
                                                    if (val.transaction_slug === "crypto" && val.wd_status === 1 && val.title !== "Crypto") {
                                                        return (
                                                            <div className="form-check form-check-inline" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`payment${val.slug}`}
                                                                    value={val.slug}
                                                                    checked={this.state.input.paymentGatewaySelected === val.slug ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                <label
                                                                    className="btn form-check-label"
                                                                    htmlFor={`paymentdirepay`}
                                                                    onClick={() => this.selectPaymentGatewayHandler("direpay")}
                                                                >
                                                                    <img className="active" src={cryptoImg["direpay.svg"]} alt={"direpay"} />
                                                                </label>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            ) : this.state.isOptionLoading === false ? (
                                                <span className="text-danger">{t("deposit.invalid-payment-gateway")}</span>
                                            ) : (
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("withdrawal.select-channel")}:</label>
                                    <div className="col-9 align-self-center">
                                        <div className="form-check-group select-bank-opts">
                                            {cryptoChannel.length > 0 ? (
                                                cryptoChannel.map((val, idx) => {
                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_code"
                                                                id={`crypto${val.label}`}
                                                                value={val.label}
                                                                checked={this.state.channel === val.label ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <label
                                                                className="btn form-check-label"
                                                                htmlFor={`crypto${val.label}`}
                                                                onClick={() => this.selectChannel(val.label, val.id, val.type)}
                                                            >
                                                                <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                                <p>{val.label}</p>
                                                                {process.env.REACT_APP_ENV === "staging" && val.withdrawal_est_time && (
                                                                    <div className="transfer-duration">
                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                    Deposit in 1 min */}
                                                                    {val.withdrawal_est_time === "00:00:00" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                            {t("withdrawal.instant-withdrawal")}
                                                                        </>
                                                                        ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                            {t("withdrawal.withdrawal-in")} {val.withdrawal_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                        </>
                                                                    )}           
                                                                    </div>
                                                                                
                                                                )}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                // <span className="text-danger">{t("deposit.invalid-payment-channel")}</span>
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("withdrawal.select-crypto-wallet")}:</label>
                                    <div className="col-9">
                                        <div className="bank-list-group">
                                            {this.state.dropDown.memberWithdrawalCryptoAddress.length > 0 &&
                                                this.state.dropDown.memberWithdrawalCryptoAddress.map((val, idx) => {
                                                    if(val.crypto === this.state.type)
                                                    {
                                                        return (
                                                            <div className="form-check form-check-inline" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    name="crypto_id"
                                                                    id={`withdrawalAddressOpt${val.id}`}
                                                                    value={val.id}
                                                                    onChange={(event) => this.onInputChangeHandler(event, val.withdrawal_address)}
                                                                    checked={Number(this.state.input.crypto_id) === Number(val.id) ? true : false}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`withdrawalAddressOpt${val.id}`}>
                                                                    <div>
                                                                        <img src={cryptoImg[`${val.crypto.toLowerCase()}.svg`]} alt={val.crypto} />
                                                                        {val.network}
                                                                    </div>
                                                                    <p>{val.withdrawal_address}</p>
                                                                    <div>
                                                                        <Button
                                                                            typeButton="button"
                                                                            classValue="btn icon"
                                                                            key={`edit-${val.id}`}
                                                                            idValue={val.id === undefined ? 0 : val.id}
                                                                            clicked={(e) => this.openModalHandler(e, val.id, val.network, val.withdrawal_address)}
                                                                            buttonName={<Icon icon="uil:edit" />}
                                                                        />
                                                                        <Button
                                                                            typeButton="button"
                                                                            classValue="btn icon red"
                                                                            key={`remove-${val.id}`}
                                                                            idValue={val.id === undefined ? 0 : val.id}
                                                                            clicked={(e) => this.removeCryptoAddressHandler(e, val.id, val.withdrawal_address)}
                                                                            buttonName={<Icon icon="uil:trash-alt" />}
                                                                        />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        );
                                                    }
                                                    else
                                                    {
                                                        return null;
                                                    }
                                                })}
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="cryptoOtps"
                                                    id="cryptoOtpsAdd"
                                                    value="addcryptoOtp"
                                                    onChange={() => this.showAddCryptoHandler()}
                                                    checked={this.state.showAddCrypto ? true : false}
                                                />
                                                <label className="btn form-check-label wdrawal-add-bank" htmlFor="cryptoOtpsAdd">
                                                    {this.state.showAddCrypto ? <Icon className="me-2" icon="radix-icons:minus-circled" /> : <Icon className="me-2" icon="radix-icons:plus-circled" />}
                                                    {t("withdrawal.add-crypto-wallet")}
                                                </label>
                                            </div>
                                            {/* <Fade direction="up" duration={500} triggerOnce>
                                                <li
                                                    className={`list-group-item select-bank-opts p-3 ${this.state.showAddCrypto ? "" : "d-none"} form-control ${this.state.errorMsg?.bank_id ? "was-validated is-invalid" : ""
                                                        }`}
                                                >
                                                    {this.state.showAddCrypto &&
                                                        cryptoChannel.map((val, idx) => {
                                                            return (
                                                                <div className="form-check form-check-inline" key={idx}>
                                                                    <input
                                                                        className="btn-check"
                                                                        type="radio"
                                                                        name="add_crypto_id"
                                                                        id={`addCryptoOtps${val.id}`}
                                                                        value={val.id}
                                                                        onChange={(event) => this.onInputChangeHandler(event)}
                                                                        checked={Number(this.state.input.crypto_id) === Number(val.id) ? true : false}
                                                                    />
                                                                    <label className="btn form-check-label" htmlFor={`addCryptoOtps${val.id}`}>
                                                                        <img src={cryptoImg[`${val.type}.svg`]} alt={val.type} />
                                                                        <p>{val.label}</p>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                </li>
                                            </Fade> */}
                                            <div className="invalid-feedback">{this.state.errorMsg?.bank_id || ""}</div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showAddCrypto && (
                                    <div className="row g-2">
                                        <label className="col-3 col-form-label">{t("withdrawal.wallet-address")}</label>
                                        <div className="col-9">
                                            <StringInput
                                                id="crypto_address"
                                                name="crypto_address"
                                                className={`form-control ${this.state.errorMsg.crypto_address ? "was-validated is-invalid" : ""}`}
                                                placeholder={cryptoChannel[inputArr.crypto_id] ? cryptoChannel[inputArr.crypto_id].crypto_address : ""}
                                                value={inputArr.crypto_address}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                step="any"
                                                required={this.state.showAddCrypto === true ? true : false}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.withdrawal_address || ""}
                                            />
                                        </div>

                                        {/* <label className="col-3 col-form-label">OTP</label>
                                        <div className="col-9">
                                            <div className='d-flex'>
                                            <NumberInput
                                                id="crypto_address"
                                                name="crypto_address"
                                                className={`form-control ${this.state.errorMsg?.crypto_address ? "was-validated is-invalid" : ""}`}
                                                placeholder={memberCryptoWallet[inputArr.crypto_id] ? memberCryptoWallet[inputArr.crypto_id].crypto_address : ""}
                                                value={inputArr.crypto_address}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                // step="any"
                                                // readOnly={memberBankAccount[inputArr.bank_id] ? true : false}
                                                // required={this.props.withdrawalOption === "bank-transfer" ? true : false}
                                                // requiredMessage={t("validation.required")}
                                                // formError={this.state.formError}
                                                // errorMsg={this.state.errorMsg.bank_acc_number || ""}
                                            />
                                            <button className='btn btn-gradient-blue ms-2'>Retransmit</button>
                                            </div>
                                        </div> */}
                                    </div>
                                )}

                                {/* <div className="row">
                                    <label className="col-3 col-form-label">OTP:</label>
                                    <div className="col-9 d-flex">
                                        <div className="input-group left has-validation">
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className={`${this.state.errorMsg?.amount ? "was-validated is-invalid" : ""}`}
                                                placeholder={t("withdraw.otp")}
                                                value={inputArr.amount}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                min={this.state.minWithdrawal || 0}
                                                max={6}
                                                step="any"
                                                required={true}
                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                minValueMessage={t("validation.amtMinValue", { 0: this.state.minWithdrawal })}
                                                maxValueMessage={t("validation.amtMaxValue", { 0: this.state.maxWithdrawal })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.amount || ""}
                                                onBlur={this.onCryptoGetExchangeRates}
                                            />
                                        </div>
                                        <Button
                                            typeButton="button"
                                            clicked={this.withdrawalMaxHandler}
                                            classValue="btn btn-gradient-blue ms-2"
                                            style={{ minWidth: "unset" }}
                                            buttonName={t("withdraw.retransmit")}
                                        />
                                    </div>
                                </div> */}

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("withdraw.amount")}:</label>
                                    <div className="col-9 d-flex">
                                        <div className="withdraw-crypto-amount">
                                            <div className="d-flex position-relative" style={{ width: '47%' }}>
                                                <div className="input-group left has-validation">
                                                    <span className="input-group-text">{this.props.currentUser.currency}</span>
                                                    <NumberInput
                                                        id="amount"
                                                        name="amount"
                                                        className={`${this.state.errorMsg?.amount ? "was-validated is-invalid" : ""}`}
                                                        placeholder={`${t("withdraw.min-max-limit")} ${new Intl.NumberFormat("en-US", num_format).format(this.state.minWithdrawal)}/${new Intl.NumberFormat("en-US", num_format).format(this.state.maxWithdrawal)}`}
                                                        value={inputArr.amount}
                                                        onChange={(event) => this.onInputChangeHandler(event)}
                                                        min={this.state.minWithdrawal || 0}
                                                        max={this.state.maxWithdrawal || 0}
                                                        step="any"
                                                        required={true}
                                                        onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                        minValueMessage={t("validation.amtMinValue", { 0: this.state.minWithdrawal })}
                                                        maxValueMessage={t("validation.amtMaxValue", { 0: this.state.maxWithdrawal })}
                                                        requiredMessage={t("validation.required")}
                                                        formError={this.state.formError}
                                                        errorMsg={this.state.errorMsg.amount || ""}
                                                        onBlur={this.onCryptoGetExchangeRates}
                                                        style={{ paddingRight: '4.25rem' }}
                                                    />
                                                </div>
                                                <Button
                                                    typeButton="button"
                                                    clicked={this.withdrawalMaxHandler}
                                                    classValue="btn btn-gradient-blue btn-max"
                                                    buttonName={t("withdraw.max")}
                                                />
                                            </div>
                                            <div className="exchange-rate-img" style={{ width: '6%' }}>
                                                <Icon icon="akar-icons:arrow-repeat" />
                                            </div>
                                            <div className="exchange-rate-input" style={{ width: '47%' }}>
                                                <div className="input-group left has-validation">
                                                    <span className="input-group-text">{this.state.type}</span>
                                                    <NumberInput
                                                        id="exchange_rate"
                                                        name="exchange_rate"
                                                        placeholder={this.state.exchangeRateAmount}
                                                        value={this.state.exchangeRateAmount}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <small className="col-9 offset-3">
                                        {t("withdraw.last-withdraw")}: <span style={{ fontWeight: "600" }}>{currency[this.props.currentUser.country_id] ?? ""}200</span>
                                    </small> */}
                                    {/* <label className="col-6 col-form-label">{t("deposit.exchange-rate")}:</label> */}
                                    {/* <p className="exchange-rate">
                                        Exchange Rate: {this.state.type} 
                                        <span className="number">{this.state.exchangeRateAmount}</span>
                                    </p> */}
                                </div>

                                <div className="row">
                                    <div className="col-9 offset-3">
                                        {this.state.addSecurityPinStatus === true && this.state.input.amount && this.state.input.crypto_id && this.state.input.crypto_address ? (
                                            <button
                                                className="btn btn-gradient-blue"
                                                type="button"
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#securityPinModal"
                                                onClick={() => this.securityPinModalHandler("enter-sec-pin")}
                                                id="securityPinSubmitBtn"
                                                disabled={this.state.exchangeRateAmount === 0 || this.state.exchangeRateAmount === undefined ? true : false}
                                            >
                                                {t("global.confirm")}
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-gradient-blue"
                                                type="submit"
                                                value="Submit"
                                                disabled={this.state.exchangeRateAmount === 0 || this.state.exchangeRateAmount === undefined ? true : false}
                                            >
                                                {t("global.confirm")}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                ) : (
                    <MobileCrypto
                        {...this.state}
                        withdrawalMaxHandler={this.withdrawalMaxHandler}
                        selectChannel={this.selectChannel}
                        memberCryptoWallet={memberCryptoWallet}
                        cryptoChannel={cryptoChannel}
                        numFormat={num_format}
                        securityPinModalHandler={this.securityPinModalHandler}
                        onInputChangeHandler={this.onInputChangeHandler}
                        removeCryptoAddressHandler={this.removeCryptoAddressHandler}
                        onCryptoGetExchangeRates={this.onCryptoGetExchangeRates}
                        submitHandler={this.submitHandler}
                        searchHandler={this.searchHandler}
                    />
                )}
            </>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        store: state.withdrawal.store,
        topProfileData: state.profile.top_profile_data,
        currentUser: state.currentUser.data,
        dropDown: state.withdrawal.dropDown,
        update_crypto_address_result: state.profile.update_crypto_address_result,
        memberRemoveCryptoAddressResult: state.profile.member_remove_crypto_address_result,
        get_crypto_exchange_rates_result: state.withdrawal.get_crypto_exchange_rates_result,
        verify_security_pin_result: state.profile.verify_security_pin_result,
        update_security_pin_result: state.profile.update_security_pin_result,
        check_security_pin_attempts_result: state.profile.check_security_pin_attempts_result,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcStore: (data) => dispatch(actions.store(data)),
        getTopProfile: () => dispatch(getTopProfile()),
        funcGetDropDown: (data) => dispatch(actions.getDropDown(data)),
        updateMemberWithdrawalAddress: (data) => dispatch(updateMemberWithdrawalAddress(data)),
        removeMemberCryptoAddress: (data) => dispatch(removeMemberCryptoAddress(data)),
        funcGetCryptoExchangeRates: (data) => dispatch(actions.getCryptoExchangeRates(data)),
        checkSecurityPinAttempts: (data) => dispatch(checkSecurityPinAttempts(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Crypto));
