import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { LazyLoadImage } from "react-lazy-load-image-component";
import { detectCountry } from "../../../hoc/Shared/utility";

let dynamicCountry = detectCountry(window.location.host);

export class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };
  render() {
    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    const { t } = this.props;
    const homeImg = this.imageImport(
      require.context(
        "../../../assets/images/home/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    // const videos = this.imageImport(
    //   require.context(
    //     "../../../assets/images/videos/",
    //     false,
    //     /\.(png|jpe?g|svg|mp4)$/
    //   )
    // );
    const christmas = this.imageImport(
      require.context(
        "../../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const chinese = this.imageImport(
      require.context(
        "../../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const raya = this.imageImport(
      require.context(
        "../../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const merdeka = this.imageImport(
      require.context(
        "../../../assets/images/home/merdeka",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const mooncake = this.imageImport(
      require.context(
        "../../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const uefa = this.imageImport(
      require.context(
        "../../../assets/images/home/uefa",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const anni = this.imageImport(
      require.context(
        "../../../assets/images/home/anni",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <section
        className={`global-section ${this.props.currentEvent === "uefa" ? "cny event" : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? "mooncake event" :  this.props.currentEvent === "anni" ? " event" : ""}`}
      >
        <div className="x-container">
          <div
            className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
              }`}
          >
            <div className="d-flex align-item-center">
            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) :(
                                <></>
                            )}
                            {this.props.currentEvent === "cny" ?  <h2>{t("main-page.who-we-are")}</h2> :  <h2 className="mb-2">{t("main-page.who-we-are")}</h2> }
                            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : (
                                <></>
                            )}
          
            </div>
            <p>{t("main-page.who-we-are-remark")}</p>
          </div>
          <div className="position-relative">
            <div
              className={`section-image-container ${this.props.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ? "merdeka" : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? "mooncake" : this.props.currentEvent === "mooncake" ? "mooncake" : this.props.currentEvent === "cny" ? "cny" :  this.props.currentEvent === "uefa" ? "uefa" : this.props.currentEvent === "anni" ? "anni" : this.props.currentEvent === "christmas" ? "christmas" : "" }` }
            >
              {this.props.currentEvent === "raya" ? (
                <LazyLoadImage
                  className="section-img"
                  src={raya["wwr-img.webp"]}
                  visibleByDefault={true}
                  alt="who-we-are-img"
                  effect="blur"
                />
              ) : this.props.currentEvent ==="merdeka" && this.props.currentUser.country_id !== 4 ?(
                <LazyLoadImage
                className="section-img"
                src={merdeka["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
              />
              ): this.props.currentEvent ==="mooncake" && this.props.currentUser.country_id !== 4 ?(
                <LazyLoadImage
                className="section-img"
                src={mooncake["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
              />
              ) :this.props.currentEvent ==="halloween" ?(
                <LazyLoadImage
                className="section-img"
                src={halloween["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
              />
              ) :this.props.currentEvent ==="christmas" ?(
                <>
                <LazyLoadImage
                className="section-img"
                src={christmas["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
              />
              <LazyLoadImage
              className="christmas-tree"
              src={christmas["tree.webp"]}
              visibleByDefault={true}
              alt="who-we-are-img"
              effect="blur"
            />
            </>
              ) :this.props.currentEvent ==="cny" ?(
                <LazyLoadImage
                className="section-img"
                src={chinese["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
              /> ) :
              this.props.currentEvent ==="uefa" ?(
                <LazyLoadImage
                className="section-img"
                src={uefa["wwr-img.webp"]}
                visibleByDefault={true}
                alt="who-we-are-img"
                effect="blur"
                /> ) :
                this.props.currentEvent ==="anni" ?(
                  <LazyLoadImage
                  className="section-img"
                  src={anni["wwr-img.webp"]}
                  visibleByDefault={true}
                  alt="who-we-are-img"
                  effect="blur"
                /> ):
              (
                <LazyLoadImage
                  className="section-img"
                  src={homeImg["wwr-img.webp"]}
                  visibleByDefault={true}
                  alt="who-we-are-img"
                  effect="blur"
                />
              )}
              {/* <LazyLoadImage className="section-img" src={homeImg["wwr-img.webp"]} alt="who-we-are-img" effect="blur" /> */}
              <img
                className="sub-img1"
                src={homeImg["wwr-subImg1.webp"]}
                alt="who-we-are-img"
              />
              <img
                className="sub-img2"
                src={homeImg["wwr-subImg2.webp"]}
                alt="who-we-are-img"
              />
              {this.props.currentEvent ==="merdeka" && this.props.currentUser.country_id !== 4 ? (
              <img
              className="sub-img3"
              src={merdeka["wwr-subImg3.webp"]}
              alt="who-we-are-img"
            />
              ): 
              (
              <img
                className="sub-img3"
                src={homeImg["wwr-subImg3.webp"]}
                alt="who-we-are-img"
              />)
              }
        
            </div>
            <div className={`section-content-container card-shadow ${this.props.currentEvent === "cny" ? "cny" : ""}`}>
              {this.props.currentEvent === "raya" && (
                  <img
                    className="sub-img4"
                    src={raya["content-deco-6.webp"]}
                    alt="deco 3"
                  />

              )}
              {this.props.currentEvent === "christmas" ? (
                <img
                  className="deco-4"
                  src={christmas["content-deco-3.webp"]}
                  alt="content-deco-3"
                />
              ) : this.props.currentEvent === "cny" ? (
                <img
                  className="deco-4"
                  src={chinese["content-deco-3.webp"]}
                  alt="content-deco-3"
                />
              ) :(
                <></>
              )}
              {this.props.currentEvent === "halloween" && (
                            <img
                            className="halloween-deco-3"
                            src={halloween["content-deco-3.webp"]}
                            alt="content-deco-3"
                          />
                      )}
              <div className={`section-content ${this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? "mooncake" : this.props.currentEvent === "halloween" ? "halloween" : this.props.currentEvent === "cny" ? "cny" : this.props.currentEvent === "christmas" || "anni" ? "christmas": ""} `}>
                <h2>{t("main-page.wwr-title", {country: t(`global.country.${dynamicCountry.label}`)})}</h2>
                <p>
                  {t("main-page.wwr-content-1")}
                  <br></br> <br></br>
                  {t("main-page.wwr-content-2", {country: t(`global.country.${dynamicCountry.label}`)})}
                  <br></br>
                  <br></br>
                  {t("main-page.wwr-content-3", {country: t(`global.country.${dynamicCountry.label}`), attribute: process.env.REACT_APP_WEBSITE_TITLE})}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(
  withNamespaces("translation")(MainSection)
);
