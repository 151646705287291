import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const homeReducer = createReducer(initialState) ({
    [type.GET_COUNTRY_AND_LANGUAGES]: (state, action) => ({
        ...state,
        country_data: action.payload,
    }),

    [type.GET_ANNOUNCEMENT]: (state, action) => ({
        ...state,
        announcement_data: action.payload,
    }),
    
    [type.GET_FEATURED_PROMOS]: (state, action) => ({
        ...state,
        featured_promos_data: action.payload,
    }),

    [type.SABA_ODDS]: (state, action) => ({
        ...state,
        odds: action.payload,
    }),
});

export default homeReducer;