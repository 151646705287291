export const mobilePrefix = {
    1: {
        "code": "MY",
        "prefix": "+60",
        "tag": [{ value: "+60", name: "Malaysia", image: "MY.webp", phone: "+60", code: "MY" }]
    },
    4: {
        "code": "ID",
        "prefix": "+62",
        "tag": [{ value: "+62", name: "Indonesia", image: "ID.webp", phone: "+62", code: "ID" }]
    }
};
export const mobilePrefixCodeAsKey = {
    'MY': {
        "code": "MY",
        "prefix": "+60",
        "tag": [{ value: "+60", name: "Malaysia", image: "MY.webp", phone: "+60", code: "MY" }]
    },
    'ID': {
        "code": "ID",
        "prefix": "+62",
        "tag": [{ value: "+62", name: "Indonesia", image: "ID.webp", phone: "+62", code: "ID" }]
    }
};