import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";

export class MobileSubHeader extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={`m-subHeader ${this.props.className}`} style={this.props.style}>
        {this.props.closeModalHandler && (
          <div className="sub-link">
            <Icon icon="uil:angle-left" onClick={this.props.closeModalHandler} />
          </div>
        )}

        {this.props.returnPage && (
          <NavLink className="sub-link" to={{ pathname: `${this.props.returnPage}` }}>
            <Icon icon="uil:angle-left" />
          </NavLink>
        )}

        {this.props.header && <h6>{this.props.header}</h6>}

        {this.props.subPage && (
          <NavLink className="sub-link" to={{ pathname: `${this.props.subPage}`, state: {prev: `${this.props.returnPage ? this.props.returnPage : undefined }`} }}>
            <p>{this.props.subPageHeader}</p>
            {this.props.showSubIcon &&
              <Icon icon="uil:angle-right" />
            }
          </NavLink>
        )}

        {this.props.modalHeader && (
          <div className="sub-link">
            <p onClick={this.props.modalHandler}>
              {
                this.props.showEdit === true ? (
                  <>{t("message.done")}</>
                ) : (
                  this.props.modalHeader
                )
              }
            </p>
          </div>
        )}

      </div>
    );
  }
}

export default (withNamespaces("translation")(MobileSubHeader));
