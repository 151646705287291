import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const withdrawalReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.STORE_WITHDRAWAL_CRYPTO_ADDRESS]: (state, action) => ({
        ...state,
        store_withdrawal_crypto_address: action.value,
    }),
    [type.GET_WITHDRAWAL_CRYPTO_ADDRESS]: (state, action) => ({
        ...state,
        get_withdrawal_crypto_address: action.value,
    }),
    [type.CRYPTO_WITHDRAWAL_STORE]: (state, action) => ({
        ...state,
        store_withdrawal_crypto: action.value,
    }),
    [type.WITHDRAWAL_DETAILS]: (state, action) => ({
        ...state,
        get_withdrawal_details: action.value,
    }),
    [type.GET_CRYPTO_EXCHANGE_RATES]: (state, action) => ({
        ...state,
        get_crypto_exchange_rates_result: action.value,
    })
});

export default withdrawalReducer;