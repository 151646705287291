import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const promosReducer = createReducer(initialState) ({
    [type.GET_PROMOS]: (state, action) => ({
        ...state,
        promos_data: action.payload,
    }),

    [type.SHOW_PROMOS]: (state, action) => ({
        ...state,
        show_promos_data: action.payload,
    }),

    [type.SET_STATE]: (state, action) => ({
        ...state,
        persistState: action.payload,
    }),
});

export default promosReducer;