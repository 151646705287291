import React, { Component, createRef } from "react";
import MobileSubHeader from '../../../components/PageHeader/MobileSubHeader';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import MobileReferralDetails from "./MobileReferralDetails";
// import ReferralModal from "./ReferralModal";
import Skeleton from "react-loading-skeleton";
import QRCode from "react-qr-code";

const fb_app_id = "1099533464309233"; // app id from facebook developer app


export class MobileReferral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      referral_code: "",
      referral_link: "",
      referral_settings: []
    };
    this.linkRef = createRef();
  }

  componentDidMount() {

  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]", nextProps);
    if (nextProps.member_referral_data !== undefined && nextProps.member_referral_data !== this.props.member_referral_data) {
      if (nextProps.member_referral_data.responseCode === 200 && nextProps.member_referral_data.msgType === "success") {
        let referralData = nextProps.member_referral_data.data;

        // If Testing In Localhost should included port Number
        let referralLink = "";
        if (window.location.hostname === "localhost") {
          referralLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/?refCode=" + referralData.memberReferral.referralCode;
        } else {
          referralLink = window.location.protocol + "//" + window.location.hostname + "/?refCode=" + referralData.memberReferral.referralCode;
        }

        this.setState({
          referral_code: referralData.memberReferral.referralCode,
          referral_link: referralLink,
          referral_settings: referralData.referralSettings,
          loading: false
        });
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  copyToClipboardHandler = event => {
    this.linkRef.current.select();
    document.execCommand("copy");
  }

  shareToSocialMedia = (event, type) => {
    let url = null;
    switch (type) {
      case "fb":
        // currrently using URL Redirection, an alternate solution suggested by fb (default solution is SDK ver)
        if (fb_app_id) {
          url = `https://www.facebook.com/dialog/share?app_id=${fb_app_id}&display=popup`;
        }
        // &redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer"; // additional optional params
        break;
      // case "ig":
      //   // based on official documentation by meta/fb, possibly support IOS & Android only
      //   break;
      case "whatsapp":
        // url = "https://wa.me/?text="; #possible broken at whatsapp
        url = "https://api.whatsapp.com/send?text=";
        break;
      case "telegram":
        url = "https://t.me/share/url?url=";
        break;
      case "messenger":
        if (fb_app_id) {
          url = `http://www.facebook.com/dialog/send?app_id=${fb_app_id}`;
        }
        break;
      case "twitter":
        url = "https://twitter.com/intent/tweet?text=";
        break;
      default:
        break;
    }
    if (url) {
      if (type === "fb") {
        window.open(url + "&href=" + encodeURI(this.state.referral_link));
        // window.open(url+"&href="+encodeURI("https://www.google.com.my")); // for localhost test only
      }
      else if (type === "messenger") {
        window.open(url + "&link=" + encodeURI(this.state.referral_link) + "&redirect_uri=" + encodeURI(window.location.protocol + "//" + window.location.hostname));
        // window.open(url+"&link="+encodeURI("https://www.google.com.my")+"&redirect_uri="+encodeURI("http://www.google.com.my")); // for localhost test only
      }
      else {
        window.open(url + encodeURI(this.state.referral_link));
      }
    }
  }


  toggleDetailsHandler = () => {
    if (this.state.showDetails === false) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({
      showDetails: !this.state.showDetails,
    });
  }

  render() {
    const { t } = this.props;
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    return (
      <Auxiliary>
        {this.state.showDetails &&
          <MobileReferralDetails
            {...this.state}
            {...this.props}
            toggleDetailsHandler={this.toggleDetailsHandler}
          />
        }
        <MobileSubHeader
          className="bg-white"
          returnPage="/me"
          header={t("referral.my-referral")}
          style={{ justifyContent: "center" }}
          modalHeader={t("referral.details")}
          modalHandler={this.toggleDetailsHandler}
        />
        <section className="pf-m-dashboard">
          <div className="">
            <div className="pd-content">
              <div className="referral-tp-panel">
                <div className="row g-3">
                  <div className="col-6">
                    <div>
                      <img className="active" src={this.props.icons["people-ico.svg"]} alt="people-ico" />
                      <div>
                        <label>{t("referral.recommendation.count")}</label>
                        <p>
                          {!this.props.loading ? this.props.count_invitation : 0}
                          <small>{t("referral.recommendation.person")}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 left-border">
                    <div>
                      <img className="active" src={this.props.icons["bonus-received-ico.svg"]} alt="bonus-received-ico" />
                      <div>
                        <label>{t("referral.bonus-received")}</label>
                        <p>
                          <small>{this.props.currency}</small>
                          {!this.props.loading ? Intl.NumberFormat('en-US', this.props.num_format).format(this.props.bonus_received) : Intl.NumberFormat('en-US', this.props.num_format).format(0)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-referral mt-3">
            <h6>{t("referral.popup.friends")}</h6>
            {this.state.loading ? (<Skeleton count={1} height={100} />) : (
              <>
                <div className="ref-qr-code-container mb-3">
                  <div className="ref-qr-code">
                    <QRCode
                      value={this.state.referral_link}
                      id="qr-code"
                      size="350" />
                  </div>
                  <h3>{t("referral.popup.scanfr")}</h3>
                </div>

                <p className="or-line">{t("page-header.or")}</p>
              </>

            )
            }
            <ul className="list-group list-group-horizontal">
              <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "fb")}>
                <img src={icons["facebook.svg"]} alt="facebook" />
                <p>{t("referral.popup.fb")}</p>
              </div>
              {/* <div className="list-group-item">
                <img src={icons["instagram.svg"]} alt="instagram" />
                <p>{t("referral.popup.ig")}</p>
              </div> */}
              <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "whatsapp")}>
                <img src={icons["whatsapp.svg"]} alt="whatsapp" />
                <p>{t("referral.popup.whatsapp")}</p>
              </div>
              <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "telegram")}>
                <img src={icons["telegram.svg"]} alt="telegram" />
                <p>{t("referral.popup.telegram")}</p>
              </div>
              <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "messenger")}>
                <img src={icons["messenger.svg"]} alt="messenger" />
                <p>{t("referral.popup.messenger")}</p>
              </div>
              <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "twitter")}>
                <img src={icons["twitter.svg"]} alt="twitter" />
                <p>{t("referral.popup.twitter")}</p>
              </div>
              <a role="button" className="list-group-item" href={`mailto:?subject=${this.state.referral_link}`} target="_blank" rel="noreferrer">
                <img src={icons["email.svg"]} alt="email" />
                <p>{t("referral.popup.email")}</p>
              </a>
            </ul>

            <div className="input-group right">
              <input
                ref={this.linkRef}
                type="text"
                className="form-control"
                name="referCode"
                defaultValue={this.state.referral_link}
                readOnly
              />
              <span className="input-group-text">
                <button type="button" className="btn btn-gradient-blue" onClick={(event) => this.copyToClipboardHandler(event)}>
                  {t("referral.popup.copy")}
                </button>
              </span>
            </div>
          </div>
        </section>
        {/* <ReferralModal /> */}
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileReferral));