import React, { Component } from 'react'
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import moment from "moment";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { getTopProfile } from "../../../store/ducks/profile/actions";
import { getUserData } from "../../../store/ducks/currentUser/actions";

// import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import { formValidation } from "../../../hoc/Shared/utility";
import ReactLoading from "react-loading";

import SecurityPinModal from '../../../components/SecurityPinModal/SecurityPinModal';
import VerificationModal from './VerificationModal';
import MobileUpdateName from './MobileUpdateName';
import MobileThirdPartyAuthorization from './MobileThirdPartyAuthorization';

import DateScroller from '../../../components/MobileAsset/DateScroller/DateScroller';
import Gender from '../../../components/MobileAsset/Gender/Gender';
import SelectProfile from '../../../components/MobileAsset/SelectProfile/SelectProfile';
import StringInput from '../../../components/Input/StringInput/StringInput';

import AddAddressModal from '../../../components/AddressModal/AddAddressModal';

export class MobilePersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddSecurityPin: false,

      showGenderSelect: false,
      showDateSelect: false,
      showProfileSelect: false,
      showUpdateName: false,
      showThirdPartyAuthorization: false,
      addAddress: false,

      fullName: "",
    };
  }

  componentDidMount() {
    formValidation('basic_info_from');
  }
  //open mobile add security pin page
  toggleShowAddSecurityPin = () => {
    if (this.state.showAddSecurityPin === false) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    if (this.props.securityPinStatusClass === "") {
      this.props.securityPinModalHandler('set-sec-pin')
    } else {
      this.props.securityPinModalHandler('edit-sec-pin')
    }

    this.setState({
      showAddSecurityPin: !this.state.showAddSecurityPin
    })

  }

  updateFullName = (e) => {
    this.setState({
      fullName: e.target.value
    })
  }

  //open mobile verification page
  toggleShowVerification = (value) => {
    if (this.state.showVerification === false) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    if (this.state.showAddSecurityPin) {
      this.setState({
        showAddSecurityPin: false
      })
    }

    this.props.verificationModalHandler(value)
  }

  //open or close select gender asset
  toggleShowGender = () => {
    this.setState({
      showGenderSelect: !this.state.showGenderSelect
    })
  }
  //open or close select date asset
  toggleShowDateSelect = () => {
    this.setState({
      showDateSelect: !this.state.showDateSelect
    })
  }
  //open or close select profile pic
  toggleShowProfilePic = () => {
    this.setState({
      showProfileSelect: !this.state.showProfileSelect
    })
  }

  //open or close edit name
  toggleShowUpdateName = () => {
    this.setState({
      showUpdateName: !this.state.showUpdateName
    })
  }

  //open or close third party authorization 
  toggleShowThirdPartyAuthorization = () => {
    if (this.state.showThirdPartyAuthorization === false) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({
      showThirdPartyAuthorization: !this.state.showThirdPartyAuthorization
    })
  }

  toggleAddAddressHandler = () => {
    if (this.state.addAddress === false) {
        document.body.classList.add('modal-open');
    }
    else {
        document.body.classList.remove('modal-open');
    }
    this.setState({
        addAddress: !this.state.addAddress,
    });
}


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    // Get birthday countdown and age
    let birthdayCountdown = '';
    // let age = 0;

    if (this.props.formFilter.dates) {
      let nextBirthday = moment().format("yyyy-") + moment(this.props.formFilter.dates).format("MM-DD");
      let age = 0;
      if (nextBirthday === moment().format("yyyy-MM-DD")) {
        age = moment().diff(moment(this.props.formFilter.dates).format("yyyy-MM-DD"), 'years');
        birthdayCountdown = t("profile.today-birthday").replace('%%age%%', age);
      } else {
        if (nextBirthday < moment().format("yyyy-MM-DD")) {
          nextBirthday = moment().add(1, "years").format("yyyy-") + moment(this.props.formFilter.dates).format("MM-DD");
          age = moment().diff(moment(this.props.formFilter.dates).subtract(1, "years").format("yyyy-MM-DD"), 'years');
        } else {
          age = moment().diff(moment(this.props.formFilter.dates).subtract(1, "years").format("yyyy-MM-DD"), 'years');
        }

        const given = moment(nextBirthday, "YYYY-MM-DD");
        const current = moment().startOf('day');
        const dayLeft = moment.duration(given.diff(current)).asDays();
        birthdayCountdown = t("profile.birthday-countdown").replace('%%age%%', age);
        birthdayCountdown = birthdayCountdown.replace('%%day%%', dayLeft);
      }
    }

    // const profile = this.imageImport(
    //   require.context("../../../assets/images", false, /\.(png|jpe?g|svg)$/)
    // );

    const mbIcon = this.imageImport(
      require.context(
        "../../../assets/images/profile/mobile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const avatar = this.imageImport(
      require.context("../../../assets/images/profile/profile-icons", false, /\.(png|jpe?g|svg)$/)
    );

    let topProfileData = null;
    if (!this.state.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
    }

    return (
      <Auxiliary>
        <VerificationModal
          {...this.state}
          {...this.props}
          closeVerification={this.toggleShowVerification}
        />

        <SecurityPinModal
          {...this.props}
          {...this.state}
          securityPinStatusClass={this.props.securityPinStatusClass}
          closeAddSecurityPin={this.toggleShowAddSecurityPin}
          closeVerification={this.toggleShowVerification}
        />

        <MobileThirdPartyAuthorization
          {...this.state}
          {...this.props}
          closeThirdPartyAuthorization={this.toggleShowThirdPartyAuthorization}
        />

        <MobileUpdateName
          {...this.props}
          {...this.state}
          toggleShowUpdateName={this.toggleShowUpdateName}
          updateFullName={this.updateFullName}
        />

        <MobileSubHeader
          className="bg-white"
          returnPage="/me"
          header={t("page-header.personal-information")}
          style={{ justifyContent: "unset" }}
        />

        <Gender
          {...this.state}
          {...this.props}
          toggleShowGender={this.toggleShowGender}
          onChangeGenderHandler={this.props.onChangeGenderHandler}
        />
        <DateScroller
          {...this.state}
          {...this.prop}
          toggleShowDateSelect={this.toggleShowDateSelect}
          onChangeDateHandler={this.props.onChangeDateHandler}
          maxDate={18}
        />
        <SelectProfile
          {...this.state}
          {...this.props}
          toggleShowProfilePic={this.toggleShowProfilePic}
        />

        {this.state.addAddress &&
            <AddAddressModal
                {...this.state}
                {...this.props}
                toggleAddAddressHandler={this.toggleAddAddressHandler}
            />
         }


        <section className='ei-m-section my-3'>
          <div className="pi-m-info">
            <div role="button" className="user-profile-container">
              {topProfileData ? (
                <img
                  className="user-profile-img"
                  src={(topProfileData && topProfileData.topProfile.profile_picture) || avatar["male-7.jpg"]}
                  alt="profile img"
                />
              ) : (
                <div className="react-loading">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fff"}
                  />
                </div>
              )}
              <img
                className='edit-icon'
                src={mbIcon["edit-btn.svg"]}
                alt="edit"
                onClick={this.toggleShowProfilePic}
              />
            </div>
            <div className="user-info">
              <p className="us-name">{t("profile.username")}</p>
              <p className="us-join">{this.props.currentUser.data.username}</p>
            </div>
          </div>

          <div className='pf-m-dashboard my-3'>
            <div className='input-group-col' onClick={this.toggleShowThirdPartyAuthorization}>
              <div className='input-group right'>
                <p className='form-control lh-lg'>{t("profile.third-party-acc-authorization")}</p>
                <span className="input-group-text">
                  <Icon icon="uil:angle-right" />
                </span>
              </div>

            </div>
          </div>

          {/* <form className='forms-col needs-validation my-3' noValidate> */}
          <form className="forms-col needs-validation my-3" method="post" name="basic_info_from" id="basic_info_from" onSubmit={this.props.postUpdateMemberProfile} noValidate>
            <div className='pd-form-m-col'>
              <div className='input-group-col'>
                <label className='col-form-label'>{t("profile.fullname")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    this.props.formFilter.set_full_name_status ? (
                      <p className='form-control lh-lg '>{this.props.formFilter.full_name}</p>
                    ) : (
                      <StringInput
                        id="full_name"
                        name="full_name"
                        style={{ width: "70%" }}
                        type="text"
                        placeholder={t("profile.fullname-remark")}
                        defaultValue=""
                        value={this.props.formFilter.full_name}
                        onChange={this.props.onFullNameInputChangeHandler}
                        className={`${this.props.errorMsg.full_name && "was-validated is-invalid"}`}
                        // minLength={this.props.contact_no_min_limits}
                        // maxLength={this.props.contact_no_max_limits}
                        // minLengthMessage={t("validation.minLength", {
                        //   0: this.props.contact_no_min_limits,
                        // })}
                        // maxLengthMessage={t("validation.maxLength", {
                        //   0: this.props.contact_no_max_limits,
                        // })}
                        required
                        requiredMessage={t("validation.required")}
                        errorMsg={this.props.errorMsg.full_name || ""}
                        formError={this.props.formError}
                      />
                      // <p className='form-control lh-lg '>{t("profile.fullname-remark")}</p>
                    )
                  )}
                </div>
              </div>

              <div className='input-group-col' onClick={(!this.props.formFilter.set_basic_info) ? this.toggleShowGender : null}>
                <label className='col-form-label'>{t("profile.gender")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    this.props.formFilter.gender ? (
                      <p className='form-control lh-lg '>{this.props.formFilter.gender === "M" ? `${t("profile.male")}` : `${t("profile.female")}`}</p>
                    ) : !this.props.formFilter.gender ? (
                      <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                    ) : (
                      <p className='form-control lh-lg '>{t("profile.choose-gender")}</p>
                    )
                  )}
                </div>
              </div>
            
              <div className="input-group-col">
                <label className="col-form-label">{t("profile.address")}</label>
                <div className="input-group right has-validation" onClick={this.toggleAddAddressHandler}>
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    <>
                      <p className='form-control lh-lg'>
                        {(this.props.formFilter.address && this.props.formFilter.address.full_address)
                        ?
                          this.props.formFilter.address.full_address
                                
                        :
                          '-'
                        }
                      </p>
                      <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                    </>
                  )}
                </div>
              </div>

              <div className='input-group-col' onClick={(!this.props.formFilter.set_basic_info) ? this.toggleShowDateSelect : null}>
                <label className='col-form-label'>{t("profile.date-of-birth")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    this.props.formFilter.dates ? (
                      <p className='form-control lh-lg'>{this.props.formFilter.loading ? null : moment(this.props.formFilter.dates).format("yyyy-MM-DD")} <br /> {this.props.formFilter.loading ? null : birthdayCountdown}</p>
                    ) : !this.props.formFilter.dates && (
                      <>
                        <p className='form-control lh-lg '>{moment().subtract(18, "years").format("yyyy-MM-DD")}</p>
                        <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                      </>
                    )
                  )}
                </div>
              </div>


              <div className='input-group-col' onClick={() => this.toggleShowVerification("verification-phone")}>
                <label className='col-form-label'>{t("profile.mobile-phone-no")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    <>
                      {
                        topProfileData && topProfileData.topProfile.contact_verified === 1 ? (
                          <p className='form-control lh-lg'>{this.props.formFilter.verified_contact_no}</p>
                        ) : (
                          <p className='form-control lh-lg'>{t("profile.unverified")}</p>
                        )
                      }
                      <div className='d-flex'>
                        {
                          topProfileData && topProfileData.topProfile.contact_verified === 1 &&
                          <div className='my-auto'>
                            <span className='note-text'>{t("profile.verified")}</span>
                          </div>
                        }
                        <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                      </div>
                    </>
                  )}
                </div>
              </div>


              <div className='input-group-col' onClick={() => this.toggleShowVerification("verification-email")}>
                <label className='col-form-label'>{t("profile.email-address")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    <>
                      {
                        topProfileData && topProfileData.topProfile.email_verified === 1 ? (
                          // <p className='form-control lh-lg note-text'>{t("profile.verified")}</p>
                          <p className='form-control lh-lg'>{this.props.formFilter.verified_email}</p>
                        ) : (
                          <p className='form-control lh-lg'>{t("profile.unverified")}</p>
                        )
                      }
                      <div className='d-flex'>
                        {
                          topProfileData && topProfileData.topProfile.email_verified === 1 &&
                          <div className='my-auto'>
                            <span className='note-text'>{t("profile.verified")}</span>
                          </div>

                        }
                        <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className='input-group-col' onClick={() => {
                if (topProfileData && (topProfileData.topProfile.google_id || topProfileData.topProfile.telegram_id)) {
                  this.props.socialMediaNoPasswordHandler();
                }
                else {
                  this.toggleShowVerification("verification-acc-pwd");
                }
              }}>
                <label className='col-form-label'>{t("profile.account-password")}</label>
                <div className="input-group right has-validation">
                  <p className='form-control lh-lg '>********</p>
                  <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                </div>
              </div>

              <div className='input-group-col' onClick={this.toggleShowAddSecurityPin}>
                <label className='col-form-label'>{t("profile.security-pin")}</label>
                <div className="input-group right has-validation">
                  {this.props.formFilter.loading ? (
                    <Skeleton count={1} width={895} height={"100%"} />
                  ) : (
                    !this.props.securityPinStatusClass ? (<><p className='form-control lh-lg '>{t("global.not-set")}</p><span className="input-group-text"><Icon icon="uil:angle-right" /></span></>) : (<><p className='form-control lh-lg '>{t("global.set")}</p><span className="input-group-text"><Icon icon="uil:angle-right" /></span></>)
                  )}
                </div>
              </div>

            </div>

            {!this.props.formFilter.set_basic_info && (
              <button
                className="btn btn-gradient-blue w-100"
                type="submit"
                value="Submit"
              >
                {t("profile.done")}
              </button>
            )}

          </form>

        </section>
      </Auxiliary>

    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    topProfileData: state.profile.top_profile_data
  }
}

const mapDispatchToProps = {
  getUserData,
  getTopProfile
}


export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobilePersonalInformation));