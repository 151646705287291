import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import MobileBetAndEarn from './MobileBetAndEarn';
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import { currency } from "../../../common/config/currency";
import { numFormat } from '../../../hoc/Shared/utility';

// Actions
import * as actions from "../../../store/ducks/events/actions";

export class BetAndEarn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusSettings: [],
      goldDiggerData: [],
      validGameTypes: [],
      summaryData: [],

      isClaimed: false,
      loading: true,
      login: localStorage.getItem("accessToken") ? true : false
    };
  }

  componentDidMount() {
    this.props.getData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.claimResult !== this.props.claimResult && nextProps.claimResult !== undefined) {
      if (nextProps.claimResult.responseCode === 200 && nextProps.claimResult.msgType === 'success') {
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "success",
          title: t("global.success"),
          text: nextProps.claimResult.message,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container success",
            icon: "swal-success-icon",
          } : []
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({
              bonusSettings: nextProps.rs.data.bonusSettings,
              goldDiggerData: nextProps.rs.data.goldDiggerData,
              validGameTypes: nextProps.rs.data.validGameTypes,
              summaryData: nextProps.rs.data.summaryData
            });

            window.setTimeout(function () { window.location.reload() }, 1000);
          }
        });;
      } else {
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "error",
          title: t("global.error"),
          text: nextProps.claimResult.message,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container error",
            icon: "swal-error-icon",
          } : []
        });
      }
    }

    if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
      if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') {
        this.setState({
          bonusSettings: nextProps.rs.data.bonusSettings,
          goldDiggerData: nextProps.rs.data.goldDiggerData,
          validGameTypes: nextProps.rs.data.validGameTypes,
          summaryData: nextProps.rs.data.summaryData,

          loading: false
        });
      }
    }

    return true;
  }

  BettingType = (props) => {
    const { t } = this.props;
    return (
      <div className='col-3'>
        <div className='bne-total-amount card-shadow'>
          <div className='total-amount-header'>
            <img src={props.icon} alt="Game Type" />
            <h3>{props.title}</h3>
          </div>
          <div className='total-amount-content p-3'>
            <div className='border-bottom pb-2'>
              <p className='text-center'>{t('bet_and_earn.this_week_jackpot')}</p>
              <p className='text-end'>{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]}
                {
                  props.summary && props.summary.map((item, index) => (
                    item.name === props.type && (
                      <span className='number'>
                        {item.total}
                      </span>
                    )
                  ))
                }
              </p>
            </div>
            <div className='pt-2'>
              <div className='receive-state'>
                <p>{t('bet_and_earn.claimed')}:</p>
                <p>{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]}
                  {
                    props.summary && props.summary.map((item, index) => (
                      item.name === props.type && (
                        <span className='number'>
                          {item.received}
                        </span>
                      )
                    ))
                  }
                </p>
              </div>
              <div className='receive-state'>
                <p>{t('bet_and_earn.unclaimed')}:</p>
                <p>{this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]}
                  {
                    props.summary && props.summary.map((item, index) => (
                      item.name === props.type && (
                        <span className='number'>
                          {item.unreceived}
                        </span>
                      )
                    ))
                  }
                </p>
              </div>
            </div>
            {/* <button className='btn btn-gradient-blue'>登入看奖金</button> */}
            {
              props.summary && props.summary.map((item, index) => (
                item.name === props.type && (
                  <button
                    className='btn btn-gradient-blue'
                    disabled={(item.claimAllData && item.claimAllData.length > 0) || item.claimAllDisabled === false ? false : true}
                    onClick={localStorage.getItem("accessToken") ? (e) => this.handleClaim(e, item.claimAllData, props.type) : (e) => this.redirectLogin(e)}
                  >
                    {item.claimAll}
                  </button>
                )
              ))
            }

            <p className='total-amount-mention'>
              {t('bet_and_earn.next_tier_1')} {this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]}
              {
                props.summary && props.summary.map((item, index) => (
                  item.name === props.type && (
                    <span> {item.nextBonus} </span>
                  )
                ))
              }
              {t('bet_and_earn.next_tier_2')}
              <br />
              {t('bet_and_earn.next_tier_3')} {this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]}
              {
                props.summary && props.summary.map((item, index) => (
                  item.name === props.type && (
                    <span> {item.nextBet}</span>
                  )
                ))
              }
            </p>
          </div>
        </div>

        <div className='events-table-container card-shadow'>
          <table className="table table-borderless events-table">
            <thead>
              <tr>
                <th>{t('bet_and_earn.valid_bet')}</th>
                <th>{t('bet_and_earn.bonus')}</th>
              </tr>
            </thead>
            <tbody>
              {
                props.data && props.data.map((item, index) => (
                  item.name === props.type && item.list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.amount}</td>
                      <td>
                        <button
                          className={`btn ${item.status === "unavailable" ? 'btn-outline-blue' : 'btn-gradient-blue'}`}
                          disabled={item.status === "available" ? false : true}
                          onClick={(e) => this.handleClaim(e, [item.amount], props.type)}
                        >
                          {item.status === "received" ? t('bet_and_earn.claimed') : item.reward}
                        </button>
                      </td>
                    </tr>
                  ))
                ))
              }
              {/* <tr>
              <td>6,000</td>
              <td>
                <button className='btn btn-gradient-blue' disabled>已领取</button>
              </td>
            </tr>
            <tr>
              <td>50,000</td>
              <td>
                <button className='btn btn-gradient-blue'>18</button>
              </td>
            </tr>
            <tr>
              <td>100,000</td>
              <td>
                <button className='btn btn-outline-blue'>28</button>
              </td>
            </tr>
            <tr>
              <td>10,000,000</td>
              <td>
                <button className='btn btn-outline-blue'>1,888</button>
              </td>
            </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  redirectLogin = (e) => {
    window.location.href = '/login';
  }

  handleClaim = (e, validBet, gameType) => {
    const { t } = this.props;

    if (localStorage.getItem("accessToken")) {
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
        title: t("global.confirmation"),
        text: t("global.would-you-like-to-proceed") + "?",
        icon: "warning",
        confirmButtonText: t("global.ok"),
        showDenyButton: true,
        denyButtonText: t("global.cancel"),
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container confirmation",
          icon: "swal-confirmation-icon",
        } : [],
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });

          const data = {
            valid_bet: validBet,
            game_type: gameType,
            member_id: this.props.currentUser.id,
            country_id: this.props.currentUser.country_id,
          }

          this.props.claimBonus(data);

          this.setState({
            isClaimed: true
          });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));
    const icon = this.imageImport(require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));

    let data = [];
    let summary = [];
    let gameTypeData = [];
    if (this.state.validGameTypes.length !== 0) {
      summary = this.state.validGameTypes.map((val) => {
        let totalBonus = "0.00";
        let totalUnreceived = "0.00";
        let totalReceived = "0.00";
        let nextBet = "0.00";
        let nextBonus = "0.00";
        let claimAllData = [];
        let claimAllDisabled = false;
        let claimAllBtn = t('bet_and_earn.login_to_view');

        if (localStorage.getItem("accessToken")) {
          if (this.state.summaryData[val] !== undefined) {
            totalBonus = this.state.summaryData[val]['totalBonus'];
            totalUnreceived = this.state.summaryData[val]['totalUnreceived'];
            totalReceived = this.state.summaryData[val]['totalReceived'];
            nextBet = this.state.summaryData[val]['nextBet'];
            nextBonus = this.state.summaryData[val]['nextBonus'];
            claimAllData = this.state.summaryData[val]['availableClaim'];
          }

          claimAllBtn = totalUnreceived > 0 ? t('bet_and_earn.one_click_receive') : t('bet_and_earn.not_yet_reached');

          if (claimAllData && claimAllData.length > 0) {
            claimAllDisabled = false;
          } else {
            claimAllDisabled = true;
          }
        }

        return {
          name: val,
          total: new Intl.NumberFormat("en-US", numFormat(currency[this.props.currentUser.country_id])).format(totalBonus),
          received: new Intl.NumberFormat("en-US", numFormat(currency[this.props.currentUser.country_id])).format(totalReceived),
          unreceived: new Intl.NumberFormat("en-US", numFormat(currency[this.props.currentUser.country_id])).format(totalUnreceived),
          claimAll: claimAllBtn,
          claimAllData: claimAllData,
          claimAllDisabled: claimAllDisabled,
          nextBonus: nextBonus,
          nextBet: nextBet
        }
      });

      data = this.state.validGameTypes.map((val) => {
        let list = [];
        this.state.bonusSettings[val].map((innerVal) => {

          let status = 'unavailable';
          if (localStorage.getItem("accessToken")) {
            if (this.state.goldDiggerData[val]) {
              if (this.state.goldDiggerData[val].claimed) {
                if (this.state.goldDiggerData[val].claimed.includes(innerVal.valid_bet)) {
                  status = 'received';
                }
              }

              if (this.state.goldDiggerData[val].unclaimed) {
                if (this.state.goldDiggerData[val].unclaimed.includes(innerVal.valid_bet)) {
                  status = 'available';
                }
              }
            }
          }

          list.push({
            amount: innerVal.valid_bet,
            reward: innerVal.amount,
            status: status
          });

          return null;
        });

        return {
          name: val,
          list: list
        };
      });

      gameTypeData = this.state.validGameTypes.map((val) => {
        let icon = "";
        let title = "";
        switch (val) {
          case 'sp':
            icon = "sports-icon.png";
            title = t('game-type.' + val);
            break;

          case 'ca':
            icon = "lc-icon.png";
            title = t('game-type.' + val);
            break;

          case 'sl':
            icon = "slot-icon.png";
            title = t('game-type.' + val);
            break;

          case 'ga':
            icon = "game-icon.png";
            title = t('game-type.' + val);
            break;
          default:
            break;
        }

        return {
          gameType: val,
          icon: icon,
          title: title
        }
      });
    }

    let imageLanguage = '';
    let tncImageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        tncImageLanguage = 'bet-tnc-cn.svg'
        break;
        case "ms":
          imageLanguage = "-ms";
          tncImageLanguage = 'term-condition-bm.svg'
          break;
        case "id":
          imageLanguage = "-indon";
          tncImageLanguage = 'term-condition-id.svg'
          break;
        default:
          imageLanguage = ""
          tncImageLanguage = 'term-condition.svg'
          break;
    }

    return (
      <Auxiliary>
        {
          this.state.loading && <LoadingPage />
        }
        {
          isDesktop ? (
            <section className='bne-section'>
              <img className="w-100" src={banner[`bet-and-earn-banner${imageLanguage}.webp`]} alt="banner" />

              <div className='x-container'>
                <div className='section-header-gray'>
                  <h2>{t('bet_and_earn.title_1')}</h2>
                  <p>{t('bet_and_earn.title_2')}</p>
                  {/*<p className='event-date'>{t('bet_and_earn.time_1')}：
                    <span>{t('bet_and_earn.time_2')}</span>
                    <span className='px-2'>{t('bet_and_earn.time_3')}</span>
                    <span>{t('bet_and_earn.time_4')}</span>
                  </p>*/}
                </div>
                <div className='row'>
                  {
                    gameTypeData && gameTypeData.map((val) => (
                      <this.BettingType
                        icon={icon[val.icon]}
                        title={val.title}
                        type={val.gameType}
                        summary={summary}
                        data={data}
                      />
                    ))
                  }
                </div>

                <div className='term-section mt-5'>
                  <div className='term-container card-shadow'>
                    <img src={tNc[`${tncImageLanguage}`]} alt="Terms Condition" />
                    <div className='term-content'>
                      <h4 className='mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
                      <ol>
                        <li>{t('bet_and_earn.rule_1', { attribute: process.env.REACT_APP_WEBSITE_TITLE }).replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</li>
                        <li>{t('bet_and_earn.rule_2')}</li>
                        <li>{t('bet_and_earn.rule_3')}</li>
                        <li>{t('bet_and_earn.rule_4')}</li>
                        <li>{t('bet_and_earn.rule_5')}</li>
                        <li>{t('bet_and_earn.rule_6', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                      </ol>
                    </div>
                    <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <MobileBetAndEarn
              data={data}
              summary={summary}
              gameTypeData={gameTypeData}
              handleClaim={this.handleClaim}
              imageLanguage={imageLanguage}
              currency={currency}
              currentUser={this.props.currentUser}
              login={this.state.login}
              redirectLogin={this.redirectLogin}
            />
          )
        }

      </Auxiliary>

    )
  }
}

const mapStateToProps = state => {
  return {
    rs: state.betAndEarn.rs,
    claimResult: state.betAndEarn.claim,
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (filter) => dispatch(actions.getData(filter)),
    claimBonus: (data) => dispatch(actions.claim(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BetAndEarn));
