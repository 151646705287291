import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const postRegister = (res_data) => dispatch => {
    axios.post('register', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.POST_REGISTER,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.POST_REGISTER,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const checkUsername = (res_data) => dispatch => {
    axios.post('check_username', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.CHECK_USERNAME,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.CHECK_USERNAME,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const checkReferralRegion = (res_data) => dispatch => {
    axios.post('check_referral_region', res_data)
    .then((response) => {
        dispatch({
            type: type.CHECK_REFERRAL_REGION,
            payload: response.data
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};

export const checkIpRegion = (data) => dispatch => {
    axios.get('check_ip_region', data)
    .then((res) => {
        dispatch({
            type: type.CHECK_IP_REGION,
            payload: res.data
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};