import React, {Component, Fragment} from 'react';
import {required, validateDisplay, isEmail, minLength} from "../../../hoc/Shared/utility";
// import Button from "../../Button/Button";
import PropTypes from 'prop-types';

class StringInput extends Component {
    constructor (props) {
        super (props);

        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage),
            isEmailRules: isEmail(false, this.props.value, this.props.isEmailMessage),
            minLengthRules: minLength(false, this.props.value, this.props.minLength, this.props.minLengthMessage),
            maxLengthRules: minLength(false, this.props.value, this.props.maxLength, this.props.maxLengthMessage)
        };
    }

    // shouldComponentUpdate (nextProps, nextState, nextContext) {
    //     return nextProps.value !== this.props.value;
    // }


    static getDerivedStateFromProps (nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ){
            let payload = {
                value: nextProps.value,
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if(nextProps.required){
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            if(nextProps.valid_email){
                payload['isEmailRules'] = isEmail(true, nextProps.value, nextProps.isEmailMessage);
            }

            if(nextProps.minLength){
                payload['minLengthRules'] = minLength(true, nextProps.value, nextProps.minLength, nextProps.minLengthMessage);
            }

            if(nextProps.maxLength){
                payload['maxLengthRules'] = minLength(true, nextProps.value, nextProps.maxLength, nextProps.maxLengthMessage);
            }

            return payload;
        }

        return null;
    }

    render() {

        const {id, name, onChange, className, style, defaultValue, disabled, required, placeholder, minLength, maxLength, onBlur, autoComplete, /*iconName, onSearchChange,*/ readOnly, onSearchPress, valid_email, formError, errorMsg} = this.props;

        const {value, requiredRules, isEmailRules, minLengthRules, maxLengthRules } = this.state;

        //Export the validation classes and message
        const [ requiredClass ] = validateDisplay(requiredRules);
        const [ isEmailClass ] = validateDisplay(isEmailRules);
        const [ minLengthClass ] = validateDisplay(minLengthRules);
        const [ maxLengthClass ] = validateDisplay(maxLengthRules);

        let inputType = "text";

        if(isEmailClass === "is-invalid"){
            inputType = "email";
        }

        return (
            <Fragment>
                <input
                    type={inputType}
                    id={id}
                    value={value}
                    className={`form-control ${requiredClass} ${isEmailClass} ${minLengthClass} ${className}`}
                    style={style}
                    name={name}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                    onKeyPress={onSearchPress}
                    valid_email={valid_email}
                />
                
                {/*Display the message*/}
                <div className="invalid-feedback">
                    {
                    (requiredClass !== 'is-valid' && this.props.requiredMessage)
                        || (requiredClass === "is-valid" && isEmailClass === "is-invalid" && this.props.emailMessage)
                        || (requiredClass === "is-valid" && minLengthClass === "is-invalid" && this.props.minLengthMessage) 
                        || (requiredClass === "is-valid" && maxLengthClass === "is-invalid" && this.props.maxLengthMessage) 
                        || (formError && errorMsg)
                    }
                </div>

            </Fragment>
        )
    }
}

export default StringInput;

StringInput.propTypes = {
    id:         PropTypes.string.isRequired,
    name:       PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    defaultValue:   PropTypes.string,
    value:          PropTypes.string,
    className:      PropTypes.string,
    disabled:       PropTypes.bool,
    required:       PropTypes.bool,
    placeholder:    PropTypes.string,
    minLength:      PropTypes.number,
    maxLength:      PropTypes.number,
    onBlur:         PropTypes.func,
    valid_email:    PropTypes.string,
    isEmailMessage:  PropTypes.string,
    requiredMessage: PropTypes.string,
    minLengthMessage: PropTypes.string,
    maxLengthMessage: PropTypes.string,
    autoComplete:   PropTypes.string,
    iconName: PropTypes.any,
    onSearchPress: PropTypes.func,
    emailMessage: PropTypes.string,
    formError: PropTypes.bool,
    errorMsg: PropTypes.string,
};

StringInput.defaultProps = {
    className: ''
};