import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getGameVendorList = (props) => (dispatch, getState) =>
{
    props.country_id = getState().currentUser.data.country_id;
    axios.get('general/game_vendor_list', {params: props})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.GENERAL_VENDOR_LIST,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const getFooter = (props) => dispatch =>
{
    axios.get('general/footer', {params: props})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.FOOTER,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};