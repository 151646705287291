import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Layout from "./containers/Layout/Layout";
// import MaintenancePageMobile from "./containers/Miscellaneous/MaintenancePageMobile";
import 'bootstrap';
import './assets/sass/app.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';

import OneSignal from 'react-onesignal';
import { useEffect } from 'react';

// import "bootstrap/dist/js/bootstrap.min.js";


function App() {

    useEffect(() => {
        OneSignal.init({
          appId: (process.env.REACT_APP_ENV === "staging") ? process.env.REACT_APP_ONE_SIGNAL_KEY : process.env.REACT_APP_ONE_SIGNAL_LIVE_KEY,
           promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage: "Subscribe to our notifications for the latest news and updates. You can disable anytime.",
              acceptButtonText: "Subscribe",
              cancelButtonText: '',
          } 
        }
        });
    }, []);

  return (
      // <MaintenancePageMobile/>
      <Router>
        <Switch>
          <Route path="/" name="Home" render={props => <Layout {...props}/>}/>
        </Switch>
      </Router>
  );
}

export default App;
