import React, { Component } from 'react';

//Miscellaneous
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { isDesktop } from 'react-device-detect';
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';

export class PrivacyPolicy extends Component {

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context("../../assets/images/promos", false, /\.(png|jpe?g|svg|webp)$/)
    );
    const tnc = this.imageImport(
      require.context("../../assets/images/tnc", false, /\.(png|jpe?g|svg|webp)$/)
    );
    const mobileTnc = this.imageImport(
      require.context("../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg|webp)$/)
    );

    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      case "ms":
        imageLanguage="-bm" 
        break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        {isDesktop ? (
          <>
            <section className="term-section">
              <div className="x-container">
                <div className="term-container card-shadow">
                  <img src={img[`privacy-policy-title${imageLanguage}.webp`]} alt="Privacy Policy" />
                  <div className="term-content policy-content">
                    <ol>
                      <li>
                        {t("privacy-policy.pp-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                      </li>
                      <li>
                        {t("privacy-policy.pp-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                      </li>
                    </ol>
                    <p>{t("privacy-policy.pp-rules")}</p>

                    <h4>{t("privacy-policy.pp-intro")}</h4>
                    <p>
                      {t("privacy-policy.pp-intro-content-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>
                    <p>
                      {t("privacy-policy.pp-intro-content-2")}
                    </p>
                    <p>
                      {t("privacy-policy.pp-intro-content-3")}
                    </p>

                    <h4>{t("privacy-policy.pp-purpose")}</h4>
                    <p>
                      {t("privacy-policy.pp-purpose-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>

                    <h4>{t("privacy-policy.pp-acc-info")}</h4>
                    <p>
                      {t("privacy-policy.pp-acc-info-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>

                    <h4>{t("privacy-policy.pp-confiden")}</h4>
                    <p>
                      {t("privacy-policy.pp-confiden-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>

                    <h4>{t("privacy-policy.pp-cus-info")}</h4>
                    <p>
                      {t("privacy-policy.pp-cus-info-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>

                    <h4>{t("privacy-policy.pp-monitor")}</h4>
                    <p>
                      {t("privacy-policy.pp-monitor-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </p>
                  </div>
                  <img src={tnc["term-footer.png"]} alt="Terms Footer" />
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="term-section mt-0">
            <MobileSubHeader
              returnPage="/"
              className="bg-white"
              header={t("footer.privacy-policy")}
              style={{ justifyContent: "unset" }}
            />
            <div className="x-m-container">
              <div className="term-container m-3 card-shadow">
                <img img src={mobileTnc[`term-condition${imageLanguage}.svg`]} alt="Privacy Policy" />
                <div className="term-content policy-content mobile">
                  <ol>
                    <li>
                      {t("privacy-policy.pp-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                    </li>
                    <li>
                      {t("privacy-policy.pp-li-2")}
                    </li>
                    <li>
                      {t("privacy-policy.pp-li-2")}
                    </li>
                  </ol>
                  <p>{t("privacy-policy.pp-rules")}</p>

                  <h4>{t("privacy-policy.pp-intro")}</h4>
                  <p>
                    {t("privacy-policy.pp-intro-content-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>
                  <p>
                    {t("privacy-policy.pp-intro-content-2")}
                  </p>
                  <p>
                    {t("privacy-policy.pp-intro-content-3")}
                  </p>

                  <h4>{t("privacy-policy.pp-purpose")}</h4>
                  <p>
                    {t("privacy-policy.pp-purpose-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>

                  <h4>{t("privacy-policy.pp-acc-info")}</h4>
                  <p>
                    {t("privacy-policy.pp-acc-info-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>

                  <h4>{t("privacy-policy.pp-confiden")}</h4>
                  <p>
                    {t("privacy-policy.pp-confiden-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>

                  <h4>{t("privacy-policy.pp-cus-info")}</h4>
                  <p>
                    {t("privacy-policy.pp-cus-info-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>

                  <h4>{t("privacy-policy.pp-monitor")}</h4>
                  <p>
                    {t("privacy-policy.pp-monitor-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>
                </div>
                <img src={mobileTnc["term-footer-m.jpg"]} alt="Terms Footer" />
              </div>
            </div>
          </section>
        )
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
};

export default connect(mapStateToProps)(withNamespaces("translation")(PrivacyPolicy));