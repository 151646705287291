import { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import slugConf from "../../../common/data/games.json";
import { withNamespaces } from "react-i18next";

class SlotHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorList: [],
            loading: true
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
            if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === 'success') {
                let slotGameList = [];
                let vendorList = nextProps.vendor.data.vendor_list;
                if (vendorList.length > 0) {
                    vendorList.map((val, idx) => {
                        if (val.game_type_slug === 'sl') {
                            slotGameList.push(val);
                        }
                        return null;
                    });
                }
                this.setState({
                    vendorList: slotGameList,
                    loading: false
                });
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let imgDropdown = this.props.imgDropdown;
        let slots = this.state.vendorList ? this.state.vendorList : [];
        return (
            <>
                {(!this.state.loading && slots) &&
                    slots.slice(0, 11).map((item, index) => {
                        if (item.maintenance_status === 0) {
                            let link = ((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || '');
                            return (
                                <Link
                                    to={{ pathname: `/slot/${link}`, state: { vendorSlug: item.game_vendor_slug, vendorId: item.game_vendor_id } }}
                                    className="col-2"
                                    key={index}
                                >
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <img className="dropdown-img" src={imgDropdown[`hm-${item.game_vendor_slug}-img.webp`]} alt="Dropdown" />
                                </Link>
                            );
                        }
                        else {
                            return (
                                <div className="col-2 under-maintenance">
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <img className="dropdown-img" src={imgDropdown[`hm-${item.game_vendor_slug}-img.webp`]} alt="Dropdown" />
                                </div>
                            );
                        }
                    })}
                {
                    slots.length >= 11 && (
                        <div className="col-2">
                            <NavLink className="btn more-btn-blue" to={{pathname:"/slot"}}>{t("page-header.more-slots")}</NavLink>
                        </div>
                    )
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vendor: state.general.vendor
    }
}

export default connect(mapStateToProps)(withNamespaces("translation")(SlotHeader));