import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {};

const settingReducer = createReducer(initialState) ({
    [types.SYSTEM_SETTING]: (state, action) => ({
        ...state,
        system_setting: action.payload  
    })
});

export default settingReducer;