import React, { Component } from 'react'
import { connect } from "react-redux";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SideBar from '../../components/SideBar/SideBar';
import { withNamespaces } from "react-i18next";
export class MobileCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  //certificate content component START
  certificateContent = (props) => {
    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //   imageLanguage="-ms" 
      //   break;
      default:
        imageLanguage = ""
        break;
    }
    return (
      <div className="position-relative p-3">
        <span className="card-shadow">
          <img className="w-100 h-auto" src={props.img[`license-bg-mobile${imageLanguage}.webp`]} alt="license background" />
        </span>
        <div className="cert-img">
          <LazyLoadImage src={props.img[props.cert]} alt="cert" key={props.img[props.cert]} effect="blur" />
        </div>
        <div className="cert-content-container">
          {props.header}
          {props.content}
        </div>
      </div>
    );
  };
  //certificate content component END

  updateActiveCert = (num) => {

    this.setState({
      activePage: num,
      triggerAnimation: true,
    });

  };


  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    // const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/certificate",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>

        <section className='certificate-m-section'>
          <SideBar />
          <div className="cert-page-btn mt-3">
            <ul>
              <li
                className={`cert-btn ${this.state.activePage === 1 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(1)}
              >
                PAGCOG
              </li>
              <li
                className={`cert-btn ${this.state.activePage === 2 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(2)}
              >
                Isle of Man Government
              </li>
              <li
                className={`cert-btn ${this.state.activePage === 3 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(3)}
              >
                First Cagayan
              </li>
            </ul>
          </div>

          {this.state.activePage === 1 ? (
            <this.certificateContent
              img={img}
              cert={"pagcor-cert.webp"}
              header={
                <h1>
                  PAGCOR <br /> {t("gaming-license.gaming-license")}
                </h1>
              }
              content={
                <p>
                  {t("gaming-license.pagcor-content-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  <br />
                  <br />
                  {t("gaming-license.pagcor-content-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  <br />
                  <br />
                  {t("gaming-license.pagcor-content-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  <br />
                  <br />
                  {t("gaming-license.pagcor-content-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  <br />
                  <br />
                  {t("gaming-license.pagcor-content-5", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  <br />
                  <br />
                </p>
              }
            />
          ) : this.state.activePage === 2 ? (
            <this.certificateContent
              img={img}
              cert={"isle-cert.webp"}
              header={
                <h1>
                  Isle of Man Government <br /> Gaming License
                </h1>
              }
              content={
                <p>
                  {t("gaming-license.iomg-content-1")}
                  <br />
                  <br />
                  {t("gaming-license.iomg-content-2")}
                  <br />
                  <br />
                  {t("gaming-license.iomg-content-3")}
                  <br />
                  <br />
                  {t("gaming-license.iomg-content-4")}
                  <br />
                  <br />
                  {t("gaming-license.iomg-content-5")}
                  <br />
                  <br />
                </p>
              }
            />
          ) : this.state.activePage === 3 ? (
            <this.certificateContent
              img={img}
              cert={"cagayan-cert.webp"}
              header={
                <h1>
                  First Cagayan <br /> Gaming License
                </h1>
              }
              content={
                <p>
                  {t("gaming-license.fc-content-1")}
                  <br />
                  <br />
                  {t("gaming-license.fc-content-2")}
                  <br />
                  <br />
                  {t("gaming-license.fc-content-3")}
                  <br />
                  <br />
                  {t("gaming-license.fc-content-4")}
                  <br />
                  <br />
                  {t("gaming-license.fc-content-5")}
                </p>
              }
            />
          ) : (
            <div></div>
          )}
        </section>
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
};

export default connect(mapStateToProps)(withNamespaces("translation")(MobileCertificate));