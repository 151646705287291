import * as type from './types';
import axios from '../../../common/config/axios';

export const list = () => (dispatch, getState) => {
	let data = {};
	data.country_id = getState().currentUser.data.country_id; 
	axios.get('leaderboard/list', {params: data})
		.then(function (response) {
			dispatch ({
				type: type.LEADERBOARD_LIST,
				payload: response.data
			})
		})
		.catch(err => {});
};

export const showWinner = (data) => dispatch => {
	axios.post('leaderboard/winner', data)
		.then(function (response) {
			dispatch ({
				type: type.SHOW_WINNER,
				payload: response
			})
		}).catch(err => {});
};
