import React, { Component } from "react";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Link } from "react-router-dom";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import Pagination from "../../../components/Pagination/Pagination";
// import NoRecords from "../../../components/NoRecords/NoRecords";

//Icons
import { Icon } from "@iconify/react";

const feedbackList = [
  { value: "1", title: "Deposit Problem", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod", date: "2021 -12-05", read: false },
  { value: "2", title: "Deposit Problem", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod", date: "2021 -12-05", read: true },
]

class FeedbackList extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files })
    };
    this.state = {
      type: '',
      wordsCount: '0',
      files: []
    };
  }

  componentDidMount() { }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {

    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    return (
      <Auxiliary>
        <section className="pf-dashboard">
          <div className="x-container">
            <ProfileSideBar />
            <div className="row g-3">
              <div className="col-12">
                <div className="pd-card-col d-flex flex-column h-100">
                  <h4>
                    <Link className="btn icon me-2" to={{ pathname: "/feedback" }}>
                      <Icon icon="uil:angle-left-b" />
                    </Link>
                    My Feedback
                  </h4>
                  <div className="msg-list-col">
                    <div className="msg-list">
                      <ul className="list-group">
                        {feedbackList &&
                          feedbackList.map((item, index) => (
                            <a className="list-group-item" key={index} href="/feedback/list/inner-feedback">
                              <div className="msg-icon-col">
                                {
                                  !item.read &&
                                  <span className="notify-red-dot" />
                                }
                                <img src={icons['feedback.svg']} alt={item.icon} />
                              </div>
                              <h6>【<span>{item.title}</span>】</h6>
                              <p>{item.content}</p>
                              <span>{item.date}</span>
                            </a>
                          ))}
                      </ul>
                    </div>
                    <Pagination />
                    {/* <NoRecords component="feedback"/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default FeedbackList;
