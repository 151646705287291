import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = () => (dispatch, getState) => 
{
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    data.member_id = (getState().currentUser.data.id || '');
    axios.get('lucky_spin/get_list', {params: data})
    .then(function (response) 
    {
        dispatch ({
            type: actionType.INDEX,
            value: response.data
        })
    }).catch(err => 
    {
        console.log(err);
    });
};

// export const getPrizeGroup = () => (dispatch, getState) => 
// {
//     let data = {};
//     data.country_id = getState().currentUser.data.country_id;
//     axios.get('lucky_spin/get_prize_group', {params: data})
//     .then(function (response) 
//     {
//         dispatch ({
//             type: actionType.PRIZE_GROUP,
//             value: response.data
//         })
//     }).catch(err => 
//     {
//         console.log(err);
//     });
// };

export const store = () => (dispatch, getState) => 
{
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    
    axios.post('lucky_spin/store', {params: data})
    .then(function (response) 
    {
        dispatch ({
            type: actionType.STORE,
            value: response.data
        })
    }).catch(err => 
    {
        console.log(err);
    });
};