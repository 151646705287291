import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('history/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const cancelWithdraw = (data) => dispatch => 
{
    axios.post('history/cancel', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.CANCEL,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const applyPromo = (data) => dispatch => 
{
    axios.post('history/apply_promo', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.APPLY_PROMO,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getLatest = data => dispatch => {
  axios.get('history/latest', {params: data})
  .then(function(response){
    dispatch({
      type: actionType.LATEST,
      value: response.data
    });
  })
  .catch(err=>{

  });
};

export const updatePrize = (data) => dispatch => 
{
    axios.post('history/update_prize', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_PRIZE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getGameList = (props) => (dispatch, getState) => 
{
    props.language = getState().currentUser.data.language_code;
    axios.get('history/game_list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.GAME_LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};