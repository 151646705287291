import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getDropDown = (leagueId, yearMth) => (dispatch, getState) =>
{
    let data = {};
    data.year_month = yearMth ? yearMth : '';
    data.league_id = leagueId;
    data.member_id = (localStorage.getItem("accessToken")) ? getState().currentUser.data.id : 0;
    data.country_id = (getState().currentUser.data.country_id || 1);
    // console.log(getState().currentUser.data);
    axios.get('world_cup/drop_down', {params: data})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.DROPDOWN,
            value: response.data
        });
    })
    .catch(err => 
    {
    
    });
};

export const store = (data) => dispatch =>
{
    axios.post('world_cup/store', data)
    .then(function (response) 
    {

        dispatch (
        {
            type: actionType.STORE,
            value: response.data
        });
    })
    .catch(err => 
    {
        
    });
};