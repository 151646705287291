import React, { Component, createRef } from 'react';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import SideBar from '../../components/SideBar/SideBar';
import MobileSelectRadio from '../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio';
import Timer from "react-compound-timer";

export class MobileFifaQna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRadio: false,
      showFifaRadio: true,
    };
    this.topSection = createRef();

  }

  scrollToTop = () => {
    this.topSection.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  toggleSelectRadio = () => {
      this.setState({
          showSelectRadio: !this.state.showSelectRadio,
      });
  };

  handleChange = (event) => {
      this.setState({
          optionSelected: event
      });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const image = this.imageImport(require.context("../../assets/images/world-cup", false, /\.(png|jpe?g|svg)$/));
    // const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //   imageLanguage = "-ms"
      //   break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        <SideBar />
        <div className="banner-col">
          <div className="banner-item">
            <img src={banner[`world-cup-two-banner${imageLanguage}.webp`]} alt="fifa banner" />
          </div>
        </div>

        <section className='wc-section' ref={this.topSection}>

          <div className='section-header-gray mt-3'>
            <h2>{t('qna.title')}</h2>
            <p>{t('qna.title-1')}</p>
          </div>

          {this.props.endMatch ?
            <div className='fifa-two-body'>
                {/* <img src={image["notice.jpg"]} alt="notice" /> */}
                <p className='mb-3'><Icon icon="material-symbols:info" />{t('qna.event-ended')}</p>
                <p className='mt-2'>{t('qna.ady-upload')+ this.props.totalSubmission +t('qna.click-to')}<span style={{ color: "blue" }} onClick={this.props.toggleFifaModal}>{t('qna.check-result')}</span></p>
            </div>
            :
            <div className='fifa-two-body'>
              <h5>{t('qna.time').replace('##timeLimit##',this.props.timeLimit.time).replace('##timeUnit##',t('qna.'+this.props.timeLimit.unit))}</h5>
              {this.props.startQuiz ?
                <>
                  <p className='result-text mt-2'>{t('qna.countdown')}
                      <span className='orange-text'>
                      <Timer 
                          initialTime={this.props.getCountdownTime()}
                          direction="backward"
                          formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                      >
                          <Timer.Minutes />{t('world-cup.m')} <Timer.Seconds />{t('world-cup.s')}
                      </Timer>
                      </span>
                  </p>
                  <div className='vip-lvl-col'>
                    <div className="vip-lvl-md">
                      <ul>
                        {this.props.questions &&
                          this.props.questions.map((item, index) => (
                            <li
                              key={item.id}
                            >
                              <span>{item.id}</span>
                            </li>
                          ))}
                      </ul>
                      <div className="vip-lvl-progress-bar" style={{ width: `calc(10% * ${this.props.activeQuestions})` }} />
                    </div>
                  </div>
                  <div>
                    {this.props.questions &&
                      this.props.questions.map((item, index) => (
                        <div key={item.id}>
                          {this.props.activeQuestions === item.id &&
                            <>
                              <h4>{item.questions}</h4>
                              <p className='mb-2'>{item.sub}</p>
                              <form method="post" id="qna_form" className="forms-col needs-validation my-3" >
                                <div>

                                  <div className="input-group-col">
                                    <div className="input-group right has-validation" onClick={this.toggleSelectRadio}>
                                      <p className="form-control lh-lg">
                                      {!!(this.props.selecetedAnswerDisplay) ? this.props.selecetedAnswerDisplay : t('qna.choose-ur-ans')}
                                      </p>
                                      {/* <div className="form-control lh-lg d-flex align-item-center">
                                        <img src={flag["sen.svg"]} alt="flag" />
                                        <span className='ms-3'>SEN</span>
                                      </div> */}
                                      <span className="input-group-text">
                                        <Icon icon="uil:angle-down" />
                                      </span>
                                    </div>
                                    <MobileSelectRadio
                                        name={"fifa-select"}
                                        className="btn-check"
                                        showSelectRadio={this.state.showSelectRadio}
                                        toggleHandler={this.toggleSelectRadio}
                                        onChange={this.props.handleChange}
                                        options={this.props.options}
                                        maxSelect={item.multi}
                                    />
                                  </div>
                                </div>
                              </form>
                            </>
                          }
                        </div>
                      ))}
                    {this.props.activeQuestions > 1 && <button type="submit" className='btn-outline-white mb-5 next ' onClick={this.props.prevBtn}>{t('qna.prev-question')}</button>}
                    {this.props.activeQuestions < this.props.totalQuestion && <button type="submit" className='btn-outline-white mb-5 next ' onClick={this.props.nextBtn}>{t('qna.next-question')}</button>}
                  </div>
                </>
                :
                ""
              }
              {this.props.startQuiz ?
                  this.props.activeQuestions < this.props.totalQuestion ? <button type="submit" className="btn-gradient-blue mt-2 w-100" disabled >{t('global.submit')}</button> :
                    <button type="submit" onClick={this.props.submitBtn} className="btn-gradient-blue mt-2 w-100" >{t('global.submit')}</button>
                :
                <button type="submit" className='btn-gradient-blue mt-2 w-100' onClick={this.props.startQuizBtn}>{t('qna.start')}</button>
              }
              <p className='mt-2'>{t('qna.ady-upload')+ this.props.totalSubmission +t('qna.click-to')}<span style={{ color: "blue" }} onClick={this.props.toggleFifaModal}>{t('qna.check-result')}</span></p>
            </div>
          }
          <div className='section-header-gray mt-5 mb-3'>
            {this.props.endMatch ?
              <p className='result-text mt-2'>{t('qna.congrats')}<span className='blue-text'>2</span>{t('qna.get-prize')}<span className='orange-text'>{this.props.currency[this.props.currentUser.country_id]}16</span> </p>
              :
              <>
                <h2>{t('qna.unlimited-prize')}</h2>
                <p>{t('qna.redeem-prize')}</p>
              </>
            }
          </div>
          <div className=' wc-reward row gap-3 mb-5'>
            {
              this.props.reward && this.props.reward.map((item, index) => (
                <div className={`wc-reward-container col-3 ${item.status}`} key={index}>
                  <div className='wc-reward-header'>
                    <p className='m-0'>{item.value}</p>
                  </div>
                  <div className='wc-reward-body'>
                    <img src={image[`${item.img}.png`]} alt="reward-1" />
                    <p>{item.label}</p>
                  </div>
                </div>
              ))
            }
          </div>

        </section>

        <div className='term-section no-bg'>
          {/* <h4 className='tnc-title'>世界杯竞猜 TNC</h4> */}
          <div className='term-container  no-bg'>
            <p className='mb-2'>{t('qna.total-prize')}:</p>
            <table className='table'>
              <thead>
                <tr>
                  <th>{t('qna.correct-1-4')}</th>
                  <th>{t('qna.correct-5-9')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('qna.correct-ans-1')}{t('qna.get')}{t('qna.prize-1').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                  <td>{t('qna.correct-ans-5')}{t('qna.get')}{t('qna.prize-5').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                </tr>
                <tr>
                  <td>{t('qna.correct-ans-2')}{t('qna.get')}{t('qna.prize-2').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                  <td>{t('qna.correct-ans-6')}{t('qna.get')}{t('qna.prize-6').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                </tr>
                <tr>
                  <td>{t('qna.correct-ans-3')}{t('qna.get')}{t('qna.prize-3').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                  <td>{t('qna.correct-ans-7')}{t('qna.get')}{t('qna.prize-7').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                </tr>
                <tr>
                  <td>{t('qna.correct-ans-4')}{t('qna.get')}{t('qna.prize-4').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                  <td>{t('qna.correct-ans-8')}{t('qna.get')}{t('qna.prize-8').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                </tr>
                <tr>
                  <td colspan="2">{t('qna.correct-ans-9')}{t('qna.get')}{t('qna.prize-9').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</td>
                </tr>
              </tbody>
            </table>

            <div className='term-content no-bg'>
              <p className='my-2'>{t('bet_and_earn.rule_of_activity')}：</p>
              <ol>
                <li>{t('qna.tnc-1', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                <li>{t('qna.tnc-2', { attribute: process.env.REACT_APP_WEBSITE_TITLE }).replace('##coin##',this.props.coinConsumption)}</li>
                <li>{t('qna.tnc-3').replace('##startTime##',this.props.startTime).replace('##endTime##',this.props.endTime)}</li>
                <li>{t('qna.tnc-4')}</li>
                <li>{t('qna.tnc-5').replace('##totalQuestion##',this.props.totalQuestion).replace('##timeLimit##',this.props.timeLimit.time).replace('##timeUnit##',t(`qna.${this.props.timeLimit.unit}`)).replace('##coin##',this.props.coinConsumption)}</li>
                <li>{t('qna.tnc-6')}</li>
                <li>{t('qna.tnc-7')}</li>
                <li>{t('qna.tnc-8')}</li>
                <li>{t('qna.tnc-9')}</li>
                <li>{t('qna.tnc-10', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="btt-btn mt-0 mb-5">
          <div className="btn btn-white" onClick={this.scrollToTop}>
            <Icon icon="ph:caret-double-up-bold" />
            {t("vip.back-to-top")}
          </div>
        </div>

      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(MobileFifaQna));
