import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    gameData: [],
};

const currentUserReducer = createReducer(initialState) ({
    [type.SET_USER]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.CHANGE_COUNTRY]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.CHANGE_LANGUAGE]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.SET_GAME_QT]: (state, action) => ({
        ...state,
        gameData: {
            ...state.qt,
            qt: action.payload
        },
    }),

    [type.SET_GAME_URL]: (state, action) => ({
        ...state,
        gameData: {
            ...state.url,
            url: action.payload
        },
    }),


    // [type.GET_COUNTRY_ID]: (state, action) => ({
    //     ...state,
    //     data: state.countryId,
    // }),

    // [type.GET_USER_GROUP]: (state, action) => ({
    //     ...state,
    //     data: action.payload,
    // }),

});

export default currentUserReducer;
