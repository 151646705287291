import React, { Component } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { isDesktop } from 'react-device-detect';
import ReactPlayer from 'react-player';
import MobileLiveStreaming from './MobileLiveStreaming';

export class LiveStreaming extends Component {
  constructor(props) {
    super(props);
    // const t = this.props.t;
    this.state = {
      activeTab: 'football',
      matchStreamVideo: false,
      activeMatch: 1
    };
  }

  changeTab = (tab) => {
    this.setState({
      activeTab: tab
    })
  }

  changeMatch = (id) => {
    this.setState({
      activeMatch: id
    })
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };
  render() {
    const { t } = this.props;
    const liveImg = this.imageImport(require.context("../../assets/images/live-streaming", false, /\.(png|jpe?g|svg|webp)$/));
    const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg|webp)$/));

    const liveCtg = [
      "football", "games", "basketball"
    ]

    const matches = [
      { id: 1, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 2, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: false },
      { id: 3, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 4, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 5, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 6, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 7, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 8, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 9, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
      { id: 10, date: '11月24', time: '17:30', team1: 'arg', team2: 'arg', streaming: true },
    ]

    return (
      <Auxiliary>
        {isDesktop ?
          <section className='live-streaming-section'>
            <div className='x-container'>
              <div className='section-header'>
                <h2>{t("live-stream.title")}</h2>
                <p>{t("live-stream.desc")}</p>
              </div>
              <div className='global-game-section'>
                <div className='game-container bg-transparent shadow-none rounded-0'>
                  <div className='game-category border-0'>
                    <ul className='row justify-content-center'>
                      {
                        liveCtg && liveCtg.map((item, index) =>
                          <li className='game-ctg-col' key={index}>
                            <div className={`game-link ${this.state.activeTab === item ? "active" : ""}`} onClick={() => this.changeTab(item)}>
                              <span>
                                {this.state.activeTab === item ? (
                                  <img src={liveImg[`${item}-active.webp`]} alt="live category" />
                                ) : (
                                  <img src={liveImg[`${item}.webp`]} alt="live category" />
                                )
                                }
                              </span>
                              <p>{t(`live-stream.${item}`)}</p>
                            </div>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className='section-content card-shadow'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='live-stream-match pb-3'>
                      <div className='team'>
                        <p>阿根廷</p>
                        <img className='ms-2' src={flag["arg.svg"]} alt="flag" />
                      </div>
                      <img className='mx-5' src={liveImg["vs.webp"]} alt="vs" />
                      <div className='team'>
                        <img className='me-2' src={flag["arg.svg"]} alt="flag" />
                        <p>阿根廷</p>
                      </div>
                    </div>
                    <div className='live-stream-video ratio ratio-16x9 rounded'>
                      {
                        this.state.matchStreamVideo ? (
                          <ReactPlayer
                            volume={0.3}
                            width="100%"
                            height="100%"
                            url={"https://www.youtube.com/watch?v=IOu99FotmBQ"}
                            onEnded={this.playNext}
                            controls
                            playing
                          />
                        ) : (
                          <>
                            <img className='h-100' src={liveImg["video-bg.webp"]} alt="video background" />
                            <div className='overlay'>
                              <p style={{ fontSize: "1.375rem" }}>FIFA世界杯2022</p>
                              <small>11月24日  17:30</small>
                              <div className='live-stream-match pb-3'>
                                <div className='team'>
                                  <p className='me-2'>阿根廷</p>
                                  <img src={flag["arg.svg"]} alt="flag" />
                                </div>
                                <img className='mx-5' src={liveImg["vs.webp"]} alt="vs" />
                                <div className='team'>
                                  <img src={flag["arg.svg"]} alt="flag" />
                                  <p className='ms-2'>阿根廷</p>
                                </div>
                              </div>
                              <p style={{ fontSize: "1.375rem" }}>{t("live-stream.no-start")}</p>
                            </div>
                          </>

                        )
                      }

                    </div>
                    <div className='live-stream-result mt-3'>
                      <div className='row'>
                        <div className='col-10'>
                          <div className='result-container' style={{maxWidth:'49%'}}>
                            <p className='result-type'>
                              <span>{t("live-stream.capot")}</span>
                            </p>
                            <div className='d-flex justify-content-center'>
                              <div className='result' >
                                <p>{t("live-stream.home-win")}</p>
                                <span>-</span>
                              </div>
                              <div className='result' >
                                <p>{t("live-stream.draw")}</p>
                                <span>-</span>
                              </div>
                              <div className='result' >
                                <p>{t("live-stream.away-win")}</p>
                                <span>-</span>
                              </div>
                            </div>
                          </div>

                          <div className='result-container' style={{maxWidth:'24%'}}>
                            <p className='result-type'>
                              <span>{t("live-stream.handicap")}</span>
                            </p>
                            <div className='d-flex justify-content-center'>
                              <div className='result' >
                                <p>+0/0.5</p>
                                <span>1.69</span>
                              </div>
                              <div className='result' >
                                <p>+0/0.5</p>
                                <span>1.69</span>
                              </div>
                            </div>
                          </div>

                          <div className='result-container' style={{maxWidth:'24%'}}>
                            <p className='result-type'>
                              <span>{t("live-stream.over-under")}</span>
                            </p>
                            <div className='d-flex justify-content-center'>
                              <div className='result' >
                                <p>+0/0.5</p>
                                <span>1.69</span>
                              </div>
                              <div className='result' >
                                <p>+0/0.5</p>
                                <span>1.69</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-2 d-flex align-items-end'>
                          <button className='btn more-btn-blue'>{t("live-stream.bet")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 p-0'>
                    <div className='lsv-options-container'>
                      {
                        matches && matches.map((item, index) =>
                          <div
                            className={`live-stream-options card-shadow ${this.state.activeMatch === item.id && "active"}`}
                            key={index}
                            onClick={() => this.changeMatch(item.id)}
                          >
                            <div>
                              <div className='match-header'>
                                <p>FIFA世界杯2022</p>
                                <p className='match-date'>11月24日  17:30
                                  <img src={liveImg["video-icon.webp"]} alt="video" />
                                </p>
                              </div>
                              <div className='live-stream-match justify-content-between mt-3'>
                                <div className='team'>
                                  <img className='me-2' src={flag["arg.svg"]} alt="flag" />
                                  <p>阿根廷</p>
                                </div>
                                <img className='mx-5' src={liveImg["vs.webp"]} alt="vs" />
                                <div className='team'>
                                  <p>阿根廷</p>
                                  <img className='ms-2' src={flag["arg.svg"]} alt="flag" />
                                </div>
                              </div>
                              <small className={`text-center text-danger ${this.state.activeMatch === item.id && item.streaming ? "d-block" : "d-none"}`}>
                              {t("live-stream.streaming")}
                              </small>
                            </div>
                            {
                              !item.streaming &&
                              <div className='overlay'>
                                <p style={{ fontSize: "1.375rem" }}>{t("live-stream.no-streaming")}</p>
                              </div>
                            }
                          </div>
                        )
                      }
                    </div>

                    {/* <div className='live-stream-options card-shadow'>
                    <div className='match-header'>
                      <p>FIFA世界杯2022</p>
                      <p className='match-date'>11月24日  17:30
                        <img src={liveImg["video-icon.svg"]} alt="video" />
                      </p>
                    </div>
                    <div className='live-stream-match justify-content-between mt-3'>
                      <div className='team'>
                        <img className='me-2' src={flag["arg.svg"]} alt="flag" />
                        <p>阿根廷</p>
                      </div>
                      <img className='mx-5' src={liveImg["vs.png"]} alt="vs" />
                      <div className='team'>
                        <p>阿根廷</p>
                        <img className='ms-2' src={flag["arg.svg"]} alt="flag" />
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>

            </div>

          </section>

          :
          <MobileLiveStreaming
            matches={matches} />

        }
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(LiveStreaming));