import React, { Component } from 'react';

import ReactLoading from "react-loading";
import icon from "../../assets/images/icons/loading-icon.svg";
import { isDesktop } from "react-device-detect";
export class LoadingPage extends Component {

  componentDidMount() {
    document.body.classList.add('modal-open');
  }
  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }
  render() {
    return (
      <div className="react-loading" aria-hidden>
        <div>
          <img className="loading-img" src={icon}/>
          <ReactLoading
            type={"cylon"}
            color={"#fff"}
            width={(isDesktop ? "80px" : "200px")}
            height={(isDesktop ? "80px" : "200px")}
          />
        </div>
      </div>
    );
  }
}

export default LoadingPage;
