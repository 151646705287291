import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const eventQNAReducer = createReducer(initialState) ({
    [type.CREATE]: (state, action) => ({
        ...state,
        create: action.payload,
    }),
    [type.SUBMIT]: (state, action) => ({
        ...state,
        submit: action.payload,
    }),
    [type.GET_DATA]: (state, action) => ({
        ...state,
        rs: action.payload,
    }),
    [type.GET_HISTORY]: (state, action) => ({
        ...state,
        get_history: action.payload,
    })
});

export default eventQNAReducer;