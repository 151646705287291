export const storedDepositDropdownValidation = (stored_data, api_data) => {
    // console.log("ReduxDepositDropDown - ", stored_data);
    // console.log("ApiDepositDropDown - ", api_data);
    let status = Object.assign({});
    status.overwrite = false;

    if(stored_data === undefined){
        status.overwrite = true;
        // console.log("OverwriteDropDown - ", status);
        return status;
    }

    let result_member_group = verifyMemberGroup(stored_data.data.member_group, api_data.member_group);
    let result_promotion = verifyPromotion(stored_data.data.promotion, api_data.promotion);
    let result_payment_gateway = verifyPaymentGateway(stored_data.data.payment_gateway, api_data.payment_gateway);
    let result_last_deposit = verifyLastDeposit(stored_data.data.last_deposit, api_data.last_deposit);
    let result_bank_account = verifyBankAccount(stored_data.data.bank_account, api_data.bank_account);

    if(result_member_group || result_promotion || result_payment_gateway || result_last_deposit || result_bank_account){
        status.overwrite = true;
        // console.log("OverwriteDropDown - ", status);
        return status;
    }

    return status;
}

export const storedSecretPayBankOptionsValidation = (stored_data, api_data) => {
    // console.log("ReduxBankOption - ", stored_data);
    // console.log("ApiBankOption - ", api_data);
    let status = Object.assign({});
    status.overwrite = false;

    if(stored_data === undefined){
        status.overwrite = true;
        // console.log("OverwriteSecretPay - ", status);
        return status;
    }

    if(stored_data.data.length !== api_data.length){
        status.overwrite = true;
        // console.log("OverwriteSecretPay - ", status);
        return status;
    }

    if(JSON.stringify(stored_data.data) !== JSON.stringify(api_data)){
        status.overwrite = true;
        // console.log("OverwriteSecretPay - ", status);
        return status;
    }

    return status;
}

const verifyMemberGroup = (stored_member_group, api_member_group) => {
    let restore = false;

    if(JSON.stringify(stored_member_group) !== JSON.stringify(api_member_group)){
        return restore = true;
    }

    return restore;
}

const verifyPromotion = (stored_promotion, api_promotion) => {
    let restore = false;

    if(stored_promotion.length !== api_promotion.length){
        return restore = true;
    }

    if(JSON.stringify(stored_promotion) !== JSON.stringify(api_promotion)){
        return restore = true;
    }

    return restore;
}

const verifyPaymentGateway = (stored_payment_gateway, api_payment_gateway) => {
    let restore = false;

    if(stored_payment_gateway.length !== api_payment_gateway.length){
        return restore = true;
    }

    if(JSON.stringify(stored_payment_gateway) !== JSON.stringify(api_payment_gateway)){
        return restore = true;
    }

    return restore;
}

const verifyLastDeposit = (stored_last_deposit, api_last_deposit) => {
    let restore = false;

    if(JSON.stringify(stored_last_deposit) !== JSON.stringify(api_last_deposit)){
        return restore = true;
    }

    return restore;
}

const verifyBankAccount = (stored_bank_account, api_bank_account) => {
    let restore = false;

    if(JSON.stringify(stored_bank_account) !== JSON.stringify(api_bank_account)){
        return restore = true;
    }

    return restore;
}