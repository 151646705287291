import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment-timezone";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {
  getPromos,
  showPromos,
  setPersistState,
} from "../../store/ducks/promos/actions";
import { isDesktop } from "react-device-detect";

//Components
import MobilePromos from "./MobilePromos";
import { promoSeo } from "../../components/SeoMeta/SeoMeta";

const navList = [
  { id: 1, icon: "all-icon", label: "All" },
  { id: 2, icon: "lc-icon", label: "Live Casino" },
  { id: 3, icon: "sport-icon", label: "Sport" },
  { id: 4, icon: "slot-icon", label: "Slots" },
  { id: 5, icon: "game-icon", label: "Games" },
  { id: 6, icon: "p2p-icon", label: "P2P" },
  { id: 7, icon: "lottery-icon", label: "Lottery" },
];

// const promos = [
//   { id: 1, promosId: 1, img: "promos-1", titleImg: "" },
//   { id: 2, promosId: 1, img: "promos-2", titleImg: "" },
//   { id: 3, promosId: 2, img: "promos-3", titleImg: "lc-title" },
//   // { id: 4, promosId: 5, img: "promos-4", titleImg: "game-title" },
//   { id: 4, promosId: 5, img: "promos-3", titleImg: "game-title" },
// ];

class Promos extends Component {
  constructor(props) {
    super(props);
    let promosData = JSON.parse(localStorage.getItem("promosData"));
    this.state =
      props.persistState && props.persistState !== undefined
        ? props.persistState
        : // this.state =
          {
            isLoading: true,
            currentSelectPromoCategoryId: "",
            showPromosImage: "",
            showPromosTitle: "",
            showPromosContent: "",
            promosList: (promosData && promosData.promotion) || [],
            promotion_categories:
              (promosData && promosData.promotion_category) || [],
          };
  }

  fetchData() {
    if (localStorage.getItem("promosData")) {
      let promosData = JSON.parse(localStorage.getItem("promosData"));
      let tmpMoment = moment(promosData.expired);
      let duration = moment.duration(tmpMoment.diff(moment())).asSeconds();

      if (duration > 0) {
        let filtered = this.filterHandler(promosData.promotion);
        this.setState({
          promosList: filtered,
        });
        return false;
      }
    }
    const showPromosData = {};
    showPromosData.category_id = "";
    this.props.getPromos(showPromosData);
  }

  componentDidMount = () => {
    this.fetchData();
  };

  shouldComponentUpdate(nextProps, nextState) {
    this.props.setPersistState(nextState);
    if (nextProps.promos_data !== this.props.promos_data) {
      if (nextProps.promos_data.responseCode === 200) {
        let filtered = this.filterHandler(nextProps.promos_data.data.promotion);

        this.setState({
          isLoading: false,
          promosList: filtered,
          promotion_categories: nextProps.promos_data.data.promotion_category,
        });

        let expired = moment().add(2, "minutes");
        let tmpArr = nextProps.promos_data.data;
        tmpArr.expired = expired.format("YYYYMMDDTHHmmss");
        localStorage.setItem("promosData", JSON.stringify(tmpArr));
        // if (this.state.currentSelectPromoCategoryId === '') {
        // localStorage.setItem('promosList', JSON.stringify(nextProps.promos_data.data.promotion));
        // localStorage.setItem('promosCategory', JSON.stringify(nextProps.promos_data.data.promotion_category));
        // }
      } else {
        return false;
      }
    }

    if (nextProps.show_promos_data !== this.props.show_promos_data) {
      if (nextProps.show_promos_data.responseCode === 200) {
        this.setState({
          isLoading: false,
          showPromosImage: nextProps.show_promos_data.data[0].image,
          showPromosTitle: nextProps.show_promos_data.data[0].title,
          showPromosContent: nextProps.show_promos_data.data[0].content,
          openModal: true,
        });
      } else {
        this.setState({
          isLoading: false,
          openModal: false,
        });
      }
    }
    return true;
  }

  // toggleActiveCtg = (id) => {
  //   const filterPromos = promos.filter((promos) => promos.promosId === id);
  //   if (id !== 1) {
  //     this.setState({
  //       activeCtg: id,
  //       promosList: filterPromos,
  //     });
  //   } else {
  //     this.setState({
  //       activeCtg: id,
  //       promosList: promos,
  //     });
  //   }
  // };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  promoCategoryHandler = (event, category_id) => {
    event.preventDefault();

    if (localStorage.getItem("promosData")) {
      let promosData = JSON.parse(localStorage.getItem("promosData"));
      let tmpMoment = moment(promosData.expired);
      let duration = moment.duration(tmpMoment.diff(moment())).asSeconds();

      if (duration > 0) {
        let filtered = this.filterHandler(promosData.promotion, category_id);
        this.setState({
          promosList: filtered,
          currentSelectPromoCategoryId: category_id,
        });
        return false;
      }
    }

    this.setState({
      isLoading: true,
      currentSelectPromoCategoryId: category_id,
    });

    const showPromosData = {};
    showPromosData.category_id = "";
    this.props.getPromos(showPromosData);
  };

  filterHandler = (promosList, categoryId) => {
    let duplicate = [];
    let category_id =
      categoryId !== undefined
        ? categoryId
        : this.state.currentSelectPromoCategoryId;
    let filtered = promosList.filter(function (item) {
      if (duplicate[item.promotionId] === undefined) {
        return category_id === ""
          ? (item, (duplicate[item.promotionId] = item.promotionId))
          : item.promotionCategoriesId === Number(category_id)
          ? item
          : null;
      } else {
        return null;
      }
    });
    return filtered;
  };

  render() {
    const { t } = this.props;
    const banner = this.imageImport(
      require.context(
        "../../assets/images/banners",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const img = this.imageImport(
      require.context(
        "../../assets/images/promos",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icon = this.imageImport(
      require.context(
        "../../assets/images/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let imageLanguage = "";
    // console.log(this.props.currentUser.language_code)
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn";
        break;
      case "ms":
        imageLanguage = "-ms";
        break;
      case "id":
        imageLanguage = "-indon";
        break;
      default:
        imageLanguage = "";
        break;
    }

    return (
      <Auxiliary>
        {promoSeo}
        {isDesktop ? (
          <>
            <div className="banner-col">
              <div className="banner-item">
                <img
                  src={banner[`promos-banner${imageLanguage}.webp`]}
                  alt="Promos"
                />
              </div>
            </div>
            <section className="promos-container">
              <div className="x-container">
                <div className="row g-3">
                  <div className="col">
                    <span className="card-shadow">
                      <img
                        className="sideNav-bg"
                        src={img["sideBar-bg.webp"]}
                        alt="sideBar-bg"
                      />
                    </span>
                    <ul className="promos-sideNav-container gap-3">
                      <li
                        className={
                          this.state.currentSelectPromoCategoryId === ""
                            ? "active"
                            : ""
                        }
                      >
                        <div
                          className="promos-sideNav"
                          role="button"
                          onClick={(event) =>
                            this.promoCategoryHandler(event, "")
                          }
                        >
                          <span>
                            <img
                              className="promos-icon"
                              src={icon[`all-icon.png`]}
                              alt={"all"}
                            />
                          </span>
                          <p>{t("global.all")}</p>
                        </div>
                      </li>
                      {this.state.promotion_categories &&
                        this.state.promotion_categories.map((item, index) => (
                          <li
                            key={index}
                            className={`${
                              this.state.currentSelectPromoCategoryId ===
                              item.id
                                ? "active"
                                : ""
                            }`}
                            onClick={(event) =>
                              this.promoCategoryHandler(event, item.id)
                            }
                          >
                            <div className="promos-sideNav">
                              <span>
                                <img
                                  className="promos-icon"
                                  src={
                                    icon[
                                      `${
                                        item.title.toLowerCase() ===
                                        "live casino"
                                          ? "lc"
                                          : item.title.toLowerCase()
                                      }-icon.png`
                                    ]
                                  }
                                  alt={item.icon}
                                />
                              </span>
                              <p>{t(`promos.${item.title}`)}</p>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="col d-grid gap-3">
                    {this.state.promosList &&
                      this.state.promosList.map((item, index) => (
                        <Link
                          {...(item.eventsRedirectUrl
                            ? {
                                to: { pathname: item.eventsRedirectUrl },
                                target: "_blank",
                              }
                            : {
                                to: {
                                  pathname:
                                    "/promos-detail/" + item.promotionId,
                                  params: { promo_id: item.promotionId },
                                },
                              })}
                          className="promos-list position-relative"
                        >
                          <span className="card-shadow" key={index}>
                            <LazyLoadImage
                              className="w-100 h-auto"
                              src={item.promotionImage}
                              alt={item.promotionName}
                              effect="blur"
                            />
                          </span>
                          {this.state.currentSelectPromoCategoryId === "" ? (
                              <img
                                className="promos-title"
                                src={
                                  img[
                                    `${item.defaultPromoCategory
                                      .replace(/ /g, "-")
                                      .toLowerCase()}-cat.webp`
                                  ]
                                }
                                alt={item.defaultPromoCategory}
                              />

                          ) : (
                            <img
                            className="promos-title"
                            src={
                              img[
                                `${item.promotionCategoriesTitle
                                  .replace(/ /g, "-")
                                  .toLowerCase()}-cat.webp`
                              ]
                            }
                            alt={item.promotionCategoriesTitle}
                          />
                          )}
                         
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <MobilePromos
            navList={navList}
            {...this.state}
            promoCategoryHandler={this.promoCategoryHandler}
          />
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { promos } = state;

  return {
    promos_data: promos.promos_data,
    show_promos_data: promos.show_promos_data,
    currentUser: state.currentUser.data,
    persistState: promos.persistState,
  };
};

const mapDispatchToProps = {
  getPromos,
  showPromos,
  setPersistState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(Promos));
