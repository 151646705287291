import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
//Miscellaneous

import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
//Components
import DataTable from "../../../components/Datatable/DataTable";
// import { NavLink, Redirect } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Skeleton from "react-loading-skeleton";

import MobileAddBank from "../../../components/MobileAddBank/MobileAddBank";
import MobileEditBank from "../../../components/MobileAddBank/MobileEditBank";

class MobileBankingDetails extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            // openSelectRadio: false,
            // example: false,
            // data: [],
            // bank_acc_name: this.props.bank_acc_name, 
            // editBankDropDown: [],
            // edit_id: null,
            // edit_bank_key: null,
            // edit_bank_name: "",
            // edit_bank_acc_number: "",

            showAddNewBank: false,
            showEditBank: false,
            // loading: false,
            // errorMsg: [], 
        };
    }

    componentDidMount() {}

    shouldComponentUpdate() 
    {
        return true;
    }

    toggleAddBankHandler = () => 
    {
        if (this.state.showAddNewBank === false)  
        { 
            document.body.classList.add('modal-open'); 
        }  
        else  
        { 
            document.body.classList.remove('modal-open'); 
        } 
        this.setState({ 
            showAddNewBank: !this.state.showAddNewBank, 
        });
    }

    toggleEditBankHandler = () => 
    {
        if (this.state.showEditBank === false) 
        {
            document.body.classList.add('modal-open');
        } 
        else 
        {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showEditBank: !this.state.showEditBank,
        });
    }

    getEditBankHanlder = (e, id, bankId, bankName, swiftCode, bankAccNo) => 
    {
        this.toggleEditBankHandler();
        this.child.openModalHandler(e, id, bankId, bankName, swiftCode, bankAccNo);
    }

    actionFormatter = (cell, row) => 
    {
        let removeButton, output;
        removeButton = (
            <div className="text-end" key={row.memberBankAccId}>
                <Button
                    typeButton="button"
                    classValue="btn icon"
                    key={`edit-${row.memberBankAccId}`}
                    idValue={row.memberBankAccId === undefined ? 0 : row.memberBankAccId}
                    // clicked={(e) => this.openModalHandler(e, row.memberBankAccId, row.bankId, row.bankName, row.swiftCode, row.accountNo)}
                    // clicked={(e) => this.getEditBankHanlder(e, val, val.bank_id, val.bank_name, val.swift_code, val.bank_acc_number)}
                    clicked={(e) =>
                        this.getEditBankHanlder(
                            e,
                            row.memberBankAccId,
                            row.bankId,
                            row.bankName,
                            row.swiftCode,
                            row.accountNo
                        )
                    }
                    buttonName={<Icon icon="uil:edit" />}
                />
                <Button
                    typeButton="button"
                    classValue="btn icon red"
                    key={`remove-${row.memberBankAccId}`}
                    idValue={row.memberBankAccId === undefined ? 0 : row.memberBankAccId}
                    clicked={(e) => 
                        this.props.removeBankHandler(e, row.memberBankAccId, row.bankName)
                    }
                    buttonName={<Icon icon="uil:trash-alt" />}
                />
            </div>
        );

        output = [removeButton];
        return output;
    };

    render() 
    {
        const { t } = this.props;
        const columns = [
            {
                text: t("banking-details.bank-name"),
                dataField: "bank",
                headerAttrs: {
                    hidden: true,
                },
            },
            {
                text: "",
                dataField: "delete",
                formatter: this.actionFormatter,
                headerAttrs: {
                    hidden: true,
                },
            },
        ];

        return (
            <Auxiliary>
                {this.state.showAddNewBank && 
                    <MobileAddBank
                        {...this.state}
                        {...this.props}
                        toggleAddBankHandler={this.toggleAddBankHandler}
                    />
                }
                {/* {this.state.showEditBank &&  */}
                    <MobileEditBank
                        {...this.state}
                        // {...this.props}
                        toggleEditBankHandler={this.toggleEditBankHandler}
                        onRef={ref => (this.child = ref)}
                    />
                {/* } */}

                <div className="bank-list-group">
                    <div className="tab-pane fade show active" id="banking-details" role="tabpanel" aria-labelledby="banking-tab">
                        {
                        this.props.loading ? (
                            <div className="mt-3">
                                <Skeleton count={2} style={{ marginBottom: "1rem" }} height={"56px"} />
                            </div>
                        ) : (
                            <DataTable
                                keyField="no"
                                data={this.props.rsData}
                                columns={columns}
                                activePage={1}
                                limit={20}
                                totalItems={this.props.totalRecords}
                                paginationHandler={(val) =>
                                    this.props.datatablesChangeHandler(val, "activePage")
                                }
                                sizePerPageHandler={(val) =>
                                    this.props.datatablesChangeHandler(val, "limit")
                                }
                                loading={this.props.loading}
                                hideSearchBar={true}
                                hidePagination={true}
                                hideTotalRecords={true}
                                classValue="pd-table w-border mb-0"
                                component="banking-details"
                            />
                        )
                        }
                        <button type="button" className="btn form-check-label wdrawal-add-bank w-100" onClick={this.toggleAddBankHandler}>
                            <Icon className="me-2" icon="radix-icons:plus-circled" />
                            {t("banking-details.add-bank")}
                        </button>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MobileBankingDetails);
