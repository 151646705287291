import React, {Component} from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import {required, validateDisplay} from "../../../hoc/Shared/utility";
import PropTypes from 'prop-types';

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage)
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ) {
            let payload = {
                value: nextProps.value,
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if (nextProps.required) {
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            return payload;
        }

        return null;
    }

    render() {
        const {id, name, className, style, onChange, defaultValue, required, getOptionLabel, getOptionValue, emptyValue, disabled} = this.props;

        const {value, requiredRules} = this.state;

        //Export the validation classes and message
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);

        return (
            <Auxiliary>
                <select
                    className={className}
                    style={style}
                    id={id}
                    name={name}
                    onChange={onChange}
                    value={value}
                    required={required}
                    defaultValue={defaultValue}
                    disabled={disabled}
                >
                    {(emptyValue) && <option value=""></option>}
                    {this.props.options.map((option) =>
                        <option key={option[getOptionValue] || option.value} value={option[getOptionValue] || option.value}
                                defaultValue={option.selected}>{option[(getOptionLabel)] || option.label}</option>
                    )}
                </select>

                <div className="invalid-feedback">
                    {requiredClass !== 'is-valid' && requiredMsg}
                </div>
            </Auxiliary>
        )
    }
}

export default Select;

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,

    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    required: PropTypes.bool
};

Select.defaultProps = {
    className: ''
};
