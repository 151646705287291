import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // data: [],
};

const bonusReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.APPLY_RESCUE_BONUS]: (state, action) => ({
        ...state,
        rescue_bonus: action.value,
    }),
    [type.CLAIM_FIRST_BET_BONUS]: (state, action) => ({
        ...state,
        first_bet_bonus: action.value,
    }),
    [type.REDEEM_VOUCHER_CODE]: (state, action) => ({
        ...state,
        voucher_code: action.value,
    }),
    [type.APPLY_DELAY_BONUS]: (state, action) => ({
        ...state,
        delay_bonus: action.value,
    }),
});

export default bonusReducer;