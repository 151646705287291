import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const vipReducer = createReducer(initialState) ({
    [type.GET_VIP]: (state, action) => ({
        ...state,
        vip_data: action.payload,
    }),
    [type.VALIDATE_VIP_WEEKLY_BONUS]: (state, action) => ({
        ...state,
        validate_vip_weekly_bonus: action.payload
    }),
    [type.CLAIM_VIP_WEEKLY_BONUS]: (state, action) => ({
        ...state,
        claim_vip_weekly_bonus: action.payload
    }),
    [type.VALIDATE_VIP_BIRTHDAY_BONUS]: (state, action) => ({
        ...state,
        validate_vip_birthday_bonus: action.payload
    }),
    [type.CLAIM_VIP_BIRTHDAY_BONUS]: (state, action) => ({
        ...state,
        claim_vip_birthday_bonus: action.payload
    }),
    [type.GET_VIP_DEPOSIT_TURNOVER]: (state, action) => ({
        ...state,
        vip_deposit_turnover: action.payload
    })
});

export default vipReducer;