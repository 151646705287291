import { Component } from "react";
import { connect } from "react-redux";
import * as action from '../../store/ducks/slot/actions';
import { Redirect } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import slugConf from "../../common/data/games.json";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";
// import MobileSlot from "./MobileSlot";
import MobileHome from "../Home/MobileHome";

class DefaultSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            vendorSlug: "",
            vendorId: "",
            vendorList: []
        }
    }

    componentDidMount() {
        let params = {};
        // params.country_id = this.props.currentUser.data.country_id;

        this.props.getVendor(params);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps);
        if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
            if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === 'success') {
                let vendorSlug = "";
                let vendorId = "";
                let vendorList = nextProps.vendor.data.vendor_list;
                if (vendorList.length > 0) {
                    vendorSlug = vendorList[0].game_vendor_slug;
                    vendorId = vendorList[0].game_vendor_id;
                }
                else {
                    vendorSlug = "not-found";
                    vendorId = 0;
                }

                this.setState({
                    loading: false,
                    vendorSlug: vendorSlug,
                    vendorId: vendorId,
                    vendorList: vendorList
                });
            }
        }
        return true;
    }

    render() {
        let vendorList = this.state.vendorList ? this.state.vendorList.map((item, index) => {
            return {
                name: item.game_vendor_title,
                slug: item.game_vendor_slug,
                img: `${item.game_type}-${item.game_vendor_slug}-img.webp`,
                gameType: item.game_type,
                hotStatus: item.hot_status
            };
        }) : [];
        
        return (
            <>
                {!this.state.loading ? (isDesktop ? 
                    <Redirect
                        push
                        to={{ pathname: `/slot/${((slugConf['slug-link'] && slugConf['slug-link'][this.state.vendorSlug]) || '')}`, state: { prevUrl: window.location.pathname } }}
                    /> :
                        // <MobileSlot 
                        //     vendorList={this.state.vendorList}
                        // />
                        <MobileHome
                            vendorList={vendorList}
                            location={this.props.location}
                        />
                    ) : (
                    <>
                        <LoadingPage />
                        {isDesktop ? (
                            <Skeleton count={1} height={800} />
                        ) : (
                            <Skeleton count={1} height={500} />
                        )}
                    </>
                )
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vendor: state.slot.vendor,
        currentUser: state.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVendor: (params) => dispatch(action.getVendorList(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSlot);