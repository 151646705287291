import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

//Miscellaneous
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { getMemberProfile, updateMemberProfile, getTopProfile, redirectGoogle, unlinkGoogle, generateEmailOTP, resendEmailOTP, verifyEmailOTP, unlinkTelegram, getBusinessAccount, getPersonalInfo } from "../../../store/ducks/profile/actions";
// import ValidationError from "../../../components/ValidationError/ValidationError";
import { formValidation } from "../../../hoc/Shared/utility";
import StringInput from "../../../components/Input/StringInput/StringInput";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { isDesktop } from "react-device-detect";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import Modal from "react-modal";
import ValidationError from "../../../components/ValidationError/ValidationError";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
// import NoRecords from "../../../components/NoRecords/NoRecords";
import VerificationModal from "./VerificationModal";
import SecurityPinModal from "../../../components/SecurityPinModal/SecurityPinModal";
import MobilePersonalInformation from "./MobilePersonalInformation";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";

import { Lightbox } from "react-modal-image";
import CoinModal from "../../../components/CoinModal/CoinModal";
import AddAddressModal from "../../../components/AddressModal/AddAddressModal";

//Icons
import { Icon } from "@iconify/react";
import BettingHistory from "./BettingHistory";
import BankingDetails from "./BankingDetails";

const initialFormInputState = {
    /* form input value */
    dates: null,
    full_name: "",
    gender: "M",
    loading: true,
    register_date: "",
    set_basic_info: false,
    set_full_name_status: false,
    unlink_otp: "",
    address: [],
    openImageModal: false,
};
class PersonalInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFilter: initialFormInputState,
            verificationModal: "",
            bindingMobilePhoneStatus: false,
            bindingEmailStatus: false,
            addBankStatus: false,
            addSecurityPinStatus: false,
            errorMsg: [],
            formError: false,
            topProfileData: [],
            refresh: true,
            linkSocialMediaLoading: false,

            showUnlinkModal: false,
            showSendUnlinkOtp: false,
            showEnterUnlinkOtp: false,

            companyName: "",
            companyRegNo: "",
            bankAccNo: "",
            ssmImage: "",

            openCoinModal: false,
            addressModal: false,

            openSecurityPinModal: false,

            memberBankData: [],
            latestBetData: []
        };
    }

    onSelect = (dates) => {
        this.state.setState({ dates });
    };

    componentDidMount() {
        formValidation("basic_info_from");

        if (this.props.topProfileData === undefined) {
            if (localStorage.getItem("accessToken")) {
                this.props.getTopProfile();
            }
        } else {
            this.setState({ loading: false });
        }

        // this.balanceInterval = setInterval(() => {
        //   this.props.getTopProfile();
        // }, 10000);

        let data = {};
        data.language = this.props.currentUser.language_code;
        this.props.getPersonalInfo(data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
            if (nextProps.topProfileData.responseCode === 200) {
                this.setState({
                    loading: false,
                    refresh: false,
                    topProfileData: nextProps.topProfileData.data,
                    bindingMobilePhoneStatus: nextProps.topProfileData.data.topProfile.contact_verified === 1 ? true : false,
                    bindingEmailStatus: nextProps.topProfileData.data.topProfile.email_verified === 1 ? true : false,
                    addBankStatus: nextProps.topProfileData.data.topProfile.bank_verified === 1 ? true : false,
                    addSecurityPinStatus: nextProps.topProfileData.data.topProfile.security_pin_verified === 1 ? true : false
                });

            }
        }

        if (nextProps.memberProfileData !== this.props.memberProfileData && nextProps.memberProfileData !== undefined) {
            if (nextProps.memberProfileData.responseCode === 200) {
                let memberProfile = nextProps.memberProfileData.data.memberProfile[0];
                let newFilter = Object.assign({}, this.state.formFilter);
                newFilter["full_name"] = (!memberProfile.name) ? this.state.formFilter.full_name : memberProfile.name;
                newFilter["dates"] = (!memberProfile.dateOfBirth) ? this.state.formFilter.dates : moment(memberProfile.dateOfBirth).toDate();
                newFilter["loading"] = false;
                newFilter["gender"] =
                    (!memberProfile.gender)
                    ?
                        this.state.formFilter.gender
                    :
                        memberProfile.gender !== null && memberProfile.gender === "M" ? "M" : memberProfile.gender !== null && memberProfile.gender === "F" ? "F" : "M"
                    ;
                newFilter["register_date"] = memberProfile.registerDetails;
                newFilter["set_basic_info"] = memberProfile.name !== "" && memberProfile.dateOfBirth !== null ? true : false;
                newFilter["set_full_name_status"] = memberProfile.name !== "" ? true : false;
                newFilter["verified_contact_no"] = memberProfile.contactNo;
                newFilter["verified_email"] = memberProfile.email;
                newFilter["address"] = memberProfile.address;

                this.setState({
                    formFilter: newFilter,
                });
            }
        }

        if (nextProps.memberProfileUpdateResult !== this.props.memberProfileUpdateResult) {
            if (nextProps.memberProfileUpdateResult.responseCode === 200) {
                this.props.getMemberProfile();
                const { t } = this.props;
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.record-updated"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                });
            } else if (nextProps.memberProfileUpdateResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.memberProfileUpdateResult.message.input_error || [],
                    formError: true,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.google_redirect_result !== this.props.google_redirect_result) {
            if (nextProps.google_redirect_result.responseCode === 200) {
                this.setState({
                    linkSocialMediaLoading: false
                });

                window.location.href = nextProps.google_redirect_result.data;
            }
        }

        if (nextProps.generate_email_otp_result !== this.props.generate_email_otp_result) {
            if (nextProps.generate_email_otp_result.responseCode === 200) {
                if (this.state.openUnlinkGoogleModal === false) {
                    this.setState({
                        generateEmailStatus: true
                    })
                } else {
                    this.setState({
                        showSendUnlinkOtp: false,
                        showEnterUnlinkOtp: true
                    });
                }
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.generate_email_otp_result.message,
                });
                Swal.close();
            }
        }


        if (nextProps.unlink_google_result !== this.props.unlink_google_result) {
            if (nextProps.unlink_google_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    // text: t("profile.unlinked-google-success"),
                    html: t("profile.unlinked-google-success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ showUnlinkModal: false, showSendUnlinkOtp: false, showEnterUnlinkOtp: false });

                        this.props.getTopProfile();

                    }

                });


            } else if (nextProps.unlink_google_result.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.unlink_google_result.message || [],
                    formError: true,
                });
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.unlink_google_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.resend_email_otp_result !== this.props.resend_email_otp_result) {
            if (nextProps.resend_email_otp_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then(() => {
                    if (this.state.openUnlinkGoogleModal === false) {
                        this.setState({
                            generateEmailOTP: true,
                            openEmailModal: true,
                        });
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.resend_email_otp_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.unlink_telegram_result !== this.props.unlink_telegram_result) {
            if (nextProps.unlink_telegram_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.getTopProfile();
                    }
                });


            } else if (nextProps.unlink_telegram_result.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.unlink_telegram_result.message || [],
                    formError: true,
                });
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.unlink_telegram_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.get_business_account_result !== undefined && nextProps.get_business_account_result !== this.props.get_business_account_result) {
            if (nextProps.get_business_account_result.responseCode === 200) {
                if (nextProps.get_business_account_result.data) {
                    this.setState({
                        loading: false,
                        refresh: false,
                        companyName: nextProps.get_business_account_result.data.company_name,
                        companyRegNo: nextProps.get_business_account_result.data.company_reg_no,
                        bankAccNo: nextProps.get_business_account_result.data.bank_account_number,
                        ssmImage: nextProps.get_business_account_result.data.ssm_image
                    });
                }
            }
        }

        if (nextProps.get_personal_info !== undefined && nextProps.get_personal_info !== this.props.get_personal_info) {
            if (nextProps.get_personal_info.responseCode === 200 && nextProps.get_personal_info.msgType === 'success') {
                let memberBankData = nextProps.get_personal_info.data.memberBank;
                let latestBetData = nextProps.get_personal_info.data.latestBet;
                let businessAccData = nextProps.get_personal_info.data.businessAccount;
                let memberProfileData = nextProps.get_personal_info.data.memberProfile;
                let memberAddressData = nextProps.get_personal_info.data.memberAddress;
                let newFilter = Object.assign({}, this.state.formFilter);
                newFilter["full_name"] = (!memberProfileData.name) ? this.state.formFilter.full_name : memberProfileData.name;
                newFilter["dates"] = (!memberProfileData.dateOfBirth) ? this.state.formFilter.dates : moment(memberProfileData.dateOfBirth).toDate();
                newFilter["loading"] = false;
                newFilter["gender"] = (!memberProfileData.gender) ? this.state.formFilter.gender : memberProfileData.gender !== null && memberProfileData.gender === "M" ? "M" : memberProfileData.gender !== null && memberProfileData.gender === "F" ? "F" : "M";
                newFilter["register_date"] = memberProfileData.registerDetails;
                newFilter["set_basic_info"] = memberProfileData.name !== "" && memberProfileData.dateOfBirth !== null ? true : false;
                newFilter["set_full_name_status"] = memberProfileData.name !== "" ? true : false;
                newFilter["verified_contact_no"] = memberProfileData.contactNo;
                newFilter["verified_email"] = memberProfileData.email;
                newFilter["address"] = memberAddressData;

                this.setState({
                    formFilter: newFilter,
                    memberBankData: memberBankData,
                    latestBetData: latestBetData,
                    companyName: businessAccData ? businessAccData.company_name : "",
                    companyRegNo: businessAccData ? businessAccData.company_reg_no : "",
                    bankAccNo: businessAccData ? businessAccData.bank_account_number : "",
                    ssmImage: businessAccData ? businessAccData.ssm_image : ""
                });
            }
        }

        return true;
    }

    verificationModalHandler = (value) => {
        this.setState({ verificationModal: value }, () => {
            switch (value) {
                case "verification-acc-pwd":
                    formValidation("change_password_from");
                    break;
                case "verification-email":
                    formValidation("email_verification_from");
                    break;
                case "verification-phone":
                    formValidation("mobile_verify_from");
                    break;
                default:
                    break;
            }
        });
    };

    securityPinModalHandler = (value) => {
        this.setState({ openSecurityPinModal: true, securityPinModal: value });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        if (event.target.id === "genderFemale") {
            newFilter["gender"] = event.target.value;
        } else {
            newFilter[event.target.id] = event.target.value;
        }

        this.setState({
            formFilter: newFilter,
        });
    };

    postUpdateMemberProfile = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
            title: t("global.confirmation"),
            // text: t("global.would-you-like-to-proceed") + "?" + " " + t("global.details-update-basic-information"),
            text: (`${t("global.would-you-like-to-proceed")}? ${t("global.details-update-basic-information")}`),
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                if (this.state.formFilter.set_full_name_status === true) {
                    data.gender = this.state.formFilter.gender;
                    data.date_of_birth = this.state.formFilter.dates ? this.state.formFilter.dates : moment().subtract(18, "years").format("yyyy-MM-DD");
                } else {
                    data.full_name = this.state.formFilter.full_name;
                    data.gender = this.state.formFilter.gender;
                    data.date_of_birth = this.state.formFilter.dates ? this.state.formFilter.dates : moment().subtract(18, "years").format("yyyy-MM-DD");
                }

                this.props.updateMemberProfile(data);
            }
        });
    };

    redirectAddBank = () => {
        this.props.history.push("/my-wallet");
    };

    onChangeDateHandler = (value) => {
        // console.log(`${value.years}-${value.months}-${value.days}`);
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter["dates"] = `${value.years}-${value.months}-${value.days}`;

        this.setState({
            formFilter: newFilter,
        });
    };
    onChangeGenderHandler = (value) => {
        // console.log(value);
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter["gender"] = value;

        this.setState({
            formFilter: newFilter,
        });
    };

    socialMediaNoPasswordHandler = () => {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
            icon: "info",
            title: t("global.sorry"),
            text: t("global.no-password-require-msg-1"),
            confirmButtonText: t("global.ok"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container sorry",
                icon: "swal-sorry-icon",
            } : []
        });
    }

    handleGoogleRedirect = () => {
        const data = {};
        data.url = window.location.origin;

        this.setState({
            linkSocialMediaLoading: true
        })

        this.props.redirectGoogle(data);
    };

    handleTelegramRedirect = () => {
        window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL + "/?url=" + window.location.origin, "_blank");
    };

    openUnLinkModalHandler = () => {
        this.setState({
            showUnlinkModal: !this.state.showUnlinkModal,
            showSendUnlinkOtp: true,
        })
    }

    showEnterUnlickOtpHandler = () => {
        this.setState({
            showSendUnlinkOtp: false,
            showEnterUnlinkOtp: true
        })
    }

    onSubmitUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.data.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = localStorage.getItem('lang');
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateEmailOTP(data);
    }

    resendUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = this.props.currentUser.language_code;
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.resendEmailOTP(data);
    };

    verifyUnlinkGoogleOTPHandler = (event, googleLinkEmail) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            title: t("validation.confirmation"),
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.email = googleLinkEmail;

                data.otp_code = this.state.formFilter.unlink_otp;

                this.props.unlinkGoogle(data);
            }
        });
    }

    resendUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = localStorage.getItem('lang');
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.resendEmailOTP(data);
    };

    unlinkTelegramHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.ok"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });
                const data = {};

                data.telegram_id = this.state.topProfileData.topProfile.telegram_id;

                this.props.unlinkTelegram(data);
            }
        });
    }

    toggleCoinModal = () => {
        this.setState({
            openCoinModal: !this.state.openCoinModal
        })
    }

    toggleAddressModal = () => {
        this.setState({
            addressModal: !this.state.addressModal,
        })
    }

    toggleSecurityPinModal = () => {
        this.setState({
            openSecurityPinModal: !this.state.openSecurityPinModal
        })
    }

    render() {
        const { t } = this.props;

        let topProfileData = null;

        let emailStatusClass = "";
        let mobileStatusClass = "";
        let securityPinStatusClass = "";
        let googleStatusClass = true;
        let telegramStatusClass = true;
        let googleLinkEmail = "";
        let businessAccountStatus = false;
        // let addressStatusClass = false;

        if (this.state.topProfileData.length !== 0) {
            topProfileData = this.state.topProfileData;
            emailStatusClass = topProfileData.topProfile.email_verified === 1 ? "active" : "";
            mobileStatusClass = topProfileData.topProfile.contact_verified === 1 ? "active" : "";
            securityPinStatusClass = topProfileData.topProfile.security_pin_verified === 1 ? "active" : "";

            if (topProfileData.topProfile.google_id === "") {
                googleStatusClass = false;
            } else if (topProfileData.topProfile.google_id === null) {
                googleStatusClass = false;
            }

            if (topProfileData.topProfile.telegram_id === "") {
                telegramStatusClass = false;
            } else if (topProfileData.topProfile.telegram_id === null) {
                telegramStatusClass = false;
            }

            googleStatusClass = googleStatusClass === true ? "active" : "";
            telegramStatusClass = telegramStatusClass === true ? "active" : "";
            googleLinkEmail = topProfileData.topProfile.google_link_email;
            businessAccountStatus = (this.props.topProfileData && this.props.topProfileData.data.topProfile.business_account_status);
        }

        let unlinkMaskEmail;
        let unlinkEmailDomain;

        if (this.state.showUnlinkModal === true) {
            unlinkMaskEmail = googleLinkEmail.substring(0, googleLinkEmail.length - 9).replace(/\d/g, "******");
            unlinkEmailDomain = googleLinkEmail.split("@")[1];
        }

        // Get birthday countdown and age
        let birthdayCountdown = '';
        // let age = 0;

        if (this.state.formFilter.dates) {
            let nextBirthday = moment().format("yyyy-") + moment(this.state.formFilter.dates).format("MM-DD");
            let age = 0;
            if (nextBirthday === moment().format("yyyy-MM-DD")) {
                age = moment().diff(moment(this.state.formFilter.dates).format("yyyy-MM-DD"), 'years');
                birthdayCountdown = t("profile.today-birthday").replace('%%age%%', age);
            } else {
                if (nextBirthday < moment().format("yyyy-MM-DD")) {
                    nextBirthday = moment().add(1, "years").format("yyyy-") + moment(this.state.formFilter.dates).format("MM-DD");
                    age = moment().diff(moment(this.state.formFilter.dates).subtract(1, "years").format("yyyy-MM-DD"), 'years');
                } else {
                    age = moment().diff(moment(this.state.formFilter.dates).subtract(1, "years").format("yyyy-MM-DD"), 'years');
                }

                const given = moment(nextBirthday, "YYYY-MM-DD");
                const current = moment().startOf('day');
                const dayLeft = moment.duration(given.diff(current)).asDays();
                birthdayCountdown = t("profile.birthday-countdown").replace('%%age%%', age);
                birthdayCountdown = birthdayCountdown.replace('%%day%%', dayLeft);
            }
        }
        // const img = this.imageImport(
        //   require.context(
        //     "../../../assets/images/profile",
        //     false,
        //     /\.(png|jpe?g|svg)$/
        //   )
        // );
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        const thirdParty = this.imageImport(require.context("../../../assets/images/register/", false, /\.(png|jpe?g|svg|webp)$/));

        const handleApply = (event, picker) => {
            picker.element.val(picker.startDate.format("YYYY-MM-DD"));
            let newFilter = Object.assign({}, this.state.formFilter);
            newFilter["dates"] = event.target.value;

            this.setState({
                formFilter: newFilter,
            });
        };

        const handleCancel = (event, picker) => {
            picker.element.val("");
        };

        const fadeUp = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, 50px ,0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `;

        let maxDate = moment().subtract(18, "years").startOf("day");
        let minDate = moment(maxDate).subtract(62, "years").startOf("day");

        return (
            <Auxiliary>
                <CoinModal
                    openCoinModal={this.state.openCoinModal}
                    toggleCoinModal={this.toggleCoinModal}
                    currentUser={this.props.currentUser}
                />
                {
                    !this.state.formFilter.loading &&
                    <AddAddressModal
                        toggleAddressModal={this.toggleAddressModal}
                        {...this.state}
                        {...this.props}
                    />
                }

                {
                    this.state.linkSocialMediaLoading && <LoadingPage />
                }
                {
                    this.state.openImageModal && (
                        <Lightbox
                            medium={this.state.ssmImage}
                            hideZoom={true}
                            hideDownload={true}
                            onClose={() => { this.setState({ openImageModal: false }) }}
                        />
                    )
                }
                {isDesktop ? (
                    <>
                        <VerificationModal {...this.state} mobileStatusClass={mobileStatusClass} emailStatusClass={emailStatusClass} />
                        <SecurityPinModal {...this.state}
                            verificationModalHandler={this.verificationModalHandler}
                            toggleSecurityPinModal={this.toggleSecurityPinModal}
                        />

                        <Modal
                            isOpen={this.state.showUnlinkModal}
                            closeTimeoutMS={500}
                            portalClassName={`global-modal`}
                            className="modal modal-dialog modal-dialog-centered modal-pd"
                            ariaHideApp={false}
                            onRequestClose={this.openUnLinkModalHandler}>
                            {
                                this.state.showSendUnlinkOtp ? (
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5>{t("top-profile.unlink-google-email")}</h5>
                                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.openUnLinkModalHandler} />
                                        </div>
                                        <div className="modal-body table-responsive">
                                            <div>
                                                <p>{t("top-profile.unlink-google-description")}</p>
                                                <button className="btn btn-gradient-blue mt-3" onClick={(e) => this.onSubmitUnlinkGoogleHandler(e, googleLinkEmail)}>{t("top-profile.send")}</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : this.state.showEnterUnlinkOtp && (
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5>{t("top-profile.enter-otp-code")}</h5>
                                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.openUnLinkModalHandler} />
                                        </div>
                                        <div className="modal-body table-responsive">
                                            <div>
                                                {this.state.resend_email_status === false ? (
                                                    <p className="mb-3">
                                                        {t("top-profile.enter-email-otp-message")} {unlinkMaskEmail + unlinkEmailDomain}. {t("top-profile.enter-email-otp-message-cont")}
                                                    </p>
                                                ) : (
                                                    <p className="mb-3">
                                                        {t("top-profile.enter-email-otp-message")} {unlinkMaskEmail + unlinkEmailDomain}.{" "}
                                                        {t("top-profile.enter-email-otp-message-cont")}
                                                    </p>
                                                )}
                                                <ValidationError formName="email_otp_from" touched={this.state.touched} message={this.state.errorMsg} />

                                                <div className="d-flex mt-3">
                                                    <NumberInput
                                                        id="unlink_otp"
                                                        name="unlink_otp"
                                                        type="text"
                                                        className={`${this.state.errorMsg.otp_code && "was-validated is-invalid"}`}
                                                        // placeholder={t("profile.fullname-remark")}
                                                        value={this.state.formFilter.unlink_otp}
                                                        onChange={this.onInputChange}
                                                    // errorMsg={this.state.errorMsg.otp_code || ""}
                                                    // formError={this.state.formError}
                                                    // disabled={this.state.formFilter.set_full_name_status === true ? true : false}
                                                    />
                                                    <button className="btn btn-gradient-blue ms-3" style={{ minWidth: '7rem' }} onClick={(e) => this.verifyUnlinkGoogleOTPHandler(e, googleLinkEmail)} disabled={this.state.formFilter.unlink_otp === "" ? true : false}>{t("top-profile.verify")}</button>
                                                    <button className="btn btn-outline-blue ms-3" style={{ minWidth: '7rem' }} onClick={(e) => this.resendUnlinkGoogleHandler(e, googleLinkEmail)}>{t("top-profile.resend")}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Modal>

                        <section className="pf-dashboard">
                            <div className="x-container">
                                <ProfileSideBar />
                                <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                                    <div className="row g-3">
                                        <div className="col-9">
                                            <div className="pd-card-col">
                                                <h4>{t("page-header.personal-information")}</h4>
                                                <div className="pd-currency-col pi">
                                                    {this.state.formFilter.loading ? (
                                                        <>
                                                            <Skeleton count={1} height={45} width={45} circle={50} className="me-3" />
                                                            <Skeleton count={1} width={120} />
                                                            <span className="mx-4"></span>
                                                            <Skeleton count={1} width={120} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img className="inactive" src={icons["coin.svg"]} />
                                                            <div className="d-flex flex-column">
                                                                <p>
                                                                    {t("top-profile.coin", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}{" "}
                                                                    <b>
                                                                        {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                            (topProfileData != null && topProfileData.topProfile.coin) || 0
                                                                        )}
                                                                    </b>
                                                                    <Icon icon="uil:angle-right-b" />
                                                                </p>
                                                                <span className="my-learn-more" onClick={this.toggleCoinModal}>{t("global.learn-more")}</span>
                                                            </div>

                                                            <span className="mx-4"></span>
                                                            <p>
                                                                {t("top-profile.today-earning")}{" "}
                                                                <b className="tx-green">
                                                                    +
                                                                    {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                        (topProfileData != null && topProfileData.topProfile.today_coin) || 0
                                                                    )}
                                                                </b>
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="pd-content bordered">
                                                    <div className="pi-sec-lvl-col">
                                                        <div className="pi-sec-lvl-content">
                                                            <p>{t("profile.account-security-level")}:</p>
                                                            {this.state.formFilter.loading ? (
                                                                <Skeleton count={1} width={200} />
                                                            ) : (
                                                                this.state.formFilter.set_basic_info === true &&
                                                                    this.state.bindingMobilePhoneStatus === true &&
                                                                    this.state.bindingEmailStatus === false ? (
                                                                    <div className="pi-sec-lvl-bar normal">
                                                                        <p>{t("register.normal")}</p>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                ) : this.state.formFilter.set_basic_info === true &&
                                                                    this.state.bindingEmailStatus === true &&
                                                                    this.state.bindingMobilePhoneStatus === true ? (
                                                                    <div className="pi-sec-lvl-bar strong">
                                                                        <p>{t("register.strong")}</p>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="pi-sec-lvl-bar low">
                                                                        <p>{t("register.low")}</p>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        <p>{t("profile.account-security-level-remark")}</p>
                                                    </div>
                                                    <hr />
                                                    <div className="pi-progress-col">
                                                        <ul className="navbar-nav">
                                                            {this.state.formFilter.loading ? (
                                                                <>
                                                                    <li className="nav-item active">
                                                                        <Skeleton count={1} height={40} width={40} circle={50} className="me-2" />
                                                                        <Skeleton count={1} width={120} />
                                                                    </li>
                                                                    <li className="nav-item active">
                                                                        <Skeleton count={1} height={40} width={40} circle={50} className="me-2" />
                                                                        <Skeleton count={1} width={120} />
                                                                    </li>
                                                                    <li className="nav-item active">
                                                                        <Skeleton count={1} height={40} width={40} circle={50} className="me-2" />
                                                                        <Skeleton count={1} width={120} />
                                                                    </li>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <li className="nav-item active">
                                                                        <img
                                                                            className="active"
                                                                            src={this.state.formFilter.set_basic_info === true ? icons["sc-bi-active-ico.svg"] : icons["sc-bi-ico.svg"]}
                                                                            alt={this.state.formFilter.set_basic_info === true ? "sc-bi-active-ico" : "sc-bi-ico"}
                                                                        />
                                                                        <p>{t("profile.fill-in-basic-information")}</p>
                                                                    </li>
                                                                    <li className="nav-item active">
                                                                        <img
                                                                            className="active"
                                                                            src={this.state.bindingMobilePhoneStatus === true ? icons["sc-mp-active-ico.svg"] : icons["sc-mp-ico.svg"]}
                                                                            alt={this.state.bindingMobilePhoneStatus === true ? "sc-mp-active-ico" : "sc-mp-ico"}
                                                                        />
                                                                        <p>{t("profile.binding-account-to-mobile-phone")}</p>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <img
                                                                            className="inactive"
                                                                            src={this.state.bindingEmailStatus === true ? icons["sc-mail-active-ico.svg"] : icons["sc-mail-ico.svg"]}
                                                                            alt={this.state.bindingEmailStatus === true ? "sc-mail-active-ico" : "sc-mail-ico"}
                                                                        />
                                                                        <p>{t("profile.binding-account-to-email")}</p>
                                                                    </li>
                                                                    {/* <li className="nav-item"> */}
                                                                    {/* {this.state.addBankStatus === false ? (
                                                                    <Link to="/my-wallet">
                                                                    <img
                                                                        className="inactive"
                                                                        src={icons["sc-pin-ico.svg"]}
                                                                        alt={"sc-pin-ico"}
                                                                    />
                                                                    </Link>
                                                                ) : ( */}
                                                                    {/* <img
                                                                    className="inactive"
                                                                    src={this.state.addSecurityPinStatus === true ? icons["sc-pin-active-ico.svg"] : icons["sc-pin-ico.svg"]}
                                                                    alt={this.state.addSecurityPinStatus === true ? "sc-pin-active-ico" : "sc-pin-ico"}
                                                                /> */}
                                                                    {/* )} */}
                                                                    {/* <p>{t("profile.security-pin")}</p> */}
                                                                    {/* </li> */}
                                                                </>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pd-card-col">
                                                <h6 className="mb-2">{t("profile.third-party-acc-authorization")}</h6>
                                                <small>{t("profile.third-party-acc-authorization-desc", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</small>
                                                <div className="pd-content">
                                                    <ul className="third-party-list d-grid gap-2">
                                                        {this.state.refresh ? (
                                                            <Skeleton count={1} width={200} className="my-auto" />
                                                        ) : (
                                                            <>
                                                                <li>
                                                                    <div>
                                                                        <img src={thirdParty["icon-google.webp"]} alt="google" />
                                                                        <p>{t("global.google")}</p>
                                                                        {/* {googleStatusClass === "active" ? <small>{t("profile.third-party-linked")}</small> : <small>{t("profile.third-party-unlinked")}</small>} */}
                                                                    </div>
                                                                    <div>
                                                                        {googleStatusClass === "active" ?
                                                                            <>
                                                                                {/* <span className="note-text pe-2">{t("profile.linked")}</span> */}
                                                                                <button className="btn btn-outline-blue" onClick={this.openUnLinkModalHandler}>
                                                                                    {t("profile.unlinked")}
                                                                                </button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <button className="btn btn-gradient-blue" onClick={this.handleGoogleRedirect}>
                                                                                    {t("profile.link")}
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </div>

                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <img src={thirdParty["icon-telegram.webp"]} alt="telegram" />
                                                                        <p>{t("global.telegram")}</p>
                                                                    </div>
                                                                    {telegramStatusClass === "active" ?
                                                                        <div>
                                                                            {/* <span className="note-text pe-2">{t("profile.linked")}</span> */}
                                                                            <button className="btn btn-outline-blue" onClick={(e) => this.unlinkTelegramHandler(e)}>
                                                                                {t("profile.unlinked")}
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <button className="btn btn-gradient-blue" onClick={this.handleTelegramRedirect}>{t("profile.link")}</button>
                                                                        </>
                                                                    }
                                                                </li>
                                                            </>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="pd-card-col">
                                                <h6>{t("profile.basic-information")}</h6>
                                                <div className="pd-content">
                                                    <form
                                                        className="forms-col"
                                                        method="post"
                                                        name="basic_info_from"
                                                        id="basic_info_from"
                                                        onSubmit={this.postUpdateMemberProfile}
                                                        noValidate
                                                    >
                                                        {/* <ValidationError formName="basic_info_from" touched={this.state.touched} message={this.state.error_message} /> */}
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.fullname")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {(() => {
                                                                    if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === false) {
                                                                        return (
                                                                            <StringInput
                                                                                id="full_name"
                                                                                name="full_name"
                                                                                type="text"
                                                                                className={`${this.state.errorMsg.full_name && "was-validated is-invalid"}`}
                                                                                placeholder={t("profile.fullname-remark")}
                                                                                value={this.state.formFilter.full_name}
                                                                                onChange={this.onInputChange}
                                                                                required
                                                                                requiredMessage={t("validation.required")}
                                                                                errorMsg={this.state.errorMsg.full_name || ""}
                                                                                formError={this.state.formError}
                                                                                disabled={this.state.formFilter.set_full_name_status === true ? true : false}
                                                                            />
                                                                        );
                                                                    } else if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === true) {
                                                                        return <p>{this.state.formFilter.loading ? null : this.state.formFilter.full_name}</p>;
                                                                    } else {
                                                                        return <Skeleton count={1} width={200} />;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.gender")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {(() => {
                                                                    if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === false) {
                                                                        return (
                                                                            <>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="btn-check"
                                                                                        type="radio"
                                                                                        id="gender"
                                                                                        name="genderOptions"
                                                                                        value="M"
                                                                                        onChange={this.onInputChange}
                                                                                        required="required"
                                                                                        // checked
                                                                                        checked={this.state.formFilter.gender === "M" ? true : false}
                                                                                    />
                                                                                    <label className="btn form-check-label" htmlFor="gender">
                                                                                        {t("profile.male")}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input
                                                                                        className="btn-check"
                                                                                        type="radio"
                                                                                        id="genderFemale"
                                                                                        name="genderOptions"
                                                                                        value="F"
                                                                                        onChange={this.onInputChange}
                                                                                        required="required"
                                                                                        checked={this.state.formFilter.gender === "F" ? true : false}
                                                                                    />
                                                                                    <label className="btn form-check-label" htmlFor="genderFemale">
                                                                                        {t("profile.female")}
                                                                                    </label>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    } else if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === true) {
                                                                        return <p>{this.state.formFilter.loading ? null : (this.state.formFilter.gender) === "M" ? t("profile.male") : t("profile.female")}</p>;
                                                                    } else {
                                                                        return <Skeleton count={1} width={200} />;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.date-of-birth")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {(() => {
                                                                    if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === false) {
                                                                        return (
                                                                            <>
                                                                                <div className="input-group right">
                                                                                    <DateRangePicker
                                                                                        selected="2004/04"
                                                                                        initialSettings={{
                                                                                            singleDatePicker: true,
                                                                                            autoUpdateInput: false,
                                                                                            // minYear: 1901,
                                                                                            // maxYear: parseInt(moment().format("YYYY"), 10),
                                                                                            showDropdowns: true,
                                                                                            startDate: maxDate,
                                                                                            minDate: minDate,
                                                                                            maxDate: maxDate,
                                                                                            locale: {
                                                                                                cancelLabel: t("global.clear"),
                                                                                                applyLabel: t("global.apply"),
                                                                                            },
                                                                                        }}
                                                                                        onApply={handleApply}
                                                                                        onCancel={handleCancel}
                                                                                    >
                                                                                        <input type="text" className="form-control" defaultValue="" required />
                                                                                    </DateRangePicker>
                                                                                    <span className="input-group-text">
                                                                                        <Icon icon="uil:calendar-alt" />
                                                                                    </span>
                                                                                    <br />
                                                                                </div>
                                                                                <small className="caution-text">{this.state.errorMsg.date_of_birth}</small>
                                                                            </>
                                                                        );
                                                                    } else if (!this.state.formFilter.loading && this.state.formFilter.set_basic_info === true) {
                                                                        return (
                                                                            <p>{this.state.formFilter.loading ? null : moment(this.state.formFilter.dates).format("yyyy-MM-DD")} {this.state.formFilter.loading ? null : birthdayCountdown}</p>
                                                                        );
                                                                    } else {
                                                                        return <Skeleton count={1} width={200} />;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.registration-date")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {(() => {
                                                                    if (!this.state.formFilter.loading && this.state.formFilter.register_date === "") {
                                                                        return (
                                                                            <>
                                                                                <input
                                                                                    type="email"
                                                                                    className="form-control disabled"
                                                                                    value={this.state.formFilter.register_date}
                                                                                    disabled
                                                                                />
                                                                            </>
                                                                        );
                                                                    } else if (!this.state.formFilter.loading && this.state.formFilter.register_date !== "") {
                                                                        return <p>{this.state.formFilter.loading ? null : this.state.formFilter.register_date}</p>;
                                                                    } else {
                                                                        return <Skeleton count={1} width={200} />;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.address")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {(!this.state.formFilter.loading)
                                                                    ?
                                                                    <div className="d-flex justify-content-between">
                                                                        {(this.state.formFilter.address && this.state.formFilter.address.full_address)
                                                                            ?
                                                                            <div className="verified-col">
                                                                                <p>
                                                                                    {this.state.formFilter.address.full_address}
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="verified-col">
                                                                                    <p> - </p>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-outline-blue"
                                                                                type="button"
                                                                                onClick={this.toggleAddressModal}
                                                                            >
                                                                                {this.state.formFilter.address && this.state.formFilter.address.full_address ? t("global.edit") : t("crypto-address-details.add-withdrawal-address")}

                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <Skeleton count={1} width={200} />
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <label className="col-3 col-form-label">Address: </label>
                                                            <div className="col-9 align-self-center">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="verified-col">
                                                                        <p>
                                                                            {addressStatusClass === "active"
                                                                                ? "no1, taman no 1, kuala lumpur"
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            className="btn btn-outline-blue"
                                                                            type="button"
                                                                            onClick={this.toggleAddressModal}
                                                                        >
                                                                            {addressStatusClass === "" ? <>{t("global.edit")}</> : <>{t("crypto-address-details.add-withdrawal-address")}</>}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div> */}
                                                        {!this.state.formFilter.set_basic_info && (
                                                            <div className="row">
                                                                <div className="col-9 offset-3">
                                                                    <button className="btn btn-gradient-blue w-100" type="submit" value="Submit">
                                                                        {t("profile.done")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>

                                            {businessAccountStatus === true && (
                                                <div className="pd-card-col">
                                                    <h6 className="mb-2">{t("profile.corporate-info")}</h6>
                                                    <small>
                                                        {t("profile.corporate-info-desc1")}
                                                        <span onClick={window['liveChatButton']}>{t("global.customer-service")}</span>
                                                        {t("profile.corporate-info-desc2")}
                                                    </small>
                                                    <div className="pd-content mt-2">
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.comp-name")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {
                                                                    this.state.companyName ? (
                                                                        <p>{this.state.companyName}</p>
                                                                    ) : (
                                                                        <Skeleton count={1} width={200} />
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.comp-reg-no")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {
                                                                    this.state.companyRegNo ? (
                                                                        <p>{this.state.companyRegNo}</p>
                                                                    ) : (
                                                                        <Skeleton count={1} width={200} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.bank-acc-no")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {
                                                                    this.state.bankAccNo ? (
                                                                        <p>{this.state.bankAccNo}</p>
                                                                    ) : (
                                                                        <Skeleton count={1} width={200} />
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.ssm-no")}: </label>
                                                            <div className="col-9 align-self-center">
                                                                {
                                                                    this.state.ssmImage ? (
                                                                        <p className="view-image" onClick={() => this.setState({ openImageModal: true })} >{t("global.view-image")}</p>
                                                                    ) : (
                                                                        <Skeleton count={1} width={200} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="pd-card-col">
                                                <h6>{t("profile.account-security")}</h6>
                                                <div className="pd-content">
                                                    <form className="forms-col" method="post">
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.mobile-phone-no")}:</label>
                                                            <div className="col-9 align-self-center">
                                                                {this.state.refresh ? (
                                                                    <Skeleton count={1} width={200} className="my-auto" />
                                                                ) : (
                                                                    <>
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="verified-col">
                                                                                <p>
                                                                                    {mobileStatusClass === "active"
                                                                                        ? this.state.formFilter.verified_contact_no
                                                                                        : t("profile.unverified")}
                                                                                </p>
                                                                                {/* {mobileStatusClass === "active" && <p className="verified-label">({t("profile.verified")})</p>} */}
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                {mobileStatusClass === "active" && <span className="note-text me-2">{t("profile.verified")}</span>}
                                                                                <button
                                                                                    className="btn btn-outline-blue"
                                                                                    type="button"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#verificationModal"
                                                                                    onClick={() => this.verificationModalHandler("verification-phone")}
                                                                                >
                                                                                    {mobileStatusClass === "" ? <>{t("profile.verify-now")}</> : <>{t("global.edit")}</>}
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.email-address")}:</label>
                                                            <div className="col-9 align-self-center">
                                                                {this.state.refresh ? (
                                                                    <Skeleton count={1} width={200} />
                                                                ) : (
                                                                    <>
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="verified-col">
                                                                                {/* <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                placeholder={emailStatusClass === "active" ? t("profile.verified") : t("profile.unverified")}
                                                                                value=""
                                                                                disabled
                                                                              /> */}
                                                                                <p>
                                                                                    {emailStatusClass === "active" ? this.state.formFilter.verified_email : t("profile.unverified")}
                                                                                </p>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                {emailStatusClass && <span className="note-text me-2">{t("profile.verified")}</span>}
                                                                                <button
                                                                                    className="btn btn-outline-blue"
                                                                                    type="button"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#verificationModal"
                                                                                    onClick={() => this.verificationModalHandler("verification-email")}
                                                                                >
                                                                                    {emailStatusClass === "" ? <>{t("profile.verify-now")}</> : <>{t("global.edit")}</>}
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.account-password")}:</label>
                                                            <div className="col-9 align-self-center">
                                                                <div className="d-flex justify-content-between">
                                                                    {this.state.refresh ? (
                                                                        <Skeleton count={1} width={200} />
                                                                    ) : (topProfileData !== null && (topProfileData.topProfile.google_id || topProfileData.topProfile.telegram_id)) ?
                                                                        <>
                                                                            <div className="verified-col">
                                                                                <p>*********</p>
                                                                            </div>
                                                                            <button
                                                                                className="btn btn-outline-blue"
                                                                                type="button"
                                                                                onClick={this.socialMediaNoPasswordHandler}
                                                                            >
                                                                                {t("global.edit")}
                                                                            </button>
                                                                        </>
                                                                        : (
                                                                            <>
                                                                                <div className="verified-col">
                                                                                    {/* <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        placeholder="*********"
                                                                        value=""
                                                                        autoComplete="off"
                                                                        disabled
                                                                      /> */}
                                                                                    <p>*********</p>
                                                                                </div>
                                                                                <button
                                                                                    className="btn btn-outline-blue"
                                                                                    type="button"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#verificationModal"
                                                                                    onClick={() => this.verificationModalHandler("verification-acc-pwd")}
                                                                                >
                                                                                    {t("global.edit")}
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="col-3 col-form-label">{t("profile.security-pin")}:</label>
                                                            <div className="col-9">
                                                                <div className="d-flex justify-content-between">
                                                                    {this.state.refresh ? (
                                                                        <Skeleton count={1} width={200} />
                                                                    ) : (
                                                                        <>
                                                                            {securityPinStatusClass === "" ? (
                                                                                <>
                                                                                    <div className="verified-col">
                                                                                        <p>{t("global.not-set")}</p>
                                                                                    </div>
                                                                                    <button
                                                                                        className="btn btn-outline-blue"
                                                                                        type="button"
                                                                                        // data-bs-toggle="modal"
                                                                                        // data-bs-target="#securityPinModal"
                                                                                        onClick={() => this.securityPinModalHandler("set-sec-pin")}
                                                                                    >
                                                                                        {t("global.set-now")}
                                                                                    </button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="verified-col w-auto">
                                                                                        <p>{t("global.set")}</p>
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <button
                                                                                            className="btn btn-outline-red me-2"
                                                                                            type="button"
                                                                                            // data-bs-toggle="modal"
                                                                                            // data-bs-target="#securityPinModal"
                                                                                            onClick={() => this.securityPinModalHandler("remove-sec-pin")}
                                                                                        >
                                                                                            {t("global.remove")}
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-outline-blue"
                                                                                            type="button"
                                                                                            // data-bs-toggle="modal"
                                                                                            // data-bs-target="#securityPinModal"
                                                                                            onClick={() => this.securityPinModalHandler("edit-sec-pin")}
                                                                                        >
                                                                                            {t("global.edit")}
                                                                                        </button>
                                                                                    </div>

                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="pd-card-col d-flex flex-column">
                                                <div className="pd-header-more">
                                                    <h6>{t("profile.betting-history")}</h6>
                                                    <Link className="float-end" to={{ pathname: "/betting-history" }}>
                                                        {t("profile.more")} <Icon icon="uil:angle-right" />
                                                    </Link>
                                                </div>
                                                <BettingHistory 
                                                    latestBetData={this.state.latestBetData}
                                                    loading={this.state.formFilter.loading}
                                                />
                                            </div>
                                            <div className="pd-card-col d-flex flex-column">
                                                <div className="pd-header-more">
                                                    <h6>{t("profile.banking-details")}</h6>
                                                    <Link className="float-end" to={{ pathname: "/my-wallet" }}>
                                                        {t("profile.more")} <Icon icon="uil:angle-right" />
                                                    </Link>
                                                </div>
                                                <BankingDetails 
                                                    memberBankData={this.state.memberBankData}
                                                    loading={this.state.formFilter.loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Reveal>
                            </div>
                        </section>
                    </>
                ) : (
                    <MobilePersonalInformation
                        {...this.state}
                        onFullNameInputChangeHandler={this.onInputChange}
                        securityPinStatusClass={securityPinStatusClass}
                        verificationModalHandler={this.verificationModalHandler}
                        securityPinModalHandler={this.securityPinModalHandler}
                        onChangeDateHandler={this.onChangeDateHandler}
                        onChangeGenderHandler={this.onChangeGenderHandler}
                        postUpdateMemberProfile={this.postUpdateMemberProfile}
                        socialMediaNoPasswordHandler={this.socialMediaNoPasswordHandler}
                        toggleCoinModal={this.toggleCoinModal}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;
    return {
        topProfileData: profile.top_profile_data,
        memberProfileData: profile.member_profile_data,
        memberProfileUpdateResult: profile.member_profile_update_result,
        google_redirect_result: profile.google_redirect_result,
        unlink_google_result: profile.unlink_google_result,
        generate_email_otp_result: profile.generate_email_otp_result,
        verify_email_otp_result: profile.verify_email_otp_result,
        resend_email_otp_result: profile.resend_email_otp_result,
        unlink_telegram_result: profile.unlink_telegram_result,
        get_business_account_result: profile.get_business_account_result,
        get_personal_info: profile.get_personal_info,
        currentUser: state.currentUser.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopProfile: () => dispatch(getTopProfile()),
        getMemberProfile: () => dispatch(getMemberProfile()),
        updateMemberProfile: (data) => dispatch(updateMemberProfile(data)),
        redirectGoogle: (data) => dispatch(redirectGoogle(data)),
        unlinkGoogle: (data) => dispatch(unlinkGoogle(data)),
        generateEmailOTP: (data) => dispatch(generateEmailOTP(data)),
        verifyEmailOTP: (data) => dispatch(verifyEmailOTP(data)),
        resendEmailOTP: (data) => dispatch(resendEmailOTP(data)),
        unlinkTelegram: (data) => dispatch(unlinkTelegram(data)),
        getBusinessAccount: () => dispatch(getBusinessAccount()),
        getPersonalInfo: (data) => dispatch(getPersonalInfo(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PersonalInformation));
