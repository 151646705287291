import React, {Component, Fragment} from 'react';
import {required, validateDisplay} from "../../hoc/Shared/utility";
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { withNamespaces } from 'react-i18next';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import './bootstrap.datetimepicker.css';

class Datepicker extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            value: '',
            requiredRules: required(false, this.props.value, this.props.requiredMessage)
        }
    }

    static getDerivedStateFromProps (nextProps, prevState)
    {
        if (nextProps.value !== prevState.value)
        {
            let payload =
            {
                value: nextProps.value,
            };
            //Set to the payload when validation is true or not and set the init to true start validation
            if(nextProps.required)
            {
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }
            return payload;
        }
        return null;
    }

    handleApply = (event, picker) =>
    {
        // console.log('handleApply :' + picker.startDate+' | '+picker.endDate);
        // let timeVal = (picker.timePicker) ? ' hh:mm A' : '';
        let dateVal = (picker.locale.format) ? picker.locale.format : '';
        let dateTimeVal = (picker.singleDatePicker) ? picker.startDate.format(dateVal) : picker.startDate.format(dateVal) + ' - ' + picker.endDate.format(dateVal).replace("00 ", "59 ");

        picker.element.val(
            dateTimeVal
        );
        this.props.onChange(event);
    }

    handleCancel = (event, picker) =>
    {
        picker.element.val('');
        this.props.onChange(event);
    }

    render()
    {
        // console.log('render :', this.state);
        const { t } = this.props;

        const {id, name, onChange, className, defaultValue, disabled, required, placeholder, autoComplete, range, singleDatePicker, timePicker, format, autoUpdateInput, minDate, maxDate} = this.props;

        const {value, requiredRules} = this.state;

        //Export the validation classes and message
        const [ requiredClass, requiredMsg ] = validateDisplay(requiredRules);

        let keyRef = Date.now();
        let rangeVal = (range) ? {
            [t('datepicker.today')]: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
            [t('datepicker.yesterday')]: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()],
            [t('datepicker.last-7-days')]: [moment().subtract(6, 'days').startOf('day'), moment()],
            [t('datepicker.last-30-days')]: [moment().subtract(29, 'days').startOf('day'), moment()],
            [t('datepicker.last-month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            [t('datepicker.last-quarter')]: [moment().subtract(3, 'month').startOf('month'), moment()],
            [t('datepicker.last-year')]: [moment().subtract(1, 'years').startOf('day'), moment()],
            [t('datepicker.last-3-years')]: [moment().subtract(3, 'years').startOf('day'), moment()],
        } : false;
        let singleDatePickerVal = (singleDatePicker) ? true : false;
        let timePickerVal = (timePicker) ? true : false;
        let timeformatVal = (timePicker) ? ' hh:mm:ss A' : '';
        let formatVal = (format) ? format : 'YYYY/MM/DD';
        let autoUpdateInputVal = (autoUpdateInput) ? true : false;
        let minDateVal = (minDate) ? minDate : moment().subtract(30,'years');
        let maxDateVal = (maxDate) ? maxDate : false;

        return (
            <Fragment>
                <DateRangePicker
                    key={keyRef}
                    initialSettings=
                    {{
                        singleDatePicker: singleDatePickerVal,
                        timePicker: timePickerVal,
                        alwaysShowCalendars: true,
                        linkedCalendars: false,
                        showCustomRangeLabel: false,
                        showDropdowns: true,
                        autoUpdateInput: autoUpdateInputVal,
                        autoApply: true,
                        // startDate: moment().startOf('hour').subtract(7,'days'),
                        // endDate: moment(),
                        // minDate: moment().subtract(30,'years'),
                        // maxDate: moment(),
                        minDate: minDateVal,
                        maxDate: maxDateVal,
                        ranges: rangeVal,
                        locale:
                        {
                            // format: 'YYYY/MM/DD hh:mm A',
                            format: formatVal+timeformatVal,
                            cancelLabel: t('global.clear'),
                            applyLabel: t('global.apply'),
                        },
                        cancelClass: 'btn btn-secondary',
                    }}
                    onApply={this.handleApply}
                    onCancel={this.handleCancel}
                  >
                    <input
                        type="text"
                        id={id}
                        name={name}
                        className={className}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        disabled={disabled}
                        required={required}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                    />
                </DateRangePicker>

                {/*Display the message*/}
                <div className="invalid-feedback">
                    {requiredClass !== 'is-valid' && requiredMsg}
                </div>
            </Fragment>
        )
    }
}

export default withNamespaces('translation')(Datepicker);

Datepicker.propTypes =
{
    id:         PropTypes.string.isRequired,
    name:       PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,

    defaultValue:   PropTypes.string,
    value:          PropTypes.string,
    className:      PropTypes.string,
    disabled:       PropTypes.bool,
    required:       PropTypes.bool,
    placeholder:    PropTypes.string,
    autoComplete:   PropTypes.string,
    requiredMessage: PropTypes.string,
};

Datepicker.defaultProps =
{
    className: ''
};