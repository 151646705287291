import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import Gauge from "../../../components/Gauge/Gauge";
import { LazyLoadImage } from "react-lazy-load-image-component";

export class ServiceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };
  render() {
    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    const { t } = this.props;
    const homeImg = this.imageImport(
      require.context(
        "../../../assets/images/home/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    // const videos = this.imageImport(
    //   require.context(
    //     "../../../assets/images/videos/",
    //     false,
    //     /\.(png|jpe?g|svg|mp4)$/
    //   )
    // );
    const christmas = this.imageImport(
      require.context(
        "../../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const chinese = this.imageImport(
      require.context(
        "../../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <section className={`service-section ${this.props.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ? "merdeka" : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? "mooncake" : this.props.currentEvent ==="halloween" ? "halloween" : this.props.currentEvent ==="raya" ? "raya" : this.props.currentEvent ==="cny" ? "cny" : this.props.currentEvent && this.props.currentUser.country_id !== 4 ? "event" : ""}` }>
        <div className="x-container">
          <div
            className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
              }`}
          >
            <div className="d-flex align-item-center">
            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) :(
                                <></>
                            )}
                            {this.props.currentEvent === "cny" ?  <h2>{t("main-page.excellent-service")}</h2> :  <h2 className="mb-2">{t("main-page.excellent-service")}</h2> }
                            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : (
                                <></>
                            )}             
            </div>

            <p>{t("main-page.excellent-service-remark")}</p>
          </div>

          <div className="gauge-col mb-5">
            <div className="row">
              <div className="col-3 position-relative">
                <Gauge value="60" />
                <div className="gauge-wrapper">
                  <LazyLoadImage
                    className="gauge-img"
                    src={homeImg["gauge.webp"]}
                    alt="gauge"
                    effect="blur"
                  />
                  <div className="gauge-content">
                    <div className="gauge-body">
                      <p>{t("main-page.current-speed")}</p>
                      <p>{t("main-page.sec")}</p>
                    </div>
                  </div>
                </div>
                <h5>{t("main-page.averages-time-deposit")}</h5>
              </div>
              <div className="col-3 position-relative">
                <Gauge value="80" />
                <div className="gauge-wrapper">
                  <LazyLoadImage
                    className="gauge-img"
                    src={homeImg["gauge.webp"]}
                    alt="gauge"
                    effect="blur"
                  />
                  <div className="gauge-content">
                    <div className="gauge-body">
                      <p>{t("main-page.total-amount")}</p>
                      <p>{t("main-page.platform")}</p>
                    </div>
                  </div>
                </div>
                <h5>{t("main-page.payment-platform-partners")}</h5>
              </div>
              <div className="col-3 position-relative">
                <Gauge value="90" />
                <div className="gauge-wrapper">
                  <LazyLoadImage
                    className="gauge-img"
                    src={homeImg["gauge.webp"]}
                    alt="gauge"
                    effect="blur"
                  />
                  <div className="gauge-content">
                    <div className="gauge-body">
                      <p>{t("main-page.total-amount")}</p>
                      <p>{t("main-page.sec")}</p>
                    </div>
                  </div>
                </div>
                <h5>{t("main-page.averages-time-withdraw")}</h5>
              </div>
              <div className="col-3 position-relative">
                <Gauge value="23" />
                <div className="gauge-wrapper">
                  <LazyLoadImage
                    className="gauge-img"
                    src={homeImg["gauge.webp"]}
                    alt="gauge"
                    effect="blur"
                  />
                  <div className="gauge-content">
                    <div className="gauge-body">
                      <p>{t("main-page.total-amount")}</p>
                      <p>{t("main-page.platform")}</p>
                    </div>
                  </div>
                </div>
                <h5>{t("main-page.gaming-provider-partners")}</h5>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-3 position-relative">
              {this.props.currentEvent === "raya" && (
                <img
                  className="service-deco-1"
                  src={raya["content-deco-4.webp"]}
                  alt="decoration"
                />
              )}
               {this.props.currentEvent === "halloween" && (
                <img
                className="halloween-deco-3"
                src={halloween["content-deco-3.webp"]}
                alt="content-deco-3"
              />
              )}
              <div className="service-section-content">
                {
                  this.props.currentEvent === "raya" ? (
                    <LazyLoadImage
                      src={raya["professional.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  ) : (
                    <LazyLoadImage
                      src={homeImg["professional.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  )
                }
                <h4>{t("main-page.professional")}</h4>
                <p>{t("main-page.professional-content", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
              </div>
            </div>

            <div className="col-3 position-relative">
              <div className="service-section-content">
                {
                  this.props.currentEvent === "raya" ? (
                    <LazyLoadImage
                      src={raya["safety.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  ) : (
                    <LazyLoadImage
                      src={homeImg["safety.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  )
                }

                <h4>{t("main-page.safety")}</h4>
                <p>{t("main-page.safety-content")}</p>
              </div>
            </div>

            <div className="col-3 position-relative">
              <div className="service-section-content">
                {
                  this.props.currentEvent === "raya" ? (
                    <LazyLoadImage
                      src={raya["convenient.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  ) : (
                    <LazyLoadImage
                      src={homeImg["convenient.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  )
                }
                <h4>{t("main-page.convenient")}</h4>
                <p>{t("main-page.convenient-content")}</p>
              </div>
            </div>

            <div className="col-3 position-relative">
              {this.props.currentEvent === "raya" && (
                <>
                  <img
                    className="service-deco-2"
                    src={raya["content-deco-5.webp"]}
                    alt="decoration"
                  />
                </>
              )}
              {/* {this.props.currentEvent === "christmas" ? (
                <>
                  <img
                    className="deco-5"
                    src={christmas["content-deco-4.webp"]}
                    alt="content-deco-4"
                  />
                  <img
                    className="service-border-image"
                    src={christmas["service-christmas.webp"]}
                    // alt="border-image"
                    alt="border"
                  />
                </>
              ) : (
                <></>
              )} */}
              <div className="service-section-content">
                {
                  this.props.currentEvent === "raya" ? (
                    <LazyLoadImage
                      src={raya["speed.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  ) : (
                    <LazyLoadImage
                      src={homeImg["speed.webp"]}
                      alt="excellet-service-bg"
                      effect="blur"
                    />
                  )
                }

                <h4>{t("main-page.speed")}</h4>
                <p>{t("main-page.speed-content")}</p>
              </div>
            </div>


          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(
  withNamespaces("translation")(ServiceSection)
);
