import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const Button = (props) => (
    <Fragment>
        <button
            type={props.typeButton}
            className={props.classValue}
            style={props.style}
            id={props.idValue}
            onClick={props.clicked}
            disabled={props.disabled}
            form={props.formValue}
        >{props.buttonName}</button>
    </Fragment>
);

export default Button;

Button.propTypes = {
    typeButton: PropTypes.string.isRequired,
    classValue: PropTypes.string.isRequired,
    idValue:    PropTypes.any,
    buttonName: PropTypes.any,
    clicked:    PropTypes.func,
    disabled:    PropTypes.bool
}