import React, { Component } from "react";

import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
// import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";

//Components
// import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";

//Actions
import * as actions from "../../../store/ducks/deposit/actions";
import MobileSelectRadio from "../../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";
import CryptoDepositModal from "../../../components/CryptoDepositModal/CryptoDepositModal";
import { formValidation } from "../../../hoc/Shared/utility";
export class MobileCrypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelectRadio: false,
            // openCryptoDepositModal: false,
        };
    }

    componentDidMount() {
        formValidation("deposit_form");
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleSelectRadio = () => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const cryptoImg = this.imageImport(require.context("../../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));
        return (
            <>
                <PromosTncModal {...this.state} {...this.props} toggleTncModalHandler={this.props.toggleTncModalHandler} />

                <CryptoDepositModal {...this.props} {...this.state} toggleCryptoDepositModalHandler={this.props.toggleCryptoDepositModalHandler} />

                <form
                    className="forms-col needs-validation"
                    // method="post"
                    id="deposit_form"
                    onSubmit={this.props.confirmHandler}
                    noValidate
                >
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                {/* <div>
                                    <label className="col-form-label">{t("deposit.payment-option")}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.paymentGateway.length > 0 ? (
                                                this.props.paymentGateway.map((val, idx) => {
                                                    if (val.transaction_slug === "crypto" && val.dp_status === 1 && val.title !== "Crypto") {
                                                        return (
                                                            <div className="form-check form-check-inline four-check">
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`paymentdirepay`}
                                                                    value={"direpay"}
                                                                    checked={this.props.paymentGatewaySelected === "direpay" ? true : false}
                                                                    onChange={() => {}}
                                                                />
                                                                <label
                                                                    className="btn form-check-label"
                                                                    htmlFor={`paymentdirepay`}
                                                                    onClick={() => this.props.selectPaymentGatewayHandler("direpay")}
                                                                >
                                                                    <img className="active" src={cryptoImg[`direpay.svg`]} alt={"direpay"} />
                                                                </label>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                this.props.isOptionLoading === false ?
                                                <span className="text-danger">{t("deposit.invalid-payment-gateway")}</span> :
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                                <div>
                                    <label className="col-form-label">{t('deposit.payment-option')}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.paymentGateway.length > 0 ? (
                                                this.props.paymentGateway.map((val, idx) => {
                                                    if (val.transaction_slug === "crypto" && val.dp_status === 1) {
                                                        return (
                                                            <div className="form-check form-check-inline four-check" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`payment${val.slug}`}
                                                                    value={val.slug}
                                                                    checked={this.props.paymentGatewaySelected === val.slug ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.props.selectPaymentGatewayHandler(val)}>
                                                                    <img className="active" src={this.props.payment[`${val.slug}.svg`]} alt={val.slug} />
                                                                    <p>{val.slug.replaceAll('_', ' ')}</p>
                                                                    {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant-deposit")}
                                                                        </>
                                                                        ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                        </>
                                                                        )} 
                                                                    </div>                 
                                                                )}
                                                                </label>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                this.props.isOptionLoading === false ?
                                                <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("withdrawal.select-channel")}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                        {this.props.paymentGatewaySelected === "direpay" && this.props.cryptoChannel.length > 0 ? (
                                            this.props.cryptoChannel.map((val, idx) => {
                                                return (
                                                    <div className="form-check form-check-inline four-check" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="bank_id"
                                                            id={`crypto${val.label}`}
                                                            value={val.label}
                                                            checked={this.props.channel === val.label ? true : false}
                                                        />
                                                        <label
                                                            className="btn form-check-label"
                                                            htmlFor={`crypto${val.label}`}
                                                            onClick={() => this.props.selectChannel(val.label, val.id)}
                                                        >
                                                            <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                            <p>{val.label}</p>
                                                            {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                <div className="transfer-duration">
                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                    Deposit in 1 min */}
                                                                    {val.deposit_est_time === "Instant" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant-deposit")}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                {t("deposit.deposit-in", { minute: val.deposit_est_time })} {t("deposit.min")}
                                                                        </>
                                                                    )}
                                                                    </div>                 
                                                            )}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        ) : this.props.paymentGatewaySelected === "secret_pay" && this.props.spCryptoChannel.length > 0 ? (
                                            this.props.spCryptoChannel.map((val, idx) => {
                                                return (
                                                    <div className="form-check form-check-inline four-check" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="bank_id"
                                                            id={`crypto${val.label}`}
                                                            value={val.label}
                                                            checked={this.props.channel === val.label ? true : false}
                                                        />
                                                        <label
                                                            className="btn form-check-label"
                                                            htmlFor={`crypto${val.label}`}
                                                            onClick={() => this.props.selectChannel(val.label, val.id, val.type, val.coinCode)}
                                                        >
                                                            <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                            <p>{val.label}</p>
                                                            {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                <div className="transfer-duration">
                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                    Deposit in 1 min */}
                                                                    {val.deposit_est_time === "Instant" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant-deposit")}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                {t("deposit.deposit-in", { minute: val.deposit_est_time })} {t("deposit.min")}
                                                                        </>
                                                                    )}
                                                                    </div>                 
                                                            )}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            this.props.isOptionLoading === false ?
                                            <span className="text-danger">{t("deposit.invalid-payment-channel")}</span> :
                                            <Skeleton />
                                        )}
                                        <div className="invalid-feedback">{this.props.errorMsg.bank_id || ""}</div>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label d-flex align-items-center">
                                        {t("deposit.promotion")}:
                                        {this.props.promoOpts && (
                                            <button className="btn btn-gradient-blue tnc-btn" type="button" onClick={this.props.toggleTncModalHandler}>
                                                {t("deposit.tnc-apply")}
                                            </button>
                                        )}
                                    </label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            <p className="form-control lh-lg" onClick={this.toggleSelectRadio}>
                                                {this.props.promoOpts ? this.props.promoOpts.label : <>{t("history.select-promotion")}</>}
                                            </p>

                                            {this.props.promoOpts && (
                                                <span className="input-group-text">
                                                    <Icon icon="mdi:close-circle" onClick={this.props.promoChangeHandler} />
                                                </span>
                                            )}

                                            <span className="input-group-text" onClick={this.toggleSelectRadio}>
                                                <Icon icon="uil:angle-right" />
                                            </span>
                                        </div>
                                        <MobileSelectRadio
                                            name={"selectPromo"}
                                            toggleHandler={this.toggleSelectRadio}
                                            showSelectRadio={this.state.showSelectRadio}
                                            onChange={this.props.promoChangeHandler}
                                            options={this.props.promoArry}
                                            value={this.props.promoOpts && this.props.promoOpts.key !== null ? this.props.promoOpts.key : null}
                                        />
                                        {/* <ReactSelect
                                            classNamePrefix="react-select"
                                            placeholder={t('history.select-promotion')}
                                            name="selectPromo"
                                            options={this.props.promoArry}
                                            onChange={this.props.promoChangeHandler}
                                            // value={this.props.promoOpts}
                                            // isClearable={true}
                                        /> */}
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('transaction-type.bonus-code')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <input 
                                                id="bonusCode"
                                                name="bonusCode"
                                                value={this.props.input.bonusCode}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.bonusCode || ''}
                                                autoComplete="off"
                                                type="text" 
                                                className="form-control" 
                                                placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("deposit.deposit-amount")}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className={`form-control ${this.props.errorMsg.amount && "was-validated is-invalid"}`}
                                                aria-label="Username"
                                                // aria-describedby="depositAmtHelp"
                                                value={this.props.input.amount}
                                                placeholder={`${t("deposit.min-max-limit")} ${new Intl.NumberFormat("en-US", this.props.numFormat).format(
                                                    this.props.depositOption === "bank-transfer"
                                                        ? this.props.groupSetting.min_deposit
                                                        : this.props.quickPayTransactionMinimumPaymentAmt || 0.0
                                                )}/${new Intl.NumberFormat("en-US", this.props.numFormat).format(
                                                    this.props.depositOption === "bank-transfer"
                                                        ? this.props.groupSetting.max_deposit
                                                        : this.props.quickPayTransactionMaximumPaymentAmt || 0.0
                                                )}`}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                required={true}
                                                min={
                                                    this.props.depositOption === "bank-transfer"
                                                        ? this.props.groupSetting.min_deposit
                                                        : this.props.quickPayTransactionMinimumPaymentAmt || 0
                                                }
                                                max={
                                                    this.props.depositOption === "bank-transfer"
                                                        ? this.props.groupSetting.max_deposit
                                                        : this.props.quickPayTransactionMaximumPaymentAmt || 0
                                                }
                                                step="any"
                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                minValueMessage={t("validation.amtMinValue", {
                                                    0:
                                                        this.state.depositOption === "bank-transfer"
                                                            ? this.props.groupSetting.min_deposit
                                                            : this.props.quickPayTransactionMinimumPaymentAmt || 0,
                                                })}
                                                maxValueMessage={t("validation.amtMaxValue", {
                                                    0:
                                                        this.state.depositOption === "bank-transfer"
                                                            ? this.props.groupSetting.max_deposit
                                                            : this.props.quickPayTransactionMaximumPaymentAmt || 0,
                                                })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.amount || ""}
                                            />
                                        </div>
                                        <div className="form-check-group deposit-amts">
                                            {this.props.depositAmts &&
                                                this.props.depositAmts.map((depositAmt, idx) => (
                                                    <div className="form-check form-check-inline five-check" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="depositAmt"
                                                            id={`depositAmt${depositAmt.value}`}
                                                            value={depositAmt.value}
                                                            onChange={(event) => this.props.onInputChangeHandler(event)}
                                                            checked={Number(this.props.depositAmt) === Number(depositAmt.value) ? true : false}
                                                        />
                                                        <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                            {new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(depositAmt.value || 0.0)}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                        <small>
                                            {t("deposit.last-deposit-amount")}:{" "}
                                            <span style={{ fontWeight: "600" }}>
                                                {this.props.currentUser.currency}
                                                {this.props.lastDeposit}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-gradient-blue mx-3"
                                    type="submit"
                                    // value="Submit"
                                    // onClick={this.props.confirmHandler}
                                >
                                    {t("global.confirm")}
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileCrypto));
