import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Modal from 'react-modal';
import male1 from '../../assets/images/profile/profile-icons/male-1.jpg';
import female1 from '../../assets/images/profile/profile-icons/female-1.jpg';

import { updateProfilePicture, /*getMemberProfile,*/ getTopProfile } from "../../store/ducks/profile/actions";
//Icons
import { Icon } from "@iconify/react";

const maleIcon = [
  { name: "male-1.jpg", checked: true, src: male1 },
  { name: "male-2.jpg", checked: false, src: "" },
  { name: "male-3.jpg", checked: false, src: "" },
  { name: "male-4.jpg", checked: false, src: "" },
  { name: "male-5.jpg", checked: false, src: "" },
  { name: "male-6.jpg", checked: false, src: "" },
  { name: "male-7.jpg", checked: false, src: "" },
  { name: "male-8.jpg", checked: false, src: "" },
];
const femaleIcon = [
  { name: "female-1.jpg", checked: true, src: female1 },
  { name: "female-2.jpg", checked: false, src: "" },
  { name: "female-3.jpg", checked: false, src: "" },
  { name: "female-4.jpg", checked: false, src: "" },
  { name: "female-5.jpg", checked: false, src: "" },
  { name: "female-6.jpg", checked: false, src: "" },
  { name: "female-7.jpg", checked: false, src: "" },
  { name: "female-8.jpg", checked: false, src: "" },
];
export class SelectProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadImg: "",
      maleIcon: maleIcon,
      femaleIcon: femaleIcon,
      openAblum: false,
      gender: "male",
      userId: this.props.currentUser.data.id
        ? this.props.currentUser.data.id
        : "",
      uplodedImg: {}
    };
  }

  componentDidMount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.update_profile_picture_result !== this.props.update_profile_picture_result) {
      if (nextProps.update_profile_picture_result.responseCode === 200) {
        const { t } = this.props;
        Swal.close();
        this.setState({ openAblum: false, uploadedImg: '' });
        this.props.closeProfileModal();

        Swal.fire({
          title: t("global.success"),
          text: t("global.record-updated"),
          icon: "success",
          confirmButtonText: t("global.ok"),
        }).then((result) => {
          if (result.isConfirmed) {
            // this.props.getMemberProfile();
            window.location.reload();
          }
        });
      } else if (nextProps.update_profile_picture_result.responseCode === 422) {
        this.setState({
          touched: true,
          // error_message: nextProps.memberProfileUpdateResult.message,
          errorMsg: (nextProps.update_profile_picture_result.message.input_error || []),
          formError: true,
          openAblum: false
        });
        Swal.close();
      } else {
        return false;
      }
    }

    return true;
  }


  changeGender = (g) => {
    this.setState({
      gender: g,
    });
  };

  changeMaleIcon = (e, index) => {
    let newMaleIcon = [...this.state.maleIcon];
    newMaleIcon[index].checked = true;

    for (let i = 0; i < newMaleIcon.length; i++) {
      if (i === index) {
        newMaleIcon[i].checked = true;
        newMaleIcon[i].src = e.target.src;
      } else {
        newMaleIcon[i].checked = false;
        newMaleIcon[i].src = "";
      }
    }

    this.setState({
      maleIcon: newMaleIcon,
    });
  };

  changeFemaleIcon = (e, index) => {
    let newFemaleIcon = [...this.state.femaleIcon];
    newFemaleIcon[index].checked = true;

    for (let i = 0; i < newFemaleIcon.length; i++) {
      if (i === index) {
        newFemaleIcon[i].checked = true;
        newFemaleIcon[i].src = e.target.src;
      } else {
        newFemaleIcon[i].checked = false;
        newFemaleIcon[i].src = "";
      }
    }

    this.setState({
      femaleIcon: newFemaleIcon,
    });
  };

  uploadProfileImage = (event) => {
    const that = this;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      that.setState({
        uploadImg: URL.createObjectURL(file),
        uploadedImg: file,
      });
    };
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  onUpdateProfilePictureHandler = (e) => {
    e.preventDefault();
    const { t } = this.props;

    Swal.fire({
      title: t("global.confirmation"),
      text: t("profile.proceed-to-change-profile-image") + "?",
      icon: "warning",
      confirmButtonText: t("global.confirm"),
      showDenyButton: true,
      denyButtonText: t("global.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });


        let data = {};

        if (this.state.gender === "male" && this.state.uploadImg === "") {
          let newMaleIcon = [...this.state.maleIcon];
          for (let i = 0; i < newMaleIcon.length; i++) {
            if (newMaleIcon[i].checked === true) {
              data.profile_image = newMaleIcon[i].src;
            }
          }
        } else if (this.state.uploadImg === "") {
          let newFemaleIcon = [...this.state.femaleIcon];
          for (let i = 0; i < newFemaleIcon.length; i++) {
            if (newFemaleIcon[i].checked === true) {
              data.profile_image = newFemaleIcon[i].src;
            }
          }
        }

        else if (this.state.uploadedImg !== "") {
          data = new FormData();
          data.append('upload_profile_image', this.state.uploadedImg);
          data.append('member_id', this.state.userId);
        }

        data.member_id = this.state.userId;

        this.props.updateProfilePicture(data);
      }
    })
  }
  
  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/profile/",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const profile = this.imageImport(
      require.context(
        "../../assets/images/profile/profile-icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const icon = this.imageImport(
      require.context("../../assets/images/icons/", false, /\.(png|jpe?g|svg)$/)
    );

    let topProfileData = null;
    if (!this.state.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
    }

    return (
      <Modal
        isOpen={this.props.openProfileModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd"
        ariaHideApp={false}
        onRequestClose={this.props.toggleProfileModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark" id="selectCountryLabel">
              {t("profile.edit-profile-icon")}
            </h5>
            <Icon
              type="button"
              className="btn-close"
              icon="mdi:close"
              onClick={()=>{this.props.toggleProfileModal(); this.setState({ openAblum: false });}}
              // onClick={() => {
              //   this.setState({ openAblum: false })
              // }}
            />
          </div>
          <div className="modal-body pf-select-container">
            {this.state.openAblum ? (
              <div className="upload-profile-container gap-3 w-75">
                {this.state.uploadImg ? (
                  <img
                    className="uploaded-img"
                    src={this.state.uploadImg}
                    alt="uploaded"
                  />
                ) : (
                  <img
                    className="uploaded-img"
                    src={img["upload-img.jpg"]}
                    alt="uploaded"
                  />
                )}
                <label
                  className="upload-btn"

                  htmlFor="formId"
                >
                  <input name="" type="file" id="formId" hidden accept="image/png, image/gif, image/jpeg" onChange={(e) => this.uploadProfileImage(e)} />
                  {t("profile.upload-image")}
                </label>
                <p>{t("profile.file-size")}: {t("global.maximum")} 1 MB • {t("profile.file-extension")}: .JPEG, .PNG</p>
                <div className="d-flex justify-content-between w-100">
                  <button
                    className="btn btn-gray"
                    onClick={() => this.setState({ openAblum: false })}
                  >
                    {t("global.back")}
                  </button>
                  <button className="btn btn-gradient-blue" onClick={this.onUpdateProfilePictureHandler}>{t("global.save")}</button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-4 d-flex flex-column align-items-center">
                  <img
                    className="profile-img"
                    src={(topProfileData && topProfileData.topProfile.profile_picture) || profile["male-5.jpg"]}
                    alt="profile-img"
                  />
                  <div className="pf-gender">
                    <button
                      className={`btn ${this.state.gender === "male"
                          ? "btn-gradient-blue"
                          : "btn-white"
                        }`}
                      onClick={() => this.changeGender("male")}
                    >
                      <Icon icon="mdi:gender-male" />
                      <span>M</span>
                    </button>
                    <button
                      className={`btn ${this.state.gender === "female"
                          ? "btn-gradient-blue"
                          : "btn-white"
                        }`}
                      onClick={() => this.changeGender("female")}
                    >
                      <Icon
                        className="female-icon"
                        icon="mdi:gender-female"
                      />
                      <span>F</span>
                    </button>
                  </div>
                </div>
                <div className="col-8">
                  <div className="pf-pic">
                    <ul className="row g-2 mb-3">
                      {this.state.gender === "male"
                        ? this.state.maleIcon &&
                        this.state.maleIcon.map((item, index) => (
                          <li
                            className="col-3 position-relative"
                            key={index}
                          >
                            <img
                              className={`profile-icon ${item.checked ? "selected" : ""
                                }`}
                              src={profile[item.name]}
                              alt={item.name}
                              onClick={(e) => this.changeMaleIcon(e, index)}
                            />
                            {item.checked && (
                              <img
                                className="profile-checked"
                                src={icon["check-icon.png"]}
                                alt="checked"
                              />
                            )}
                          </li>
                        ))
                        : this.state.femaleIcon &&
                        this.state.femaleIcon.map((item, index) => (
                          <li
                            className="col-3 position-relative"
                            key={index}
                          >
                            <img
                              className={`profile-icon ${item.checked ? "selected" : ""
                                }`}
                              src={profile[item.name]}
                              alt={item.name}
                              onClick={(e) => this.changeFemaleIcon(e, index)}
                            />
                            {item.checked && (
                              <img
                                className="profile-checked"
                                src={icon["check-icon.png"]}
                                alt="checked"
                              />
                            )}
                          </li>
                        ))}
                    </ul>
                    <span className="line">or</span>
                    <div
                      className="ablum-select my-3"
                      onClick={() => this.setState({ openAblum: true })}
                    >
                      <img src={icon["ablum-icon.svg"]} alt="ablum" />
                      <span>{t("global.photo-album")}</span>
                    </div>
                    <button className="btn btn-gradient-blue" onClick={this.onUpdateProfilePictureHandler}>{t("global.save")}</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const { profile } = state;
  return {
    update_profile_picture_result: profile.update_profile_picture_result,
    currentUser: state.currentUser,
    topProfileData: state.profile.top_profile_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfilePicture: (data) => dispatch(updateProfilePicture(data)),
    getTopProfile: () => dispatch(getTopProfile())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SelectProfileModal));
