import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const referralReducer = createReducer(initialState) ({
    [type.UPDATE_VIEWCOUNT]: (state, action) => ({
        ...state,
        update_viewcount: action.payload,
    })
});

export default referralReducer;