import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

//Components
import { updateMemberPassword, getMemberProfile } from "../../../store/ducks/profile/actions";
import ValidationError from "../../../components/ValidationError/ValidationError";
import { generateEmailOTP, verifyEmailOTP, generateSMSOTP, verifySMSOTP, resendOTP } from "../../../store/ducks/profile/actions";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { getCountry } from "../../../store/ducks/home/actions";

//Icons
import { Icon } from "@iconify/react";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";

import MobileVerification from "./MobileVerification";
import * as CryptoJS from "crypto-js";
import moment from "moment";

// const countryArry = [
//   { value: 1, label: "Malaysia +60" }, { value: 2, label: "Singapore +65", }
// ];

let countryArry = [];
let mobileCountryArray = [];
let contact_no_min_limits = 9;
let contact_no_max_limits = 10;

class VerificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryCode: "Malaysia +60",
            showOldPwd: false,
            showPwd: false,
            showPwdConfirmation: false,
            current_password: "",
            new_password: "",
            confirm_password: "",
            email: this.props.currentUser.data.email ? this.props.currentUser.data.email : "",
            email_otp_code: "",
            verify_email_btn_disabled: true,
            sms_otp_code: "",
            currentMobileNo: this.props.currentUser.data.contact_no ? this.props.currentUser.data.contact_no.substr(2) : "",
            currentCountryId: "",
            currentMobileDialCode: "",
            check_sending_sms_times: "",
            check_sending_sms_status: true,
            time: {},
            seconds: 180,
            timer: 0,
            generateOTPStatus: false,
            resend_otp_button_disabled: true,
            errorMsg: [],
            formError: false,

            pwdStrength: "low",
        };

        this.formik = React.createRef();
    }

    componentDidMount() {
        this.props.getCountry();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.countryData !== undefined) {
            if (nextProps.countryData !== this.props.countryData) {
                let countryData = nextProps.countryData.country;
                countryArry = [];
                mobileCountryArray = [];
                Object.keys(countryData).forEach((item, i) => {
                    if (this.props.currentUser.data.country_code === countryData[item].countryShortCode) {
                        this.setState({
                            currentCountryId: countryData[item].countryId,
                            currentMobileDialCode: countryData[item].dialCode,
                        });

                        countryArry.push({
                            label: countryData[item].countryName + countryData[item].dialCode,
                            value: countryData[item].countryId,
                        });
                        mobileCountryArray.push({
                            code: countryData[item].countryShortCode,
                            label: countryData[item].dialCode,
                            value: countryData[item].countryId,
                            details: { label: countryData[item].countryName + countryData[item].dialCode, value: countryData[item].countryId },
                        });
                    }

                });
            }
        }

        // console.log("[ChangePassword] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextProps.memberUpdatePasswordResult !== this.props.memberUpdatePasswordResult) {
            if (nextProps.memberUpdatePasswordResult.responseCode === 200) {
                const { t } = this.props;

                if (isDesktop) {
                    Swal.fire({
                        title: t("global.success"),
                        text: t("global.record-updated"),
                        icon: "success",
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                current_password: "",
                                new_password: "",
                                confirm_password: "",
                                touched: false,
                                errorMsg: [],
                            });

                            this.formik.resetForm();

                            document.getElementById("verificationModal").click();
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                        title: t("global.success"),
                        icon: "success",
                        text: t("global.record-updated"),
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                current_password: "",
                                new_password: "",
                                confirm_password: "",
                                touched: false,
                                errorMsg: [],
                            });

                            Swal.close();

                            this.props.closeVerification("");
                        }
                    });
                }
            } else if (nextProps.memberUpdatePasswordResult.responseCode === 422) {
                // if (!isDesktop) {
                //     Swal.fire({
                //         iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">',
                //         title: t("global.sorry"),
                //         text: nextProps.memberUpdatePasswordResult.message,
                //         // nextProps.memberUpdatePasswordResult.message.input_error.new_password ||
                //         // nextProps.memberUpdatePasswordResult.message.input_error.confirm_password,
                //         customClass: {
                //             container: "swal-mobile-container sorry",
                //             icon: "swal-sorry-icon",
                //         },
                //     }).then((result) => {
                //         if (result.isConfirmed) {
                //             this.setState({
                //                 touched: true,
                //                 errorMsg: nextProps.memberUpdatePasswordResult.message.input_error || [],
                //                 formError: true,
                //             });
                //         }
                //     });
                // } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.memberUpdatePasswordResult.message.input_error || [],
                    formError: true,
                });
                Swal.close();
                // }
            } else {
                return false;
            }
        }

        if (nextProps.generate_email_otp_result !== this.props.generate_email_otp_result) {
            if (nextProps.generate_email_otp_result.responseCode === 200) {
                // if(this.state.openUnlinkGoogleModal === false){
                //     this.setState({
                //         generateEmailStatus: true
                //     })
                // }else{
                //     this.setState({
                //         openUnlinkGoogleStatus: true
                //     });
                // }
                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.generate_email_otp_result.msgType,
                        icon: "success",
                        title: t("global.success"),
                        html: t("profile.generate-email-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                verify_email_btn_disabled: false,
                            });
                            Swal.close();
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                        icon: 'success',
                        title: t("global.success"),
                        html: t("profile.generate-email-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                verify_email_btn_disabled: false,
                            });
                            Swal.close();
                        }
                    });
                }
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">',
                        icon: "error",
                        title: t("global.sorry"),
                        text: nextProps.generate_email_otp_result.message,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.generate_email_otp_result.message || [],
                                formError: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.generate_email_otp_result.message || [],
                        formError: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.verify_email_otp_result !== this.props.verify_email_otp_result) {
            if (nextProps.verify_email_otp_result.responseCode === 200) {
                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.verify_email_otp_result.msgType,
                        icon: 'success',
                        title: t("global.success"),
                        html: t("profile.email-verify-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                            document.getElementById("verificationModal").click();

                            window.location.reload();

                        }
                        // this.setState({ generateEmailOTP: false, openEmailModal: false });
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                        title: t("global.success"),
                        icon: 'success',
                        html: t("profile.email-verify-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                            this.props.closeVerification("");
                            window.location.reload();
                        }
                        // this.setState({ generateEmailOTP: false, openEmailModal: false });
                    });
                }

                // const data = this.props.currentUser;
                // data.email = this.state.email;

                // // here is that the goup id problem
                // this.props.getUserData(data);

                // this.props.getTopProfile();
                // this.props.getMemberProfile();
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">',
                        title: t("global.sorry"),
                        icon: "error",
                        text: nextProps.verify_email_otp_result.message,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_email_otp_result.message || [],
                                formError: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.verify_email_otp_result.message || [],
                        formError: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.generate_sms_otp_result !== this.props.generate_sms_otp_result) {
            if (nextProps.generate_sms_otp_result.responseCode === 200) {
                if (!nextProps.generate_sms_otp_result.data.sms_reached_limit) {
                    let timeLeftVar = this.secondsToTime(this.state.seconds);

                    this.setState({ time: timeLeftVar });

                    this.startTimer();
                }

                this.setState({
                    generateOTPStatus: true,
                    check_sending_sms_times: nextProps.generate_sms_otp_result.data.count_sending_sms_times,
                    check_sending_sms_status: nextProps.generate_sms_otp_result.data.sms_reached_limit === true ? false : true,
                    resend_otp_button_disabled: true,
                });

                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.generate_sms_otp_result.msgType,
                        icon: 'success',
                        title: t("global.success"),
                        html: nextProps.generate_sms_otp_result.data.sms_reached_limit === true ? t("profile.reached-sms-limit") : t("profile.generate-sms-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                        // icon: nextProps.generate_sms_otp_result.msgType,
                        icon: 'success',
                        title: t("global.success"),
                        html: nextProps.generate_sms_otp_result.data.sms_reached_limit === true ? t("profile.reached-sms-limit") : t("profile.generate-sms-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                        }
                    });
                }
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">',
                        icon: "error",
                        title: t("global.sorry"),
                        text: nextProps.generate_sms_otp_result.message,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_email_otp_result.message || [],
                                formError: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.generate_sms_otp_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.verify_sms_otp_result !== this.props.verify_sms_otp_result) {
            if (nextProps.verify_sms_otp_result.responseCode === 200) {
                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.verify_sms_otp_result.msgType,
                        icon: 'success',
                        title: t("global.success"),
                        html: t("profile.verify-sms-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                            document.getElementById("verificationModal").click();
                        }
                        // this.setState({ generateOTPStatus: false, openMobileModal: false });
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                        title: t("global.success"),
                        icon: 'success',
                        html: t("profile.verify-sms-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                            this.props.closeVerification("");
                            clearInterval(this.timer);
                        }
                        // this.setState({ generateOTPStatus: false, openMobileModal: false });
                    });
                }
                const contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;
                const data = this.props.currentUser.data;
                data.contact_no = contact_number.replace(/^\+/, "").replace(/ /g, "");
                this.props.getUserData(data);
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">',
                        icon: "error",
                        title: t("global.sorry"),
                        text: nextProps.verify_sms_otp_result.message,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_sms_otp_result.message || [],
                                formError: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.verify_sms_otp_result.message || [],
                        formError: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.resend_otp_result !== this.props.resend_otp_result) {
            if (nextProps.resend_otp_result.responseCode === 200) {
                if (nextProps.resend_otp_result.data.sms_reached_limit === true) {
                    this.setState({ check_sending_sms_status: false });
                    Swal.close();
                } else {
                    if (isDesktop) {
                        Swal.fire({
                            // icon: nextProps.resend_otp_result.msgType,
                            icon: 'success',
                            title: t("global.success"),
                            html: t("profile.resend-otp-success"),
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                        }).then(() => {
                            this.setState({
                                generateOTPStatus: true,
                                openMobileModal: true,
                                check_sending_sms_times: nextProps.resend_otp_result.data.count_sending_sms_times,
                                seconds: 180,
                                timer: 0,
                                resend_otp_button_disabled: true,
                            });
                        });
                    } else {
                        Swal.fire({
                            iconHtml: '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">',
                            title: t("global.success"),
                            icon: 'success',
                            html: t("profile.resend-otp-success"),
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                            customClass: {
                                container: "swal-mobile-container sucess",
                                icon: "swal-success-icon",
                            },
                        }).then(() => {
                            this.setState({
                                generateOTPStatus: true,
                                openMobileModal: true,
                                check_sending_sms_times: nextProps.resend_otp_result.data.count_sending_sms_times,
                                seconds: 180,
                                timer: 0,
                                resend_otp_button_disabled: true,
                            });
                        });
                    }
                }
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">',
                        icon: "error",
                        title: t("global.failed"),
                        html: nextProps.resend_otp_result.message,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container failed",
                            icon: "swal-failed-icon",
                        },
                    }).then(() => {
                        this.setState({
                            touched: true,
                            errorMsg: nextProps.resend_otp_result.message || [],
                            formError: true,
                            resend_otp_button_disabled: true,
                        });
                        Swal.close();
                    });
                }
                this.setState({
                    touched: true,
                    errorMsg: nextProps.resend_otp_result.message.input_error || [],
                    formError: true,
                    resend_otp_button_disabled: true,
                });
                Swal.close();
            }
        }
        return true;
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({
                resend_otp_button_disabled: false,
                disabled_resend_contact_no: false,
            });
        }
    };

    handlePromoChange = (event) => {
        if (isDesktop) {
            this.setState({ countryCode: event });
        } else {
            this.setState({
                countryCode: event.details,
            });
        }
    };

    updateLoginPasswordHandler = (e) => {
        // if (this.state.show_confirm_password_validation === false) {
        // e.preventDefault();
        const { t } = this.props;
        if (isDesktop) {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.confirm"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.current_password = e.current_password;
                    data.new_password = e.new_password;
                    data.confirm_password = e.confirm_password;
                    data.trxTime = moment().unix();
                    data.trxHash = this.hashingAlg(data);
                    this.props.updateMemberPassword(data);
                }
            });
        } else {
            Swal.fire({
                iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">',
                icon: "warning",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                showCancelButton: true,
                confirmButtonText: t("global.confirm"),
                cancelButtonText: t("global.cancel"),
                customClass: {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.current_password = e.current_password;
                    data.new_password = e.new_password;
                    data.confirm_password = e.confirm_password;
                    data.trxTime = moment().unix();
                    data.trxHash = this.hashingAlg(data);
                    this.props.updateMemberPassword(data);
                }
            });
        }

        // }
    };

    generateEmailOTPHandler = (event) => {
        event.preventDefault();

        const data = {};
        data.email = this.state.email;
        data.language_code = this.props.currentUser.data.language_code;

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateEmailOTP(data);
    };

    verifyEmailOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        if (isDesktop) {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    // if (this.state.resend_email !== "") {
                    //     data.email = this.state.resend_email;
                    // } else {
                    data.email = this.state.email;
                    // }

                    data.otp_code = this.state.email_otp_code;

                    this.props.verifyEmailOTP(data);
                }
            });
        } else {
            Swal.fire({
                iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">',
                icon: "warning",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                confirmButtonText: t("global.confirm"),
                showCancelButton: true,
                cancelButtonText: t("global.cancel"),
                customClass: {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    // if (this.state.resend_email !== "") {
                    //     data.email = this.state.resend_email;
                    // } else {
                    data.email = this.state.email;
                    // }

                    data.otp_code = this.state.email_otp_code;

                    this.props.verifyEmailOTP(data);
                }
            });
        }
    };

    generateOTPHandler = (event) => {
        event.preventDefault();
        const data = {};
        // if (this.state.resend_contact_no === "") {
        data.contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;
        // } else {
        // data.contact_number = this.state.currentMobileDialCode + this.state.resend_contact_no;
        // }

        data.contact_prefix = this.state.currentMobileDialCode;

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateSMSOTP(data);
    };

    verifyOtpHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        if (isDesktop) {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    // if (this.state.resend_contact_no !== "") {
                    // data.contact_no = this.state.currentMobileDialCode + this.state.resend_contact_no;
                    // } else {
                    data.contact_no = this.state.currentMobileDialCode + this.state.currentMobileNo;
                    // }

                    data.otp_code = this.state.sms_otp_code;

                    this.props.verifySMSOTP(data);
                }
            });
        } else {
            Swal.fire({
                iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">',
                icon: "warning",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                confirmButtonText: t("global.confirm"),
                showCancelButton: true,
                cancelButtonText: t("global.cancel"),
                customClass: {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    // if (this.state.resend_contact_no !== "") {
                    // data.contact_no = this.state.currentMobileDialCode + this.state.resend_contact_no;
                    // } else {
                    data.contact_no = this.state.currentMobileDialCode + this.state.currentMobileNo;
                    // }

                    data.otp_code = this.state.sms_otp_code;

                    this.props.verifySMSOTP(data);
                }
            });
        }
    };

    resendOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;
        if (isDesktop) {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    data.contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;

                    data.contact_prefix = this.state.currentMobileDialCode;

                    this.props.resendOTP(data);

                    this.setState({ seconds: 180 });

                    let timeLeftVar = this.secondsToTime(this.state.seconds);

                    this.setState({ time: timeLeftVar });

                    this.startTimer();
                }
            });
        } else {
            Swal.fire({
                iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">',
                icon: "warning",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    data.contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;

                    data.contact_prefix = this.state.currentMobileDialCode;

                    this.props.resendOTP(data);

                    this.setState({ seconds: 180 });

                    let timeLeftVar = this.secondsToTime(this.state.seconds);

                    this.setState({ time: timeLeftVar });

                    this.startTimer();
                }
            });
        }
    };

    //Mobile No on change
    MobileNoInput = (e) => {
        this.setState({
            currentMobileNo: e.target.value,
        });
    };
    //Mobile set opt code
    MobileOtpInput = (e) => {
        this.setState({
            sms_otp_code: e.target.value,
        });
    };

    //Mobile set email
    MobileEmailInput = (e) => {
        this.setState({
            email: e.target.value,
        });
    };
    //Mobile set email otp
    MobileEmailOtp = (e) => {
        this.setState({
            email_otp_code: e.target.value,
        });
    };

    //Mobile set current pwd
    MobileCurrentPwd = (e) => {
        this.setState({
            current_password: e.target.value,
        });
    };
    //Mobile set new pwd
    MobileNewPwd = (value) => {
        this.updatePassword(value);
        this.setState({
            new_password: value,
        });
    };
    //Mobile set confirm pwd
    MobileConfirmPwd = (e) => {
        this.setState({
            confirm_password: e.target.value,
        });
    };
    //Mobile show current pwd
    MobileShowCurrentPwd = () => {
        this.setState({
            showOldPwd: !this.state.showOldPwd,
        });
    };
    //Mobile show new pwd
    MobileShowNewPwd = () => {
        this.setState({
            showPwd: !this.state.showPwd,
        });
    };
    //Mobile show confirm pwd
    MobileShowConfirmPwd = () => {
        this.setState({
            showPwdConfirmation: !this.state.showPwdConfirmation,
        });
    };

    //close and reset errorMsg
    triggleVerification = () => {
        this.setState({
            errorMsg: [],
        });
        //  this.props.closeVerification
    };

    updatePassword = (value) => {
        // let newFilter = Object.assign({}, this.state.formFilter);
        // newFilter["pwd"] = value;

        const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})");
        if (strongRegex.test(value)) {
            // newFilter["pwdStrength"] = "strong";

            this.setState({
                pwdStrength: "strong",
            });
        } else if (mediumRegex.test(value)) {
            // newFilter["pwdStrength"] = "normal";
            this.setState({
                pwdStrength: "normal",
            });
        } else if (!mediumRegex.test(value) && !strongRegex.test(value)) {
            // newFilter["pwdStrength"] = "low";
            this.setState({
                pwdStrength: "low",
            });
        }
    };

    closeVerificationModal = () => {
        this.setState({
            errorMsg: [],
            formError: false,
            current_password: "",
            new_password: "",
            confirm_password: "",
            sms_otp_code: "",
            email_otp_code: "",
            email: this.props.currentUser.data.email ? this.props.currentUser.data.email : ""
        });
    }

    handlePasswordChange = () => {
        this.setState({
            errorMsg: [],
        });
    }

    hashingAlg = (inputArr) => {
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        Object.keys(inputArr).forEach((item) => {
            if (inputArr[item] !== "") {
                paramsKey.push(item);
            }
        });
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = inputArr[val];
            if (x !== '') {
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        return hashStr;
    }

    render() {
        const { t } = this.props;
        const verification = this.props.verificationModal;
        switch (this.props.currentUser.data.country_id) {
            case 1:
                contact_no_min_limits = 9;
                contact_no_max_limits = 13;
                break;
            case "3":
                contact_no_min_limits = 8;
                contact_no_max_limits = 8;
                break;
            case 4:
                contact_no_min_limits = 11;
                contact_no_max_limits = 14;
                break;
            case "5":
                contact_no_min_limits = 9;
                contact_no_max_limits = 9;
                break;
            case "7":
                contact_no_min_limits = 10;
                contact_no_max_limits = 10;
                break;
            default:
                break;
        }

        let resendOTPCountDownTimer;

        if (this.state.resend_otp_button_disabled === true) {
            resendOTPCountDownTimer = "0" + this.state.time.m + ":" + this.state.time.s;
        } else {
            if (isDesktop) {
                resendOTPCountDownTimer = t("top-profile.resend");
            } else {
                resendOTPCountDownTimer = "0" + this.state.time.m + ":" + this.state.time.s;
            }
        }

        const ChangePasswordSchema = Yup.object().shape({
            current_password: Yup.string()
                .min(6, t("validation.minLength", { 0: 6 }))
                .max(20, t("validation.maxLength", { 0: 20 }))
                .required(t("validation.required")),
            new_password: Yup.string()
                .min(8, t("validation.minLength", { 0: 8 }))
                .max(20, t("validation.maxLength", { 0: 20 }))
                .required(t("validation.required")),
            confirm_password: Yup.string()
                .min(8, t("validation.minLength", { 0: 8 }))
                .max(20, t("validation.maxLength", { 0: 20 }))
                .required(t("validation.required"))
                .when("new_password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf([Yup.ref("new_password")], t("validation.confirm-password-not-match-with-new-password")),
                }),
        });

        return (
            <>
                {isDesktop ? (
                    <div className="modal global-modal fade" id="verificationModal" tabIndex="-1" aria-labelledby="verificationModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-verification">
                            <div className="modal-content">
                                {verification === "verification-phone" ? (
                                    <>
                                        <div className="modal-header">
                                            <div>
                                                <h5 className="modal-title">
                                                    {this.props.mobileStatusClass === "active" ? <>{t("profile.edit-mobile-number")}</> : <>{t("profile.mobile-number-verification")}</>}
                                                </h5>
                                                <small>
                                                    {t("profile.verification-mention-1")}
                                                    {t("global.customer-service")}
                                                    {t("profile.verification-mention-2")}
                                                </small>
                                            </div>
                                            <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" onClick={this.closeVerificationModal} />
                                        </div>
                                        <div className="modal-body">
                                            {this.state.check_sending_sms_status === true ? (
                                                <form
                                                    className="forms-col needs-validation"
                                                    name="mobile_verify_from"
                                                    id="mobile_verify_from"
                                                    method="post"
                                                    onSubmit={this.verifyOtpHandler}
                                                    noValidate
                                                >
                                                    <ValidationError formName="mobile_verify_from" touched={this.state.touched} message={this.state.errorMsg} />
                                                    <div className="input-group left has-validation">
                                                        <Select
                                                            className="form-select"
                                                            style={{ width: "30%" }}
                                                            name="selectCountryCode"
                                                            options={countryArry}
                                                            onChange={this.handlePromoChange}
                                                            defaultValue={countryArry[0]}
                                                            value={this.state.currentCountryId}
                                                        />
                                                        <StringInput
                                                            className={`${this.state.errorMsg.contact_no && "was-validated is-invalid"}`}
                                                            style={{ width: "70%" }}
                                                            type="text"
                                                            placeholder={t("profile.mobile-phone-no")}
                                                            name="verificationPhoneNo"
                                                            defaultValue=""
                                                            value={this.state.currentMobileNo}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    currentMobileNo: e.target.value,
                                                                });
                                                            }}
                                                            minLength={contact_no_min_limits}
                                                            maxLength={contact_no_max_limits}
                                                            minLengthMessage={t("validation.minLength", {
                                                                0: contact_no_min_limits,
                                                            })}
                                                            maxLengthMessage={t("validation.maxLength", {
                                                                0: contact_no_max_limits,
                                                            })}
                                                            required
                                                            requiredMessage={t("validation.required")}
                                                            errorMsg={this.state.errorMsg.contact_no || ""}
                                                            formError={this.state.formError}
                                                        />
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    sms_otp_code: e.target.value,
                                                                });
                                                            }}
                                                            className="form-control"
                                                            placeholder={t("profile.enter-verification-code")}
                                                        />
                                                        {this.state.generateOTPStatus && this.state.check_sending_sms_status ? (
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <small>
                                                                    {t("top-profile.otp-attempt-msg")} {this.state.check_sending_sms_times} / 5
                                                                </small>
                                                                <Button
                                                                    typeButton="button"
                                                                    clicked={(event) => this.resendOTPHandler(event)}
                                                                    classValue="btn btn-underlined"
                                                                    buttonName={resendOTPCountDownTimer}
                                                                    disabled={this.state.resend_otp_button_disabled === true ? true : false}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="btn btn-underlined"
                                                                type="button"
                                                                onClick={this.generateOTPHandler}
                                                                disabled={this.state.generateOTPStatus === true && this.state.resend_otp_button_disabled === true ? "active" : ""}
                                                            >
                                                                {t("profile.get-verification-code")}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <button
                                                        className="btn btn-gradient-blue"
                                                        type="submit"
                                                        value="Submit"
                                                        disabled={this.state.sms_otp_code === "" ? "active" : ""}
                                                    >
                                                        {t("global.submit")}
                                                    </button>
                                                </form>
                                            ) : (
                                                <div className="verification-error">
                                                    <p>
                                                        {t("top-profile.otp-failed-word")} {t("top-profile.otp-failed-msg")}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : verification === "verification-email" ? (
                                    <>
                                        <div className="modal-header">
                                            <div>
                                                <h5 className="modal-title">
                                                    {this.props.emailStatusClass === "active" ? <>{t("profile.edit-email-address")}</> : <>{t("profile.verification-email-address")}</>}
                                                </h5>
                                                <small>
                                                    {t("profile.verification-mention-1")}
                                                    {t("global.customer-service")}
                                                    {t("profile.verification-mention-2")}
                                                </small>
                                            </div>
                                            <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" onClick={this.closeVerificationModal} />
                                        </div>
                                        <div className="modal-body">
                                            <form
                                                className="forms-col needs-validation"
                                                name="email_verification_from"
                                                id="email_verification_from"
                                                method="post"
                                                onSubmit={this.verifyEmailOTPHandler}
                                                noValidate
                                            >
                                                <ValidationError formName="email_verification_from" touched={this.state.touched} message={this.state.errorMsg} />
                                                <div>
                                                    <StringInput
                                                        className={`${this.state.errorMsg.email && "was-validated is-invalid"}`}
                                                        type="email"
                                                        placeholder={t("profile.email-address")}
                                                        name="verificationEmail"
                                                        defaultValue=""
                                                        value={this.state.email}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                email: e.target.value,
                                                            });
                                                        }}
                                                        valid_email="true"
                                                        emailMessage={t("validation.email")}
                                                        required
                                                        requiredMessage={t("validation.required")}
                                                        errorMsg={this.state.errorMsg.email || ""}
                                                        formError={this.state.formError}
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("profile.enter-verification-code")}
                                                        name="verificationCode"
                                                        defaultValue=""
                                                        value={this.state.email_otp_code}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                email_otp_code: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                    <button className="btn btn-underlined" type="button" onClick={this.generateEmailOTPHandler}>
                                                        {t("profile.get-verification-code")}
                                                    </button>
                                                </div>
                                                <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                    {t("global.submit")}
                                                </button>
                                            </form>
                                        </div>
                                    </>
                                ) : verification === "verification-acc-pwd" ? (
                                    <>
                                        <div className="modal-header">
                                            <div>
                                                <h5 className="modal-title">{t("profile.edit-account-password")}</h5>
                                            </div>
                                            <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" onClick={this.closeVerificationModal} />
                                        </div>
                                        <div className="modal-body">
                                            <Formik
                                                innerRef={(p) => (this.formik = p)}
                                                initialValues={{
                                                    current_password: "",
                                                    new_password: "",
                                                    confirm_password: "",
                                                }}
                                                validationSchema={ChangePasswordSchema}
                                                onSubmit={(values, actions) => {
                                                    this.updateLoginPasswordHandler(values, actions);
                                                }}
                                            >
                                                {({ errors, touched }) => (
                                                    <Form
                                                        className="forms-col"
                                                        // method="post"
                                                        name="change_password_from"
                                                        id="change_password_from"
                                                    // onSubmit={this.updateLoginPasswordHandler}
                                                    // noValidate
                                                    >
                                                        {/* <ValidationError formName="change_password_from" touched={this.state.touched} message={this.state.errorMsg} /> */}
                                                        <div>
                                                            <label className="col-form-label lh-1">{t("profile.old-password")}</label>
                                                            <div className="input-group right has-validation">
                                                                {(this.state.errorMsg.current_password || touched.current_password) ? (
                                                                    <Field
                                                                        name="current_password"
                                                                        type={`${!this.state.showOldPwd ? "password" : "text"}`}
                                                                        className={`form-control ${this.state.errorMsg.current_password || (touched.current_password && errors.current_password) ? `is-invalid` : `is-valid`}`}
                                                                        minLength="8"
                                                                        maxLength="20"
                                                                        onKeyUp={() => this.handlePasswordChange()}
                                                                    />
                                                                ) : (
                                                                    <Field name="current_password" type="password" className={`form-control`}
                                                                        minLength="8"
                                                                        maxLength="20"
                                                                    />
                                                                )}
                                                                <span className="input-group-text">
                                                                    <button
                                                                        type="button"
                                                                        className="btn icon"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showOldPwd: !this.state.showOldPwd,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.state.showOldPwd ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                                    </button>
                                                                </span>
                                                                {(this.state.errorMsg.current_password || (touched.current_password && errors.current_password)) && (
                                                                    <div className="invalid-feedback">{(errors.current_password) ? errors.current_password : this.state.errorMsg.current_password}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className="col-form-label lh-1">{t("profile.new-password")}</label>
                                                            <div className="input-group right has-validation">
                                                                {(this.state.errorMsg.new_password || touched.new_password) ? (
                                                                    <Field
                                                                        name="new_password"
                                                                        type={`${!this.state.showPwd ? "password" : "text"}`}
                                                                        className={`form-control ${this.state.errorMsg.new_password || (touched.new_password && errors.new_password) ? `is-invalid` : `is-valid`}`}
                                                                        minLength="8"
                                                                        maxLength="20"
                                                                        onKeyUp={() => this.handlePasswordChange()}
                                                                    />
                                                                ) : (
                                                                    <Field 
                                                                        name="new_password" 
                                                                        type="password" 
                                                                        className={`form-control`}
                                                                        minLength="8"
                                                                        maxLength="20"
                                                                    />
                                                                )}

                                                                <span className="input-group-text">
                                                                    <button
                                                                        type="button"
                                                                        className="btn icon"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showPwd: !this.state.showPwd,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.state.showPwd ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                                    </button>
                                                                </span>
                                                                {(this.state.errorMsg.new_password || (touched.new_password && errors.new_password)) && <div className="invalid-feedback">
                                                                    {(errors.new_password) ? errors.new_password : this.state.errorMsg.new_password}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className="col-form-label lh-1">{t("profile.confirm-password")}</label>
                                                            <div className="input-group right has-validation">
                                                                {(this.state.errorMsg.confirm_password || touched.confirm_password) ? (
                                                                    <Field
                                                                        name="confirm_password"
                                                                        type={`${!this.state.showPwdConfirmation ? "password" : "text"}`}
                                                                        className={`form-control ${this.state.errorMsg.confirm_password || (touched.confirm_password && errors.confirm_password) ? `is-invalid` : `is-valid`}`}
                                                                        // minLength="8"
                                                                        // maxLength="20"
                                                                        onKeyUp={() => this.handlePasswordChange()}
                                                                    />
                                                                ) : (
                                                                    <Field 
                                                                        name="confirm_password" 
                                                                        type="password" 
                                                                        className={`form-control`}
                                                                        // minLength="8"
                                                                        // maxLength="20"
                                                                    />
                                                                )}
                                                                <span className="input-group-text">
                                                                    <button
                                                                        type="button"
                                                                        className="btn icon"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showPwdConfirmation: !this.state.showPwdConfirmation,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.state.showPwdConfirmation ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                                    </button>
                                                                </span>
                                                                {(this.state.errorMsg.confirm_password || (touched.confirm_password && errors.confirm_password)) && (
                                                                    <div className="invalid-feedback">{(errors.confirm_password) ? errors.confirm_password : this.state.errorMsg.confirm_password}</div>
                                                                )}
                                                                {/* {touched.confirm_password} | {errors.confirm_password} | {this.state.errorMsg.confirm_password} */}
                                                            </div>
                                                        </div>
                                                        <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                            {t("global.submit")}
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <MobileVerification
                        errorMsgMobile={this.state.errorMsg}
                        formErrorMobile={this.state.formError}
                        {...this.state}
                        {...this.props}
                        verification={verification}
                        countryArry={countryArry}
                        mobileCountryArray={mobileCountryArray}
                        contact_no_max_limit
                        s={contact_no_max_limits}
                        contact_no_min_limits={contact_no_min_limits}
                        verifyOtpHandler={this.verifyOtpHandler}
                        resendOTPCountDownTimer={resendOTPCountDownTimer}
                        MobileNoInput={this.MobileNoInput}
                        generateOTPHandler={this.generateOTPHandler}
                        resendOTPHandler={this.resendOTPHandler}
                        MobileOtpInput={this.MobileOtpInput}
                        handlePromoChange={this.handlePromoChange}
                        MobileEmailInput={this.MobileEmailInput}
                        MobileEmailOtp={this.MobileEmailOtp}
                        generateEmailOTPHandler={this.generateEmailOTPHandler}
                        verifyEmailOTPHandler={this.verifyEmailOTPHandler}
                        MobileCurrentPwd={this.MobileCurrentPwd}
                        MobileNewPwd={this.MobileNewPwd}
                        MobileConfirmPwd={this.MobileConfirmPwd}
                        MobileShowConfirmPwd={this.MobileShowConfirmPwd}
                        MobileShowCurrentPwd={this.MobileShowCurrentPwd}
                        MobileShowNewPwd={this.MobileShowNewPwd}
                        updateLoginPasswordHandler={this.updateLoginPasswordHandler}
                        triggleVerification={this.triggleVerification}
                        handlePasswordChange={this.handlePasswordChange}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        memberUpdatePasswordResult: profile.member_update_password_result,
        generate_email_otp_result: profile.generate_email_otp_result,
        verify_email_otp_result: profile.verify_email_otp_result,
        generate_sms_otp_result: profile.generate_sms_otp_result,
        verify_sms_otp_result: profile.verify_sms_otp_result,
        countryData: state.home.country_data,
        currentUser: state.currentUser,
        resend_otp_result: state.profile.resend_otp_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMemberPassword: (data) => dispatch(updateMemberPassword(data)),
        generateEmailOTP: (data) => dispatch(generateEmailOTP(data)),
        verifyEmailOTP: (data) => dispatch(verifyEmailOTP(data)),
        getUserData: (data) => dispatch(getUserData(data)),
        generateSMSOTP: (data) => dispatch(generateSMSOTP(data)),
        verifySMSOTP: (data) => dispatch(verifySMSOTP(data)),
        resendOTP: (data) => dispatch(resendOTP(data)),
        getCountry: () => dispatch(getCountry()),
        getMemberProfile: () => dispatch(getMemberProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VerificationModal));
