import * as type from './types';
import axios from '../../../common/config/axios';

export const updateViewCount = (data) => dispatch => {
    axios.post('referral/view_count', data)
    .then((res) => {
        dispatch({
            type: type.UPDATE_VIEWCOUNT,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}