import React, { Component } from 'react';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import MobileFifaQna from './MobileFifaQna';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import FifaModal from './FifaModal';
import Swal from "sweetalert2";
import moment from "moment";
import Timer from "react-compound-timer";
import { Icon } from "@iconify/react";
import { getData, createBet, submitBet, getHistoryByDate } from "../../store/ducks/fifaQNA/actions";
// import { start } from '@popperjs/core';
import { currency } from "../../common/config/currency";

export class FifaQna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      options: [],
      activeQuestions: 1,
      optionSelected: [],
      fifaModal: false,
      endMatch: "",
      startQuiz: false,
      settingData: [],
      onGoingBet: [],
      isNotReady: true,
      reward: [],
      totalSubmission: 0,
      timeLimit: {
        time: 0,
        unit: 'minutes'
      },
      betData: [],
      betID: null,
      betHistory: [],
      betHistoryDate: '',
      betHistoryDateList: [],
      countdownTime: 0,
      countdownDateTime: '0000-00-00 00:00:00',
      selecetedAnswerDisplay: '',
      coinConsumption: 0,
      totalQuestion: 0
    };
  }

  componentDidMount() {
    // const { t } = this.props;

    let params = {};
    params.member_id = this.props.currentUser.id;
    params.country_id = this.props.currentUser.country_id;
    params.language_code = this.props.currentUser.language_code;
    this.props.getData(params);
  }

  componentWillUnmount() {
      clearInterval(this.countdownInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
      const { t } = this.props;

      if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
          // Process ongoing bet
          let hasOnGoingBet = false;
          let betData = [];
          let nextQuestionNo = 1;

          if (!!(nextProps.rs.data.data.onGoingBet) && nextProps.rs.data.data.onGoingBet !== [] && Object.values(nextProps.rs.data.data.onGoingBet).length > 0) {
              hasOnGoingBet = true;

              if (!!(nextProps.rs.data.data.onGoingBet.bet_data)) {
                  betData = Object.values(nextProps.rs.data.data.onGoingBet.bet_data);
              }

              if (!!(nextProps.rs.data.data.onGoingBet.nextQuestionNo)) {
                  nextQuestionNo = nextProps.rs.data.data.onGoingBet.nextQuestionNo;
              }
          }

          this.setState({
              settingData: nextProps.rs.data.data.settingData,
              onGoingBet: nextProps.rs.data.data.onGoingBet,
              startQuiz: hasOnGoingBet,
              betData: betData,
              activeQuestions: nextQuestionNo
          });
          
          if (!!(nextProps.rs.data.data.settingData) && Object.values(nextProps.rs.data.data.settingData).length > 0) {
              // Process bonus data
              let reward = [];
              const bonusAmtArray = !!(nextProps.rs.data.data.settingData.bonus) ? nextProps.rs.data.data.settingData.bonus : {};
              // const bonusGiftArray = !!(nextProps.rs.data.data.settingData.has_gift) ? nextProps.rs.data.data.settingData.has_gift : {};
              const bonusImage = !!(nextProps.rs.data.data.settingData.bonus_image) ? nextProps.rs.data.data.settingData.bonus_image : {};
              
              Object.keys(bonusAmtArray).forEach((val) => {
                  // let withGift = '';
                  // if (!!(bonusGiftArray[val])) {
                  //     withGift = ' + '+t('qna.mystery-rewards');
                  // }
                  // reward.push({'value': t('qna.correct-ans').replace('##questionNo##',val), img: "reward-"+bonusImage[val], label: bonusAmtArray[val] + withGift});
                  reward.push({'value': t('qna.correct-ans').replace('##questionNo##',val), img: "reward-"+bonusImage[val], label: bonusAmtArray[val]});
              });

              // Process event period checking
              let startTime = '';
              let endTime = '';
              if(!!(nextProps.rs.data.data.settingData.event_period)) {
                  startTime = new Date(nextProps.rs.data.data.settingData.event_period.start).getTime();
                  endTime = new Date(nextProps.rs.data.data.settingData.event_period.end).getTime();
              }

              if (Date.now() < startTime) {
                  Swal.fire({
                      iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                      icon: "warning",
                      title: t('qna.sorry'),
                      // html: msg,
                      showCancelButton: true,
                      confirmButtonText: t('qna.more'),
                      cancelButtonText: t('qna.noted'),
                      reverseButtons: !isDesktop ? false : true,
                      customClass: !isDesktop
                          ? {
                            container: "swal-mobile-container confirmation",
                            icon: "swal-sorry-icon",
                          }
                          : [],
                  });
              } else if (Date.now() > endTime) {
                  Swal.fire({
                      iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                      icon: "warning",
                      title: t('qna.sorry'),
                      // html: msg,
                      showCancelButton: true,
                      confirmButtonText: t('qna.more'),
                      cancelButtonText: t('qna.noted'),
                      reverseButtons: !isDesktop ? false : true,
                      customClass: !isDesktop
                          ? {
                            container: "swal-mobile-container confirmation",
                            icon: "swal-sorry-icon",
                          }
                          : [],
                  });
              } else {
                  this.setState({
                      isNotReady: false
                  });
              }

              // Process bonus data
              let questions = [];
              const questionArray = nextProps.rs.data.data.questionArray;
              const answerLimit = nextProps.rs.data.data.settingData.answer_limit;

              questionArray.map((val,idx) => {
                  const quenstionNo = idx+1;
                  questions.push({id: quenstionNo, questions: quenstionNo+". "+val, sub: t('qna.can-choose').replace('##number##',answerLimit[quenstionNo]), multi: answerLimit[quenstionNo]});
                  return null;
              });

              // Process bet history
              let betHistory = [];
              if (!!(nextProps.rs.data.data.betHistory) && !!(nextProps.rs.data.data.betHistory.bet_data)) {
                  Object.values(nextProps.rs.data.data.betHistory.bet_data).map((answerArray, answerNo) => {
                      let answer1 = '';
                      let answer2 = '';
                      let answer3 = '';
                      let answer4 = '';

                      Object.values(answerArray).map((answerVal, idx) => {
                          switch (idx+1) {
                              case 1:
                                  answer1 = answerVal.toLowerCase();
                                  break;
                                  
                              case 2:
                                  answer2 = answerVal.toLowerCase();
                                  break;
                                  
                              case 3:
                                  answer3 = answerVal.toLowerCase();
                                  break;
                                  
                              case 4:
                                  answer4 = answerVal.toLowerCase();
                                  break;

                              default:
                                break;
                          }
                          return null;
                      });

                      betHistory.push({questions: (answerNo+1)+". "+questionArray[answerNo],ansOne:answer1,ansTwo:answer2,ansThree:answer3,ansFour:answer4});
                      return null;
                  });
              }

              let betHistoryDateList = [];
              if (!!(nextProps.rs.data.data.betHistoryDate)) {
                  nextProps.rs.data.data.betHistoryDate.map((val) => {
                      betHistoryDateList.push({value:val});
                      return null;
                  });
              }

              // Process leauge team
              let options = [];
              if (!!(nextProps.rs.data.data.leagueTeam)) {
                  nextProps.rs.data.data.leagueTeam.map((val) => {
                      options.push({value:val.toLowerCase(), icon:val.toLowerCase(), label:val});
                      return null;
                  });
              }
              
              // Set state
              this.setState({
                  totalSubmission: nextProps.rs.data.data.totalSubmission,
                  timeLimit: {
                      time: nextProps.rs.data.data.settingData.time_limit.timeLimit,
                      unit: nextProps.rs.data.data.settingData.time_limit.timeUnit
                  },
                  reward: reward,
                  questions: questions,
                  questionArray: questionArray,
                  totalQuestion: !!(nextProps.rs.data.data.totalQuestion) ? nextProps.rs.data.data.totalQuestion : 0,
                  options: options,
                  answerLimit: answerLimit,
                  betID: !!(nextProps.rs.data.data.onGoingBet) ? nextProps.rs.data.data.onGoingBet.id : null,
                  betHistory: betHistory,
                  betHistoryDate: !!(nextProps.rs.data.data.betHistory) ? nextProps.rs.data.data.betHistory.created_at : null,
                  betHistoryDateList: betHistoryDateList,
                  countdownTime: !!(nextProps.rs.data.data.countdownTime) ? nextProps.rs.data.data.countdownTime : 0,
                  countdownDateTime: !!(nextProps.rs.data.data.countdownDateTime) ? nextProps.rs.data.data.countdownDateTime : '0000-00-00 00:00:00',
                  coinConsumption: nextProps.rs.data.data.settingData.coin_consumption.coin,
                  startTime: moment(nextProps.rs.data.data.settingData.event_period.start).utcOffset('GMT+08:00').format('DD MMMM YYYY h:mm:ss'),
                  endTime: moment(nextProps.rs.data.data.settingData.event_period.end).utcOffset('GMT+08:00').format('DD MMMM YYYY h:mm:ss')
              });

              if (hasOnGoingBet === true && nextProps.rs.data.data.countdownTime > 0) {
                  this.startInterval(nextProps.rs.data.data.countdownTime);
              }
          }
      }

      if (!!(nextProps.create) && nextProps.create !== this.props.create) {
          if ((nextProps.create.responseCode === 200)) {
            this.startInterval(this.state.timeLimit.time*60000);

            this.setState({
                betID: nextProps.create.data.bet_id,
                countdownDateTime: moment(Date.now()).add(this.state.timeLimit.time, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                countdownTime: this.state.timeLimit.time*60000,                  
                startQuiz: true,
            });
          } else {
              Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
                icon: "error",
                title: t("global.error"),
                html: nextProps.create.message[0],
                confirmButtonText: t("global.ok"),
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon",
                    }
                    : [],
            });
          }
      }

      if (!!(nextProps.submit) && nextProps.submit.responseCode === 200 && nextProps.submit !== this.props.submit && nextProps.submit.data.isCompleted === true) {
          Swal.fire({
              iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
              icon: "success",
              title: t("global.success"),
              html: t('qna.success'),
              confirmButtonText: t("global.ok"),
              customClass: !isDesktop
                  ? {
                      container: "swal-mobile-container sucess",
                      icon: "swal-success-icon",
                  }
                  : [],
          }).then((result) => {
              if (result.isConfirmed) {
                  let params = [];
                  params.member_id = this.props.currentUser.id;
                  params.country_id = this.props.currentUser.country_id;
                  params.language_code = this.props.currentUser.language_code;
                  
                  this.props.getData(params);

                  this.setState({
                      activeQuestions: 1
                  });
              }
          });
      }

      if (nextProps.get_history !== this.props.get_history && nextProps.get_history !== undefined && nextProps.get_history.responseCode === 200) {
          // Process bet history
          let betHistory = [];
          if (!!(nextProps.get_history.data.betHistory)) {
              Object.values(nextProps.get_history.data.betHistory).map((answerArray, answerNo) => {
                  let answer1 = '';
                  let answer2 = '';
                  let answer3 = '';
                  let answer4 = '';

                  Object.values(answerArray).map((answerVal, idx) => {
                      switch (idx+1) {
                          case 1:
                              answer1 = answerVal.toLowerCase();
                              break;
                              
                          case 2:
                              answer2 = answerVal.toLowerCase();
                              break;
                              
                          case 3:
                              answer3 = answerVal.toLowerCase();
                              break;
                              
                          case 4:
                              answer4 = answerVal.toLowerCase();
                              break;

                          default:
                            break;
                      }
                      return null;
                  });

                  betHistory.push({questions: (answerNo+1)+". "+this.state.questionArray[answerNo],ansOne:answer1,ansTwo:answer2,ansThree:answer3,ansFour:answer4});
                  return null;
              });
          }

          this.setState({
              betHistory: betHistory
        });
      }

      return true;
  }

  handleChange = (selected) => {
      const { t } = this.props;
      let selecetedAnswerDisplay = '';
      
      if (!!(selected) && selected.length > 0) {
          selected.map((val) => {
              if (!!(selecetedAnswerDisplay)) {
                  selecetedAnswerDisplay = selecetedAnswerDisplay+', '+t('world-cup.team.'+val.value);
              } else {
                  selecetedAnswerDisplay = t('world-cup.team.'+val.value);
              }
              return null;
          });
      }
      
      this.setState({
          optionSelected: selected,
          selecetedAnswerDisplay: selecetedAnswerDisplay
      });
  };

  startQuizBtn = () => {
      const { t } = this.props;

      Swal.fire({
        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
        icon: "warning",
        title: t("global.reminder"),
        html: t('qna.reminder', { attribute: process.env.REACT_APP_WEBSITE_TITLE }).replace('##totalQuestion##',this.state.totalQuestion).replace('##coin##',this.state.coinConsumption).replace('##timeLimit##',this.state.timeLimit.time).replace('##timeUnit##',t(`qna.${this.state.timeLimit.unit}`)),
        confirmButtonText: t("global.ok"),
        showDenyButton: true,
        denyButtonText: t("global.cancel"),
        customClass: !isDesktop
            ? {
                container: "swal-mobile-container reminder",
                icon: "swal-reminder-icon",
            }
            : [],
      }).then((result) => {
          if (result.isConfirmed) {
              this.props.createBet();
          }
      });
  }

  nextBtn = () => {
      const { t } = this.props;
      let params = {};
      let selecetedAnswer = {};
      let betData = !!(this.state.betData) ? this.state.betData : [];
      this.state.optionSelected.map((val,idx) => {
          selecetedAnswer[idx+1] = val.value.toUpperCase();
          return null;
      });

      let optionSelected = this.state.optionSelected.length.toString();

      if (optionSelected === this.state.answerLimit[this.state.activeQuestions]) {
          betData.push(selecetedAnswer);
          params['bet_id'] = this.state.betID;
          params['bet_data'] = betData;
          this.props.submitBet(params);
    
          this.setState({
              activeQuestions: this.state.activeQuestions + 1,
              optionSelected: [],
              betData: betData,
              selecetedAnswerDisplay: ''
          });
      } else {
          Swal.fire({
              iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
              title: t("global.failed"),
              icon: "error",
              html: t('qna.minimum-answer').replace('##number##',this.state.answerLimit[this.state.activeQuestions],true),
              showConfirmButton: true,
              confirmButtonText: t("global.ok"),
              customClass: (!isDesktop) ? {
                  container: "swal-mobile-container failed",
                  icon: "swal-failed-icon",
              } : [],
          });
      }
  };

  prevBtn = () => {
      let params = {};
      const betData = this.state.betData;
      betData.splice(this.state.activeQuestions-2,1);    

      params['bet_id'] = this.state.betID;
      params['bet_data'] = betData;
      this.props.submitBet(params);

      this.setState({
          activeQuestions: this.state.activeQuestions - 1,
          optionSelected: [],
          betData: betData,
          selecetedAnswerDisplay: ''
      });
  };

  submitBtn = () => {
      const { t } = this.props;
      
      let params = {};
      let selecetedAnswer = {};
      let betData = !!(this.state.betData) ? this.state.betData : [];
      this.state.optionSelected.map((val,idx) => {
          selecetedAnswer[idx+1] = val.value.toUpperCase();
          return null;
      });

      let optionSelected = this.state.optionSelected.length.toString();

      if (optionSelected === this.state.answerLimit[this.state.activeQuestions]) {
        betData.push(selecetedAnswer);
        params['bet_id'] = this.state.betID;
        params['bet_data'] = betData;
        this.props.submitBet(params);

        clearInterval(this.countdownInterval);

        this.setState({
            optionSelected: [],
            betData: betData,
            selecetedAnswerDisplay: '',
            startQuiz: false,
            countdownTime: 0
        });
    } else {
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
            title: t("global.failed"),
            icon: "error",
            html: t('qna.minimum-answer').replace('##number##',this.state.answerLimit[this.state.activeQuestions],true),
            showConfirmButton: true,
            confirmButtonText: t("global.ok"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
            } : [],
        });
    }
  }

  getCountdownTime = (date) => {
      let createdAt = moment.tz(this.state.countdownDateTime, "Asia/Kuala_Lumpur");
      let now = moment().tz("Asia/Kuala_Lumpur");
      let duration = createdAt.diff(now, "milliseconds");
      
      return (duration > 0) ? duration : 0;
  }

  startInterval = (countdownTime) => {
      this.countdownInterval = setInterval(this.handleCountdown, countdownTime);
  }

  handleCountdown = () => {
      const { t } = this.props;

      clearInterval(this.countdownInterval);
      
      this.setState({
          startQuiz: false,
          countdownTime: 0
      });

      let params = [];
      params.member_id = this.props.currentUser.id;
      params.country_id = this.props.currentUser.country_id;
      params.language_code = this.props.currentUser.language_code;
      this.props.getData(params);

      Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: t('qna.end').replace('##timeLimit##',this.state.timeLimit.time).replace('##timeUnit##',t(`qna.${this.state.timeLimit.unit}`)),
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
              container: "swal-mobile-container failed",
              icon: "swal-failed-icon",
          } : [],
      });
  }

  toggleFifaModal = () => {
      this.setState({
          fifaModal: !this.state.fifaModal,
      })
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };


  render() {
    const { t } = this.props;
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const image = this.imageImport(require.context("../../assets/images/world-cup", false, /\.(png|jpe?g|svg)$/));
    const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));
    const tNc = this.imageImport(require.context("../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));

    const Option = (props) => {
      return (
        <components.Option {...props}>
          <div className='form-check form-check-inline'>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label className='btn form-check-label '> <img src={flag[`${props.value}.svg`]} alt={props.value} />{" "}<p>{t(`world-cup.team.${props.value}`)}</p></label>
          </div>
        </components.Option>
      );
    };

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //     imageLanguage = "-ms"
      //     break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        <FifaModal
            toggleFifaModal={this.toggleFifaModal}
            {...this.state}
            {...this.props}
        />
        {
          isDesktop ? (
            <section className='wc-section'>

              <div className="banner-col">
                <div className="banner-item">
                  <img src={banner[`world-cup-two-banner${imageLanguage}.webp`]} alt="fifa banner" />
                </div>
              </div>
              <div className='x-container'>
                <div className='section-header-gray'>
                  <h2>{t('qna.title')}</h2>
                  <p>{t('qna.title-1')}</p>
                </div>
                {this.state.endMatch ?
                  <div className='wc-vs-section mt-4 mb-0'>
                    <div className='section-header-gray'>
                      <h3>{t('qna.title-2')}</h3>
                    </div>
                    <div>
                    <p className='mb-5'><Icon icon="material-symbols:info" />{t('qna.event-ended')}</p>
                      <div>
                        <p>{t('qna.ady-upload')}{this.state.totalSubmission}{t('qna.click-to')}
                            <span style={{ color: "blue", cursor: 'pointer' }} onClick={this.toggleFifaModal}>
                            {t('qna.check-result')}
                            </span>
                        </p>
                      </div>
                    </div>
                  </div>
                    :
                  <div className='wc-vs-section mt-4 mb-0'>
                    <div className='section-header-gray'>
                      <h3>{t('qna.title-2')}</h3>
                      <h4 className='mt-3'>{t('qna.time').replace('##timeLimit##',this.state.timeLimit.time).replace('##timeUnit##',t('qna.'+this.state.timeLimit.unit))}</h4>
                      {this.state.startQuiz === true ? 
                          <p className='result-text mt-2'>{t('qna.countdown')}
                              <span className='orange-text'>
                                  <Timer 
                                      initialTime={this.getCountdownTime()}
                                      direction="backward"
                                      formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                  >
                                  <Timer.Minutes />{t('world-cup.m')} <Timer.Seconds />{t('world-cup.s')}
                                  </Timer>
                              </span>
                              {/* t('qna.'+this.state.timeLimit.unit) */}
                          </p> : ""}
                    </div>
                    <div className='fifa-two-body'>
                      {this.state.startQuiz === true ? <>
                        <div className='vip-lvl-col'>
                          <div className="vip-lvl-md">
                            <ul>
                              {this.state.questions &&
                                this.state.questions.map((item, index) => (
                                  <li
                                    key={item.id}
                                  >
                                    <span>{item.id}</span>
                                  </li>
                                ))}
                            </ul>
                            <div className="vip-lvl-progress-bar" style={{ width: `calc(10% * ${this.state.activeQuestions})` }} />
                          </div>
                        </div>
                        <div>
                          {this.state.questions &&
                            this.state.questions.map((item, index) => (
                              <div key={item.id}>
                                {this.state.activeQuestions === item.id && <>
                                  <h4>{item.questions}</h4>
                                  <p className='mb-2'>{item.sub}</p>
                                  <span
                                      class="d-inline-block w-100 mb-5 react-select"
                                      data-toggle="popover"
                                      data-trigger="focus"
                                      data-content="Please selecet answer(s)"
                                    >
                                    <ReactSelect
                                        options={this.state.options}
                                        classNamePrefix="react-select"
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={true}
                                        placeholder={t('qna.choose-ur-ans')}
                                        isMulti={item.multi === 1 ? false : true}
                                        components={{Option}}
                                        onChange={this.handleChange}
                                        // value={this.state.optionSelected}
                                        formatOptionLabel={(item) => (
                                          <p><img src={flag[`${item.value}.svg`]} alt={item.value} />{t(`world-cup.team.${item.value}`)}</p>
                                        )}

                                        isOptionDisabled={() => this.state.optionSelected.length >= item.multi}
                                    />
                                  </span>
                                  </>
                                }
                              </div>
                            ))}
                          {this.state.activeQuestions > 1 && <button type="submit" className='btn-outline-white mb-5 next ' onClick={this.prevBtn}>{t('qna.prev-question')}</button>}
                          {this.state.activeQuestions < this.state.totalQuestion && <button type="submit" className='btn-outline-white mb-5 next ' onClick={this.nextBtn}>{t('qna.next-question')}</button>}
                        </div>
                         </>
                          :
                          <h4 className='my-5'>{t('qna.click-to-ans')}</h4>
                      }
                      <div>
                        <p>{t('qna.ady-upload')}{this.state.totalSubmission}{t('qna.click-to')}
                            <span style={{ color: "#3e73fa", cursor: 'pointer' }} onClick={this.toggleFifaModal}>
                            {t('qna.check-result')}
                            </span>
                        </p>
                        {this.state.startQuiz === true ?
                          (this.state.activeQuestions < this.state.totalQuestion ? 
                            <button type="submit" className="btn-gradient-blue mt-2" disabled >{t('global.submit')}</button> 
                          : <button type="submit" onClick={this.submitBtn} className="btn-gradient-blue mt-2" >{t('global.submit')}</button>)
                          :<button type="submit" className='btn-gradient-blue mt-2' onClick={this.startQuizBtn} disabled={this.state.isNotReady}>{t('qna.start')}</button>
                        }
                        </div>
                    </div>
                  </div>
                }
                <div className='section-header-gray mt-5'>
                  <h2>{t('qna.unlimited-prize')}</h2>
                  <p>{t('qna.redeem-prize')}</p>
                </div>
                <div className='wc-vs-section'>
                  {this.state.endMatch ?
                    <div className='section-header-gray'>
                      <h3>{t('qna.title-2')}</h3>
                      <h4 className='result-text mt-2'>{t('qna.congrats')}<span className='blue-text'>2</span>{t('qna.get-prize')}<span className='orange-text'>{currency[this.props.currentUser.country_id] ?? ""}16</span> </h4>
                    </div> : ""
                  }
                  <div className='wc-reward row gap-4 gx-5 gy-2 fifa-qna'>
                    { 
                      this.state.reward && this.state.reward.map((item, index) => (
                        <div className={`wc-reward-container col-2 ${item.status}`} key={index}>
                          <div className='wc-reward-header'>
                            <h4 className='m-0'>{item.value}</h4>
                          </div>
                          <div>
                            <img src={image[`${item.img}.png`]} alt={item.img} />
                            <h5>{item.label}</h5>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='term-section mt-5'>
                  <div className='term-container card-shadow'>
                    <img src={tNc[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                    <div className='term-content'>
                      <h4 className='ps-4 '>{t('qna.total-prize')}</h4>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{t('qna.correct-1-4')}</th>
                            <th>{t('qna.correct-5-9')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t('qna.correct-ans-1')}{t('qna.get')}{t('qna.prize-1').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                            <td>{t('qna.correct-ans-5')}{t('qna.get')}{t('qna.prize-5').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                          </tr>
                          <tr>
                            <td>{t('qna.correct-ans-2')}{t('qna.get')}{t('qna.prize-2').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                            <td>{t('qna.correct-ans-6')}{t('qna.get')}{t('qna.prize-6').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                          </tr>
                          <tr>
                            <td>{t('qna.correct-ans-3')}{t('qna.get')}{t('qna.prize-3').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                            <td>{t('qna.correct-ans-7')}{t('qna.get')}{t('qna.prize-7').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                          </tr>
                          <tr>
                            <td>{t('qna.correct-ans-4')}{t('qna.get')}{t('qna.prize-4').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                            <td>{t('qna.correct-ans-8')}{t('qna.get')}{t('qna.prize-8').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                          </tr>
                          <tr>
                            <td colspan="2">{t('qna.correct-ans-9')}{t('qna.get')}{t('qna.prize-9').replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</td>
                          </tr>
                        </tbody>
                      </table>
                      <h4 className='ps-4 mt-4 mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
                      <ol>
                        <li>{t('qna.tnc-1', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                        <li>{t('qna.tnc-2', { attribute: process.env.REACT_APP_WEBSITE_TITLE }).replace('##coin##',this.state.coinConsumption)}</li>
                        <li>{t('qna.tnc-3').replace('##startTime##',this.state.startTime).replace('##endTime##',this.state.endTime)}</li>
                        <li>{t('qna.tnc-4')}</li>
                        <li>{t('qna.tnc-5').replace('##totalQuestion##',this.state.totalQuestion).replace('##timeLimit##',this.state.timeLimit.time).replace('##timeUnit##',t('qna.'+this.state.timeLimit.unit)).replace('##coin##',this.state.coinConsumption)}</li>
                        <li>{t('qna.tnc-6')}</li>
                        <li>{t('qna.tnc-7')}</li>
                        <li>{t('qna.tnc-8')}</li>
                        <li>{t('qna.tnc-9')}</li>
                        <li>{t('qna.tnc-10', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                      </ol>
                    </div>
                    <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                  </div>
                </div>
              </div>
            </section>
          ) :
            <MobileFifaQna
                {...this.state}
                {...this.props}
                handleChange={this.handleChange}
                nextBtn={this.nextBtn}
                prevBtn={this.prevBtn}
                toggleFifaModal={this.toggleFifaModal}
                startQuizBtn={this.startQuizBtn}
                getCountdownTime={this.getCountdownTime}
                submitBtn={this.submitBtn}
                currency={currency}
            />
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = state => {
    return {
        rs: state.fifaQNA.rs,
        create: state.fifaQNA.create,
        submit: state.fifaQNA.submit,
        get_history: state.fifaQNA.get_history,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (data) => dispatch(getData(data)),
        createBet: (data) => dispatch(createBet(data)),
        submitBet: (data) => dispatch(submitBet(data)),
        getHistoryByDate: (data) => dispatch(getHistoryByDate(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(FifaQna));
