import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const getVIP = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    // console.log(res_data);
    axios.get('vip/list',  {
        params: res_data
    }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_VIP,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_VIP,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};
export const validateVIPWeeklyBonus = (data) => dispatch => {
    axios.post('vip_bonus/validate', data)
    .then((res) => {
        dispatch({
            type: type.VALIDATE_VIP_WEEKLY_BONUS,
            payload: res.data
        });
    })
    .catch((res) => {

    });
};
export const claimVIPWeeklyBonus = (data) => dispatch => {
    axios.post('vip_bonus/claim', data)
    .then((res) => {
        dispatch({
            type: type.CLAIM_VIP_WEEKLY_BONUS,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}
export const validateVIPBirthdayBonus = (data) => dispatch => {
    axios.post('vip_exclusive/check_birthday_bonus', data)
    .then((res) => {
        dispatch({
            type: type.VALIDATE_VIP_BIRTHDAY_BONUS,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}
export const claimVIPBirthdayBonus = (data) => dispatch => {
    axios.post('vip_exclusive/redeem_birthday_bonus', data)
    .then((res) => {
        dispatch({
            type: type.CLAIM_VIP_BIRTHDAY_BONUS,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}
export const getVIPDepositTurnover = (data) => dispatch => {
    axios.post('vip_bonus/deposit_turnover', data)
    .then((res) => {
        dispatch({
            type: type.GET_VIP_DEPOSIT_TURNOVER,
            payload: res.data
        });
    })
    .catch((res) => {

    });
}