import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const downloadAppReducer = createReducer(initialState) ({
    [type.DOWNLOAD]: (state, action) => ({
        ...state,
        download: action.payload,
    })
});

export default downloadAppReducer;