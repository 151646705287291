import React, { Component } from "react";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { default as ReactSelect } from "react-select";
import Dropzone from 'react-dropzone';

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
// import NoRecords from "../../../components/NoRecords/NoRecords";

//Icons
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const typeArry = [
  { value: 1, label: "Feedback" }, { value: 2, label: "Deposit Problem", }
];

const feedbackLists = [
  {
    value: 1,
    label: "Deposit Problem",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  },
  {
    value: 2,
    label: "Deposit Problem",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  },
];

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files })
    };
    this.state = {
      type: '',
      wordsCount: '0',
      files: []
    };
  }

  componentDidMount() { }

  handleType = (event) => {
    this.setState({ type: event });
  };

  wordsCount = (e) => {
    this.setState({ wordsCount: e.target.value.length })
  }

  removeFileHandler = (file) => {
    this.setState(state => {
      const index = state.files.indexOf(file);
      const files = state.files.slice(0);
      files.splice(index, 1);
      return { files };
    });
  };


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const wordsCount = this.state.wordsCount;

    const truncate = (value) =>
    value?.length > 60 ? `${value.substring(0, 54)}...` : value;

    let files = this.state.files.map(file => (
      <li key={file.name}>
        <p>
          {file.name} - {Math.round(file.size / 1024)} KB{" "}
          <button className="btn icon" onClick={this.removeFileHandler}>
            <Icon icon="mdi:close" />
          </button>
        </p>
      </li>
    ));

    return (
      <Auxiliary>
        <section className="pf-dashboard">
          <div className="x-container">
            <ProfileSideBar />
            <div className="row g-3">
              <div className="col-9">
                <div className="pd-card-col h-100">
                  <h4>Feedback</h4>
                  <div className="pd-content">
                    <form className="forms-col" method="post">
                      <div>
                        <label className="col-form-label">
                          Types
                        </label>
                        <ReactSelect
                          classNamePrefix="react-select"
                          placeholder="Select"
                          name="selectPromo"
                          options={typeArry}
                          onChange={this.handleType}
                          value={this.state.type}
                        />
                      </div>
                      <div>
                        <label className="col-form-label">
                          Description <small className="ms-3">{wordsCount}/200</small>
                        </label>
                        <textarea
                          className="form-control"
                          rows="8"
                          maxLength="200"
                          placeholder="Fill in the content not less than 20 words"
                          style={{ resize: 'none' }}
                          onChange={(e) => this.wordsCount(e)}
                        >
                        </textarea>
                      </div>
                      <div>
                        <label className="col-form-label ">
                          Problem Screenshot <small className="ms-3">The file format is png、jpg、jpeg, and the size does not exceed 1MB</small>
                        </label>
                        <Dropzone
                          maxFiles={3}
                          accept={'image/*'}
                          // maxSize={500000}
                          onDrop={this.onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone-container">
                              {files && files.length ? (
                                <aside>
                                  <ul>{files}</ul>
                                </aside>
                              ) : ''}
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <div>
                                  <input {...getInputProps()} />
                                  <Icon icon="mdi:upload" />
                                  <p>Drop files to upload, or <span>browse</span></p>
                                  <p>({files.length}/3)</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <button className="btn btn-gradient-blue w-25" type="submit" value="Submit">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="pd-card-col h-100">
                  <div className="pd-header-more">
                    <h6>My Feedback</h6>
                    <Link className="float-end" to={{ pathname: "/feedback/list" }}>
                      More <Icon icon="uil:angle-right" />
                    </Link>
                  </div>
                  <div className="pd-content">
                    <div className="fb-mf-col">
                      <ul className="list-group list-group-flush">
                        {feedbackLists &&
                          feedbackLists.map((feedBackList, index) => (
                            <a className="list-group-item" key={index} href="/feedback/list/inner-feedback">
                              <label>{feedBackList.label}</label>
                              <p>{truncate(feedBackList.content)}</p>
                            </a>
                          ))}
                      </ul>
                    </div>
                  </div>
                  {/* <NoRecords component="feedback" /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default Feedback;
