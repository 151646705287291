import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import SideBar from "../../components/SideBar/SideBar";
import { isDesktop } from "react-device-detect";
import Logo from "../../assets/images/merchant-logo.png";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

export class RestrictionPageMobile extends Component {
  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/miscellaneous",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    return (
      <Auxiliary>
        {isDesktop ? (
          <>
            <section className="miscellaneous desktop">
              <div className="x-container-no-m">
                <div className="miscellaneous-content">
                  <div className="miscellaneous-body">
                    <img
                      src={img["restriction.webp"]}
                      alt="restriction"
                      className="left-image"
                    />
                  </div>
                  <div className="miscellaneous-body">
                    <img src={Logo} className="logo-image" />
                    <h3>{t("miscellaneous.restriction-1")}</h3>
                    <h6>{t("miscellaneous.restriction-1")} 18.176.5.148</h6>
                    <p>
                    {t("miscellaneous.restriction-3")}
                    </p>
                    <Link className="btn enter-btn-blue"
                        to={{ pathname: "/contact-us" }}>
                        {t("contact-us.contact-us")}
                      </Link>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="miscellaneous mobile">
            <SideBar />
            <div className="m-container">
              <div className="miscellaneous-content">
                <img src={img["restriction.webp"]} alt=" restriction " />
                <h4>{t("miscellaneous.restriction-1")}</h4>
                <h6>{t("miscellaneous.restriction-2")} 18.176.5.148</h6>
                <p>
                {t("miscellaneous.restriction-3")}
                </p>
                <Link className="btn-gradient-blue"
                    to={{ pathname: "/contact-us" }}>
                    {t("contact-us.contact-us")}
                  </Link>
              </div>
            </div>
          </section>
        )}
      </Auxiliary>
    );
  }
}

export default  (withNamespaces("translation")(RestrictionPageMobile));
