import React from "react";
import PropTypes from "prop-types";
import styles from './ValidationError.module.css';

const ValidationError = (props) => {
    let display = null;
    let touched = props.touched === undefined ? false : props.touched;
    if (touched && props.message !== undefined && props.message.length !== 0) {
        display = (
            <div id={props.formName} className={`${styles.alert} py-0 m-0`} role="alert">
                {/* <ul className="p-0 m-0" style={{listStyle:"none"}}>*/}
                <ul className="list-group" >
                    {props.message.map((m, index) => {
                        return <li key={index} className="text-danger">{m}</li>;
                    })}
                </ul>
            </div>
        );
    }
    return display;
};

export default ValidationError;

ValidationError.propTypes = {
    formName: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    touched: PropTypes.bool,
};
