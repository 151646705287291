import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

//Store
import { getGameVendorList } from "../../store/ducks/general/actions";
import { postLogOut } from "../../store/ducks/auth/actions";
import { getCountUnreadInboxAlert } from "../../store/ducks/inbox/actions";
import { getFeaturedPromotion } from "../../store/ducks/home/actions";
import { getTopProfile } from "../../store/ducks/profile/actions";
import { postLogin } from "../../store/ducks/auth/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";

import LoadingPage from "../LoadingPage/LoadingPage";

export class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSieBar: false,
      login: localStorage.getItem("accessToken") ? true : false,
      loading: false,
      unreadMsgCnt: 0,
      msgLoading: true,
      topProfileData: [],

      deviceOs: "",

      currentEvent: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      let params = {};
      params.lang_id = this.props.currentUser.data.language_id;
      this.props.getCountUnreadInboxAlert(params);

      this.sidebarIntervalId = setInterval(() => {
        this.props.getCountUnreadInboxAlert(params);
      }, 10000);
    }
    this.mobileOsDetect();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.logout_data !== this.props.logout_data) {
      if (nextProps.logout_data.responseCode === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("google_user_info");
        sessionStorage.removeItem("google_user_info");
        localStorage.removeItem("showAngbao");
        // localStorage.removeItem('changeCountry');
        // localStorage.removeItem('lang');
        localStorage.clear();
        this.setState({
          loading: false,
        });
        window.location.reload();
      } else {
        return false;
      }
    }

    if (nextProps.unreadMessagesCnt !== undefined && nextProps.unreadMessagesCnt !== this.props.unreadMessagesCnt) {
      if (nextProps.unreadMessagesCnt.msgType === "success" && nextProps.unreadMessagesCnt.responseCode === 200) {
        let unreadCnt = nextProps.unreadMessagesCnt.data.totalCount;
        unreadCnt = unreadCnt >= 99 ? 99 : unreadCnt;
        this.setState({
          msgLoading: false,
          unreadMsgCnt: unreadCnt,
        });
      }
    }

    if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
      if (nextProps.topProfileData.responseCode === 200) {
        this.setState({
          topProfileData: nextProps.topProfileData.data,
        });
      }
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.sidebarIntervalId);
  }

  toggleSideBar = () => {
    this.setState({
      activeSieBar: !this.state.activeSieBar,
    });
  };

  handleSignOutHandler = () => {
    this.props.postLogOut();
    this.setState({
      // activeSieBar: false
      loading: true,
    });
  };

  //will remove after ios version is come out
  mobileOsDetect = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.setState({
        deviceOs: "Windows Phone",
      });
    }
    if (/android/i.test(userAgent)) {
      this.setState({
        deviceOs: "android",
      });
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({
        deviceOs: "ios",
      });
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const avatar = this.imageImport(
      require.context("../../assets/images/profile/profile-icons", false, /\.(png|jpe?g|svg)$/)
    );
    const navIcon = this.imageImport(
      require.context("../../assets/images/profile/mobile/icons", false, /\.(png|jpe?g|svg)$/)
    );
    // const icon = this.imageImport(
    //   require.context(
    //     "../../assets/images/icons/mobile",
    //     false,
    //     /\.(png|jpe?g|svg)$/
    //   )
    // );
    const img = this.imageImport(require.context("../../assets/images/", false, /\.(png|jpe?g|svg|webp)$/));
    const flags = this.imageImport(require.context("../../assets/images/flags", false, /\.(png|jpe?g|svg|webp)$/));
    return (
      <div className="side-bar-mobile">
        {this.state.loading && <LoadingPage />}
        <div className="sb-toggle-btn" onClick={this.toggleSideBar}>
          <Icon icon="ph:squares-four-fill" />
          {(() => {
            if (!this.state.msgLoading) {
              if (this.state.unreadMsgCnt > 0) {
                return <span className="notify-red-dot" />;
              }
            }
          })()}
        </div>
        <NavLink to={{ pathname: "/" }} className="sb-logo">
          {this.state.currentEvent === "merdeka" && this.props.currentUser.data.country_id !== 4 ? (
            <img className="w-100" src={img["logo-merdeka.png"]} />
          ) : this.state.currentEvent === "mooncake" && this.props.currentUser.data.country_id !== 4 ? (
            <img src={img["logo-mooncake.png"]} />
          ): this.state.currentEvent === "halloween"? (
            <img src={img["logo-halloween.png"]} />
            ): this.state.currentEvent === "christmas"? (
              <img src={img["logo-christmas.png"]} />
            ): this.state.currentEvent === "cny"? (
              <img src={img["logo-cny.png"]} />
            ): this.state.currentEvent === "raya"? (
              <img src={img["logo-raya.png"]} />
            ): this.state.currentEvent === "uefa"? (
              <img src={img["logo-uefa.png"]} />
            ): this.state.currentEvent === "anni"? (
              <img src={img["logo-anniversary.png"]} />
            ):(
            <img className="w-100" src={img["merchant-logo.png"]} />
          )}
        </NavLink>

        {/* {!this.state.login && (
          <NavLink to={{ pathname: "/login" }} className="btn btn-white sb-login-register">
            {t("page-header.login")}/{t("page-header.register")}
          </NavLink>
        )} */}
        {/* {process.env.REACT_APP_ENV === "staging" && ( */}
              <NavLink to={{ pathname: "/region-language" }}>
                <div className="language-btn-m">
                <img src={flags[this.props.currentUser.data.country_code + ".webp"]} alt="flags" />
                </div>
              </NavLink>
        {/* )} */}

        <div className={`sb-menu pb-3 ${this.state.activeSieBar ? "open" : ""}`}>
          {/* <NavLink to={{ pathname:"/" }}><img className="sb-banner" src={img["side-bar-banner.jpg"]} alt="banner" /></NavLink> */}
          {this.state.login ? (
            <NavLink to={{ pathname: "/personal-information" }}>
              <div className="pi-m-info">
                <div role="button" className="user-profile-container">
                  <img
                    className="user-profile-img"
                    src={
                      this.state.topProfileData &&
                      this.state.topProfileData.topProfile &&
                      this.state.topProfileData.topProfile.profile_picture
                        ? this.state.topProfileData.topProfile.profile_picture
                        : avatar["male-7.jpg"]
                    }
                    alt="profile img"
                  />
                </div>
                <div className="user-info">
                  <p className="us-name pe-2">{this.props.currentUser.data.username}</p>
                  <p className="vip-icon">
                    {t("vip.vip")}
                    {this.props.currentUser.data.user_group_level}
                  </p>
                </div>
                <Icon icon="uil:angle-right" />
              </div>
            </NavLink>
          ) : (
            <div className="sb-banner">
              {this.state.currentEvent === "christmas" ? (
                <img src={img["logo-christmas.png"]} />
              ) : this.state.currentEvent === "cny" ? (
                <img src={img["logo-cny.png"]} />
              ) : this.state.currentEvent === "raya" ? (
                <img src={img["logo-raya.png"]} />
              ) : (
                <img src={img["merchant-logo.png"]} />
              )}
            </div>
          )}
          <ul className="sb-list">
            {this.state.login && (
              <li>
                <NavLink to={{ pathname: "/messages" }}>
                  <img src={navIcon["message.svg"]} alt="message" />
                  <p>{t("message.message")}</p>
                  <div className="badge">
                    {(() => {
                      if (!this.state.msgLoading) {
                        if (this.state.unreadMsgCnt > 0) {
                          return this.state.unreadMsgCnt;
                        } else {
                          return null;
                        }
                      }
                    })()}
                  </div>
                </NavLink>
              </li>
            )}
            {/* <li>
              <NavLink to={{ pathname: "/feedback" }}>
                <img src={navIcon["feedback.svg"]} alt="feedback" />
                <p>Feedback</p>
                <div className="badge">13</div>
              </NavLink>
            </li> */}
            <li>
              <NavLink to={{ pathname: "/about-us" }}>
                <img src={navIcon["about-us.svg"]} alt="about-us" />
                <p>{t("footer.about-us")}</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={{ pathname: "/privacy-policy" }}>
                <img src={navIcon["security.svg"]} alt="policy privacy" />
                <p>{t("footer.privacy-policy")}</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={{ pathname: "/responsible-gaming" }}>
                <img src={navIcon["resp-gaming.svg"]} alt="resp-gaming" />
                <p>{t("footer.responsible-gaming")}</p>
              </NavLink>
            </li>
            <li>
              <NavLink to={{ pathname: "/info-centre" }}>
                <img src={navIcon["info-centre.svg"]} alt="info-centre" />
                <p>{t("footer.info-centre")}</p>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to={{ pathname: "/sponsor" }}>
                <img src={img["sponsor-icon-bottom-nav-mobile-active.webp"]} alt="sponsor" />
                <p>{t("global.sponsor")}</p>
              </NavLink>
            </li> */}
            <li>
              <NavLink to={{ pathname: "/contact-us" }}>
                <img src={navIcon["contact-us.svg"]} alt="contact-us" />
                <p>{t("footer.contact-us")}</p>
              </NavLink>
            </li>

            {/* {this.state.deviceOs === "android" && ( */}
              <li>
                <NavLink to={{ pathname: "/download-app" }}>
                  <img src={navIcon["download-app.svg"]} alt="app" />
                  <p>{t("download-app.download-app")}</p>
                </NavLink>
              </li>
            {/* )} */}
            <li>
              <NavLink to={{ pathname: "/region-language" }}>
                <img src={navIcon["global.svg"]} alt="global" />
                <p>{t("footer.region-language")}</p>
              </NavLink>
            </li>
          </ul>
          {this.state.login ? (
            <div className="sb-btn gap-3">
              <button className="btn btn-gray hm-5" onClick={this.handleSignOutHandler}>
                {t("page-header.logout")}
              </button>
            </div>
          ) : (
            <div className="sb-btn gap-3">
              <NavLink className="btn btn-gradient-blue hm-5" to={{ pathname: "/login" }}>
                {t("page-header.login")}
              </NavLink>
              <NavLink className="btn btn-white hm-5" to={{ pathname: "/register" }}>
                {t("page-header.join-now")}
              </NavLink>
            </div>
          )}
        </div>
        <div className="overlay" onClick={this.toggleSideBar}></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    currentUser: state.currentUser,
    logout_data: auth.logout_data,
    unreadMessagesCnt: state.inbox.count_unread_inbox_alert_data,
    featured_promos_data: state.home.featured_promos_data,
    topProfileData: state.profile.top_profile_data,
    login_data: auth.login_data,
  };
};

const mapDispatchToProps = {
  getGameVendorList,
  postLogOut,
  getCountUnreadInboxAlert,
  getFeaturedPromotion,
  getTopProfile,
  postLogin,
  getUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces("translation")(SideBar)));
