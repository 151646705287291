import React, { Component } from "react";

//Miscellaneous

//Icons
import { Icon } from "@iconify/react";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  render() {
    return (
      <nav className="pagination-col">
        <ul className="pagination">
          <li className="page-item">
            <a
              className="page-link"
              href="#/"
              aria-label="Previous"
              disabled
            >
              <span aria-hidden="true">
                <Icon icon="uil:angle-left-b" />
              </span>
            </a>
          </li>
          <li className="page-item active">
            <a className="page-link" href="#/">
              1
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/" aria-label="Next">
              <span aria-hidden="true">
                <Icon icon="uil:angle-right-b" />
              </span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
