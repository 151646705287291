import * as type from './types';
import axios from '../../../common/config/axios';

export const showBanner = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    data.lang_id = getState().currentUser.data.language_id;
    axios.get('banner/get_banner', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.SHOW_BANNER,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};
