import React, { Component } from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
// import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import ValidationError from "../../../components/ValidationError/ValidationError";
import StringInput from "../../../components/Input/StringInput/StringInput";
// import Select from "../../../components/Input/Select/Select";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import { default as ReactSelect } from "react-select";

export class MobileVerification extends Component {
    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };
    render() {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));

        const ChangePasswordSchema = Yup.object().shape({
            current_password: Yup.string()
                .min(6, t("validation.minLength", { 0: 6 }))
                .required(t("validation.required")),
            new_password: Yup.string()
                .min(6, t("validation.minLength", { 0: 6 }))
                .required(t("validation.required")),
            confirm_password: Yup.string()
                .min(6, t("validation.minLength", { 0: 6 }))
                .required(t("validation.required"))
                .when("new_password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf([Yup.ref("new_password")], t("validation.confirm-password-not-match-with-new-password")),
                }),
        });
        return (
            <Auxiliary>
                {this.props.verification === "verification-phone" ? (
                    <section className="verification-m-section pf-m-dashboard on-top d-block pe-auto">
                        <MobileSubHeader
                            className="bg-white"
                            closeModalHandler={() => {
                                this.props.triggleVerification();
                                this.props.closeVerification();
                            }}
                            header={t("profile.mobile-number-verification")}
                            style={{ justifyContent: "unset" }}
                        />

                        <form className="forms-col needs-validation my-3" method="post" onSubmit={this.props.verifyOtpHandler}>
                            <div className="pd-form-m-col">
                                <div className="input-group-col">
                                    <label className="col-form-label p-0 m-0">{t("profile.mobile-number")}</label>
                                    <ValidationError formName="mobile_verify_from" touched={this.props.touched} message={this.props.errorMsgMobile} />
                                    <div className="input-group left has-validation pb-3">
                                        <ReactSelect
                                            classNamePrefix="react-select"
                                            defaultValue={this.props.mobileCountryArray[0]}
                                            options={this.props.mobileCountryArray}
                                            onChange={this.props.handlePromoChange}
                                            formatOptionLabel={(item) => (
                                                <>
                                                    <img src={img[`${item.code}.webp`]} alt={item.image} />
                                                    <p>{item.label}</p>
                                                </>
                                            )}
                                        />

                                        <StringInput
                                            className={`${this.props.errorMsgMobile.contact_no && "was-validated is-invalid"}`}
                                            style={{ width: "70%" }}
                                            type="text"
                                            placeholder={t("profile.mobile-phone-no")}
                                            name="verificationPhoneNo"
                                            defaultValue=""
                                            value={this.props.currentMobileNo}
                                            onChange={(e) => {
                                                this.props.MobileNoInput(e);
                                            }}
                                            minLength={this.props.contact_no_min_limits}
                                            maxLength={this.props.contact_no_max_limits}
                                            minLengthMessage={t("validation.minLength", {
                                                0: this.props.contact_no_min_limits,
                                            })}
                                            maxLengthMessage={t("validation.maxLength", {
                                                0: this.props.contact_no_max_limits,
                                            })}
                                            required
                                            requiredMessage={t("validation.required")}
                                            errorMsg={this.props.errorMsgMobile.contact_no || ""}
                                            formError={this.props.formErrorMobile}
                                        />
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0 m-0">{t("profile.verification-code")}</label>
                                        <div className="verificate-m-input">
                                            <input
                                                type="number"
                                                onChange={(e) => this.props.MobileOtpInput(e)}
                                                className={`form-control ${this.props.sms_otp_code.length > 5 ? "is-valid" : "was-validated"}`}
                                                placeholder={t("profile.enter-verification-code")}
                                            />
                                            {this.props.generateOTPStatus && this.props.check_sending_sms_status ? (
                                                <>
                                                    {/* <small>
                            {t("top-profile.otp-attempt-msg")}{" "}
                            {this.props.check_sending_sms_times} / 5
                          </small> */}
                                                    {this.props.resendOTPCountDownTimer && <small>{this.props.resendOTPCountDownTimer}</small>}
                                                    <button
                                                        className="btn btn-blue"
                                                        type="button"
                                                        onClick={this.props.resendOTPHandler}
                                                        disabled={this.props.generateOTPStatus === true && this.props.resend_otp_button_disabled === true ? true : false}
                                                    >
                                                        {t("forgot-password.request")}
                                                    </button>
                                                    {/* <Button
                            typeButton="button"
                            clicked={(event) =>
                              this.props.resendOTPHandler(event)
                            }
                            classValue="btn btn-underlined"
                            buttonName={this.props.resendOTPCountDownTimer}
                            disabled={
                              this.props.resend_otp_button_disabled ===
                                true
                                ? true
                                : false
                            }
                          /> */}
                                                </>
                                            ) : (
                                                <>
                                                    {/* <small>
                            {t("top-profile.otp-attempt-msg")}{" "}
                            {this.props.check_sending_sms_times} / 5
                          </small> */}

                                                    {/* <small>0s</small> */}
                                                    <button
                                                        className="btn btn-blue"
                                                        type="button"
                                                        onClick={this.props.generateOTPHandler}
                                                        disabled={this.props.generateOTPStatus === true && this.props.resend_otp_button_disabled === true ? "active" : ""}
                                                    >
                                                        {t("forgot-password.request")}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="btn btn-gradient-blue mx-3"
                                    type="submit"
                                    value="Submit"
                                    disabled={!this.props.currentMobileNo || (this.props.sms_otp_code.length < 6 && "active")}
                                >
                                    {t("global.submit")}
                                </button>

                                {/* <p className="sp-mention">
                                    {t("profile.verification-mention-1")} &nbsp;
                                    <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                                    &nbsp; {t("profile.verification-mention-2")}
                                </p> */}
                            </div>
                        </form>
                    </section>
                ) : this.props.verification === "verification-email" ? (
                    <section className="verification-m-section pf-m-dashboard on-top d-block pe-auto">
                        <MobileSubHeader
                            className="bg-white"
                            closeModalHandler={() => {
                                this.props.triggleVerification();
                                this.props.closeVerification();
                            }}
                            header={t("profile.verification-email-address")}
                            style={{ justifyContent: "unset" }}
                        />

                        <form
                            className="forms-col needs-validation my-3"
                            name="email_verification_from"
                            id="email_verification_from"
                            method="post"
                            onSubmit={this.props.verifyEmailOTPHandler}
                            noValidate
                        >
                            <div className="pd-form-m-col">
                                <div className="input-group-col">

                                    {/* <ValidationError
                    formName="email_verification_from"
                    touched={this.props.touched}
                    message={this.props.errorMsgMobile}
                  /> */}
                                    <div className="pb-3">
                                        <label className="col-form-label p-0 m-0">{t("profile.email-address")}</label>
                                        <StringInput
                                            className={`${this.props.errorMsgMobile.email && "was-validated is-invalid"} ps-0`}
                                            type="email"
                                            placeholder={t("profile.email-address")}
                                            name="verificationEmail"
                                            defaultValue=""
                                            value={this.props.email}
                                            onChange={(e) => {
                                                this.props.MobileEmailInput(e);
                                            }}
                                            valid_email="true"
                                            emailMessage={t("validation.email")}
                                            required
                                            requiredMessage={t("validation.required")}
                                            errorMsg={this.props.errorMsgMobile.email || ""}
                                            formError={this.props.formErrorMobile}
                                        />
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0 m-0">{t("profile.verification-code")}</label>
                                        <div className="verificate-m-input">
                                            <input
                                                type="text"
                                                className={`form-control ${this.props.email_otp_code.length > 5 ? "is-valid" : "was-validated is-invalid"}`}
                                                // className="form-control"
                                                placeholder={t("profile.enter-verification-code")}
                                                name="verificationCode"
                                                defaultValue=""
                                                value={this.props.email_otp_code}
                                                onChange={(e) => {
                                                    this.props.MobileEmailOtp(e);
                                                }}
                                            />
                                            {
                                                // this.props.time && <small>{this.props.resendOTPCountDownTimer}</small>
                                                // <small>50s</small>
                                            }
                                            <button className="btn btn-blue" type="button" onClick={this.props.generateEmailOTPHandler} disabled={!this.props.email && "active"}>
                                                {t("forgot-password.request")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                                className="btn btn-gradient-blue mx-3"
                                type="submit"
                                value="Submit"
                                disabled={!this.props.email || (this.props.email_otp_code.length < 6 && "active")}
                            >
                                {t("global.submit")}
                            </button>
                            {/* <p className="sp-mention">
                                {t("profile.verification-mention-1")} &nbsp;
                                <Link to={{ pathname: "/" }}>{t("global.customer-service")}</Link>
                                &nbsp; {t("profile.verification-mention-2")}
                            </p> */}
                        </form>
                    </section>
                ) : (
                    this.props.verification === "verification-acc-pwd" && (
                        <section className="verification-m-section pf-m-dashboard on-top d-block pe-auto">
                            <MobileSubHeader
                                className="bg-white"
                                closeModalHandler={() => {
                                    this.props.triggleVerification();
                                    this.props.closeVerification();
                                }}
                                header={t("profile.edit-account-password")}
                                style={{ justifyContent: "unset" }}
                            />

                            <Formik
                                innerRef={(p) => (this.formik = p)}
                                initialValues={{
                                    current_password: "",
                                    new_password: "",
                                    confirm_password: "",
                                    pwdStrength: "low"
                                }}
                                validationSchema={ChangePasswordSchema}
                                validate={ values => {
                                    if(values.new_password){
                                        const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
                                        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})");
                                        if (strongRegex.test(values.new_password)) {
                                            values.pwdStrength = "strong";
                                        } else if (mediumRegex.test(values.new_password)) {
                                            values.pwdStrength = "normal";
                                        } else if (!mediumRegex.test(values.new_password) && !strongRegex.test(values.new_password)) {
                                            values.pwdStrength = "low";
                                        }
                                    }
                                }}
                                onSubmit={(values, actions) => {
                                    this.props.updateLoginPasswordHandler(values, actions);
                                }}
                            >
                                {({ errors, touched, values }) => (
                                    <Form
                                        className="forms-col my-3"
                                        // method="post"
                                        name="change_password_from"
                                        id="change_password_from"
                                    // onSubmit={this.props.updateLoginPasswordHandler}
                                    // noValidate
                                    >
                                        <div className="pd-form-m-col">
                                            <div className="input-group-col">
                                                {/* <ValidationError formName="change_password_from" touched={this.props.touched} message={this.props.error_message} /> */}
                                                <div className="py-2">
                                                    <label className="col-form-label lh-1 p-0 m-0">{t("profile.old-password")}</label>
                                                    <div className="input-group right has-validation">
                                                        {(this.props.errorMsgMobile.current_password || touched.current_password) ? (
                                                            <Field
                                                                name="current_password"
                                                                type={`${this.props.showOldPwd ? "text" : "password"}`}
                                                                className={`form-control ${this.props.errorMsgMobile.current_password || (touched.current_password && errors.current_password) ? `is-invalid` : `is-valid`}`} 
                                                                minLength="8"
                                                                maxLength="15"
                                                                onKeyUp={() => this.props.handlePasswordChange()}
                                                            />
                                                        ) : (
                                                            <Field name="current_password" type="password" className={`form-control`}
                                                                minLength="8"
                                                                maxLength="15"
                                                            />
                                                        )}

                                                        <span className="input-group-text">
                                                            <button type="button" className="btn icon" onClick={this.props.MobileShowCurrentPwd}>
                                                                {this.props.showOldPwd ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                            </button>
                                                        </span>
                                                        {(this.props.errorMsgMobile.current_password || (touched.current_password && errors.current_password)) && ( 
                                                            <div className="invalid-feedback">{(errors.current_password) ? errors.current_password : this.props.errorMsgMobile.current_password}</div> 
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="py-2 border-top">
                                                    <div className="d-flex align-items-center">
                                                        <label className="col-form-label lh-1 p-0 m-0">{t("profile.new-password")}</label>
                                                        <div className="pi-sec-lvl-col ms-3">
                                                            <div className="pi-sec-lvl-content ">
                                                                <div className={`pi-sec-lvl-bar ${values.pwdStrength} sec-m-lvl`}>
                                                                    <p>
                                                                        {values.pwdStrength === "low"
                                                                            ? t("register.low")
                                                                            : values.pwdStrength === "normal"
                                                                                ? t("register.normal")
                                                                                : t("register.strong")}
                                                                    </p>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group right has-validation">
                                                        {(this.props.errorMsgMobile.new_password || touched.new_password) ? (
                                                            <Field
                                                                name="new_password"
                                                                type={`${this.props.showPwd ? "text" : "password"}`}
                                                                className={`form-control ${this.props.errorMsgMobile.new_password || (touched.new_password && errors.new_password) ? `is-invalid` : `is-valid`}`}  
                                                                minLength="8"
                                                                maxLength="15"
                                                                onKeyUp={() => this.props.handlePasswordChange()}
                                                            />
                                                        ) : (
                                                            <Field name="new_password" type="password" className={`form-control`}
                                                                minLength="8"
                                                                maxLength="15"
                                                            />
                                                        )}

                                                        <span className="input-group-text">
                                                            <button type="button" className="btn icon" onClick={this.props.MobileShowNewPwd}>
                                                                {this.props.showPwd ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                            </button>
                                                        </span>
                                                        {(this.props.errorMsgMobile.new_password || (touched.new_password && errors.new_password)) && (  
                                                            <div className="invalid-feedback">{(errors.new_password) ? errors.new_password : this.props.errorMsgMobile.new_password}</div>  
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="py-2 border-top">
                                                    <label className="col-form-label lh-1 p-0 m-0">{t("profile.confirm-password")}</label>
                                                    <div className="input-group right has-validation">
                                                        {(this.props.errorMsgMobile.confirm_password || touched.confirm_password) ? (
                                                            <Field
                                                                name="confirm_password"
                                                                type={`${this.props.showPwdConfirmation ? "text" : "password"}`}
                                                                className={`form-control ${this.props.errorMsgMobile.confirm_password || (touched.confirm_password && errors.confirm_password) ? `is-invalid` : `is-valid`}`}  
                                                                minLength="8"
                                                                maxLength="15"
                                                            />
                                                        ) : (
                                                            <Field name="confirm_password" type="password" className={`form-control`}
                                                                minLength="8"
                                                                maxLength="15"
                                                            />
                                                        )}

                                                        <span className="input-group-text">
                                                            <button type="button" className="btn icon" onClick={this.props.MobileShowConfirmPwd}>
                                                                {this.props.showPwdConfirmation ? <Icon icon="ph:eye" /> : <Icon icon="ph:eye-closed" />}
                                                            </button>
                                                        </span>
                                                        {(this.props.errorMsgMobile.confirm_password || (touched.confirm_password && errors.confirm_password)) && (  
                                                            <div className="invalid-feedback">{(errors.confirm_password) ? errors.confirm_password : this.props.errorMsgMobile.confirm_password}</div>  
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                                {t("global.submit")}
                                            </button>
                                            {/* <p className="sp-mention">
                                                {t("profile.verification-mention-1")} &nbsp;
                                                <Link to={{ pathname: "/" }}> {t("global.customer-service")}</Link>
                                                &nbsp;  {t("profile.verification-mention-2")}
                                            </p> */}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </section>
                    )
                )}
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MobileVerification);
