import * as type from './types';
// import * as type from "../announcement/types";
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const getCountry = (res_data) => dispatch => {
    axios.get('country', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_COUNTRY_AND_LANGUAGES,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_COUNTRY_AND_LANGUAGES,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const getAnnouncement = (res_data) => dispatch => {
    axios.get('announcements', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_ANNOUNCEMENT,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_ANNOUNCEMENT,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const getFeaturedPromotion = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    res_data.lang_id = getState().currentUser.data.language_id;
    axios.get('promotion/get_featured_promotion', {
        params: res_data
        }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_FEATURED_PROMOS,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_FEATURED_PROMOS,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const getSabaOdds = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    data.language = getState().currentUser.data.language_code;
    // console.log(data);
    axios.get('home/saba_odds', {
        params: data
        }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.SABA_ODDS,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.SABA_ODDS,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};