import { Component } from "react";

import { Icon } from "@iconify/react";

class GamePagination extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    renderLink = total => {
        let x = [];
        let z = [];
        for (let y = 1; y <= total; y++) {
            let active = this.props.curPage === y ? "active" : "";
            if (this.props.curPage !== y) {
                x.push(<li className={`page-item ${active}`} key={y}><button type="button" onClick={(event) => this.props.navigateList(event, 'page', y)} className="page-link">{y}</button></li>);
            }
            else {
                x.push(<li className={`page-item disabled ${active}`} key={y}><button type="button" className="page-link">{y}</button></li>);
            }
        }

        // pagination behavior
        if(x.length>5){
            if(this.props.curPage>3){
                if(this.props.curPage===total){
                    // last page
                    x.forEach((item) => {
                        let key = Number(item.key);
                        if(this.props.curPage-key===4 || this.props.curPage-key===3 || this.props.curPage-key===2 || 
                            this.props.curPage-key===1 || this.props.curPage===key){
                            z.push(item);
                        }
                    });
                }
                else if(this.props.curPage-total === -1){
                    // the page before last page
                    x.forEach((item) => {
                        let key = Number(item.key);
                        if(this.props.curPage-key===3 || this.props.curPage-key===2 || this.props.curPage-key===1 || this.props.curPage===key || 
                            this.props.curPage-key===-1){
                            z.push(item);
                        }
                    });
                }
                else{
                    // normal condition
                    x.forEach((item) => {
                        let key = Number(item.key);
                        if(this.props.curPage-key===2 || this.props.curPage-key===1 || this.props.curPage===key || 
                            this.props.curPage-key===-1 || this.props.curPage-key===-2){
                            z.push(item);
                        }
                    });
                }
            }
            else{
                // display first 5 pages only if curPage is not 4
                for(let a=0;a<5;a++){
                    z.push(x[a]);
                }
            }
        }
        else{
            // if total page less than 5
            x.forEach((item) => {
                z.push(item);
            });
        }
        return z;
    }

    render(){
        return(
            <>
                {this.props.data && this.props.data.length>0 && this.props.totalPage>1 && (
                    // <nav className="mt-4">
                    //     <ul className="pagination justify-content-center">
                    //         <li onClick={this.props.curPage !== 1 ? (event) => this.props.navigateList(event, 'prev') : null} className="page-item">
                    //             <div className="page-link-arrow" aria-label="Previous">
                    //                 <span aria-hidden="true">
                    //                     <Icon icon="uil:angle-left-b" />
                    //                 </span>
                    //             </div>
                    //         </li>
                    //         {this.renderLink(this.props.totalPage)}
                    //         <li onClick={this.props.curPage !== this.props.totalPage ? (event) => this.props.navigateList(event, 'next') : null} className="page-item">
                    //             <div className="page-link-arrow" aria-label="Next">
                    //                 <span aria-hidden="true">
                    //                     <Icon icon="uil:angle-right-b" />
                    //                 </span>
                    //             </div>
                    //         </li>
                    //     </ul>
                    // </nav>
                    <nav className="pagination-col">
                        <ul className="pagination">
                            <li className="page-item">
                                <button type="button" className="page-link" onClick={this.props.curPage !== 1 ? (event) => this.props.navigateList(event, 'prev') : null}>
                                    <span aria-hidden="true">
                                        <Icon icon="uil:angle-left-b" />
                                    </span>
                                </button>
                            </li>
                            {this.renderLink(this.props.totalPage)}
                            <li className="page-item">
                                <button type="button" className="page-link" onClick={this.props.curPage !== this.props.totalPage ? (event) => this.props.navigateList(event, 'next') : null}>
                                    <span aria-hidden="true">
                                        <Icon icon="uil:angle-right-b" />
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </>
        );
    }
}

export default GamePagination;