import React, { Component } from 'react';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
// import { Icon } from "@iconify/react";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';

export class MobilePromos extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };
  render() {
    const { t } = this.props;
    
    const truncate = (value) =>
    value?.length > 105 ? `${value.substring(0, 102)}...` : value;

    return (
      <Auxiliary>
        <section className='promos-mobile-section'>
          <div className='promos-m-header bg-white'>
            <MobileSubHeader 
              header={t("promos.promotions")}
              style={{justifyContent:"center"}}
            />
            <ul className='navbar-nav'>
              <li className='nav-item' onClick={(event) => this.props.promoCategoryHandler(event, '')}>
                <a href="#/" className={`nav-link ${ this.props.currentSelectPromoCategoryId === "" ? "active" : ""}`}>{t("global.all")}</a>
              </li>
              {
                this.props.promotion_categories && this.props.promotion_categories.map((item, index) => (
                  <li className='nav-item' key={index} onClick={(event) => this.props.promoCategoryHandler(event, item.id)}>
                    <a href="#/" className={`nav-link ${this.props.currentSelectPromoCategoryId === item.id ? "active" : ""}`}>{t(`promos.${item.title}`)}</a>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className='promos-m-list gap-3'>
            {
              this.props.promosList &&
              this.props.promosList.map((item, index) => (
                <Link to={{ pathname: "/promos-detail/" + item.promotionId, params: { promo_id: item.promotionId } }} key={index}>
                  <div className='promos-m'>
                    <LazyLoadImage className="promos-m-image" src={item.mobileImage} alt={item.promotionName} effect="blur" />
                    {/* <img className="promos-m-title" src={img[`${item.promotionCategoriesTitle.toLowerCase()}-cat.png`]} alt={item.promotionCategoriesTitle} /> */}
                    <div className='promos-m-content'>
                      <p>{item.promotionName}</p>
                      <div className='promos-date'>
                        {/* <Icon icon="mdi:clock-time-three-outline" /> */}
                        <span>{truncate(item.promotionSubContent)!=="null"?truncate(item.promotionSubContent):""}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        </section>

      </Auxiliary>

    );
  }
}

export default (withNamespaces("translation")(MobilePromos));
