import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const postForgotPassword = (res_data) => dispatch => {
    axios.post('forgot-password/reset-password', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.POST_FORGOT_PASSWORD,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.POST_FORGOT_PASSWORD,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const verifyForgotPassword = (res_data) => dispatch => {
    axios.get('forgot-password/verify/'+ res_data['username'])
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.VERIFY_FORGOT_PASSWORD,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.VERIFY_FORGOT_PASSWORD,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const postForgotPasswordEmail = (res_data) => dispatch => {
    axios.post('forgot-password/reset-password-email', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.POST_SEND_FORGOT_PASSWORD_EMAIL,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.POST_SEND_FORGOT_PASSWORD_EMAIL,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const checkForgotPassword = (res_data) => dispatch => {
    axios.post('forgot-password/check-reset-password', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.CHECK_FORGOT_PASSWORD,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.CHECK_FORGOT_PASSWORD,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const generateForgotPasswordOTP = (res_data) => dispatch => {
    axios.post('forgot-password/generateForgetPasswordOTP', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GENERATE_FORGOT_PASSWORD_OTP,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GENERATE_FORGOT_PASSWORD_OTP,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const resendForgotPasswordOTP = (res_data) => dispatch => {
    axios.post('forgot-password/resendForgetPasswordOTP', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.RESEND_FORGOT_PASSWORD_OTP,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.RESEND_FORGOT_PASSWORD_OTP,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const verifyForgotPasswordOTP = (res_data) => dispatch => {
    axios.post('forgot-password/verifyForgetPasswordOTP', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.VERIFY_FORGOT_PASSWORD_OTP,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.VERIFY_FORGOT_PASSWORD_OTP,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const postForgotPasswordOTP = (res_data) => dispatch => {
    axios.post('forgot-password/reset-password-otp', res_data)
        .then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.POST_FORGOT_PASSWORD_OTP,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.POST_FORGOT_PASSWORD_OTP,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};