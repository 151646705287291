import * as actionType from './types';
import axios from '../../../common/config/axios';
import { isDesktop } from 'react-device-detect';

export const getVendorList = (data) => (dispatch, getState) =>
{
  data.country_id = getState().currentUser.data.country_id;
    axios.get(`${(isDesktop) ? 'sport' : 'home'}/vendor_list`, {params: data})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.VENDOR_LIST,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const accessGame = (props, vendor) => dispatch =>
{
    // console.log(props);
    axios.post(`${vendor}/get_game_url`, props)
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.SPORT_GAME_ACCESS,
        value: response.data,
        actionFrom: props.action_from
      })
    })
    .catch(err =>
    {

    });
};