import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Miscellaneous
import Slider from "react-slick";
import { showBanner } from "../../store/ducks/banners/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";

//Components
import PrizesPool from "../../components/Banner/PrizesPool";
import Announcement from "../../components/Announcement/Announcement";

//Icons
import { Icon } from "@iconify/react";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktop_image_preview: [],
      desktop_image_name: [],
      desktop_image_link: [],
      category: "",
      lang_id: "",
      data: [],

      openModal: false,
      openDropDown: false,
      loading: true,

      currentEvent: "",
    };
  }
  componentDidMount() {
    // if (!localStorage.getItem("changeCountry") && !localStorage.getItem("accessToken")) {
    //     let domains = domainMapping;
    //     let domainContent = null;
    //     if(domains.my.key.includes(window.location.host)){
    //         domainContent = domains.my.data;
    //     }
    //     else if(domains.th.key.includes(window.location.host)){
    //         domainContent = domains.th.data;
    //     }
    //     else if(domains.id.key.includes(window.location.host)){
    //         domainContent = domains.id.data;
    //     }
    //     else if(domains.vn.key.includes(window.location.host)){
    //         domainContent = domains.vn.data;
    //     }
    //     else if(domains.us.key.includes(window.location.host)){
    //         domainContent = domains.us.data;
    //     }

    //     if(domainContent){
    //         this.fetchLoadingCountryData(domainContent[0],domainContent[1],domainContent[2],domainContent[3],domainContent[4],domainContent[5]);
    //     }
    //     else{
    //         // default to my
    //         let language_code = (this.props.currentUser && this.props.currentUser.language_code) ? this.props.currentUser.language_code : 'en';
    //         let language_id = (this.props.currentUser && this.props.currentUser.language_id) ? this.props.currentUser.language_id : 1;
    //         this.fetchLoadingCountryData('MY', 1, language_code, language_id, 'Asia/Kuala_Lumpur', 1);
    //     }
    // } else {
    this.fetchData();
    // }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.show_banner_data !== this.props.show_banner_data) {
      if (nextProps.show_banner_data.data.responseCode === 200) {
        const {
          desktop_image_preview,
          desktop_image_name,
          desktop_image_link,
          category,
        } = this.state;

        nextProps.show_banner_data.data.data.map((item, i) => {
          desktop_image_preview[item] = item.desktop_image;
          desktop_image_name[item] = item.desktop_image_name;
          desktop_image_link[item] = item.desktop_image_link;
          return item;
        });

        this.setState({
          loading: false,
          desktop_image_preview,
          desktop_image_name,
          desktop_image_link,
          category,
        });
      } else {
        return false;
      }
    }
    return true;
  }

  fetchData() {
    const showBannerData = {};
    // showBannerData.country_id = this.props.currentUser.country_id || 1;
    // showBannerData.lang_id = this.props.currentUser.language_id || 1;
    showBannerData.category = this.props.category;
    this.props.showBanner(showBannerData);
  }

  // fetchLoadingCountryData(country_code, country_id, language_code, language_id, time_zone, member_group_id) {
  //     const data = {};
  //     data.country_code = country_code;
  //     data.country_id = country_id;
  //     data.language_code = language_code;
  //     data.language_id = language_id;
  //     data.time_zone = time_zone;
  //     data.member_group_id = member_group_id;

  //     this.props.getUserData(data);

  //     const showBannerData = {};
  //     showBannerData.country_id = country_id;
  //     showBannerData.category = this.props.category;
  //     this.props.showBanner(showBannerData);
  // }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  redirectUrlHandler = (e, url) => {
    // e.preventDefault();
    window.location.replace(url);
  };

  render() {
    // const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg)$/));
    // const cnyDeco = this.imageImport(
    //   require.context(
    //     "../../assets/images/home/chinese",
    //     false,
    //     /\.(gif|png|jpe?g|svg|webm)$/
    //   )
    // );
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      fade: true,
      speed: 700,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: false,
      cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: false,
          },
        },
      ],
    };
    let defaultBanner;
    let bannerCount = 0;
    if (!this.state.loading) {
      let banners_data = this.props.show_banner_data.data.data;
      defaultBanner = banners_data.map((banner, idx) => {
        bannerCount = bannerCount + 1;

        let url_id = banner.desktop_image_link
          ? banner.desktop_image_link.split("promos/")
          : null;

        if (
          banner.desktop_image_link !== null &&
          url_id[1] > 0 &&
          (banner.redirect_url === null || banner.redirect_url === "")
        ) {
          return (
            <Link
              to={{
                pathname: "/promos-detail/" + url_id[1],
                params: { promo_id: url_id[1] },
              }}
              key={idx}
            >
              {isDesktop ? (
                <img
                  src={banner.desktop_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              ) : (
                <img
                  src={banner.mobile_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              )}
            </Link>
          );
        } else if (banner.redirect_url !== null && banner.redirect_url !== "") {
          return (
            <Link
              to={{ pathname: banner.redirect_url }}
              key={idx}
              onClick={(event) =>
                this.redirectUrlHandler(event, banner.redirect_url)
              }
            >
              {isDesktop ? (
                <img
                  src={banner.desktop_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              ) : (
                <img
                  src={banner.mobile_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              )}
            </Link>
          );
        } else {
          // return <img src={ banner.desktop_image } alt={banner.desktop_image_name} key={idx} effect="blur" />
          //New banner
          // if(process.env.REACT_APP_ENV === 'staging') {
          return (
            <img
              src={isDesktop ? banner.desktop_image : banner.mobile_image}
              alt={banner.desktop_image_name}
              key={idx}
              effect="blur"
            />
          );
          // }else{
          //     return <img src={ banner.desktop_image } alt={banner.desktop_image_name} key={idx} effect="blur" />
          // }
        }
      });
    }

    return (
      <Auxiliary>
        <div className="banner-slick">
          {/* {this.state.currentEvent === "cny" && (
            <div className="banner-deco">
              <img
                className="cny-deco-1"
                src={cnyDeco["cny-deco-1.gif"]}
                alt="cny deco"
              />
              <img
                className="cny-deco-2"
                src={cnyDeco["cny-deco-1.gif"]}
                alt="cny deco"
              />
            </div>
          )} */}

          <div className="slick-container">
            {this.state.loading ? (
              <Skeleton
                count={1}
                height="100%"
                style={{ lineHeight: "unset" }}
              />
            ) : (
              <>
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  {defaultBanner}
                </Slider>
                { window.location.pathname === "/slot" ||
                window.location.pathname.includes("/slot") && isDesktop && 
                  <PrizesPool />
               }
                {bannerCount > 1 && isDesktop && (
                  <div className="slick-arrow">
                    <div
                      className="slick-arrow-btn prev"
                      onClick={() => this.slider.slickPrev()}
                    >
                      <Icon icon="uil:angle-left-b" />
                    </div>
                    <div
                      className="slick-arrow-btn next"
                      onClick={() => this.slider.slickNext()}
                    >
                      <Icon icon="uil:angle-right-b" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <span className="gradient-bg-banner"></span> */}
        <Announcement />
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { banner } = state;
  return {
    show_banner_data: banner.show_banner_data,
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = {
  showBanner,
  getUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
