export const GET_INBOX = "GET_INBOX";
export const SHOW_INBOX = "SHOW_INBOX";
export const DELETE_INBOX = "DELETE_INBOX";
export const READ_INBOX = "READ_INBOX";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const GET_COUNT_UNREAD_INBOX_ALERT = "GET_COUNT_UNREAD_INBOX_ALERT";
export const GET_INBOX_ALERT = "GET_INBOX_ALERT";
export const LOAD_MORE_INBOX_ALERT = "LOAD_MORE_INBOX_ALERT";
export const READ_INBOX_ALERT = "READ_INBOX_ALERT";

export const GET_NOTIFICATION_SETTINGS = "GET_NOTIFICATION_SETTINGS";
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";

