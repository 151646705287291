import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const historyReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.CANCEL]: (state, action) => ({
        ...state,
        cancel: action.value,
    }),
    [type.APPLY_PROMO]: (state, action) => ({
        ...state,
        promo: action.value,
    }),
    [type.LATEST]: (state, action) => ({
        ...state,
        latest: action.value
    }),
    [type.UPDATE_PRIZE]: (state, action) => ({
        ...state,
        update_prize: action.value,
    }),
    [type.GAME_LIST]: (state, action) => ({
        ...state,
        game_list: action.value,
    }),
});

export default historyReducer;