import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
// import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import DomPurify from "../../../components/Sanitize/DomPurify";

//Components 
// import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";

//Actions 
import * as actions from "../../../store/ducks/deposit/actions";
import MobileSelectRadio from "../../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";
import DoItNowDepositModal from "../../../components/DoItNowDepositModal/DoItNowDepositModal";

class MobileEWallet extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            showSelectRadio: false,
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleSelectRadio = () => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() {
        const { t } = this.props;
        const tncImg = this.imageImport(require.context("../../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg)$/));
        const payment = this.imageImport(require.context("../../../assets/images/profile/payment", false, /\.(png|jpe?g|svg)$/));
        return (
            <>
                {this.props.openPromoTncModal && (
                    <Modal
                        isOpen={this.props.openPromoTncModal}
                        closeTimeoutMS={500}
                        portalClassName={`global-modal`}
                        className="modal modal-dialog modal-dialog-centered modal-dialog-scrollable  modal-pd"
                        ariaHideApp={false}
                        onRequestClose={this.props.toggleTncModalHandler}>
                        <div className="modal-content tnc-content">
                            <div className="modal-header">
                                <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleTncModalHandler} />
                            </div>
                            <div className="modal-body table-responsive">
                                <section className="promos-table-section">
                                    <div className="promos-table-container p-0">
                                        <h2>{this.props.showPromosTitle}</h2>
                                        {this.state.showPromosTable !== null && (
                                            <span className="card-shadow">
                                                <DomPurify text={this.props.showPromosTable} />
                                            </span>
                                        )}
                                    </div>
                                </section>

                                <section className="term-section mobile mt-3">
                                    <div className="term-container p-0">
                                        <img src={tncImg["term-condition.svg"]} alt="Terms Condition" />
                                        <div className="term-content">
                                            <DomPurify text={this.props.showPromosContent} />
                                        </div>
                                        <img src={tncImg["term-footer-m.jpg"]} alt="Terms Footer" />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Modal>
                )}
                <DoItNowDepositModal 
                    {...this.state}
                    {...this.props}
                    toggleDoItNowModalHandler={this.props.toggleDoItNowModalHandler}
                />
                <form className="forms-col needs-validation" method="post" id="deposit_form" encType='multipart/form-data' onSubmit={this.props.confirmHandler} noValidate>
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t('deposit.payment-option')}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.paymentGatewayOptions.length > 0 ? (
                                                this.props.paymentGatewayOptions.map((val, idx) => {
                                                    if (val.transaction_slug === "e-wallet" && val.dp_status === 1) {
                                                        return (
                                                            <div className="form-check form-check-inline four-check" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`payment${val.slug}`}
                                                                    value={val.slug}
                                                                    checked={this.props.selectedPaymentGateway === val.slug ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.props.selectPaymentGatewayHandler(val)}>
                                                                    <img className="active" src={this.props.payment[`${val.slug==="doitnow_ewallet"?'doitnow':val.slug}.svg`]} alt={val.slug} />
                                                                    <p>{val.slug.replaceAll('_', ' ')}</p>
                                                                    {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {/* <Icon icon="ic:baseline-alarm" />
                                                                        Deposit in 1 min */}
                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                            <>
                                                                                <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                    {t("deposit.instant")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Icon icon="ic:baseline-alarm" />
                                                                                    {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                            </>
                                                                        )}
                                                                                            
                                                                    </div>                 
                                                                )}
                                                                </label>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                this.props.isOptionLoading === false ? 
                                                <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                {this.props.selectedPaymentGateway === 'secret_pay' && (
                                        <>
                                        <label className="col-form-label d-flex align-items-center">{t('deposit.choose-a-ewallet-type')}:</label>
                                        <div className="input-group-col">
                                            <div className="form-check-group">
                                                {(this.props.ewalletOption.length > 0 && this.props.ewalletOption.length > 0) ? (
                                                        <>
                                                        {this.props.ewalletOption.map((val, idx) => {
                                                            return (
                                                                <div className="form-check form-check-inline four-check" key={idx}>
                                                                    <input
                                                                        className="btn-check"
                                                                        type="radio"
                                                                        name="bank_code"
                                                                        id={`bankOpt${val.walletCode}`}
                                                                        value={val.walletCode}
                                                                        checked={this.props.ewalletCode === val.walletCode ? true : false}
                                                                        onChange={() => { }}
                                                                    />
                                                                    <label className="btn form-check-label" htmlFor={`bankOpt${val.walletCode}`} onClick={() => this.selectEwalletOption(val)}>
                                                                        <img className="active" src={payment[`${val.walletCode==="DUITNOW"?'duitnow':val.walletCode}.svg`]} alt={val.walletCode} />
                                                                        <p>{val.walletName}</p>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    this.state.loadingBank === false ? 
                                                    <span className="text-danger">{t('deposit.invalid-bank')}</span> :
                                                    <Skeleton />
                                                )
                                                }
                                            </div>
                                        </div>
                                        </>
                                    )}
                                </div>

                                <div>
                                    <label className="col-form-label d-flex align-items-center">{t('deposit.promotion')}:
                                        {
                                            this.props.promoOpts &&
                                            <button className="btn btn-gradient-blue tnc-btn" type="button" onClick={this.props.toggleTncModalHandler}>
                                                {t('deposit.tnc-apply')}
                                            </button>
                                        }
                                    </label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            <p className="form-control lh-lg" onClick={this.toggleSelectRadio}>
                                                {
                                                    this.props.promoOpts ?
                                                        (
                                                            this.props.promoOpts.label
                                                        ) : (
                                                            <>{t('history.select-promotion')}</>
                                                        )
                                                }
                                            </p>
                                            {this.props.promoOpts && <span className="input-group-text"><Icon icon="mdi:close-circle" onClick={this.props.promoChangeHandler} /></span>}
                                            <span className="input-group-text" onClick={this.toggleSelectRadio}><Icon icon="uil:angle-right" /></span>
                                        </div>
                                        <MobileSelectRadio
                                            name={"selectPromo"}
                                            toggleHandler={this.toggleSelectRadio}
                                            showSelectRadio={this.state.showSelectRadio}
                                            onChange={this.props.promoChangeHandler}
                                            options={this.props.promoArry}
                                            value={(this.props.promoOpts && this.props.promoOpts.key !== null) ? this.props.promoOpts.key : null}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('transaction-type.bonus-code')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <input 
                                                id="bonusCode"
                                                name="bonusCode"
                                                value={this.props.input.bonusCode}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.bonusCode || ''}
                                                autoComplete="off"
                                                type="text" 
                                                className="form-control" 
                                                placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('deposit.deposit-amount')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                // className="form-control"
                                                className={`form-control ${this.props.errorMsg.amount && 'was-validated is-invalid'}`}
                                                aria-label="Username"
                                                aria-describedby="depositAmtHelp"
                                                value={this.props.input.amount}
                                                placeholder={`${t('deposit.min-max-limit')} ${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.minTransactionAmt || 0.0)}/${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.maxTransactionAmt || 0.0)}`}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                required={true}
                                                min={this.props.minTransactionAmt || 0}
                                                max={this.props.maxTransactionAmt || 0}
                                                step="any"
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                minValueMessage={t("validation.amtMinValue", { 0: this.props.minTransactionAmt })}
                                                maxValueMessage={t("validation.amtMaxValue", { 0: this.props.maxTransactionAmt })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.amount || ''}
                                            />
                                        </div>
                                        <div className="form-check-group deposit-amts">
                                            {this.props.depositAmts && this.props.depositAmts.map((depositAmt, index) => (
                                                <div className="form-check form-check-inline five-check" key={index}>
                                                    <input
                                                        className="btn-check"
                                                        type="radio"
                                                        name="depositAmt"
                                                        id={`depositAmt${depositAmt.value}`}
                                                        value={depositAmt.value}
                                                        onChange={(event) => this.props.onInputChangeHandler(event)}
                                                        checked={Number(this.props.depositAmt) === Number(depositAmt.value) ? true : false}
                                                    />
                                                    <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                        {new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(depositAmt.value || 0.0)}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <small>
                                            {t('deposit.last-deposit-amount')}: <span style={{ fontWeight: '600' }}>{this.props.currentUser.currency}{this.props.lastDeposit}</span>
                                        </small>
                                    </div>
                                </div>
                                <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                    {t('global.confirm')}
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        store: state.deposit.store,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileEWallet));
