import * as types from "./types";
import axios from "../../../common/config/axios";

export const getDailyMission = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    data.language_code = getState().currentUser.data.language_code;
    axios.get("daily_mission/list", { params: data })
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION,
            payload: res
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};

export const getDailyMissionBonuses = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    data.language_code = getState().currentUser.data.language_code;
    if(localStorage.getItem("accessToken")){
        data.member_id = getState().currentUser.data.id;
    }
    axios.get("daily_mission/claim_list", { params: data })
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_BONUSES,
            payload: res
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};

export const claimDailyBonus = data => dispatch => {
    axios.post("daily_mission/claim", data)
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_BONUS_CLAIM,
            payload: res
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};

export const claimExtraBonus = data => dispatch => {
    axios.post("daily_mission/claim_extra_bonus", data)
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_EXTRA_BONUS_CLAIM,
            payload: res
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};