import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const leaderboardReducer = createReducer(initialState) ({
    // Export the response to the react.js to get the response data based on type name
    [type.LEADERBOARD_LIST]: (state = initialState, action) => ({
        ...state,
        leaderboard_data: action.payload,
    }),
    [type.SHOW_WINNER]: (state, action) => ({
        ...state,
        show_winner: action.payload,
    })
});

export default leaderboardReducer;
