import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // data: [],
};

const slotReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.VENDOR_LIST]: (state, action) => ({
        ...state,
        vendor: action.value,
    }),
    [type.GAME_LIST]: (state, action) => ({
        ...state,
        game: action.value,
    }),
    [type.SLOT_GAME_ACCESS]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
});

export default slotReducer;