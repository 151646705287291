import React, { Component } from "react";
import { withNamespaces } from 'react-i18next';
import { connect } from "react-redux";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";

//Components
import DataTable from "../../../components/Datatable/DataTable";
import { numFormat } from "../../../hoc/Shared/utility";

//Icons
// import { Icon } from "@iconify/react";

class Rebate extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {};
    }

    componentDidMount() 
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray("rebate");
            this.props.funcGetList(listingArr);
        }
    }

    render() 
    {
        const { t } = this.props;
        const num_format = numFormat(this.props.currentUser.currency);
        let rsData = [];

        const columns = [
            // {text: 'No', dataField: "no", formatter: this.noFormatter, headerAttrs: { hidden: true }},
            {text: t('history.date'), dataField: "transaction_date"},
            {text: t('history.game-vendor'), dataField: "g_slug"},
            {text: t('history.category'), dataField: "gt_slug"},
            {text: t('history.total-valid-turnover'), dataField: "turnover"},
            {text: t('history.amount'), dataField: "rebate_amt"},
        ];

        if(this.props.data.data)
        {
            rsData = this.props.data.data.map((val, idx) =>
            {
                let vendor = val.g_slug.split('_');
                return {
                    no: idx,
                    transaction_date: val.transaction_date,
                    g_slug: t(`vendor.${vendor[0]}`),
                    gt_slug: t(`game-type.${val.gt_slug}`),
                    turnover: new Intl.NumberFormat("en-US", num_format).format(val.turnover),
                    rebate_amt: new Intl.NumberFormat("en-US", num_format).format(val.rebate_amt),
                };
            });
        }

        return (
            <Auxiliary>
                <Fade duration={1000} triggerOnce>
                <DataTable
                    keyField="no"
                    data={rsData}
                    columns={columns}
                    activePage={this.props.data.activePage}
                    limit={this.props.data.limit}
                    totalItems={this.props.data.totalRecords}
                    paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'activePage')}
                    sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'limit')}
                    loading={this.props.data.loading}
                    hideSearchBar={true}
                    hideTotalRecords={true}
                    classValue="pd-table"
                    component="transaction-history"
                />
                </Fade>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Rebate));
