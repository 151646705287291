import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
// import NoRecords from "../../../components/NoRecords/NoRecords";
import Notice from "./Notice";
// import Activity from "./Activity";
import Announcement from "./Announcement";
import InnerMessage from "./InnerMessage";
import MobileMessage from "./MobileMessage";

//Icons
import { Icon } from "@iconify/react";

import * as actions from "../../../store/ducks/inbox/actions";

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            unreadNotice: 0,
            unreadActivity: 0,
            unreadAnnouncement: 0,
            pageShow: "listing",
            data: [],
            currentId: 0,
            msgType: "",
            msgStatus: 0,
            activePanel: 0,
            depositNotifcation: 1,
            withdrawalNotification: 1,
            rebateNotification: 1,
        };
    }

    componentDidMount() {
        if (localStorage.getItem("accessToken")) {
            // let params = {};
            // params.lang_id = this.props.currentUser.data.language_id;
            // this.props.getCountUnreadInboxAlert(params);

            // this.intervalId = setInterval(() => {
            //   this.props.getCountUnreadInboxAlert(params);
            // }, 10000);

            this.props.notificationSettings();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[shouldComponentUpdate]",nextProps);
        if (nextProps.totalUnread !== undefined && nextProps.totalUnread !== this.props.totalUnread) {
            if (nextProps.totalUnread.responseCode === 200 && nextProps.totalUnread.msgType === "success") {
                let unreadNoticeCnt = nextProps.totalUnread.data.inboxCount;
                let unreadAnnouncementCnt = nextProps.totalUnread.data.notificationCount;
                unreadNoticeCnt = unreadNoticeCnt >= 99 ? 99 : unreadNoticeCnt;
                unreadAnnouncementCnt = unreadAnnouncementCnt >= 99 ? 99 : unreadAnnouncementCnt;
                this.setState({
                    loading: false,
                    unreadNotice: unreadNoticeCnt,
                    unreadAnnouncement: unreadAnnouncementCnt,
                });
            }
        }

        if (nextProps.get_notification_settings_data !== undefined && nextProps.get_notification_settings_data !== this.props.get_notification_settings_data) {
            if (nextProps.get_notification_settings_data.data.responseCode === 200 && nextProps.get_notification_settings_data.data.msgType === "success") {
                this.setState({
                    loading: false,
                    depositNotifcation: nextProps.get_notification_settings_data.data.data.deposit_notification,
                    withdrawalNotification: nextProps.get_notification_settings_data.data.data.withdrawal_notification,
                    rebateNotification: nextProps.get_notification_settings_data.data.data.rebate_notification,
                });
            }
        }

        if (nextProps.update_notification_settings_data !== undefined && nextProps.update_notification_settings_data !== this.props.update_notification_settings_data) {
            if (nextProps.update_notification_settings_data.responseCode === 200 && nextProps.update_notification_settings_data.msgType === "success") {
                // this.setState({
                //   loading: false,
                //   depositNotifcation: nextProps.get_notification_settings_data.data.deposit_notification,
                //   withdrawalNotification: nextProps.get_notification_settings_data.data.withdrawal_notification,
                // });
                this.props.getNotificationSettings();
            }
        }

        return true;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    handleChangePage = (event, page, val, data, type, status, activePage) => {
        this.setState({
            pageShow: page,
            currentId: val,
            data: data,
            msgType: type,
            msgStatus: status,
            activePage: activePage,
        });
    };

    handleDeleteMsg = (event, id, type) => {
        let arr = [];
        arr.push(id);
        const deleteMsgData = {};
        deleteMsgData.inbox_id = arr;
        switch (type) {
            case "notification":
                this.setState(
                    {
                        pageShow: "listing",
                    },
                    () => {
                        this.props.deleteInbox(deleteMsgData);
                    }
                );
                break;
            case "announcement":
                this.setState(
                    {
                        pageShow: "listing",
                    },
                    () => {
                        this.props.deleteNotification(deleteMsgData);
                    }
                );
                break;
            default:
                break;
        }
    };

    handleReadMsg = (id, type) => {
        let arr = [];
        arr.push(id);
        const readMsgData = {};
        readMsgData.inbox_id = arr;
        switch (type) {
            case "notification":
                this.props.readInbox(readMsgData);
                break;
            case "announcement":
                this.props.readNotification(readMsgData);
                break;
            default:
                break;
        }
    };

    handleActivaPanel = (panel) => {
        this.setState({
            activePanel: panel,
        });
    };

    onNotificationsSettingHandler = (e) => {
        let data = {};
        // const { t } = this.props;

        if (e.target.id === "deposit-check") {
            // if(e.target.checked === true) {
            //     window.open(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}&text=${t("message.deposit-notification-message")}`, "_blank");
            // }

            this.setState({
                depositNotifcation: e.target.checked === true ? 1 : 0,
            });

            data.deposit_notification = e.target.checked === true ? 1 : 0;

            this.props.updateNotificationSettings(data);
        } else if (e.target.id === "withdrawal-check") {
            // if(e.target.checked === true) {
            //     window.open(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}&text=${t("message.withdrawal-notification-message")}`, "_blank");
            // }

            this.setState({
                withdrawalNotification: e.target.checked === true ? 1 : 0,
            });

            data.withdrawal_notification = e.target.checked === true ? 1 : 0;

            this.props.updateNotificationSettings(data);
        } else if (e.target.id === "rebate-check") {
            // if(e.target.checked === true) {
            //     window.open(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}&text=${t("message.rebate-notification-message")}`, "_blank");
            // }

            this.setState({
                rebateNotification: e.target.checked === true ? 1 : 0,
            });

            data.rebate_notification = e.target.checked === true ? 1 : 0;

            this.props.updateNotificationSettings(data);
        }
    };

    render() {
        const { t } = this.props;

        const fadeUp = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, 50px ,0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `;
        return (
            <Auxiliary>
                {isDesktop ? (
                    <>
                        {this.state.pageShow === "listing" && (
                            <section className="pf-dashboard">
                                <div className="x-container">
                                    <ProfileSideBar />
                                    <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="pd-card-col d-flex justify-content-between">
                                                    <div>
                                                        <h4>{t("message.message")}</h4>
                                                        <div className="pd-content">
                                                            <div className="msg-tabs-col">
                                                                <ul className="nav nav-tabs" role="tablist">
                                                                    <li className="nav-item" role="presentation">
                                                                        <button
                                                                            className={`nav-link ${this.state.activePanel === 0 ? "active" : ""}`}
                                                                            data-bs-toggle="tab"
                                                                            data-bs-target="#msg-notice"
                                                                            type="button"
                                                                            role="tab"
                                                                            onClick={() => this.handleActivaPanel(0)}
                                                                        >
                                                                            {t("message.notice")}
                                                                            {(() => {
                                                                                if (!this.state.loading) {
                                                                                    if (this.state.unreadNotice > 0) {
                                                                                        return <span className="notify-num">{this.state.unreadNotice}</span>;
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }
                                                                            })()}
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        {/* <button className={`nav-link ${this.state.activePanel===1?"active":""}`} data-bs-toggle="tab" data-bs-target="#msg-activity" type="button" role="tab"
                            onClick={()=>this.handleActivaPanel(1)}
                            >
                              {t("message.activity")}{(()=>{
                                if(!this.state.loading){
                                  if(this.state.unreadActivity>0){
                                    return <span className="notify-num">{this.state.unreadActivity}</span>;
                                  }
                                  else{
                                    return null;
                                  }
                                }
                              })()}
                            </button> */}
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button
                                                                            className={`nav-link ${this.state.activePanel === 2 ? "active" : ""}`}
                                                                            data-bs-toggle="tab"
                                                                            data-bs-target="#msg-announcement"
                                                                            type="button"
                                                                            role="tab"
                                                                            onClick={() => this.handleActivaPanel(2)}
                                                                        >
                                                                            {t("message.announcement")}
                                                                            {(() => {
                                                                                if (!this.state.loading) {
                                                                                    if (this.state.unreadAnnouncement > 0) {
                                                                                        return <span className="notify-num">{this.state.unreadAnnouncement}</span>;
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }
                                                                            })()}
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="msg-whatsapp">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Icon className="me-1" icon="mdi:whatsapp" />
                                                            <p>{t("message.notification-setting")}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center border-top">
                                                            {this.state.loading ? (
                                                                <Skeleton count={1} />
                                                            ) : (
                                                                <>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="deposit-check"
                                                                            onChange={(e) => this.onNotificationsSettingHandler(e)}
                                                                            checked={this.state.depositNotifcation === 1 ? "checked" : ""}
                                                                        />
                                                                        <label className="ms-1" htmlFor="deposit-check">
                                                                            {t("transaction-type.deposit")}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center ms-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="withdrawal-check"
                                                                            onChange={(e) => this.onNotificationsSettingHandler(e)}
                                                                            checked={this.state.withdrawalNotification === 1 ? "checked" : ""}
                                                                        />
                                                                        <label className="ms-1" htmlFor="withdrawal-check">
                                                                            {t("transaction-type.withdrawal")}
                                                                        </label>
                                                                    </div>
                                                                    {/* <div className="d-flex align-items-center ms-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="rebate-check"
                                                                            onChange={(e) => this.onNotificationsSettingHandler(e)}
                                                                            checked={this.state.rebateNotification === 1 ? "checked" : ""}
                                                                        />
                                                                        <label className="ms-1" htmlFor="rebate-check">
                                                                            {t("transaction-type.rebate")}
                                                                        </label>
                                                                    </div> */}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pd-card-col">
                                                    <div className="msg-tabs-content">
                                                        <div className="tab-content">
                                                            <Notice
                                                                tabName="notice"
                                                                handleChangePage={this.handleChangePage}
                                                                activePanel={this.state.activePanel}
                                                                activePage={this.state.activePage}
                                                            />
                                                            <Announcement
                                                                tabName="anouncement"
                                                                handleChangePage={this.handleChangePage}
                                                                activePanel={this.state.activePanel}
                                                                activePage={this.state.activePage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                            </section>
                        )}
                    </>
                ) : (
                    <MobileMessage
                        {...this.state}
                        handleActivaPanel={this.handleActivaPanel}
                        handleChangePage={this.handleChangePage}
                        msgType={this.props.msgType}
                        handleDeleteMsg={this.handleDeleteMsg}
                        handleReadMsg={this.handleReadMsg}
                        activePage={this.state.activePage}
                        onNotificationsSettingHandler={this.onNotificationsSettingHandler}
                    />
                )}
                {this.state.pageShow === "details" && (
                    <InnerMessage
                        data={this.state.data}
                        currentId={this.state.currentId}
                        handleChangePage={this.handleChangePage}
                        msgType={this.state.msgType}
                        handleDeleteMsg={this.handleDeleteMsg}
                        handleReadMsg={this.handleReadMsg}
                        msgStatus={this.state.msgStatus}
                        activePage={this.state.activePage}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("[mapStateToProps]", state);
    return {
        currentUser: state.currentUser,
        totalUnread: state.inbox.count_unread_inbox_alert_data,
        get_notification_settings_data: state.inbox.get_notification_settings_data,
        update_notification_settings_data: state.inbox.update_notification_settings_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountUnreadInboxAlert: (params) => dispatch(actions.getCountUnreadInboxAlert(params)),
        deleteInbox: (params) => dispatch(actions.deleteInbox(params)),
        deleteNotification: (params) => dispatch(actions.deleteNotification(params)),
        readInbox: (params) => dispatch(actions.readInbox(params)),
        readNotification: (params) => dispatch(actions.readNotification(params)),
        notificationSettings: () => dispatch(actions.getNotificationSettings()),
        updateNotificationSettings: (params) => dispatch(actions.updateNotificationSettings(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Messages));
