import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
import { default as ReactSelect } from "react-select";

// Components
import Skeleton from "react-loading-skeleton";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import SideBar from '../../components/SideBar/SideBar';

// let countryList = [];
let contact_no_min_limits = 9;
let contact_no_max_limits = 10;


class MobileForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
      
    }

    shouldComponentUpdate() {
        return true;
    }


    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

  render() {
    const { t } = this.props;
    const img = this.imageImport(require.context("../../assets/images/register/", false, /\.(png|jpe?g|svg|webp)$/));
    const flag = this.imageImport(require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }

    switch (this.props.countryOption) {
        case "MY":
            contact_no_min_limits = 9;
            contact_no_max_limits = 13;
            break;
        case "TH":
            contact_no_min_limits = 8;
            contact_no_max_limits = 8;
            break;
        case "ID":
            contact_no_min_limits = 11;
            contact_no_max_limits = 14;
            break;
        case "VN":
            contact_no_min_limits = 9;
            contact_no_max_limits = 9;
            break;
        case "7":
            contact_no_min_limits = 10;
            contact_no_max_limits = 10;
            break;
        default:
            break;
    }

    let resendOTPCountDownTimer;

    if (this.props.resend_otp_button_disabled === true) {
        resendOTPCountDownTimer = "0" + this.props.time.m + ":" + this.props.time.s;
    } else {
        resendOTPCountDownTimer = "Request";
    }


    return (
      <Auxiliary>
        {/* <video className="login-video mobile" autoPlay loop muted controls={false} playsInline>
          <source src={BackVideo} type="video/mp4" />
        </video> */}
        <section className="register-section register-m-section">
        <SideBar />
        <img src={img[`reglogin-m-bg${imageLanguage}.webp`]} alt="login register image" className="logreg-img" />
          <div className="x-container-no-m">
            <div className="forget-password-wrap text-center mobile">
              <div className="forget-password-content">
                {this.props.activeBtn === "select" ? (
                  <div>
                    <h4>{t("forgot-password.title")} </h4>
                    <p>{t("forgot-password.desc")}</p>
                      <div className="forget-pwd-btn">
                        <button type="button" onClick={() => this.props.changeActiveBtn("email")}>
                          <img src={img["icon-login-email.webp"]} alt="forget pwd email" />
                        </button>
                        <span style={{ color: "$slate-gray-200" }}>or</span>
                        <button type="button" onClick={() => this.props.changeActiveBtn("phone")}>
                          <img src={img["icon-forget-phone.webp"]} alt="forget pwd phone" />
                        </button>
                      </div>
                  </div>
                ) : this.props.activeBtn === "email" ? (
                  <div>
                    <h4>{t("forgot-password.title")} </h4>
                    <p>{t("forgot-password.desc")}</p>
                      <form
                        name="forgot_password_form"
                        id="forgot_password_form"
                        onSubmit={this.props.submitForgotPasswordEmailHandler}
                        className="forgot_password_form needs-validation"
                        noValidate
                        method="post"
                      >
                        <div className="input-group register-global-input">
                          <span className="input-group-text left-icon">
                            <img src={img["icon-login-email.webp"]} alt="forget pwd email" />
                          </span>

                          <StringInput
                            className={`${this.props.errorMsg.email && "was-validated is-invalid"}`}
                            onChange={this.props.handleEmail}
                            id="email"
                            name="email"
                            value={this.props.email}
                            required
                            valid_email="true"
                            requiredMessage={t("validation.required")}
                            emailMessage={t("validation.email")}
                            errorMsg={this.props.errorMsg.email || ""}
                            formError={this.props.formError}
                          />
                        </div>
                      </form>
                  </div>
                ) : this.props.activeBtn === "phone" ? (
                  <div>
                    <h4>{t("forgot-password.title")} </h4>
                    <p>{t("forgot-password.desc")}</p>
                      <form name="phone_forgot_password_form" id="phone_forgot_password_form" method="post" className="password-form reg-and-log-select mobile" onSubmit={this.props.verifyForgotPasswordOTPHandler} noValidate>
                        {this.props.loading ? (
                          <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                        ) : (
                          <>
                            <div className="input-group position-relative">
                              <ReactSelect
                                classNamePrefix="react-select"
                                defaultValue={this.props.countryList.filter((country) => country.code === "MY")}
                                options={this.props.countryList}
                                onChange={this.props.selectCountry}
                                formatOptionLabel={(item) => (
                                  <>
                                    <img src={flag[`${item.image}`]} alt={item.image} />
                                    <p>{item.phone}</p>
                                    <p>{item.name}</p>
                                  </>
                                )}
                              />

                              <StringInput
                                className={`${this.props.errorMsg.contact_no && "was-validated is-invalid"}`}
                                type="text"
                                placeholder="Mobile Phone No."
                                name="verificationPhoneNo"
                                defaultValue=""
                                value={this.props.currentMobileNo}
                                onChange={this.props.currentMobileNoFunction}
                                // onChange={(e) => {
                                //     this.setState({
                                //       currentMobileNo: e.target.value,
                                //     });
                                //   }}
                                minLength={contact_no_min_limits}
                                maxLength={contact_no_max_limits}
                                minLengthMessage={t("validation.minLength", { 0: contact_no_min_limits })}
                                maxLengthMessage={t("validation.maxLength", { 0: contact_no_max_limits })}
                                required
                                requiredMessage={t("validation.required")}
                                errorMsg={this.props.errorMsg.contact_no || ""}
                                formError={this.props.formError}
                              />
                            </div>

                            <div className="position-relative">
                              <div className="input-group register-global-input">
                                <span className="input-group-text left-icon left-text">Insert OTP</span>
                                <NumberInput
                                  className={`form-control forget-pwd-mobile ${this.props.errorMsg.otp_code && "was-validated is-invalid"}`}
                                  type="number"
                                  onChange={this.props.otp_code_Function}
                                  // onChange={(e) => {
                                  //   this.setState({
                                  //     otp_code: e.target.value,
                                  //   });
                                  // }}
                                  placeholder=""
                                  errorMsg={this.props.errorMsg.otp_code || ""}
                                  formError={this.props.formError}
                                  required
                                  requiredMessage={t("validation.required")}
                                  value={this.props.otp_code}
                                  format={
                                    <span className="input-group-text right-btn-bg ">
                                      {this.props.generateOTPStatus && this.props.check_sending_sms_status ? (
                                        <button
                                          type="button"
                                          className={`btn right-btn  ${this.props.showTime ? "disabled" : "active"}`}
                                          onClick={this.props.resendOTPHandler}
                                        >
                                          <span>Resend</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className={`btn right-btn  ${this.props.showTime ? "disabled" : "active"}`}
                                          onClick={this.props.generateOTPHandler}
                                        >
                                          <span>Request</span>
                                        </button>
                                      )}
                                    </span>
                                  }
                                />

                              </div>
                              {this.props.showTime ? <span className="count">{resendOTPCountDownTimer}</span> : ""}
                            </div>
                          </>
                        )}
                      </form>
                  </div>
                ) : this.props.activeBtn === "change_password" && (
                  <form method="post" id="change-new-pwd-from" name="change-new-pwd-from" className="password-form" onSubmit={ !this.props.match.params.token  ? this.props.submitForgotPasswordOTPHandler : this.props.submitForgotPasswordHandler} noValidate>
                      <h4>CHANGE NEW PASSWORD </h4>
                      <div className="input-group">
                          <span className="input-group-text left-icon">
                              <img src={img["icon-password.webp"]} alt="password icon" />
                          </span>

                          <PasswordInput
                              showPwd={this.props.formFilter.showPwd}
                              onChange={this.props.onChangeNewPasswordHandler}
                              id="password"
                              name="password"
                              className={`no-border form-control ${this.props.errorMsg.newPassword && 'was-validated is-invalid'}`}
                              placeholder="Insert New Password"
                              value={this.props.newPassword}
                              minLength={6}
                              required
                              requiredMessage={t("validation.required")}
                              minLengthMessage={t("validation.minLength", { 0: 6 })}
                              errorMsg={this.props.errorMsg.newPassword || ''}
                              formError={this.props.formError}
                              format = {
                                  <span className="input-group-text">
                                      <button
                                          type="button"
                                          className=" right-icon"
                                          onClick={this.props.showPwd}
                                      >
                                          {this.props.formFilter.showPwd ? (
                                              <img src={img["icon-eye-open.webp"]} alt="show pwd" />
                                          ) : (
                                              <img src={img["icon-eye-close.webp"]} alt="hide pwd" />
                                          )}
                                      </button>
                                  </span>
                                  }
                          />
                          
                      </div>
                      <div className="input-group">
                          <span className="input-group-text left-icon">
                              <img src={img["icon-password.webp"]} alt="password icon" />
                          </span>

                          <PasswordInput
                              showPwdConfirmation={this.props.formFilter.showPwdConfirmation}
                              onChange={this.props.onChangeConfirmNewPasswordHandler}
                              id="password"
                              name="password"
                              className={`no-border form-control ${this.props.errorMsg.confirmNewPassword && 'was-validated is-invalid'}`}
                              placeholder="Insert New Password Again"
                              value={this.props.confirmNewPassword}
                              minLength={6}
                              required
                              requiredMessage={t("validation.required")}
                              minLengthMessage={t("validation.minLength", { 0: 6 })}
                              errorMsg={this.props.errorMsg.confirmNewPassword || ''}
                              formError={this.props.formError}
                              format = { 
                                  <span className="input-group-text">
                                      <button
                                          type="button"
                                          className=" right-icon"
                                          onClick={this.props.showPwdConfirmation}
                                      >
                                          {this.props.formFilter.showPwdConfirmation ? (
                                              <img src={img["icon-eye-open.webp"]} alt="show pwd" />
                                          ) : (
                                              <img src={img["icon-eye-close.webp"]} alt="hide pwd" />
                                          )}
                                      </button>
                                  </span>
                                  }
                          />
                      </div>
                  </form>
              )}
                <button className={`btn btn-blue ${this.props.activeBtn !== "select" ? "active" : "disabled"}`} type="submit" form={this.props.activeBtn === "change_password" ? "change-new-pwd-from" : (this.props.activeBtn) === "phone" ? "phone_forgot_password_form" : "forgot_password_form"} value="Submit">
                  Request
                </button>
              </div>
              {this.props.activeBtn === "select" ? (
                <Link to={{ pathname: "/login" }}>
                  <small> BACK </small>{" "}
                </Link>
              ) : (
                <small onClick={() => this.props.changeActiveBtn("select")}>BACK</small>
              )}
            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(MobileForgetPassword));
