import React, { Component } from 'react'
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import { Icon } from "@iconify/react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import GamePagination from '../../components/Pagination/GamePagination';
import { CgDanger } from 'react-icons/cg';
import Skeleton from 'react-loading-skeleton';

export class MobileProviderGames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGame: '',
      gameCode: '',
      demo: 0,
      demoFishUrl: ''
    };
  }

  updateGameCode = (game, demo, demo_fish_url) => {
    this.setState({
      selectedGame: game,
      gameCode: game?.code,
      demo: demo,
      demoFishUrl: demo_fish_url
    });
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const icon = this.imageImport(
      require.context(
        "../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const games = this.props.gamesList ? this.props.gamesList : [];
    const gameIcon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const tmpImg = this.imageImport(require.context("../../assets/images/placeholder/", false, /\.(png|jpe?g|svg)$/));
    const { t } = this.props;
    return (
      <Auxiliary>
        <MobileSubHeader
          returnPage={this.props.returnPage ? this.props.returnPage : "/"}
          header={this.props.title ? this.props.title : "No Title"}
          style={{ justifyContent: "unset" }}
        />
        <section className='pg-m-section'>
          <div className="search-bar my-3">
            <input
              className="form-control"
              type="text"
              placeholder={t("global.search")}
              aria-label="Search"
              onChange={(event) => this.props.searchHandler(event)}
            />
            <Icon icon="uil:search" />
          </div>
          <div className='game-m-nav'>
            <div className='row g-2'>
              <div className='col-4' onClick={(event) => this.props.changeTab(event, "")}>
                <div className={`game-nav-btn ${this.props.fav === "" && "active"}`}>
                  <Icon className='pg-all' icon="mdi:grid-large" />
                  <p>{t("global.all")}</p>
                </div>
              </div>
              <div className='col-4' onClick={(event) => this.props.changeTab(event, 1)}>
                <div className={`game-nav-btn ${this.props.fav === 1 && "active"}`}>
                  <Icon className='pg-hot' icon="uil:fire" />
                  <p>{t("global.hot")}</p>
                </div>
              </div>
              <div className='col-4' onClick={(event) => this.props.changeTab(event, 2)}>
                <div className={`game-nav-btn ${this.props.fav === 2 && "active"}`}>
                  {/* <img className='unactived' src={icon["fav-icon.png"]} alt="fav" />
                  <img className='actived' src={icon["fav-icon-active.png"]} alt="fav" /> */}
                  <Icon className='pg-all' icon="ic:round-favorite-border" />
                  <p>{t("global.favorite")}</p>
                </div>
              </div>

              {
                this.props.bonusBtn &&
                <div className='col-4' onClick={(event) => this.props.changeTab(event, 3, 'buy-bonus')}>
                  <div className={`game-nav-btn ${this.props.fav === 3 && "active"}`}>
                    {/* <img className='unactived' src={icon["fav-icon.png"]} alt="fav" />
                  <img className='actived' src={icon["fav-icon-active.png"]} alt="fav" /> */}
                    <Icon className='pg-all' icon="ri:money-dollar-circle-line" />
                    <p>{t("game-page.buy-bonus")}</p>
                  </div>
                </div>
              }

            </div>
          </div>
          <div className='game-list'>
            <div className='row g-2'>
              {this.props.loading ?
                <>
                  {(() => {
                    let display = [];
                    for (var a = 1; a <= 3; a++) {
                      display.push(
                        <div className='col-4'>
                          <Skeleton count={1} height={300} />
                        </div>
                      );
                    }
                    return display;
                  })()}
                </>
                : ((this.props.fav === 2 && !localStorage.getItem("accessToken")) ?
                  <div className='col-12 text-center'>{t("game-page.no-info")}</div>
                  :
                  games && games.map((item, index) => (
                    <div className='col-4' key={index}>
                      <div className='position-relative ratio ratio-1x1'>
                        <img src={item.img_url} onError={(e) => { e.target.onerror = null; e.target.src = this.src = tmpImg["tmp_image.png"]; }} alt="game-img" data-bs-toggle="modal" data-bs-target="#playOrDemo" onClick={() => this.updateGameCode(item, item.demo, item.demo_fish_url)} />
                        {
                          this.props.gameType !== "p2p" &&
                          <div className="rtp">
                            <p>{t("game-page.rtg")} :
                              <span>{item.rtg_rate.toString()}%</span>
                            </p>
                          </div>
                        }
                      </div>
                      <div className='game-m-name'>
                        <p>{item.name} </p>
                        <img src={gameIcon[`${this.props.vendorSlug}-grey.svg`]} alt={this.props.vendorSlug} />
                      </div>
                      {
                        item.new && <span className='new-tag'>{t("game-page.new")}</span>
                      }
                      {
                        this.props.memberFavGamesList.length > 0 && this.props.memberFavGamesList.includes(item.id) ? (
                          <img className='fav-icon' src={icon["fav-red.png"]} alt="fav" onClick={(event) => this.props.updateMemberFavGame(event, item.id)} />
                        ) : (
                          <img className='fav-icon' src={icon["fav-gray.png"]} alt="fav" onClick={(event) => this.props.updateMemberFavGame(event, item.id)} />
                        )
                      }
                    </div>
                  )))
              }
            </div>
          </div>

          <div className="modal global-modal fade" id="playOrDemo" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered gap-3">
              <div className="modal-content">
                {/* <div className="modal-header pb-0">
                  <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                </div> */}
                <div className="modal-body">
                  {(() => {
                    if (this.props.gameCategory !== undefined && this.props.gameCategory.length !== 0 && this.props.selectedVendor !== undefined && this.props.selectedVendor.length !== 0) {
                      if (this.props.selectedVendor[0].maintenance_status === 0) {
                        return (
                          <>
                            <img src={this.state.selectedGame.img_url} onError={(e) => { e.target.onerror = null; e.target.src = this.src = tmpImg["tmp_image.png"]; }} alt="selected games" />
                            <p>{this.state.selectedGame.name}</p>
                            <button className='btn btn-gradient-blue' onClick={() => this.props.accessGameFunc(this.state.gameCode)}>{t("global.play-now")}</button>

                            {this.props.gameType === "games" && this.props.vendorSlug === "pp" && this.state.demoFishUrl && this.state.demo === 1 && (
                              <button className='btn btn-white' onClick={() => this.props.accessDemoFunc(this.state.gameCode, this.state.demoFishUrl)}>{t("global.demo")}</button>
                            )}

                            {this.props.gameType === "games" && this.props.vendorSlug !== "pp" && this.state.demo === 1 && (
                              <button className='btn btn-white' onClick={() => this.props.accessDemoFunc(this.state.gameCode, this.props.demoUrl)}>{t("global.demo")}</button>
                            )}

                            {this.props.gameType !== "games" && this.props.gameType !== "p2p" && this.state.demo === 1 &&
                              <button className='btn btn-white' onClick={() => this.props.accessDemoFunc(this.state.gameCode)}>{t("global.demo")}</button>
                            }
                          </>
                        );
                      }
                      else {
                        return (
                          <>
                            <div className="under-maintenance-text text-center">
                              <CgDanger />
                              <p>
                                {t("global.under-maintenance")}
                                <br />
                                {t("global.will-be-back-soon")}!
                              </p>
                            </div>
                          </>
                        );
                      }
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='global-game-section mb-5 '>
          <GamePagination
            totalPage={this.props.totalPage}
            curPage={this.props.curPage}
            data={games}
            navigateList={(event, type, page) => this.props.navigateList(event, type, page)}
          />
        </div>
      </Auxiliary>
    )
  }
}

export default MobileProviderGames;