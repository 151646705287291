import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { withNamespaces } from "react-i18next";
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';
import ReactPlayer from "react-player";
import Swal from "sweetalert2";

// const navList = [
//   { id: 1, label: "football_stream" },
//   { id: 2, label: "basketball_stream" },
//   { id: 3, label: "games_stream" },
// ];

export class MobileLiveStreaming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageShow: 'football_stream',
      viewVideo: false,
      watchStreamVideo: false,
    };
  }

  componentDidMount = () => {
    const { t } = this.props;
    Swal.fire({
      iconHtml: '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' ,
      icon: 'error',
      title: t("global.sorry"),
      html: "赛事暂未开始，敬请期待。",
      showConfirmButton: true,
      confirmButtonText: t("global.ok"),
      customClass: {
        container: 'swal-mobile-container sorry',
        icon: 'swal-sorry-icon'
      },
    });

  }

  changeTabHandler = (e) => {
    e.preventDefault();
    if (e.target.id === 'events_details') {
      this.setState({
        historyTab: 'lucky_spin'
      })
    } else if (e.target.id === 'mall_details') {
      this.setState({
        historyTab: 'mall'
      })
    }
    this.setState({
      pageShow: e.target.id,
    });
  };


  toggleViewVideoHandler = () => {
    if (this.state.viewVideo === false) {
      document.body.classList.add('modal-open');
    }
    else {
      document.body.classList.remove('modal-open');
    }
    this.setState({
      viewVideo: !this.state.viewVideo,
    });
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };


  render() {
    const { t } = this.props;
    const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));
    const liveImg = this.imageImport(require.context("../../assets/images/live-streaming", false, /\.(png|jpe?g|svg|webp)$/));

    return (
      <Auxiliary>
        {isMobile &&
          <section className='promos-mobile-section live-streaming-section'>
            <div className='promos-m-header bg-white'>
              <MobileSubHeader
                header={t("live-stream.title")}
                style={{ justifyContent: "center" }}
              />
              <ul className='navbar-nav'>
                <li className="nav-item" role="presentation">
                  <a href="/#" className={`nav-link ${this.state.pageShow === 'football_stream' && 'active'}`} id="football_stream" onClick={(event) => this.changeTabHandler(event)}
                  > {t("live-stream.football")} </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a href="/#" className={`nav-link ${this.state.pageShow === 'basketball_stream' && 'active'}`} id="basketball_stream" onClick={(event) => this.changeTabHandler(event)}
                  > {t("live-stream.basketball")} </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a href="/#" className={`nav-link ${this.state.pageShow === 'games_stream' && 'active'}`} id="games_stream" onClick={(event) => this.changeTabHandler(event)}
                  > {t("live-stream.games")}  </a>
                </li>
              </ul>
            </div>
            <div className='lsv-options-container p-3'>
              {
                this.props.matches && this.props.matches.map((item, index) =>
                  <div
                    className={`live-stream-options card-shadow ${this.state.activeMatch === item.id && "active"}`}
                    key={index}
                    onClick={this.toggleViewVideoHandler}
                  >
                    <div className='match-header'>
                      <p>FIFA世界杯2022</p>
                      <p className='match-date'>11月24日  17:30
                        <img src={liveImg["video-icon.svg"]} alt="video" />
                      </p>
                    </div>
                    <div className='live-stream-match justify-content-between mt-3'>
                      <div className='team'>
                        <img className='me-2' src={flag["arg.svg"]} alt="flag" />
                        <p>阿根廷</p>
                      </div>
                      <img className='mx-5' src={liveImg["vs.png"]} alt="vs" />
                      <div className='team'>
                        <p>阿根廷</p>
                        <img className='ms-2' src={flag["arg.svg"]} alt="flag" />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>

            <div className={`pf-m-dashboard ${this.state.viewVideo ? 'd-block pe-auto on-top' : 'd-none'}`}>
              <MobileSubHeader
                className="bg-white"
                closeModalHandler={this.toggleViewVideoHandler}
                header="Sports Live Stream"
                subPage='/'
              />
              <div className="ratio ratio-16x9">
                {
                  this.state.watchStreamVideo ? (
                    <ReactPlayer
                      volume={0.3}
                      width="100%"
                      height="550px"
                      url="https://m.youtube.com/watch?v=AhbtJwR8Vno"
                      onEnded={this.playNext}
                      controls
                      playing
                    />
                  ) : (
                    <>
                      <img className='h-100' src={liveImg["video-bg.png"]} alt="video background"/>
                      <div className='overlay'>
                        <p style={{ fontSize: "1.25rem" }}>FIFA世界杯2022</p>
                        <small>11月24日  17:30</small>
                        <div className='live-stream-match pb-3'>
                          <div className='team'>
                            <p className='me-2'>阿根廷</p>
                            <img src={flag["arg.svg"]} alt="flag" />
                          </div>
                          <img className='mx-5' src={liveImg["vs.png"]} alt="vs" />
                          <div className='team'>
                            <img src={flag["arg.svg"]} alt="flag" />
                            <p className='ms-2'>阿根廷</p>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.25rem" }}>{t("live-stream.no-start")}</p>
                      </div>
                    </>
                  )
                }

              </div>
              <div className='live-stream-match py-3'>
                <div className='team'>
                  <p>阿根廷</p>
                  <img className='ms-2' src={flag["arg.svg"]} alt="flag" />
                </div>
                <img className='mx-5' src={liveImg["vs.png"]} alt="vs" />
                <div className='team'>
                  <img className='me-2' src={flag["arg.svg"]} alt="flag" />
                  <p>阿根廷</p>
                </div>
              </div>
              <div className='px-3 mb-3'>
                <div className='live-stream-result mb-3'>
                  <h5>{t("live-stream.capot")}</h5>
                  <div className='row gap-3 p-3'>
                    <div className='col-3'>
                      <p>{t("live-stream.home-win")}</p>
                      <span>-</span>
                    </div>
                    <div className='col-3'>
                      <p>{t("live-stream.draw")}</p>
                      <span>-</span>
                    </div>
                    <div className='col-3'>
                      <p>{t("live-stream.away-win")}</p>
                      <span>-</span>
                    </div>
                  </div>
                </div>
                <div className='live-stream-result mb-3'>
                  <h5>{t("live-stream.handicap")}</h5>
                  <div className='row gap-3 p-3'>
                    <div className='col-3'>
                      <p>+0/0.5</p>
                      <span>1.69</span>
                    </div>
                    <div className='col-3'>
                      <p>-0/0.5</p>
                      <span>1.69</span>
                    </div>
                  </div>
                </div>
                <div className='live-stream-result mb-3'>
                  <h5>{t("live-stream.over-under")}</h5>
                  <div className='row gap-3 p-3'>
                    <div className='col-3'>
                      <p>8.75</p>
                      <span>2.17</span>
                    </div>
                    <div className='col-3'>
                      <p>8.75</p>
                      <span>2.17</span>
                    </div>
                  </div>
                </div>
                <button type="button" className='btn btn-gradient-blue w-100'>{t("live-stream.bet")}</button>
              </div>
            </div>
          </section>
        }
      </Auxiliary >
    )
  }
}

export default (withNamespaces("translation")(MobileLiveStreaming));