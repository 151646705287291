import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import Swal from "sweetalert2";
// import { isDesktop } from "react-device-detect";
// import moment from "moment";

import Skeleton from "react-loading-skeleton";
import { Icon } from "@iconify/react";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";

import MobileAddCrypto from "../../../components/MobileAddCrypto/MobileAddCrypto";
import MobileEditCrypto from "../../../components/MobileAddCrypto/MobileEditCrypto";
import SecurityPinModal from "../../../components/SecurityPinModal/SecurityPinModal";

import { currency } from "../../../common/config/currency";

class MobileCrypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddNewCrypto: false,
            showEditCrypto: false,
            showAddSecurityPin: false,

            edit_id: '',
            edit_network: '',
            edit_withdrawal_address: '',
        };
    }

    componentDidMount() {
        formValidation("withdrawal_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    selectPaymentGatewayHandler = (val) => {
        this.setState({
            paymentGatewaySelected: val,
        });
    };

    toggleAddCryptoHandler = () => {
        this.setState({
            showAddNewCrypto: !this.state.showAddNewCrypto,
        });
    };

    toggleEditCryptoHandler = (e, id, network, withdrawal_address) => {
        this.setState({
            edit_id: id,
            edit_network: network,
            edit_withdrawal_address: withdrawal_address,
            showEditCrypto: !this.state.showEditCrypto,
        });
    };

    // getEditCryptoHandler = (e, id, network, withdrawal_address) => {
    //     this.toggleEditCryptoHandler();
    //     this.props.openModalHandler(e, id, network, withdrawal_address);
    // };

    toggleShowAddSecurityPin = () => {
        // if (this.state.showAddSecurityPin === false)
        // {
        //     document.body.classList.add('modal-open');
        // }
        // else
        // {
        //     document.body.classList.remove('modal-open');
        // }
        this.setState({
            showAddSecurityPin: !this.state.showAddSecurityPin,
        });

        this.props.securityPinModalHandler("enter-sec-pin");
    };

    render() {
        const { t } = this.props;

        const cryptoImg = this.imageImport(require.context("../../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));
        return (
            <Auxiliary>
                {this.state.showAddNewCrypto && <MobileAddCrypto {...this.state} {...this.props} toggleAddCryptoHandler={this.toggleAddCryptoHandler} />}

                {this.state.showEditCrypto && <MobileEditCrypto {...this.state} toggleEditCryptoHandler={this.toggleEditCryptoHandler} searchHandler={this.props.searchHandler}/>}
                {/* <MobileEditCrypto {...this.state} {...this.props} toggleEditCryptoHandler={this.toggleEditCryptoHandler} onRef={(ref) => (this.child = ref)} /> */}

                <SecurityPinModal {...this.props} {...this.state} closeAddSecurityPin={this.toggleShowAddSecurityPin} />

                <form className="forms-col needs-validation" method="post" id="withdrawal_form" onSubmit={this.props.submitHandler} noValidate>
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                {/* <div>
                                    <label className="col-form-label">{t("withdrawal.payment-option")}:</label>
                                    <div className="input-group-col select-bank-opts">
                                        {this.state.dropDown.payment_gateway.length > 0 ? (
                                            this.state.dropDown.payment_gateway.map((val, idx) => {
                                                if (val.transaction_slug === "crypto" && val.wd_status === 1 && val.title !== "Crypto") {
                                                    return (
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                id={`payment${val.slug}`}
                                                                value={val.slug}
                                                                checked={this.props.input.paymentGatewaySelected === val.slug ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <label
                                                                className="btn form-check-label"
                                                                htmlFor={`paymentdirepay`}
                                                                onClick={() => this.selectPaymentGatewayHandler("direpay")}
                                                            >
                                                                <img className="active" src={cryptoImg["direpay.svg"]} alt={"direpay"} />
                                                            </label>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })
                                        ) : this.state.isOptionLoading === false ? (
                                            <span className="text-danger">{t("deposit.invalid-payment-gateway")}</span>
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </div>
                                </div> */}

                                <div>
                                    <label className="col-form-label">{t("deposit.select-channel")}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.cryptoChannel.length > 0 ? (
                                                this.props.cryptoChannel.map((val, idx) => {
                                                    return (
                                                        <div className="form-check form-check-inline four-check" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_code"
                                                                id={`crypto${val.label}`}
                                                                value={val.label}
                                                                checked={this.props.channel === val.label ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <label
                                                                className="btn form-check-label"
                                                                htmlFor={`crypto${val.label}`}
                                                                onClick={() => this.props.selectChannel(val.label, val.id, val.type)}
                                                            >
                                                                <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                                <p>{val.label}</p>
                                                                {process.env.REACT_APP_ENV === "staging" && val.withdrawal_est_time && (
                                                                    <div className="transfer-duration">
                                                                    {val.withdrawal_est_time === "00:00:00" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                            {t("withdrawal.instant-withdrawal")}
                                                                        </>
                                                                        ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                            {t("withdrawal.withdrawal-in")} {val.withdrawal_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                        </>
                                                                    )}
                                                                                    
                                                                    </div>
                                                                                
                                                                )}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                // <span className="text-danger">{t("deposit.invalid-payment-channel")}</span>
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label className="col-form-label">{t("withdrawal.select-crypto-wallet")}</label>
                                    <div className="input-group-col">
                                        <div className={`bank-list-group ${this.props.errorMsg?.crypto_address ? "form-control was-validated is-invalid" : ""}`}>
                                            {this.props.dropDown.memberWithdrawalCryptoAddress && 
                                            this.props.dropDown.memberWithdrawalCryptoAddress.length > 0 &&
                                                this.props.dropDown.memberWithdrawalCryptoAddress.map((val, idx) => {
                                                    if(val.crypto === this.props.type)
                                                    {
                                                        return (
                                                            <div className="form-check form-check-inline" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    name="crypto_id"
                                                                    id={`withdrawalAddressOpt${val.id}`}
                                                                    value={val.id}
                                                                    onChange={(event) => this.props.onInputChangeHandler(event, val.withdrawal_address)}
                                                                    checked={Number(this.props.input.crypto_id) === Number(val.id) ? true : false}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`withdrawalAddressOpt${val.id}`}>
                                                                    <div>
                                                                        <img src={cryptoImg[`${val.crypto.toLowerCase()}.svg`]} alt={val.crypto} />
                                                                        <div className="list-details">
                                                                            <p>{val.network}</p>
                                                                            <p>{val.withdrawal_address}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            typeButton="button"
                                                                            classValue="btn icon"
                                                                            key={`edit-${val.id}`}
                                                                            idValue={val.id === undefined ? 0 : val.id}
                                                                            clicked={(e) => this.toggleEditCryptoHandler(e, val.id, val.network, val.withdrawal_address)}
                                                                            buttonName={<Icon icon="uil:edit" />}
                                                                        />
                                                                        <Button
                                                                            typeButton="button"
                                                                            classValue="btn icon red"
                                                                            key={`remove-${val.id}`}
                                                                            idValue={val.id === undefined ? 0 : val.id}
                                                                            clicked={(e) => this.props.removeCryptoAddressHandler(e, val.id, val.withdrawal_address)}
                                                                            buttonName={<Icon icon="uil:trash-alt" />}
                                                                        />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        );
                                                    }
                                                    else
                                                    {
                                                        return null;
                                                    }
                                                })}
                                            <div className="form-check form-check-inline">
                                                <button type="button" className="btn form-check-label wdrawal-add-bank w-100" onClick={this.toggleAddCryptoHandler}>
                                                    <Icon className="me-2" icon="radix-icons:plus-circled" />
                                                    {t("withdrawal.add-crypto-wallet")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex justify-content-between px-3">
                                        <label className="col-form-label p-0">{t("withdraw.amount")}:</label>
                                        <small className="col-form-label p-0" style={{ fontSize: '0.75rem' }}>
                                            {t("withdraw.last-withdraw")}: <span style={{ fontWeight: "600" }}>{currency[this.props.currentUser.country_id] ?? ""}200</span>
                                        </small>
                                    </div>

                                    <div className="withdraw-crypto-amount">
                                        <div className="input-group-col d-flex">
                                            <div className="input-group left has-validation">
                                                <span className="input-group-text">{this.props.currentUser.currency}</span>
                                                <NumberInput
                                                    id="amount"
                                                    name="amount"
                                                    className={`form-control ${this.props.errorMsg?.amount ? "was-validated is-invalid" : ""}`}
                                                    placeholder={`${t("withdraw.min-max-limit")} ${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.minWithdrawal)}/${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.maxWithdrawal)}`}
                                                    value={this.props.input.amount}
                                                    onChange={(event) => this.props.onInputChangeHandler(event)}
                                                    min={this.props.minWithdrawal || 0}
                                                    max={this.props.maxWithdrawal || 0}
                                                    step="any"
                                                    required={true}
                                                    onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                    minValueMessage={t("validation.amtMinValue", { 0: this.props.minWithdrawal })}
                                                    maxValueMessage={t("validation.amtMaxValue", { 0: this.props.maxWithdrawal })}
                                                    requiredMessage={t("validation.required")}
                                                    formError={this.props.formError}
                                                    errorMsg={this.props.errorMsg?.amount || ""}
                                                    onBlur={this.props.onCryptoGetExchangeRates}
                                                />
                                            </div>
                                            <Button
                                                typeButton="button"
                                                clicked={this.props.withdrawalMaxHandler}
                                                classValue="btn btn-gradient-blue ms-2"
                                                // style={{ minWidth: "4rem" }}
                                                buttonName={t("withdraw.max")}
                                            />
                                        </div>
                                        <div className="exchange-rate-img">
                                            <Icon icon="akar-icons:arrow-repeat" />
                                        </div>
                                        {/* <label className="col-form-label">Exchange Rate:</label> */}
                                        <div className="input-group-col d-flex" style={{ pointerEvents: 'none' }}>
                                            <div className="input-group left has-validation">
                                                <span className="input-group-text">{this.state.type}</span>
                                                <NumberInput
                                                    id="exchange_rate"
                                                    name="exchange_rate"
                                                    className="form-control"
                                                    placeholder={this.props.exchangeRateAmount}
                                                    value={this.props.exchangeRateAmount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.props.addSecurityPinStatus === true && this.props.input.amount && this.props.input.crypto_id && this.props.input.crypto_address ? (
                                    <button
                                        className="btn btn-gradient-blue mx-3"
                                        type="button"
                                        onClick={this.toggleShowAddSecurityPin}
                                        id="securityPinSubmitBtn"
                                        disabled={this.props.exchangeRateAmount === 0 || this.props.exchangeRateAmount === undefined ? true : false}
                                    >
                                        {t("global.confirm")}
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-gradient-blue mx-3"
                                        type="submit"
                                        value="Submit"
                                        disabled={this.props.exchangeRateAmount === 0 || this.props.exchangeRateAmount === undefined ? true : false}
                                    >
                                        {t("global.confirm")}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </form>
            </Auxiliary >
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileCrypto));
