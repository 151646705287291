import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
// import Modal from "react-modal";
// import Swal from "sweetalert2";
// import { default as ReactSelect } from "react-select";
import Skeleton from "react-loading-skeleton";
// import { Fade } from "react-awesome-reveal";
import { Icon } from "@iconify/react";
// import moment from "moment";
// import { NavLink } from 'react-router-dom';

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
// import { isDesktop } from "react-device-detect";

//Components
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Button from "../../../components/Button/Button";
import SecurityPinModal from "../../../components/SecurityPinModal/SecurityPinModal";

//Actions
// import * as actions from "../../../store/ducks/withdrawal/actions";
// import { editMemberBank, removeMemberBank } from "../../../store/ducks/profile/actions";
import { getTopProfile } from "../../../store/ducks/profile/actions";
// import { checkSecurityPinAttempts } from "../../../store/ducks/profile/actions";
// import { getUserData } from "../../../store/ducks/currentUser/actions";
import MobileAddBank from "../../../components/MobileAddBank/MobileAddBank";
import MobileEditBank from "../../../components/MobileAddBank/MobileEditBank";

class MobileBankTransfer extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            showAddNewBank: false,
            showEditBank: false,
            showAddSecurityPin: false,
        };
    }

    shouldComponentUpdate() 
    {
        return true;
    }

    //open mobile add security pin page
    toggleShowAddSecurityPin = () => 
    {
        // // if (this.state.showAddSecurityPin == false) 
        // // {
        // //     document.body.classList.add('modal-open');
        // // } 
        // // else 
        // // {
        // //     document.body.classList.remove('modal-open');
        // // }
        this.setState({
            showAddSecurityPin: !this.state.showAddSecurityPin
        });

        this.props.securityPinModalHandler("enter-sec-pin");
    }

    toggleAddBankHandler = () => 
    {
        if (this.state.showAddNewBank === false) 
        {
            document.body.classList.add('modal-open');
        }
        else 
        {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showAddNewBank: !this.state.showAddNewBank,
        });
    }

    toggleEditBankHandler = () => 
    {
        if (this.state.showEditBank === false) 
        {
            document.body.classList.add('modal-open');
        }
        else 
        {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showEditBank: !this.state.showEditBank,
        });
    }

    getEditBankHanlder = (e, id, bankId, bankName, swiftCode, bankAccNo) => 
    {
        // console.log(id, bankId, bankName, swiftCode, bankAccNo)
        this.toggleEditBankHandler();
        this.child.openModalHandler(e, id, bankId, bankName, swiftCode, bankAccNo);
    }

    render() 
    {
        const { t } = this.props;

        return (
            <Auxiliary>
                <SecurityPinModal
                    {...this.props}
                    {...this.state}
                    closeAddSecurityPin={this.toggleShowAddSecurityPin}
                />
                {this.state.showAddNewBank && 
                    <MobileAddBank
                        {...this.state}
                        {...this.props}
                        toggleAddBankHandler={this.toggleAddBankHandler} 
                    />
                }

                <MobileEditBank
                    {...this.state}
                    {...this.props}
                    toggleEditBankHandler={this.toggleEditBankHandler}
                    onRef={ref => (this.child = ref)}
                />

                <form className="forms-col needs-validation" method="post" id="withdrawal_form" onSubmit={this.props.submitHandler} noValidate>
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t("withdraw.select-a-bank")}</label>
                                    <div className="input-group-col">
                                        <div className={`bank-list-group ${this.props.errorMsg.bank_id ? "form-control was-validated is-invalid" : ""}`}>
                                            {this.props.memberBankAccount.length > 0 && this.props.memberBankAccount.map((val, idx) => 
                                            {
                                                let bankColorImage = null;
                                                bankColorImage = 
                                                    (val.swift_code && this.props.bankImg[`${val.swift_code.toLowerCase()}-color.svg`] !== undefined)
                                                    ? 
                                                    this.props.bankImg[`${val.swift_code.toLowerCase()}-color.svg`]
                                                    :
                                                    this.props.bankImg["default-bank-icon-color.png"];

                                                return (
                                                    <div className="form-check form-check-inline" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="bank_id"
                                                            id={`bankOpt${val.bank_id}`}
                                                            value={val.bank_id}
                                                            onChange={(event) => this.props.onInputChangeHandler(event)}
                                                            checked={Number(this.props.input.bank_id) === Number(val.bank_id) ? true : false}
                                                        />
                                                        <label className="btn form-check-label" htmlFor={`bankOpt${val.bank_id}`}>
                                                            <div>
                                                                <img src={bankColorImage} alt={val.swift_code} />
                                                                <div>
                                                                    <p>{val.bank_acc_number}</p>
                                                                    <p>{val.bank_name}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    typeButton="button"
                                                                    classValue="btn icon"
                                                                    key={`edit-${val.id}`}
                                                                    idValue={val.id === undefined ? 0 : val.id}
                                                                    clicked={(e) => this.getEditBankHanlder(e, val.id, val.bank_id, val.bank_name, val.swift_code, val.bank_acc_number)}
                                                                    buttonName={<Icon icon="uil:edit" />}
                                                                />
                                                                <Button
                                                                    typeButton="button"
                                                                    classValue="btn icon red"
                                                                    key={`remove-${val.id}`}
                                                                    idValue={val.id === undefined ? 0 : val.id}
                                                                    clicked={(e) => this.props.removeBankHandler(e, val.id, val.bank_name)}
                                                                    buttonName={<Icon icon="uil:trash-alt" />}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                            <div className="form-check form-check-inline">
                                                <button type="button" className="btn form-check-label wdrawal-add-bank w-100" onClick={this.toggleAddBankHandler}>
                                                    <Icon className="me-2" icon="radix-icons:plus-circled" />
                                                    {t("withdraw.add-bank-account")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label className="col-form-label">{t("withdraw.amount")}:</label>
                                    <div className="input-group-col d-flex">
                                        <div className="input-group left has-validation">
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className={`form-control ${this.props.errorMsg.amount ? "was-validated is-invalid" : ""}`}
                                                // className={`form-control was-validated is-invalid`}
                                                // className={`form-control ${this.props.formClass}`}
                                                // className={`form-control ${(this.props.errorMsg.amount!==undefined) ? 'was-validated is-invalid' : ''}`}
                                                // className='form-control'
                                                placeholder={
                                                    (this.props.minWithdrawal && this.props.maxWithdrawal) 
                                                    ? `${t("withdraw.min-max-limit")} ${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.minWithdrawal)}/${new Intl.NumberFormat("en-US", this.props.numFormat).format(this.props.maxWithdrawal)}` 
                                                    : ''
                                                }
                                                value={this.props.inputArr.amount}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                min={this.props.minWithdrawal || 0}
                                                max={this.props.maxWithdrawal || 0}
                                                step="any"
                                                required={this.props.withdrawalOption === "bank-transfer" ? true : false}
                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                minValueMessage={t("validation.amtMinValue", { 0: this.props.minWithdrawal })}
                                                maxValueMessage={t("validation.amtMaxValue", { 0: this.props.maxWithdrawal })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.amount || ""}
                                            />
                                        </div>
                                        <Button
                                            typeButton="button"
                                            clicked={this.props.withdrawalMaxHandler}
                                            classValue="btn btn-gradient-blue ms-2"
                                            buttonName={t("withdraw.max")}
                                        />
                                    </div>
                                </div>

                                {
                                    this.props.addSecurityPinStatus === true && this.props.input.amount && this.props.input.bank_id && this.props.input.member_bank_account_id !== null && this.props.input.bank_acc_number ? (
                                        <button
                                            className="btn btn-gradient-blue mx-3"
                                            type="button"
                                            onClick={this.toggleShowAddSecurityPin}
                                            id="securityPinSubmitBtn"
                                        >
                                            {t("global.confirm")}
                                        </button>
                                    ) : (
                                        <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                            {t("global.confirm")}
                                        </button>
                                    )
                                
                                }
                            </>
                        )}
                    </div>
                </form>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => 
{
    return {
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => 
{
    return {
        getTopProfile: () => dispatch(getTopProfile())
    };
};

// Connect redux

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileBankTransfer));
