import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import Skeleton from "react-loading-skeleton";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import { isDesktop } from "react-device-detect";
import ValidationError from "../../../components/ValidationError/ValidationError";

import { getTopProfile, redirectGoogle, unlinkGoogle, generateEmailOTP, resendEmailOTP, verifyEmailOTP, unlinkTelegram } from "../../../store/ducks/profile/actions";

export class MobileThirdPartyAuthorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: true,
            topProfileData: [],
            linkSocialMediaLoading: false,
            showUnlinkModal: false,
            showSendUnlinkOtp: false,
            showEnterUnlinkOtp: false,
            unlink_otp: ""
        };
    }

    componentDidMount() {
        this.props.getTopProfile();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
            if (nextProps.topProfileData.responseCode === 200) {
                this.setState({
                    refresh: false,
                    topProfileData: nextProps.topProfileData.data,
                });
            }
        }

        if (nextProps.google_redirect_result !== this.props.google_redirect_result) {
            if (nextProps.google_redirect_result.responseCode === 200) {
                this.setState({
                    linkSocialMediaLoading: false,
                });

                window.location.href = nextProps.google_redirect_result.data;
            }
        }

        if (nextProps.generate_email_otp_result !== this.props.generate_email_otp_result) {
            if (nextProps.generate_email_otp_result.responseCode === 200) {
                if (this.state.openUnlinkGoogleModal === false) {
                    this.setState({
                        generateEmailStatus: true
                    })
                } else {
                    this.setState({
                        showSendUnlinkOtp: false,
                        showEnterUnlinkOtp: true
                    });
                }
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.generate_email_otp_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.unlink_google_result !== this.props.unlink_google_result) {
            if (nextProps.unlink_google_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ showUnlinkModal: false, showSendUnlinkOtp: false, showEnterUnlinkOtp: false });

                        this.props.getTopProfile();

                    }

                });


            } else if (nextProps.unlink_google_result.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.unlink_google_result.message || [],
                    formError: true,
                });
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.unlink_google_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.resend_email_otp_result !== this.props.resend_email_otp_result) {
            if (nextProps.resend_email_otp_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then(() => {
                    if (this.state.openUnlinkGoogleModal === false) {
                        this.setState({
                            generateEmailOTP: true,
                            openEmailModal: true,
                        });
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.resend_email_otp_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.unlink_telegram_result !== this.props.unlink_telegram_result) {
            if (nextProps.unlink_telegram_result.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : "",
                    title: t("global.success"),
                    text: t("global.success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.getTopProfile();
                    }
                });


            } else if (nextProps.unlink_telegram_result.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.unlink_telegram_result.message || [],
                    formError: true,
                });
                Swal.close();
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.unlink_telegram_result.message,
                });
                Swal.close();
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    handleGoogleRedirect = () => {
        const data = {};
        data.url = window.location.origin;

        this.setState({
            linkSocialMediaLoading: true,
        });

        this.props.redirectGoogle(data);
    };

    handleTelegramRedirect = () => {
        window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL + "/?url=" + window.location.origin, "_blank");
    };

    openUnLinkModalHandler = () => {
        this.setState({
            showUnlinkModal: !this.state.showUnlinkModal,
            showSendUnlinkOtp: true,
        });
    };

    showEnterUnlickOtpHandler = () => {
        this.setState({
            showSendUnlinkOtp: false,
            showEnterUnlinkOtp: true,
        });
    };

    onSubmitUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.data.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = localStorage.getItem('lang');
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateEmailOTP(data);
    }

    resendUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = this.props.currentUser.language_code;
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.resendEmailOTP(data);
    };

    verifyUnlinkGoogleOTPHandler = (event, googleLinkEmail) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.ok"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.email = googleLinkEmail;

                data.otp_code = this.state.unlink_otp;

                this.props.unlinkGoogle(data);
            }
        });
    }

    resendUnlinkGoogleHandler = (event, unlinkGoogleEmail) => {
        event.preventDefault();

        const data = {};
        let linkEmail = "";

        if (unlinkGoogleEmail === "") {
            linkEmail = this.props.currentUser.google_link_email;
        } else {
            linkEmail = unlinkGoogleEmail;
        }

        data.email = linkEmail;
        data.language_code = localStorage.getItem('lang');
        data.type = "unlink";

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.resendEmailOTP(data);
    };

    onInputChange = (event) => {
        this.setState({
            unlink_otp: event.target.value
        })
    }

    unlinkTelegramHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%23ffffff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.ok"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });
                const data = {};

                data.telegram_id = this.state.topProfileData.topProfile.telegram_id;

                this.props.unlinkTelegram(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        const thirdParty = this.imageImport(require.context("../../../assets/images/register/", false, /\.(png|jpe?g|svg|webp)$/));

        let topProfileData = null;

        let googleStatusClass = true;
        let telegramStatusClass = true;
        let googleLinkEmail = "";

        if (this.state.topProfileData.length !== 0) {
            topProfileData = this.state.topProfileData;

            if (topProfileData.topProfile.google_id === "") {
                googleStatusClass = false;
            } else if (topProfileData.topProfile.google_id === null) {
                googleStatusClass = false;
            }

            if (topProfileData.topProfile.telegram_id === "") {
                telegramStatusClass = false;
            } else if (topProfileData.topProfile.telegram_id === null) {
                telegramStatusClass = false;
            }

            googleStatusClass = googleStatusClass === true ? "active" : "";
            telegramStatusClass = telegramStatusClass === true ? "active" : "";
            googleLinkEmail = topProfileData.topProfile.google_link_email;
        }

        let unlinkMaskEmail;
        let unlinkEmailDomain;

        if (this.state.showUnlinkModal === true) {
            unlinkMaskEmail = googleLinkEmail.substring(0, googleLinkEmail.length - 9).replace(/\d/g, "******");
            unlinkEmailDomain = googleLinkEmail.split("@")[1];
        }

        return (
            <Auxiliary>
                {this.state.linkSocialMediaLoading && <LoadingPage />}

                {this.props.showThirdPartyAuthorization && (
                    <section className="third-party-m-auth pf-m-dashboard on-top d-block pe-auto">
                        <Modal
                            isOpen={this.state.showUnlinkModal}
                            closeTimeoutMS={500}
                            portalClassName={`global-modal`}
                            className="modal modal-dialog modal-dialog-centered modal-pd"
                            ariaHideApp={false}
                            onRequestClose={this.openUnLinkModalHandler}
                        >
                            {this.state.showSendUnlinkOtp ? (
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5>{t("top-profile.unlink-google-email")}</h5>
                                        <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.openUnLinkModalHandler} />
                                    </div>
                                    <div className="modal-body table-responsive">
                                        <div>
                                            <p>{t("top-profile.unlink-google-description")}</p>
                                            <button className="btn btn-gradient-blue mt-3" onClick={(e) => this.onSubmitUnlinkGoogleHandler(e, googleLinkEmail)}>
                                                {t("top-profile.send")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                this.state.showEnterUnlinkOtp && (
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5>{t("top-profile.enter-otp-code")}</h5>
                                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.openUnLinkModalHandler} />
                                        </div>
                                        <div className="modal-body table-responsive">
                                            <div>
                                                {this.state.resend_email_status === false ? (
                                                    <p className="mb-3">
                                                        {t("top-profile.enter-email-otp-message")} {unlinkMaskEmail + unlinkEmailDomain}. {t("top-profile.enter-email-otp-message-cont")}
                                                    </p>
                                                ) : (
                                                    <p className="mb-3">
                                                        {t("top-profile.enter-email-otp-message")} {unlinkMaskEmail + unlinkEmailDomain}.{" "}
                                                        {t("top-profile.enter-email-otp-message-cont")}
                                                    </p>
                                                )}
                                                <ValidationError formName="email_otp_from" touched={this.state.touched} message={this.state.errorMsg} />

                                                <div className="mt-3">
                                                    <NumberInput
                                                        id="unlink_otp"
                                                        name="unlink_otp"
                                                        type="text"
                                                        // className={`${this.state.errorMsg.full_name && "was-validated is-invalid"}`}
                                                        // placeholder={t("profile.fullname-remark")}
                                                        value={this.state.unlink_otp}
                                                        onChange={(e) => this.onInputChange(e)}
                                                        // required
                                                        requiredMessage={t("validation.required")}
                                                    // errorMsg={this.state.errorMsg.full_name || ""}
                                                    // formError={this.state.formError}
                                                    // disabled={this.state.formFilter.set_full_name_status === true ? true : false}
                                                    />
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn btn-gradient-blue ms-3" style={{ minWidth: "7rem" }} onClick={(e) => this.verifyUnlinkGoogleOTPHandler(e, googleLinkEmail)} disabled={this.state.unlink_otp === "" ? true : false}>
                                                            {t("top-profile.verify")}
                                                        </button>
                                                        <button className="btn btn-outline-blue ms-3" style={{ minWidth: "7rem" }} onClick={(e) => this.resendUnlinkGoogleHandler(e, googleLinkEmail)}>
                                                            {t("top-profile.resend")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </Modal>

                        <MobileSubHeader
                            className="bg-white"
                            closeModalHandler={this.props.closeThirdPartyAuthorization}
                            header={t("profile.third-party-acc-authorization")}
                            style={{ justifyContent: "unset" }}
                        />

                        <div className="third-party-m-auth-container">
                            <ul className="third-party-list d-grid gap-2">
                                {this.state.refresh ? (
                                    <Skeleton count={1} width={200} className="my-auto" />
                                ) : (
                                    <>
                                        <li>
                                            <div>
                                                <img src={thirdParty["icon-google.webp"]} alt="google" />
                                                <p>{t("global.google")}</p>
                                                {/* {googleStatusClass === "active" ? (
                                                    <small>{t("profile.third-party-linked")}</small>
                                                ) : (
                                                    <small>{t("profile.third-party-unlinked")}</small>
                                                )} */}
                                            </div>
                                            {googleStatusClass === "active" ? (
                                                <div>
                                                    {/* <span className="note-text pe-2">{t("profile.linked")}</span> */}
                                                    <button className="btn btn-outline-blue" onClick={this.openUnLinkModalHandler}>
                                                        {t("profile.unlinked")}
                                                    </button>
                                                </div>

                                            ) : (
                                                <button className="btn btn-gradient-blue" onClick={this.handleGoogleRedirect}>
                                                    {t("profile.link")}
                                                </button>
                                            )}
                                        </li>
                                        <li>
                                            <div>
                                                <img src={thirdParty["icon-telegram.webp"]} alt="telegram" />
                                                <p>{t("global.telegram")}</p>
                                                {/* {telegramStatusClass === "active" ? (
                                                    <small>{t("profile.third-party-linked")}</small>
                                                ) : (
                                                    <small>{t("profile.third-party-unlinked")}</small>
                                                )} */}
                                            </div>
                                            {telegramStatusClass === "active" ? (
                                                <div>
                                                    {/* <span className="note-text pe-2">{t("profile.linked")}</span> */}
                                                    <button className="btn btn-outline-blue" onClick={(e) => this.unlinkTelegramHandler(e)}>{t("profile.unlinked")}</button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-gradient-blue" onClick={this.handleTelegramRedirect}>
                                                    {t("profile.link")}
                                                </button>
                                            )}
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </section>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        topProfileData: profile.top_profile_data,
        google_redirect_result: profile.google_redirect_result,
        unlink_google_result: profile.unlink_google_result,
        social_media_user_verification_result: state.profile.social_media_user_verification_result,
        generate_email_otp_result: profile.generate_email_otp_result,
        verify_email_otp_result: profile.verify_email_otp_result,
        resend_email_otp_result: profile.resend_email_otp_result,
        unlink_telegram_result: profile.unlink_telegram_result
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopProfile: () => dispatch(getTopProfile()),
        redirectGoogle: (data) => dispatch(redirectGoogle(data)),
        unlinkGoogle: (data) => dispatch(unlinkGoogle(data)),
        generateEmailOTP: (data) => dispatch(generateEmailOTP(data)),
        verifyEmailOTP: (data) => dispatch(verifyEmailOTP(data)),
        resendEmailOTP: (data) => dispatch(resendEmailOTP(data)),
        unlinkTelegram: (data) => dispatch(unlinkTelegram(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileThirdPartyAuthorization));
