import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { default as ReactSelect } from "react-select";
import * as actions from "../../../store/ducks/deposit/actions";
import { Icon } from "@iconify/react";

import { isDesktop } from "react-device-detect";

//Components
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { storedDepositDropdownValidation } from "../../../common/config/depositDataValidation";
import StringInput from "../../../components/Input/StringInput/StringInput";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";
import MobileCrypto from "./MobileCrypto";
import CryptoDepositModal from "../../../components/CryptoDepositModal/CryptoDepositModal";
import { formValidation, clearFormValidation, numFormat, millesimalValue } from "../../../hoc/Shared/utility";
import * as CryptoJS from "crypto-js";
import moment from "moment";

let quickPayTransactionMinimumPaymentAmt;
let quickPayTransactionMaximumPaymentAmt;
// let cryptoChannel = [];

export class Crypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositAmt: "",
            input: {
                amount: "",
                promo_id: "",
            },
            dropDown: {
                groupSetting: [],
                promoList: [],                
                paymentGatewayOptions: [],
                lastDeposit: 0,                
            },
            cryptoChannel: [
                { id: 0, label: "TRC20-USDT", type: "usdt", coinCode: "TRX" },
                { id: 1, label: "TRC20-USDC", type: "usdc", coinCode: "TRX" },
            ],
            spCryptoChannel: [
                { id: 0, label: "TRC20-USDT", type: "usdt", coinCode: "TRX" },
                //{ id: 1, label: "BSC-BUSD", type: "busd", coinCode: "BSC" },
            ],
            loading: false,
            errorMsg: [],
            formError: false,

            paymentGatewaySelected: this.props.paymentGatewaySelected ? this.props.paymentGatewaySelected : "",
            paymentTypeId: '',
            channel: 'TRC20-USDT',
            type: 'USDT',
            coinCode: 'TRX',
            // channel: this.props.cryptoChannel ? this.props.cryptoChannel : "",
            // channel_id: this.props.channelId ? this.props.channelId : "",
            // type: this.props.cryptoType ? this.props.cryptoType : "",

            openPromoTncModal: false,
            showPromosTitle: "",
            showPromosContent: "",
            showPromosTable: "",

            openCryptoDepositModal: false,
            cryptoPayment: "pending",
            refCode: "",
            txid: "",
            exchangeRate: "",
            usdtAmt: "",

            time: {},
            seconds: 900,
            timer: 0,
            expiredDateTime: "",

            isOptionLoading: true,
        };
    }

    componentDidMount() {
        this.props.funcGetDropDown();
        this.fetchStorageDeposit();
        formValidation("deposit_form");

        clearInterval(this.timer);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            // let cryptoChannel = [];
            // this.props.paymentGateway && this.props.paymentGateway.map((val) => {
            //     if(this.state.paymentGatewaySelected === val.slug)
            //     {
            //         cryptoChannel = this.state.cryptoChannel.map((val) => {
            //             return { ...val, deposit_est_time: val.deposit_est_time };
            //         });
            //     }
            // });

            this.setState({
                isOptionLoading: false,
                // cryptoChannel,
            });

            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let to_store_data = storedDepositDropdownValidation(this.props.default_dropdown_options, nextProps.dropDown.data);
                if (to_store_data.overwrite === true) {
                    nextProps.dropDown.data.payment_gateway.forEach((val) => {
                        if (this.state.paymentGatewaySelected === val.slug && this.props.depositOption === val.transaction_slug) {
                            quickPayTransactionMinimumPaymentAmt = val.transaction_minimum_payment_amount;
                            quickPayTransactionMaximumPaymentAmt = val.transaction_maximum_payment_amount;
                        }
                    });

                    this.setState({
                        dropDown: {
                            groupSetting: nextProps.dropDown.data.member_group,
                            promoList: nextProps.dropDown.data.promotion,
                            paymentGatewayOptions: nextProps.dropDown.data.payment_gateway,
                            lastDeposit: nextProps.dropDown.data.last_deposit || 0,
                        },
                        loading: false,
                    });

                    const storeDropdown = {};
                    storeDropdown.data = nextProps.dropDown.data;
                    this.props.funcDefaultDropDownOptions(storeDropdown);
                }
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                // nextProps.dropDown.data.payment_gateway.forEach((val) => {
                //   if (this.state.paymentGatewaySelected === val.slug) {
                //     quickPayTransactionMinimumPaymentAmt = val.transaction_minimum_payment_amount;
                //     quickPayTransactionMaximumPaymentAmt = val.transaction_maximum_payment_amount
                //   }
                // });
                Swal.close();

                // if(this.state.paymentGatewaySelected === "secret_pay"){
                //     window.location.href = nextProps.store.data.deposit_url;
                // }

                let timeLeftVar = this.secondsToTime(this.state.seconds);

                this.setState({ time: timeLeftVar });

                this.startTimer();

                this.setState({
                    expiredDateTime: nextProps.store.data.expired_date,
                    cryptoPayment: "pending",
                    openCryptoDepositModal: true,
                    refCode: nextProps.store.data.payment_address,
                    txid: nextProps.store.data.txid,
                    exchangeRate: nextProps.store.data.rates,
                    usdtAmt: nextProps.store.data.payment_amount,
                    loading: false,
                });

                this.getStatusInterval = setInterval(() => {
                    let data = {};
                    data.reference_code = this.state.txid;
                    this.props.funcCryptoTransStatus(data);
                }, 10000);
            } else {
                clearFormValidation("deposit_form");
                const { t } = this.props;
                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.store.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<div>";
                    nextProps.store.message.forEach((val) => {
                        msg += "<p>" + val + "</p>";
                    });
                    msg += "</div>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                  container: "swal-mobile-container sorry",
                                  icon: "swal-sorry-icon",
                              }
                            : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== '' && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }
                        }
                    });
                }
            }
        }

        if (nextProps.crypto_trans_status !== this.props.crypto_trans_status && nextProps.crypto_trans_status !== undefined) {
            if (nextProps.crypto_trans_status.data[0].status === 1 || nextProps.crypto_trans_status.data[0].status === 2) {
                this.setState({
                    cryptoPayment: nextProps.crypto_trans_status.data[0].status === 1 ? "success" : "reject",
                });
                clearInterval(this.getStatusInterval);
            }
        }

        return true;
    }

    fetchStorageDeposit = () => {
        if (this.props.default_dropdown_options !== undefined) {
            this.props.default_dropdown_options.data.payment_gateway.forEach((val) => {
                if (this.state.paymentGatewaySelected === val.slug && this.props.depositOption === val.transaction_slug) {
                    quickPayTransactionMinimumPaymentAmt = val.transaction_minimum_payment_amount;
                    quickPayTransactionMaximumPaymentAmt = val.transaction_maximum_payment_amount;
                }
            });

            this.setState({
                dropDown: {
                    groupSetting: this.props.default_dropdown_options.data.member_group,
                    promoList: this.props.default_dropdown_options.data.promotion,
                    paymentGatewayOptions: this.props.default_dropdown_options.data.payment_gateway,
                    lastDeposit: this.props.default_dropdown_options.data.last_deposit || 0,
                },
                loading: false,
                isOptionLoading: false,
            });
        }
    };

    selectPaymentGatewayHandler = (val) => {
        this.state.dropDown.paymentGatewayOptions.map((item) => {
            if (item.slug === val.slug && this.props.depositOption === val.transaction_slug) {
                quickPayTransactionMinimumPaymentAmt = item.transaction_minimum_payment_amount;
                quickPayTransactionMaximumPaymentAmt = item.transaction_maximum_payment_amount;
            }
            return null;
        });
        this.setState({
            paymentGatewaySelected: val.slug,
            paymentTypeId: val.id,
        });
    };

    selectChannel = (val, id, slug, code) => {
        this.setState({
            channel: val,
            // channel_id: id,
            type: val === "TRC20-USDC" ? "USDC" : val === "BSC-BUSD" ? "BUSD" : "USDT",
            coinCode: code
        });
    };

    onInputChangeHandler = (event) => {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        let depositAmt = event.target.name === "depositAmt" ? event.target.value : this.state.depositAmt;

        if (event.target.name === "bonusCode") {
            event.target.value = event.target.value.toUpperCase();
        }

        newInput[event.target.name] = event.target.value;

        if (event.target.name === "depositAmt") {
            newInput["amount"] = event.target.value;
        }

        if (event.target.name.includes("amount")) {
            const splitedValue = event.target.value.split(".");
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
            depositAmt = "";
        }

        this.setState({
            input: newInput,
            depositAmt: depositAmt,
            errorMsg: [],
            formError: false,
        });
    };

    promoChangeHandler = (event) => {
        let newInput = Object.assign({}, this.state.input);
        newInput["promo_id"] = event === null || event.id === "" ? "" : event.id;

        let showBonus = this.state.dropDown?.promoList.filter((val) => event !== null && val.id === event.id);
        let showBonusTitle = showBonus && showBonus[0] && showBonus[0].title ? showBonus[0].title : "";
        let showBonusContent = showBonus && showBonus[0] && showBonus[0].content ? showBonus[0].content : "";
        let showPromosTable = showBonus && showBonus[0] && showBonus[0].promo_details_image ? showBonus[0].promo_details_image : "";

        this.setState({
            promoOpts: event !== null && event.id > 0 ? event : "",
            input: newInput,
            showPromosTitle: showBonusTitle,
            showPromosContent: showBonusContent,
            showPromosTable: showPromosTable,
        });
    };

    toggleTncModalHandler = () => {
        this.setState({
            openPromoTncModal: !this.state.openPromoTncModal,
        });
    };

    toggleCryptoDepositModalHandler = () => {
        if (this.state.openCryptoDepositModal === true) {
            this.props.redirectHandler();
        }

        this.setState({
            openCryptoDepositModal: !this.state.openCryptoDepositModal,
            timer: 0,
            seconds: 900,
        });
        clearInterval(this.getStatusInterval);
        clearInterval(this.timer);
    };

    confirmHandler = (e) => {
        e.preventDefault();

        if (this.state.input.promo_id > 0) {
            const { t } = this.props;
            let pickedBonus = this.state.dropDown.promoList.filter((val, idx) => {
                if (val.id === this.state.input.promo_id) {
                    return val;
                }
                return null;
            });
            // console.log(pickedBonus);
            const num_format = numFormat(this.props.currentUser.currency);
            let bonusTitle = pickedBonus && pickedBonus[0] && pickedBonus[0].title ? pickedBonus[0].title : "";
            let bonusTurnover = pickedBonus && pickedBonus[0] && pickedBonus[0].turnover_multiply ? pickedBonus[0].turnover_multiply : 0;
            let bonusPercentage = pickedBonus && pickedBonus[0] && pickedBonus[0].percentage ? pickedBonus[0].percentage : 0;
            let bonusMaxPayout = pickedBonus && pickedBonus[0] && pickedBonus[0].max_payout ? pickedBonus[0].max_payout : 0;
            let promotionAmt = (parseFloat(this.state.input.amount) * parseFloat(bonusPercentage)) / 100;
            // console.log(promotionAmt);
            let bonusAmt = promotionAmt > parseFloat(bonusMaxPayout) ? bonusMaxPayout : promotionAmt;
            // console.log(bonusAmt);
            let totalRollover = (parseFloat(this.state.input.amount) + parseFloat(bonusAmt)) * bonusTurnover;
            // console.log(totalRollover);
            let htmlText =
                "<div>" +
                t("deposit.deposit-warning-msg-1").replace("_promotion_", bonusTitle).replace("_turnover_", bonusTurnover) +
                "</div><br>" +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-2") +
                `: ${this.props.currentUser.currency} ${this.state.input.amount}</div>` +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-3") +
                `: ${bonusPercentage}%</div>` +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-4") +
                `: ${this.props.currentUser.currency} ${new Intl.NumberFormat("en-US", num_format).format(totalRollover)}</div>`;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
                title: t("validation.promotion_confirmation"),
                html: htmlText,
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: !isDesktop
                    ? {
                          container: "swal-mobile-container reminder",
                          icon: "swal-reminder-icon",
                      }
                    : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitHandler();
                }
            });
        } else {
            this.submitHandler();
        }
        // this.setState({
        //   openCryptoDepositModal: true
        // })
    };

    submitHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let formData = new FormData();
        Object.keys(this.state.input).forEach((val) => {
            formData.append(val, this.state.input[val]);
        });

        formData.append('payment_type_id', (this.state.paymentTypeId) ? this.state.paymentTypeId : this.props.paymentTypeId);
        formData.append("transaction_channel_id", this.props.transactionChannelId);
        formData.append("token", this.state.channel);
        formData.append("amount", this.state.input.amount);
        formData.append("currency", this.props.currentUser.currency);
        formData.append("platform", this.props.platform);
        formData.append('pg_min_deposit', quickPayTransactionMinimumPaymentAmt);
        formData.append('pg_max_deposit', quickPayTransactionMaximumPaymentAmt);
        formData.append('payment_gateway', this.state.paymentGatewaySelected);

        if(this.state.paymentGatewaySelected === "secret_pay"){
            formData.append('crypto_type', this.state.type);
            formData.append('coin_code', this.state.coinCode);
            formData.append('callbackUrl', process.env.REACT_APP_PAYMENT_GATEWAY_URL + '/payment_gateway/secret_pay/deposit_crypto_success');
            formData.append('failedRedirectURL', window.location.origin + '/transaction-history');
            formData.append('successRedirectURL', window.location.origin + '/transaction-history');
            formData.append('ownerBy', this.props.currentUser.id);
        }

        // HASHING ALGO
        formData.append("trxTime", moment().unix());
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        for (var to_hash of formData.entries()) {
            if (!paramsKey.includes(to_hash[0])) {
                paramsKey.push(to_hash[0]);
            }
        }
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = formData.get(val);
            if (x !== "") {
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        formData.append("trxHash", hashStr);

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + " = " + pair[1]);
        // }

        this.props.funcStore(formData);
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({
                cryptoPayment: "reject",
            });
        }
    };

    render() {
        const { t } = this.props;
        const millesimal = millesimalValue(this.props.currentUser.currency);
        const depositAmts = [{ value: 30*millesimal }, { value: 50*millesimal }, { value: 100*millesimal }, { value: 300*millesimal }, { value: 500*millesimal }, { value: 1000*millesimal }, { value: 5000*millesimal }];
        const num_format = numFormat(this.props.currentUser.currency);
        const payment = this.imageImport(require.context("../../../assets/images/profile/payment", false, /\.(png|jpe?g|svg)$/));
        const cryptoImg = this.imageImport(require.context("../../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));

        // const cryptoChannel = [
        //     // { id: 21, label: "ERC20-USDT", type: "usdt" },
        //     { id: 20, label: "TRC20-USDT", type: "usdt" },
        //     { id: 22, label: "TRC20-USDC", type: "usdc" },
        //     // { id: 23, label: "ERC20-USDC", type: "usdc" },
        // ];

        let promoArry = [];
        let paymentGateway = this.state.dropDown?.paymentGatewayOptions ? this.state.dropDown.paymentGatewayOptions : [];
        let groupSetting = this.state.dropDown.groupSetting ? this.state.dropDown.groupSetting : [];
        let lastDeposit = this.state.dropDown?.lastDeposit.last_deposit || 0;

        if (this.state.dropDown?.promoList && !this.state.loading) {
            if (isDesktop) {
                promoArry.push({
                    id: "",
                    label: t("global.none"),
                    key: "",
                });
            }
            // promoArry = this.state.dropDown.promoList.map((val, idx) => {
            //     return {
            //         id: val.id,
            //         label: val.title,
            //         key: idx,
            //     };
            // });
            this.state.dropDown.promoList.map((val, idx) => {
                return promoArry.push({
                    id: val.id,
                    label: val.title,
                    key: idx,
                });
            });
        }
        return (
            <>
                {isDesktop ? (
                    <form
                        className="forms-col needs-validation"
                        // method="post"
                        id="deposit_form"
                        onSubmit={this.confirmHandler}
                        noValidate
                    >
                        {this.state.loading ? (
                            <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                        ) : (
                            <>
                                <PromosTncModal {...this.state} {...this.props} toggleTncModalHandler={this.toggleTncModalHandler} />

                                <CryptoDepositModal {...this.state} {...this.props} toggleCryptoDepositModalHandler={this.toggleCryptoDepositModalHandler} />

                                <div className="row">
                                    <label className="col-3 col-form-label">{t('deposit.payment-option')}:</label>
                                    <div className="col-9 select-bank-opts">
                                        {paymentGateway.length > 0 ? (
                                            paymentGateway.map((val, idx) => {
                                                if (val.transaction_slug === "crypto" && val.dp_status === 1) {
                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                id={`payment${val.slug}`}
                                                                value={val.slug}
                                                                checked={this.state.paymentGatewaySelected === val.slug ? true : false}
                                                                onChange={() => {}}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.selectPaymentGatewayHandler(val)}>
                                                                <img className="active" src={payment[`${val.slug}.svg`]} alt={val.slug} />
                                                                <p>{val.slug.replaceAll('_', ' ')}</p>
                                                                {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant-deposit")}
                                                                        </>
                                                                        ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" /> 
                                                                                {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                        </>
                                                                        )}             
                                                                    </div>                 
                                                                )}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                        ) : (
                                            this.state.isOptionLoading === false ? 
                                                <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                                <Skeleton />
                                        )}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <label className="col-3 col-form-label">{t("deposit.select-channel")}:</label>
                                    <div className="col-9 select-bank-opts">
                                        
                                        {this.state.paymentGatewaySelected === "direpay" && this.state.cryptoChannel.length > 0 ? (
                                            this.state.cryptoChannel.map((val, idx) => {
                                                return (
                                                    <div className="form-check form-check-inline" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="bank_code"
                                                            id={`crypto${val.label}`}
                                                            value={val.label}
                                                            checked={this.state.channel === val.label ? true : false}
                                                            onChange={() => {}}
                                                        />
                                                        <label
                                                            className="btn form-check-label"
                                                            htmlFor={`crypto${val.label}`}
                                                            onClick={() => this.selectChannel(val.label, val.id, val.type, val.coinCode)}
                                                        >
                                                            <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                            <p>{val.label}</p>
                                                            {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                <div className="transfer-duration">
                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                    Deposit in 1 min */}
                                                                    {val.deposit_est_time === "Instant" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant-deposit")}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                {t("deposit.deposit-in", { minute: val.deposit_est_time })} {t("deposit.min")}
                                                                        </>
                                                                    )}             
                                                                </div>                 
                                                            )}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        ) : this.state.paymentGatewaySelected === "secret_pay" && this.state.spCryptoChannel.length > 0 ? (
                                                this.state.spCryptoChannel.map((val, idx) => {
                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_code"
                                                                id={`crypto${val.label}`}
                                                                value={val.label}
                                                                checked={this.state.channel === val.label ? true : false}
                                                                onChange={() => {}}
                                                            />
                                                            <label
                                                                className="btn form-check-label"
                                                                htmlFor={`crypto${val.label}`}
                                                                onClick={() => this.selectChannel(val.label, val.id, val.type, val.coinCode)}
                                                            >
                                                                <img src={cryptoImg[`${val.type}.svg`]} alt={val.label} />
                                                                <p>{val.label}</p>
                                                                {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {/* <Icon icon="ic:baseline-alarm" />
                                                                        Deposit in 1 min */}
                                                                        {val.deposit_est_time === "Instant" ? (
                                                                            <>
                                                                                <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                    {t("deposit.instant-deposit")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Icon icon="ic:baseline-alarm" />
                                                                                    {t("deposit.deposit-in", { minute: val.deposit_est_time })} {t("deposit.min")}
                                                                            </>
                                                                        )}             
                                                                    </div>                 
                                                                )}
                                                            </label>
                                                        </div>
                                                    );
                                                })
                                            ):(
                                            <>
                                                <Skeleton count={1} height={72}/>
                                                <Skeleton count={1} height={72}/>
                                                <Skeleton count={1} height={72}/>
                                                <Skeleton count={1} height={72}/>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("deposit.promotion")}:</label>
                                    <div className="col-9">
                                        <ReactSelect
                                            classNamePrefix="react-select"
                                            className="w-100"
                                            placeholder={t("history.select-promotion")}
                                            name="selectPromo"
                                            options={promoArry}
                                            onChange={this.promoChangeHandler}
                                            value={this.state.promoOpts}
                                            // isClearable={true}
                                        />
                                        {this.state.promoOpts && (
                                            <p className="tnc-text" onClick={this.toggleTncModalHandler}>
                                                {" "}
                                                {t("deposit.tnc-apply")}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("transaction-type.bonus-code")}</label>
                                    <div className="col-9 d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <StringInput
                                                id="bonusCode"
                                                name="bonusCode"
                                                className={`form-control ${this.state.errorMsg.amount && "was-validated is-invalid"}`}
                                                value={this.state.input.bonusCode}
                                                placeholder={`${t("global.insert")} ${t("transaction-type.bonus-code")}`}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.bonusCode || ""}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-3 col-form-label">{t("deposit.deposit-amount")}:</label>
                                    <div className="col-9 d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className={`form-control ${this.state.errorMsg.amount && "was-validated is-invalid"}`}
                                                aria-label="Username"
                                                // aria-describedby="depositAmtHelp"
                                                value={this.state.input.amount}
                                                placeholder={`${t("deposit.min-max-limit")} ${new Intl.NumberFormat("en-US", num_format).format(
                                                    this.state.depositOption === "bank-transfer" ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0.0
                                                )}/${new Intl.NumberFormat("en-US", num_format).format(
                                                    this.state.depositOption === "bank-transfer" ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0.0
                                                )}`}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                required={true}
                                                min={this.state.depositOption === "bank-transfer" ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0}
                                                max={this.state.depositOption === "bank-transfer" ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0}
                                                step="any"
                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                minValueMessage={t("validation.amtMinValue", {
                                                    0: this.state.depositOption === "bank-transfer" ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0,
                                                })}
                                                maxValueMessage={t("validation.amtMaxValue", {
                                                    0: this.state.depositOption === "bank-transfer" ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0,
                                                })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.amount || ""}
                                            />
                                        </div>
                                        <div className="form-check-group deposit-amts">
                                            {depositAmts &&
                                                depositAmts.map((depositAmt, idx) => (
                                                    <div className="form-check form-check-inline" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="depositAmt"
                                                            id={`depositAmt${depositAmt.value}`}
                                                            value={depositAmt.value}
                                                            onChange={(event) => this.onInputChangeHandler(event)}
                                                            checked={Number(this.state.depositAmt) === Number(depositAmt.value) ? true : false}
                                                        />
                                                        <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                            {new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(depositAmt.value || 0.0)}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                        <small>
                                            {t("deposit.last-deposit-amount")}:{" "}
                                            <span style={{ fontWeight: "600" }}>
                                                {this.props.currentUser.currency}
                                                {lastDeposit}
                                            </span>
                                        </small>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9 offset-3">
                                        <button
                                            className="btn btn-gradient-blue"
                                            type="submit"
                                            // value="Submit"
                                            // onClick={this.confirmHandler}
                                        >
                                            {t("global.confirm")}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                ) : (
                    <MobileCrypto
                        {...this.state}
                        // {...this.props}
                        spCryptoChannel={this.state.spCryptoChannel}
                        paymentGateway={paymentGateway}
                        groupSetting={groupSetting}
                        quickPayTransactionMaximumPaymentAmt={quickPayTransactionMaximumPaymentAmt}
                        quickPayTransactionMinimumPaymentAmt={quickPayTransactionMinimumPaymentAmt}
                        confirmHandler={this.confirmHandler}
                        selectChannel={this.selectChannel}
                        promoArry={promoArry}
                        promoChangeHandler={this.promoChangeHandler}
                        toggleTncModalHandler={this.toggleTncModalHandler}
                        depositAmts={depositAmts}
                        onInputChangeHandler={this.onInputChangeHandler}
                        selectPaymentGatewayHandler={this.selectPaymentGatewayHandler}
                        toggleCryptoDepositModalHandler={this.toggleCryptoDepositModalHandler}
                        isOptionLoading={this.state.isOptionLoading}
                        payment={payment}
                        numFormat={num_format}
                    />
                )}
            </>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        currentUser: state.currentUser.data,
        store: state.deposit.store,
        crypto_trans_status: state.deposit.crypto_trans_status,
        default_dropdown_options: state.deposit.default_dropdown_options,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
        funcCryptoTransStatus: (data) => dispatch(actions.getCryptoTransStatus(data)),
        funcDefaultDropDownOptions: (data) => dispatch(actions.defaultDropDownOptions(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Crypto));