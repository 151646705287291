import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

// Assets
// import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
// import Logo from "../../assets/images/merchant-logo.png";
// import EventLogo from "../../assets/images/logo-raya.png"; //change when event change

// Components
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import ValidationError from "../../components/ValidationError/ValidationError";
// import Button from "../../components/Button/Button";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import SideBar from '../../components/SideBar/SideBar';

class MobileLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  shouldComponentUpdate() {
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/register/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }


    return (
      <Auxiliary>
        {this.state.isLoading && <LoadingPage />}
        {/* <video
          className="login-video mobile"
          autoPlay
          loop
          muted
          controls={false}
          playsInline
        >
          <source src={BackVideo} type="video/mp4" />
        </video> */}
        <section className="register-section register-m-section">
          <SideBar />
          <img src={img[`reglogin-m-bg${imageLanguage}.webp`]} alt="login register image" className="logreg-img" />
          <div className="x-container-no-m">
            <div className="register-form">
              {/* {this.props.currentEvent === "raya" ? (
                <img src={EventLogo} alt="logo" className="logo-image" />
              ) : (
                <img src={Logo} alt="logo" className="logo-image" />
              )} */}
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/login"
                  >
                    {t("page-header.login")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/register">
                    {t("page-header.register")}
                  </a>
                </li>
              </ul>
              <form
                method="post"
                className="member-form"
                name="member_login_from"
                id="member_login_from"
                onSubmit={this.props.postMemberLogin}
                noValidate
              >
                <ValidationError
                  formName="member_login_from"
                  touched={this.props.touched}
                  message={this.props.error_message}
                />
                {/* username */}
                <div className="input-group">
                  <span className="input-group-text left-icon">
                    <img src={img["icon-user.webp"]} alt="username icon" />
                  </span>
                  <StringInput
                    className={`form-control ${this.props.errorMsg.username && "was-validated is-invalid"
                      }`}
                    onChange={this.props.onInputChange}
                    id="username"
                    name="username"
                    placeholder={t("register.username")}
                    value={this.props.formFilter.username}
                    required
                    requiredMessage={t("validation.required")}
                    errorMsg={this.props.errorMsg.username || ""}
                    formError={this.props.formError}
                  />
                </div>
                {/* password */}
                <div className="input-group">
                  <span className="input-group-text left-icon">
                    <img src={img["icon-password.webp"]} alt="password icon" />
                  </span>

                  <PasswordInput
                    showPwd={this.props.formFilter.showPwd}
                    onChange={this.props.onInputChange}
                    id="password"
                    name="password"
                    className={`no-border form-control ${this.props.errorMsg.password && "was-validated is-invalid"
                      }`}
                    placeholder={t("register.password")}
                    value={this.props.formFilter.password}
                    minLength={6}
                    required
                    requiredMessage={t("validation.required")}
                    minLengthMessage={t("validation.minLength", { 0: 6 })}
                    errorMsg={this.props.errorMsg.password || ""}
                    formError={this.props.formError}
                    format={
                      <span className="input-group-text">
                        <button
                          type="button"
                          className=" right-icon"
                          onClick={this.props.showPwd}
                        >
                          {this.props.formFilter.showPwd ? (
                            <img
                              src={img["icon-eye-open.webp"]}
                              alt="show pwd"
                            />
                          ) : (
                            <img
                              src={img["icon-eye-close.webp"]}
                              alt="hide pwd"
                            />
                          )}
                        </button>
                      </span>
                    }
                  />
                </div>
                {/* checklist and forget password*/}
                <div className="d-flex justify-content-end small-w">
                  <Link to={{ pathname: "/forget-password" }}>
                    {" "}
                    <small>{t("page-header.forgot")}?</small>
                  </Link>
                </div>
                <button
                  className={`btn btn-blue ${this.props.formFilter.username !== "" &&
                    this.props.formFilter.password !== ""
                    ? ""
                    : "disabled"
                    }`}
                  type="submit"
                  form="member_login_from"
                  value="Submit"
                >
                  {t("page-header.login")}
                </button>
                {/* register now /skip and enter*/}
                <div className="d-flex justify-content-center small-w">
                  <div>
                    <Link to={{ pathname: "/" }}>
                      <small>{t("page-header.skip-n-enter")} </small>
                    </Link>
                  </div>
                </div>
                {/*google and tele login*/}
                <div className="text-center bottom-content small-w">
                  <small>
                    <span className="line">{t("register.or")}</span>
                  </small>
                  <small className="small-w">
                    {t("register.login-with")} :
                  </small>

                  <div className="google-tele-bg ">
                    <button
                      type="button"
                      onClick={this.props.handleGoogleRedirect}
                    >
                      <img
                        src={img["icon-google.webp"]}
                        alt="google logo"
                        onClick={this.props.handleGoogleRedirect}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={this.props.handleTelegramRedirect}
                    >
                      <img src={img["icon-telegram.webp"]} alt="telegram logo" />
                    </button>
                  </div>
                </div>
                {/* contact us*/}
                <div className="text-center contact-us-btn small-w">
                  <Link to={{ pathname: "/contact-us" }}>
                    <img
                      src={img["icon-contact-us.webp"]}
                      alt="contact us icon"
                    />
                    <small> {t("footer.contact-us")} </small>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

export default withNamespaces("translation")(MobileLogin);
