// export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
// export const GET_COUNTRY_ID = "GET_COUNTRY_ID";
// export const GET_USER_GROUP = "GET_USER_GROUP";


// GAMES
export const SET_GAME_URL = "SET_GAME_URL";

export const SET_GAME_QT = "SET_GAME_QT";
