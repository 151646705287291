import React, { Component } from "react";
// import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
import { Icon } from "@iconify/react";

//Components 
import NumberInput from "../../../components/Input/NumberInput/NumberInput";

//Actions
import MobileSelectRadio from "../../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";

class MobileQuickPay extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            showSelectRadio: false,
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleSelectRadio = () => {
        if (this.state.showSelectRadio === false) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <PromosTncModal
                    {...this.state}
                    {...this.props}
                    toggleTncModalHandler={this.props.toggleTncModalHandler}
                />
                <form className="forms-col needs-validation" method="post" id="deposit_form" onSubmit={this.props.confirmHandler} noValidate>
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t('deposit.payment-option')}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.paymentGateway.length > 0 ? (
                                                this.props.paymentGateway.map((val, idx) => {
                                                    if (val.transaction_slug === "quick-pay" && val.dp_status === 1) {
                                                        return (
                                                            <div className="form-check form-check-inline four-check" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`payment${val.slug}`}
                                                                    value={val.slug}
                                                                    checked={this.props.selectedPaymentGateway === val.slug ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.props.selectPaymentGatewayHandler(val)}>
                                                                    <img className="active" src={this.props.payment[`${val.slug}.svg`]} alt={val.slug} />
                                                                    <p>{val.slug.replaceAll('_', ' ')}</p>
                                                                    {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {/* <Icon icon="ic:baseline-alarm" />
                                                                        Deposit in 1 min */}
                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                            <>
                                                                                <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                    {t("deposit.instant")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Icon icon="ic:baseline-alarm" />
                                                                                    {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                            </>
                                                                        )}
                                                                                            
                                                                    </div>                 
                                                                )}
                                                                </label>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                this.props.isOptionLoading === false ?
                                                <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {this.props.selectedPaymentGateway=== 'secret_pay' && (
                                    <div>
                                        <label className="col-form-label">{t('deposit.choose-a-bank')}:</label>

                                        <div className="input-group-col">
                                            {this.props.loadingBank ? (
                                                <Skeleton count={2} />
                                            ) : (
                                                
                                                (this.props.paymentGateway.length > 0 && this.props.secretPayBankOption.length > 0) ? (
                                                    <div className="form-check-group">
                                                        {this.props.secretPayBankOption.map((val, idx) => {
                                                            let bankColorImage =
                                                                (this.props.bankImg[`${val.bankCode.toLowerCase()}-color.svg`] !== undefined)
                                                                    ?
                                                                    this.props.bankImg[`${val.bankCode.toLowerCase()}-color.svg`]
                                                                    :
                                                                    this.props.bankImg['default-bank-icon-color.png'];

                                                            return (
                                                                <div className="form-check form-check-inline four-check" key={idx}>
                                                                    <input
                                                                        className="btn-check"
                                                                        type="radio"
                                                                        name="bank_code"
                                                                        id={`bankOpt${val.bankCode}`}
                                                                        value={val.bankCode}
                                                                        checked={this.props.secretPayBankCode === val.bankCode ? true : false}
                                                                        onChange={() => { }}
                                                                    />
                                                                    <label className="btn form-check-label" htmlFor={`bankOpt${val.bankCode}`} onClick={() => this.props.selectSecretPayBank(val)}>
                                                                        <img className="active" src={bankColorImage} alt={val.bankCode} />
                                                                        <p>{val.bankName}</p>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                     </div>
                                                ) : (
                                                    this.props.isOptionLoading === false ?
                                                    <span className="text-danger">{t('deposit.invalid-bank')}</span> :
                                                    <Skeleton />
                                                )
                                            )}
                                            </div>
                                    </div>
                                )}
                                <div>
                                    <label className="col-form-label d-flex align-items-center">{t('deposit.promotion')}:
                                        {
                                            this.props.promoOpts &&
                                            <button className="btn btn-gradient-blue tnc-btn" type="button" onClick={this.props.toggleTncModalHandler}>
                                                {t('deposit.tnc-apply')}
                                            </button>
                                        }
                                    </label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            {/* <input
                                                placeholder={t('history.select-promotion')}
                                                name="selectPromo"
                                                className="form-control"
                                                value={this.props.promoOpts.label}
                                                disabled
                                            /> */}
                                            <p className="form-control lh-lg" onClick={this.toggleSelectRadio}>
                                                {
                                                    this.props.promoOpts ?
                                                        (
                                                            this.props.promoOpts.label
                                                        ) : (
                                                            <>{t('history.select-promotion')}</>
                                                        )
                                                }
                                            </p>

                                            {this.props.promoOpts && <span className="input-group-text"><Icon icon="mdi:close-circle" onClick={this.props.promoChangeHandler} /></span>}

                                            <span className="input-group-text" onClick={this.toggleSelectRadio}><Icon icon="uil:angle-right" /></span>
                                        </div>
                                        <MobileSelectRadio
                                            name={"selectPromo"}
                                            toggleHandler={this.toggleSelectRadio}
                                            showSelectRadio={this.state.showSelectRadio}
                                            onChange={this.props.promoChangeHandler}
                                            options={this.props.promoArry}
                                            value={(this.props.promoOpts && this.props.promoOpts.key !== null) ? this.props.promoOpts.key : null}
                                        />
                                        {/* <ReactSelect
                                            classNamePrefix="react-select"
                                            placeholder={t('history.select-promotion')}
                                            name="selectPromo"
                                            options={this.props.promoArry}
                                            onChange={this.props.promoChangeHandler}
                                            // value={this.props.promoOpts}
                                            // isClearable={true}
                                        /> */}
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('transaction-type.bonus-code')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <input 
                                                id="bonusCode"
                                                name="bonusCode"
                                                value={this.props.input.bonusCode}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.bonusCode || ''}
                                                autoComplete="off"
                                                type="text" 
                                                className="form-control" 
                                                placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('deposit.deposit-amount')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className={`form-control ${this.props.errorMsg.amount && 'was-validated is-invalid'}`}
                                                aria-label="Username"
                                                aria-describedby="depositAmtHelp"
                                                value={this.props.input.amount}
                                                placeholder={`${t('deposit.min-max-limit')} ${new Intl.NumberFormat("en-US", this.numFormat).format(
                                                    (this.props.depositOption === "bank-transfer") ? this.props.groupSetting.min_deposit : this.props.quickPayTransactionMinimumPaymentAmt || 0.0
                                                )}/${new Intl.NumberFormat("en-US", this.numFormat).format((this.props.depositOption === "bank-transfer") ? this.props.groupSetting.max_deposit : this.props.quickPayTransactionMaximumPaymentAmt || 0.0)}`}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                required={true}
                                                min={(this.props.depositOption === "bank-transfer") ? this.props.groupSetting.min_deposit : this.props.quickPayTransactionMinimumPaymentAmt || 0}
                                                max={(this.props.depositOption === "bank-transfer") ? this.props.groupSetting.max_deposit : this.props.quickPayTransactionMaximumPaymentAmt || 0}
                                                step="any"
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                minValueMessage={t("validation.amtMinValue", { 0: (this.state.depositOption === "bank-transfer") ? this.props.groupSetting.min_deposit : this.props.quickPayTransactionMinimumPaymentAmt || 0 })}
                                                maxValueMessage={t("validation.amtMaxValue", { 0: (this.state.depositOption === "bank-transfer") ? this.props.groupSetting.max_deposit : this.props.quickPayTransactionMaximumPaymentAmt || 0 })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.amount || ''}
                                            />
                                        </div>
                                        <div className="form-check-group deposit-amts">
                                            {this.props.depositAmts && this.props.depositAmts.map((depositAmt, idx) => (
                                                <div className="form-check form-check-inline five-check" key={idx}>
                                                    <input
                                                        className="btn-check"
                                                        type="radio"
                                                        name="depositAmt"
                                                        id={`depositAmt${depositAmt.value}`}
                                                        value={depositAmt.value}
                                                        onChange={(event) => this.props.onInputChangeHandler(event)}
                                                        checked={Number(this.props.depositAmt) === Number(depositAmt.value) ? true : false}
                                                    />
                                                    <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                        {new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(depositAmt.value || 0.0)}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <small>{t('deposit.last-deposit-amount')}: <span style={{ fontWeight: '600' }}>{this.props.currentUser.currency}{this.props.lastDeposit}</span></small>
                                    </div>
                                </div>
                                <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                    {t('global.confirm')}
                                </button>
                            </>)}
                    </div>
                </form>
            </>
        );
    }
}

export default (withNamespaces("translation")(MobileQuickPay));