import React, { Component } from 'react'
import { Accordion, TabContainer, Nav, NavItem, NavLink, TabContent, TabPane } from 'react-bootstrap'
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import MobileSelectRadio from "../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

export class MobileInfoCentre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRadio: false,
      activeTab: 'faq',
      activeOption: '',
      activeKey: 0,
      faqOptions: [],
      ruleOptions: [],
      options: []
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const faqOptions = [
      { label: t("info-centre.general"), key: 0 },
      { label: t("info-centre.my-acc"), key: 1 },
      { label: t("info-centre.payment"), key: 2 },
      { label: t("info-centre.cryptocurrency"), key: 3 },
      { label: t("info-centre.cash-rebates"), key: 4 },
      { label: t("info-centre.products"), key: 5 },
    ];

    const ruleOptions = [
      { label: t("info-centre.info-sport"), key: 0 },
      { label: t("info-centre.info-live-casino"), key: 1 },
      { label: t("info-centre.info-games"), key: 2 },
      { label: t("info-centre.info-lottery"), key: 3 },
      { label: t("info-centre.info-poker"), key: 4 },
    ]

    const newOptions = faqOptions.filter((item) => item.key !== this.state.activeKey)

    this.setState({
      activeOption: t("info-centre.general"),
      faqOptions: faqOptions,
      ruleOptions: ruleOptions,
      options: newOptions
    })

  }

  changeActiveTab = (tab) => {
    const { t } = this.props;

    if (tab === 'faq') {
      const newOptions = this.state.faqOptions.filter((item) => item.key !== 0)
      this.setState({
        activeTab: tab,
        activeOption: t("info-centre.general"),
        activeKey: 0,
        options: newOptions
      })
    } else if (tab === 'rule') {
      const newOptions = this.state.ruleOptions.filter((item) => item.key !== 0)
      this.setState({
        activeTab: tab,
        activeOption: t("info-centre.info-sport"),
        activeKey: 0,
        options: newOptions
      })
    } else if (tab === 'tnc') {
      this.setState({
        activeTab: tab
      })
    }
  }

  toggleSelectRadio = () => {
    this.setState({
      showSelectRadio: !this.state.showSelectRadio,
    });
  };

  handleOptionChange = (event) => {
    var newOptions;
    if (this.state.activeTab === 'faq') {
      newOptions = this.state.faqOptions.filter((item) => item.key !== event.key)
    } else if (this.state.activeTab === 'rule') {
      newOptions = this.state.ruleOptions.filter((item) => item.key !== event.key)
    }
    this.setState({
      activeOption: event.label,
      activeKey: event.key,
      options: newOptions
    })
  }


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(require.context("../../assets/images/info-centre/mobile", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //     imageLanguage = "-ms"
      //     break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        <section className='ic-m-section info-center-section'>
          {!localStorage.getItem("browseByApp") &&  
          <MobileSubHeader
            className="bg-white"
            returnPage="/"
            header={t("footer.info-centre")}
            style={{ justifyContent: "unset" }}
          />}

          <TabContainer defaultActiveKey="faq">
            <Nav className='mobile' variant="pills">
              <NavItem>
                <NavLink eventKey="faq" onClick={() => this.changeActiveTab("faq")}>{t("info-centre.faq")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="rule" onClick={() => this.changeActiveTab("rule")}>{t("info-centre.rules-and-regulations")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="tnc" onClick={() => this.changeActiveTab("tnc")}>{t("info-centre.terms-and-conditions")}</NavLink>
              </NavItem>
            </Nav>

            {
              this.state.activeTab !== 'tnc' &&
              <div className="faq-selection" onClick={this.toggleSelectRadio}>
                <p>{this.state.activeOption}</p>
                <Icon icon="uil:angle-right" />
              </div>
            }

            <MobileSelectRadio
              name="infoCentre"
              showSelectRadio={this.state.showSelectRadio}
              toggleHandler={this.toggleSelectRadio}
              onChange={this.handleOptionChange}
              options={this.state.options}
            // value={this.state.bank_key}
            />
            <TabContent className='tab-content-container'>
              <TabPane eventKey="faq">
                {
                  this.state.activeTab === 'faq' && this.state.activeKey === 0 ? (
                    //General
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.general-title-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6></Accordion.Header>
                          <Accordion.Body><p>{t("info-centre.general-details-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.general-title-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6></Accordion.Header>
                          <Accordion.Body> <p>{t("info-centre.general-details-2")} <Link to={{ pathname: "/register" }}> { window.location.hostname === "localhost" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname }/register</Link>{t("info-centre.general-details-2-1")}</p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header><h6>{t("info-centre.general-title-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.general-details-3")}</p>
                            <ul>
                              <li>{t("info-centre.gd3-li-1")}</li>
                              <li>{t("info-centre.gd3-li-2")}</li>
                              <li>{t("info-centre.gd3-li-3")}</li>
                              <li>{t("info-centre.gd3-li-4")}</li>
                              <li>{t("info-centre.gd3-li-5")}</li>
                              <li>{t("info-centre.gd3-li-6")}</li>
                              <li>{t("info-centre.gd3-li-7")}</li>
                              <li>{t("info-centre.gd3-li-8")}</li>
                              <li>{t("info-centre.gd3-li-9")}</li>
                            </ul>
                            <p>{t("info-centre.pd-notes")}
                              <br />{t("info-centre.gd3-1")}
                              <br />{t("info-centre.gd3-1-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'faq' && this.state.activeKey === 1 ? (
                    //my acc
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.my-acc-title-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.my-acc-details-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <p>{t("info-centre.myad-1-1")}</p>

                            <strong>{t("info-centre.myad-1-2")}</strong>
                            <p>{t("info-centre.myad-1-3")}</p>

                            <strong>{t("info-centre.myad-1-4")}</strong>
                            <p>{t("info-centre.myad-1-5")}</p>

                            <strong>{t("info-centre.myad-1-6")}</strong>
                            <p>{t("info-centre.myad-1-7")}</p>

                            <strong>{t("info-centre.myad-1-8")}</strong>
                            <p>{t("info-centre.myad-1-9")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header> <h6>{t("info-centre.my-acc-title-2")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.my-acc-details-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.myad-2-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </p>
                            <p>{t("info-centre.myad-2-2")} </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h6>{t("info-centre.my-acc-title-3")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.my-acc-details-3")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'faq' && this.state.activeKey === 2 ? (
                    //payment
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.payment-title-1-how-to-deposit")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.payment-details-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </p>
                            <ul>
                              <li>{t("info-centre.pd1-li-1")}</li>
                              <li>{t("info-centre.pd1-li-2")}</li>
                              <li>{t("info-centre.pd1-li-3")}</li>
                              <li>{t("info-centre.pd1-li-4")}</li>
                              <li>{t("info-centre.pd1-li-5")}</li>
                            </ul>

                            <strong>{t("info-centre.pd-notes")}</strong>
                            <p>{t("info-centre.pd1-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.pd1-2-types-of-deposit-below")}</h6>
                            <h6>{t("info-centre.pd1-2-a-how-do-i-make-a-deposit")}</h6>

                            <p>{t("info-centre.pd1-2-1")}</p>

                            <ul>
                              <li>{t("info-centre.pd1-2-li-1")}</li>
                              <li>{t("info-centre.pd1-2-li-2")}</li>
                              <li>{t("info-centre.pd1-2-li-3")}</li>
                              <li>{t("info-centre.pd1-2-li-4")}</li>
                              <li>{t("info-centre.pd1-2-li-5")}</li>
                              <li>{t("info-centre.pd1-2-li-6")}</li>
                              <li>{t("info-centre.pd1-2-li-7")}</li>
                              <li>{t("info-centre.pd1-2-li-8")}</li>
                            </ul>

                            <strong>{t("info-centre.pd-step")}</strong>
                            <ol className="custom-list">
                              <label><li>{t("info-centre.pd-step1")}</li> </label>
                              <li>{t("info-centre.pd1-2-2")} <Link to={{ pathname: "/home" }}>{t("info-centre.pd1-2-2-1")}</Link></li>
                              <label>{t("info-centre.pd-step2")}</label>
                              <li>{t("info-centre.pd1-2-3")}</li>
                              <label>{t("info-centre.pd-step3")}</label>
                              <li>{t("info-centre.pd1-2-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>
                            <ul>
                              <li>{t("info-centre.pd1-2-4-li-1")}</li>
                              <li>{t("info-centre.pd1-2-4-li-2")}</li>
                              <li>{t("info-centre.pd1-2-4-li-3")}</li>
                            </ul>
                            <br />
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <p>{t("info-centre.pd1-2-5")}</p>

                            <h6>{t("info-centre.pd1-3-b-how-to-use-fpx")}</h6>

                            <h6>{t("info-centre.pd-step")}</h6>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step1")}</label>
                              <li>{t("info-centre.pd1-3-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <label>{t("info-centre.pd-step2")}</label>
                              <li>
                                {t("info-centre.pd1-3-2")}<strong>{t("info-centre.pd1-3-2-1")}</strong>{t("info-centre.pd1-3-2-2")}<strong>{t("info-centre.pd1-3-2-3")}</strong>{t("info-centre.pd1-3-2-4")}
                              </li>
                            </ol>
                            <ul>
                              <li>{t("info-centre.pd1-3-2-li-1")}</li>
                              <li>{t("info-centre.pd1-3-2-li-2")}</li>
                              <li>{t("info-centre.pd1-3-2-li-3")}</li>
                              <li>{t("info-centre.pd1-3-2-li-4")}</li>
                              <li>{t("info-centre.pd1-3-2-li-5")}</li>
                            </ul>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step3")}</label>
                              <li>{t("info-centre.pd1-3-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>
                            <br />
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <ol>
                              <li>{t("info-centre.pd1-3-4")}</li>
                              <li>{t("info-centre.pd1-3-4-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <h6>{t("info-centre.pd1-4-c-how-to-use-qr-code")}</h6>
                            <h6>{t("info-centre.pd-step")}</h6>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step1")} </label>
                              <li>{t("info-centre.pd1-4-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <label>{t("info-centre.pd-step2")}</label>
                              <li>{t("info-centre.pd1-4-2")}</li>
                            </ol>
                            <ul>
                              <li>{t("info-centre.pd1-4-2-li-1")}</li>
                              <li>{t("info-centre.pd1-4-2-li-2")}</li>
                              <li>{t("info-centre.pd1-4-2-li-3")}</li>
                            </ul>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step3")}</label>
                              <li>{t("info-centre.pd1-4-3")}</li>
                            </ol>
                            <br />
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <ol>
                              <li>{t("info-centre.pd1-4-4")}</li>
                              <li>{t("info-centre.pd1-4-4-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <h6>{t("info-centre.pd1-5-d-how-to-use-ewallet")}</h6>

                            <h6>{t("info-centre.pd-step")}</h6>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step1")}</label>
                              <li>{t("info-centre.pd1-5-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <label>{t("info-centre.pd-step2")}</label>
                              <li>{t("info-centre.pd1-5-2")}</li>
                            </ol>
                            <ul>
                              <li>{t("info-centre.pd1-5-2-li-1")}</li>
                              <li>{t("info-centre.pd1-5-2-li-2")}</li>
                              <li>{t("info-centre.pd1-5-2-li-3")}</li>
                            </ul>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step3")}</label>
                              <li>{t("info-centre.pd1-5-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>
                            <br />
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <ol>
                              <li>{t("info-centre.pd1-5-4")}</li>
                              <li>{t("info-centre.pd1-5-4-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <h6>{t("info-centre.pd1-6-e-how-to-use-cryto")}</h6>
                            <h6>{t("info-centre.pd-step")}</h6>
                            <ol className="custom-list">
                              <label>{t("info-centre.pd-step1")}</label>
                              <li>{t("info-centre.pd1-6-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <label>{t("info-centre.pd-step2")}</label>
                              <li>{t("info-centre.pd1-6-2")}</li>
                              <label>{t("info-centre.pd-step3")}</label>
                              <li>{t("info-centre.pd1-6-3")}</li>
                              <label>{t("info-centre.pd-step4")}</label>
                              <li>{t("info-centre.pd1-6-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </li>
                            </ol>
                            <br />
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <ol>
                              <li>{t("info-centre.pd1-6-5")}</li>
                              <li>{t("info-centre.pd1-6-5-1")}</li>
                              <li>{t("info-centre.pd1-6-5-2")}</li>
                              <li>{t("info-centre.pd1-6-5-3")}</li>
                              <li>{t("info-centre.pd1-6-5-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.payment-title-2-how-to-withdraw")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.payment-details-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pd2-li-1")}</li>
                            </ul>
                            <strong>{t("info-centre.pd2-1-documentary")}</strong>
                            <p>{t("info-centre.pd2-1-1")}</p>
                            <strong>{t("info-centre.pd2-2-how-do-i-withdraw-funds-using-bank-transfer")}</strong>
                            <p>{t("info-centre.pd2-2-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pd2-2-2-li-1")}</li>
                              <li>{t("info-centre.pd2-2-2-li-2")}</li>
                              <li>{t("info-centre.pd2-2-2-li-3")}</li>
                              <li>{t("info-centre.pd2-2-2-li-4")}</li>
                              <li>{t("info-centre.pd2-2-2-li-5")}</li>
                              <li>{t("info-centre.pd2-2-2-li-6")}</li>
                              <li>{t("info-centre.pd2-2-2-li-7")}</li>
                              <li>{t("info-centre.pd2-2-2-li-8")}</li>
                              <li>{t("info-centre.pd2-2-2-li-9")}</li>
                            </ul>
                            <p>{t("info-centre.pd2-2-3")}</p>
                            <ul>
                              <li>{t("info-centre.pd2-2-3-li-1")}</li>
                              <li>{t("info-centre.pd2-2-3-li-2")}</li>
                            </ul>
                            <p>{t("info-centre.pd2-2-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pd2-2-4-li-1")}</li>
                              <li>{t("info-centre.pd2-2-4-li-2")}</li>
                            </ul>
                            <strong>{t("info-centre.pd2-3-how-do-i-widthdraw-funds-using-crypto")}</strong>
                            <p>{t("info-centre.pd2-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pd2-3-li-1")}</li>
                              <li>{t("info-centre.pd2-3-li-2")}</li>
                              <li>{t("info-centre.pd2-3-li-3")}</li>
                              <li>{t("info-centre.pd2-3-li-4")}</li>
                              <li>{t("info-centre.pd2-3-li-5")}</li>
                            </ul>
                            <p>{t("info-centre.pd2-3-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header> <h6>{t("info-centre.payment-title-3-how-long-will-i-receive-my-withdraw")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.payment-details-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pd3-li-1")}</li>
                              <li>{t("info-centre.pd3-li-2")}</li>
                            </ul>
                            <strong>{t("info-centre.pd-notes")}</strong>
                            <p>{t("info-centre.pd3-2")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header><h6>{t("info-centre.payment-title-4-is-there-any-charges")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.payment-details-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'faq' && this.state.activeKey === 3 ? (
                    //crypto
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> <h6>{t("info-centre.cryto-title-1-what-is-cryptocurrency")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cryto-details-1")}</p>
                            <strong>{t("info-centre.cd1-1")}</strong>
                            <p>{t("info-centre.cd1-1-1")}</p>
                            <strong>{t("info-centre.cd1-2")}</strong>
                            <p>{t("info-centre.cd1-2-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <strong>{t("info-centre.cd1-3")}</strong>
                            <p>{t("info-centre.cd1-3-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.cryto-title-2-how-to-pruchase-cryto")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <ol>
                              <li>{t("info-centre.cryto-details-2-li")}</li>
                              <li>{t("info-centre.cd2-li-1")}</li>
                              <li>{t("info-centre.cd2-li-2")}</li>
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h6>{t("info-centre.cryto-title-3-charges-for-the-deposit")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cryto-details-3")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header> <h6>{t("info-centre.cryto-title-4-how-long-does-it-take-to-process", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cryto-details-4")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header> <h6>{t("info-centre.cryto-title-5-cryptocurrency-safety")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cryto-details-5")} </p>
                            <ul>
                              <li>{t("info-centre.cd5-li-1")}</li>
                              <li>{t("info-centre.cd5-li-2")}</li>
                              <li>{t("info-centre.cd5-li-3")}</li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header><h6>{t("info-centre.cryto-title-6-privacy-and-security")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cryto-details-6", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.cd6-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}<a href={`mailto: ${process.env.REACT_APP_MERCHANT_INFO_EMAIL}`}>{process.env.REACT_APP_MERCHANT_INFO_EMAIL}</a></p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'faq' && this.state.activeKey === 4 ? (
                    //cash-rebates
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.cash-title-1-what-is-cash-rebates")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cash-details-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.cash-title-2-how-does-the-cash-rebate-works")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cash-details-2")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header> <h6>{t("info-centre.cash-title-3-is-there-any-rollover")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cash-details-3")}<Link to={{ pathname: "/contact-us" }}>{t("info-centre.cd3-1")}</Link>{t("info-centre.cd3-2")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header><h6>{t("info-centre.cash-title-4-how-to-check-my-rebates")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.cash-details-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'faq' && this.state.activeKey === 5 ? (
                    //products
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.products-title-1-live-casino")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <strong>{t("info-centre.products-details-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </strong>
                            <p>{t("info-centre.pdc1-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </p>

                            <strong>{t("info-centre.pdc1-2")}</strong>
                            <p>{t("info-centre.pdc1-2-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <img
                              src={img[`faq-product-table1${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />
                            <strong>{t("info-centre.pdc1-3")}</strong>
                            <p>{t("info-centre.pdc1-3-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <strong>{t("info-centre.pdc1-4")} </strong>
                            <p>{t("info-centre.pdc1-4-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.products-title-2-sports")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <strong>{t("info-centre.products-details-2")}</strong>
                            <p>{t("info-centre.pdc2-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul>
                              <li>{t("info-centre.pdc2-1-li-1")}</li>
                              <li>{t("info-centre.pdc2-1-li-2")}</li>
                              <li>{t("info-centre.pdc2-1-li-3")}</li>
                              <li>{t("info-centre.pdc2-1-li-4")}</li>
                              <li>{t("info-centre.pdc2-1-li-5")}</li>
                            </ul>
                            <strong>{t("info-centre.pdc2-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</strong>
                            <p>{t("info-centre.pdc2-2-1")}</p>
                            <strong>{t("info-centre.pdc2-3")}</strong>
                            <p>{t("info-centre.pdc2-3-1")}</p>
                            <strong>{t("info-centre.pdc2-4")}</strong>
                            <p>{t("info-centre.pdc2-4-1")}</p>
                            <strong>{t("info-centre.pdc2-5")}</strong>
                            <p>{t("info-centre.pdc2-5-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h6>{t("info-centre.products-title-3-fishing")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.products-details-3")}</p>
                            <ul>
                              <li>{t("info-centre.pdc3-li-1")}</li>
                              <li>{t("info-centre.pdc3-li-2")}</li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header> <h6>{t("info-centre.products-title-4-poker")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.products-details-4")}</p>
                            <p>{t("info-centre.pdc-4-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header><h6>{t("info-centre.products-title-5-lottery")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <strong>{t("info-centre.products-details-5", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</strong>
                            <p>{t("info-centre.pdc5-1")}</p>
                            <ul>
                              <li>{t("info-centre.pdc5-li-1")}</li>
                              <li>{t("info-centre.pdc5-li-2")}</li>
                              <li>{t("info-centre.pdc5-li-3")}</li>
                              <li>{t("info-centre.pdc5-li-4")}</li>
                              <li>{t("info-centre.pdc5-li-5")}</li>
                              <li>{t("info-centre.pdc5-li-6")}</li>
                              <li>{t("info-centre.pdc5-li-7")}</li>
                              <li>{t("info-centre.pdc5-li-8")}</li>
                              <li>{t("info-centre.pdc5-li-9")}</li>
                              <li>{t("info-centre.pdc5-li-10")}</li>
                              <li>{t("info-centre.pdc5-li-11")}</li>
                              <li>{t("info-centre.pdc5-li-12")}</li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header><h6>{t("info-centre.products-title-6-slot-games")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <strong>{t("info-centre.products-details-6", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</strong>
                            <p>{t("info-centre.pdc6-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : (<></>)
                }



              </TabPane>
              <TabPane eventKey="rule">
                {
                  this.state.activeTab === 'rule' && this.state.activeKey === 0 ? (
                    //sports
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.sports-title-1-general-rules")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <h6>{t("info-centre.sports-details-1")}</h6>
                            <p>{t("info-centre.sd1-11", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.sd1-12")}</h6>
                            <p>{t("info-centre.sd1-12-1")}<Link to={{ pathname: "/home" }}>{t("info-centre.sd1-12-2")}</Link>
                              {t("info-centre.sd1-12-3")}<Link to={{ pathname: "/home" }}>{t("info-centre.sd1-12-4")}</Link>{t("info-centre.sd1-12-5")}</p>
                            <p>{t("info-centre.sd1-12-6")}</p>
                            <p>{t("info-centre.sd1-12-7", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-12-8", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-12-9", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-12-10", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-12-11", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.sd1-13")}</h6>
                            <p>{t("info-centre.sd1-13-1")}</p>
                            <p>{t("info-centre.sd1-13-2")}</p>
                            <p>{t("info-centre.sd1-13-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-13-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.sd1-13-5")}</p>

                            <h6>{t("info-centre.sd1-131")}</h6>
                            <p>{t("info-centre.sd1-1311")}</p>
                            <p>{t("info-centre.sd1-1312")}</p>

                            <h6>{t("info-centre.sd1-14")}</h6>
                            <p>{t("info-centre.sd1-14-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.sd1-15")}</h6>
                            <p>{t("info-centre.sd1-15-1")} </p>
                            <p>{t("info-centre.sd1-15-2")} </p>

                            <h6>{t("info-centre.sd1-16")}</h6>
                            <p>{t("info-centre.sd1-16-1")}</p>
                            <p>{t("info-centre.sd1-16-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <ul>
                              <li>{t("info-centre.sd1-16-2-li-1")}</li>
                              <li>{t("info-centre.sd1-16-2-li-2")}</li>
                              <li>{t("info-centre.sd1-16-2-li-3")}</li>
                              <li>{t("info-centre.sd1-16-2-li-4")}</li>
                            </ul>

                            <p>{t("info-centre.sd1-16-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.sd1-17")}</h6>
                            <h6>{t("info-centre.sd1-1711")}</h6>
                            <p>{t("info-centre.sd1-1711-2")}</p>

                            <h6>{t("info-centre.sd1-172")}</h6>
                            <p>{t("info-centre.sd1-172-1")} </p>

                            <h6>{t("info-centre.sd1-18")}</h6>
                            <p>{t("info-centre.sd1-18-1")}</p>

                            <p>{t("info-centre.sd1-18-2")}</p>

                            <p>{t("info-centre.sd1-18-3")}</p>

                            <p>{t("info-centre.sd1-18-4")}</p>

                            <p>{t("info-centre.sd1-18-5")}</p>

                            <h6>{t("info-centre.sd1-181")}</h6>
                            <h6>{t("info-centre.sd1-1811")}</h6>
                            <p>{t("info-centre.sd1-1811-1")}</p>
                            <img
                              src={img[`rnr-sports-table-1${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />
                            <h6>{t("info-centre.sd1-1812")}</h6>
                            <p>{t("info-centre.sd1-1812-1")}</p>
                            <img
                              src={img[`rnr-sports-table-2${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-182")}</h6>
                            <h6>{t("info-centre.sd1-182-1")}</h6>
                            <p>{t("info-centre.sd1-182-2")}</p>
                            <img
                              src={img[`rnr-sports-table-3${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1822")}</h6>
                            <p>{t("info-centre.sd1-1822-1")}</p>
                            <img
                              src={img[`rnr-sports-table-4${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1823")}</h6>
                            <p>{t("info-centre.sd1-1823-1")}</p>
                            <img
                              src={img[`rnr-sports-table-5${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-183")}</h6>
                            <h6>{t("info-centre.sd1-1831")}</h6>
                            <p>{t("info-centre.sd1-1831-1")}</p>
                            <img
                              src={img[`rnr-sports-table-6${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />


                            <h6>{t("info-centre.sd1-1832")}</h6>
                            <p>{t("info-centre.sd1-1832-1")}</p>
                            <img
                              src={img[`rnr-sports-table-7${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />


                            <h6>{t("info-centre.sd1-1833")}</h6>
                            <p>{t("info-centre.sd1-1833-1")}</p>
                            <img
                              src={img[`rnr-sports-table-8${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />


                            <h6>{t("info-centre.sd1-1834")}</h6>
                            <p>{t("info-centre.sd1-1834-1")}</p>
                            <img
                              src={img[`rnr-sports-table-9${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />


                            <h6>{t("info-centre.sd1-184")}</h6>
                            <h6>{t("info-centre.sd1-184-1")}</h6>
                            <p>{t("info-centre.sd1-184-2")}</p>
                            <img
                              src={img[`rnr-sports-table-10${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />


                            <h6>{t("info-centre.sd1-1842")}</h6>
                            <p>{t("info-centre.sd1-1842-1")}</p>
                            <img
                              src={img[`rnr-sports-table-11${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1843")}</h6>
                            <p>{t("info-centre.sd1-1843-1")}</p>
                            <img
                              src={img[`rnr-sports-table-12${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1844")}</h6>
                            <p>{t("info-centre.sd1-1844-1")}</p>
                            <img
                              src={img[`rnr-sports-table-13${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1845")}</h6>
                            <p>{t("info-centre.sd1-1845-1")}</p>
                            <img
                              src={img[`rnr-sports-table-14${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-185")}</h6>
                            <h6>{t("info-centre.sd1-185-1")}</h6>
                            <p>{t("info-centre.sd1-185-2")}</p>
                            <img
                              src={img[`rnr-sports-table-15${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1852")}</h6>
                            <p>{t("info-centre.sd1-1852-1")}</p>
                            <img
                              src={img[`rnr-sports-table-16${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1853")}</h6>
                            <p>{t("info-centre.sd1-1853-1")}</p>
                            <img
                              src={img[`rnr-sports-table-17${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1854")}</h6>
                            <p>{t("info-centre.sd1-1854-1")}</p>
                            <img
                              src={img[`rnr-sports-table-18${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1855")}</h6>
                            <p>{t("info-centre.sd1-1855-1")}</p>
                            <img
                              src={img[`rnr-sports-table-19${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1856")}</h6>
                            <p>{t("info-centre.sd1-1856-1")}</p>
                            <img
                              src={img[`rnr-sports-table-20${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-186")}</h6>
                            <h6>{t("info-centre.sd1-186-1")}</h6>
                            <p>{t("info-centre.sd1-186-2")}</p>
                            <img
                              src={img[`rnr-sports-table-21${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1862")}</h6>
                            <p>{t("info-centre.sd1-1862-1")}</p>
                            <img
                              src={img[`rnr-sports-table-22${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1863")}</h6>
                            <p>{t("info-centre.sd1-1863-1")}</p>
                            <img
                              src={img[`nr-sports-table-23${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1864")}</h6>
                            <p>{t("info-centre.sd1-1864-1")}</p>
                            <img
                              src={img[`rnr-sports-table-24${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1865")}</h6>
                            <p>{t("info-centre.sd1-1865-1")}</p>
                            <img
                              src={img[`rnr-sports-table-25${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1866")}</h6>
                            <p>{t("info-centre.sd1-1866-1")}</p>
                            <img
                              src={img[`rnr-sports-table-26${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1867")}</h6>
                            <p>{t("info-centre.sd1-1867-1")} </p>
                            <img
                              src={img[`rnr-sports-table-27${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />

                            <h6>{t("info-centre.sd1-1867-2")}</h6>
                            <p>{t("info-centre.sd1-1867-3")}</p>

                            <p>{t("info-centre.sd1-1867-4")}</p>
                            <ol className="custom-list-count">
                              <li>{t("info-centre.sd1-1867-4-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <br />
                              <li>{t("info-centre.sd1-1867-4-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <br />
                              <li>{t("info-centre.sd1-1867-4-li-3")}
                                <ol className="custom-list-count custom-list-count-2">
                                  <li>{t("info-centre.sd1-1867-4-li-3-1")} </li>
                                  <li>{t("info-centre.sd1-1867-4-li-3-2")}</li>
                                  <li>{t("info-centre.sd1-1867-4-li-3-3")}</li>
                                  <li>{t("info-centre.sd1-1867-4-li-3-4")}</li>
                                  <li>{t("info-centre.sd1-1867-4-li-3-5")}</li>
                                </ol>
                              </li>
                              <br />
                              <li>{t("info-centre.sd1-1867-4-li-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>
                            <br />
                            <p>{t("info-centre.sd1-1867-5", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.sports-title-2-bti-sports")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <a href="https://bti-io.gitlab.io/betting-rules/en/" target="_blank" rel="noreferrer" className="btn btn-gradient-blue" type="button">{t("info-centre.sports-details-2")}</a>
                          </Accordion.Body>
                        </Accordion.Item> */}
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h6>{t("info-centre.sports-title-3-saba-sports")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <h6>{t("info-centre.sports-details-3")}</h6>
                            <h6>{t("info-centre.sd3-11")}</h6>
                            <p>{t("info-centre.sd3-111")}</p>
                            <p>{t("info-centre.sd3-112")}</p>
                            <p>{t("info-centre.sd3-113")}</p>
                            <p>{t("info-centre.sd3-114")}</p>
                            <p>{t("info-centre.sd3-115")}</p>
                            <p>{t("info-centre.sd3-116")}</p>
                            <p>{t("info-centre.sd3-117")}</p>
                            <ul>
                              <li>{t("info-centre.sd3-117-li-1")}</li>
                              <li>{t("info-centre.sd3-117-li-2")}</li>
                              <li>{t("info-centre.sd3-117-li-3")}</li>
                              <li>{t("info-centre.sd3-117-li-4")}</li>
                              <li>{t("info-centre.sd3-117-li-5")}</li>
                            </ul>
                            <p>{t("info-centre.sd3-118")}</p>
                            <p>{t("info-centre.sd3-119")}</p>
                            <p>{t("info-centre.sd3-1110")}</p>
                            <p>{t("info-centre.sd3-1111")}</p>
                            <p>{t("info-centre.sd3-1112")}</p>
                            <p>{t("info-centre.sd3-1113")}</p>
                            <p>{t("info-centre.sd3-1114")}</p>
                            <p>{t("info-centre.sd3-1115")}</p>
                            <p>{t("info-centre.sd3-1116")}</p>
                            <p>{t("info-centre.sd3-1117")}</p>
                            <p>{t("info-centre.sd3-1118")}</p>
                            <p>{t("info-centre.sd3-1119")}</p>
                            <p>{t("info-centre.sd3-1120")}</p>
                            <p>{t("info-centre.sd3-1121")}</p>
                            <p>{t("info-centre.sd3-1122")}</p>
                            <p>{t("info-centre.sd3-1123")}</p>
                            <p>{t("info-centre.sd3-1124")}</p>
                            <p>{t("info-centre.sd3-1125")}</p>
                            <p>{t("info-centre.sd3-1125-1")}</p>
                            <p>{t("info-centre.sd3-1125-2")}</p>
                            <p>{t("info-centre.sd3-1125-3")}</p>
                            <p>{t("info-centre.sd3-1125-4")}</p>
                            <p>{t("info-centre.sd3-1125-5")}</p>
                            <p>{t("info-centre.sd3-1126")}</p>
                            <p>{t("info-centre.sd3-1127")}</p>

                            <h6>{t("info-centre.sd3-12")}</h6>
                            <p>{t("info-centre.sd3-121")}</p>
                            <p>{t("info-centre.sd3-122")}</p>
                            <p>{t("info-centre.sd3-123")}</p>

                            <h6>{t("info-centre.sd3-13")}</h6>
                            <p>{t("info-centre.sd3-131")}</p>
                            <p>{t("info-centre.sd3-132")}</p>

                            <h6>{t("info-centre.sd3-14")}</h6>
                            <p>{t("info-centre.sd3-141")}</p>
                            <p>{t("info-centre.sd3-142")}</p>

                            <h6>{t("info-centre.sd3-15")}</h6>
                            <p>{t("info-centre.sd3-151")}</p>
                            <p>{t("info-centre.sd3-152")}</p>
                            <p>{t("info-centre.sd3-153")}</p>
                            <p>{t("info-centre.sd3-154")}</p>
                            <p>{t("info-centre.sd3-155")}</p>
                            <p>{t("info-centre.sd3-156")}</p>

                            <h6>{t("info-centre.sd3-16")}</h6>
                            <p>{t("info-centre.sd3-161")}</p>
                            <p>{t("info-centre.sd3-162")}</p>
                            <p>{t("info-centre.sd3-162-1")}</p>
                            <p>{t("info-centre.sd3-162-2")}</p>
                            <p>{t("info-centre.sd3-162-3")}</p>
                            <p>{t("info-centre.sd3-162-4")}</p>
                            <p>{t("info-centre.sd3-163")}</p>

                            <h6>{t("info-centre.sd3-17")}</h6>
                            <p>{t("info-centre.sd3-171")}</p>
                            <ul>
                              <li>{t("info-centre.sd3-171-li-1")}</li>
                              <li>{t("info-centre.sd3-171-li-2")}</li>
                              <li>{t("info-centre.sd3-171-li-3")}</li>
                              <li>{t("info-centre.sd3-171-li-4")}</li>
                              <li>{t("info-centre.sd3-171-li-5")}</li>
                            </ul>
                            <p>{t("info-centre.sd3-172")}</p>
                            <p>{t("info-centre.sd3-173")}</p>
                            <p>{t("info-centre.sd3-174")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header><h6>{t("info-centre.sports-title-4-sbo-sports")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <h6>{t("info-centre.sports-details-4")}</h6>
                            <p>{t("info-centre.sd4-1")}<strong>{t("info-centre.sd4-1-1")}</strong>{t("info-centre.sd4-1-2")}<strong>wap.sbobet.com</strong>{t("info-centre.sd4-1-3")}<strong>wap.sbobet.com or mobile.sbobet.com</strong>{t("info-centre.sd4-1-4")}<strong>{t("info-centre.sd4-1-5")}</strong>{t("info-centre.sd4-1-6")}
                            </p>
                            <p>{t("info-centre.sd4-1-7")}</p>

                            <h6>{t("info-centre.sd4-11-0")}</h6>
                            <h6>{t("info-centre.sd4-11")}</h6>
                            <p>{t("info-centre.sd4-11-1")}</p>
                            <p>{t("info-centre.sd4-11-2")}</p>

                            <h6>{t("info-centre.sd4-12")}</h6>
                            <p>{t("info-centre.sd4-12-1")}</p>

                            <h6>{t("info-centre.sd4-13")}</h6>
                            <p>{t("info-centre.sd4-13-1")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.sd4-13-1-li-1")}</li>
                              <li>{t("info-centre.sd4-13-1-li-2")}</li>
                              <li>{t("info-centre.sd4-13-1-li-3")}</li>
                            </ul>
                            <p>{t("info-centre.sd4-13-2")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.sd4-13-2-li-1")}</li>
                              <li>{t("info-centre.sd4-13-2-li-2")}</li>
                              <li>{t("info-centre.sd4-13-2-li-3")}</li>
                            </ul>

                            <h6>{t("info-centre.sd4-14")}</h6>
                            <p>{t("info-centre.sd4-14-1")}</p>
                            <p>{t("info-centre.sd4-14-2")}</p>
                            <p>{t("info-centre.sd4-14-3")}</p>

                            <h6>{t("info-centre.sd4-15")}</h6>
                            <p>{t("info-centre.sd4-15-1")}</p>

                            <h6>{t("info-centre.sd4-16")}</h6>
                            <p>{t("info-centre.sd4-16-1")}</p>

                            <h6>{t("info-centre.sd4-17")}</h6>
                            <p>{t("info-centre.sd4-17-1")}</p>

                            <h6>{t("info-centre.sd4-18")}</h6>
                            <p>{t("info-centre.sd4-18-1")}</p>

                            <h6>{t("info-centre.sd4-19")}</h6>
                            <p>{t("info-centre.sd4-19-1")}</p>
                            <p>{t("info-centre.sd4-19-2")}</p>
                            <p>{t("info-centre.sd4-19-3")}</p>

                            <h6>{t("info-centre.sd4-110")}</h6>
                            <p>{t("info-centre.sd4-110-1")}</p>

                            <h6>{t("info-centre.sd4-111")}</h6>
                            <p>{t("info-centre.sd4-111-1")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.sd4-111-1-li-1")}</li>
                              <li>{t("info-centre.sd4-111-1-li-2")}</li>
                              <li>{t("info-centre.sd4-111-1-li-3")}</li>
                            </ul>
                            <p>{t("info-centre.sd4-111-2")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.sd4-111-2-li-1")}<br />{t("info-centre.sd4-111-2-li-1-1")}</li>
                              <br />
                              <li>{t("info-centre.sd4-111-2-li-2")}</li>
                              <ol className="li-roman">
                                <li>{t("info-centre.sd4-111-2-li-2-1")}</li>
                              </ol>
                              <br />
                              <li>{t("info-centre.sd4-111-2-li-3")}<br />{t("info-centre.sd4-111-2-li-3-1")}</li>
                              <br />
                              <li>{t("info-centre.sd4-111-2-li-4")}<br />{t("info-centre.sd4-111-2-li-4-1")}</li>
                              <br />
                              <li>{t("info-centre.sd4-111-2-li-5")}<br />{t("info-centre.sd4-111-2-li-5-1")}</li>
                              <ol className="li-roman">
                                <li>{t("info-centre.sd4-111-2-li-5-2")}</li>
                                <li>{t("info-centre.sd4-111-2-li-5-3")}</li>
                              </ol>
                              <br />
                              <li>{t("info-centre.sd4-111-2-li-6")}<br />{t("info-centre.sd4-111-2-li-6-1")}</li>
                              <ol className="li-roman">
                                <li>{t("info-centre.sd4-111-2-li-6-2")}</li>
                                <li>{t("info-centre.sd4-111-2-li-6-3")}</li>
                              </ol>
                            </ul>
                            <p>{t("info-centre.sd4-111-3")}</p>
                            <p>{t("info-centre.sd4-111-4")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.sd4-111-4-li-1")}</li>
                              <li>{t("info-centre.sd4-111-4-li-2")}</li>
                              <li>{t("info-centre.sd4-111-4-li-3")}</li>
                            </ul>
                            <p>{t("info-centre.sd4-111-5")}</p>

                            <h6>{t("info-centre.sd4-122")}</h6>
                            <p>{t("info-centre.sd4-122-1")}</p>

                            <h6>{t("info-centre.sd4-113")}</h6>
                            <p>{t("info-centre.sd4-113-1")}</p>
                            <p>{t("info-centre.sd4-113-2")}</p>
                            <p>{t("info-centre.sd4-113-3")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'rule' && this.state.activeKey === 1 ? (
                    //live casino
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.info-live-casino")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <a href="https://info.gpiops.com/share/rules-live-casino-en-my.shtml" target="_blank" rel="noreferrer" className="btn btn-gradient-blue" type="button">{t("info-centre.info-lc-details")}</a>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'rule' && this.state.activeKey === 2 ? (
                    //games
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> <h6>{t("info-centre.info-games")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <a href="https://info.gpiops.com/share/rules-games-en.shtml" target="_blank" rel="noreferrer" className="btn btn-gradient-blue" type="button">{t("info-centre.info-games-details")}</a>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'rule' && this.state.activeKey === 3 ? (
                    //lottery
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.info-lottery")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <a href="https://info.gpiops.com/share/rules-lottery-en.shtml" target="_blank" rel="noreferrer" className="btn btn-gradient-blue" type="button">{t("info-centre.info-lottery-details")}</a>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : this.state.activeTab === 'rule' && this.state.activeKey === 4 ? (
                    //poker
                    <Fade duration={1000}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6>{t("info-centre.poker-title-1-texas")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <h6>{t("info-centre.poker-details-1")}</h6>
                            <p>{t("info-centre.pker1-1")}</p>
                            <h6>{t("info-centre.pker1-2")}</h6>
                            <p>{t("info-centre.pker1-2-1")}</p>
                            <h6>{t("info-centre.pker1-3")}</h6>
                            <p>{t("info-centre.pker1-3-1")}</p>
                            <h6>{t("info-centre.pker1-4")}</h6>
                            <p>{t("info-centre.pker1-4-1")}</p>
                            <ul>
                              <li>{t("info-centre.pker1-4-1-li-1")}</li>
                              <li>{t("info-centre.pker1-4-1-li-2")}</li>
                              <li>{t("info-centre.pker1-4-1-li-3")}</li>
                              <li>{t("info-centre.pker1-4-1-li-4")}</li>
                            </ul>

                            <h6>{t("info-centre.pker1-5")}</h6>
                            <p>{t("info-centre.pker1-5-1")}</p>
                            <h6>{t("info-centre.pker1-6")}</h6>
                            <p>{t("info-centre.pker1-6-1")}</p>
                            <h6>{t("info-centre.pker1-7")}</h6>
                            <p>{t("info-centre.pker1-7-1")}</p>
                            <h6>{t("info-centre.pker1-8")}</h6>
                            <p>{t("info-centre.pker1-8-1")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6>{t("info-centre.poker-title-2-omaha-hi")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.poker-details-2")}</p>
                            <h6>{t("info-centre.pker2-1")}</h6>
                            <ul>
                              <li>{t("info-centre.pker2-1-li-1")}</li>
                              <li>{t("info-centre.pker2-1-li-2")}</li>
                            </ul>
                            <h6>{t("info-centre.pker2-2")}</h6>
                            <p>{t("info-centre.pker2-2-1")}</p>
                            <h6>{t("info-centre.pker2-3")}</h6>
                            <h6>{t("info-centre.pker2-3-1")}</h6>
                            <p>{t("info-centre.pker2-3-2")}</p>
                            <h6>{t("info-centre.pker2-4")}</h6>
                            <p>{t("info-centre.pker2-4-1")}</p>
                            <h6>{t("info-centre.pker2-5")}</h6>
                            <h6>{t("info-centre.pker2-5-1")}</h6>
                            <p>{t("info-centre.pker2-5-2")}</p>
                            <h6>{t("info-centre.pker2-6")}</h6>
                            <p>{t("info-centre.pker2-6-1")}</p>
                            <h6>{t("info-centre.pker2-7")}</h6>
                            <h6>{t("info-centre.pker2-7-1")}</h6>
                            <p>{t("info-centre.pker2-7-2")}</p>
                            <h6>{t("info-centre.pker2-8")}</h6>
                            <p>{t("info-centre.pker2-8-1")}</p>
                            <h6>{t("info-centre.pker2-9")}</h6>
                            <h6>{t("info-centre.pker2-9-1")}</h6>
                            <p>{t("info-centre.pker2-9-2")}</p>
                            <h6>{t("info-centre.pker2-10")}</h6>
                            <p>{t("info-centre.pker2-10-1")}</p>
                            <h6>{t("info-centre.pker2-11")}</h6>
                            <h6>{t("info-centre.pker2-11-1")}</h6>
                            <p>{t("info-centre.pker2-11-2")}</p>
                            <ol>
                              <li>{t("info-centre.pker2-11-2-li-1")}</li>
                              <li>{t("info-centre.pker2-11-2-li-2")}</li>
                              <li>{t("info-centre.pker2-11-2-li-3")}</li>
                              <li>{t("info-centre.pker2-11-2-li-4")}</li>
                              <li>{t("info-centre.pker2-11-2-li-5")}</li>
                              <li>{t("info-centre.pker2-11-2-li-6")}</li>
                              <li>{t("info-centre.pker2-11-2-li-7")}</li>
                            </ol>
                            <h6>{t("info-centre.pker2-12")}</h6>
                            <p>{t("info-centre.pker2-12-1")}</p>
                            <ol>
                              <li>{t("info-centre.pker2-12-1-li-1")}</li>
                              <li>{t("info-centre.pker2-12-1-li-2")}</li>
                              <li>{t("info-centre.pker2-12-1-li-3")}</li>
                            </ol>
                            <p>{t("info-centre.pker2-12-2")}</p>
                            <ol>
                              <li>{t("info-centre.pker2-12-2-li-1")}</li>
                              <li>{t("info-centre.pker2-12-2-li-2")}</li>
                              <li>{t("info-centre.pker2-12-2-li-3")}</li>
                              <li>{t("info-centre.pker2-12-2-li-4")}</li>
                              <li>{t("info-centre.pker2-12-2-li-5")}</li>
                              <li>{t("info-centre.pker2-12-2-li-6")}</li>
                            </ol>
                            <h6>{t("info-centre.pker2-13")}</h6>
                            <p>{t("info-centre.pker2-13-1")}</p>
                            <p>{t("info-centre.pker2-13-2")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h6>{t("info-centre.poker-title-3-sit-and-go")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>
                              <strong>{t("info-centre.poker-details-3")}</strong>{t("info-centre.pker3-1")}
                            </p>
                            <p>
                              <strong>{t("info-centre.pker3-2")}</strong>{t("info-centre.pker3-2-1")}
                            </p>
                            <p>
                              <strong>{t("info-centre.pker3-3")}</strong>{t("info-centre.pker3-3-1")}
                            </p>
                            <p>
                              <strong>{t("info-centre.pker3-4")}</strong>{t("info-centre.pker3-4-1")}
                            </p>
                            <p>{t("info-centre.pker3-5")}</p>
                            <p>
                              <strong>{t("info-centre.pker3-6")}</strong>{t("info-centre.pker3-6-1")}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header> <h6>{t("info-centre.poker-title-4-tournament")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.poker-details-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <strong>{t("info-centre.pker4-1")}</strong>
                            <ol>
                              <li>{t("info-centre.pker4-1-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-1-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-1-li-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-1-li-4")}</li>
                              <li>{t("info-centre.pker4-1-li-5")}</li>
                              <li>{t("info-centre.pker4-1-li-6")}</li>
                              <li>{t("info-centre.pker4-1-li-7")}</li>
                              <li>{t("info-centre.pker4-1-li-8")}</li>
                              <li>{t("info-centre.pker4-1-li-9")}</li>
                              <li>{t("info-centre.pker4-1-li-10", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <strong>{t("info-centre.pker4-2")}</strong>
                            <ol>
                              <li>{t("info-centre.pker4-2-li-1")}</li>
                              <li>{t("info-centre.pker4-2-li-2")}</li>
                              <li>{t("info-centre.pker4-2-li-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-2-li-4")}</li>
                              <li>{t("info-centre.pker4-2-li-5")}</li>
                            </ol>

                            <strong>{t("info-centre.pker4-3")}</strong>
                            <ol>
                              <li>{t("info-centre.pker4-3-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <ul>
                              <li>{t("info-centre.pker4-3-li-2")}</li>
                              <li>{t("info-centre.pker4-3-li-2-1")}</li>
                              <li>{t("info-centre.pker4-3-li-2-2")}</li>
                            </ul>

                            <strong>{t("info-centre.pker4-4")}</strong>
                            <ol>
                              <li>{t("info-centre.pker4-4-li-1")}</li>
                            </ol>

                            <ul>
                              <li>{t("info-centre.pker4-4-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-4-li-2-1")}</li>
                              <li>{t("info-centre.pker4-4-li-2-2")}</li>
                            </ul>

                            <strong>{t("info-centre.pker4-5")}</strong>

                            <ol>
                              <li>{t("info-centre.pker4-5-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-5-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <ul>
                              <li>{t("info-centre.pker4-5-li-2-1")}</li>
                              <li>{t("info-centre.pker4-5-li-2-2")}</li>
                              <li>{t("info-centre.pker4-5-li-2-3")}</li>
                              <li>{t("info-centre.pker4-5-li-2-4")}</li>
                              <li>{t("info-centre.pker4-5-li-2-5")} </li>
                              <li>{t("info-centre.pker4-5-li-2-6", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker4-5-li-2-7")}</li>
                              <li>{t("info-centre.pker4-5-li-2-8")}</li>
                              <li>{t("info-centre.pker4-5-li-2-9")}</li>
                            </ul>

                            <strong>{t("info-centre.pker4-6")}</strong>
                            <ol>
                              <li>{t("info-centre.pker4-6-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                            </ol>

                            <strong>{t("info-centre.pker4-7")}</strong>
                            <p>{t("info-centre.pker4-7-1")}</p>

                            <p>
                              <strong>{t("info-centre.pker4-8")}</strong> {t("info-centre.pker4-8-1")}
                            </p>

                            <p>
                              <strong>{t("info-centre.pker4-9")}</strong>{t("info-centre.pker4-9-1")}
                            </p>

                            <p>
                              <strong>{t("info-centre.pker4-10")}</strong>{t("info-centre.pker4-10-1")}
                            </p>

                            <ol>
                              <li>{t("info-centre.pker4-11-li-1")}</li>
                              <ul>
                                <li>{t("info-centre.pker4-11-li-1-1")}</li>
                              </ul>
                              <li>{t("info-centre.pker4-11-li-2")}</li>
                              <li>{t("info-centre.pker4-11-li-3")}</li>
                              <li>{t("info-centre.pker4-11-li-4")}</li>
                              <li>{t("info-centre.pker4-11-li-5")}</li>
                            </ol>

                            <strong>{t("info-centre.pker4-12", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</strong>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header><h6>{t("info-centre.poker-title-5-rake-structure")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.poker-details-5")}</p>
                            <img
                              src={img[`rake-structure${imageLanguage}.webp`]}
                              alt="faq products table"
                              className="info-table-en"
                            />
                            <p>{t("info-centre.pker5-1")}</p>
                            <p>{t("info-centre.pker5-2")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header><h6>{t("info-centre.poker-title-6-end-user-agreement")}</h6></Accordion.Header>
                          <Accordion.Body>
                            <p>{t("info-centre.poker-details-6")}</p>
                            <p>{t("info-centre.pker6-1")}<a href={`mailto: ${process.env.REACT_APP_MERCHANT_INFO_EMAIL}`}>{process.env.REACT_APP_MERCHANT_INFO_EMAIL}</a></p>
                            <p>{t("info-centre.pker6-1-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.pker6-1-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.pker6-1-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.pker6-2")}</h6>
                            <h6>{t("info-centre.pker6-2-1")}</h6>
                            <p>{t("info-centre.pker6-2-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-3")}</h6>
                            <p>{t("info-centre.pker6-3-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-4")}</h6>
                            <p>{t("info-centre.pker6-4-1")}</p>
                            <h6>{t("info-centre.pker6-5")}</h6>
                            <p>{t("info-centre.pker6-5-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-6")}</h6>
                            <p>{t("info-centre.pker6-6-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-7")}</h6>
                            <p>{t("info-centre.pker6-7-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.pker6-7-2")}</p>
                            <p>{t("info-centre.pker6-7-3")}</p>
                            <p>{t("info-centre.pker6-7-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <h6>{t("info-centre.pker6-8")}</h6>
                            <h6>{t("info-centre.pker6-8-1")}</h6>
                            <p>{t("info-centre.pker6-8-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-9")}</h6>
                            <p>{t("info-centre.pker6-9-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-10")}</h6>
                            <p>{t("info-centre.pker6-10-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-11")}</h6>
                            <p>{t("info-centre.pker6-11-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.pker6-11-2")}</p>
                            <p>{t("info-centre.pker6-11-3")}</p>
                            <p>{t("info-centre.pker6-11-4")}</p>
                            <p>{t("info-centre.pker6-11-5", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-12")}</h6>
                            <p>{t("info-centre.pker6-12-1")}</p>
                            <h6>{t("info-centre.pker6-13")}</h6>
                            <p>{t("info-centre.pker6-13-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-14")}</h6>
                            <p>{t("info-centre.pker6-14-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-15")}</h6>
                            <p>{t("info-centre.pker6-15-1")}</p>
                            <h6>{t("info-centre.pker6-16")}</h6>
                            <p>{t("info-centre.pker6-16-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-16-2-1")}</h6>
                            <p>{t("info-centre.pker6-16-2-2")}<a href={`mailto: ${process.env.REACT_APP_MERCHANT_INFO_EMAIL}`}>{process.env.REACT_APP_MERCHANT_INFO_EMAIL}</a></p>
                            <h6>{t("info-centre.pker6-17")}</h6>
                            <p>{t("info-centre.pker6-17-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <p>{t("info-centre.pker6-17-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>

                            <p>{t("info-centre.pker6-17-3")}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.pker6-17-3-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-17-3-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-17-3-li-3")}</li>
                              <li>{t("info-centre.pker6-17-3-li-4", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-17-3-li-5")}</li>
                            </ul>
                            <h6>{t("info-centre.pker6-17-4")}</h6>
                            <p>{t("info-centre.pker6-17-4-1")}</p>

                            <h6>{t("info-centre.pker6-18")}</h6>
                            <p>{t("info-centre.pker6-18-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-19")}</h6>
                            <p>{t("info-centre.pker6-19-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-20")}</h6>
                            <p>{t("info-centre.pker6-20-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-21")}</h6>
                            <p>{t("info-centre.pker6-21-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-22", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6>
                            <p>{t("info-centre.pker6-22-1")}</p>
                            <h6>{t("info-centre.pker6-23", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6>
                            <p>{t("info-centre.pker6-23-1")}</p>
                            <h6>{t("info-centre.pker6-24")}</h6>
                            <p>{t("info-centre.pker6-24-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-25")}</h6>
                            <p>{t("info-centre.pker6-25-1")}</p>
                            <h6>{t("info-centre.pker6-26")}</h6>
                            <p>{t("info-centre.pker6-26-1")}</p>
                            <h6>{t("info-centre.pker6-27")}</h6>
                            <p>{t("info-centre.pker6-27-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-28")}</h6>
                            <p>{t("info-centre.pker6-28-1")}</p>
                            <h6>{t("info-centre.pker6-29")}</h6>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.pker6-29-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-29-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-29-li-3")}</li>
                              <li>{t("info-centre.pker6-29-li-4")}</li>
                              <li>{t("info-centre.pker6-29-li-5")}</li>
                            </ul>
                            <h6>{t("info-centre.pker6-30")}</h6>
                            <p>{t("info-centre.pker6-30-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.pker6-30-li-1")}</li>
                              <li>{t("info-centre.pker6-30-li-2")}</li>
                              <li>{t("info-centre.pker6-30-li-3")}</li>
                              <li>{t("info-centre.pker6-30-li-4")}</li>
                              <li>{t("info-centre.pker6-30-li-5")}</li>
                              <li>{t("info-centre.pker6-30-li-6")}</li>
                            </ul>
                            <p>{t("info-centre.pker6-30-2")}</p>
                            <p>{t("info-centre.pker6-30-3")}</p>
                            <p>{t("info-centre.pker6-30-4")}</p>

                            <h6>{t("info-centre.pker6-31")}</h6>
                            <p>{t("info-centre.pker6-31-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
                            <h6>{t("info-centre.pker6-32")}</h6>
                            <ul className="li-alphabet">
                              <li>{t("info-centre.pker6-32-li-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-32-li-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-32-li-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                              <li>{t("info-centre.pker6-32-li-4")}</li>
                            </ul>
                            <h6>{t("info-centre.pker6-33")}</h6>
                            <p>{t("info-centre.pker6-33-1")}<a href={`mailto: ${process.env.REACT_APP_MERCHANT_INFO_EMAIL}`}>{process.env.REACT_APP_MERCHANT_INFO_EMAIL}</a></p>
                            <p>{t("info-centre.pker6-34")}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Fade>
                  ) : (
                    <></>
                  )
                }
              </TabPane>
              <TabPane eventKey="tnc">
                <Fade triggerOnce duration={1000}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-1-definitions")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>1.1</label>
                          <li>{t("info-centre.tnc1-1")}</li>
                          <label>1.2</label>
                          <li>{t("info-centre.tnc1-2")}</li>
                          <label>1.3</label>
                          <li>{t("info-centre.tnc1-3")}</li>
                          <label>1.4</label>
                          <li>{t("info-centre.tnc1-4")}</li>
                          <label>1.5</label>
                          <li>{t("info-centre.tnc1-5")}</li>
                          <label>1.6</label>
                          <li>{t("info-centre.tnc1-6")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> <h6>{t("info-centre.tnc-title-2")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>2.1</label>
                          <li>{t("info-centre.tnc-details-2")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc2-1-li-1")}</li>
                            <li>{t("info-centre.tnc2-1-li-2")}</li>
                            <li>{t("info-centre.tnc2-1-li-3")}</li>
                          </ul>
                          <label>2.2</label>
                          <li>{t("info-centre.tnc2-2")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc2-2-li-1")}</li>
                            <li>{t("info-centre.tnc2-2-li-2")}</li>
                          </ul>
                          <label>2.3</label>
                          <li>{t("info-centre.tnc2-3")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc2-3-li-1")}</li>
                            <li>{t("info-centre.tnc2-3-li-2")}</li>
                            <li>{t("info-centre.tnc2-3-li-3")}</li>
                          </ul>
                          <label>2.4</label>
                          <li>{t("info-centre.tnc2-4")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-3")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>3.1</label>
                          <li>{t("info-centre.tnc-details-3")}</li>
                          <label>3.2</label>
                          <li>{t("info-centre.tnc3-1")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-4")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>4.1</label>
                          <li>{t("info-centre.tnc-details-4")}</li>
                          <label>4.2</label>
                          <li>{t("info-centre.tnc4-1")}</li>
                          <label>4.3</label>
                          <li>{t("info-centre.tnc4-2")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-5")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>5.1</label>
                          <li>{t("info-centre.tnc-details-5")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc5-li-1")}</li>
                            <li>{t("info-centre.tnc5-li-2")}</li>
                            <li>{t("info-centre.tnc5-li-3")}</li>
                            <li>{t("info-centre.tnc5-li-4")}</li>
                            <li>{t("info-centre.tnc5-li-5")}</li>
                            <li>{t("info-centre.tnc5-li-6")}</li>
                            <li>{t("info-centre.tnc5-li-7")}</li>
                            <li>{t("info-centre.tnc5-li-8")}</li>
                            <li>{t("info-centre.tnc5-li-9")}</li>
                            <li>{t("info-centre.tnc5-li-10")}</li>
                            <li>{t("info-centre.tnc5-li-11")}</li>
                            <li>{t("info-centre.tnc5-li-12")}</li>
                            <li>{t("info-centre.tnc5-li-13")}</li>
                            <li>{t("info-centre.tnc5-li-14")}</li>
                            <li>{t("info-centre.tnc5-li-15")}</li>
                            <li>{t("info-centre.tnc5-li-16")}</li>
                          </ul>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-6")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>6.1</label>
                          <li>{t("info-centre.tnc-details-6")}</li>
                          <label>6.2</label>
                          <li>{t("info-centre.tnc6-2")}</li>
                          <label>6.3</label>
                          <li>{t("info-centre.tnc6-3")}</li>
                          <label>6.4</label>
                          <li>{t("info-centre.tnc6-4")}</li>
                          <label>6.5</label>
                          <li>{t("info-centre.tnc6-5")}</li>
                          <label>6.6</label>
                          <li>{t("info-centre.tnc6-6")}</li>
                          <label>6.7</label>
                          <li>{t("info-centre.tnc6-7")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-7")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>7.1</label>
                          <li>{t("info-centre.tnc-details-7")}</li>
                          <label>7.2</label>
                          <li>{t("info-centre.tnc7-2")}</li>
                          <label>7.3</label>
                          <li>{t("info-centre.tnc7-3")}</li>
                          <label>7.4</label>
                          <li>{t("info-centre.tnc7-4")}</li>
                          <label>7.5</label>
                          <li>{t("info-centre.tnc7-5")}</li>
                          <label>7.6</label>
                          <li>{t("info-centre.tnc7-6")}</li>
                          <label>7.7</label>
                          <li>{t("info-centre.tnc7-7")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc7-7-li-1")}</li>
                            <li>{t("info-centre.tnc7-7-li-2")}</li>
                            <li>{t("info-centre.tnc7-7-li-3")}</li>
                          </ul>
                          <label>7.8</label>
                          <li>{t("info-centre.tnc7-8")}</li>
                          <label>7.9</label>
                          <li>{t("info-centre.tnc7-9")}</li>
                          <label>7.10</label>
                          <li>{t("info-centre.tnc7-10")}</li>
                          <label>7.11</label>
                          <li>{t("info-centre.tnc7-11")}</li>
                          <label>7.12</label>
                          <li>{t("info-centre.tnc7-12", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                          <label>7.13</label>
                          <li>{t("info-centre.tnc7-13")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-8")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>8.1</label>
                          <li>{t("info-centre.tnc-details-8")}</li>
                          <label>8.2</label>
                          <li>{t("info-centre.tnc8-2")}</li>
                          <label>8.3</label>
                          <li>{t("info-centre.tnc8-3")}</li>
                          <label>8.4</label>
                          <li>{t("info-centre.tnc8-4")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc8-4-li-1")}</li>
                            <li>{t("info-centre.tnc8-4-li-2")}</li>
                            <li>{t("info-centre.tnc8-4-li-3")}</li>
                            <li>{t("info-centre.tnc8-4-li-4")}</li>
                            <li>{t("info-centre.tnc8-4-li-5")}</li>
                            <li>{t("info-centre.tnc8-4-li-6")}</li>
                          </ul>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-9")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>9.1</label>
                          <li>{t("info-centre.tnc-details-9")}</li>
                          <label>9.2</label>
                          <li>{t("info-centre.tnc9-2")}</li>
                          <label>9.3</label>
                          <li>{t("info-centre.tnc9-3")}</li>
                          <label>9.4</label>
                          <li>{t("info-centre.tnc9-4")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-10")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>10.1</label>
                          <li>{t("info-centre.tnc-details-10")}</li>
                          <label>10.2</label>
                          <li>{t("info-centre.tnc10-2")}</li>
                          <label>10.3</label>
                          <li>{t("info-centre.tnc10-3")}</li>
                          <label>10.4</label>
                          <li>{t("info-centre.tnc10-4")}</li>
                          <label>10.5</label>
                          <li>{t("info-centre.tnc10-5")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                      <Accordion.Header> <h6>{t("info-centre.tnc-title-11")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>11.1</label>
                          <li>{t("info-centre.tnc-details-11", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                          <label>11.2</label>
                          <li>{t("info-centre.tnc11-2")}</li>
                          <label>11.3</label>
                          <li>{t("info-centre.tnc11-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                          <label>11.4</label>
                          <li>{t("info-centre.tnc11-4")}</li>
                          <label>11.5</label>
                          <li>{t("info-centre.tnc11-5")}</li>
                          <label>11.6</label>
                          <li>{t("info-centre.tnc11-6", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                          <label>11.7</label>
                          <li>{t("info-centre.tnc11-7")}</li>
                          <label>11.8</label>
                          <li>{t("info-centre.tnc11-8")}</li>
                          <label>11.9</label>
                          <li>{t("info-centre.tnc11-9")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-12")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>12.1</label>
                          <li>{t("info-centre.tnc-details-12")}</li>
                          <label>12.2</label>
                          <li>{t("info-centre.tnc12-2")}</li>
                          <label>12.3</label>
                          <li>{t("info-centre.tnc12-3")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc12-3-li-1")}</li>
                            <li>{t("info-centre.tnc12-3-li-2")}</li>
                            <li>{t("info-centre.tnc12-3-li-3")}</li>
                            <li>{t("info-centre.tnc12-3-li-4")}</li>
                          </ul>
                          <label>12.4</label>
                          <li>{t("info-centre.tnc12-4")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc12-4-li-1")}</li>
                            <li>{t("info-centre.tnc12-4-li-2")}</li>
                            <li>{t("info-centre.tnc12-4-li-3")}</li>
                            <li>{t("info-centre.tnc12-4-li-4")}</li>
                          </ul>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-13")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>13.1</label>
                          <li>{t("info-centre.tnc-details-13")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-14")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>14.1</label>
                          <li>{t("info-centre.tnc-details-14")}</li>
                          <label>14.2</label>
                          <li>{t("info-centre.tnc14-2")}</li>
                          <label>14.3</label>
                          <li>{t("info-centre.tnc14-3")}</li>
                          <label>14.4</label>
                          <li>{t("info-centre.tnc14-4")}</li>
                          <label>14.5</label>
                          <li>{t("info-centre.tnc14-5")}</li>
                          <label>14.6</label>
                          <li>{t("info-centre.tnc14-6")}</li>
                          <label>14.7</label>
                          <li>{t("info-centre.tnc14-7")}</li>
                          <label>14.8</label>
                          <li>{t("info-centre.tnc14-8")}</li>
                          <label>14.9</label>
                          <li>{t("info-centre.tnc14-9")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-15")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>15.1</label>
                          <li>{t("info-centre.tnc-details-15")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc15-li-1")}</li>
                            <li>{t("info-centre.tnc15-li-2")}</li>
                            <li>{t("info-centre.tnc15-li-3")}</li>
                            <li>{t("info-centre.tnc15-li-4")}</li>
                            <li>{t("info-centre.tnc15-li-5")}</li>
                            <li>{t("info-centre.tnc15-li-6")}</li>
                            <li>{t("info-centre.tnc15-li-7")}</li>
                            <li>{t("info-centre.tnc15-li-8")}</li>
                            <li>{t("info-centre.tnc15-li-9")}</li>
                            <li>{t("info-centre.tnc15-li-10")}</li>
                            <li>{t("info-centre.tnc15-li-11")}</li>
                            <li>{t("info-centre.tnc15-li-12")}</li>
                          </ul>
                          <label>15.2</label>
                          <li>{t("info-centre.tnc15-2")}</li>
                          <label>15.3</label>
                          <li>{t("info-centre.tnc15-3")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-16")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>16.1</label>
                          <li>{t("info-centre.tnc-details-16")}</li>
                          <label>16.2</label>
                          <li>{t("info-centre.tnc16-2")}</li>
                          <label>16.3</label>
                          <li>{t("info-centre.tnc16-3")}</li>
                          <label>16.4</label>
                          <li>{t("info-centre.tnc16-4")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-17")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>17.1</label>
                          <li>{t("info-centre.tnc-details-17")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="17">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-18")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>18.1</label>
                          <li>{t("info-centre.tnc-details-18")}</li>
                          <label>18.2</label>
                          <li>{t("info-centre.tnc18-2")}</li>
                          <ul className="li-roman">
                            <li>{t("info-centre.tnc18-2-li-1")}</li>
                            <li>{t("info-centre.tnc18-2-li-2")}</li>
                            <li>{t("info-centre.tnc18-2-li-3")}</li>
                          </ul>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="18">
                      <Accordion.Header><h6>{t("info-centre.tnc-title-19")}</h6></Accordion.Header>
                      <Accordion.Body>
                        <ol className="tnc-custom-list">
                          <label>19.1</label>
                          <li>{t("info-centre.tnc-details-19")}</li>
                          <label>19.2</label>
                          <li>{t("info-centre.tnc19-2")}</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Fade>
              </TabPane>
            </TabContent>
          </TabContainer>
        </section>
      </Auxiliary >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(withNamespaces("translation")(MobileInfoCentre));