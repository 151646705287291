import React, { Component } from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

//Miscellaneous
import moment from "moment";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getCountry } from "../../store/ducks/home/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { showBanner } from "../../store/ducks/banners/actions";
import Accordion from 'react-bootstrap/Accordion'

//Icons
import { Icon } from "@iconify/react";

let countries = [];
let langArr = [];
class PageFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            openModal: false,
            currentCountryImage: "MY",
            currentLanguageCode: "EN",
            reloadPage: false,

            currentEvent: "",
        };
    }

    componentDidMount() {
        // const { t } = this.props;
        this.props.getCountry();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;

        if (nextProps.country_data !== undefined) {
            if (nextProps.country_data !== this.props.country_data) {
                let countryData = nextProps.country_data.country;
                let languageData = nextProps.country_data.language;
                // here

                //Clear the country array
                countries = [];
                langArr = [];

                Object.keys(countryData).map((item, i) => {
                    import(`../../assets/images/flags/${countryData[item].countryShortCode}.webp`).then((image) => {
                        if (this.props.currentUser.country_code === countryData[item].countryShortCode) {
                            this.setState({
                                currentCountryImage: image.default,
                                currentCountryShortCode: countryData[item].countryShortCode,
                                countryLoading: false,
                                currentLanguageCode: countryData[item].defaultLanguageCode.toUpperCase(),
                            });
                        }

                        if (localStorage.getItem("accessToken")) {
                            // if login & same country
                            if (this.props.currentUser.country_id === countryData[item].countryId) {
                                countries.push({
                                    image: image.default,
                                    countryId: countryData[item].countryId,
                                    countryName: countryData[item].countryName,
                                    countryCode: countryData[item].countryShortCode,
                                });
                            }
                        }
                        else {
                            countries.push({
                                image: image.default,
                                countryId: countryData[item].countryId,
                                countryName: countryData[item].countryName,
                                countryCode: countryData[item].countryShortCode,
                            });
                        }

                        this.setState({
                            loading: true,
                        });
                    });

                    return countries;
                });

                Object.keys(languageData).map((item, i) => {
                    return langArr.push({
                        languageId: languageData[item].languageId,
                        countryCode: languageData[item].countryShortCode,
                        code: languageData[item].languageCode,
                        desc: languageData[item].languageName,
                        displayLanguageName: languageData[item].displayLanguageName,
                        timezone: languageData[item].timezone,
                    });
                });
            }
        }

        if (nextProps.show_banner_data !== this.props.show_banner_data) {
            if (nextProps.show_banner_data.data.responseCode === 200) {
                if (this.state.reloadPage) {
                    window.location.reload();
                }
            } else {
                return false;
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    changeCountryHandler = (event, countryCode, countryId, languageId, languageCode, timezone) => {
        event.preventDefault();

        if (this.props.currentUser.length === 0) {
            const data = {};
            data.country_code = countryCode;
            data.country_id = countryId;
            data.language_code = languageCode;
            data.language_id = languageId;
            data.time_zone = timezone;
            data.member_group_id = this.props.currentUser.member_group_id;
            // here
            this.props.getUserData(data);
        } else {
            const data = this.props.currentUser;
            data.country_code = countryCode;
            data.country_id = countryId;
            data.language_code = languageCode;
            data.language_id = languageId;
            data.time_zone = timezone;
            data.member_group_id = this.props.currentUser.member_group_id;
            // here is that the goup id problem
            this.props.getUserData(data);
        }
        let la = languageCode.replace("-", "_").toString().toLowerCase();
        localStorage.setItem("lang", la || "en");
        this.props.i18n.changeLanguage(la);

        import(`../../assets/images/flags/${countryCode}.webp`).then((image) => {
            this.setState({
                currentCountryImage: image.default,
                currentCountryShortCode: countryCode,
                currentLanguageCode: languageCode.toUpperCase(),
                timezone: timezone,
                openModal: false,
            });
        });

        const showBannerData = {};
        showBannerData.country_id = countryId;

        this.props.showBanner(showBannerData);

        localStorage.setItem("changeCountry", true);
        localStorage.removeItem('promosData');
        localStorage.removeItem('vipDetails');

        this.setState({
            reloadPage: true
        });
    };

    render() {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/));
        const flags = this.imageImport(require.context("../../assets/images/flags", false, /\.(png|jpe?g|svg|webp)$/));
        const christmas = this.imageImport(require.context("../../assets/images/home/christmas", false, /\.(png|jpe?g|svg|webp)$/));

        return (
            <Auxiliary>
                <footer className="position-relative">
                    {
                        this.state.currentEvent === "christmas" ? (
                            <img className="event-bottom-deco" src={christmas["bottom-bg-christmas.webp"]} alt="bottom-deco" />
                        ) : (
                            <></>
                        )
                    }
                    <div className="x-container-no-m">
                        <div className="footer-one">
                            <span>
                                <p className="mb-3">{t("footer.payment-method")}</p>
                                <img src={img["fpx-logo.svg"]} alt="fpx-logo" />
                                <img src={img["tether.svg"]} alt="tether-logo" />
                                {/* <img src={img['eeziepay.svg']} alt="eezie-logo" /> */}
                            </span>
                            <NavLink to={{ pathname: "/certificate" }}>
                                <span className="p-0">
                                    <p className="mb-3">{t("footer.licenses")}</p>
                                    <span>
                                        <img src={img["fcl.svg"]} alt="fcl-logo" />
                                        <img src={img["pagcor.svg"]} alt="pagcor-logo" />
                                        <img src={img["ifm-logo.svg"]} alt="ifm-logo" />
                                    </span>
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <hr />
                    <div className="x-container-no-m">
                        <div className="footer-two">
                            <Accordion className="row g-4" alwaysOpen>
                                <Accordion.Item eventKey="0" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-1', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-1-1")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>  {t("footer.info-1-2")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong> {t("footer.info-1-3")} <a href="https://www.x33my1.com/">{t("footer.info-1-4")}</a> {t("footer.info-1-5")}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>   
                                <Accordion.Item eventKey="1-" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-2', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-2-1")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong> {t("footer.info-2-2")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong> {t("footer.info-2-3")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong> {t("footer.info-2-4")} <strong><a href="https://www.x33my1.com/promos">{t("footer.info-2-5")}</a></strong>.</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="2" className="col-4">
                                    <Accordion.Header><h2>{t("footer.hdr-info-3")}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-3-1")}<strong>{t("footer.info-3-2")}</strong>{t("footer.info-3-3")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-3-4")}<strong><a href="https://www.x33my1.com/about-us">{t("footer.info-3-5")}</a></strong>{t("footer.info-3-6")}</p>
                                        <br/>
                                        <p>{t("footer.info-3-7")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-3-8")} <strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-3-9")}<strong><a href="https://www.x33my1.com/online-live-games-malaysia">{t("footer.info-3-10")}</a></strong>.</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="3" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-4', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-4-1")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-4-2")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-4-3")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-4-4")}</p>
                                        <br/>
                                        <p><strong>{t("footer.info-4-5")}</strong>{t("footer.info-4-6")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-4-7")}<a href="https://www.x33my1.com/slot/pragmatic-play">Pragmatic Play</a>{t("footer.info-4-8")}<a href="https://www.x33my1.com/games/playtech">Playtech</a>{t("footer.info-4-9")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="4" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-5', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-5-1")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-5-2")}<strong><a href="https://www.x33my1.com/">{t("footer.info-5-3")}</a></strong>{t("footer.info-5-4")}</p>
                                        <br/>
                                        <p>{t("footer.info-5-5")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-5-6")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-5-6")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="5" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-6', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-6-1")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-6-2")}<strong>{t("footer.info-6-3")}</strong>{t("footer.info-6-4")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-6-5")}</p>
                                        <br/>
                                        <p>{t("footer.info-6-6")}<strong><a href="https://www.x33my1.com/online-live-games-malaysia">{t("footer.info-6-7")}</a></strong>{t("footer.info-6-8")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-6-9")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-6-10")}<a href="https://www.x33my1.com/vip">{t("footer.info-6-11")}</a>{t("footer.info-6-12")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="6" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-7', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-7-1")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-7-2")}<strong>{t("footer.info-7-3")}</strong>{t("footer.info-7-4")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-7-5")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="7" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-8', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-8-1")}<strong>{t("footer.info-8-2")}</strong>{t("footer.info-8-3")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-8-4")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-8-5")}</p>
                                        <br/>
                                        <p>{t("footer.info-8-6")}<strong><a href="https://www.x33my1.com/online-live-games-malaysia">{t("footer.info-8-7")}</a></strong>{t("footer.info-8-8")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-8-9")}<strong>{`${process.env.REACT_APP_WEBSITE_TITLE}`}</strong>{t("footer.info-8-10")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                                <Accordion.Item eventKey="8" className="col-4">
                                    <Accordion.Header><h2>{t('footer.hdr-info-8')}</h2></Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("footer.info-9-1")}<a href="https://www.begambleaware.org/">BeGambleAware</a>{t("footer.info-9-2")}<a href="https://www.ncpgambling.org/">National Council on Problem Gambling</a>{t("footer.info-9-3")}</p>
                                    </Accordion.Body>
                                </Accordion.Item>  
                            </Accordion>      
                            <div className="btn-social">
                                <a href="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://www.facebook.com/${process.env.REACT_APP_MERCHANT_FB}`); }}>
                                    <Icon icon="uil:facebook-f" />
                                </a>
                                {/* <a href="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://twitter.com/${process.env.REACT_APP_MERCHANT_TWITTER}?s=20`); }}>
                                    <Icon icon="uil:twitter-alt" />
                                </a> */}
                                <a href="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://www.instagram.com/${process.env.REACT_APP_MERCHANT_INSTAGRAM}/`); }}>
                                    <Icon icon="uil:instagram" />
                                </a>
                                <a href="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://www.youtube.com/channel/${process.env.REACT_APP_MERCHANT_YOUTUBE}`); }}>
                                    <Icon icon="uil:youtube-alt" />
                                </a>
                                <Link to="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}`); }}>
                                    <Icon icon="uil:whatsapp" />
                                </Link>
                                {/* <a href="/#" target="_blank" rel="noreferrer" onClick={() => { window.open(`https://t.me/${process.env.REACT_APP_MERCHANT_TELEGRAM}/`); }}>
                                    <Icon icon="uil:telegram-alt" />
                                </a> */}
                            </div>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to={{ pathname: "/about-us" }} className="nav-link">
                                        {t("footer.about-us")}
                                    </Link>
                                </li>
                                <span></span>
                                <li className="nav-item">
                                    <Link to={{ pathname: "/privacy-policy" }} className="nav-link">
                                        {t("footer.privacy-policy")}
                                    </Link>
                                </li>
                                <span></span>
                                <li className="nav-item">
                                    <Link to={{ pathname: "/responsible-gaming" }} className="nav-link">
                                        {t("footer.responsible-gaming")}
                                    </Link>
                                </li>
                                <span></span>
                                <li className="nav-item">
                                    <Link to={{ pathname: "/info-centre" }} className="nav-link">
                                        {t("footer.info-centre")}
                                    </Link>
                                </li>
                                <span></span>
                                <li className="nav-item">
                                    <Link to={{ pathname: "/vip" }} className="nav-link">
                                        VIP
                                    </Link>
                                </li>
                                <span></span>
                                <li className="nav-item">
                                    <Link to={{ pathname: "/contact-us" }} className="nav-link">
                                        {t("footer.contact-us")}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <div
                                        role="button"
                                        className="language-select gap-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectCountry"
                                        onClick={this.openChangeCountryModal}
                                    >
                                        <img src={flags[(this.props.currentUser.country_code) ? this.props.currentUser.country_code + ".webp" : this.state.currentCountryShortCode + ".png"]} alt="flags" />
                                        <p className="ml-2">{(this.props.currentUser.language_code) ? this.props.currentUser.language_code.toUpperCase() : this.state.currentLanguageCode}</p>
                                        <i className="arrow down btn-text-icon-gray" />
                                    </div>
                                </li>
                            </ul>
                            <small>&#0169; {process.env.REACT_APP_WEBSITE_TITLE} | {moment().format("YYYY")}. {t("footer.all-right")}</small>
                        </div>
                    </div>

                    <div className="modal global-modal fade" id="selectCountry" tabIndex="-1" aria-labelledby="selectCountryLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered  modal-language-body">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <img src={img["global-language.svg"]} alt="language" />
                                    <h5 className="modal-title text-uppercase" id="selectCountryLabel">
                                        {t("footer.region-language")}
                                    </h5>
                                    <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                {this.state.loading && (
                                    <div className="modal-body">
                                        <ul>
                                            {countries &&
                                                countries.map((countryVal, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="country">
                                                                <img src={countryVal.image} alt="my flags" />
                                                                <h6>{countryVal.countryName}</h6>
                                                            </div>
                                                            <div className="language">
                                                                {langArr &&
                                                                    langArr.map((langVal, idx) => {
                                                                        if (countryVal.countryCode === langVal.countryCode) {
                                                                            if (idx === 0 && this.props.currentUser.length === 0) {
                                                                                return (
                                                                                    <button
                                                                                        className="btn btn-gradient-blue"
                                                                                        key={idx}
                                                                                        onClick={(event) =>
                                                                                            this.changeCountryHandler(
                                                                                                event,
                                                                                                countryVal.countryCode,
                                                                                                countryVal.countryId,
                                                                                                langVal.languageId,
                                                                                                langVal.code,
                                                                                                langVal.timezone
                                                                                            )
                                                                                        }
                                                                                        data-bs-dismiss="modal"
                                                                                    >
                                                                                        {langVal.displayLanguageName}
                                                                                    </button>
                                                                                );
                                                                            }

                                                                            return (
                                                                                <button
                                                                                    className={`btn ${this.props.currentUser.language_id === langVal.languageId
                                                                                        ? "btn-gradient-blue"
                                                                                        : "btn-white"
                                                                                        }`}
                                                                                    key={idx}
                                                                                    onClick={(event) =>
                                                                                        this.changeCountryHandler(
                                                                                            event,
                                                                                            countryVal.countryCode,
                                                                                            countryVal.countryId,
                                                                                            langVal.languageId,
                                                                                            langVal.code,
                                                                                            langVal.timezone
                                                                                        )
                                                                                    }
                                                                                    data-bs-dismiss="modal"
                                                                                >
                                                                                    {langVal.displayLanguageName}
                                                                                </button>
                                                                            );
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </footer>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { home, banner } = state;
    return {
        country_data: home.country_data,
        currentUser: state.currentUser.data,
        show_banner_data: banner.show_banner_data
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getCountry,
    getUserData,
    showBanner
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces("translation")(PageFooter)));
