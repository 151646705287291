import * as type from './types';
import axios from '../../../common/config/axios';

export const list = (data) => (dispatch, getState) => {
	axios.get('reward_item/list', {params: data})
		.then(function (response) {
			dispatch ({
				type: type.REWARD_LIST,
				payload: response.data
			})
		})
		.catch(err => {
			// console.log('Error get reward list', err);
			dispatch({
				type: type.REWARD_LIST,
				payload: [],
			});
		});
};

export const favorite_list = () => dispatch => {
	axios.get('reward_item/favorite_list')
		.then(function (response) {
			dispatch ({
				type: type.FAVORITE_LIST,
				payload: response.data
			})
		}).catch(err => {
			// console.log('Error get favorite list', err);
			dispatch({
				type: type.FAVORITE_LIST,
				payload: err.message,
			});
		});
};

export const favorite = (data) => dispatch => {
	axios.post('reward_item/favorite', data)
		.then(function (response) {
			dispatch ({
				type: type.FAVORITE,
				payload: response
			})
		}).catch(err => {
			// console.log('Error favorite reward', err);
			dispatch({
				type: type.FAVORITE,
				payload: err.message,
			});
		});
};

export const cart = (data) => dispatch => {
	axios.post('reward_item/cart', data)
		.then(function (response) {
			dispatch ({
				type: type.CART_LIST,
				payload: response.data
			})
		}).catch(err => {
			// console.log('Error get cart list', err);
			dispatch({
				type: type.CART_LIST,
				payload: err.message,
			});
		});
};

export const cart_add = (data) => dispatch => {
	axios.post('reward_item/add', data)
		.then(function (response) {
			dispatch ({
				type: type.CART_ADD,
				payload: response.data
			})
		}).catch(err => {
			// console.log('Error add to cart', err);
			dispatch({
				type: type.CART_ADD,
				payload: err.message,
			});
		});
};

export const cart_update = (data) => dispatch => {
	axios.post('reward_item/update', data)
		.then(function (response) {
			dispatch ({
				type: type.CART_UPDATE,
				payload: response
			})
		}).catch(err => {
			// console.log('Error update cart', err);
			dispatch({
				type: type.CART_UPDATE,
				payload: err.message,
			});
		});
};

export const checkout = (data) => dispatch => {
	axios.post('reward_item/checkout', data)
		.then(function (response) {
			dispatch ({
				type: type.CHECKOUT,
				payload: response
			})
		}).catch(err => {
			// console.log('Error checkout', err);
			dispatch({
				type: type.CHECKOUT,
				payload: err.message,
			});
		});
};
