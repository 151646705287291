import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
import DataTable from "../../../components/Datatable/DataTable";
import { numFormat } from "../../../hoc/Shared/utility";

//Icons
// import { Icon } from "@iconify/react";

class Bonus extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {};
    }

    componentDidMount() 
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray("bonus");
            this.props.funcGetList(listingArr);
        }
    }

    render() 
    {
        const { t } = this.props;
        let rsData = [];
        let stCls = { 0: "on-going", 1: "success", 2: "failed", 3: "on-going", 4: "failed" };
        let stVal = { 0: t("history.pending"), 1: t("history.success"), 2: t("history.reject"), 3: t("history.processing"), 4: t("history.cancel") };
        const num_format = numFormat(this.props.currentUser.currency);

        // let promoList = this.props.data.promoList ? this.props.data.promoList : [];
        // let promoList = [];
        // if (this.props.data.promoList && this.props.openModal) 
        // {
		// 	promoList = this.props.data.promoList.map((val, idx) => 
        //     {
		// 		return {
		// 			id: val.id,
		// 			label: val.title,
		// 			key: idx,
		// 		};
		// 	});
		// }
        // let validPromo = this.props.data.validPromo;

        const columns = [
            // {text: 'No', dataField: "no", formatter: this.noFormatter, headerAttrs: { hidden: true }},
            { text: t("history.date"), dataField: "create_date" },
            { text: t("history.amount"), dataField: "credit" },
            { text: t("history.type"), dataField: "transaction_type" },
            { text: t("history.status"), dataField: "status"},
        ];

        if (this.props.data.data) 
        {
            rsData = this.props.data.data.map((val, idx) => 
            {
                return {
                    no: idx,
                    create_date: moment(val.create_date).format("YYYY-MM-DD H:mm"),
                    credit: new Intl.NumberFormat("en-US", num_format).format(val.credit),
                    transaction_type: t(`transaction-type.${val.transaction_type}`),
                    status: (<span role="button" className={` status ${stCls[val.status]}`}>{stVal[val.status]}</span>),
                };
            });
        }

        return (
            <Auxiliary>
                <Fade duration={1000} triggerOnce>
                    <DataTable
                        keyField="no"
                        data={rsData}
                        columns={columns}
                        activePage={this.props.data.activePage}
                        limit={this.props.data.limit}
                        totalItems={this.props.data.totalRecords}
                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "activePage")}
                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "limit")}
                        loading={this.props.data.loading}
                        hideSearchBar={true}
                        hideTotalRecords={true}
                        classValue="pd-table"
                        component="transaction-history"
                    />
                </Fade>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces("translation")(Bonus));
