import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const getPromotedEvents = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    res_data.lang_id = getState().currentUser.data.language_id;
    axios.get('promoted-events/get_promoted_events',  {
        params: res_data
    }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_PROMOTED_EVENTS,
                payload: response.data
            })
        }).catch (err => { 
        dispatch ({
            type: type.GET_PROMOTED_EVENTS,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};