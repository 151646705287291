import React, {Component} from 'react';
import {connect} from 'react-redux';
import jwt from "jsonwebtoken";
// import moment from "moment";

// import {text} from "../public-key";
import {postIsAuthenticated} from "../../store/ducks/auth/actions";
import {Redirect} from "react-router-dom";

const authPermission = (ComposedComponent) => {
    class Authentication extends Component {
        state = {
            isAuthenticated: false,
            loading: false
        }

        UNSAFE_componentWillMount() {
            let publicKey = process.env[`REACT_APP_${process.env.REACT_APP_ENV.toUpperCase()}_PUBLIC_KEY`];
            jwt.verify(localStorage.getItem("accessToken"), publicKey, {algorithm: 'RS256'}, function (err, decoded_token) {
                if (err === null) {
                    this.setState({
                        isAuthenticated: true
                    }, () => {
                        this.props.postIsAuthenticated(false);
                    });
                    //Refresh token will do it later
                } else {
                    this.setState({
                        isAuthenticated: false
                    }, () => {
                        localStorage.clear();
                    });
                }


            }.bind(this));
        }

        render() {
            return (this.state.isAuthenticated === true) ? <ComposedComponent {...this.props}/> :
                <Redirect to='/'/>;
        }
    }

    const mapStateToProps = state => {
        const {auth} = state;

        return {authenticated: auth.login_data};
    }

    const mapDispatchToProps = {
        postIsAuthenticated
    };

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}

export default authPermission;