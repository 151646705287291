import * as types from "./types";
import axios from "../../../common/config/axios";

export const getSystemSetting = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get("setting/list", {params: data})
    .then((res) => {
        dispatch({
            type: types.SYSTEM_SETTING,
            payload: res
        });
    })
    .catch((err) => {
        // console.log(err);
    });
};