import * as type from './types';
import axios from '../../../common/config/axios';

export const showAnnouncement = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    data.lang_id = getState().currentUser.data.language_id;
    data.member_group_id = localStorage.getItem("accessToken") ? getState().currentUser.data.member_group_id : "";
        axios.get('announcement/show', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.SHOW_ANNOUNCEMENT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};
