import React, { Component } from "react";
// import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Skeleton from "react-loading-skeleton";
import { Icon } from "@iconify/react";

//Components 
// import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import BankTransfer from "./BankTransfer";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import MobileImportantNotice from "../../../components/MobileImportantNotice/MobileImportantNotice";

import Crypto from "./Crypto";
//Actions 
// import * as actions from "../../../store/ducks/withdrawal/actions";

class MobileWithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
        }
    }

    componentDidMount() {
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleNoticeHandler = () => {
        if (this.state.showNotice === false) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showNotice: !this.state.showNotice,
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() {
        const { t } = this.props;
        let transactionChannel = this.props.transactionChannel ? this.props.transactionChannel : [];
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        return (
            <Auxiliary>
                {this.state.showNotice &&
                    <MobileImportantNotice
                        notice="withdrawal"
                        toggleNoticeHandler={this.toggleNoticeHandler}
                    />
                }
                <section className="pf-m-dashboard dnw-section">
                    <MobileSubHeader
                        className="bg-white"
                        returnPage="/me"
                        header={t('profile-sidebar.withdraw')}
                        modalHeader={t('withdraw.notice')}
                        modalHandler={this.toggleNoticeHandler}
                    />
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t("withdraw.withdraw-options")}:</label>
                                    {this.props.withdrawalEstTime &&(
                                        <>
                                            <div className="transfer-duration-label">
                                                {this.props.withdrawalEstTime === "00:00:00" ? (
                                                    <>
                                                     <Icon icon="ant-design:thunderbolt-outlined" />
                                                        {t("withdrawal.instant-withdrawal")}
                                                    </> 
                                                ) : (
                                                    <>
                                                        <Icon icon="ic:baseline-alarm" />
                                                        {t("withdrawal.withdrawal-in")} {this.props.withdrawalEstTime.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                    </>
                                                )
                                                }
                                            </div>
                                        </>
                                    )}
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {transactionChannel.map((val, idx) => {
                                                if (val.wd_status === 1) {
                                                    return (
                                                        <div
                                                            className="form-check form-check-inline four-check"
                                                            onClick={(event) => this.props.optionSelectHandler(event, val.slug, val.id, val.withdrawal_est_time)}
                                                            key={idx}
                                                        >
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="withdrawalOption"
                                                                id={`withdrawal${val.slug}`}
                                                                value={val.slug}
                                                                onChange={() => { }}
                                                                checked={this.props.withdrawalOption === val.slug ? true : false}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`withdrawal${val.slug}`}>
                                                                <img src={icons[`${val.slug}.svg`]} alt={val.slug} />
                                                                {t(`transaction-channel.${val.slug}`)}
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            })}

                                            {/* <div
                                                className="form-check form-check-inline four-check"
                                                onClick={(event) => this.props.optionSelectHandler(event, "crypto", 2)}
                                            >
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="withdrawalOption"
                                                    id={`withdrawalcrypto`}
                                                    value={"crypto"}
                                                    onChange={() => { }}
                                                    checked={this.props.withdrawalOption === "crypto" ? true : false}
                                                />
                                                <label className="btn form-check-label" htmlFor={`withdrawalcrypto`}>
                                                    <img src={icons[`crypto.svg`]} alt={"crypto"} />
                                                    {t(`transaction-channel.${"crypto"}`)}
                                                </label>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {this.props.withdrawalOption === 'quick-pay' ? (
                                    <>Quick Pay (Coming Soon)</>
                                ) : this.props.withdrawalOption === 'e-wallet' ? (
                                    <>E-Wallet (Coming Soon)</>
                                ) : this.props.withdrawalOption === 'crypto' ? (
                                    // <>Crypto (Coming Soon)</>
                                    <Crypto
                                        redirectHandler={this.redirectHandler}
                                        {...this.state}
                                        {...this.props}
                                    />
                                ) : this.props.withdrawalOption === 'bank-transfer' ? (
                                    <BankTransfer
                                        redirectHandler={this.props.redirectHandler}
                                        {...this.props}
                                    />
                                ) : (
                                    <>Invalid Option</>
                                )}
                            </>
                        )}
                    </div>
                </section >
            </Auxiliary >
        );
    }
}

// Connect redux
export default (withNamespaces("translation")(MobileWithdrawal));
