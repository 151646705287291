import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {};

const dailyMissionReducer = createReducer(initialState) ({
    [types.CUSTOM_LINK]: (state, action) => ({
        ...state,
        custom_link: action.payload
    })
});

export default dailyMissionReducer;

