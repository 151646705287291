import React, { Component } from 'react'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import MobileCheckIn from './MobileCheckIn';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';
import { numFormat } from '../../hoc/Shared/utility';
import { currency } from '../../common/config/currency';

import { getDailyMission, getDailyMissionBonuses, claimDailyBonus, claimExtraBonus } from "../../store/ducks/dailyMission/actions";
import moment from 'moment';

export class CheckIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mission: [],
      daily_claim_list: [],
      claim_loading: true,
      start_day: "",
      end_day: ""
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    // let data = {};
    // data.country_id = this.props.currentUser.country_id;
    // data.language_code = this.props.currentUser.language_code;
    // this.props.getDailyMission(data);
    // this.props.getDailyMissionBonuses(data);
    this.props.getDailyMission();
    this.props.getDailyMissionBonuses();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.daily_mission !== undefined && nextProps.daily_mission !== this.props.daily_mission) {
      if (nextProps.daily_mission.data.responseCode === 200 && nextProps.daily_mission.data.msgType === "success") {
        this.setState({
          loading: false,
          mission: nextProps.daily_mission.data.data.result,
          start_day: nextProps.daily_mission.data.data.start_day,
          end_day: nextProps.daily_mission.data.data.end_day
        });
      }
    }

    if (nextProps.daily_mission_bonuses !== undefined && nextProps.daily_mission_bonuses !== this.props.daily_mission_bonuses) {
      if (nextProps.daily_mission_bonuses.data.responseCode === 200 && nextProps.daily_mission_bonuses.data.msgType === "success") {
        this.setState({
          claim_loading: false,
          daily_claim_list: nextProps.daily_mission_bonuses.data.data.result
        });
      }
    }

    if (nextProps.daily_mission_bonus_claim !== undefined && nextProps.daily_mission_bonus_claim !== this.props.daily_mission_bonus_claim) {
      if (nextProps.daily_mission_bonus_claim.data.responseCode === 200 && nextProps.daily_mission_bonus_claim.data.msgType === "success") {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
          icon: "info",
          title: t("global.success"),
          text: t("check_in.bonus-claim"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
          } : []
        }).then((result) => {
          this.setState({
            loading: true,
            mission: [],
            daily_claim_list: [],
            claim_loading: true,
            start_day: "",
            end_day: ""
          });
          this.fetchData();
        });
      }
    }

    if (nextProps.daily_mission_extra_bonus_claim !== undefined && nextProps.daily_mission_extra_bonus_claim !== this.props.daily_mission_extra_bonus_claim) {
      if (nextProps.daily_mission_extra_bonus_claim.data.responseCode === 200 && nextProps.daily_mission_extra_bonus_claim.data.msgType === "success") {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
          icon: "info",
          title: t("global.success"),
          text: t("check_in.extra-bonus-claim"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
          } : []
        }).then((result) => {
          this.setState({
            loading: true,
            mission: [],
            daily_claim_list: [],
            claim_loading: true,
            start_day: "",
            end_day: ""
          });
          this.fetchData();
        });
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  claimBonusHandler = (id, turnover, date, bonus) => {
    if (localStorage.getItem("accessToken")) {
      if (id === "extra-bonus") {
        let data = {};
        data.id = id;
        data.country_id = this.props.currentUser.country_id;
        data.bonus = bonus;
        this.props.claimExtraBonus(data);
      }
      else {
        // normal daily claim
        let data = {};
        data.id = id;
        data.country_id = this.props.currentUser.country_id;
        data.validate_turnover = turnover;
        data.validate_date = date;
        data.bonus = bonus;
        this.props.claimDailyBonus(data);
      }
    }
    else {
      const { t } = this.props;
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        showCancelButton: (!isDesktop) ? false : '',
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    }
  }

  render() {

    const { t } = this.props;
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const img = this.imageImport(require.context("../../assets/images/check-in", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }

    const num_format = localStorage.getItem('accessToken') ? numFormat(this.props.currentUser.currency) : numFormat(currency[this.props.currentUser.country_id]);
    let checkInDay = !this.state.claim_loading && this.state.daily_claim_list ? this.state.daily_claim_list.map((val, idx) => {
      let amt = new Intl.NumberFormat("en-US", num_format).format(val.bonus);
      let label = `day-${idx + 1}`;
      if (val.id === 'extra-bonus') {
        label = 'full-check-in';
      }
      if (val.claimed) {
        amt = val.claimed_amt;
      }
      return {
        label: t(`check_in.${label}`),
        amount: amt,
        turnover: val.turnover,
        claim: val.claimed,
        active: val.active,
        current: val.current,
        date: val.date,
        hash: val.id,
        enable: val.enable,
        bonus: val.bonus,
        isLogin: localStorage.getItem("accessToken") && val.isLogin ? true : false
      }
    }) : [];

    let data = this.state.mission;
    let table1 = data && data.length > 0 ? data.map((val, idx) => {
      return {
        amount: val.key,
        reward: val.daily_bonus
      };
    }) : [];

    let table2 = data && data.length > 0 ? data.map((val, idx) => {
      return {
        amount: val.key,
        reward: val.extra_bonus
      };
    }) : [];
    const table3 = [
      { session: '第一期', date: '2022年6月1日 00:00:00 - 2022年6月7日 23:59:59' },
      { session: '第二期', date: '2022年6月8日 00:00:00 - 2022年6月14日 23:59:59' },
      { session: '第三期', date: '2022年6月15日 00:00:00 - 2022年6月21日 23:59:59' },
      { session: '第四期', date: '2022年6月22日 00:00:00 - 2022年6月28日 23:59:59' },
    ]

    return (
      <Auxiliary>
        {this.state.loading && <LoadingPage />}
        {
          isDesktop ? (
            <section className='check-in-section'>
              <div className="banner-col">
                <div className="banner-item">
                  <img src={banner[`check-in-banner${imageLanguage}.webp`]} />
                </div>
              </div>

              <div className='x-container'>
                <div className='section-header-gray'>
                  <h2>{t('check_in.title_1')}</h2>
                  <p>{t('check_in.title_2')}</p>
                  {
                    (this.state.start_day && this.state.end_day) &&
                    <p className='event-date'>{t('check_in.time_1')}：
                      <span>{this.state.start_day}</span>
                      <span className='px-2'>{t('check_in.time_3')}</span>
                      <span>{this.state.end_day}</span>
                    </p>
                  }
                </div>

                {checkInDay.length > 0 &&
                  <div className='card-shadow'>
                    <div className='check-in-container gap-5'>
                      {
                        checkInDay && checkInDay.map((item, index) => (
                          <div className={`check-in-col ${item.active ? 'active' : ''} ${item.current ? 'current' : ''}`} key={index}>
                            <h4>{item.label}</h4>
                            {
                              item.id === 'extra-bonus' ? <img src={img['more-coin.webp']} alt="coin" /> :
                                item.claim === true ? <img src={img['silver-coin.webp']} alt="coin" /> :
                                  item.claim === false && <img src={img['golden-coin.webp']} alt="coin" />
                            }
                            <p className='number'>{item.amount}</p>
                            {
                              !item.isLogin ? (
                                <Link className='btn btn-gradient-blue' to={{ pathname: "/login" }}>
                                  <span>{t("check_in.login_to_claim")}</span>
                                </Link>
                              ) : (
                                item.enable ? (
                                  <button className='btn btn-gradient-blue' onClick={() => this.claimBonusHandler(item.hash, item.turnover, item.date, item.bonus)} >
                                    <span>{t("check_in.claim")}</span>
                                  </button>
                                ) : (
                                  <>
                                    {
                                      item.claim ? (
                                        <>
                                          <button className='btn btn-gray' disabled={true}>
                                            <span>{t("check_in.claimed")}</span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button className='btn btn-gray' data-tip data-for='claim' disabled={moment().format("YYYY-MM-DD") === item.date ? false : true}>
                                            <span>{t("check_in.claim")}</span>
                                          </button>
                                          <ReactTooltip id='claim' type='info' effect='solid' className="custom-tooltip">
                                            <span>{t("check_in.not_acheive_turnover_amount")}</span>
                                          </ReactTooltip>
                                        </>
                                      )
                                    }
                                  </>
                                ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
                <div className='row mt-5'>
                  {table1.length > 0 &&
                    <div className='col-6'>
                      <div className='events-table-container card-shadow'>
                        <table className='table table-borderless events-table'>
                          <thead>
                            <tr>
                              <th>{t("check_in.today-total-bet")}</th>
                              <th>{t("check_in.reward-today")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              table1 && table1.map((item, index) => (
                                <tr key={index}>
                                  <td className='number'>{item.amount}</td>
                                  <td className='number'>{item.reward}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {table2.length > 0 &&
                    <div className='col-6'>
                      <div className='events-table-container card-shadow'>
                        <table className='table table-borderless events-table'>
                          <thead>
                            <tr>
                              <th>{t("check_in.average-bet")}</th>
                              <th>{t("check_in.full-check-in")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              table2 && table2.map((item, index) => (
                                <tr key={index}>
                                  <td className='number'>{item.amount}</td>
                                  <td className='number'>{item.reward}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </div>

                <div className='blue-bg-notice mt-5'>
                  <img src={img["present.webp"]} alt="present" />
                  <div>
                    <p>{t("check_in.rule-header")}</p>
                    <ol className='mt-3 ps-3'>
                      <li>{t("check_in.rule-1")}</li>
                      <li>{t("check_in.rule-2")}</li>
                      <li>{t("check_in.rule-3")}</li>
                      <li>{t("check_in.rule-4")}</li>
                      <li>{t("check_in.rule-5")}</li>
                      <li>{t("check_in.rule-6")}</li>
                    </ol>
                  </div>

                </div>

                {/* <div className='event-time-table mt-5'>
                  <table className='table table-borderless card-shadow events-table'>
                    <thead>
                      <tr>
                        <th className='event-session'>{t("check_in.event-periods")}</th>
                        <th>{t("check_in.event-date")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        table3 && table3.map((item, index) => (
                          <tr key={index}>
                            <td className='event-session'>{item.session}</td>
                            <td>{item.date}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                </div> */}

                <div className='blue-bg-notice mt-5'>
                  <img src={img["reward-coin.webp"]} alt="coin" />
                  <div>
                    <p>{t("check_in.mention-1")}</p>
                    <p className='mt-3'>{t("check_in.mention-2")}</p>
                    <p>{t("check_in.mention-3")}</p>
                    <p>{t("check_in.mention-4")}</p>
                  </div>
                </div>

              </div>
            </section>
          ) : (
            <MobileCheckIn
              checkInDay={checkInDay}
              table1={table1}
              table2={table2}
              table3={table3}
              start_day={this.state.start_day}
              end_day={this.state.end_day}
              claimBonusHandler={this.claimBonusHandler}
            />
          )
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
    daily_mission: state.dailyMission.daily_mission,
    daily_mission_bonuses: state.dailyMission.daily_mission_bonuses,
    daily_mission_bonus_claim: state.dailyMission.daily_mission_bonus_claim,
    daily_mission_extra_bonus_claim: state.dailyMission.daily_mission_extra_bonus_claim
  }
}

const mapDispatchToProps = {
  getDailyMission,
  getDailyMissionBonuses,
  claimDailyBonus,
  claimExtraBonus
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(CheckIn));