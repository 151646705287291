import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getGameVendorFav = (params) => dispatch => {
    axios.get('game_vendor_fav/list', {params: params})
    .then((res) => {
        dispatch({
           type: actionType.GAME_VENDOR_FAV,
           payload: res.data 
        });
    })
    .catch((err) => {

    });
};

export const updateGameVendorFav = (params) => dispatch => {
    axios.post('game_vendor_fav/update', params)
    .then((res) => {
        dispatch({
            type: actionType.UPDATE_GAME_VENDOR_FAV,
            payload: res.data
        });
    })
    .catch((err) => {

    });
};