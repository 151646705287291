import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";

import { isDesktop } from 'react-device-detect';
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";

class ResponsibleGaming extends Component {
  render() {
    const { t } = this.props;
    return (
      <Auxiliary>
        {isDesktop ? (
          <section className="responsible-gaming-section ">
            <div className="x-container">
              <div className="row">
                <div className="section-header mt-5">
                  <h2>{t("responsible-gaming.responsible-gaming")}</h2>
                </div>
                <div className="section-content">
                  <h3>{t("responsible-gaming.problem-gambling-checklist")}</h3>
                  <p>
                    {t("responsible-gaming.problem-gambling-checklist-details", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>
                  <br />
                  <ul>
                    <li>
                      {t("responsible-gaming.pgc-1")}
                    </li>
                    <li> {t("responsible-gaming.pgc-2")}</li>
                    <li>
                      {t("responsible-gaming.pgc-3")}
                    </li>
                    <li> {t("responsible-gaming.pgc-4")}</li>
                    <li>
                      {t("responsible-gaming.pgc-5")}
                    </li>
                    <li>
                      {t("responsible-gaming.pgc-6")}
                    </li>
                    <li>
                      {t("responsible-gaming.pgc-7")}
                    </li>
                    <li>
                      {t("responsible-gaming.pgc-8")}
                    </li>
                    <li>
                      {t("responsible-gaming.pgc-9")}
                    </li>
                  </ul>
                  <br />
                  <p>
                    {t("responsible-gaming.pgc-recommend")}
                  </p>
                  <ul>
                    <li> {t("responsible-gaming.pgc-recommend-1")}</li>
                    <li> {t("responsible-gaming.pgc-recommend-2")}</li>
                    <li> {t("responsible-gaming.pgc-recommend-3")}</li>
                    <li> {t("responsible-gaming.pgc-recommend-4")}</li>
                  </ul>
                  <br />
                  <p>
                    {t("responsible-gaming.pgc-advice")}
                  </p>

                  <h3>{t("responsible-gaming.self-exclusion")}</h3>
                  <p>
                    {t("responsible-gaming.self-exclusion-details")}
                  </p>

                  <h3>{t("responsible-gaming.legal-age-limit")}</h3>
                  <p>
                    {t("responsible-gaming.lgl-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>
                  <br />
                  <p>
                    {t("responsible-gaming.lgl-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                  </p>

                  <h3>{t("responsible-gaming.parental-controls")}</h3>
                  <p>
                    {t("responsible-gaming.parental-controls-details")}
                  </p>
                  <br />
                  <p>
                    {t("responsible-gaming.pc-1")} :<a href="https://www.cybersitter.com/">www.cybersitter.com</a>
                  </p>
                  <p>
                    {t("responsible-gaming.pc-2")} : <a href="https://www.netnanny.com/">www.netnanny.com</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="responsible-gaming-section">
            <MobileSubHeader
              className="bg-white"
              returnPage="/"
              header={t("responsible-gaming.responsible-gaming")}
              style={{ justifyContent: "unset" }}
            />
            <div>
              <div className="section-content mobile mb-5">
                <h3 className="p-0">{t("responsible-gaming.problem-gambling-checklist")}</h3>
                <p>
                  {t("responsible-gaming.problem-gambling-checklist-details", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                </p>
                <br />
                <ul>
                  <li>
                    {t("responsible-gaming.pgc-1")}
                  </li>
                  <li> {t("responsible-gaming.pgc-2")}</li>
                  <li>
                    {t("responsible-gaming.pgc-3")}
                  </li>
                  <li> {t("responsible-gaming.pgc-4")}</li>
                  <li>
                    {t("responsible-gaming.pgc-5")}
                  </li>
                  <li>
                    {t("responsible-gaming.pgc-6")}
                  </li>
                  <li>
                    {t("responsible-gaming.pgc-7")}
                  </li>
                  <li>
                    {t("responsible-gaming.pgc-8")}
                  </li>
                  <li>
                    {t("responsible-gaming.pgc-9")}
                  </li>
                </ul>
                <br />
                <p>
                  {t("responsible-gaming.pgc-recommend")}
                </p>
                <ul>
                  <li> {t("responsible-gaming.pgc-recommend-1")}</li>
                  <li> {t("responsible-gaming.pgc-recommend-2")}</li>
                  <li> {t("responsible-gaming.pgc-recommend-3")}</li>
                  <li> {t("responsible-gaming.pgc-recommend-4")}</li>
                </ul>
                <br />
                <p>
                  {t("responsible-gaming.pgc-advice")}
                </p>

                <h3>{t("responsible-gaming.self-exclusion")}</h3>
                <p>
                  {t("responsible-gaming.self-exclusion-details")}
                </p>

                <h3>{t("responsible-gaming.legal-age-limit")}</h3>
                <p>
                  {t("responsible-gaming.lgl-1", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                </p>
                <br />
                <p>
                  {t("responsible-gaming.lgl-2", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                </p>

                <h3>{t("responsible-gaming.parental-controls")}</h3>
                <p>
                  {t("responsible-gaming.parental-controls-details")}
                </p>
                <br />
                <p>
                  {t("responsible-gaming.pc-1")} :<a href="https://www.cybersitter.com/">www.cybersitter.com</a>
                </p>
                <p>
                  {t("responsible-gaming.pc-2")} : <a href="https://www.netnanny.com/">www.netnanny.com</a>
                </p>
              </div>
            </div>
          </section>
        )}

      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(ResponsibleGaming));
