import React, { Component } from "react";
import Picker from "react-scrollable-picker";
import moment from "moment";
import { withNamespaces } from "react-i18next";

class DateScroller extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            dateValue: 
            {
                // days: '12',
                // months: '08',
                // years: '2008',
                days: moment().format('DD').toString(),
                months: moment().format('MM').toString(),
                years: moment().format('YYYY').toString(),
            },
            dateArry: 
            {
                days: [{}],
                months: [{}],
                years: [{}],
            },
        };
    }

    componentDidMount() 
    {
        this.loopYear();
    }

    componentDidUpdate(nextProps, nextState) 
    {
        if (nextState.dateValue !== this.state.dateValue && nextState.dateValue !== undefined) 
        {
            this.props.onChangeDateHandler(this.state.dateValue);
        }
        
        if ((nextProps.showDate !== this.props.showDate || nextProps.selectedDate !== this.props.selectedDate) 
            && this.props.showDate !== undefined 
            && this.props.selectedDate !== undefined)
        {
            let date = this.props.selectedDate.split('-');
            
            let dateValue = 
            {
                days: date[2],
                months: date[1],
                years: date[0],
            };
            
            this.setState({
                dateValue,
            });
        }
        
        return true;
    }

    getNewDays = (name, value) => 
    {
        const n = { ...this.state.dateArry };
        const days = [];
        const months = [];
        
        if (name === "months") 
        {
            // const currentMonth = moment(`${this.state.dateValue.years}-${value}`, "YYYY-MM").daysInMonth();
            let day = null;
            if((this.props.maxDate && this.props.maxDate > 0))
            {
                day = (moment(moment().format('YYYY-MM'), 'YYYY-MM').subtract(this.props.maxDate, 'years') <= moment(`${this.state.dateValue.years}-${value}-01`, "YYYY-MM"))
                      ?
                      moment().subtract(this.props.maxDate, 'years').format('DD')
                      :
                      moment(`${this.state.dateValue.years}-${value}`, "YYYY-MM").daysInMonth();
            }
            else
            {
                day = moment(`${this.state.dateValue.years}-${value}`, "YYYY-MM").daysInMonth();
            }
            
            for (let i = 1; i <= day; i++) 
            {
                let tmp = (i < 10) ? `0${i}` : i;
                days.push({
                    value: tmp.toString(), //moment().day(i - 3).format("DD"),
                    label: tmp.toString()
                });
            }
        }
        else if (name === "years") 
        {
            // const currentYear = moment(`${value}-${this.state.dateValue.months}`, "YYYY-MM").daysInMonth();
            let day = null;
            let month = null;

            if((this.props.maxDate && this.props.maxDate > 0))
            {
                if(moment(moment().format('YYYY-MM'), 'YYYY-MM').subtract(this.props.maxDate, 'years') <= moment(`${value}-${this.state.dateValue.months}-01`, "YYYY-MM"))
                {
                    day = moment().subtract(this.props.maxDate, 'years').format('DD');
                    month = moment().subtract(this.props.maxDate, 'years').format('MM');
                }
                else
                {
                    day = moment(`${value}-${this.state.dateValue.months}`, "YYYY-MM").daysInMonth();
                    month = 12;
                }
            }
            else
            {
                day = moment(`${value}-${this.state.dateValue.months}`, "YYYY-MM").daysInMonth();
                month = 12;
            }

            for (let i = 1; i <= day; i++) 
            {
                let tmp = (i < 10) ? `0${i}` : i;
                days.push({
                    value: tmp.toString(), //moment().day(i - 3).format("DD"),
                    label: tmp.toString()
                });
            }
            
            for (let i = 1; i <= month; i++) 
            {
                months.push({
                    value: moment().month(i - 1).format("MM"),
                    label: moment().month(i - 1).format("MMMM"),
                });
            }
        }

        n.days = days;
        if(name === "years")
        {
            n.months = months;
        }
        
        this.setState({
            dateArry: n,
        });
    }

    loopYear = () => 
    {
        const years = [];
        const months = [];
        const days = [];

        // const currentYear = moment().format('YYYY');
        // const currentDay = moment(`${this.state.dateValue.years}-${this.state.dateValue.months}`, "YYYY-MM").daysInMonth();

        let maxDate = (this.props.maxDate && this.props.maxDate > 0) ? moment().subtract(this.props.maxDate, 'years') : moment();
        const currentYear = maxDate.format('YYYY');
        const currentMonth = (this.props.maxDate && this.props.maxDate > 0) ? maxDate.format('MM') : 12;
        const currentDay = (this.props.maxDate && this.props.maxDate > 0) ? maxDate.format('DD') : moment(`${this.state.dateValue.years}-${this.state.dateValue.months}`, "YYYY-MM").daysInMonth();

        const n = { ...this.state.dateArry };

        for (let i = 0; i <= 62; i++) 
        {
            years.push({ value: (currentYear - i).toString(), label: (currentYear - i).toString() });
        }

        for (let i = 1; i <= currentDay; i++) 
        {
            let tmp = (i < 10) ? `0${i}` : i;
            days.push({
                value: tmp.toString(), //moment().day(i - 3).format("DD"),
                label: tmp.toString()
            });
        }
        for (let i = 1; i <= currentMonth; i++) 
        {
            months.push({
                value: moment().month(i - 1).format("MM"),
                label: moment().month(i - 1).format("MMMM"),
            });
        }

        n.years = years;
        n.months = months;
        n.days = days;

        this.setState({
            dateArry: n
        });
    };

    handleChange = (name, value) => 
    {
        if(value !== undefined)
        {
            this.setState(({ dateValue }) => ({
                dateValue: {
                ...dateValue,
                [name]: value,
                },
            }));

            if (name === "years" || name === "months") {
                this.getNewDays(name, value);
            }
        }
    };

    render() 
    {
        const { dateArry, dateValue } = this.state;
        const { t } = this.props;

        return (
            <section className={`mobile-asset-section ic-assets ${this.props.showDateSelect && "open"}`}>
                <div className="mobile-asset-content">
                    <div className="mobile-asset-header">
                        <button
                            type="button"
                            className="btn btn-cancel"
                            data-bs-dismiss="mobile-asset-section"
                            onClick={this.props.toggleShowDateSelect}
                        >
                            {t("global.cancel")}
                        </button>
                        <button type="submit" className="btn btn-ok" onClick={this.props.toggleShowDateSelect}>
                            {t("global.ok")}
                        </button>
                    </div>
                    <div className="mobile-asset-body">
                        <Picker
                            optionGroups={dateArry}
                            valueGroups={dateValue}
                            itemHeight={110}
                            height={700}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="overlay" id="menu-overlay" onClick={this.props.toggleShowDateSelect} />
            </section>
        );
    }
}

export default withNamespaces("translation")(DateScroller);
