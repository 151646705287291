import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    // data: [],
};

const sportReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.VENDOR_LIST]: (state, action) => ({
        ...state,
        vendor: action.value,
    }),
    [type.SPORT_GAME_ACCESS]: (state, action) => ({
        ...state,
        rs: action.value,
        actionFrom: action.actionFrom
    }),
});

export default sportReducer;