export const POST_FORGOT_PASSWORD = "POST_FORGOT_PASSWORD";
export const VERIFY_FORGOT_PASSWORD = "VERIFY_FORGOT_PASSWORD";
export const POST_SEND_FORGOT_PASSWORD_EMAIL = "POST_SEND_FORGOT_PASSWORD_EMAIL";
export const CHECK_FORGOT_PASSWORD = "CHECK_FORGOT_PASSWORD";

export const GENERATE_FORGOT_PASSWORD_OTP = "GENERATE_FORGOT_PASSWORD_OTP";
export const RESEND_FORGOT_PASSWORD_OTP = "RESEND_FORGOT_PASSWORD_OTP";
export const VERIFY_FORGOT_PASSWORD_OTP = "VERIFY_FORGOT_PASSWORD_OTP";
export const POST_FORGOT_PASSWORD_OTP = "POST_FORGOT_PASSWORD_OTP";

