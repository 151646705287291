import React, { Component } from 'react';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from "react-device-detect";
import { Link } from 'react-router-dom';
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import MallCheckbox from './MallCheckbox';
import MobileMallCart from './MobileMallCart';
import { cart, cart_update } from "../../store/ducks/mall/actions";

export class MallCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initiated: false,
      products: [],
      totalItem: 0,
      totalCoin: 0
    };
  }

  componentDidMount() {
    this.props.cart({
      lang: this.props.currentUser.language_id
    });

    localStorage.removeItem('checkout_items');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.cart_data !== this.props.cart_data) {
      if (nextProps.cart_data.responseCode === 200) {
        if ( !this.state.initiated) {
          this.setState({
            initiated: true,
            products: nextProps.cart_data.data.result
          });
        }
      }
    }

    return true;
  }

  handleAllChecked = event => {
    let products = this.state.products.filter(item => item.stock > 0);
    products.forEach(product => (product.isChecked = event.target.checked));

    this.setState({
      products: products
    }, this.refreshCartTotal);

    localStorage.setItem('checkout_items', JSON.stringify(products));
  };

  handleCheckChildElement = event => {
    let products = this.state.products;
    let checkout_items = [];

    products.map((item, idx) => {
      if (item.id === Number(event.target.value) && item.stock > 0) {
        item.isChecked = event.target.checked;
      }
      return null;
    });

    this.setState({
      products: products
    }, this.refreshCartTotal);

    products.map((item, idx) => {
      if (item.isChecked) {
        checkout_items.push(products[idx]);
      }
      return null;
    });

    localStorage.setItem('checkout_items', JSON.stringify(checkout_items));
  };

  minusProductCount = (idx) => {
    if (this.state.products[idx]['qty'] === 1) {
      return false;
    }

    var products = this.state.products;
    products[idx]['qty'] -= 1;

    this.setState({
      products: products
    }, this.refreshCartTotal);

    this.props.cart_update({
      item: products[idx]['id'],
      quantity: 1,
      cart: products
    });
  };

  addProductCount = (idx) => {
    let newQty = this.state.products[idx]['qty'];
    newQty += 1;

    if (newQty > this.state.products[idx]['stock']) {
      return false;
    }

    if (this.state.products[idx]['limit'] !== 0 && newQty > this.state.products[idx]['limit']) {
      return false;
    }

    var products = this.state.products;
    products[idx]['qty'] += 1;

    this.setState({
      products: products
    }, this.refreshCartTotal);

    this.props.cart_update({
      item: products[idx]['id'],
      quantity: 1,
      cart: products
    });
  };

  removeProduct = (idx) => {
    let item_id = this.state.products[idx]['id'];
    this.state.products.splice(idx, 1);

    this.setState({
      products: this.state.products
    }, this.refreshCartTotal);

    this.props.cart_update({
      item: item_id,
      quantity: 1,
      cart: this.state.products
    });
  };

  refreshCartTotal = () => {
    let totalItem = 0;
    let totalCoin = 0;

    this.state.products.map((item, idx) => {
      if (item.isChecked === true) {
        totalItem += item.qty;
        totalCoin += (item.qty * item.coin);
      }
      return null;
    });

    this.setState({
      totalItem: totalItem,
      totalCoin: totalCoin
    });
  };

  render() {
    const { t } = this.props;

    let allowedCheckout = this.state.products.filter(val => val.isChecked === true);
    return (
      <Auxiliary>
        {isDesktop ? (
            <>
              <section className="mall checkout">
                <div className="pf-dashboard">
                  <div className="x-container">
                    <div className="pf-fade-col">
                      <div className="pd-card-col">
                        <h4>{t("mall.cart")}</h4>
                        {
                          !this.state.initiated ? (
                            <Skeleton count={4} />
                          ) : (
                            <Fade duration={1000}>
                              <table className="table pd-table w-border mb-0 cart">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>{t("mall.your-order")}</th>
                                    <th>{t("mall.items")}</th>
                                    <th>{t("mall.quantity")}</th>
                                    <th>{t("redemption-history.order-total")}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                {this.state.products && this.state.products.map((product, idx) => {
                                  return (
                                    <MallCheckbox
                                      {...product}
                                      {...this.props}
                                      idx={idx}
                                      handleCheckChildElement={this.handleCheckChildElement}
                                      minusProductCount={this.minusProductCount}
                                      addProductCount={this.addProductCount}
                                      removeProduct={this.removeProduct}
                                    />
                                  );
                                })}
                              </table>
                              <hr />
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <input
                                    id="checkAll"
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    onClick={this.handleAllChecked}
                                    value="checkedAll"
                                  />
                                  <label htmlFor="checkAll">
                                  {t("global.select-all")}
                                  </label>
                                </div>
                                <p className='fw-bold'> {t("mall.total")} ({this.state.totalItem}  {t("mall.items")}) : {this.state.totalCoin}  {t("mall.coins")}</p>
                              </div>
                              <div className='text-end mt-3'>
                                <Link className="btn btn-outline-blue submit-btn "
                                  to={{ pathname: "/mall" }}>
                                 {t("mall.bt-mall")}
                                </Link>
                                {allowedCheckout.length > 0 && (
                                  <Link className="btn btn-gradient-blue submit-btn ms-2"
                                    to={{ pathname: "/mall/checkout" }}>
                                    {t("mall.checkout")}
                                  </Link>
                                )}
                              </div>
                            </Fade>
                          )
                        }
                        {/* <div className="no-records-cont">
                          <NoRecords />
                          <NavLink to={{ pathname: "/mall" }} className="nav-link btn-gradient-blue" activeClassName='active'>
                            Redeem Now
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <MobileMallCart
              {...this.state}
              {...this.props}
              handleCheckChildElement={this.handleCheckChildElement}
              handleAllChecked={this.handleAllChecked}
              minusProductCount={this.minusProductCount}
              addProductCount={this.addProductCount}
              removeProduct={this.removeProduct}
              allowedCheckout={allowedCheckout}
            />
          )
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = state => {
  const { mall } = state;

  return {
    currentUser: state.currentUser.data,
    cart_data: mall.cart_data
  };
};

const mapDispatchToProps = {
  cart,
  cart_update
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MallCart));
