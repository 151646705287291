import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from "sweetalert2";

//Miscellaneous
import moment from "moment";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Button from "../../../components/Button/Button";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import { isDesktop } from "react-device-detect";
import LuckySpin from "./LuckySpin";
import FifaWorldCup from "./FifaWorldCup";
import { FIfaQna } from "./FIfaQna";
import MobileRedemptionHistory from "./MobileRedemptionHistory";
import Mall from "./Mall";

//Icons
import { Icon } from "@iconify/react";

// Actions
import * as actions from "../../../store/ducks/history/actions";

const redemptionTypes = [
    { id: "typeLuckySpin", value: "lucky_spin", label: "lucky_spin" },
    // { id: "typeFifaWorldCup", value: "fifa_world_cup", label: "fifa_world_cup" },
    // { id: "typeFifaQna", value: "fifa_qna", label: "fifa_qna" },
];

const mallTypes = [
    { id: "typeMall", value: "mall", label: "mall" }
];

const filterDate = [
    { id: "filterToday", value: "0", label: "today" },
    { id: "filterYtd", value: "1", label: "yesterday" },
    { id: "filter7Day", value: "7", label: "last-7-days" },
    { id: "filter30Days", value: "30", label: "last-30-days" },
];

const initialTabState =
{
    /* datatables / pagination */
    activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
    // limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    limit: 7,

    totalPage: 0,
    order: '',
    totalRecords: 0,

    /* api result array */
    data: [],
    address: false,

    /* skeleton loading */
    loading: true,
};

class RedemptionHistory extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            historyTab: (props.location.state && props.location.state.historyTab) ? props.location.state.historyTab : 'lucky_spin',
            defaultTab: (props.location.state && props.location.state.defaultTab) ? props.location.state.defaultTab : 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            currentFilterDate: 0,
            disableSearchButton: false,
            spin: false,

            lucky_spin: initialTabState,
            fifa_world_cup: initialTabState,
            mall: initialTabState,
            fifa_qna: initialTabState,

            /* mobile */
            showDateSelect: false,
            showDate: '',
            selectedDate: '',
            pageShow: 'events_details',
        };

        this.onInputChange = this.onInputChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined)
        {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                let resData = [];
                let questionArray = [];
                if (nextProps.rs.data.transaction_type === 'fifa_qna') {
                    nextProps.rs.data.questionArray.forEach((val) => {
                        questionArray.push({
                            question: val.question,
                            answer: JSON.parse(val.answer)
                        });
                    });

                    if (nextProps.rs.data.result.row && nextProps.rs.data.result.row.length > 0) {
                        let totalCorrect = 0;
                        nextProps.rs.data.result.row.forEach((val, idx) => {
                            Object.values(JSON.parse(val.bet_data)).forEach((betData,betDataIdx) => {
                                if (betData[1] === questionArray[betDataIdx].answer[0]) {
                                    totalCorrect = totalCorrect+1;
                                } 
                            });

                            resData.push({
                                betData: JSON.parse(val.bet_data), 
                                winAmount: !!(val.win_amount) ? val.win_amount : 0,
                                date: val.created_at,
                                totalCorrect: totalCorrect
                            });
                        });
                    }
                }

                let newTab = Object.assign({}, this.state[nextProps.rs.data.transaction_type]);
                newTab['data'] = (nextProps.rs.data.transaction_type === 'fifa_qna') ? resData : nextProps.rs.data.result.row;
                newTab['totalRecords'] = nextProps.rs.data.result.pagination.total_record;
                newTab['activePage'] = nextProps.rs.data.result.pagination.current_page;
                newTab['limit'] = nextProps.rs.data.result.pagination.per_page;
                newTab['totalPage'] = nextProps.rs.data.result.pagination.total_page;
                newTab['loading'] = false;
                newTab['address'] = nextProps.rs.data.address;
                newTab['questionArray'] = questionArray;

                this.setState({
                    [nextProps.rs.data.transaction_type]: newTab,
                    disableSearchButton: false,
                });
            }
            else
            {
                this.swalErrorHandler(nextProps.rs.message[0]);
            }
        }

        if (nextProps.updatePrize !== this.props.updatePrize && nextProps.updatePrize !== undefined)
        {
            if (nextProps.updatePrize.responseCode === 200 && nextProps.updatePrize.msgType === "success")
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
                    icon: "success",
                    title: t('global.success'),
                    html: `<p>${t('redemption-history.success-msg-1')}<br/> ${t('redemption-history.success-msg-2')}</p>`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then(() =>
                {
                    const listingArr = this.getFilterArray('lucky_spin');
                    this.props.funcGetList(listingArr);
                });
            }
            else
            {
                this.swalErrorHandler(nextProps.updatePrize.message[0]);
            }
        }

        return true;
    }

    onInputChange = (event) =>
    {
        if (event.target.id === 'transaction_date')
        {
            const date = (event.target.value !== '') ? event.target.value.split(" - ") : null;
            this.setState({
                start_date: (date[0]) ? moment(date[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: (date[1]) ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                transaction_date: event.target.value,
            });

            const startDate = moment(this.state.start_date);
            const endDate = moment(this.state.end_date);
            const diff = endDate.diff(startDate, 'days');

            // Only allow to get data between 30 days
            if (diff > 30)
            {
                this.setState({
                    disableSearchButton: true
                });
            }
            else
            {
                this.setState({
                    disableSearchButton: false
                });
            }
        }
    }

    getFilterArray = (tab) =>
    {
        const listingArr = {};
        listingArr.page = this.state[tab].activePage;
        listingArr.limit = this.state[tab].limit;
        listingArr.order = JSON.stringify(this.state[tab].order);
        listingArr.start_date = this.state.start_date;
        listingArr.end_date = this.state.end_date;
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.lang_id = this.props.currentUser.language_id;
        listingArr.transaction_type = this.state.historyTab;
        // console.log(listingArr);
        return listingArr;
    }

    searchHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['activePage'] = 1;
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: initialTabState,
            disableSearchButton: true
        }, () =>
        {
            const listingArr = this.getFilterArray(tab);
            // console.log(listingArr);
            this.props.funcGetList(listingArr);
        });
    }

    filterDayRange = (val) =>
    {
        let days = val;
        let startDate = moment().subtract(days, "days").format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");
        let transactionDate = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`;

        let dayRange = moment(endDate).diff(moment(startDate), 'days');
        let disableButton = (!moment(startDate).isSameOrBefore(moment(endDate)) || !moment(endDate).isSameOrAfter(moment(startDate)) || dayRange > 30) ? true : false ;

        this.setState({
            start_date: startDate,
            end_date: endDate,
            transaction_date: transactionDate,
            filterRange: days,
            disableSearchButton: disableButton,
        }, () =>
        {
            this.searchHandler(this.state.historyTab);
        });
    }

    onChangeFilterDateHandler = (value, e) =>
    {
        this.setState({
            currentFilterDate: value
        });
    }

    toggleShowDateSelect = (e, show) =>
    {
        if (this.state.showDateSelect === false) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showDateSelect: !this.state.showDateSelect,
        });

        const startDate = moment(this.state.start_date);
        const endDate = moment(this.state.end_date);
        const diff = endDate.diff(startDate, 'days');

        this.setState({
            showDate: show,
            selectedDate: (show === 'startDate') ? this.state.start_date : this.state.end_date,
            disableSearchButton: (diff > 30) ? true : false,
        })
    }

    onChangeDateHandler = (value) =>
    {
        if (this.state.showDate === 'startDate')
        {
            this.setState({
                start_date: `${value.years}-${value.months}-${value.days}`,
            })
        }
        else if (this.state.showDate === 'endDate')
        {
            this.setState({
                end_date: `${value.years}-${value.months}-${value.days}`,
            })
        }
    }

    imageImport = (r) =>
    {
        let images = {};
        r.keys().map((item, index) =>
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    onResetHandler = () =>
    {
        this.setState({
            defaultTab: 0,
            historyTab: 'lucky_spin',
            currentFilterDate: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            transaction_date: "",
            spin: true
        }, () =>
        {
            const listingArr = this.getFilterArray("lucky_spin");
            this.props.funcGetList(listingArr);
            // this.searchHandler(this.state.historyTab);
        });

        setTimeout(() => this.setState({ spin: false }), 2000);
    }

    navigateList = (event, type, customPage = 0, tab) =>
    {
        event.preventDefault();
        // console.log(type+' | '+customPage+' | '+tab);
        let params = {};
        let newFilter = Object.assign({}, this.state[tab]);

        switch (type)
        {
            case "page":
                params.page = customPage === 0 ? newFilter.activePage : customPage;
            break;
            case "prev":
                params.page = newFilter.activePage !== 1 ? newFilter.activePage - 1 : newFilter.activePage;
            break;
            case "next":
                params.page = newFilter.activePage < newFilter.totalPage ? newFilter.activePage + 1 : newFilter.activePage;
            break;
            default:
                params.page = newFilter.activePage;
            break;
        }

        newFilter['activePage'] = params.page;
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            disableSearchButton: true
        }, () =>
        {
            const listingArr = this.getFilterArray(tab);
            this.props.funcGetList(listingArr);
        });
    }

    swalErrorHandler = (msg) =>
    {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : '',
            title: t("global.error"),
            text: msg,
            icon: 'error',
            confirmButtonText: t('global.ok'),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
            } : [],
        }).then(() => {
            let newTab = Object.assign({}, this.state[this.state.historyTab]);
            newTab['loading'] = false;

            this.setState({
                [this.state.historyTab]: newTab,
                disableSearchButton: false,
            });
        });
    }

    tabShow = (option) => {
        let newTab = Object.assign({}, this.state[option]);
        newTab = initialTabState;
        newTab['loading'] = (this.state.disableSearchButton) ? false : true;
        this.setState({
            historyTab: option,
            [option]: newTab,
        });
    }

    updatePrizeHandler = (e, id, addr) =>
    {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : '',
            title: t("global.reminder"),
            html: `<p>${t('redemption-history.your-personal-details')}:</p><p class="my-3 lh-base text-dark">${this.props.currentUser.name}, +${this.props.currentUser.contact_no || ''} <br/> ${addr || ''}.</p><p style="font-size: .75rem">*${t('redemption-history.acknowledge-msg', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>`,
            showDenyButton: true.valueOf,
            denyButtonText: t('global.edit'),
            denyButtonColor: '#04be02',
            confirmButtonText: t('global.confirm'),
            icon: "warning",
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) =>
        {
            if (result.isConfirmed)
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                let data = {};
                data.id = id;
                this.props.funcUpdatePrize(data);
            }
            else if(result.isDenied)
            {
                window.location = "/personal-information";
            }
        });
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        if (e.target.id === 'events_details') {
            this.setState({
                historyTab: 'lucky_spin'
            })
        } else if (e.target.id === 'mall_details') {
            this.setState({
                historyTab: 'mall'
            })
        }
        this.setState({
            pageShow: e.target.id,
        });
    };

    render()
    {
        const { t } = this.props;
        const resetIcon = this.imageImport(
            require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
        );

            const fadeUp = keyframes`
            from {
            opacity: 0;
            transform: translate3d(0, 50px ,0);
            }

            to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            }
        `;

        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                            <div className="row g-3">
                                <div className="col-12">
                                    <div className="pd-card-col">
                                        <h4>{t('profile-sidebar.redemption-history')}</h4>
                                        <div className="pd-content">
                                            <div className="filters-col">
                                            <div className="fd-col">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${this.state.pageShow === 'events_details' && 'active'}`}
                                                            id="events_details"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                        >
                                                           {t("history.event-prize")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${this.state.pageShow === 'mall_details' && 'active'}`}
                                                            id="mall_details"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                        >
                                                            {t("history.mall")}
                                                        </button>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div className="filters-tp-panel">
                                                        <div className="filters-tp-col">
                                                            <label className="col-form-label">{t('history.type')}:</label>
                                                            <div className="filters-group">
                                                            {this.state.pageShow === "events_details" &&
                                                                redemptionTypes &&
                                                                    redemptionTypes.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="filterType"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                // defaultChecked={index === this.state.defaultTab}
                                                                                defaultChecked={item.value === this.state.historyTab}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id} onClick={() => this.tabShow(item.value)}>
                                                                                {t(`history.${item.label}`)}
                                                                            </label>
                                                                        </div>
                                                                    ))}

                                                            {this.state.pageShow === "mall_details" &&
                                                                mallTypes &&
                                                                    mallTypes.map((item, index) => (
                                                                        <div className="form-check form-check-inline filter" key={index}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name="filterType"
                                                                                id={item.id}
                                                                                value={item.value}
                                                                                // defaultChecked={index === this.state.defaultTab}
                                                                                defaultChecked={item.value === this.state.historyTab}
                                                                            />
                                                                            <label className="btn form-check-label" htmlFor={item.id} onClick={() => this.tabShow(item.value)}>
                                                                                {t(`history.${item.label}`)}
                                                                            </label>
                                                                        </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="filters-bt-panel">
                                                    <div className="filters-bt-col">
                                                        <label className="col-form-label">{t('history.date')}:</label>
                                                        <div className="filters-group">
                                                            {filterDate && filterDate.map((item, index) => (
                                                                <div className="form-check form-check-inline filter" key={index}>
                                                                    <input
                                                                        className="btn-check"
                                                                        type="radio"
                                                                        name="filterDate"
                                                                        id={item.id}
                                                                        value={item.value}
                                                                        // defaultChecked={index === 0}
                                                                        defaultChecked={index === this.state.currentFilterDate}
                                                                        onClick={(e) => this.onChangeFilterDateHandler(index, e)}
                                                                    />
                                                                    <label className="btn form-check-label" htmlFor={item.id} style={{ maxWidth: '75px' }} onClick={() => this.filterDayRange(item.value)}>
                                                                        {t(`history.${item.label}`)}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="filters-bt-col">
                                                        <label className="col-form-label">{t('history.time')}:</label>
                                                        <div className="input-group right">
                                                            <DateTimePicker
                                                                id="transaction_date"
                                                                name="transaction_date"
                                                                className="form-control"
                                                                autoUpdateInput={true}
                                                                autoApply={true}
                                                                format="MM/DD/YYYY"
                                                                value={this.state.transaction_date}
                                                                onChange={this.onInputChange}
                                                            />
                                                            <span className="input-group-text">
                                                                <Icon icon="uil:calendar-alt" />
                                                            </span>
                                                        </div>
                                                        <Button
                                                            typeButton="button"
                                                            classValue="btn btn-gradient-blue"
                                                            idValue="search"
                                                            buttonName={t('global.check')}
                                                            clicked={() => this.searchHandler(this.state.historyTab)}
                                                            disabled={this.state.disableSearchButton}
                                                        />
                                                        <div className="reset-btn">
                                                            <img className={`${this.state.spin ? "spin" : ""} `} src={resetIcon["rotate-icon.svg"]} alt="reset" onClick={this.onResetHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pd-card-col">
                                    {this.state.pageShow === "events_details" &&
                                        this.state.historyTab === 'lucky_spin' ? (
                                            <LuckySpin
                                                tabName='lucky_spin'
                                                data={this.state.lucky_spin}
                                                currentUser={this.props.currentUser}
                                                updatePrizeHandler={(event, id, addr) => this.updatePrizeHandler(event, id, addr)}
                                                navigateList={(event, type, page, tab) => this.navigateList(event, type, page, tab)}
                                                getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                {...this.state}
                                                {...this.props}
                                            />
                                        ) : this.state.historyTab === 'fifa_world_cup' ? (
                                            <FifaWorldCup
                                                tabName='fifa_world_cup'
                                                data={this.state.fifa_world_cup}
                                                currentUser={this.props.currentUser}
                                                updatePrizeHandler={(event, id, addr) => this.updatePrizeHandler(event, id, addr)}
                                                navigateList={(event, type, page, tab) => this.navigateList(event, type, page, tab)}
                                                getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                {...this.state}
                                                {...this.props}
                                            />
                                        ) : this.state.historyTab === 'fifa_qna' ? (
                                            <FIfaQna
                                                tabName='fifa_qna'
                                                data={this.state.fifa_qna}
                                                currentUser={this.props.currentUser}
                                                navigateList={(event, type, page, tab) => this.navigateList(event, type, page, tab)}
                                                getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                searchHandler={this.searchHandler}
                                                {...this.state}
                                                {...this.props}
                                            />
                                        ) :  ("")
                                        }
                                         {this.state.pageShow === "mall_details" &&
                                         this.state.historyTab === 'mall' ? (
                                            <Mall
                                                tabName='mall'
                                                data={this.state.mall}
                                                currentUser={this.props.currentUser}
                                                updatePrizeHandler={(event, id, addr) => this.updatePrizeHandler(event, id, addr)}
                                                navigateList={(event, type, page, tab) => this.navigateList(event, type, page, tab)}
                                                getFilterArray={() => this.getFilterArray(this.state.historyTab)}
                                                searchHandler={this.searchHandler}
                                                {...this.state}
                                                {...this.props}
                                            /> ) : ("")}
                                    </div>
                                </div>
                            </div>
                            </Reveal>
                        </div>
                    </section>
                ) : (
                    <MobileRedemptionHistory
                        // redemptionTypes={redemptionTypes}
                        filterDate={filterDate}

                        tabShow={this.tabShow}
                        searchHandler={this.searchHandler}
                        filterDayRange={this.filterDayRange}
                        navigateList={(event, type, page, tab) => this.navigateList(event, type, page, tab)}
                        updatePrizeHandler={this.updatePrizeHandler}
                        getFilterArray={this.getFilterArray}
                        onChangeDateHandler={this.onChangeDateHandler}
                        toggleShowDateSelect={this.toggleShowDateSelect}
                        onResetHandler={this.onResetHandler}
                        onChangeFilterDateHandler={this.onChangeFilterDateHandler}

                        {...this.state}
                        {...this.props}
                    />
                )
            }
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        rs: state.history.rs,
        currentUser: state.currentUser.data,
        updatePrize: state.history.update_prize,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcUpdatePrize: (data) => dispatch(actions.updatePrize(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RedemptionHistory));